import React from 'react'
import './style.css'

const MeetOurTeam = () => {
    return (
        <>
            <div className="meet-our-team-box">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="left-side-box">
                                <h1 className="main-heading">We Work Hard and Play Harder</h1>
                                <p className="common-text">Our work ethic is built on dedication, collaboration, and excellence—but we also know how to have a good time. Whether it’s intense matches on the in-house table tennis court, friendly PS5 gaming competitions, or enjoying dinners together, we make sure there’s always time to unwind and have fun. Our vibrant workplace culture fosters not just hard work, but also camaraderie and relaxation, making Bitsclan a place where you can thrive professionally while enjoying the journey.</p>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="meet-team-image">
                                <img src="/images/life-at-bitsclan-image.svg" alt="Life At Bitsclan" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MeetOurTeam
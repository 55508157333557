import React, { useRef, useState, useCallback, useEffect } from 'react'
import './style.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

import { Navigation } from 'swiper/modules';

const CoverTheStack = ({ tabs, mainHeading, subHeading, secondSubHeading, whereStart, tabItems, qaTabsData }) => {
    const [activeTab, setActiveTab] = useState(whereStart);

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    const sliderRef = useRef();

    const [isBeginning, setIsBeginning] = useState(true);
    const [isEnd, setIsEnd] = useState(false);

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);

    const handleSlideChange = (swiper) => {
        setIsBeginning(swiper.isBeginning);
        setIsEnd(swiper.isEnd);
    };

    useEffect(() => {
        if (sliderRef.current && sliderRef.current.swiper) {
            sliderRef.current.swiper.slideTo(0);
            setIsEnd(false)
        }
    }, [activeTab]);

    const getFilteredItems = (tabName) => tabItems.filter(item => item.tab === tabName);

    return (
        <div className="hire-us-box" id='our-stacks'>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="main-heading">{mainHeading}</h1>
                        <h3 className="sub-heading">{subHeading}</h3>
                        <h3 className="sub-heading">{secondSubHeading}</h3>
                    </div>
                </div>
            </div>

            {tabs ? <div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="links-boxes cover-the-stack-box">
                                <ul className="links-boxes-lists">
                                    {tabs.map((tab) => (
                                        <li
                                            key={tab}
                                            className={activeTab === tab ? 'active' : ''}
                                            onClick={() => handleTabClick(tab)}
                                        >
                                            {tab}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tab-content-box">
                                {tabs?.map((tab) => (
                                    activeTab === tab ? (
                                        <div key={tab}>

                                            <div className="container-fluid">
                                                <div className="row" style={{ position: 'relative' }}>

                                                    <div className="col-md-1 flex-left-arrow">
                                                        {getFilteredItems(tab)?.length > 5 ? <div className="arrow-right arrows-slider arrow-stack-slider">
                                                            <img
                                                                onClick={handlePrev}
                                                                src="/images/arrow-right.svg"
                                                                className={`left-image ${isBeginning ? 'disabled' : ''}`}
                                                                alt="Arrow Image"
                                                                style={{ cursor: isBeginning ? 'not-allowed' : 'pointer' }}
                                                            />
                                                        </div> : ""}
                                                    </div>

                                                    <div className="col-md-10">
                                                        <Swiper
                                                            slidesPerView={4}
                                                            spaceBetween={30}
                                                            slidesPerGroup={1}
                                                            navigation={false}
                                                            modules={[Navigation]}
                                                            onSlideChange={handleSlideChange}
                                                            className="mySwiper"
                                                            ref={sliderRef}
                                                            breakpoints={{
                                                                320: { slidesPerView: 2, spaceBetween: 10, slidesPerGroup: 1 },
                                                                768: { slidesPerView: 3, spaceBetween: 20, slidesPerGroup: 1 },
                                                                1024: { slidesPerView: 4, spaceBetween: 30, slidesPerGroup: 1 },
                                                                1280: { slidesPerView: 5, spaceBetween: 30, slidesPerGroup: 1 },
                                                            }}
                                                        >
                                                            {getFilteredItems(tab)?.map((item, index) => (
                                                                <SwiperSlide key={index}>
                                                                    <div className="icon-text-box">
                                                                        <div className="cover-the-stack-image-box">
                                                                            <img src={item.icon} alt={item.name} />
                                                                        </div>
                                                                        <h1>{item.name}</h1>
                                                                    </div>
                                                                </SwiperSlide>
                                                            ))}
                                                        </Swiper>
                                                    </div>

                                                    <div className="col-md-1 flex-end-mb">
                                                        {getFilteredItems(tab)?.length > 5 ? <div className="arrows-slider arrow-stack-slider-next">
                                                            <img
                                                                onClick={handleNext}
                                                                src="/images/arrow-right.svg"
                                                                className={`${isEnd ? 'disabled' : ''}`}
                                                                alt="Arrow Image"
                                                                style={{ cursor: isEnd ? 'not-allowed' : 'pointer' }}
                                                            />
                                                        </div> : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div> : <div className='icon-text-main-box'>
                {
                    qaTabsData.map((item) => {
                        return <div className="icon-text-box">
                            <div className="cover-the-stack-image-box">
                                <img src={item.icon} alt={item.name} />
                            </div>
                            <h1>{item.name}</h1>
                        </div>
                    })
                }
            </div>}
        </div>
    );
};

export default CoverTheStack;
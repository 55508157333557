import React from 'react'
import './style.css'

const LifeGallery = () => {

    const galleryData = [
        {
            id: 1,
            image: "/images/life-at-bitsclan/life-image-1.png",
            title: "Small Image",
            description: "This is a smaller image.",
            height: "200px", // Custom height
        },
        {
            id: 2,
            image: "/images/life-at-bitsclan/life-image-1.png",
            title: "Large Image",
            description: "This is a larger image.",
            height: "400px", // Custom height
        },
        {
            id: 3,
            image: "/images/life-at-bitsclan/life-image-1.png",
            title: "Small Image",
            description: "This is a smaller image.",
            height: "200px", // Custom height
        },
        {
            id: 4,
            image: "/images/life-at-bitsclan/life-image-1.png",
            title: "Large Image",
            description: "This is a larger image.",
            height: "400px", // Custom height
        },
    ];


    return (
        <>
            <div className="ceo-intro-main-box meet-our-team-box">
                <div className="container">
                    <div className="row mb-5">
                        <div class="col-md-12"><h1 class="main-heading">Life Of Bitsclan</h1><h3 class="sub-heading">Whether it is software development and consulting services, QA testing services, cybersecurity, Blockchain, or digital branding, we have got you covered! No matter the service you choose, we deliver exceptional quality and measurable impact.</h3></div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="life-image">
                                <img src="/images/life-at-bitsclan/life-image-1.png" alt="Life At Bitsclan" />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="life-image left-life-image">
                                <img src="/images/life-at-bitsclan/life-image-2.png" alt="Life At Bitsclan" />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="life-image left-life-image">
                                <img src="/images/life-at-bitsclan/life-image-3.png" alt="Life At Bitsclan" />
                            </div>
                        </div>

                        <div className="col-md-6 top-image-design">
                            <div className="life-image">
                                <img src="/images/life-at-bitsclan/life-image-4.png" alt="Life At Bitsclan" />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="life-image">
                                <img src="/images/life-at-bitsclan/life-image-5.png" alt="Life At Bitsclan" />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="life-image left-life-image">
                                <img src="/images/life-at-bitsclan/life-image-6.png" alt="Life At Bitsclan" />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="life-image left-life-image">
                                <img src="/images/life-at-bitsclan/life-image-7.png" alt="Life At Bitsclan" />
                            </div>
                        </div>

                        <div className="col-md-6 top-image-design">
                            <div className="life-image">
                                <img src="/images/life-at-bitsclan/life-image-8.png" alt="Life At Bitsclan" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LifeGallery
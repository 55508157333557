import React from 'react'
import './style.css'

const CeoIntro = () => {
    return (
        <>
            <div className="ceo-intro-main-box meet-our-team-box">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-5 col-md-6">
                            <div className="ceo-image">
                                <img src="images/life-at-bitsclan/ceo.svg" alt="CEO Image" />
                            </div>
                        </div>

                        <div className="col-xl-7 col-lg-7 col-md-6 ps-xl-5 ps-lg-5">
                            <div className="intro-text">
                                <h1 class="main-heading">M Awais Sarwar</h1>
                                <p>"I have drastically improved my soft skills by attending various hands-on trainings. I am more confident and have stronger relationships with clients and my colleagues.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CeoIntro
import React from 'react'
import { Link } from 'react-router-dom'
import './style.css'


const WhoWeServeCard = ({ cardContent }) => {

    return (
        <>
            <div className="main-card pt-3">
                <div className={`hire-us-card ${cardContent?.borderClass}`}>
                    <div className="hire-us-icon">
                        <img src={cardContent?.icon} alt={cardContent?.title} />
                    </div>
                    <h1 className="common-heading">{cardContent?.title}</h1>
                    <p className="common-text">{cardContent?.description}</p>
                    <ul className='ps-3 mt-2'>
                        {cardContent?.serveList?.map((list, index) => {
                            return <li className='mt-2' key={index}>{list}</li>
                        })}
                    </ul>
                    {/* <Link to="/contact-us">Contact us <i className="fa-solid fa-arrow-right-long"></i></Link> */}
                </div>
            </div>
        </>
    )
}

export default WhoWeServeCard

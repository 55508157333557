import React from "react";
import HirePageBanner from "../components/HirePageBanner/HirePageBanner";
import DevelopmentPerformance from "../components/DevelopmentPerformance/DevelopmentPerformance";
import SecondFaq from "../components/secondFaq/SecondFaq";
import Footer from "../components/footer/Footer";
import MegaHeader from "../components/MegaHeader/MegaHeader";
import { Helmet } from "react-helmet";
import {
  bannerList,
  facts,
  faqData,
  hirignDetails,
  mainBannerImg,
  servicesData,
  solutionsData,
  steps,
  tabItems,
  tabs,
  techCombinations,
} from "../data/hire-goLang-data";
import HirePageAppIdea from "../components/hire-page-app-idea/HirePageAppIdea";
import HirePageEngagmentModels from "../components/hire-page-engagment-models/HirePageEngagmentModels";
import HireExpertFacts from "../components/hire-expert-facts/HireExpertFacts";
import HirePageSteps from "../components/hire-page-steps/HirePageSteps";
import HirePageExperties from "../components/hirepageExperties/HirePageExperties";
import ContactSection from "../components/contactSection/ContactSection";
import TrendsInsights from "../components/trends-n-insights/TrendsInsights";
import HireCombinationSection from "../components/hire-combination-sectiono/HireCombinationSection";
import CoverTheStack from "../components/coverthestack/CoverTheStack";

const HireGoLang = () => {
  return (
    <>
      {/* <Header /> */}
      <Helmet>
        <title>
          Hire Golang Developers | Golang Development Services
        </title>
        <meta
          name="description"
          content="Hire skilled Golang developers for fast, reliable applications. Expert Go development services for modern, scalable solutions."
        />
      </Helmet>

      <div className="sticky-header">
        <MegaHeader />
      </div>

      <HirePageBanner
        diffBannerBackgroundImage={mainBannerImg?.backgroundImage}
        bgGradientClass="bgGradient-green"
        image={""}
        mainHeading="Hire Remote Golang Developers"
        content=" Looking to hire Golang developers? We provide pre-vetted experts to help you build secure, scalable, and high-performance applications—perfect for startups and enterprises."
        btnText="Consult our Go lang developers"
        bannerList={bannerList}
      />

      <HirePageExperties
        heading="Our Golang Developer Expertise"
        discription="At Bitsclan, we deliver high-performance Golang solutions tailored to your business needs. Whether you need API development, migration services, or full-scale applications, our experienced developers ensure efficiency, scalability, and security."
        servicesData={servicesData}
      />

      <DevelopmentPerformance
        heading="Why Choose Us to Hire "
        greenHeading="Golang developers"
        subHeading="At Bitsclan, we combine Golang with modern frontend and backend technologies to create robust, scalable applications. Whether you're developing a web platform or a microservices-based architecture, our experts ensure seamless performance and fast development cycles."
        solutions={solutionsData}
      />

      <HireCombinationSection combinations={techCombinations} heading="Top GoLang Technology Combinations for High-Performance Development" description="Maximize efficiency and scalability with the best GoLang technology combinations. Hire expert GoLang developers to build robust, high-performing, and secure applications tailored to your business needs." />

      <HirePageSteps
        heading={"Hire Golang Developers in 4 Easy Steps"}
        subHeading={
          "At Bitsclan, we make it easy to hire Golang developers with a straightforward 4-step process. We focus on making sure you get the right talent for your project, quickly and effectively."
        }
        steps={steps}
      />

      <CoverTheStack
        whereStart={"Framework"}
        mainHeading={"Boost Your Web Development with High-Performance Golang Solutions"}
        subHeading={"At Bitsclan, we empower your web development journey with a powerful Golang tech stack that ensures efficiency, scalability, and speed. Our expert Golang developers leverage modern technologies to build robust, high-performance web applications tailored to your business needs."}
        tabs={tabs}
        tabItems={tabItems} />

      <HireExpertFacts
        heading="Quick Facts About "
        greenHeading="Our Golang Team"
        facts={facts}
        diffBannerBackgroundImage={mainBannerImg?.factBackgroundImage}
      />

      <HirePageAppIdea content="Have an idea but unsure how to proceed? Our Golang experts can help you build a functional prototype in just 6-8 weeks! Validate your vision, test user engagement, and establish a strong foundation for success with our expert development services." />

      <HirePageEngagmentModels description="Choose the engagement model that fits your project needs! Whether you need full-time, part-time, or hourly developers, we offer flexible options to ensure seamless collaboration and maximum efficiency throughout your project." hirignDetails={hirignDetails} />

      <TrendsInsights specificClass="mobile-dev-page" category="all" />

      <SecondFaq
        specificClass="mobile-dev-page"
        faqs={faqData}
        mainHeading={"Frequently Asked Questions"}
        subHeading={
          "Explore our Golang development services for tailored web solutions, API creation, and deployment management!"
        }
      />

      <ContactSection />

      <Footer />
    </>
  );
};

export default HireGoLang;

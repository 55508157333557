import React, { useEffect } from 'react'
import Footer from '../components/footer/Footer'
import SimilerBanner from '../components/similerBanner/SimilerBanner'
import PioneeringChange from '../components/pioneeringChange/PioneeringChange'
import DevelopingSolution from '../components/developingsolution/DevelopingSolution'
import WhyUs from '../components/whyus/WhyUs'
import BrandImages from '../components/brandImages/BrandImages'
import { Helmet } from 'react-helmet'
import MegaHeader from '../components/MegaHeader/MegaHeader'
import ContactSection from '../components/contactSection/ContactSection'
import tabData, { mainBannerImg, slidesData } from '../data/industryData'

const Industry = () => {
    return (
        <>
            <Helmet>
                <title>Expert IT Solutions Tailored for Each Industry | Bitsclan</title>
                <meta name="description" content="Our industry-specific IT solutions meet the unique challenges of your sector. We provide customized services that drive innovation and growth." />
            </Helmet>

            <div className="sticky-header">
                <MegaHeader />
            </div>

            <div className="industry-banner">
                <SimilerBanner mainHeading="Reimagining industry with Leading Tech" content={"No matter your industry, we are your trusted partners in building state-of-the-art technology solutions."} btnText2={"Schedule a call for consultation!"} diffBannerBackgroundImage={mainBannerImg} />
            </div>

            <PioneeringChange tabData={tabData} mainHeading="Our Expertise Across " greenHeading="Key Industries" content="At Bitsclan, we don’t just serve industries—we transform them. Leveraging the latest technologies and our deep expertise, we create customized solutions that address the unique challenges faced by businesses across various sectors. From pioneering digital transformation in traditional industries to enabling the future of smart technologies, our mission is to drive meaningful change." />

            <DevelopingSolution heading="Powering Innovative Businesses With Top-Notch Services" />

            <WhyUs slidesData={slidesData} heading="Experience The Bitsclan Advantage" content="We don’t just deliver services; we create lasting value for your business. Our approach is built on a foundation of deep expertise, cutting-edge technology, and a relentless commitment to excellence. When you choose Bitsclan, you’re choosing a partner dedicated to your success." />

            {/* <BrandImages /> */}

            <ContactSection heading="Get a free 30-minute consultancy worth 250$" />
            <Footer />
        </>
    )
}

export default Industry
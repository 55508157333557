import React from "react";
import "./style.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const WhyUs = ({
  slidesData,
  content,
  secondContent,
  heading,
  greenHeading,
}) => {
  return (
    <>
      <div className="why-us-box" id="why-us">
        <div className="container" style={{ position: "relative" }}>
          <div className="row">
            <div className="col-xl-7 col-lg-8 col-md-12">
              <div className="why-us-text-box">
                <h1>
                  <span>{greenHeading}</span> {heading}
                </h1>
                <p className="common-text">{content}</p>
                <p className="common-text">{secondContent}</p>

                <div className="why-us-box-slider-box">
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={0}
                    pagination={{ clickable: true }}
                    navigation={false}
                    modules={[Pagination, Autoplay]}
                    autoplay={{
                      delay: 3500, // Delay between slides in milliseconds (3000ms = 3 seconds)
                      disableOnInteraction: true, // Keep autoplay even after interaction
                      pauseOnMouseEnter: true,
                    }}
                    className="why-us-swiper"
                  >
                    {slidesData.map((item, index) => (
                      <SwiperSlide key={index}>
                        <div className="why-us-box-slider">
                          <h2 className="common-heading">{item.heading}</h2>
                          <p className="common-text">{item.text}</p>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </div>

            <div className="col-md-5 col-lg-4 col-md-12">
              <div className="container">
                <div className="row">
                  <div className="w-100 d-flex flex-wrap justify-content-center gap-5">
                    <div className="clutch-icons">
                      <img
                        src="/images/clutch-icons/clutch-rating3.svg"
                        alt=""
                      />
                    </div>
                    <div className="clutch-icons">
                      <img src="/images/clutch-footer.svg" alt="Clutch" />
                    </div>
                    <div className="clutch-icons">
                      <img src="/images/payoneer-footer.svg" style={{ width: '200px', marginTop: '-30px' }} alt="Payoneer" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12">
              <div className="why-us-second-text-box">
                <h1>
                  Some Say That <span>Numbers Speak</span> <br /> Louder Than
                  Words
                </h1>

                <div className="number-boxes-main">
                  <div className="number-box">
                    <h1>2017</h1>
                    <p className="common-text">
                      Delivering Excellence <br /> Since
                    </p>
                  </div>

                  <div className="number-box">
                    <h1>120+</h1>
                    <p className="common-text">
                      Created Success <br /> Stories
                    </p>
                  </div>

                  <div className="number-box">
                    <h1>200+</h1>
                    <p className="common-text">
                      Associated <br /> Professionals
                    </p>
                  </div>

                  <div className="number-box">
                    <h1>20+</h1>
                    <p className="common-text">
                      Providing Solutions <br /> Across Industries
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyUs;

import React from "react";
import MegaHeader from "../components/MegaHeader/MegaHeader";
import Footer from "../components/footer/Footer";
import AIContactSection from "../components/ai-contact-section/AIContactSection";
import DevelopingSolution from "../components/developingsolution/DevelopingSolution";
import Testimonial from "../components/testimonila/Testimonial";
import HirePageBanner from "../components/HirePageBanner/HirePageBanner";
import PortFolioData from "../components/PortfolioData/PortFolioData";
import ContactSection from "../components/contactSection/ContactSection";
import { Helmet } from "react-helmet";

const Potfoliio = () => {
  var mainBannerImg = `url(/images/portfolio-banner-bg-image.png)`;

  return (
    <>
      <div className="sticky-header">
        <MegaHeader />
      </div>

      <Helmet>
        <title>
          Portfolio | Explore Bitsclan's Successful Software Projects
        </title>
        <meta
          name="description"
          content="Discover Bitsclan's diverse portfolio of successful software development projects across industries. See how we deliver value and innovation"
        />
      </Helmet>
      <HirePageBanner
        diffBannerBackgroundImage={mainBannerImg}
        mainHeading="Our work, your eyes, it's a love story"
        content="We make difference with better equipment & dedication"
        contactUsLink="/contact-us"
        btnText={"Let's Connect"}
      />

      <PortFolioData />

      <DevelopingSolution heading="Clients Who Trust With Us Their Security" />

      <ContactSection />

      <Footer />
    </>
  );
};

export default Potfoliio;

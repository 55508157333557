import React, { useRef, useState, useCallback } from 'react'
import './style.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Autoplay } from 'swiper/modules';

const Testimonial = ({ heading, content }) => {

    const sliderRef = useRef();

    const [isBeginning, setIsBeginning] = useState(true);
    const [isEnd, setIsEnd] = useState(false);

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);

    const handleSlideChange = (swiper) => {
        setIsBeginning(swiper.isBeginning);
        setIsEnd(swiper.isEnd);
    };


    const testimonials = [
        {
            name: "Amanda H",
            company: "JM Test",
            profileImage: "/images/profile-user.png",
            logoImage: "/images/JM_Test_Systems.jpg",
            text: "Working with Bitsclan has been absolutely vital to our organizational initiatives for web development, website maintenance, email campaigns, graphic design, and digital marketing projects. Not only do they do great work, but they are extremely responsive, accurate, and have been able to help us meet our needs in any capacity we’ve needed."
        },
        {
            name: "Roberto",
            company: "Ratech Services",
            profileImage: "/images/profile-user.png",
            logoImage: "/images/RATECH LOGOBLUE.png",
            text: "I want to thank the team at Bitclan, Fred, Hadi for creating a wonderful website for us. I didn't have a website, but with a coordinated effort by the team, my site looks awesome now. We scheduled project meetings from the beginning until the end so I can provide feedback. The team was professional, hands on and very technical."
        },
        {
            name: "Johnny",
            company: "Song Heart",
            profileImage: "/images/profile-user.png",
            logoImage: "/images/icon-logo.svg",
            text: "Working with Bitsclan and their team has been remarkable. Within a short period of time they were able to understand our vision and execute quickly. They are very organized and communicated regularly and clearly. Everyone is complimenting how professional and clean our website is. Nice to wake up and see all the changes made. We are very happy and look forward to continue working with Bitsclan."
        },
        {
            name: "Gregor Hakkenberg",
            company: "Immogo",
            profileImage: "/images/profile-user.png",
            logoImage: "/images/logo-2.png",
            text: "Ridiculously good service! My very old real estate website with obsolete custom software needed some minor repairs. The job seemed straightforward, but was much more complicated than expected. The Bitsclan team dug into the technical problems, kept at it and finally found the solution. Everything works again and I can continue offering service to my clients."
        }
    ];


    return (
        <>
            <div className="about-testimonial-box" id='testimonial-section'>
                <div className="container h-100">
                    <div className="row h-100">
                        <div className="col-md-5 h-100">
                            <div className="center-testimonial-box">
                                <div className="about-testimonial-content-box">
                                    <h1 className='main-heading mb-0'>{heading}</h1>
                                    <p className="common-text mt-3">Our customers say Excellent <i class="fas fa-star ps-2"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star pe-2"></i> 4.9 / 5 <img src="/images/cluth-logo.svg" alt="" /> </p>
                                    <p className="common-text">{content}</p>
                                </div>

                                <div className="client-brand-images">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4 ps-0">
                                                <div className="clinet-logo-image">
                                                    <a href="https://www.youtube.com/watch?v=LDmv2CrkM1c" target='_blank'>
                                                        <img src="/images/Pyner-logo.svg" alt="Payoneer" />
                                                    </a>
                                                </div>
                                            </div>

                                            <div className="col-xl-2 col-lg 2 col-md-4 col-sm-4 col-4">
                                                <div className="clinet-logo-image" id='testimonial-clutch-widget'>
                                                    <a href="https://clutch.co/profile/bitsclan-it-solutions?utm_source=widget&utm_medium=2&utm_campaign=widget&utm_content=logo&utm_term=bitsclan.com#highlights" target='_blank'>
                                                        <img src="/images/Clutch-logo.svg" alt="Clutch" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <h3 className='about-testimonil-heading'>Client Testimonials</h3>

                                <div className="about-slider-arrow arrow-developing-slider">
                                    <div className="arrow prev-arrow" style={{ marginRight: '0' }}>
                                        <img
                                            onClick={handlePrev}
                                            src="/images/arrow-right.svg"
                                            className={`left-image ${isBeginning ? 'disabled' : ''}`}
                                            alt="Arrow"
                                            style={{ cursor: isBeginning ? 'not-allowed' : 'pointer' }}
                                        />
                                    </div>
                                    <div className="arrow next-arrow" onClick={handleNext} >
                                        <img
                                            onClick={handleNext}
                                            src="/images/arrow-right.svg"
                                            className={`${isEnd ? 'disabled' : ''}`}
                                            alt="Arrow"
                                            style={{ cursor: isEnd ? 'not-allowed' : 'pointer' }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-7 h-100">
                            <div className="center-testimonial-box">
                                <div className="about-testimonial-slider-card ps-md-4">
                                    <Swiper
                                        slidesPerView={'1.3'}
                                        spaceBetween={30}
                                        navigation={false}
                                        autoplay={{
                                            delay: 3000,
                                            disableOnInteraction: false,
                                            pauseOnMouseEnter: true,
                                        }}
                                        modules={[Navigation, Autoplay]}
                                        onSlideChange={handleSlideChange}
                                        className="mySwiper"
                                        ref={sliderRef}
                                        breakpoints={{
                                            425: {
                                                slidesPerView: 1,
                                                spaceBetween: 10,
                                            },
                                            375: {
                                                slidesPerView: 1,
                                                spaceBetween: 10,
                                            },
                                            320: {
                                                slidesPerView: 1,
                                                spaceBetween: 10,
                                            },
                                            780: {
                                                slidesPerView: 1,
                                                spaceBetween: 10,
                                            },
                                            1280: {
                                                slidesPerView: 1.3,
                                                spaceBetween: 10,
                                            },
                                        }}
                                    >
                                        {testimonials.map((testimonial, index) => (
                                            <SwiperSlide key={index}>
                                                <div className="about-testimonial-card">
                                                    <div className="about-test-profile-card">
                                                        <div className="about-testi-image">
                                                            <img src={testimonial.profileImage} alt="Profile" />
                                                        </div>

                                                        <div className="about-prof-text">
                                                            <h2>{testimonial.name}</h2>
                                                        </div>
                                                    </div>
                                                    <p className="common-text quote-images-box"><i class="fa-solid fa-quote-left quote-left"></i> <br /> {testimonial.text} <i class="fa-solid fa-quote-right quote-right"></i></p>
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Testimonial
import React, { useEffect } from "react";
import { bannerList, facts, faqData, hirignDetails, mainBannerImg, servicesData, solutionsData, steps } from "../data/hire-ruby-and-rails.data";
import Footer from "../components/footer/Footer";
import HirePageBanner from "../components/HirePageBanner/HirePageBanner";
import DevelopmentPerformance from "../components/DevelopmentPerformance/DevelopmentPerformance";
import SecondFaq from "../components/secondFaq/SecondFaq";
import { Helmet } from "react-helmet";
import MegaHeader from "../components/MegaHeader/MegaHeader";
import HirePageExperties from "../components/hirepageExperties/HirePageExperties";
import HirePageSteps from "../components/hire-page-steps/HirePageSteps";
import HireExpertFacts from "../components/hire-expert-facts/HireExpertFacts";
import HirePageEngagmentModels from "../components/hire-page-engagment-models/HirePageEngagmentModels";
import HirePageAppIdea from "../components/hire-page-app-idea/HirePageAppIdea";
import ContactSection from "../components/contactSection/ContactSection";
import { schemaData, tabItems, tabs, techCombinations } from "../data/hire-ruby-rails-data";
import TrendsInsights from "../components/trends-n-insights/TrendsInsights";
import HireCombinationSection from "../components/hire-combination-sectiono/HireCombinationSection";
import CoverTheStack from "../components/coverthestack/CoverTheStack";

const HireRubyAndRails = () => {

  return (
    <>
      <Helmet>
        <title>Hire Ruby on Rails Programmers | RoR Development Services</title>
        <meta
          name="description"
          content="Hire expert Ruby on Rails developers for scalable, robust web applications. Tailored RoR development services to fit your business needs."
        />
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
      </Helmet>

      <div className="sticky-header">
        <MegaHeader />
      </div>

      <HirePageBanner
        diffBannerBackgroundImage={mainBannerImg?.backgroundImage}
        image={""}
        mainHeading="Hire Ruby on Rails Developers"
        content="Looking to hire expert Ruby on Rails programmers? Our skilled RoR development team is ready to build scalable, robust, secure, and high-performance web applications tailored to your specific needs."
        btnText="Consult our RoR developers"
        bannerList={bannerList}
      />

      <HirePageExperties
        heading="We Offer Ruby on Rails Development Services"
        discription="At Bitsclan, we craft scalable, secure, and efficient solutions tailored to your business needs. Our RoR developers are ready to support you at every stage of development, including post-launch maintenance. As a leading Ruby on Rails development company, we seamlessly integrate with your projects, allowing you to focus on business growth."
        servicesData={servicesData}
      />

      <DevelopmentPerformance
        heading="Why Choose Us to Hire"
        greenHeading="Ruby on Rails Programmers"
        subHeading="At Bitsclan, we combine Ruby on Rails with the latest frontend and mobile technologies to create powerful, scalable applications that are perfect for your business needs. Whether you’re building a web platform or a mobile solution, our approach ensures fast development, smooth performance, and a seamless user experience."
        solutions={solutionsData}
      />

      <HireCombinationSection combinations={techCombinations} heading="Best RoR Combinations For Exceptional Development" description="Got it! Here's how the sections will be structured for Ruby on Rails, following the same description style as your reference image." />

      <HirePageSteps
        heading={"Hire Ruby On Rails Programmers in 4 Simple Steps"}
        subHeading={
          " Hiring RoR developers has never been easier! At Bitsclan, our streamlined 4-step process ensures you get top talent quickly, efficiently, and seamlessly."
        }
        steps={steps}
      />

      <CoverTheStack
        whereStart={"Languages"}
        mainHeading={"Hire Expert Ruby on Rails Developers for Scalable & Secure Web Solutions"}
        subHeading={"At Bitsclan, we empower your web development journey with a robust Ruby on Rails tech stack that ensures efficiency, scalability, and security. Our experienced RoR developers specialize in building high-performance, custom web applications tailored to your business needs."}
        tabs={tabs}
        tabItems={tabItems} />

      <HireExpertFacts
        heading="Quick Facts About "
        greenHeading="Our Ruby on Rails Team"
        facts={facts}
        diffBannerBackgroundImage={mainBannerImg?.factBackgroundImage}
      />

      <HirePageAppIdea content="Hire skilled Ruby on Rails developers to build scalable, high-performing web applications tailored to your business needs. Our experts specialize in rapid development, ensuring efficient and robust solutions." />
      <HirePageEngagmentModels hirignDetails={hirignDetails} />

      <TrendsInsights specificClass="mobile-dev-page" category="all" />

      <SecondFaq
        faqs={faqData}
        specificClass="mobile-dev-page"
        mainHeading={"Frequently Asked Questions"}
        subHeading={"Discover answers to common questions about Ruby on Rails."}
      />

      <ContactSection />

      <Footer />
    </>
  );
};

export default HireRubyAndRails;

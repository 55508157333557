import React from 'react'
import './style.css'

const UnderStandEngagmentModel = () => {

    const underStandEngagmentModel = [
        {
            id: 1,
            description: "Flexibility to scale your development team as your business grows."
        },
        {
            id: 2,
            description: "Access to a dedicated team of professionals aligned with your project goals."
        },
        {
            id: 3,
            description: "Optimized processes that streamline workflows and reduce overhead costs."
        },
        {
            id: 4,
            description: "Improved project efficiency, leading to faster delivery and better overall outcomes."
        },
        {
            id: 5,
            description: "Adapted to changing business requirements with engagement models that stay flexible."
        },
        {
            id: 6,
            description: "Predictable development costs while maintaining high-quality standards."
        },
        {
            id: 7,
            description: "Long-term partnerships that support continuous growth and innovation."
        }
    ];


    return (
        <>
            <div className="understand-engagment-model-box">
                <div className="container">
                    <div className="row">
                        <div class="col-md-12">
                            <h1 class="main-heading">Understanding Engagement Models</h1>
                            <h3 class="sub-heading mb-3">Finding the right way to collaborate can make all the difference in a project’s success. Our engagement models are designed to give businesses the flexibility to hire tech talent that matches their exact needs. Whether you need dedicated teams, remote developers, or a contract-based hiring approach, we ensure a smooth process that delivers results.</h3>

                            <h3 class="sub-heading">Selecting the best IT outsourcing strategy is key to project efficiency and long-term growth. Our models provide businesses with scalable, reliable, and cost-effective hiring solutions, ensuring seamless execution, optimized costs, and high-quality outcomes.</h3>

                            <h2 className='sub-heading-green'>Benefits of Bitsclan’s Engagement Models:</h2>
                        </div>
                    </div>

                    <div className="row justify-content-center mt-4">
                        {underStandEngagmentModel?.map((item, index) => {
                            return <div className="col-xl-3 col-lg-3 col-md-4 mb-xl-4 mb-lg-4 mb-md-3 mb-sm-4 mb-3" key={index}>
                                <div className="understand-engagmentmodel-card">
                                    <div className="check-icon-tick">
                                        <img src="/images/hire-experts/check-icon-box.svg" alt="Checkmark" />
                                    </div>
                                    <p className="common-text">{item?.description}</p>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default UnderStandEngagmentModel
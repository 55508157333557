import React, { useEffect, useState } from "react";
import "./styles.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function FloatingButtons() {
  const country = useSelector((state) => state.country.name);
  const [showButtons, setShowButtons] = useState(false);
  useEffect(() => {
    const savedForcedRegion = sessionStorage.getItem("forcedRegion");
    if (savedForcedRegion) {
      setShowButtons(true)
    }
  })
  if (country === "PK" && !showButtons) return null;
  return (
    <div className="floating-button-wrapper" style={{ zIndex: 1000 }}>
      <a
        href="mailto:info@bitsclan.com"
        style={{ color: "inherit", textDecoration: "none" }}
      >
        <div className="floating-button">
          <i class="fa-solid fa-envelope"></i>
        </div>
      </a>

      <a
        href="tel:+13156668052"
        style={{ color: "inherit", textDecoration: "none" }}
      >
        <div className="floating-button">
          <i class="fa-solid fa-phone"></i>
        </div>
      </a>

      <Link
        to={"/contact-us"}
        style={{ color: "inherit", textDecoration: "none" }}
      >
        <div className="floating-button">
          <i class="fa-solid fa-headset"></i>
        </div>
      </Link>
    </div>
  );
}

export default FloatingButtons;

import React from "react";
import HirePageBanner from "../components/HirePageBanner/HirePageBanner";
import DevelopmentPerformance from "../components/DevelopmentPerformance/DevelopmentPerformance";
import SecondFaq from "../components/secondFaq/SecondFaq";
import Footer from "../components/footer/Footer";
import MegaHeader from "../components/MegaHeader/MegaHeader";
import { Helmet } from "react-helmet";
import {
  bannerList,
  facts,
  faqData,
  hirignDetails,
  mainBannerImg,
  servicesData,
  solutionsData,
  steps,
  tabItems,
  tabs,
  techCombinations,
} from "../data/hire-javascript-data";
import HirePageExperties from "../components/hirepageExperties/HirePageExperties";
import HirePageSteps from "../components/hire-page-steps/HirePageSteps";
import HireExpertFacts from "../components/hire-expert-facts/HireExpertFacts";
import HirePageEngagmentModels from "../components/hire-page-engagment-models/HirePageEngagmentModels";
import HirePageAppIdea from "../components/hire-page-app-idea/HirePageAppIdea";
import ContactSection from "../components/contactSection/ContactSection";
import TrendsInsights from "../components/trends-n-insights/TrendsInsights";
import HireCombinationSection from "../components/hire-combination-sectiono/HireCombinationSection";
import CoverTheStack from "../components/coverthestack/CoverTheStack";

const HireJavaScript = () => {
  return (
    <>
      {/* <Header /> */}
      <Helmet>
        <title>
          Hire Javascript Developers | Javascipt Development Services
        </title>
        <meta
          name="description"
          content="Hire professional JavaScript developers for dynamic, responsive web apps. Expert JavaScript services tailored to your project goals."
        />
      </Helmet>

      <div className="sticky-header">
        <MegaHeader />
      </div>

      <HirePageBanner
        diffBannerBackgroundImage={mainBannerImg?.backgroundImage}
        image={""}
        mainHeading="Hire Javascript Developers"
        content=" Looking for skilled JavaScript developers? Bitsclan provides top remote JavaScript developers, perfect for startups and enterprises seeking high-performance web solutions."
        btnText="Consult our javascript developers"
        bannerList={bannerList}
      />

      <HirePageExperties
        heading="Our JavaScript Developer Expertise"
        discription=" At Bitsclan, our JavaScript developers specialize in building dynamic, scalable, and high-performance web applications. We ensure seamless functionality, smooth user experiences, and cutting-edge solutions for your business."
        servicesData={servicesData}
      />

      <DevelopmentPerformance
        heading="Why Hire JavaScript Developers "
        greenHeading="from Bitsclan?"
        subHeading="At Bitsclan, we specialize in building high-performance applications by integrating JavaScript with modern frontend, backend, and mobile technologies."
        solutions={solutionsData}
      />

      <HireCombinationSection combinations={techCombinations} heading="Top JavaScript Combinations for Outstanding Development" description="Unlock the full potential of JavaScript by leveraging the most effective technology combinations. Hire expert JavaScript developers to build high-performing, scalable, and innovative solutions tailored to your needs." />

      <HirePageSteps
        heading={"Hire JavaScript Developers in 4 Easy Steps"}
        subHeading={
          "Hiring expert JavaScript developers has never been easier. Follow our simple 4-step process to find the perfect developers for your project."
        }
        steps={steps}
      />

      <CoverTheStack
        whereStart={"Languages & Frameworks"}
        mainHeading={"Hire Expert JavaScript Developers for Dynamic & Scalable Web Solutions"}
        subHeading={"At Bitsclan, we empower your web development journey with a cutting-edge JavaScript tech stack that ensures interactivity, scalability, and performance. Our skilled JavaScript developers specialize in building responsive, high-performance web applications tailored to your business needs."}
        tabs={tabs}
        tabItems={tabItems} />

      <HireExpertFacts
        heading="Quick Facts About "
        greenHeading="Our JavaScript Team"
        facts={facts}
        diffBannerBackgroundImage={mainBannerImg?.factBackgroundImage}
      />

      <HirePageAppIdea content="Have an idea but don’t know where to start? Our JavaScript experts can help you build a working prototype in just 6-8 weeks! Validate your vision, test user engagement, and create a solid foundation for success." />

      <HirePageEngagmentModels description="Choose the engagement model that fits your project needs! Whether you need full-time, part-time, or hourly developers, we offer flexible options to ensure seamless collaboration and maximum efficiency throughout your project." hirignDetails={hirignDetails} />

      <TrendsInsights specificClass="mobile-dev-page" category="all" />

      <SecondFaq
        faqs={faqData}
        specificClass="mobile-dev-page"
        mainHeading={"Frequently Asked Questions"}
        subHeading={
          "Explore our Javascript development services for tailored web solutions, API creation, and deployment management!"
        }
      />

      <ContactSection />

      <Footer />
    </>
  );
};

export default HireJavaScript;

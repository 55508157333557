import React from 'react'
import './style.css'
import { Link } from 'react-router-dom'

const HirePageEngagmentModels = ({ hirignDetails, description }) => {
    return (
        <>
            <div className="guaranteed-results hire-page-engagment-modes-box">
                <div className="container">
                    <div className="row">
                        <div class="col-md-12">
                            <h1 class="guaranteed-results-heading">Our Flexible <span>Engagement Models</span></h1>
                            <p class="common-text">{description || `At Bitsclan, we offer flexible engagement models based on your specific needs. Choose from Full-Time, Part-Time, or Hourly options. Whether you need a single RoR developer for hire or a dedicated team, we ensure seamless collaboration and high efficiency for your project.`}</p>
                        </div>
                    </div>

                    <div className="row mt-5">
                        {hirignDetails?.map((card, index) => {
                            return <div className="col-xl-4 col-lg-4 col-md-6 mb-md-4 bottom-space-20">
                                <div className="hire-page-hiring-card" style={{ backgroundColor: `${card?.bgColor}` }} key={index}>
                                    <div className="hire-page-hiring-card-top-section">
                                        <div className="hire-page-content-text">
                                            <h3>{card?.text}</h3>
                                            <p>Start work in {card?.hours} hours</p>
                                        </div>

                                        <div className="hire-page-icon-text">
                                            <img src={`/images/hire-page-hiring-card-iocns/${card?.icon}`} alt={`${card?.icon}`} />
                                        </div>
                                    </div>

                                    <div className="hire-page-hiring-card-top-section">
                                        <div className="hire-page-content-text">
                                            <p>Duration</p>
                                            <h4>8 Hrs/Day</h4>
                                        </div>

                                        <div className="hire-page-hiring-card-icon">
                                            <img src="/images/hire-page-hiring-card-iocns/duration.svg" alt="duration" />
                                        </div>
                                    </div>

                                    <div className="hire-page-hiring-card-top-section">
                                        <div className="hire-page-content-text">
                                            <p>Minimum Days</p>
                                            <h4>30 Days</h4>
                                        </div>

                                        <div className="hire-page-hiring-card-icon">
                                            <img src="/images/hire-page-hiring-card-iocns/calender.svg" alt="calender" />
                                        </div>
                                    </div>

                                    <div className="hire-page-hiring-card-top-section rounded-10">
                                        <div className="hire-page-content-text">
                                            <p>Billing</p>
                                            <h4>Monthly</h4>
                                        </div>

                                        <div className="hire-page-hiring-card-icon">
                                            <img src="/images/hire-page-hiring-card-iocns/list.svg" alt="list" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>

                    <div className="row mt-md-5 mt-xl-5 mt-lg-5">
                        <div class="col-md-12">
                            <div class="hire-us-cta-btn d-flex justify-content-center">
                                <Link id='hire-experts-engagement-modal-click' to="/hire-contact" class="ms-0">Get Started Now</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HirePageEngagmentModels
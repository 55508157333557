import React, { useEffect } from "react";
import SimilerBanner from "../components/similerBanner/SimilerBanner";
import DevelopingSolution from "../components/developingsolution/DevelopingSolution";
import Testimonial from "../components/testimonila/Testimonial";
import CoverTheStack from "../components/coverthestack/CoverTheStack";
import SecondFaq from "../components/secondFaq/SecondFaq";
import Footer from "../components/footer/Footer";
import { Helmet } from "react-helmet";
import MegaHeader from "../components/MegaHeader/MegaHeader";
import TrendsInsights from "../components/trends-n-insights/TrendsInsights";
import RoadMap from "../components/roadMap/RoadMap";
import WhoWeServe from "../components/who-we-serve/WhoWeServe";
import HighValues from "../components/high-values/HighValues";
import TypesOfBuild from "../components/types-of-build/TypesOfBuild";
import WhyChooseBitsclan from "../components/why-choose-bitsclan/WhyChooseBitsclan";
import IndustriesTabs from "../components/industries-tabs/IndustriesTabs";
import DevelopmentServices from "../components/development-services/DevelopmentServices";
import ContactSection from "../components/contactSection/ContactSection";
import {
  WebServicesFaqData,
  WebServicesHighvaluesData,
  WebServicesIndustryTabsData,
  WebServicesIndustryTabsItemsData,
} from "../data/web-app-service-page";
import NewEngagementModels from "../components/new-engagment-modal";
import { DevelopmentServicesData, mainBannerImg, schemaData, tabItems, tabs, TypesOfBuildData, WebAppservicesRoadMap, WhoWeServeData, WhyChooseBitsclanData } from "../data/web-app-services-data";

const MobileDevelopmentServices = () => {

  return (
    <>
      <Helmet>
        <title>
          Bespoke Web App Development Services | Bitsclan IT Solutions
        </title>
        <meta
          name="description"
          content="Build robust, user-friendly web apps with our expert web app development services designed for performance and scalability"
        />
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
      </Helmet>

      <div className="sticky-header">
        <MegaHeader />
      </div>

      <SimilerBanner
        diffBannerBackgroundImage={mainBannerImg}
        mainHeading={"Web App Development"}
        greenHeading="Services"
        subHeading={"Trusted by Startups, SMBs, and Enterprises alike"}
        content={
          "As a leading web app development company, Bitsclan IT Solutions provides top-notch web application development services to help you achieve your business goals. We build robust, secure, scalable web applications ensuring reliability and seamless integration."
        }
        btnText2={"Let's Get Started"}
        sideImage={""}
      />

      <Testimonial
        heading="Web Development Company of the Year 2021"
        content="We received the Best Web Development Company of the Year award from Payoneer in 2021. We bring the same expertise, dedication, vision, and conviction to every service we provide."
      />

      <DevelopmentServices
        tabs={DevelopmentServicesData}
        heading="Our Expansive Range of End-to-end Web App Development Services"
        subheading="Our web application solutions cover everything from custom cloud platforms and interactive portals to seamless front-end and robust back-end development. We deliver secure, scalable solutions designed to evolve with your business, including expert consulting and ongoing support."
      />

      <TypesOfBuild
        typeOfApps={TypesOfBuildData}
        heading="Key Features of High-Quality Web Applications"
        sideImage="web-app-development-2.png"
      />

      <WhoWeServe
        cardContent={WhoWeServeData}
        heading="Who We Serve"
        subHeading="With our advanced web app development services, we empower businesses to harness the latest technologies. Whether it’s Artificial Intelligence, Internet of Things, or Blockchain, we build solutions that transform ideas into impactful realities. No matter your industry, we deliver results tailored to your needs and vision."
      />

      <WhyChooseBitsclan
        tabs={WhyChooseBitsclanData}
        heading="Why Choose Bitsclan as Your Web App Development Company?"
        subheading="At Bitsclan, we redefine what it means to build powerful, scalable web applications. Our expertise lies in crafting custom web app solutions that align seamlessly with your business goals. Whether it’s designing modern web apps from scratch or upgrading legacy systems, we ensure your success at every step."
      />

      <HighValues
        heading="High-Value Features for High-Performance Apps"
        subHeading="Delivering exceptional web applications is about combining innovation, expertise, and functionality. At Bitsclan, we ensure your web app includes the features that drive performance, user engagement, and scalability."
        boxContent={WebServicesHighvaluesData}
        btnLink="#"
        btnText="Consult Project Scope"
      />

      <NewEngagementModels />

      <RoadMap
        heading={"Our Proven Roadmap to Ensure Your Success"}
        description={
          "As a trusted web app development partner, we have crafted a structured roadmap to guarantee your project's success. From discovery to deployment, we align every phase with your business objectives, delivering scalable web applications, modern web apps, and tailored web application solutions. Here's how we do it:"
        }
        roadMapHeading={[]}
        roadMapsteps={WebAppservicesRoadMap}
      />

      <CoverTheStack
        whereStart={"Frontend"}
        mainHeading={"Build Scalable & High-Performance Web Apps with Expert Developers"}
        subHeading={"At Bitsclan, we empower your web app development journey with a cutting-edge tech stack that ensures seamless performance, scalability, and innovation. Our expert developers specialize in crafting custom web applications tailored to your business needs, delivering exceptional user experiences and robust functionality."}
        tabs={tabs}
        tabItems={tabItems}
      />

      <IndustriesTabs
        mainheading={"Industries we serve in Web App Development"}
        tabs={WebServicesIndustryTabsData}
        defaultTab={"Healthcare"}
        tabsItems={WebServicesIndustryTabsItemsData}
      />
      <DevelopingSolution
        heading="Developing Solutions That Transcend Mere Digital Transformation"
        subheading="We deliver innovative solutions that surpass digital transformation, driving impactful and lasting success."
      />

      <TrendsInsights specificClass="mobile-dev-page" category="web-developmet" />

      <SecondFaq
        specificClass="mobile-dev-page"
        faqs={WebServicesFaqData}
        mainHeading={"FAQs"}
        subHeading={
          "Get quick answers to your questions about our mobile app development process and services."
        }
      />

      <ContactSection />
      <Footer />
    </>
  );
};

export default MobileDevelopmentServices;

import { configureStore } from "@reduxjs/toolkit";
import countryReducer from "./slices/countrySlice";
import contactFormApiSliceReducer from "./slices/ContactFormApiSlice"

export const store = configureStore({
  reducer: {
    country: countryReducer,
    contactFormApiSlice: contactFormApiSliceReducer
  },
});

export default store;

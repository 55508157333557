import React, { useRef, useCallback } from 'react'
import './style.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Autoplay } from 'swiper/modules';

const MainBanner = () => {
    return (
        <>
            <div className="main-banner-box banner-bg-image">
                <video
                    className="video-bg"
                    autoPlay
                    muted
                    loop
                    playsInline
                >
                    <source src="videos/home-banner.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>

                <div className="container">
                    <div className="row flex-convert-box">
                        <div className="col-md-6">
                            <h3 className="common-text">The Best Development Company Awarded By  <img src="/images/payoneer-circle.png" alt="Payoneer Logo" /> Payoneer</h3>
                            <h1 className="banner-heading main-banner-heading">Your Catalyst in Creating
                                Disruptive <span>AI/ML</span> & <span>Data
                                    Solutions</span></h1>

                            <h2 className='banner-sub-heading'>Results-driven services and robust AI/ML & Blockchain solutions for Startups, SMBs, and Enterprises looking to maximize returns on IT investments.
                            </h2>

                            <div className="mt-5">
                                <a href="https://calendly.com/bitsclansolutions/15min" target='_blank' className='banner-button-box'>Let's Schedule a Call</a>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="banner-vedio-icon-image">
                                <span className='vedio-icon-border'></span>
                                <img data-bs-toggle="modal" href="#bannermodel" role="button" src="/images/vedio-icon.svg" alt="Vedio Icon" />
                                <span className='vedio-icon-border-2'></span>
                            </div>
                        </div>


                        <div class="modal fade" id="bannermodel" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
                            <div class="modal-dialog banner-model-box modal-dialog-centered">
                                <div class="modal-content">

                                    <div class="modal-header">
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class="fa-solid fa-xmark"></i></button>
                                    </div>

                                    <div class="modal-body">
                                        <div className="banner-vedio-side-image">
                                            <iframe
                                                src="https://www.youtube.com/embed/gga9KkIwusw?autoplay=1&controls=0&mute=1&loop=1&playlist=gga9KkIwusw"
                                                title="YouTube video player"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                referrerPolicy="strict-origin-when-cross-origin"
                                                allowFullScreen
                                            ></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MainBanner
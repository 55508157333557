import React from 'react'
import './style.css'

const PerkAndBenifit = () => {

    const benefits = [
        {
            title: "Provident Fund",
            description: "Secure your future with our provident fund plan, helping you build a solid financial foundation.",
            icon: "prek-iocn1.svg"
        },
        {
            title: "Health Insurance",
            description: "Your health and well-being are our priority. We provide comprehensive health insurance to cover your medical needs.",
            icon: "prek-iocn2.svg"
        },
        {
            title: "Leave Encashments",
            description: "Get rewarded for your unused leaves with our leave encashment policy, turning your time into additional income.",
            icon: "prek-iocn3.svg"
        },
        {
            title: "Paid Leaves",
            description: "Take the time you need with our paid leave policy, whether it's for personal matters or just some well-deserved rest.",
            icon: "prek-iocn4.svg"
        },
        {
            title: "Paid Overtime",
            description: "Your extra effort doesn't go unnoticed. We offer paid overtime to compensate you for the additional hours you put in.",
            icon: "prek-iocn5.svg"
        },
        {
            title: "Mobile Allowance",
            description: "Stay connected with our mobile allowance, ensuring you have the tools you need to excel in your role.",
            icon: "prek-iocn6.svg"
        },
        {
            title: "Annual Dinners",
            description: "Celebrate our successes together with annual dinners, where we come together to recognize achievements and enjoy each other's company.",
            icon: "prek-iocn7.svg"
        },
        {
            title: "Birthday & Anniversary",
            description: "We believe in celebrating you! From birthdays to work anniversaries, we make sure these special moments are recognized and cherished.",
            icon: "prek-iocn8.svg"
        }
    ]


    return (
        <>
            <div className="perks-and-benifits">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className='banner-heading text-center'>Perks & <span>Benefits</span></h1>
                            <p className="common-text text-center text-white">We believe that our people are our greatest asset, and we’re committed to providing a supportive and rewarding work environment. We offer a range of perks and benefits designed to enhance your well-being, recognize your hard work, and celebrate your milestones. When you join Bitsclan, you’re not just gaining a job—you’re joining a community that values your contributions and supports your growth.</p>
                        </div>
                    </div>

                    <div className="row perk-icon-leftside mt-5">
                        {
                            benefits.map((item) => {
                                return <div className="col-xl-3 col-lg-3 col-md-6 border-cards p-0">
                                    <div className="perk-card">
                                        <div className="perk-icon">
                                            <img src={`/images/${item.icon}`} alt={`${item.icon}`} />
                                        </div>
                                        <h3>{item.title}</h3>
                                        <p className='common-text'>{item.description}</p>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default PerkAndBenifit
export const tabs = [
  "Backend",
  "Databases",
  "Cloud & DevOps",
  "Testing",
  "Tools & Utilities",
];

export const tabItems = [
  { tab: "Backend", name: "Express.js", icon: "/images/database5.png", },
  { tab: "Backend", icon: "/images/database2.png", name: "Nest Js" },
  { tab: "Databases", name: "MongoDB", icon: "/images/db3.png", },
  { tab: "Databases", name: "PostgreSQL", icon: "/images/tech-stack-icons/postgres.svg", },
  { tab: "Databases", name: "MySQL", icon: "/images/db5.png", },
  { tab: "Cloud & DevOps", name: "AWS", icon: "/images/cloud3.png", },
  { tab: "Cloud & DevOps", name: "Docker", icon: "/images/tech-stack-icons/docker.svg", },
  { tab: "Cloud & DevOps", name: "Kubernetes", icon: "/images/tech-stack-icons/kubernetes.svg" },
  { tab: "Testing", icon: "/images/tech-stack-icons/postman.svg", name: "Postman" },
  { tab: "Testing", icon: "/images/tech-stack-icons/seaborn.svg", name: "Jmeter" },
  { tab: "Testing", icon: "/images/tech-stack-icons/selenium.svg", name: "Selenium" },
  { tab: "Testing", icon: "/images/tech-stack-icons/cypress.svg", name: "Cypress" },
  { tab: "Tools & Utilities", name: "TypeScript", icon: "/images/tech-stack-icons/typescript.svg", },
  { tab: "Tools & Utilities", name: "GraphQL", icon: "/images/tech-stack-icons/graphql.svg", },
];

export const techCombinations = [
  {
    title: "Node.js + React + MongoDB",
    description:
      "Node.js as the backend with React on the front end and MongoDB as the database helps build fast, scalable, and real-time web applications.",
    icons: ["nodejs.svg", "react.svg", "mongodb.svg"],
  },
  {
    title: "Node.js + Angular + MySQL",
    description:
      "Angular for a structured front-end, Node.js for a high-performance backend, and MySQL as the database create a powerful full-stack web solution.",
    icons: ["nodejs.svg", "angular.svg", "mysql-whale-icon.svg"],
  },
  {
    title: "Node.js + Vue.js + PostgreSQL",
    description:
      "Vue.js on the front end, Node.js as the backend, and PostgreSQL as a database ensure secure and efficient development of modern applications.",
    icons: ["nodejs.svg", "vue-js.svg", "postgres.svg"],
  },
  {
    title: "Node.js + GraphQL + DynamoDB",
    description:
      "GraphQL enables efficient data querying, Node.js manages the server logic, and DynamoDB offers scalable NoSQL storage for highly interactive applications.",
    icons: ["nodejs.svg", "graphql.svg", "dynamodb.svg"],
  },
];


export const HireNodejsBannerList = [
  { list: "Freelance & Full-Time Developers" },
  { list: "Flexible Hiring Models" },
  { list: "Global Talent Pool" },
];

export var mainBannerImg = {
  backgroundImage: `url(/images/hire-page-images/hire-node.png)`,
  factBackgroundImage: `url(/images/hire-page-images/hire-node.svg)`,
};

export const HireNodejsServicesData = [
  {
    title: "Node.js Consulting",
    description:
      "Get expert guidance on architecture, performance optimization, and scalability to maximize the potential of your Node.js applications.",
    icon: "/images/hire-page-icons/hrie-node-icons1.svg",
  },
  {
    title: "Node.js Web Development",
    description:
      "We build feature-rich, responsive, and scalable web apps using Node.js, ensuring smooth functionality across all devices and platforms.",
    icon: "/images/hire-page-icons/hrie-node-icons2.svg",
  },
  {
    title: "Node.js API Development",
    description:
      "Our developers create robust and efficient APIs, enabling seamless communication between applications and enhancing system connectivity.",
    icon: "/images/hire-page-icons/hrie-node-icons3.svg",
  },
  {
    title: "Node.js CMS and Portal Development",
    description:
      "Develop custom CMS and web portals that are intuitive, scalable, and easy to manage, designed to fit your business needs.",
    icon: "/images/hire-page-icons/hrie-node-icons4.svg",
  },
  {
    title: "Migration to Node.js",
    description:
      "Seamlessly migrate your existing applications to Node.js with minimal downtime, ensuring improved performance and maintainability.",
    icon: "/images/hire-page-icons/hrie-node-icons5.svg",
  },
  {
    title: "Integration with Existing Solutions",
    description:
      "We specialize in integrating Node.js applications with third-party services and platforms, creating a unified and streamlined system.",
    icon: "/images/hire-page-icons/hrie-node-icons6.svg",
  },
  {
    title: "Node.js eCommerce Solutions",
    description:
      "Build secure, high-performance eCommerce platforms that offer a seamless shopping experience, scalability, and enhanced security.",
    icon: "/images/hire-page-icons/hrie-node-icons7.svg",
  },
  {
    title: "Node.js Support & Maintenance",
    description:
      "Ensure optimal performance with our ongoing support and maintenance services, keeping your app updated, secure, and running smoothly.",
    icon: "/images/hire-page-icons/hrie-node-icons8.svg",
  },
];

export const HireNodejsSolutionsData = [
  {
    icon: "/images/hire-page-icons/chose-ror-section1.svg",
    title: "Access Vetted Node.js Experts",
    description:
      "Hire top-tier Node.js developers who are pre-vetted for their technical expertise, problem-solving skills, and English fluency, ensuring clear communication and high-quality development.",
  },
  {
    icon: "/images/hire-page-icons/chose-ror-section2.svg",
    title: "Find the Right Match Instantly",
    description:
      "No more wasting time on endless resumes! Our AI-driven hiring process instantly connects you with the best Node.js developers, making hiring fast, easy, and hassle-free.",
  },
  {
    icon: "/images/hire-page-icons/chose-ror-section3.svg",
    title: "Save Big with Global Talent",
    description:
      "Tap into a network of 450,000+ developers across 190+ countries and cut hiring costs by up to 58%—without compromising on quality.",
    noBorder: true,
  },
  {
    icon: "/images/hire-page-icons/chose-ror-section4.svg",
    title: "Get Personalized Hiring Support",
    description:
      "Finding the right developer can be challenging. That’s why our expert recruiters guide you through every step, ensuring you make the best hiring decision with confidence.",
  },
];

export const HireNodejsStacksdata = [
  {
    title: "Junior Ruby on Rails Developer",
    description: [
      "2 Years' experience",
      "Fresh perspectives & innovative ideas",
      "Dedicated to explore web development",
    ],
    linkText: "Connect to Hire",
    link: "#",
    borderClass: "border-1",
  },
  {
    title: "Senior Ruby on Rails Developer",
    description: [
      "2+ years' experience",
      "Leadership in complex projects",
      "Proven app deployment success",
    ],
    linkText: "Connect to Hire",
    link: "#",
    borderClass: "border-2",
  },
  {
    title: "Ruby on Rails Tech Lead",
    description: [
      "5+ years' experience",
      "Skilled with Jira, Asana, MS Teams, etc.",
      "Suitable when you Hire Web App Team",
    ],
    linkText: "Connect to Hire",
    link: "#",
    borderClass: "border-3",
  },
];

export const HireNodejsBenefitsData = [
  {
    service: "Android CMS Development",
    description:
      "Get an upper hand on your web content, augment efficiency, and develop a shared environment with our Ruby on Rails CMS solutions.",
  },
  {
    service: "Ruby on Rails Migrations",
    description:
      "Our Ruby on Rails engineers strategically migrate legacy content into modern systems in a secured, erAndroid-free, and robust way.",
  },
  {
    service: "Advanced Android Programming",
    description:
      "Our experts have extensive experience in using Android tech stack for tangible results to reach your business vision.",
  },
  {
    service: "Android Integration and Optimization",
    description:
      "Leverage the full-scale potential of Ruby on Rails integration and optimization functionalities for your app development needs.",
  },
  {
    service: "Customized Android Solutions",
    description:
      "Our Ruby on Rails developers delve into ideation, reinvention, and scale development as per your enterprise's unique IT demands.",
  },
  {
    service: "Android eCommerce App",
    description:
      "With using modern Android tools and technologies, we have a proven track record of developing scalable and secured eCommerce sites.",
  },
];

export const HireNodejsSteps = [
  {
    title: "Choose your Engagement Model",
    description:
      "Select a hiring model that aligns with your project needs—Full-Time, Part-Time, or Hourly. We offer flexible options to match your budget and timeline.",
    icon: "/images/hire-page-setps-icons/icon-1.svg",
  },
  {
    title: "Screen & Select Node.js Developers",
    description:
      "Get access to a curated list of top Node.js developers. Review their profiles, experience, and skills to find the best fit for your project.",
    icon: "/images/hire-page-setps-icons/icon-2.svg",
  },
  {
    title: "Conduct One-on-One Interview",
    description:
      "Assess shortlisted candidates through technical interviews and live coding tests. Evaluate their problem-solving skills, experience, and cultural fit.",
    icon: "/images/hire-page-setps-icons/icon-3.svg",
  },
  {
    title: "Seamless Onboarding in 24-48 Hours",
    description: "Once you’ve made your choice, your Node.js developer joins your team within 24-48 hours—ensuring a fast, smooth start to your project.",
    icon: "/images/hire-page-setps-icons/icon-4.svg",
  },
];

export const HireNodejsFactsData = [
  {
    text: "Expert Node.js Developers – Our team consists of highly skilled professionals specializing in scalable backend development with Node.js.",
  },
  {
    text: " Enterprise-Grade Solutions – We build powerful backend systems that support everything from small apps to large-scale enterprise platforms.",
  },
  { text: "Deep JavaScript & Node.js Expertise – Our developers are proficient in Node.js frameworks and modern JavaScript for fast, reliable application development." },
  {
    text: " Seamless API Development – We design and optimize APIs, ensuring your applications integrate smoothly with third-party services and platforms.",
  },
  { text: "High-Performance, Real-Time Apps – Our team excels at developing real-time applications that scale effortlessly as your business grows." },
  { text: "Full-System Integration – We specialize in connecting Node.js applications with databases, cloud services, and external tools for seamless compatibility." },
];

export const HireNodejsWhoWeServeData = [
  {
    icon: "/images/servie-icon/startups.png",
    title: "Budget savings",
    description:
      "Assembling a local Ruby on Rails team is unavoidably expensive. By approaching Innowise. you bypass many hurdles and avoid redundantexpenses such as training fees. taxes. and software licenses.",
    link: "#",
    borderClass: "border-1",
  },
  {
    icon: "/images/servie-icon/companies.png",
    title: "Software product companies",
    description:
      "When you hire Android developers. you avoid the risks of budget overruns and missed deadlines. Innowise provides solid risk management plans and has an array of risk mitigation measures",
    link: "#",
    borderClass: "border-2",
  },
  {
    icon: "/images/servie-icon/industries.png",
    title: "Enterprises from 30+ industries",
    description:
      "Innowise has experts with excellent development backgrounds. so you can rest assured that your project is in good hands. Pyrggyelgprneg",
    link: "#",
    borderClass: "border-3",
  },
];

export const HireNodejsHirignDetails = [
  {
    text: "Hourly Hiring",
    hours: "48",
    icon: "time.svg",
    bgColor: "#FDFDFF",
  },

  {
    text: "Full Time Hiring",
    hours: "72",
    icon: "full-time.svg",
    bgColor: "#FFFEFA",
  },

  {
    text: "Part Time Hiring",
    hours: "48",
    icon: "part-time.svg",
    bgColor: "#FDFFFA",
  },
];
export const HireNodejsPageDeliverData = [
  {
    image: "/images/hire-page-images/card-1.png",
    title: "Complex SaaS systems",
    description:
      "Innowise provides its expertise in the area of building complex platforms for businesses to streamline workflows. The main applications we develop include HRM, CRM, ERP, accounting software, etc.",
  },
  {
    image: "/images/hire-page-images/card-2.png", // Assumed path based on provided example
    title: "Complex SaaS systems",
    description:
      "Innowise provides its expertise in the area of building complex platforms for businesses to streamline workflows. The main applications we develop include HRM, CRM, ERP, accounting software, etc.",
  },
  {
    image: "/images/hire-page-images/card-3.png", // Assumed path based on provided example
    title: "Complex SaaS systems",
    description:
      "Innowise provides its expertise in the area of building complex platforms for businesses to streamline workflows. The main applications we develop include HRM, CRM, ERP, accounting software, etc.",
  },
];

export const HireNodejsSlidesData = [
  {
    title: "Experienced Android Developers",
    content:
      "Our team consists of senior Android developers who have extensive experience in building sophisticated web applications across various industries.",
    backgroundClass: "bg-1",
  },
  {
    title: "Remote and On-Demand",
    content:
      "Need flexibility? Hire remote Android developers from Bitsclan for seamless project execution without geographic limitations.",
    backgroundClass: "bg-2",
  },
  {
    title: "Agile Development Approach",
    content:
      "We follow agile methodologies to ensure rapid delivery, transparency, and adaptability throughout the development process.",
    backgroundClass: "bg-3",
  },
  {
    title: "Automation Solutions",
    content:
      "We combine AI automation with Robotic Process Automation to boost operational efficiency, reduce operational costs, and minimize human error, allowing you to focus on strategic initiatives.",
    backgroundClass: "bg-4",
  },
];

export const HireNodejsFaqData = [
  {
    id: "collapseOne",
    question: "What is Node.js used for?",
    answer:
      " Node.js is commonly used for building fast, scalable backend applications. It is perfect for creating real-time applications, APIs, and backend services that can handle large volumes of requests.",
  },
  {
    id: "collapseTwo",
    question: "Why should I hire Node.js developers from Arc?",
    answer:
      "Our Node.js developers are experts at creating fast, scalable backend systems. They have the skills to ensure your application performs well and integrates seamlessly with other services.",
  },
  {
    id: "collapseThree",
    question: "How long does it take to develop a Node.js application?",
    answer:
      "The timeline depends on the complexity of the project. Small to medium apps can take a few weeks, while larger systems may take a few months. We work with you to set realistic deadlines.",
  },
  {
    id: "fourth",
    question: "Can you migrate my existing app to Node.js?",
    answer:
      " Yes! Our Node.js experts can help you migrate your existing applications to Node.js, ensuring the transition is smooth and your app continues to run efficiently.",
  },
  {
    id: "fifth",
    question: "What kind of apps can be built with Node.js? ",
    answer:
      "You can build a wide range of applications using Node.js, including eCommerce platforms, real-time chat applications, APIs, CMS, and more. It’s great for high-performance, real-time apps.",
  },
  {
    id: "sixth",
    question: "How does Node.js improve app performance?",
    answer:
      "Node.js is fast and lightweight. It uses a non-blocking, event-driven architecture, which helps it handle many requests simultaneously. This makes your app faster and more scalable.",
  },
];

import React, { useEffect } from 'react'
import tabData from '../data/industryData';
import Header from '../components/header/Header'
import MainBanner from '../components/banner/MainBanner'
import HireUs from '../components/hireUs/HireUs'
import WhyUs from '../components/whyus/WhyUs'
import CoverTheStack from '../components/coverthestack/CoverTheStack'
import EngagmentModels from '../components/engagmentModels/EngagmentModels'
import DevelopingSolution from '../components/developingsolution/DevelopingSolution'
import PioneeringChange from '../components/pioneeringChange/PioneeringChange'
import TypeIndustries from '../components/typeIndustry/TypeIndustries'
import ContactSection from '../components/contactSection/ContactSection'
import OurLocations from '../components/ourlocations/OurLocations'
import Footer from '../components/footer/Footer'
import { Helmet } from 'react-helmet'
import MegaHeader from '../components/MegaHeader/MegaHeader'
import BrandImages from '../components/brandImages/BrandImages'
import HomeLogo from '../components/home-logos/HomeLogo'
import ParalexEffectSlider from '../components/ParalexEffectSlider/ParalexEffectSlider'

const Home = () => {

    const tabs = [
        "Database",
        "Backend",
        "Frontend",
        "Mobile",
        "Frameworks",
        "Cloud",
        "DevOps",
        "Ecommerce",
        "Platforms"
    ];

    const tabItems = [
        { tab: 'Frontend', icon: '/images/iconone.png', name: 'Angular JS' },
        { tab: 'Frontend', icon: '/images/icontwo.png', name: 'JavaScript' },
        { tab: 'Frontend', icon: '/images/iconthree.png', name: 'React JS' },
        { tab: 'Frontend', icon: '/images/icon4.png', name: 'Vue JS' },
        { tab: 'Frontend', icon: '/images/icon5.png', name: 'Tez JS' },
        { tab: 'Frontend', icon: '/images/icon6.png', name: 'CSS3' },
        { tab: 'Frontend', icon: '/images/icon7.png', name: 'HTML5' },
        { tab: 'Backend', icon: '/images/database1.png', name: '.Net' },
        { tab: 'Backend', icon: '/images/database2.png', name: 'Nest Js' },
        { tab: 'Backend', icon: '/images/database3.png', name: 'Meteor JS' },
        { tab: 'Backend', icon: '/images/database4.png', name: 'Fastify' },
        { tab: 'Backend', icon: '/images/database5.png', name: 'Express JS' },
        { tab: 'Backend', icon: '/images/database6.png', name: 'Python' },
        { tab: 'Backend', icon: '/images/database7.png', name: 'Java' },
        { tab: 'Backend', icon: '/images/database8.png', name: 'PHP' },
        { tab: 'Backend', icon: '/images/database9.png', name: 'Node Js' },
        { tab: 'Mobile', icon: '/images/mobile1.png', name: 'Android' },
        { tab: 'Mobile', icon: '/images/mobile2.png', name: 'IOS' },
        { tab: 'Mobile', icon: '/images/mobile3.png', name: 'Swift' },
        { tab: 'Mobile', icon: '/images/mobile4.png', name: 'Flutter' },
        { tab: 'Mobile', icon: '/images/mobile5.png', name: 'Xamarin' },
        { tab: 'Mobile', icon: '/images/mobile6.png', name: 'React Native' },
        { tab: 'Database', icon: '/images/db1.png', name: 'Firebase' },
        { tab: 'Database', icon: '/images/db2.png', name: 'Oracle ' },
        { tab: 'Database', icon: '/images/db3.png', name: 'MongoDB' },
        { tab: 'Database', icon: '/images/tech-stack-icons/postgres.svg', name: 'PostgreSQL' },
        { tab: 'Database', icon: '/images/db5.png', name: 'MySQL' },
        { tab: 'Database', icon: '/images/db6.png', name: 'SQL Server' },
        { tab: 'Database', icon: '/images/db7.png', name: 'SQLite' },
        { tab: 'Database', icon: '/images/db8.png', name: 'DynamoDB' },
        { tab: 'Frameworks', icon: '/images/framework6.png', name: 'Django' },
        { tab: 'Frameworks', icon: '/images/framework2.png', name: 'CodeIgniter' },
        { tab: 'Frameworks', icon: '/images/framework3.png', name: 'Laravel' },
        { tab: 'Frameworks', icon: '/images/framework1.png', name: 'MEAN' },
        { tab: 'Frameworks', icon: '/images/framework4.png', name: 'Rxweb.io' },
        { tab: 'Frameworks', icon: '/images/framework5.png', name: 'CakePHP' },
        { tab: 'Cloud', icon: '/images/cloud1.png', name: 'Google Cloud' },
        { tab: 'Cloud', icon: '/images/cloud2.png', name: 'Azure' },
        { tab: 'Cloud', icon: '/images/cloud3.png', name: 'AWS' },
        { tab: 'Cloud', icon: '/images/DigitalOcean.png', name: 'Digital Ocean' },
        { tab: 'DevOps', icon: '/images/devops1.png', name: 'Gradle' },
        { tab: 'DevOps', icon: '/images/devops2.png', name: 'Kubernetes' },
        { tab: 'DevOps', icon: '/images/devops3.png', name: 'Docker' },
        { tab: 'DevOps', icon: '/images/devops4.png', name: 'Jenkins' },
        { tab: 'DevOps', icon: '/images/openshift-svgrepo-com.svg', name: 'Open Shift' },
        { tab: 'DevOps', icon: '/images/packer-svgrepo-com.svg', name: 'Packer' },
        { tab: 'DevOps', icon: '/images/ansible-svgrepo-com.svg', name: 'Ansible' },
        { tab: 'DevOps', icon: '/images/saltstack-svgrepo-com.svg', name: 'Salt Stack' },
        { tab: 'Ecommerce', icon: '/images/ecomres1.png', name: 'NopCommerce' },
        { tab: 'Ecommerce', icon: '/images/ecomres2.png', name: 'Kentico' },
        { tab: 'Ecommerce', icon: '/images/ecomres3.png', name: 'Shopify' },
        { tab: 'Ecommerce', icon: '/images/ecomres4.png', name: 'Magento' },
        { tab: 'Ecommerce', icon: '/images/ecomres5.png', name: 'WooCommerce' },
        { tab: 'Platforms', icon: '/images/platform1.png', name: 'Servicenow ' },
        { tab: 'Platforms', icon: '/images/platform2.png', name: 'Tableau' },
        { tab: 'Platforms', icon: '/images/platform3.png', name: 'MS Dynamics CRM' },
        { tab: 'Platforms', icon: '/images/platform4.png', name: 'Zoho' },
        { tab: 'Platforms', icon: '/images/platform5.png', name: 'Power BI' },
        { tab: 'Platforms', icon: '/images/platform6.png', name: 'Salesforce ' },
    ];

    // const tabData = [
    //     {
    //         title: 'Healthcare',
    //         description:
    //             'We deliver tailored solutions like EHR systems and patient management platforms, enhancing care delivery, compliance, and operational efficiency in the healthcare sector.',
    //         icon: '/images/heart.png',
    //         image: 'healthcare.jpg'
    //     },
    //     {
    //         title: 'Fintech',
    //         description: 'Our solutions include secure payment gateways, risk management systems, and compliance platforms, driving innovation and customer satisfaction in the fintech industry.',
    //         icon: '/images/Fintech.png',
    //         image: 'fintech.webp'
    //     },
    //     {
    //         title: 'Retail & eCommerce',
    //         description:
    //             'We support retailers with inventory management systems, personalized shopping experiences, and omnichannel platforms that boost sales and customer loyalty.',
    //         icon: '/images/Retail-eCommerce.png',
    //         image: 'ecommerce.jpg'
    //     },
    //     {
    //         title: 'Real Estate',
    //         description:
    //             'We provide real estate professionals with property management systems, CRM platforms, and secure transaction solutions to streamline operations and improve client interactions.',
    //         icon: '/images/RealEstate.png',
    //         image: 'realestate.webp'
    //     },

    //     {
    //         title: 'Automotive',
    //         description:
    //             'Our automotive solutions include vehicle connectivity platforms, supply chain transparency tools, and dealership management systems, enhancing efficiency and user experience.',
    //         icon: '/images/Automotive.png',
    //         image: 'automotive.jpg'
    //     },

    //     {
    //         title: 'Education',
    //         description:
    //             'We empower educational institutions with e-learning platforms, student information systems, and secure data management solutions that enhance learning outcomes.',
    //         icon: '/images/Education.png',
    //         image: 'education.jpg'
    //     },
    //     {
    //         title: 'Insurance',
    //         description:
    //             'Our solutions for the insurance industry include claims management systems, fraud detection tools, and customer portals, ensuring efficiency and trust.',
    //         icon: '/images/Insurance.png',
    //         image: 'insurance.webp'
    //     },
    //     {
    //         title: 'Logistics',
    //         description:
    //             'We offer logistics companies supply chain management systems, real-time tracking tools, and optimization platforms that improve service delivery and reduce costs.',
    //         icon: '/images/Logistics.png',
    //         image: 'logistics.jpg'
    //     },
    //     {
    //         title: 'Entertainment',
    //         description:
    //             'Our entertainment solutions include content management systems, audience analytics platforms, and IP protection tools, optimizing content distribution and engagement.',
    //         icon: '/images/Entertainment.png',
    //         image: 'entertainment.jpg'
    //     },
    //     {
    //         title: 'Gaming',
    //         description:
    //             'We support the gaming industry with custom game development, in-game economy platforms, and player analytics tools, creating immersive and secure gaming experiences.',
    //         icon: '/images/Gaming.png',
    //         image: 'game.jpg'
    //     },
    //     {
    //         title: 'Social Networking',
    //         description:
    //             'We provide social platforms with user engagement analytics, content moderation tools, and secure data management systems, enhancing user interaction and privacy.',
    //         icon: '/images/SocialNetworking.png',
    //         image: 'Social.jpg'
    //     },
    //     {
    //         title: 'Telecom',
    //         description:
    //             'Our telecom solutions include billing management systems, network optimization tools, and customer relationship platforms, ensuring efficient operations and customer satisfaction.',
    //         icon: '/images/Telecom.png',
    //         image: 'telecom.png'
    //     },
    //     {
    //         title: 'Energy',
    //         description:
    //             'We deliver smart grid management systems, resource optimization platforms, and secure trading solutions that enhance efficiency and compliance in the energy sector.',
    //         icon: '/images/Energy.png',
    //         image: 'energy.jpg'
    //     },
    // ];


    const slidesData = [
        {
            heading: 'We are the experts',
            text: 'Our team boasts extensive technical knowledge across a wide range of technologies and industries. With continuous learning and certifications, we stay ahead of the curve, ensuring we provide the most innovative and effective solutions for your needs.',
        },
        {
            heading: 'Nearshore timezones',
            text: 'Our team works in your nearshore time zones, offering seamless collaboration with minimal time zone differences. This allows for real-time communication and quick turnaround times, ensuring your project progresses smoothly and efficiently.',
        },
        {
            heading: 'Nothing beats our quality',
            text: 'We prioritize delivering top-notch quality in every project. Our rigorous quality assurance processes, including thorough testing and code reviews, ensure that your software is reliable, and secure, and performs optimally.',
        },
        {
            heading: 'Flexibility and adaptiveness',
            text: 'We understand that project requirements can change. Our flexible and adaptive approach allows us to respond swiftly to evolving needs, ensuring your project remains on track and aligned with your goals.',
        },
        {
            heading: 'We take security seriously',
            text: 'Our team implements robust security measures at every stage of development to protect your data and ensure compliance with industry standards, giving you peace of mind.',
        },
        {
            heading: 'We deliver value',
            text: 'Our value-based mindset drives us to deliver more than just a service. We are committed to understanding your business objectives and providing solutions that offer real, measurable value, helping you achieve long-term success.',
        },
    ];


    // HIRE US DATA OBJECT

    const HireUsData = [
        {
            id: 1,
            borderClass: 'border-1',
            icon: '/images/icon1.svg',
            heading: 'AI development',
            text: 'We help you leverage advanced AI technologies like LLMs, chatbots, and NLP to automate processes and gain insightful, data-driven decisions tailored to your business needs.',
            btnLink: 'ai-development'
        },
        {
            id: 2,
            borderClass: 'border-2',
            icon: '/images/icon2.svg',
            heading: 'Cybersecurity',
            text: 'Protect your digital assets with our robust cybersecurity services, safeguarding your business from potential threats and vulnerabilities.',
            btnLink: 'cyber-security'
        },
        {
            id: 3,
            borderClass: 'border-1',
            icon: '/images/machine-learning.png',
            heading: 'Machine Learning',
            text: 'Harness the power of machine learning to unlock insights, automate processes, and drive growth. Hire us to build smart, data-driven solutions tailored to your needs.',
            btnLink: 'commingsoon'
        },
        {
            id: 4,
            borderClass: 'border-3',
            icon: '/images/icon3.svg',
            heading: 'Custom software development',
            text: 'We create bespoke software solutions designed to address your unique business challenges and streamline your operations.',
            btnLink: 'custom-development'
        },
        {
            id: 5,
            borderClass: 'border-1',
            icon: '/images/dashboard.png',
            heading: 'Data analytics',
            text: 'Unlock the power of data with AI-driven analytics, BI insights, and predictive models to fuel data-driven decision-making.',
            btnLink: 'data-and-analytics'
        },
        {
            id: 6,
            borderClass: 'border-2',
            icon: '/images/block.png',
            heading: 'Blockchain development',
            text: 'Implement secure and transparent blockchain solutions to revolutionize your business processes and enhance trust.',
            btnLink: 'blockchain-development'
        },
        {
            id: 7,
            borderClass: 'border-1',
            icon: '/images/cloud.png',
            heading: 'Cloud Solutions',
            text: 'Supercharge your cloud infrastructure with expert DevOps services on AWS, GCP, and Azure—ensuring scalability, security, and peak efficiency.',
            btnLink: 'commingsoon'
        },
        {
            id: 8,
            borderClass: 'border-2',
            icon: '/images/web-programming.png',
            heading: 'Web app development',
            text: 'Empower your digital presence with scalable, high-performance web apps designed for seamless user engagement and business growth',
            btnLink: 'web-app-services'
        },
        {
            id: 9,
            borderClass: 'border-3',
            icon: '/images/mobile-development.png',
            heading: 'Mobile app development',
            text: 'Transform ideas into powerful, high-speed mobile apps with cutting-edge native, hybrid, and cross-platform solutions for iOS and Android.',
            btnLink: 'mobile-app-development'
        },
        {
            id: 10,
            borderClass: 'border-2',
            icon: '/images/software-testing.png',
            heading: 'QA testing',
            text: 'Ensure flawless software with comprehensive QA services, manual testing, and automated solutions for seamless performance.',
            btnLink: 'quality-assurance-services'
        },
        {
            id: 11,
            borderClass: 'border-3',
            icon: '/images/video-marketing.png',
            heading: 'Digital Branding',
            text: 'Amplify your brand’s online presence with data-driven digital branding strategies to boost engagement and build lasting customer connections.',
            btnLink: 'commingsoon'
        },
    ];


    return (
        <>
            <Helmet>
                <title>AI, Blockchain & Technology Services | Bitsclan IT Solutions</title>
                <meta name="description" content="We offer expert AI, Blockchain, Cloud, Custom Software, mobile app development, and more. Your trustworthy tech partner for services that drive growth." />
            </Helmet>

            {/* <Header /> */}

            <MegaHeader />

            <MainBanner />

            <HomeLogo />

            {/* <HireUs HireUsData={HireUsData} heading="Hire us for" content="Whether it is software development and consulting services, QA testing services, cybersecurity, Blockchain, or digital branding, we have got you covered! No matter the service you choose, we deliver exceptional quality and measurable impact." /> */}

            <ParalexEffectSlider HireUsData={HireUsData} heading="Hire us for" content="Whether it is software development and consulting services, QA testing services, cybersecurity, Blockchain, or digital branding, we have got you covered! No matter the service you choose, we deliver exceptional quality and measurable impact." />

            <WhyUs slidesData={slidesData} heading="for Software Development & Consulting Services?" greenHeading="Why Us" content="Bitsclan is not your run-of-the-mill technology company. We are your full-scale tech partners. We take ownership of the project and deliver it to the finish line." />

            <CoverTheStack whereStart={'Frontend'} tabs={tabs} tabItems={tabItems} subHeading={"We have experts in almost all the major and common tech stacks. Our team is rapidly expanding with us covering more and more programming languages and expertise. With us, you can rest assured that you will find the experts you need."} mainHeading={"Don't worry! We've got the tech stack you're looking for!"} />

            <EngagmentModels />

            <PioneeringChange tabData={tabData} greenHeading={"Across Industries"} mainHeading={"Pioneering Change"} content={"Leading innovation and transforming sectors with cutting-edge solutions and insights."} />

            <BrandImages />

            <DevelopingSolution heading="Developing Solutions That Transcend Mere Digital Transformation" subheading="We deliver innovative solutions that surpass digital transformation, driving impactful and lasting success." />
            <TypeIndustries />
            <ContactSection />
            <Footer />


            <div class="modal fade" id="comingsoon" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
                <div class="modal-dialog shedule-call-model-box modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class="fa-solid fa-xmark"></i></button>
                        </div>
                        <div class="modal-body">
                            <div className="d-flex justify-content-center">
                                <img src="/images/logo.png" width={300} alt="Logo" />
                            </div>
                            <h1 style={{ fontSize: '42px', textAlign: 'center', color: '#000000' }} className='mt-2'>Coming Soon,</h1>
                            <h1 style={{ fontSize: '42px', textAlign: 'center', color: '#000000' }}>We Are Working On It.</h1>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home
import React from "react";
import HirePageBanner from "../components/HirePageBanner/HirePageBanner";
import DevelopmentPerformance from "../components/DevelopmentPerformance/DevelopmentPerformance";
import SecondFaq from "../components/secondFaq/SecondFaq";
import Footer from "../components/footer/Footer";
import MegaHeader from "../components/MegaHeader/MegaHeader";
import { Helmet } from "react-helmet";

import {
  HireNodejsBannerList,
  HireNodejsFactsData,
  HireNodejsFaqData,
  HireNodejsHirignDetails,
  HireNodejsServicesData,
  HireNodejsSolutionsData,
  HireNodejsSteps,
  mainBannerImg,
  tabItems,
  tabs,
  techCombinations,
} from "../data/hireNodejsPageData";
import HirePageExperties from "../components/hirepageExperties/HirePageExperties";
import HirePageSteps from "../components/hire-page-steps/HirePageSteps";
import HireExpertFacts from "../components/hire-expert-facts/HireExpertFacts";
import HirePageEngagmentModels from "../components/hire-page-engagment-models/HirePageEngagmentModels";
import HirePageAppIdea from "../components/hire-page-app-idea/HirePageAppIdea";
import ContactSection from "../components/contactSection/ContactSection";
import TrendsInsights from "../components/trends-n-insights/TrendsInsights";
import HireCombinationSection from "../components/hire-combination-sectiono/HireCombinationSection";
import CoverTheStack from "../components/coverthestack/CoverTheStack";

const HireNode = () => {

  return (
    <>
      <Helmet>
        <title>Hire Node Js Developers | Node Development Services</title>
        <meta
          name="description"
          content="Hire top Node.js developers for efficient, scalable backend solutions. Expert Node.js development services for your business growth."
        />
      </Helmet>

      <div className="sticky-header">
        <MegaHeader />
      </div>

      <HirePageBanner
        diffBannerBackgroundImage={mainBannerImg?.backgroundImage}
        image={""}
        mainHeading="Hire Expert Node.js Developers for Scalable & High-Performance Solutions"
        // greenHeading="Expert Node.js Development Services for Your Projects"
        content="Whether you're a startup or an enterprise, our top-tier Node.js developers specialize in building fast, secure, and scalable applications tailored to your business needs."
        btnText="Consult our node developers"
        bannerList={HireNodejsBannerList}
      // secBtnText="NodeJs Development Portfolio"
      />

      <HirePageExperties
        heading="Our Node.js Development Expertise"
        discription="Our Node.js developers specialize in building high-performance, scalable backend systems for businesses of all sizes. From real-time applications to enterprise-grade solutions, we ensure seamless integration, fast performance, and top-tier security."
        servicesData={HireNodejsServicesData}
      />

      <DevelopmentPerformance
        heading="Why Hire Node.js Developers "
        greenHeading="from Bitsclan?"
        subHeading="At Bitsclan, we leverage Node.js alongside cutting-edge frontend and mobile technologies to build powerful, scalable, and high-performing applications tailored to your business needs. Whether you're developing a web platform or mobile solution, we ensure efficiency, security, and an exceptional user experience."
        solutions={HireNodejsSolutionsData}
      />

      <HireCombinationSection combinations={techCombinations} heading="Top Node.js Combinations for High-Performance Development" description="Leverage the power of Node.js with the best technology combinations to build fast, scalable, and efficient applications. Hire expert Node.js developers to ensure seamless backend development and exceptional performance." />

      <HirePageSteps
        heading={"‘Hire Node.js Developers in 4 Simple Steps"}
        subHeading={
          "Finding the perfect Node.js developer is quick and hassle-free with our streamlined 4-step hiring process."
        }
        steps={HireNodejsSteps}
      />

      <CoverTheStack
        whereStart={"Backend"}
        mainHeading={"Hire Expert Node.js Developers for Scalable & High-Performance Web Solutions"}
        subHeading={"At Bitsclan, we empower your backend development journey with a cutting-edge Node.js tech stack that ensures speed, scalability, and efficiency. Our experienced Node.js developers specialize in building real-time, data-intensive applications tailored to your business needs."}
        tabs={tabs}
        tabItems={tabItems} />

      <HireExpertFacts
        heading="Quick Facts About "
        greenHeading="Our Node.js Team"
        facts={HireNodejsFactsData}
        diffBannerBackgroundImage={mainBannerImg?.factBackgroundImage}
      />

      <HirePageAppIdea heading="Validate Your App Idea with a Prototype in Just 6-8 Weeks!" content=" Have an app idea but unsure how to proceed? Our expert Node.js developers can help you bring it to life with a fully functional prototype in just 6-8 weeks!" />

      <HirePageEngagmentModels description="Choose the engagement model that fits your project needs! Whether you need full-time, part-time, or hourly developers, we offer flexible options to ensure seamless collaboration and maximum efficiency throughout your project." hirignDetails={HireNodejsHirignDetails} />

      <TrendsInsights specificClass="mobile-dev-page" category="all" />

      <SecondFaq
        faqs={HireNodejsFaqData}
        specificClass="mobile-dev-page"
        mainHeading={"Frequently Asked Questions"}
        subHeading={"Discover answers to common questions about Node.js"}
      />

      <ContactSection />

      <Footer />
    </>
  );
};

export default HireNode;

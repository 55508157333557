export const tabs = [
  "Languages",
  "Libraries & UI Libraries",
  "Frameworks",
  "Testing",
  "Platforms",
];

export const tabItems = [
  { tab: "Languages", icon: "/images/icon7.png", name: "HTML5" },
  { tab: "Languages", icon: "/images/icon6.png", name: "CSS3" },
  { tab: "Languages", icon: "/images/icontwo.png", name: "JavaScript" },
  { tab: "Languages", icon: "/images/tech-stack-icons/typescript.svg", name: "TypeScript" },
  { tab: "Languages", icon: "/images/tech-stack-icons/graphql.svg", name: "GraphQL" },
  { tab: "Libraries & UI Libraries", icon: "/images/tech-stack-icons/bootstrap.svg", name: "Bootstrap" },
  { tab: "Libraries & UI Libraries", icon: "/images/tech-stack-icons/rx-js.svg", name: "Rxjs" },
  { tab: "Libraries & UI Libraries", icon: "/images/iconthree.png", name: "React" },
  { tab: "Libraries & UI Libraries", icon: "/images/tech-stack-icons/jquery.svg", name: "jQuery" },
  { tab: "Libraries & UI Libraries", icon: "/images/iconone.png", name: "Angular CLI" },
  { tab: "Frameworks", icon: "/images/tech-stack-icons/Ionic.svg", name: "Ionic" },
  { tab: "Frameworks", icon: "/images/tech-stack-icons/bootstrap.svg", name: "Bootstrap" },
  { tab: "Frameworks", icon: "/images/database2.png", name: "Nest Js" },
  { tab: "Frameworks", icon: "/images/mobile4.png", name: "Flutter" },
  { tab: "Frameworks", icon: "/images/icon4.png", name: "Vue.js" },
  { tab: "Frameworks", icon: "/images/tech-stack-icons/tailwind.svg", name: "Tailwind" },
  { tab: "Frameworks", icon: "/images/iconone.png", name: "AngularJS" },
  { tab: "Frameworks", icon: "/images/iconone.png", name: "Angular2+" },
  { tab: "Testing", icon: "/images/tech-stack-icons/postman.svg", name: "Postman" },
  { tab: "Testing", icon: "/images/tech-stack-icons/seaborn.svg", name: "Jmeter" },
  { tab: "Testing", icon: "/images/qa6.svg", name: "Selenium" },
  { tab: "Testing", icon: "/images/tech-stack-icons/cypress.svg", name: "Cypress" },
  { tab: "Testing", icon: "/images/tech-stack-icons/browserstack.svg", name: "BrowserStack" },
  { tab: "Testing", icon: "/images/tech-stack-icons/bugzilla.svg", name: "Bugzilla" },
  { tab: "Testing", icon: "/images/tech-stack-icons/soap-icon.svg", name: "SoapUI" },
  { tab: "Testing", icon: "/images/tech-stack-icons/jasmine.svg", name: "Jasmine" },
  { tab: "Testing", icon: "/images/tech-stack-icons/karma.svg", name: "Karma" },
  { tab: "Platforms", icon: "/images/db1.png", name: "Firebase" },
  { tab: "Platforms", icon: "/images/devops3.png", name: "Docker" },
  { tab: "Platforms", icon: "/images/cloud2.png", name: "Azure AWS" },
  { tab: "Platforms", icon: "/images/cloud3.png", name: "AWS" },
];

export const techCombinations = [
  {
    title: "Angular + Node.js (JS/TS)",
    description:
      "Angular at the front end and Node.js (JavaScript/TypeScript) at the back end are robust choices for full-stack JavaScript applications.",
    icons: ["angular.svg", "nodejs.svg"],
  },
  {
    title: "Angular + Django (Python)",
    description:
      "Angular at the front end & our Django services on the backend can build modern web apps with real-time collaboration & rapid prototyping.",
    icons: ["angular.svg", "python.svg"],
  },
  {
    title: "Angular + Ruby on Rails (RoR)",
    description:
      "The apps that are API-driven or need rich interfaces are perfect to be built on this combo-Angular (TypeScript) on the front end & RoR on the back end.",
    icons: ["angular.svg", "ruby_on_rails.svg"],
  },
  {
    title: "Angular + PHP",
    description:
      "Angular (front-end) and PHP (server-side scripting language) is used in the development of dynamic and interactive web apps.",
    icons: ["angular.svg", "php.svg"],
  },
];

export const HireAngularBannerList = [
  { list: "Freelance Contractors" },
  { list: "Full-time Roles" },
  { list: "Global Talent Pool" },
];

export var mainBannerImg = {
  backgroundImage: `url(/images/hire-page-images/hire-angular.png)`,
  factBackgroundImage: `url(/images/hire-page-images/hire-angular.svg)`,
};

export const HireAngularServicesData = [
  {
    title: "Angular Consulting",
    description:
      "Get expert guidance to optimize your Angular projects—whether you're launching a new app or enhancing an existing one.",
    icon: "/images/hire-page-icons/hire-angular-icons1.svg",
  },
  {
    title: "Angular Web Development",
    description:
      "We craft dynamic, user-friendly, and responsive web applications that deliver seamless experiences across all devices.",
    icon: "/images/hire-page-icons/hire-angular-icons2.svg",
  },
  {
    title: "Angular API Development",
    description:
      "Our team builds robust APIs that enable smooth communication between platforms, ensuring efficient data exchange.",
    icon: "/images/hire-page-icons/hire-angular-icons3.svg",
  },
  {
    title: "Angular CMS and Portal Development",
    description:
      "Develop scalable, high-performance CMS and custom web portals with easy content management and intuitive interfaces.",
    icon: "/images/hire-page-icons/hire-angular-icons4.svg",
  },
  {
    title: "Migration to Angular",
    description:
      "Seamlessly transition to Angular while preserving your data and enhancing application performance.",
    icon: "/images/hire-page-icons/hire-angular-icons5.svg",
  },
  {
    title: "Integration with Existing Solutions",
    description:
      "Ensure flawless compatibility between Angular applications and your existing tools, services, and platforms.",
    icon: "/images/hire-page-icons/hire-angular-icons6.svg",
  },
  {
    title: "Angular eCommerce Solutions",
    description:
      "Create engaging, feature-rich eCommerce platforms that offer fast performance and smooth user experiences.",
    icon: "/images/hire-page-icons/hire-angular-icons7.svg",
  },
  {
    title: "Angular Support & Maintenance",
    description:
      "Keep your Angular apps optimized, secure, and up-to-date with our proactive support and maintenance services.",
    icon: "/images/hire-page-icons/hire-angular-icons8.svg",
  },
];


export const HireAngularSolutionsData = [
  {
    icon: "/images/hire-page-icons/chose-ror-section1.svg",
    title: "Access vetted talent",
    description:
      "Work with skilled Angular developers, carefully vetted for their technical expertise and effective communication skills.",
  },
  {
    icon: "/images/hire-page-icons/chose-ror-section2.svg",
    title: "Find the Right Match Instantly",
    description:
      "Skip the endless resume reviews. With HireAI, quickly discover top Angular developers who match your project requirements.",
  },
  {
    icon: "/images/hire-page-icons/chose-ror-section3.svg",
    title: "Cost-Effective Global Hiring",
    description:
      "Tap into a global talent network while reducing hiring costs—access top professionals at competitive rates.",
    noBorder: true,
  },
  {
    icon: "/images/hire-page-icons/chose-ror-section4.svg",
    title: "Dedicated Human Support",
    description:
      "Get expert guidance at every step. Our recruitment specialists ensure you hire the perfect Angular developer for your team.",
  },
];

export const HireAngularStacksdata = [
  {
    title: "Junior Ruby on Rails Developer",
    description: [
      "2 Years' experience",
      "Fresh perspectives & innovative ideas",
      "Dedicated to explore web development",
    ],
    linkText: "Connect to Hire",
    link: "#",
    borderClass: "border-1",
  },
  {
    title: "Senior Ruby on Rails Developer",
    description: [
      "2+ years' experience",
      "Leadership in complex projects",
      "Proven app deployment success",
    ],
    linkText: "Connect to Hire",
    link: "#",
    borderClass: "border-2",
  },
  {
    title: "Ruby on Rails Tech Lead",
    description: [
      "5+ years' experience",
      "Skilled with Jira, Asana, MS Teams, etc.",
      "Suitable when you Hire Web App Team",
    ],
    linkText: "Connect to Hire",
    link: "#",
    borderClass: "border-3",
  },
];

export const HireAngularBenefitsData = [
  {
    service: "Android CMS Development",
    description:
      "Get an upper hand on your web content, augment efficiency, and develop a shared environment with our Ruby on Rails CMS solutions.",
  },
  {
    service: "Ruby on Rails Migrations",
    description:
      "Our Ruby on Rails engineers strategically migrate legacy content into modern systems in a secured, erAndroid-free, and robust way.",
  },
  {
    service: "Advanced Android Programming",
    description:
      "Our experts have extensive experience in using Android tech stack for tangible results to reach your business vision.",
  },
  {
    service: "Android Integration and Optimization",
    description:
      "Leverage the full-scale potential of Ruby on Rails integration and optimization functionalities for your app development needs.",
  },
  {
    service: "Customized Android Solutions",
    description:
      "Our Ruby on Rails developers delve into ideation, reinvention, and scale development as per your enterprise's unique IT demands.",
  },
  {
    service: "Android eCommerce App",
    description:
      "With using modern Android tools and technologies, we have a proven track record of developing scalable and secured eCommerce sites.",
  },
];

export const HireAngularSteps = [
  {
    title: "Choose your Engagement Model",
    description:
      "Pick the model that suits your project: Full-Time, Part-Time, or Hourly—flexible hiring tailored to your needs.",
    icon: "/images/hire-page-setps-icons/icon-1.svg",
  },
  {
    title: "Select Top Angular Developers",
    description:
      "Review pre-vetted Angular experts and shortlist the best candidates for your project.",
    icon: "/images/hire-page-setps-icons/icon-2.svg",
  },
  {
    title: "Conduct Personalized Interviews",
    description:
      "Assess skills, expertise, and problem-solving approach through one-on-one interviews.",
    icon: "/images/hire-page-setps-icons/icon-3.svg",
  },
  {
    title: "Onboard & Get Started",
    description:
      "Once selected, your Angular developer joins your team within 24-48 hours, ready to contribute.",
    icon: "/images/hire-page-setps-icons/icon-4.svg",
  },
];

export const HireAngularFactsData = [
  {
    text: "Our team of 10+ Angular developers specializes in building high-performance web applications.",
  },
  {
    text: "With an average of 5+ years of experience, our developers ensure expert-level development and seamless execution.",
  },
  {
    text: "We leverage modern tools and libraries to create fast, scalable, and reliable Angular applications.",
  },
  {
    text: "Our expertise includes dynamic, interactive, and responsive UI development for enhanced user experiences.",
  },
  {
    text: "We build scalable web and mobile applications across industries, including eCommerce and enterprise solutions.",
  },
  {
    text: "Our team excels in API development and third-party integrations, ensuring smooth connectivity between systems.",
  },
];


export const HireAngularWhoWeServeData = [
  {
    icon: "/images/servie-icon/startups.png",
    title: "Budget savings",
    description:
      "Assembling a local Ruby on Rails team is unavoidably expensive. By approaching Innowise. you bypass many hurdles and avoid redundantexpenses such as training fees. taxes. and software licenses.",
    link: "#",
    borderClass: "border-1",
  },
  {
    icon: "/images/servie-icon/companies.png",
    title: "Software product companies",
    description:
      "When you hire Android developers. you avoid the risks of budget overruns and missed deadlines. Innowise provides solid risk management plans and has an array of risk mitigation measures",
    link: "#",
    borderClass: "border-2",
  },
  {
    icon: "/images/servie-icon/industries.png",
    title: "Enterprises from 30+ industries",
    description:
      "Innowise has experts with excellent development backgrounds. so you can rest assured that your project is in good hands. Pyrggyelgprneg",
    link: "#",
    borderClass: "border-3",
  },
];

export const HireAngularHirignDetails = [
  {
    text: "Hourly Hiring",
    hours: "48",
    icon: "time.svg",
    bgColor: "#FDFDFF",
  },

  {
    text: "Full Time Hiring",
    hours: "72",
    icon: "full-time.svg",
    bgColor: "#FFFEFA",
  },

  {
    text: "Part Time Hiring",
    hours: "48",
    icon: "part-time.svg",
    bgColor: "#FDFFFA",
  },
];
export const HireAngularPageDeliverData = [
  {
    image: "/images/hire-page-images/card-1.png",
    title: "Complex SaaS systems",
    description:
      "Innowise provides its expertise in the area of building complex platforms for businesses to streamline workflows. The main applications we develop include HRM, CRM, ERP, accounting software, etc.",
  },
  {
    image: "/images/hire-page-images/card-2.png", // Assumed path based on provided example
    title: "Complex SaaS systems",
    description:
      "Innowise provides its expertise in the area of building complex platforms for businesses to streamline workflows. The main applications we develop include HRM, CRM, ERP, accounting software, etc.",
  },
  {
    image: "/images/hire-page-images/card-3.png", // Assumed path based on provided example
    title: "Complex SaaS systems",
    description:
      "Innowise provides its expertise in the area of building complex platforms for businesses to streamline workflows. The main applications we develop include HRM, CRM, ERP, accounting software, etc.",
  },
];

export const HireAngularSlidesData = [
  {
    title: "Experienced Android Developers",
    content:
      "Our team consists of senior Android developers who have extensive experience in building sophisticated web applications across various industries.",
    backgroundClass: "bg-1",
  },
  {
    title: "Remote and On-Demand",
    content:
      "Need flexibility? Hire remote Android developers from Bitsclan for seamless project execution without geographic limitations.",
    backgroundClass: "bg-2",
  },
  {
    title: "Agile Development Approach",
    content:
      "We follow agile methodologies to ensure rapid delivery, transparency, and adaptability throughout the development process.",
    backgroundClass: "bg-3",
  },
  {
    title: "Automation Solutions",
    content:
      "We combine AI automation with Robotic Process Automation to boost operational efficiency, reduce operational costs, and minimize human error, allowing you to focus on strategic initiatives.",
    backgroundClass: "bg-4",
  },
];

export const HireAngularFaqData = [
  {
    id: "collapseOne",
    question: "What is Angular web development?",
    answer:
      "Angular web development refers to creating web applications using the Angular framework, which helps developers build dynamic, interactive, and scalable applications.",
  },
  {
    id: "collapseTwo",
    question: "Why should I hire Angular developers from Bitsclan?",
    answer:
      "By hiring Angular developers from Bitsclan, you get access to experts who can create high-performance web apps tailored to your needs, ensuring smooth user experiences and functionality.",
  },
  {
    id: "collapseThree",
    question: "Can you migrate my existing web app to Angular?",
    answer:
      "Yes! Our Angular experts can help you migrate your existing web applications to Angular, ensuring a smooth transition and enhanced performance.",
  },
  {
    id: "fourth",
    question: "What types of apps can be built with Angular?",
    answer:
      "With Angular, we can build all kinds of apps, including eCommerce platforms, enterprise solutions, content management systems (CMS), and more. Angular is great for dynamic and responsive web applications.",
  },
  {
    id: "fifth",
    question: "How does Angular improve my app’s performance?",
    answer:
      "Angular helps build scalable, fast-loading apps. It uses efficient coding practices and tools like two-way data binding and dependency injection to improve app performance and maintainability.",
  },
  {
    id: "sixth",
    question: "How long does it take to develop an Angular web application?",
    answer:
      "The development timeline depends on the complexity of the project. Simple apps can be developed in a few weeks, while larger, more complex applications may take a few months. We work with you to create realistic timelines.",
  },
];

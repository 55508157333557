import React from "react";
import "./style.css";

const RoadMap = ({ heading, description, roadMapsteps, roadMapHeading }) => {
  return (
    <>
      <div className="road-map-box ai-page-roadmap">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="engagment-model-text">
                {heading || "Our Proven Roadmap to Ensure Your Success"}
              </h1>
              <h3 className="sub-heading">
                {description ||
                  "As a leading QA service provider, we follow a structured roadmap to ensure the highest software quality. From initial assessment to ongoing support, our systematic approach aligns with your business objectives, guarantees robust testing, and drives continuous improvement."}{" "}
              </h3>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="prover-box-map-left ">
                <div className="proven-roadmap-text road-map-left-space second">
                  <h2>{roadMapHeading[1] || "Design & Proof of Concept"}</h2>
                  <p className="common-text">
                    {roadMapsteps[1] ||
                      "We design the solution architecture and develop a proof of concept, validating ideas to ensure feasibility and alignment with your vision."}
                  </p>
                </div>

                <div className="proven-roadmap-text forth">
                  <h2>{roadMapHeading[2] || "Development & Testing"}</h2>
                  <p className="common-text">
                    {roadMapsteps[2] ||
                      "We deliver robust solutions through seamless development, rigorous testing, and ongoing maintenance for optimal performance."}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 position-relative none-tab-imge">
              <div className="road-image-box ai-development-road-map">
                <img
                  className="none-tab"
                  src="/images/roadmap-fff.svg"
                  alt="Roadmap"
                />
                <img
                  className="none-web-box"
                  src="/images/roadmap-tablet/roadmap4-tab.png"
                  alt="Roadmap"
                />
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="right-box-text ">
                <div className="proven-roadmap-text road-map-left-space first">
                  <h2>{roadMapHeading[0] || "Discovery & Planning"}</h2>
                  <p className="common-text">
                    {roadMapsteps[0] ||
                      "As a leading AI development services provider, we follow a systematic approach that guarantees alignment with your business goals, delivers high-quality solutions, and provides continuous improvement."}
                  </p>
                </div>

                <div className="proven-roadmap-text third">
                  <h2>{roadMapHeading[3] || "Implementation & Support"}</h2>
                  <p className="common-text">
                    {roadMapsteps[3] ||
                      "Finally, we implement the solutions ensuring a smooth transition, and offer ongoing maintenance to keep your solution optimized and up-to-date."}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoadMap;

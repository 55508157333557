import React, { useRef, useState, useCallback, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import MegaHeader from "../components/MegaHeader/MegaHeader";
import Footer from "../components/footer/Footer";
import HirePageBanner from "../components/HirePageBanner/HirePageBanner";
import Testimonial from "../components/testimonila/Testimonial";
import ParalexEffectSlider from "../components/ParalexEffectSlider/ParalexEffectSlider";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

import { Navigation, Autoplay } from "swiper/modules";
import FAQ from "../components/Fqas/FAQ";
import AIContactSection from "../components/ai-contact-section/AIContactSection";
import IndustryCuttingEdge from "../components/industry-cutting-edge/IndustryCuttingEdge";
import industryData from "../data/industryData";
import HireUs from "../components/hireUs/HireUs";
import IndustryDetailCard from "../components/industryDetailCard/IndustryDetailCard";
import { Helmet } from "react-helmet";

const IndustryDetail = () => {
  const { slug: slug } = useParams();
  const [filteredData, setFilteredData] = useState(null);

  useEffect(() => {
    const matchingSlide = industryData.find(
      (item) => String(item.slug) === String(slug)
    );
    setFilteredData(matchingSlide);
  }, [slug, industryData]);

  console.log(filteredData, "Here is the filtered data of indystry page");

  // SLIDER FUNCTIONS
  const sliderRef = useRef();

  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const handleSlideChange = (swiper) => {
    setIsBeginning(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
  };
  // SLIDER FUNCTIONS

  console.log(filteredData?.backgroundImage, "bg image");

  const contactData = {
    heading: "Get a free 30-minute consultancy",
    subHeading: " worth $250.",
    contactSections: [
      {
        title: "Skilled Talent Network",
        description: "Our team consists of highly experienced professionals.",
      },
      {
        title: "Swift Turnaround",
        description:
          "We provide fast and efficient solutions despite time constraints.",
      },
      {
        title: "Cost-effective Solutions",
        description:
          "We deliver high-quality services that maximize your budget’s potential.",
      },
      {
        title: "Innovation-Driven Development",
        description:
          "We focus on delivering cutting-edge, innovative, and tailored solutions.",
      },
    ],
  };

  // SLIDER DATA
  // var mainBannerImg = {
  //     backgroundImage: `url(/images/industry-details/e-comerce.png)`,
  // };

  // CLIENT IMAGES HERE
  const brandImages = [
    {
      src: "brand-icon1.svg",
      alt: "Brand Icon 1",
    },
    {
      src: "brand-icon2.svg",
      alt: "Brand Icon 2",
    },
    {
      src: "brand-icon3.svg",
      alt: "Brand Icon 3",
    },
    {
      src: "brand-icon4.svg",
      alt: "Brand Icon 4",
    },
    {
      src: "brand-icon5.svg",
      alt: "Brand Icon 5",
    },
    {
      src: "brand-icon6.webp",
      alt: "Brand Icon 5",
    },
    {
      src: "brand-icon7.webp",
      alt: "Brand Icon 5",
    },
    {
      src: "brand-icon8.webp",
      alt: "Brand Icon 5",
    },
    {
      src: "brand-icon9.webp",
      alt: "Brand Icon 5",
    },
    {
      src: "brand-icon10.webp",
      alt: "Brand Icon 5",
    },
    {
      src: "brand-icon11.webp",
      alt: "Brand Icon 5",
    },
    {
      src: "brand-icon12.webp",
      alt: "Brand Icon 5",
    },
    {
      src: "brand-icon13.webp",
      alt: "Brand Icon 5",
    },
    {
      src: "brand-icon14.webp",
      alt: "Brand Icon 5",
    },
    {
      src: "brand-icon15.webp",
      alt: "Brand Icon 5",
    },
    {
      src: "brand-icon16.webp",
      alt: "Brand Icon 5",
    },
    {
      src: "brand-icon17.webp",
      alt: "Brand Icon 5",
    },
    {
      src: "brand-icon18.webp",
      alt: "Brand Icon 5",
    },
    {
      src: "brand-icon19.webp",
      alt: "Brand Icon 5",
    },
    {
      src: "brand-icon20.webp",
      alt: "Brand Icon 5",
    },
    {
      src: "brand-icon21.webp",
      alt: "Brand Icon 5",
    },
    {
      src: "brand-icon22.webp",
      alt: "Brand Icon 5",
    },
    {
      src: "brand-icon23.webp",
      alt: "Brand Icon 5",
    },
    {
      src: "brand-icon24.webp",
      alt: "Brand Icon 5",
    },
    {
      src: "brand-icon25.webp",
      alt: "Brand Icon 5",
    },
    {
      src: "brand-icon26.webp",
      alt: "Brand Icon 5",
    },
    {
      src: "brand-icon27.webp",
      alt: "Brand Icon 5",
    },
    {
      src: "brand-icon28.webp",
      alt: "Brand Icon 5",
    },
    {
      src: "brand-icon29.webp",
      alt: "Brand Icon 5",
    },
    {
      src: "brand-icon30.webp",
      alt: "Brand Icon 5",
    },
    {
      src: "brand-icon31.webp",
      alt: "Brand Icon 5",
    },
    {
      src: "brand-icon32.webp",
      alt: "Brand Icon 5",
    },
    {
      src: "brand-icon33.webp",
      alt: "Brand Icon 5",
    },

    {
      src: "brand-icon34.webp",
      alt: "Brand Icon 5",
    },

    {
      src: "brand-icon35.webp",
      alt: "Brand Icon 5",
    },

    {
      src: "brand-icon36.webp",
      alt: "Brand Icon 5",
    },

    {
      src: "brand-icon37.webp",
      alt: "Brand Icon 5",
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          {filteredData?.metaTitle ||
            "Expert IT Solutions Tailored for Each Industry | Bitsclan"}
        </title>
        <meta
          name="description"
          content={
            filteredData?.metaDes ||
            "Our industry-specific IT solutions meet the unique challenges of your sector. We provide customized services that drive innovation and growth."
          }
        />
      </Helmet>

      <div className="sticky-header">
        <MegaHeader />
      </div>

      <div className="industry-page-image">
        <HirePageBanner
          mainHeading={filteredData?.bannerHeading}
          content={filteredData?.description}
          btnText={filteredData?.bannerBtnText}
          diffBannerBackgroundImage={filteredData?.backgroundImage}
          contactUsLink="/contact-us"
        />
      </div>

      {/*HEALTH CARE CONTENT*/}
      <div className="health-card-content">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h3 className="health-care-content">
                <span>{filteredData?.discriptionSection?.heading}</span>{" "}
                {filteredData?.discriptionSection?.discription}
              </h3>
            </div>

            <div className="col-md-6">
              <div className="health-care-content-card">
                <h3>{filteredData?.discriptionSection?.cardHeading}</h3>
                <p className="common-text">
                  {filteredData?.discriptionSection?.cardDiscripiton}
                </p>
                <a
                  href="https://calendly.com/bitsclansolutions/15min"
                  target="_blank"
                  class="common-green-btn"
                >
                  Talk to Expert <i class="fa-solid fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*HEALTH CARE CONTENT*/}

      {/*HEALTH CARE COUNTER BOX*/}
      <div
        className="health-care-counter-box-wrap"
        style={{
          backgroundImage: `url(/images/industry-details/${filteredData?.statsSection?.backgroundImage})`,
        }}
      >
        <div className="health-care-counter-box">
          <div className="container">
            <div className="row">
              <div class="col-md-12">
                <h1 class="engagment-model-text">
                  {filteredData?.statsSection?.heading}{" "}
                  <span>{filteredData?.statsSection?.greenPart}</span>
                </h1>

                <h3 class="sub-heading-box w-75 m-auto">
                  {filteredData?.statsSection?.discription}
                </h3>
                <h4 class="health-card-sub-heading">
                  {filteredData?.statsSection?.subheading}{" "}
                  <span>{filteredData?.statsSection?.greenPartSubHeading}</span>
                </h4>
              </div>

              <div className="col-md-12">
                <div class="number-boxes-main health-care-number-box">
                  {filteredData?.statsSection?.stats?.map((item, index) => {
                    return (
                      <div class="number-box" key={index}>
                        <h1>{item?.count}</h1>
                        <p class="common-text">{item?.text}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*HEALTH CARE COUNTER BOX*/}

      {/*BRANDS SECTION BOX*/}
      <div className="hire-us-box bg-white">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="main-heading mb-5">
                Many Love Us, Others Count on Us for their Success
              </h1>
            </div>

            <div className="col-md-12 d-flex justify-content-end">
              <div className="mb-4 about-slider-arrow arrow-developing-slider">
                <div className="arrow prev-arrow" style={{ marginRight: "0" }}>
                  <img
                    onClick={handlePrev}
                    src="/images/arrow-right.svg"
                    className={`left-image ${isBeginning ? "disabled" : ""}`}
                    alt="Arrow"
                    style={{ cursor: isBeginning ? "not-allowed" : "pointer" }}
                  />
                </div>
                <div className="arrow next-arrow" onClick={handleNext}>
                  <img
                    onClick={handleNext}
                    src="/images/arrow-right.svg"
                    className={`${isEnd ? "disabled" : ""}`}
                    alt="Arrow"
                    style={{ cursor: isEnd ? "not-allowed" : "pointer" }}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="brand-icons-box-main">
                <Swiper
                  slidesPerView={"1.3"}
                  spaceBetween={30}
                  navigation={false}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                  }}
                  modules={[Navigation, Autoplay]}
                  onSlideChange={handleSlideChange}
                  className="mySwiper"
                  ref={sliderRef}
                  breakpoints={{
                    425: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    375: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    780: {
                      slidesPerView: 4,
                      spaceBetween: 10,
                    },
                    1280: {
                      slidesPerView: 6,
                      spaceBetween: 10,
                    },
                  }}
                >
                  {brandImages?.map((image, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <div className="brand-icon-industry">
                          <img
                            src={`/images/project-logos/${image?.src}`}
                            alt={image?.alt}
                          />
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*BRANDS SECTION BOX*/}

      <div className="bg-color-industry-detail">
        <IndustryDetailCard
          HireUsData={filteredData?.industryServices?.industryServicesSlides}
          heading={filteredData?.industryServices?.heading}
          content={filteredData?.industryServices?.text}
        />
      </div>

      {/*HEALTH CARE SOFTWARE BOX*/}
      <div className="health-care-container-box">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="health-care-image">
                <img
                  src={`/images/industry-details/${filteredData?.industryAdvertising?.image}`}
                  alt="Arrow"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="health-care-software-text">
                <h1>{filteredData?.industryAdvertising?.heading}</h1>
                <p>{filteredData?.industryAdvertising?.discription}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*HEALTH CARE SOFTWARE BOX*/}

      {/*HEALTH CARE SOFTWARE SOLUTION*/}
      <div className="health-care-software-solution">
        <div className="container">
          <div className="row">
            <div class="col-md-12 mb-5">
              <h1 class="engagment-model-text">
                {filteredData?.softwareSolutions?.heading}
              </h1>
              <h3 class="sub-heading-box">
                {filteredData?.softwareSolutions?.discription}
              </h3>
            </div>

            <Swiper
              slidesPerView={"4"}
              spaceBetween={30}
              navigation={false}
              autoplay={{
                delay: 3000,
                pauseOnMouseEnter: true,
              }}
              modules={[Navigation, Autoplay]}
              onSlideChange={handleSlideChange}
              className="mySwiper"
              ref={sliderRef}
              breakpoints={{
                425: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                375: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                780: {
                  slidesPerView: 2,
                },
                1280: {
                  slidesPerView: 4,
                },
              }}
            >
              {filteredData?.softwareSolutions?.healthcareSolutionsData?.map(
                (item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <div className="healcare-solution-card">
                        <h2>{item.heading}</h2>
                        <p className="common-text">{item.text}</p>
                      </div>
                    </SwiperSlide>
                  );
                }
              )}
            </Swiper>

            {filteredData?.softwareSolutions?.healthcareSolutionsData?.length >
              4 ? (
              <div className="col-md-12 mt-5">
                <div className="arrow-developing-slider">
                  <img
                    onClick={handlePrev}
                    src="/images/arrow-right.png"
                    className={`left-image ${isBeginning ? "disabled" : ""}`}
                    alt="Arrow"
                    style={{ cursor: isBeginning ? "not-allowed" : "pointer" }}
                  />
                  <img
                    onClick={handleNext}
                    src="/images/arrow-right.png"
                    className={`${isEnd ? "disabled" : ""}`}
                    alt="Arrow"
                    style={{ cursor: isEnd ? "not-allowed" : "pointer" }}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="w-100 d-flex flex-row justify-content-center mt-5">
                <div className="hire-us-cta-btn">
                  <Link role="button" to="/contact-us" className="ms-0 px-4">
                    Get Started
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*HEALTH CARE SOFTWARE SOLUTION*/}

      {/* <IndustryCuttingEdge
        heading={filteredData?.industryExpertise?.heading}
        discription={filteredData?.industryExpertise?.discription}
        acordianData={filteredData?.industryExpertise?.accordionData}
      /> */}

      <FAQ
        faqs={filteredData?.industryFaqs?.faqData}
        mainHeading={filteredData?.industryFaqs?.heading}
        subHeading={filteredData?.industryFaqs?.discription}
      />

      {/* <Testimonial
        heading="Hear Directly From Our Clients"
        content="We received the Best Web Development Company of the Year award from Payoneer in 2021. We bring the same expertise, dedication, vision, and conviction to every service we provide."
      /> */}

      <AIContactSection
        heading={contactData.heading}
        subHeading={contactData.subHeading}
        contactSections={contactData.contactSections}
      />

      <Footer />
    </>
  );
};

export default IndustryDetail;

import React from 'react'
import './style.css'
import { Link } from 'react-router-dom'

const HighValues = ({ heading, subHeading, boxContent, btnLink, btnText }) => {
    return (
        <>
            <div className="high-values">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>{heading}</h2>
                            <p className='px-3 pb-5'>{subHeading}</p>
                        </div>
                    </div>
                    <div className="high-value-boxes">
                        {
                            boxContent.map((data, i) => (
                                <div className="value-box" key={i}>
                                    <div className="hire-us-icon">
                                        <img src={`./images/high-values-icons/${data.image}`} alt={data.text} />
                                    </div>
                                    <h5>{data.text}</h5>
                                </div>
                            ))
                        }
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="hire-us-cta-btn d-flex mt-5 justify-content-center">
                                <Link className='consult-project-scope' to="https://calendly.com/bitsclan-it/30min?month=2025-01" target='_blank'>
                                    {btnText}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HighValues

import React, { useState, useEffect } from 'react'
import Select from 'react-select';
import slidesData from '../data/case-study-data'
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import MegaHeader from '../components/MegaHeader/MegaHeader'
import Footer from '../components/footer/Footer'
import FAQ from '../components/Fqas/FAQ'
import DevelopingSolution from '../components/developingsolution/DevelopingSolution'
import { useParams } from 'react-router-dom';
import ClutchRatingDesign from '../components/ClutchRatingDesign/ClutchRatingDesign';
import DevelopmentServices from '../components/development-services/DevelopmentServices';
import { caseStudyClutchDetil } from '../data/caseStudyDetailData';

const CaseStudyDetial = () => {

    // GET DATA AGAINST ID
    const { slug: slug } = useParams();
    const [filteredSlide, setFilteredSlide] = useState(null);
    console.log(filteredSlide, "Filtered Slide By Me");
    useEffect(() => {
        const matchingSlide = slidesData.find(item => String(item.slug) === String(slug));
        setFilteredSlide(matchingSlide);
    }, [slug, slidesData]);
    console.log(filteredSlide, "Here is the filtered slide");
    // GET DATA AGAINST ID

    const notify = () => toast("Your query submitted successfully!");

    const [loading, setLoading] = useState(false)

    const [AiPageFormData, SetAiPageFormData] = useState({
        name: '',
        email: '',
        phone: '',
        company: '',
        projectDetails: '',
        selectedOptions: []
    });

    const isFormValid = () => {
        return (
            AiPageFormData.name &&
            AiPageFormData.email &&
            AiPageFormData.phone &&
            AiPageFormData.company &&
            AiPageFormData.projectDetails &&
            AiPageFormData.selectedOptions.length > 0
        );
    };


    const [error, setError] = useState(null);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        SetAiPageFormData({
            ...AiPageFormData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    // SELECT TOO DROPDOWN

    const services = [
        { value: 'WebApplicationDevelopment', label: 'Web Application Development' },
        { value: 'AI Development', label: 'AI Development' },
        { value: 'MobileAppDevelopment', label: 'Mobile App Development' },
        { value: 'DevOpsServices', label: 'DevOps Services' },
        { value: 'QualityAssurenceServices', label: 'Quality Assurance Services' },
        { value: 'DataAndAnalytics', label: 'Data Analytics' },
        { value: 'BlockChainDevelopment', label: 'BlockChain Development' },
        { value: 'CyberSecurity', label: 'Cyber Security' },
    ]

    const handleSelectChange = (selected) => {
        console.log(selected, "selected valyedafdaf");

        SetAiPageFormData({
            ...AiPageFormData,
            selectedOptions: selected,
        });
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: 'transparent',
            color: '#ffffff',
            border: 'none',
            borderBottom: '1.12px solid #FFFFFF',
            borderRadius: '0',
            boxShadow: 'none',
            paddingBottom: '10px',
            paddingTop: '10px',
            '&:hover': {
                borderBottom: '1.12px solid #FFFFFF',
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#f8f9fa' : 'white',
            color: 'black',
            padding: 10,
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#e9ecef',
            borderRadius: '4px',
            color: '#495057',
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: '#495057',
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            color: '#dc3545',
            '&:hover': {
                backgroundColor: '#cecece',
                color: '#000000',
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#ffffff',
        }),
    };


    // HANDLE FORM SUBMIT

    // GOOGLE SHEET INTIGRATION

    const sheetName = "AIDevelopmentSheet"

    const state = {
        Name: AiPageFormData.name,
        Email: AiPageFormData.email,
        Services: JSON.stringify(AiPageFormData.selectedOptions.map(option => option.label)),
        Phone: AiPageFormData.phone,
        ComapanyName: AiPageFormData.company,
        Discription: AiPageFormData.description,
        ProjectDetails: AiPageFormData.projectDetails,
        SheetName: sheetName,
    }

    // FORM SUBMITION

    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log(state, "new Data");

        setLoading(true);

        const formData = new FormData();
        for (const key in state) {
            if (state.hasOwnProperty(key)) {
                const value = state[key];

                if (Array.isArray(value)) {
                    for (let i = 0; i < value.length; i++) {
                        formData.append(`${key}[${i}]`, value[i]);
                    }
                } else {
                    formData.append(key, value);
                }
            }
        }
        fetch(
            "https://script.google.com/macros/s/AKfycbxM1oWweWu_LvIrcz27z31De6OYDf6Rn5JqBy48NwCmAwEHnpOqR9cNSq4tfqydt9cO/exec",
            {
                method: "POST",
                body: formData,
                mode: 'no-cors',
            }
        )
            .then((data) => {
                console.log(data);
                setLoading(false);
                notify();
                SetAiPageFormData({
                    name: '',
                    email: '',
                    phone: '',
                    company: '',
                    description: '',
                    projectDetails: '',
                    selectedOptions: [],
                })
            })
            .catch((error) => console.log(error));
    };

    // FAQ DATA TEXT
    const faqData = [
        {
            id: 'collapseOne',
            question: 'How did Bitsclan perform from a project management standpoint?',
            answer: 'Bitsclan showcased excellent project management skills. They understood our requirements quickly, handled database design and error handling effectively, and ensured smooth communication throughout the process. Their ability to deliver without extensive design specifications was particularly impressive.',
        },
        {
            id: 'collapseTwo',
            question: 'What did you find most impressive about them?',
            answer: 'Their ability to take a high-level idea and turn it into a fully functional solution was remarkable. They consistently went above and beyond, showcasing a deep understanding of both technical and business requirements. Their proactive approach and attention to detail stood out.',
        },
        {
            id: 'collapseThree',
            question: 'Are there any areas they could improve?',
            answer: 'While the overall experience was positive, providing more frequent progress updates and visual prototypes during development could enhance the client experience further. However, these are minor suggestions compared to their excellent delivery.',
        },
    ];

    return (
        <>

            <Helmet>
                <title>Case Studies | Success Stories in Custom Software Development"</title>
                <meta
                    name="description"
                    content="See how Bitsclan delivers impactful software solutions across industries. Explore detailed case studies and success stories"
                />
            </Helmet>

            <div className="sticky-header">
                <MegaHeader />
            </div>
            {/* BANNER */}
            <div className="case-study-banner" style={{ backgroundImage: `url(${filteredSlide?.image})` }}>
                <div className="container h-100">
                    <div className="row h-100">
                        <div className="col-md-12 h-100">
                            <div className="case-study-banner-text">
                                <h1>{filteredSlide?.title}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* ABOUT */}
            <div className="case-study-details-box">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="about-text-box">
                                <h1 className='main-heading'>About <span>{filteredSlide?.heading}</span><strong>.</strong></h1>
                                <h3 className="sub-heading">{filteredSlide?.description}</h3>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="about-content-side-box">
                                <div className="about-case-text">
                                    <h1>Industry</h1>
                                    <p>{filteredSlide?.industry}</p>
                                </div>

                                <div className="about-case-text">
                                    <h1>Employee</h1>
                                    <p>50-500</p>
                                </div>

                                <div className="about-case-text">
                                    <h1>Location</h1>
                                    <p>{filteredSlide?.location}</p>
                                </div>

                                <div className="about-case-text">
                                    <h1>Use Cases</h1>
                                    <p>{filteredSlide?.UseCase}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* OUR COMPANY */}
            <div className="our-company-box-main">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-5 p-0">
                            <div className="comapny-image">
                                <img src="/images/coampny-image.png" alt="Company Logo" />
                            </div>
                        </div>

                        <div className="col-md-7 p-0">
                            <div className="comapany-black-side">
                                <img src="/images/SVG/company-icon2.svg" className='comapny-image' alt="Company Logo" />
                                <div className="our-chalenge-text">
                                    <h1>Our Challenge<strong style={{ color: '#80b927' }}>.</strong></h1>
                                    <p className='common-text'>{filteredSlide?.challenge}</p>
                                </div>
                                <img src="/images/SVG/company-icon1.svg" alt="Company Icon" className='comapny-image-second' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* OUR SOLUTION */}
            <div className="our-solution-main">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-5 col-md-12">
                            <div className="our-soluton-text">
                                <h1>Our Solution</h1>

                                <p className='common-text'>{filteredSlide?.solution}</p>

                                {/* <p className='common-text'>Time is very valuable for our customers and employees, so we want to make the process easier. One of the features we built is an auto form filling for the new leads entered by our sales team. It eliminates the need to manually add addresses or phone numbers. </p>

                                <p className='common-text'>We came up with additional features along the way, which would be helpful for our customers. The CRM was converted into a powerful portal through which our customers can manage their billing, text messaging,    and all the other features we provide.</p> */}
                            </div>
                        </div>

                        <div className="col-md-2 none-tab-show">
                            <div className="line-image-solution"></div>
                        </div>

                        <div className="col-xl-5 col-lg-5 col-md-12">
                            <div className="solution-text-main">
                                <h3>{filteredSlide?.conclusion}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="case-study-detail-box">
                <DevelopmentServices
                    tabs={caseStudyClutchDetil}
                    heading="Lorem ipsum dolor sit amet consectetur adipisicing elit."
                    subheading="Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis dolore aliquam esse ut eveniet ullam iste, voluptatum assumenda fugiat? Quam.Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis dolore aliquam esse ut eveniet ullam iste, voluptatum assumenda fugiat? Quam."
                />
            </div>

            {/* FAQS */}
            <FAQ faqs={faqData} mainHeading={"How Bitsclan Delivered Success"} />

            {/* BUILD TOGETHER */}

            <div className="build-something-together">
                <div className="container">
                    <div className="row build-something-row">
                        <div className="col-xl-6 col-lg-6 col-md-7">
                            <div className="together-form-box">
                                <form className="row contact-side-form-box" onSubmit={handleSubmit}>
                                    {error && <div className="alert alert-danger">{error}</div>}

                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input
                                                type="text"
                                                name="name"
                                                placeholder="Your Name"
                                                className="form-control"
                                                id="fName"
                                                value={AiPageFormData.name}
                                                onChange={handleChange}
                                                required
                                            />
                                            <label htmlFor="fName">Your Name <span style={{ color: 'red' }}>*</span></label>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input
                                                type="email"
                                                name="email"
                                                placeholder="Enter Email"
                                                className="form-control"
                                                id="email"
                                                value={AiPageFormData.email}
                                                onChange={handleChange}
                                                required
                                            />
                                            <label htmlFor="email">Enter Your Email <span style={{ color: 'red' }}>*</span></label>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-floating select-arrow-down">
                                            <Select
                                                isMulti
                                                name="services"
                                                options={services}
                                                value={AiPageFormData.selectedOptions}
                                                onChange={handleSelectChange}
                                                styles={customStyles}
                                                placeholder="Select Your Services"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-floating">
                                            <input
                                                type="number"
                                                name="phone"
                                                placeholder="Enter Phone Number"
                                                className="form-control"
                                                id="phone"
                                                value={AiPageFormData.phone}
                                                onChange={handleChange}
                                                required
                                            />
                                            <label htmlFor="phone">Enter Phone Number <span style={{ color: 'red' }}>*</span></label>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-floating">
                                            <input
                                                type="text"
                                                name="company"
                                                placeholder="Enter Company Name"
                                                className="form-control"
                                                id="company"
                                                value={AiPageFormData.company}
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="company">Enter Company Name </label>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-floating">
                                            <input
                                                type="text"
                                                name="projectDetails"
                                                placeholder="Project Details"
                                                className="form-control"
                                                id="details"
                                                value={AiPageFormData.projectDetails}
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="details">Project Details <span style={{ color: 'red' }}>*</span></label>
                                        </div>
                                    </div>

                                    <div className="col-md-5 contact-section-button">
                                        <button type="submit" disabled={!isFormValid()} className="btn btn-primary">{loading ? 'Loading...' : 'Submit'}</button>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-5">
                            <div className="solution-text-together">
                                <img src="/images/SVG/Star.svg" alt="Star" />
                                <h1>Let’s Make Something Great Together</h1>
                                <p>Ready to take the next step? Connect with us, and let’s bring your ideas to life</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content-side-box-together">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-5">
                            <div className="company-logo-image">
                                <img src={filteredSlide?.profileImage} alt="Profile" />
                            </div>
                        </div>

                        <div className="col-xl-9 col-lg-9 col-md-7">
                            <div className="comapny-together-content">
                                <p>{filteredSlide?.clientReview}</p>
                            </div>

                            <div className="company-text-owner">
                                <h1>{filteredSlide?.profileName}</h1>
                                <p>Operations Director</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <DevelopingSolution heading="Developing Solutions That Transcend Mere Digital Transformation"  subheading="We deliver innovative solutions that surpass digital transformation, driving impactful and lasting success." />

            <Footer />
        </>
    )
}

export default CaseStudyDetial
export const HomeSectionsData = [
  {
    id: 1,
    backgroundImage: "/images/countries/home/banner-1.png",
    title: "The Best Development Company Awarded By ",
    awardLogo: "/images/payoneer-circle.png",
    awardName: "Payoneer",
    heading: [
      { text: "Your Catalyst for Generative ", isSpan: false },
      { text: " AI Solutions", isSpan: true },
      { text: " & ", isSpan: false },
      { text: "Transformative Experiences", isSpan: true },
    ],
    subHeading:
      "Results-driven solutions and Generative AI services for Startups, SMBs, and Enterprises looking to automate their workflows.",
    buttonText: "Build Your Legacy",
    buttonLink: "/contact-us",
  },
  {
    id: 2,
    backgroundImage: "/images/countries/home/banner-2.png",
    title: "The Best Development Company Awarded By ",
    awardLogo: "/images/payoneer-circle.png",
    awardName: "Payoneer",
    heading: [
      { text: "Empowering Innovation with Cutting-Edge ", isSpan: false },
      { text: "Blockchain", isSpan: true },
      { text: " ", isSpan: false },
      { text: "Services", isSpan: true },
    ],
    subHeading:
      "Leverage our results-driven blockchain services designed for Startups, SMBs, and Enterprises. We deliver secure, scalable, and innovative solutions to help you maximize IT investments and drive business growth.",
    buttonText: "Build Your Legacy",
    buttonLink: "/contact-us",
  },
  {
    id: 3,
    backgroundImage: "/images/countries/home/banner-3.png",
    title: "Leading Web App Development Company endorsed by",
    awardLogo: "/images/payoneer-circle.png",
    awardName: "Clutch",
    heading: [
      { text: "Empowering Businesses with Innovative ", isSpan: false },
      { text: "Web Applications", isSpan: true },
      { text: " & ", isSpan: false },
      { text: "Seamless User Experiences", isSpan: true },
    ],
    subHeading:
      "Top-tier web app development services tailored for Startups, SMBs, and Enterprises—driving digital transformation and fostering user engagement.",
    buttonText: "Start Your Project",
    buttonLink: "/contact-us",
  },
  {
    id: 4,
    backgroundImage: "/images/countries/home/banner-4.png",
    title: "Award-Winning Mobile App Development Company endorsed by",
    awardLogo: "/images/payoneer-circle.png",
    awardName: "Clutch",
    heading: [
      { text: "Transforming Ideas into Cutting-Edge ", isSpan: false },
      { text: "Mobile Applications", isSpan: true },
      { text: " & ", isSpan: false },
      { text: "Exceptional User Experiences", isSpan: true },
    ],
    subHeading:
      "Expert Mobile App Development Services for Startups, SMBs, and Enterprises—delivering advanced solutions to empower your business on the go.",
    buttonText: "Launch Your App",
    buttonLink: "/contact-us",
  },
];
import React, { useEffect } from "react";
import SimilerBanner from "../components/similerBanner/SimilerBanner";
import DevelopingSolution from "../components/developingsolution/DevelopingSolution";
import Testimonial from "../components/testimonila/Testimonial";
import CoverTheStack from "../components/coverthestack/CoverTheStack";
import SecondFaq from "../components/secondFaq/SecondFaq";
import Footer from "../components/footer/Footer";
import { Helmet } from "react-helmet";
import MegaHeader from "../components/MegaHeader/MegaHeader";
import TrendsInsights from "../components/trends-n-insights/TrendsInsights";
import RoadMap from "../components/roadMap/RoadMap";
import WhoWeServe from "../components/who-we-serve/WhoWeServe";
import HighValues from "../components/high-values/HighValues";
import TypesOfBuild from "../components/types-of-build/TypesOfBuild";
import WhyChooseBitsclan from "../components/why-choose-bitsclan/WhyChooseBitsclan";
import IndustriesTabs from "../components/industries-tabs/IndustriesTabs";
import {
    IndustryTabsData,
} from "../data/industriesTabsData";
import DevelopmentServices from "../components/development-services/DevelopmentServices";
import ContactSection from "../components/contactSection/ContactSection";
import { WebServicesIndustryTabsItemsData } from "../data/web-app-service-page";
import NewEngagementModels from "../components/new-engagment-modal";
import { DevelopmentServicesData, faqData, HighvaluesData, mainBannerImg, schemaData, tabItems, tabs, TypesOfBuildData, WhoWeServeData, WhyChooseBitsclanData } from "../data/software-quality-assurence-data";

const MobileDevelopmentServices = () => {



    return (
        <>
            <Helmet>
                <title>Software Quality Assurance Services | QA Testing Company</title>
                <meta name="description" content="Ensure flawless software performance with our comprehensive quality assurance and testing services tailored for perfection" />
                <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
            </Helmet>

            <div className="sticky-header">
                <MegaHeader />
            </div>

            <SimilerBanner diffBannerBackgroundImage={mainBannerImg} mainHeading="Software Quality" greenHeading="Assurance Service" subHeading="Trusted by Startups, SMBs, and Enterprises alike" content="Our software quality assurance services do not create release blocks but enhance the entire development cycle from the beginning." btnText2={"Let's Get Started"} sideImage={''} />

            <Testimonial
                heading="Web Development Company of the Year 2021"
                content="We received the Best Web Development Company of the Year award from Payoneer in 2021. We bring the same expertise, dedication, vision, and conviction to every service we provide."
            />

            <DevelopmentServices tabs={DevelopmentServicesData}
                heading='Our Expansive Range of Software Quality Assurance Services.'
                subheading="Our web application services cover everything from custom cloud platforms and interactive portals to seamless front-end and robust back-end development. We deliver secure, scalable solutions designed to evolve with your business, including expert consulting and e-commerce development."
            />

            <TypesOfBuild typeOfApps={TypesOfBuildData} heading="Types of Software Testing We Offer" sideImage="software-quality-assurence.png" />

            <WhoWeServe
                cardContent={WhoWeServeData}
                heading="Who We Serve"
                subHeading="We help businesses of all sizes achieve flawless software performance through comprehensive software quality assurance services. Whether you're a startup launching a new product, a software company scaling your platform, or an enterprise requiring large-scale testing solutions, our QA experts ensure bug-free, high-performance, and secure software."
            />

            <WhyChooseBitsclan
                tabs={WhyChooseBitsclanData}
                heading="Why Choose Bitsclan as Your QA Services Company?"
                subheading="At Bitsclan, we provide end-to-end software quality assurance, ensuring your applications are bug-free, scalable, and secure. Our QA experts focus on seamless functionality, automation, and performance testing so your software meets industry standards and user expectations. From automated testing to manual evaluations, we tailor QA solutions to keep your software at its best."
            />

            <HighValues
                heading="High-Value Features for High-Performance QA Services"
                subHeading="Our QA services ensure your software meets top industry standards with seamless functionality, security, and efficiency. We focus on comprehensive testing, automation, and real-time monitoring to deliver error-free, scalable, and high-performance applications."
                boxContent={HighvaluesData}
                btnLink="#"
                btnText="Consult Project Scope"
            />

            <NewEngagementModels />

            <RoadMap heading="Our Proven Roadmap to Deliver Flawless Software Quality Assurance" content="Ensuring your software runs smoothly is our top priority. We follow a structured QA roadmap designed to identify, prevent, and resolve defects efficiently. From initial planning to continuous optimization, we make sure your software meets high-quality standards, is bug-free, and performs flawlessly across all platforms. Here's how we ensure excellence:" roadMapsteps={["We dive deep into your software’s architecture, define testing objectives, and map out a QA strategy that ensures seamless functionality.", "Our QA experts rigorously test for functionality, performance, security, and compatibility, ensuring your software meets industry quality standards.", "We provide ongoing testing, real-time issue resolution, and quality improvements to maintain software stability, security, and reliability.", "We design a customized testing framework, selecting the right automated and manual testing approaches to ensure efficiency and high performance."]} roadMapHeading={["", "Implementation & Execution", "Monitoring & Optimization", "Test Strategy & Proof of Concept"]} />

            <CoverTheStack
                whereStart={"Test Automation Tools"}
                mainHeading={"Enhance Your QA with a Powerful Tech Stack"}
                subHeading={
                    "At Bitsclan, we leverage industry-leading technologies to ensure top-tier software quality assurance. Our advanced tech stack optimizes testing processes, enhances automation, and ensures seamless performance, security, and scalability throughout the software development lifecycle."
                }
                tabs={tabs}
                tabItems={tabItems}
            />

            <IndustriesTabs
                mainheading={"Industries we serve in Software Quality Assurance"}
                tabs={IndustryTabsData}
                defaultTab={"Healthcare"}
                tabsItems={WebServicesIndustryTabsItemsData}
            />

            <DevelopingSolution heading="Developing Solutions That Transcend Mere Digital Transformation" subheading="We deliver innovative solutions that surpass digital transformation, driving impactful and lasting success." />

            <TrendsInsights specificClass="mobile-dev-page" category="quality-assurance-services" />

            <SecondFaq
                specificClass="mobile-dev-page"
                faqs={faqData}
                mainHeading={"FAQs"}
                subHeading={
                    "Get quick answers to your questions about our mobile app development process and services."
                }
            />
            <ContactSection />
            <Footer />
        </>
    );
};

export default MobileDevelopmentServices;

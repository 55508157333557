export const tabs = [
    "Languages",
    "Libraries & UI Libraries",
    "Frameworks",
    "Testing",
    "Platforms",
];

export const tabItems = [
    { tab: "Languages", name: "Ruby", icon: "/images/tech-stack-icons/ruby.svg" },
    { tab: "Languages", icon: "/images/icon7.png", name: "HTML5" },
    { tab: "Languages", icon: "/images/icon6.png", name: "CSS3" },
    { tab: "Languages", icon: "/images/icontwo.png", name: "JavaScript" },

    { tab: "Libraries & UI Libraries", icon: "/images/tech-stack-icons/bootstrap.svg", name: "Bootstrap" },
    { tab: "Libraries & UI Libraries", icon: "/images/tech-stack-icons/tailwind.svg", name: "Tailwind CSS" },
    { tab: "Libraries & UI Libraries", icon: "/images/tech-stack-icons/react.svg", name: "React.js" },
    { tab: "Libraries & UI Libraries", icon: "/images/tech-stack-icons/angular.svg", name: "Angular" },
    { tab: "Libraries & UI Libraries", icon: "/images/tech-stack-icons/vue-js.svg", name: "Vue.js" },

    { tab: "Frameworks", name: "Ruby on Rails", icon: "/images/tech-stack-icons/rails.svg" },
    { tab: "Frameworks", icon: "/images/tech-stack-icons/graph.svg", name: "GraphQl" },

    { tab: 'Platforms', icon: '/images/cloud3.png', name: 'AWS' },
    { tab: 'Platforms', icon: '/images/DigitalOcean.png', name: 'Digital Ocean' },
    { tab: 'Platforms', icon: '/images/tech-stack-icons/heroku.svg', name: 'Heroku' },

    { tab: "Testing", name: "Redis", icon: "/images/tech-stack-icons/rspec.svg", },
    { tab: "Testing", name: "Jest", icon: "/images/tech-stack-icons/jest.svg", },
];

export const techCombinations = [
    {
        title: "Ruby on Rails + React + PostgreSQL",
        description:
            "Ruby on Rails as the backend framework, React for a dynamic front-end, and PostgreSQL as a database help build scalable and high-performing web applications.",
        icons: ["rails.svg", "react.svg", "postgres.svg"],
    },
    {
        title: "Ruby on Rails + Angular + MySQL",
        description:
            "Ruby on Rails for server-side logic, Angular for a modern front-end, and MySQL as a reliable relational database create robust web solutions for businesses.",
        icons: ["rails.svg", "angular.svg", "mysql-whale-icon.svg"],
    },
    {
        title: "Ruby on Rails + Vue.js + MongoDB",
        description:
            "Vue.js on the front end, Ruby on Rails as the backend, and MongoDB as a NoSQL database provide an efficient stack for interactive and flexible applications.",
        icons: ["rails.svg", "vue-js.svg", "mongodb.svg"],
    },
    {
        title: "Ruby on Rails + GraphQL + PostgreSQL",
        description:
            "GraphQL enables flexible data queries, Ruby on Rails handles the server logic, and PostgreSQL ensures secure and structured data storage for modern applications.",
        icons: ["rails.svg", "graphql.svg", "postgres.svg"],
    },
];

export const schemaData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
        "@type": "Question",
        "name": "What is Ruby on Rails (RoR) used for?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Ruby on Rails (RoR) is a powerful web application framework used to build dynamic, database-driven websites and applications. It’s ideal for developing scalable web apps, eCommerce platforms, content management systems, and custom software solutions"
        }
    }, {
        "@type": "Question",
        "name": "How much does it cost to develop with Ruby on Rails?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "The cost of developing with Ruby on Rails varies depending on the complexity of your project, the number of features required, and the development timeline. We offer customized quotes based on your specific needs to ensure you get the best value."
        }
    }, {
        "@type": "Question",
        "name": "What is the hourly rate for a Ruby on Rails developer?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "The hourly rate for a Ruby on Rails developer can range widely based on their experience level and the project’s requirements. At Bitsclan, we offer competitive rates that reflect the expertise and quality of our RoR developers."
        }
    }, {
        "@type": "Question",
        "name": "Is Ruby on Rails good for web application development?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Yes, Ruby on Rails is an excellent choice for web application development. It’s known for its efficiency, scalability, and strong community support, making it ideal for building robust and high-performance web applications."
        }
    }, {
        "@type": "Question",
        "name": "Is Ruby on Rails frontend or backend?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Ruby on Rails is primarily a backend framework, that handles the server-side logic, database interactions, and application structure. However, it can be seamlessly integrated with front-end technologies like HTML, CSS, and JavaScript."
        }
    }, {
        "@type": "Question",
        "name": "What sets your Ruby on Rails developers apart?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Our Ruby on Rails developers stand out due to their deep expertise, commitment to quality, and passion for innovation. They are skilled in delivering custom solutions that are both efficient and scalable, ensuring your project’s success."
        }
    }]
}
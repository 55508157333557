import React from "react";
import HirePageBanner from "../components/HirePageBanner/HirePageBanner";
import DevelopmentPerformance from "../components/DevelopmentPerformance/DevelopmentPerformance";
import SecondFaq from "../components/secondFaq/SecondFaq";
import Footer from "../components/footer/Footer";
import MegaHeader from "../components/MegaHeader/MegaHeader";
import { Helmet } from "react-helmet";
import {
  HireMernstackBannerList,
  HireMernstackFactsData,
  HireMernstackFaqData,
  HireMernstackHirignDetails,
  HireMernstackServicesData,
  HireMernstackSolutionsData,
  HireMernstackSteps,
  mainBannerImg,
  tabItems,
  tabs,
  techCombinations,
} from "../data/hireMernstackPageData";
import HirePageExperties from "../components/hirepageExperties/HirePageExperties";
import HirePageSteps from "../components/hire-page-steps/HirePageSteps";
import HireExpertFacts from "../components/hire-expert-facts/HireExpertFacts";
import HirePageEngagmentModels from "../components/hire-page-engagment-models/HirePageEngagmentModels";
import HirePageAppIdea from "../components/hire-page-app-idea/HirePageAppIdea";
import ContactSection from "../components/contactSection/ContactSection";
import TrendsInsights from "../components/trends-n-insights/TrendsInsights";
import HireCombinationSection from "../components/hire-combination-sectiono/HireCombinationSection";
import CoverTheStack from "../components/coverthestack/CoverTheStack";

const HireMernStack = () => {
  return (
    <>
      <Helmet>
        <title>Hire MERN Stack Developers | Full-Stack Web Development</title>
        <meta
          name="description"
          content="Hire MERN Stack developers for dynamic, full-stack web applications. Work with skilled experts proficient in MongoDB, Express.js, React.js, and Node.js. Start now!"
        />
      </Helmet>

      <div className="sticky-header">
        <MegaHeader />
      </div>

      <HirePageBanner
        diffBannerBackgroundImage={mainBannerImg?.backgroundImage}
        image={""}
        mainHeading="Hire Mernstack Developers"
        content="Build scalable, high-performance web applications with our expert MERN Stack developers. Whether you're a startup or an enterprise, we provide top-tier development services tailored to your needs."
        btnText="Consult our mern stack developers"
        bannerList={HireMernstackBannerList}
      />

      <HirePageExperties
        heading="Our MERN Stack Developer Expertise"
        discription="Our MERN Stack developers specialize in building scalable, high-performance web applications using MongoDB, Express.js, React.js, and Node.js. We ensure smooth integration across the entire tech stack for seamless functionality."
        servicesData={HireMernstackServicesData}
      />

      <DevelopmentPerformance
        heading="Why Choose Bitsclan to Hire "
        greenHeading="MERN Developers?"
        subHeading="At Bitsclan, we build high-performance, scalable web applications using the MERN stack (MongoDB, Express.js, React.js, Node.js). By integrating modern frontend and mobile technologies, we create custom solutions that align with your business goals. Whether you're developing a web platform or a mobile solution, we ensure fast development, smooth performance, and an outstanding user experience across all devices."
        solutions={HireMernstackSolutionsData}
      />

      <HireCombinationSection combinations={techCombinations} heading="Top MERN Stack Combinations for Exceptional Development" description="Leverage the power of the MERN stack to build dynamic, scalable, and high-performance web applications. Hire expert MERN developers to create seamless, full-stack solutions tailored to your business needs." />

      <HirePageSteps
        heading={"Hire MERN Stack Developers in 4 Easy Steps"}
        subHeading={
          "Finding the right MERN stack developer is simple with our streamlined 4-step process. We ensure seamless integration, fast onboarding, and the perfect fit for your project."
        }
        steps={HireMernstackSteps}
      />

      <CoverTheStack
        whereStart={"Frontend"}
        mainHeading={"Hire Expert MERN Stack Developers for Full-Stack Web Solutions"}
        subHeading={"At Bitsclan, we empower your web development journey with a cutting-edge MERN stack, ensuring seamless, scalable, and high-performance applications. Our expert developers specialize in building dynamic, end-to-end web solutions using MongoDB, Express.js, React, and Node.js to meet your business needs."}
        tabs={tabs}
        tabItems={tabItems} />

      <HireExpertFacts
        heading="Quick Facts About "
        greenHeading="Our MERN Stack Team"
        facts={HireMernstackFactsData}
        diffBannerBackgroundImage={mainBannerImg?.factBackgroundImage}
      />

      <HirePageAppIdea heading="Turn Your App Idea into a Working Prototype in Just Weeks!" content="Have a great idea but unsure how to bring it to life? Our expert Ruby on Rails developers will help you build a functional prototype in as little as 6-8 weeks. Validate your vision, test user engagement, and lay the groundwork for a successful launch—quickly and efficiently." />

      <HirePageEngagmentModels description="We offer flexible ways to work based on your needs! Whether it’s a fixed-price project, a dedicated team, or hourly contracts, our models ensure smooth collaboration, high efficiency, and top-quality results in MERN stack development." hirignDetails={HireMernstackHirignDetails} />

      <TrendsInsights specificClass="mobile-dev-page" category="all" />

      <SecondFaq
        faqs={HireMernstackFaqData}
        specificClass="mobile-dev-page"
        mainHeading={"Frequently Asked Questions"}
        subHeading={"Discover answers to common questions about MERN Stack Development."}
      />

      <ContactSection />

      <Footer />
    </>
  );
};

export default HireMernStack;

const projectData = [
    {
        id: 1,
        title: "Ice Cream English",
        category: "CRMs",
        description: "Bitsclan Solutions has helped them in their website development and search engine optimization (SEO). After the initial meeting, we brought a lot of ideas towards their website development and maintenance.",
        image: "/images/portfolio-image/ice-cream1.png",
        image2: "/images/portfolio-image/ice-cream2.png",
        image3: "/images/portfolio-image/ice-cream3.png"
    },
    {
        id: 2,
        title: "Tilini",
        category: "CRMs",
        description: "Tilini was established in 2009 as the ladies' e-commerce platform based in the US. All types of womenswear are available on easy shipping.",
        image: "/images/portfolio-image/titini1.png",
        image2: "/images/portfolio-image/titini2.svg",
        image3: "/images/portfolio-image/titini3.svg"
    },
    {
        id: 3,
        title: "Wild Media CRM",
        category: "CRMs",
        description: "Wild Media is another CRM to manage different types of locations and advertisements. Following are the features/Module in this CRM:",
        image: "/images/portfolio-image/world-side-media1.svg",
        image2: "/images/portfolio-image/world-side-media2.svg",
        image3: "/images/portfolio-image/world-side-media3.svg"
    },
    {
        id: 4,
        title: "Duliver",
        category: "Mobile Applications",
        description: "Duliver is a UAE-based courier company that requires its drivers to use this program online to update all their regular delivery work.It is the most convenient way to keep track of all shipment records, so both the client and the recipient can be considered helpful.Duliver provides shippers a safe and easy route around the UAE and worldwide to pick up and transport courier shipments and parcels of any scale.",
        image: "/images/portfolio-image/duliver1.jpg",
        image2: "/images/portfolio-image/duliver2.jpg",
        image3: "/images/portfolio-image/duliver3.jpg"
    },
    {
        id: 5,
        title: "Netexem",
        category: "Mobile Applications",
        description: "Netexem is an open source instant messaging and voice over IP (VoIP) phone that makes it possible to communicate freely with people over the internet via voice and text messaging. Since it follows open standards from the telecommunications industry (SIP, RTP), Netexem is inter-operable with most PBXs and SIP servers and can be used with any SIP VoIP operator. Discover complementary products in our complete solution for instant messaging and IP-to-IP calls.",
        image: "/images/portfolio-image/nexturm1.webp",
        image2: "/images/portfolio-image/nexturm2.webp",
        image3: "/images/portfolio-image/nexturm3.webp"
    },
    {
        id: 6,
        title: "The House App",
        category: "Mobile Applications",
        description: "The House App is a dynamic platform designed for advertising homes for rent or sale, connecting customers and real estate agents with their dream properties.The app offers advanced features, including customizable slider timing, screen click time, menu time, and mute time, providing a tailored user experience.The admin panel enables efficient management of user logs, properties, and other key features.With version 2.0 in development, The House App is set to deliver even greater control and functionality for seamless property management.",
        image: "/images/portfolio-image/house1.jpg",
        image2: "/images/portfolio-image/house2.jpg",
        image3: "/images/portfolio-image/house3.jpg"
    },
    {
        id: 7,
        title: "On Purpose Marketplace",
        category: "Shopify",
        description: "On Purpose Marketplace is a Personal Empowerment Company. It provides almost all of the styles you desire like merchandise and personal empowerment tools. It also includes men & women clothing.",
        image: "/images/portfolio-image/shopify1.webp",
        image2: "/images/portfolio-image/shopify2.webp",
        image3: "/images/portfolio-image/shopify3.webp"
    },
    {
        id: 8,
        title: "Hyper Planner",
        category: "Web Applications",
        description: "Hyper Planner is the platform to increase followers on Instagram. It helps to grow a massive following & increase engagement naturally, & reach a wider audience.",
        image: "/images/portfolio-image/hyper-planner1.jpg",
        image2: "/images/portfolio-image/hyper-planner2.jpg",
        image3: "/images/portfolio-image/hyper-planner3.jpg"
    },
    {
        id: 9,
        title: "The Rogue Hypnotist",
        category: "Web Applications",
        description: "The Rogue Hypnotist is an ebook by Mark Anthony Hypnotist who sells out his podcast on various physical training aspects. Bitsclan developed the website and landing pages. The services for this project are:",
        image: "/images/portfolio-image/the-rough1.png",
        image2: "/images/portfolio-image/the-rough2.png",
        image3: "/images/portfolio-image/the-rough3.png"
    },
    {
        id: 10,
        title: "Hypnotist",
        category: "Web Applications",
        description: "Hypnotist is a personal branding website of Mark Anthony that provides consultancies and courses on maintaining a healthy life. All his seminars and training videos and tutorials are added to the website.",
        image: "/images/portfolio-image/hypnosit1.png",
        image2: "/images/portfolio-image/hypnosit2.png",
        image3: "/images/portfolio-image/hypnosit3.png"
    },
    {
        id: 11,
        title: "Grey Readers",
        category: "Web Applications",
        description: "Grey Readers is a result-oriented, expert curate, and knowledge-driven website for millions of readers. They are specialized in writing professional blogs based on technology, lifestyle, media, world affairs, and artificial intelligence.",
        image: "/images/portfolio-image/grey-reader1.png",
        image2: "/images/portfolio-image/grey-reader2.png",
        image3: "/images/portfolio-image/grey-reader3.png"
    },
    {
        id: 12,
        title: "Apex Luxury",
        category: "Web Applications",
        description: "Apex Luxury Car Hire offers the finest car rentals. It rents out luxurious cars to different localities in more than 50 countries and has offices worldwide.",
        image: "/images/portfolio-image/apex1.jpg",
        image2: "/images/portfolio-image/apex2.jpg",
        image3: "/images/portfolio-image/apex3.jpg"
    },
    {
        id: 13,
        title: "Pinna Clemendo",
        category: "Web Applications",
        description: "Pinna Clemendo is a website built on WordPress to showcase the media talent hunt of different categories. This website is mainly for the brands and media consultants like showbiz.",
        image: "/images/portfolio-image/pina1.svg",
        image2: "/images/portfolio-image/pina2.svg",
        image3: "/images/portfolio-image/pina3.svg"
    },
    {
        id: 14,
        title: "Creatif Design",
        category: "Web Applications",
        description: "The website is related to Interior Designing, design aspects, structural systems, and its various elements.",
        image: "/images/portfolio-image/craft1.jpg",
        image2: "/images/portfolio-image/craft2.jpg",
        image3: "/images/portfolio-image/craft3.jpg"
    },
    {
        id: 15,
        title: "Military Place",
        category: "Web Applications",
        description: "Military Place is a website to sell out military items and gadgets. Bitsclan built an e-commerce platform for Military Place.",
        image: "/images/portfolio-image/miltary-place1.svg",
        image2: "/images/portfolio-image/miltary-place2.svg",
        image3: "/images/portfolio-image/miltary-place3.svg"
    },
    {
        id: 16,
        title: "DentalBidz",
        category: "Web Applications",
        description: "DentalBidz is a UK-based platform designed to simplify buying, selling, and auctioning within the dental industry. It offers a secure environment with fully vetted members, ensuring trust and reliability for all transactions. The platform enables open market selling and online bidding, providing flexibility and transparency for users. Buyers can easily register to explore opportunities, while sellers benefit from specialized features to market and sell their dental practices. Additionally, DentalBidz offers access to property solicitor consultations, ensuring legal support for property-related transactions. The platform is a one-stop solution tailored to meet the unique needs of the dental community.",
        image: "/images/portfolio-image/DentalBidz-Final-1.jpg",
        image2: "/images/portfolio-image/DentalBidz-Final-2.jpg",
        image3: "/images/portfolio-image/DentalBidz-Final-3.jpg"
    },
    {
        id: 17,
        title: "Local Air",
        category: "Web Applications",
        description: "Local Air is well equipped to handle all of the ‎heating and cooling needs. Their top HVAC technicians have experience in operating on ‎virtually all major brands, including Carrier, Bryant, Goodman, and Trane. ‎",
        image: "/images/portfolio-image/Local-Air-Final-1.jpg",
        image2: "/images/portfolio-image/Local-Air-Final-2.jpg",
        image3: "/images/portfolio-image/Local-Air-Final-3.jpg"
    },
    {
        id: 18,
        title: "Farmers Marketing",
        category: "Web Applications",
        description: "Farmers Marketing is a WordPress-based platform offering tailored digital marketing services to help businesses grow their online presence. With programs like Go To Market, Market Share, and À La Carte, it provides flexible solutions for diverse needs. Key features include Local Search optimization, Website Audits, Keyword Strategies, PPC & Lead Generation, Marketing Automation, and Content Creation, ensuring comprehensive support for businesses to attract, engage, and convert their audience.",
        image: "/images/portfolio-image/Farmers-Marketing-Final-1.jpg",
        image2: "/images/portfolio-image/Farmers-Marketing-Final-2.jpg",
        image3: "/images/portfolio-image/Farmers-Marketing-Final-3.jpg"
    },
    {
        id: 19,
        title: "Sheena Perry",
        category: "Web Applications",
        description: "Sheena Perry is a prolific author known for her captivating books, all of which are available for purchase exclusively on this website.",
        image: "/images/portfolio-image/Seena-Perry1.jpg",
        image2: "/images/portfolio-image/Seena-Perry2.jpg",
        image3: "/images/portfolio-image/Seena-Perry3.jpg"
    },
    {
        id: 20,
        title: "JBI Software",
        category: "Web Applications",
        description: "JBI Software Inc. is a partner of Bitsclan Solutions. JBI Software launched a product that translates the patient recording into English for easy understanding of doctors. This product translates into written and audible files. Bitsclan developed the website and added basic features of its solutions.",
        image: "/images/portfolio-image/scaled1.jpg",
        image2: "/images/portfolio-image/scaled2.png",
        image3: "/images/portfolio-image/scaled3.jpg"
    },
    {
        id: 21,
        title: "Femallay",
        category: "Web Applications",
        description: "The agenda was to uplift their online and social presence through good UI/UX development and optimized rank. The services were On-Page and Off-Page SEO, Website Design, Development, and Maintenance. The project ended in 2019.",
        image: "/images/portfolio-image/Femally-Final-1.jpg",
        image2: "/images/portfolio-image/Femally-Final-2.jpg",
        image3: "/images/portfolio-image/Femally-Final-3.jpg"
    },
    {
        id: 22,
        title: "Acamate",
        category: "Web Applications",
        description: "Acamate is your go-to platform for professional writing services, including essays, research papers, and presentations, tailored to undergraduate, bachelor, and professional levels. Alongside expert writing, Acamate offers PSD to WordPress conversions, website design, customization, and support & maintenance, delivering excellence in both academic and technical solutions.",
        image: "/images/portfolio-image/Acamate1.jpg",
        image2: "/images/portfolio-image/Acamate2.jpg",
        image3: "/images/portfolio-image/Acamate3.jpg"
    },
    {
        id: 23,
        title: "Bohema",
        category: "Web Applications",
        description: "Bohema has been training professionals since 2001. Discover now our online store, where people will find very high-quality products.",
        image: "/images/portfolio-image/Bohema-Final-1.jpg",
        image2: "/images/portfolio-image/Bohema-Final-2.jpg",
        image3: "/images/portfolio-image/Bohema-Final-3.jpg"
    },
    {
        id: 24,
        title: "International Sailing Academy",
        category: "Web Applications",
        description: "International Sailing Academy provides world-class laser sailing training. In their coaching, they provide exceptional accommodations, delicious meals, and the best equipment. Their destined places are Mexico, the United States, and Portugal.",
        image: "/images/portfolio-image/Sailing1.jpg",
        image2: "/images/portfolio-image/Sailing2.png",
        image3: "/images/portfolio-image/Sailing3.jpg"
    },
    {
        id: 25,
        title: "Soft&Sweet",
        category: "Web Applications",
        description: "Soft&Sweet is a UK-based online wholesale distributor specializing in confectionery, beverages, soft drinks, and snacks. The platform offers an Admin Portal for seamless management, a Customer Registration Portal, and streamlined tools for online wholesale buying and selling, catering to businesses in the sweet and soft drink market.",
        image: "/images/portfolio-image/home-itesm1.jpg",
        image2: "/images/portfolio-image/home-itesm2.jpg",
        image3: "/images/portfolio-image/home-itesm3.jpg"
    },
    {
        id: 26,
        title: "Dekarta",
        category: "Web Applications",
        description: "Dekarta is a UK-based global trading company specializing in branded FMCG products. With a portfolio of over 2,000 top brands, Dekarta ships to nearly 50 countries, serving over 2,300 clients. The company excels in import/export and distribution to wholesale groups and supermarket chains worldwide.",
        image: "/images/portfolio-image/Dekarta1.jpg",
        image2: "/images/portfolio-image/Dekarta2.jpg",
        image3: "/images/portfolio-image/Dekarta3.jpg"
    },
    {
        id: 27,
        title: "Sencon Homes",
        category: "Web Applications",
        description: "Sencon Homes is a leading Australian company offering expert building consulting services, insurance projects, and residential and commercial construction from concept to completion. Partnered with Bitsclan for over three years, all IT operations, including CMS integration, CRM development, web design, analysis, branding, and support, are seamlessly managed to enhance its digital presence and operational efficiency.",
        image: "/images/portfolio-image/Sencon-Homes-Final-1.jpg",
        image2: "/images/portfolio-image/Sencon-Homes-Final-2.jpg",
        image3: "/images/portfolio-image/Sencon-Homes-Final-3.jpg"
    },
    {
        id: 28,
        title: "Cruzar Hair Design",
        category: "Web Applications",
        description: "Cruzar Hair Design is a renowned hair salon in Germany, offering a wide range of hairstyles and personalized services. The salon’s website features CMS integration, website development, Google Map integration, and ongoing support and maintenance, ensuring a seamless online experience for clients.",
        image: "/images/portfolio-image/Cruzar-Hair-Design-Final-2.jpg",
        image2: "/images/portfolio-image/Cruzar-Hair-Design-Final-3.jpg",
        image3: "/images/portfolio-image/Cruzar-Hair-Design-Final-1.jpg"
    },
    {
        id: 29,
        title: "Fritz",
        category: "Web Applications",
        description: "Fritz is a comprehensive platform offering physical therapy, sports medicine, and wellness services. It includes modules for patient appointments, info forms, an admin panel, and online coaching. With a focus on treatment methods, fitness training, and health tips, Fritz provides CMS integration, custom design, website development, Google Map integration, and ongoing support and maintenance to ensure a seamless and effective experience.",
        image: "/images/portfolio-image/Fritz-Physical-Therapy-Final-1.jpg",
        image2: "/images/portfolio-image/Fritz-Physical-Therapy-Final-2.jpg",
        image3: "/images/portfolio-image/Fritz-Physical-Therapy-Final-3.jpg"
    },
    {
        id: 30,
        title: "Bitzon",
        category: "Web Applications",
        description: "Bitzon is a digital blockchain technology application developed on a custom website. It provides comprehensive digital asset businesses such as currency and contract transactions. Digital currency can be sale and purchase in this blockchain. This application covers multiple platforms of iOS and Android and supports full business functions.",
        image: "/images/portfolio-image/Bitzon-Final-1.jpg",
        image2: "/images/portfolio-image/Bitzon-Final-2.jpg",
        image3: "/images/portfolio-image/Bitzon-Final-3.jpg"
    },
    {
        id: 31,
        title: "Infant Optics",
        category: "Web Applications",
        description: "Infant Optics is the maker of the top-selling DXR-8 and DXR-5 video baby monitors on Amazon. Known for their reliable baby monitoring products, Infant Optics offers features such as locations, location owners, designers, packages, location managers, and a comprehensive resource center to enhance the user experience and support efficient monitoring.",
        image: "/images/portfolio-image/Infant-Optics-Final-1.jpg",
        image2: "/images/portfolio-image/Infant-Optics-Final-2.jpg",
        image3: "/images/portfolio-image/Infant-Optics-Final-3.jpg"
    },
    {
        id: 32,
        title: "Performance",
        category: "Web Applications",
        description: "Bitsclan Solutions has helped them in their website development and search engine optimization (SEO). After the initial meeting, we brought a lot of ideas towards their website development and maintenance.",
        image: "/images/portfolio-image/performance1.png",
        image2: "/images/portfolio-image/performance2.png",
        image3: "/images/portfolio-image/performance3.png"
    },
    {
        id: 33,
        title: "Motion Matters",
        category: "Web Applications",
        description: "Motion Matters Physical Therapy is a therapeutic clinic established in 2011, offering various treatments such as Manual Therapy, Electrical Stimulation, Kinesiotaping, Therapeutic Exercise, H-WAVE, Yoga Therapy, Joint Mobilization, and Trigger Point Therapy. The clinic provides the convenience of online appointment booking for patients in remote areas. Developed by Bitsclan, the website includes an innovative Chatbot plugin that gathers user information and provides tailored solutions or answers. This plugin is also available for sale.",
        image: "/images/portfolio-image/motion-matters1.png",
        image2: "/images/portfolio-image/motion-matters2.png",
        image3: "/images/portfolio-image/motion-matters3.png"
    },
    {
        id: 34,
        title: "Town Title",
        category: "Web Applications",
        description: "Town Title is an award- winning US - based real estate company specializing in the buying, selling, and renting of residential and commercial properties.The website is fully compatible and responsive across all devices.The company offers services like CMS integration, CRM development, web design and development, web analysis, customization, and ongoing support and maintenance to enhance the user experience and streamline real estate transactions.Additional features include Title, Settlement, Healthcare, and Town Financial services.",
        image: "/images/portfolio-image/Town-Title-Final-1.jpg",
        image2: "/images/portfolio-image/Town-Title-Final-2.jpg",
        image3: "/images/portfolio-image/Town-Title-Final-3.jpg"
    },
    {
        id: 35,
        title: "Balanced Body",
        category: "Web Applications",
        description: "Balanced Body is a clinic offering physiotherapy, fitness training, and health advice to help individuals improve their well-being. The website provides convenient online services, including training sessions, appointment bookings, and access to patient information. Therapies offered include Manual Therapy, Trigger Point Therapy, Dry Needling, Electrical Stimulation, Balanced Therapy, Joint Mobilization/Manipulation, and Therapeutic Exercise to address a wide range of health needs.",
        image: "/images/portfolio-image/Balanced-Body-Final-1.jpg",
        image2: "/images/portfolio-image/Balanced-Body-Final-2.jpg",
        image3: "/images/portfolio-image/Balanced-Body-Final-3.jpg"
    },
    {
        id: 36,
        title: "Refine Estate",
        category: "Web Applications",
        description: "Refine Estates is the real estate company having nearly 40 years of experience finding people their dream luxury homes and are specialists in the prime central London residential sales and rentals property markets.",
        image: "/images/portfolio-image/Refine-Estat1.jpg",
        image2: "/images/portfolio-image/Refine-Estat2.jpg",
        image3: "/images/portfolio-image/Refine-Estat3.jpg"
    },
    {
        id: 37,
        title: "Bio Fluid Tech",
        category: "Web Applications",
        description: "Biofluid Technologies is a Life Sciences Company dedicated to offering the best-in-class Fetal Bovine Serum and associated products for cell culture applications. We designed the e-commerce website for their medical products.",
        image: "/images/portfolio-image/biofluid1.png",
        image2: "/images/portfolio-image/biofluid2.png",
        image3: "/images/portfolio-image/biofluid3.png"
    },
    {
        id: 38,
        title: "Urtasker,Inc",
        category: "Web Applications",
        description: "Urtasker is the e-commerce consulting services provider across the globe having headquarter in Long Island, New York while fully operational offices in Pakistan. They're specialized in Amazon, eBay, Shopify, and so on. Urtasker also offers digital marketing consultancies for e-commerce stores.Bitsclan Solutions has helped them in their website development and search engine optimization (SEO). After the initial meeting, we brought a lot of ideas towards their website development and maintenance. ",
        image: "/images/portfolio-image/Urtasker1.jpg",
        image2: "/images/portfolio-image/Urtasker2.jpg",
        image3: "/images/portfolio-image/Urtasker3.jpg"
    },
    {
        id: 39,
        title: "DaskTech",
        category: "Web Applications",
        description: "DaskTech is a virtual assistant company that provides virtual assistant services for Amazon, eBay, Shopify, Walmart, and many other e-commerce stores.",
        image: "/images/portfolio-image/desktech1.png",
        image2: "/images/portfolio-image/desktech2.png",
        image3: "/images/portfolio-image/desktech3.png"
    },
];


export default projectData;
import React from "react";
import HirePageBanner from "../components/HirePageBanner/HirePageBanner";
import CustomSoftware from "../components/custom-software/CustomSoftware";
import DevelopmentPerformance from "../components/DevelopmentPerformance/DevelopmentPerformance";
import GuaranteedResults from "../components/guaranteed-results/GuaranteedResults";
import SecondFaq from "../components/secondFaq/SecondFaq";
import Footer from "../components/footer/Footer";
import MegaHeader from "../components/MegaHeader/MegaHeader";
import { Helmet } from "react-helmet";
import {
  bannerList,
  facts,
  faqData,
  hirignDetails,
  mainBannerImg,
  servicesData,
  solutionsData,
  steps,
  tabItems,
  tabs,
  techCombinations,
} from "../data/hire-reactjs-data";
import HirePageExperties from "../components/hirepageExperties/HirePageExperties";
import HirePageSteps from "../components/hire-page-steps/HirePageSteps";
import HireExpertFacts from "../components/hire-expert-facts/HireExpertFacts";
import HirePageAppIdea from "../components/hire-page-app-idea/HirePageAppIdea";
import HirePageEngagmentModels from "../components/hire-page-engagment-models/HirePageEngagmentModels";
import ContactSection from "../components/contactSection/ContactSection";
import TrendsInsights from "../components/trends-n-insights/TrendsInsights";
import HireCombinationSection from "../components/hire-combination-sectiono/HireCombinationSection";
import CoverTheStack from "../components/coverthestack/CoverTheStack";

const HireReact = () => {
  return (
    <>
      <Helmet>
        <title>Hire React JS Developers | React JS Development Services</title>
        <meta
          name="description"
          content="Hire expert React JS developers for dynamic, user-friendly interfaces. Custom React JS development services for scalable solutions."
        />
      </Helmet>

      <div className="sticky-header">
        <MegaHeader />
      </div>

      <HirePageBanner
        diffBannerBackgroundImage={mainBannerImg?.backgroundImage}
        image={""}
        mainHeading="Hire React JS Developers"
        content="Your trusted source for top remote React JS Developers that is Perfect for startups and enterprises."
        btnText="Consult our React developers"
        bannerList={bannerList}
      />

      <HirePageExperties
        heading="Our React Developer Expertise"
        discription="Our React developers specialize in creating fast, scalable, and interactive user interfaces. We focus on delivering smooth performance and providing exceptional user experiences that make your application stand out."
        servicesData={servicesData}
      />

      <DevelopmentPerformance
        heading="Why Choose Bitsclan to Hire "
        greenHeading="React Developers"
        subHeading="By combining React with modern frontend and mobile technologies, we create powerful, scalable solutions tailored to your business needs. Whether it’s a web or mobile platform, our approach ensures smooth performance, efficient development, and great user experiences."
        solutions={solutionsData}
      />

      <HireCombinationSection combinations={techCombinations} heading="Top React.js Combinations for Exceptional Development" description="Leverage the power of React.js with the best technology combinations to build dynamic, high-performance, and scalable web applications. Hire expert React.js developers to create seamless, interactive, and future-ready solutions." />

      <HirePageSteps
        heading={"Hire React JS Developers in 4 Easy Steps"}
        subHeading={
          "Hiring top React developers is easy with our simple 4-step process. It ensures a smooth, fast, and efficient experience, matching you with the perfect developer for your project."
        }
        steps={steps}
      />

      <CoverTheStack
        whereStart={"Frameworks"}
        mainHeading={"Hire Expert React Developers for Scalable & Interactive Web Solutions"}
        subHeading={"At Bitsclan, we empower your web development journey with a cutting-edge React tech stack that ensures dynamic, high-performance, and user-friendly applications. Our skilled React developers specialize in building responsive, scalable, and feature-rich web applications tailored to your business needs."}
        tabs={tabs}
        tabItems={tabItems} />

      <HireExpertFacts
        heading="Quick Facts About "
        greenHeading="Our React Team"
        facts={facts}
        diffBannerBackgroundImage={mainBannerImg?.factBackgroundImage}
      />

      {/* <WhoWeServe cardContent={WhoWeServeData}
        heading="Benefits of outsourced React development"
        subHeading="Our business partners always expect minimal risks and professionalism in ROR development. We are dedicated to providing our clients with reliable and highly skilled developers who are capable of either building a product from scratch or enhancing one that already exists. Here are the key benefits of outsourcing React to Innowise." /> */}

      <HirePageAppIdea content="Have an idea but unsure how to proceed? Our Golang experts can help you build a functional prototype in just 6-8 weeks! Validate your vision, test user engagement, and establish a strong foundation for success with our expert development services." />

      <HirePageEngagmentModels description="Choose the engagement model that fits your project needs! Whether you need full-time, part-time, or hourly developers, we offer flexible options to ensure seamless collaboration and maximum efficiency throughout your project." hirignDetails={hirignDetails} />

      {/* <HirePageWeDeliver hirePageDeliver={hirePageDeliver} />

      <HirePageTabs /> */}

      <TrendsInsights specificClass="mobile-dev-page" category="all" />

      <SecondFaq
        faqs={faqData}
        specificClass="mobile-dev-page"
        mainHeading={"Frequently Asked Questions"}
        subHeading={
          "Explore our React development services for tailored web solutions, API creation, and deployment management!"
        }
      />

      {/* <HirePageServicesForm /> */}

      <ContactSection />

      <Footer />
    </>
  );
};

export default HireReact;

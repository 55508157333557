import React from "react";
import Footer from "../components/footer/Footer";
import HirePageBanner from "../components/HirePageBanner/HirePageBanner";
import DevelopmentPerformance from "../components/DevelopmentPerformance/DevelopmentPerformance";
import SecondFaq from "../components/secondFaq/SecondFaq";
import MegaHeader from "../components/MegaHeader/MegaHeader";
import { Helmet } from "react-helmet";
import {
  bannerList,
  facts,
  faqData,
  hirignDetails,
  mainBannerImg,
  servicesData,
  solutionsData,
  steps,
  tabItems,
  tabs,
  techCombinations,
} from "../data/hire-python-data";
import HirePageSteps from "../components/hire-page-steps/HirePageSteps";
import HirePageExperties from "../components/hirepageExperties/HirePageExperties";
import HireExpertFacts from "../components/hire-expert-facts/HireExpertFacts";
import HirePageEngagmentModels from "../components/hire-page-engagment-models/HirePageEngagmentModels";
import HirePageAppIdea from "../components/hire-page-app-idea/HirePageAppIdea";
import ContactSection from "../components/contactSection/ContactSection";
import TrendsInsights from "../components/trends-n-insights/TrendsInsights";
import HireCombinationSection from "../components/hire-combination-sectiono/HireCombinationSection";
import CoverTheStack from "../components/coverthestack/CoverTheStack";

const HirePython = () => {
  return (
    <>
      <Helmet>
        <title>
          Hire Python Developers | Python Development Services
        </title>
        <meta
          name="description"
          content="Hire experienced Python developers for versatile, robust solutions. Custom Python development services to drive your business forward."
        />
      </Helmet>

      <div className="sticky-header">
        <MegaHeader />
      </div>

      <HirePageBanner
        diffBannerBackgroundImage={mainBannerImg?.backgroundImage}
        image={""}
        mainHeading="Hire Python Developers"
        content="Your trusted source to Hire Python Developers that is perfect for Startups and Enterprises."
        btnText="Consult our Python developers"
        bannerList={bannerList}
      />

      <HirePageExperties
        heading="Our Python Developer Expertise"
        discription="Our team of Python developers specializes in creating scalable, secure, and high-performance solutions. We use Python’s power to build innovative applications and deliver seamless development experiences for businesses of all sizes. Whether it’s for Python application development or custom solutions, our developers are ready to meet your needs."
        servicesData={servicesData}
      />

      <DevelopmentPerformance
        heading="Why Choose Bitsclan to Hire"
        greenHeading="Python Developers"
        subHeading="At Bitsclan, we combine Python with modern frontend and mobile technologies to create powerful, scalable applications that fit your business needs. Whether you need a web platform or a mobile solution, our approach ensures smooth development, excellent performance, and a seamless user experience. Our Python development services make sure your application works efficiently and grows with your business."
        solutions={solutionsData}
      />

      <HireCombinationSection combinations={techCombinations} heading="Top Python Combinations for Powerful and Scalable Development" description="Unlock the full potential of Python with the best technology combinations to build secure, scalable, and high-performance applications. Hire expert Python developers to drive innovation and ensure seamless development." />

      <HirePageSteps
        heading={"Hire Python Developers in 4 Easy Steps"}
        subHeading={
          "Hiring top Python developers has never been easier. Follow our simple 4-step process for a seamless experience, ensuring the right fit for your project, fast delivery, and high-quality development."
        }
        steps={steps}
      />

      <CoverTheStack
        whereStart={"Database"}
        mainHeading={"Hire Expert Python Developers for Scalable & High-Performance Solutions"}
        subHeading={"At Bitsclan, we empower your web development journey with a robust Python tech stack that ensures efficiency, scalability, and innovation. Our experienced Python developers specialize in building secure, data-driven, and high-performance web applications tailored to your business needs."}
        tabs={tabs}
        tabItems={tabItems} />

      <HireExpertFacts
        heading="Quick Facts About "
        greenHeading="Our Python Team"
        facts={facts}
        diffBannerBackgroundImage={mainBannerImg?.factBackgroundImage}
      />

      <HirePageAppIdea content="Have an idea but unsure how to proceed? Our Golang experts can help you build a functional prototype in just 6-8 weeks! Validate your vision, test user engagement, and establish a strong foundation for success with our expert development services." />

      <HirePageEngagmentModels description="Choose the engagement model that fits your project needs! Whether you need full-time, part-time, or hourly developers, we offer flexible options to ensure seamless collaboration and maximum efficiency throughout your project." hirignDetails={hirignDetails} />

      <TrendsInsights specificClass="mobile-dev-page" category="all" />

      <SecondFaq
        faqs={faqData}
        specificClass="mobile-dev-page"
        mainHeading={"Frequently Asked Questions"}
        subHeading={
          "Explore our Python development services for tailored web solutions, API creation, and deployment management!"
        }
      />

      <ContactSection />

      <Footer />
    </>
  );
};

export default HirePython;

import React, { useRef, useState, useCallback } from 'react'
// import './style.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Autoplay } from 'swiper/modules';
import { Link } from 'react-router-dom';

const IndustryDetailCard = ({ HireUsData, content, heading }) => {

    const sliderRef = useRef();

    const [isBeginning, setIsBeginning] = useState(true);
    const [isEnd, setIsEnd] = useState(false);

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);

    const handleSlideChange = (swiper) => {
        setIsBeginning(swiper.isBeginning);
        setIsEnd(swiper.isEnd);
    };

    return (
        <>
            <div className="hire-us-box">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="main-heading">{heading}</h1>
                            <h3 className="sub-heading">{content}</h3>
                        </div>
                    </div>

                    <div className="row mt-4">
                        {
                            HireUsData?.length > 3 ? <div className="col-md-12 mb-4">
                                <div className="arrow-developing-slider">
                                    <img
                                        onClick={handlePrev}
                                        src="/images/arrow-right.png"
                                        className={`left-image ${isBeginning ? 'disabled' : ''}`}
                                        alt="Arrow"
                                        style={{ cursor: isBeginning ? 'not-allowed' : 'pointer' }}
                                    />
                                    <img
                                        onClick={handleNext}
                                        src="/images/arrow-right.png"
                                        className={`${isEnd ? 'disabled' : ''}`}
                                        alt="Arrow"
                                        style={{ cursor: isEnd ? 'not-allowed' : 'pointer' }}
                                    />
                                </div>
                            </div> : ""
                        }

                        <div className="col-md-12 hire-us-swiper home-card-box">
                            <Swiper
                                slidesPerView={'3'}
                                spaceBetween={20}
                                navigation={false}
                                onSlideChange={handleSlideChange}
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false,
                                    pauseOnMouseEnter: true,
                                }}
                                modules={[Navigation, Autoplay]}
                                className="mySwiper"
                                ref={sliderRef}
                                breakpoints={{
                                    425: {
                                        slidesPerView: 1,
                                        spaceBetween: 10,
                                    },
                                    375: {
                                        slidesPerView: 1,
                                        spaceBetween: 10,
                                    },
                                    320: {
                                        slidesPerView: 1,
                                        spaceBetween: 10,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 10,
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                        spaceBetween: 10,
                                    },
                                    1280: {
                                        slidesPerView: 3,
                                        spaceBetween: 10,
                                    },
                                }}
                            >
                                {HireUsData?.map(slide => (
                                    <SwiperSlide key={slide.id}>
                                        <div className={`hire-us-card ${slide.borderClass}`}>
                                            {/* <div className="hire-us-icon">
                                                <img src={slide.icon} alt={slide.heading} />
                                            </div> */}
                                            <h1 className="common-heading">{slide.heading}</h1>
                                            <p className="common-text">{slide.text}</p>
                                            {/* {
                                                slide.btnLink === "commingsoon" ? <a data-bs-toggle="modal" href="#comingsoon-model" role="button">
                                                    I’m looking for <i className="fa-solid fa-arrow-right-long"></i>
                                                </a> : <Link to={`/${slide?.btnLink}`}>
                                                    I’m looking for <i className="fa-solid fa-arrow-right-long"></i>
                                                </Link>
                                            } */}
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="w-100 d-flex flex-row justify-content-center mt-5">
                                <div className="hire-us-cta-btn">
                                    <a role="button" href="https://calendly.com/bitsclansolutions/15min" target="_blank" className="ms-0 px-4">Book an appointment</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >

            <div class="modal fade" id="comingsoon-model" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
                <div class="modal-dialog shedule-call-model-box modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class="fa-solid fa-xmark"></i></button>
                        </div>
                        <div class="modal-body">
                            <div className="d-flex justify-content-center">
                                <img src="/images/logo.png" width={300} alt="Logo" />
                            </div>
                            <h1 style={{ fontSize: '42px', textAlign: 'center', color: '#000000' }} className='mt-2'>Coming Soon,</h1>
                            <h1 style={{ fontSize: '42px', textAlign: 'center', color: '#000000' }}>We Are Working On It.</h1>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default IndustryDetailCard
import React from 'react'
import './style.css'

const HireCombinationSection = ({ heading, description, combinations }) => {

    return (
        <>
            <div className="hire-combination-section-box-main">
                <div className="container">
                    <div className="row">
                        <div class="col-md-12">
                            <h1 class="main-heading">{heading}</h1>
                            <h3 class="sub-heading">{description}</h3>
                        </div>
                    </div>

                    <div className="row">
                        {combinations?.map((combination, index) => {
                            return <div className="col-xl-6 col-lg-6 col-md-6" key={index}>
                                <div className="hire-combination-card">
                                    <div className="hire-page-combo-icons">
                                        {combination?.icons?.map((icon, index) => {
                                            return <div className="hire-page-combo-icon" key={index}>
                                                <img src={`/images/tech-stack-icons/${icon}`} alt="" />
                                            </div>
                                        })}
                                    </div>

                                    <div className="hire-page-cmobo-text">
                                        <h2>{combination?.title}</h2>
                                        <p className="common-text">{combination?.description}</p>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default HireCombinationSection
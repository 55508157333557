import React, { useRef, useState, useCallback } from 'react'
import './style.css'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

// import { Navigation } from 'swiper/modules';
import { Autoplay, Mousewheel, Pagination } from 'swiper/modules';

const DevelopmentProcess = ({ heading, description, processSteps, greenHeading }) => {

    const sliderRef = useRef();

    return (
        <>
            <div className="development-process-box">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-5 col-md-4 flex-center-box">
                            <div className="process-box-main">
                                <h1>{heading} <span>{greenHeading}</span></h1>
                                <p className="common-text">{description}</p>
                            </div>
                        </div>

                        <div className="col-xl-7 col-lg-7 col-md-8">
                            <div className="container p-0">
                                <div className="row max-height-top-box">
                                    <Swiper
                                        slidesPerView={3}
                                        spaceBetween={0}
                                        navigation={false}
                                        direction={'vertical'}
                                        mousewheel={true}
                                        pagination={{
                                            clickable: true,
                                        }}
                                        modules={[Mousewheel, Pagination]}
                                        className="mySwiper-2"
                                        ref={sliderRef}
                                    >
                                        {processSteps.map((step, index) => (
                                            <SwiperSlide key={index}>
                                                <div className="col-md-12 mb-3">
                                                    <div className={`requirement-gathering-card ${step.bgClass}`}>
                                                        <div className="icon-side-top">
                                                            <div className="icon-image">
                                                                <img src={step.icon} alt={`Step ${step.stepNumber}`} />
                                                            </div>
                                                            <h2>{step.stepNumber}</h2>
                                                        </div>
                                                        <h2>{step.title}</h2>
                                                        <p>{step.content}</p>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DevelopmentProcess
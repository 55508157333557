import React, { useRef, useState, useCallback } from 'react'
import blogsSlidesData from '../../data/blogsData';
import './style.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';

const HireForUs = ({ HireUsData, content, heading }) => {

    const sliderRef = useRef();

    const [isBeginning, setIsBeginning] = useState(true);
    const [isEnd, setIsEnd] = useState(false);

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);

    const handleSlideChange = (swiper) => {
        setIsBeginning(swiper.isBeginning);
        setIsEnd(swiper.isEnd);
    };


    // BLOG DATA ARRAY




    return (
        <>
            <div className='main-hire-us-for' id='hire-us'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-8 col-lg-12 p-0">
                            <div className="hire-for-us-slider">
                                <div className="row">
                                    <div className="col-md-10">
                                        <h1 className="main-heading">{heading}</h1>
                                        {content?.map((item, index) => (
                                            <h3 key={index} className="sub-heading">{item}</h3>
                                        ))}
                                    </div>
                                    <div className="col-md-2 d-flex align-items-center justify-content-end">
                                        <div className="arrow-developing-slider">
                                            <img
                                                onClick={handlePrev}
                                                src="/images/arrow-right.png"
                                                className={`left-image ${isBeginning ? 'disabled' : ''}`}
                                                alt="Arrow"
                                                style={{ cursor: isBeginning ? 'not-allowed' : 'pointer' }}
                                            />
                                            <img
                                                onClick={handleNext}
                                                src="/images/arrow-right.png"
                                                className={`${isEnd ? 'disabled' : ''}`}
                                                alt="Arrow"
                                                style={{ cursor: isEnd ? 'not-allowed' : 'pointer' }}
                                            />
                                        </div>
                                    </div>
                                </div>


                                <div className="row">



                                    <div className="col-md-12">
                                        <Swiper
                                            slidesPerView={3}
                                            spaceBetween={30}
                                            onSlideChange={handleSlideChange}
                                            navigation={false}
                                            modules={[Navigation]}
                                            className="mySwiper"
                                            ref={sliderRef}
                                            breakpoints={{
                                                425: {
                                                    slidesPerView: 1,
                                                    spaceBetween: 10,
                                                },
                                                375: {
                                                    slidesPerView: 1,
                                                    spaceBetween: 10,
                                                },
                                                320: {
                                                    slidesPerView: 1,
                                                    spaceBetween: 10,
                                                },
                                                768: {
                                                    slidesPerView: 2,
                                                    spaceBetween: 10,
                                                },
                                                1280: {
                                                    slidesPerView: 3,
                                                    spaceBetween: 30,
                                                },
                                            }}
                                        >

                                            {HireUsData.map(slide => (
                                                <SwiperSlide key={slide.id}>

                                                    <div className="type-setting-card side-typing-card-box">
                                                        <div className='content-wo-link'>
                                                            <div className="type-card-hire-image">
                                                                <img src={slide.icon} alt="Icon" />
                                                            </div>
                                                            <div className="type-card-hire-text">
                                                                <a>{slide.heading}</a>
                                                                <div className='insights-box'>
                                                                    <span>{slide.text}</span>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <Link to={`/${slide?.btnLink}`} style={{ textDecoration: 'none' }}>
                                                            <div className="slider-link">
                                                                {
                                                                    slide.btnLink === "commingsoon" ? <a data-bs-toggle="modal" href="#comingsoon-model" role="button">
                                                                        I’m looking for <i className="fa-solid fa-arrow-right-long"></i>
                                                                    </a> : <Link to={`/${slide?.btnLink}`}>
                                                                        I’m looking for <i className="fa-solid fa-arrow-right-long"></i>
                                                                    </Link>
                                                                }
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-12 p-0">
                            <div className="hire-us-side-bar">
                                <h5 className="" style={{ color: '#80b927' }}>
                                    Starting Price That Fits Your Budget/ Top-Tier Expertise, Cost-Effective Plans
                                </h5>
                                <h2 className="sidebar-title">
                                    High-Impact Solutions at a Budget-Friendly Price/ Premium Solutions, Affordable Pricing
                                </h2>
                                <p className="sidebar-content">
                                    Scaling your business with top-tier software development, consulting, and digital branding doesn’t have to break the bank. We provide cutting-edge solutions tailored to your needs, ensuring seamless performance, security, and innovation—all at an affordable rate.
                                </p>
                                <p className="sidebar-content">
                                    Starting at just $24 per hour, our expert team guarantees high-quality services that drive efficiency and growth. Whether you're launching a new product, optimizing your operations, or strengthening your online presence, we deliver measurable results that maximize your investment.
                                </p>
                                <div className="sidebar-link">
                                    <a id='home-hire-us-get-started-btn' href="https://calendly.com/bitsclan-it/30min?month=2025-01" target='_blank'>Let’s Build Something Great</a> <i className="fa-solid fa-arrow-right-long"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default HireForUs

import React from 'react'
import './style.css'

const HireExpertFacts = ({ heading, greenHeading, facts, btnText, diffBannerBackgroundImage }) => {
    return (
        <>
            <div className="hire-page-facts-box" style={{ backgroundImage: `${diffBannerBackgroundImage}` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-9 col-lg-9 col-md-12">
                            <h1 className="hire-fact-heading">{heading} <span>{greenHeading}</span></h1>

                            <ul className="hire-page-fact-list">
                                {facts?.map((list, index) => {
                                    return <li key={index} dangerouslySetInnerHTML={{ __html: list?.text }} />
                                })}
                            </ul>
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-md-12">
                            <div class="hire-us-cta-btn">
                                <a id='hire-experts-quick-facts-book-appointment-click' role="button" href='https://calendly.com/bitsclan-it/30min?month=2025-01' target='_blank' class="ms-0">book an appointment</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HireExpertFacts
export const IndustryTabsData = [
  "Healthcare",
  "Fintech",
  "Retail & eCommerce",
  "Real Estate",
  "Automotive",
  "Education",
  "Insurance",
  "Logistics",
  "Entertainment",
  "Gaming",
  "Social Networking",
  "Telecom",
  "Energy",
];

export const IndustryTabsItemsData = [
  {
    tabName: "Healthcare",
    tabDes:
      "Mobile app development has revolutionized the healthcare sector, streamlining processes and improving patient experiences. We specialize in creating custom mobile apps that ensure secure data handling, user-friendly designs, and efficient performance. Whether it’s iOS, Android, or cross-platform apps, our solutions empower healthcare providers with seamless communication, remote consultations, and advanced patient management.",
    objectives: [
      "Custom mobile apps tailored to healthcare needs.",
      "iOS, Android, and cross-platform app development.",
      "Innovative solutions for better patient care.",
    ],
    href: "/industry-detail/healthcare",
    tabImage: "health-care-image.png",
  },
  {
    tabName: "Fintech",
    tabDes:
      "Fintech is transforming how the world manages money, and we’re here to lead the charge. From secure payment gateways to streamlined financial tools, our apps are designed to empower businesses and elevate user experiences. With our expertise, finance becomes more accessible, efficient, and future-ready.",
    objectives: [
      "Revolutionizing financial processes with technology.",
      "Crafting tools for seamless transactions and management.",
      "Driving innovation to simplify complex financial systems.",
    ],
    href: "/industry-detail/fintech",
    tabImage: "Fintech.png"
  },
  {
    tabName: "Retail & eCommerce",
    tabDes:
      "In a world where convenience is key, our retail and eCommerce apps set the standard. We build immersive shopping experiences, empowering businesses to connect with their customers in meaningful ways. Whether it’s managing inventory or enhancing user journeys, we bring ideas to life with unmatched precision.",
    objectives: [
      "Creating memorable shopping journeys.",
      "Boosting customer loyalty through innovation.",
      "Tailored solutions for every retail challenge.",
    ],
    href: "/industry-detail/retail-ecommerce",
    tabImage: "Retail-Ecommerce.png"
  },
  {
    tabName: "Real Estate",
    tabDes:
      "Real estate isn’t just about properties; it’s about connections. Our apps redefine how buyers, sellers, and agents interact. From virtual tours to instant updates, we bridge the gap between dreams and reality. Let technology unlock the potential of your real estate business.",
    objectives: [
      "Bringing properties to life through innovation.",
      "Empowering seamless communication and exploration.",
      "Tailored tools for a dynamic market.",
    ],
    href: "/industry-detail/real-estate",
    tabImage: "Realestate.png"
  },
  {
    tabName: "Automotive",
    tabDes:
      "Driving innovation in the automotive world, our apps put control at your fingertips. From vehicle tracking to service reminders, we enhance the way businesses operate and customers engage. The road to efficiency and satisfaction starts with us.",
    objectives: [
      "Revolutionizing vehicle management and engagement.",
      "Designed for dealerships, fleet managers, and users alike.",
      "Streamlining automotive operations with ease.",
    ],
    href: "/industry-detail/automotive",
    tabImage: "Automotive.png"
  },
  {
    tabName: "Education",
    tabDes:
      "Education is evolving, and so are the tools that power it. Our apps make learning exciting, accessible, and impactful. Whether it’s personalized courses or dynamic tools for teachers, we empower institutions to inspire and engage the next generation.",
    objectives: [
      "Transforming learning experiences for all.",
      "Making education interactive and inclusive.",
      "Building platforms that inspire growth and discovery.",
    ],
    href: "/industry-detail/education",
    tabImage: "Education.png"
  },
  {
    tabName: "Insurance",
    tabDes:
      "Insurance doesn’t have to be complicated. Our apps simplify claims, policies, and communication, making every process seamless. With security and accessibility at the forefront, we help businesses deliver peace of mind to their clients.",
    objectives: [
      "Simplifying every aspect of the insurance journey.",
      "Building trust through secure, user-friendly solutions.",
      "Bridging the gap between clients and services.",
    ],
    href: "/industry-detail/insurance",
    tabImage: "Insurance.png"
  },
  {
    tabName: "Logistics",
    tabDes:
      "Speed, precision, and reliability – our logistics apps are built to deliver. Whether tracking shipments, managing fleets, or improving inventory systems, we create tools that keep businesses moving efficiently. Every delivery, perfectly timed and executed.",
    objectives: [
      "Optimizing supply chains with real-time solutions.",
      "Enhancing delivery systems for smoother operations.",
      "Empowering businesses to stay ahead in logistics.",
    ],
    href: "/industry-detail/logistics",
    tabImage: "Logistics.png"
  },
  {
    tabName: "Entertainment",
    tabDes:
      "Entertainment apps should do more than deliver content; they should create experiences. From immersive streaming platforms to engaging multimedia tools, we design apps that keep audiences entertained and coming back for more.",
    objectives: [
      "Delivering seamless entertainment on every platform.",
      "Combining creativity and technology for unforgettable experiences.",
      "Designing for fun, engagement, and connection.",
    ],
    href: "/industry-detail/entertainment",
    tabImage: "Entertainment.png"
  },
  {
    tabName: "Gaming",
    tabDes:
      "Gaming is more than play – it’s an adventure. Our apps bring captivating worlds to life, blending stunning graphics and smooth gameplay. From casual games to expansive multiplayer experiences, we fuel imagination and excitement with every tap.",
    objectives: [
      "Bringing immersive gaming experiences to life.",
      "Designing for fun, competition, and connection.",
      "Perfect for players of every kind, everywhere.",
    ],
    href: "/industry-detail/gaming",
    tabImage: "Gaming.png"
  },
  {
    tabName: "Social Networking",
    tabDes:
      "Social networking is about more than connecting; it’s about creating moments that matter. Our apps let users share, collaborate, and build communities effortlessly. With intuitive designs and engaging features, we make every connection meaningful.",
    objectives: [
      "Helping people stay connected, wherever they are.",
      "Building tools for collaboration and interaction.",
      "Empowering users to create lasting digital communities.",
    ],
    href: "/industry-detail/social-networking",
    tabImage: "social-networking.png"
  },
  {
    tabName: "Telecom",
    tabDes:
      "Telecom connects the world, and our apps are built to elevate that connection. From streamlining communication services to managing complex operations, we empower telecom providers to deliver seamless, reliable, and innovative experiences. At Bitsclan, we help services providers make networks smarter, faster, and user-centric.",
    objectives: [
      "Enabling efficient communication management.",
      "Simplifying operations with intelligent tools.",
      "Driving innovation for better connectivity.",
    ],
    href: "/industry-detail/telecom",
    tabImage: "Telecom.png"
  },
  {
    tabName: "Energy",
    tabDes:
      "In an era of sustainability, energy management demands smarter solutions. Our apps help optimize resources, monitor usage, and streamline operations for energy providers. Whether it’s renewable energy tracking or grid management, we bring clarity and control to the power sector.",
    objectives: [
      "Revolutionizing energy monitoring and efficiency.",
      "Supporting sustainability with innovative tools.",
      "Empowering smarter energy solutions for tomorrow.",
    ],
    href: "/industry-detail/energy",
    tabImage: "Energy.png"
  },
];

import { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/scrollTop/ScrollTop";
import ModalWrapper from "./components/modalWrapper";
import FloatingButtons from "./components/floating-buttons";
import ClutchIcon from "./components/clutch-icon";
import { routes } from "./routes";
import ProtectedRoute from "./protectedRoutes";
import Loader from "./components/loader";
import Cookies from "universal-cookie";
import { useDispatch } from "react-redux";
import { clearCountry, setCountry } from "./redux/slices/countrySlice";
import Chatbot from "./components/chat/Chatbot";

const cookies = new Cookies();
function App() {
  const [region, setRegion] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAccepted, setIsAccepted] = useState(cookies.get("cookiesAccepted"));
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const res = await fetch("https://ipinfo.io/json");
        const data = await res.json();
        const countryCode = data.country; // Get country code (e.g., 'PK', 'KSA', 'USA', 'AUS')

        if (countryCode === "PK") {
          setRegion("PK");
          dispatch(setCountry("PK"));
        } else if (countryCode === "SA") {
          setRegion("KSA");
          dispatch(setCountry("KSA"));
        } else if (countryCode === "US") {
          dispatch(setCountry("USA"));

          setRegion("USA");
        } else if (countryCode === "AU") {
          setRegion("AUS");
          dispatch(setCountry("AUS"));
        } else {
          setRegion("");
          dispatch(clearCountry());
        } // Default to USA if region not matched
      } catch (error) {
        setRegion(""); // Default on error
        dispatch(clearCountry());
      } finally {
        setLoading(false);
      }
    };

    fetchLocation();
  }, []);

  console.log("region =>", region);

  if (loading) return <Loader />; // Show loading while fetching location
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <ClutchIcon isAccepted={isAccepted} setIsAccepted={setIsAccepted} />
        <ModalWrapper setIsAccepted={setIsAccepted} />
        <FloatingButtons />
        {/* <Chatbot /> */}
        <Routes>
          {routes.map(({ path, element }) => (
            <Route
              key={path}
              path={path}
              // element={element}
              element={
                <ProtectedRoute region={region}>{element}</ProtectedRoute>
              }
            />
          ))}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

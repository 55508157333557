export const schemaData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
        "@type": "Question",
        "name": "What are mobile application development services?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Mobile application development services encompass the entire process of creating software applications that run on mobile devices. This includes everything from initial concept design, UI/UX development, coding, testing, and deployment, to ongoing support and maintenance. These services can be tailored to build apps for various platforms like iOS, Android, or cross-platform solutions."
        }
    }, {
        "@type": "Question",
        "name": "What is the cost to develop a mobile application?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "The cost of AI services depends on the complexity of the project, the type of AI integration needed, and the level of expertise required. Prices can range from a few thousand dollars to millions depending on the scope."
        }
    }, {
        "@type": "Question",
        "name": "How long does it take to develop a mobile app?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Building an AI product can take anywhere from a few months to several years, depending on the complexity of the solution, the resources available, and the level of customization needed."
        }
    }, {
        "@type": "Question",
        "name": "Do you work with security-sensitive apps?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Outsourcing AI development typically involves assessing business needs, selecting the right vendor, defining the project scope, and overseeing the development and deployment process. The vendor will deliver AI solutions tailored to your business requirements."
        }
    }, {
        "@type": "Question",
        "name": "How can I track the progress of my app?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Outsourcing AI development typically involves assessing business needs, selecting the right vendor, defining the project scope, and overseeing the development and deployment process. The vendor will deliver AI solutions tailored to your business requirements."
        }
    }, {
        "@type": "Question",
        "name": "How can I choose a mobile application development company?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "AI integration can improve efficiency, reduce operational costs, increase decision-making accuracy, automate tasks, and uncover valuable insights from data, leading to enhanced business performance."
        }
    }, {
        "@type": "Question",
        "name": "",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": ""
        }
    }]
}

export const tabs = [
    "Frontend",
    "Backend",
    "Database",
    "Frameworks",
    "DevOps",
    "Mobile Tools",
];

// export const tabItems = [
//     { tab: "Frontend", icon: "/images/iconone.png", name: "Angular JS" },
//     { tab: "Frontend", icon: "/images/icontwo.png", name: "JavaScript" },
//     { tab: "Frontend", icon: "/images/iconthree.png", name: "React JS" },
//     { tab: "Frontend", icon: "/images/icon4.png", name: "Vue JS" },
//     { tab: "Frontend", icon: "/images/mobile3.png", name: "Swift" },
//     { tab: "Frontend", icon: "/images/icon6.png", name: "CSS3" },
//     { tab: "Frontend", icon: "/images/icon7.png", name: "HTML5" },
//     { tab: "Backend", icon: "/images/database1.png", name: ".Net" },
//     { tab: "Backend", icon: "/images/database2.png", name: "Nest Js" },
//     { tab: "Backend", icon: "/images/database3.png", name: "Meteor JS" },
//     { tab: "Backend", icon: "/images/database5.png", name: "Express JS" },
//     { tab: "Backend", icon: "/images/database6.png", name: "Python" },
//     { tab: "Backend", icon: "/images/django-tech.svg", name: "Django" },
//     { tab: "Backend", icon: "/images/database8.png", name: "PHP" },
//     { tab: "Backend", icon: "/images/database9.png", name: "Node Js" },
//     { tab: "Database", icon: "/images/db1.png", name: "Firebase" },
//     { tab: "Database", icon: "/images/db2.png", name: "Oracle " },
//     { tab: "Database", icon: "/images/db3.png", name: "MongoDB" },
//     { tab: "Database", icon: "/images/tech-stack-icons/postgres.svg", name: "PostgreSQL" },
//     { tab: "Database", icon: "/images/db5.png", name: "MySQL" },
//     { tab: "Database", icon: "/images/db6.png", name: "SQL Server" },
//     { tab: "Database", icon: "/images/db7.png", name: "SQLite" },
//     { tab: "Database", icon: "/images/db8.png", name: "DynamoDB" },
//     { tab: "Frameworks", icon: "/images/framework1.png", name: "MEAN" },
//     { tab: "Frameworks", icon: "/images/framework2.png", name: "CodeIgniter" },
//     { tab: "Frameworks", icon: "/images/framework3.png", name: "Laravel" },
//     { tab: "Frameworks", icon: "/images/framework4.png", name: "Rxweb.io" },
//     { tab: "Frameworks", icon: "/images/framework5.png", name: "CakePHP" },
//     { tab: "Frameworks", icon: "/images/framework6.png", name: "Django" },
//     { tab: "DevOps", icon: "/images/devops1.png", name: "Gradle" },
//     { tab: "DevOps", icon: "/images/devops2.png", name: "Kubernetes" },
//     { tab: "DevOps", icon: "/images/devops3.png", name: "Docker" },
//     { tab: "DevOps", icon: "/images/devops4.png", name: "Jenkins" },
//     { tab: "Quality Assurance", icon: "/images/qa1.svg", name: "TestRail" },
//     { tab: "Quality Assurance", icon: "/images/qa2.svg", name: "LoadRunner" },
//     { tab: "Quality Assurance", icon: "/images/qa3.svg", name: "Cucumber" },
//     { tab: "Quality Assurance", icon: "/images/qa4.svg", name: "TestNG" },
//     { tab: "Quality Assurance", icon: "/images/qa5.svg", name: "Appium" },
//     { tab: "Quality Assurance", icon: "/images/qa6.svg", name: "Selenium" },
// ];

export const tabItems = [
    // Frontend Technologies for Mobile App Development
    { tab: "Frontend", icon: "/images/tech-stack-icons/react.svg", name: "React Native" },
    { tab: "Frontend", icon: "/images/tech-stack-icons/Flutter.svg", name: "Flutter" },
    { tab: "Frontend", icon: "/images/tech-stack-icons/Ionic.svg", name: "Ionic" },
    { tab: "Frontend", icon: "/images/tech-stack-icons/swift.svg", name: "Swift" },
    { tab: "Frontend", icon: "/images/tech-stack-icons/kotlin.svg", name: "Kotlin" },
    { tab: "Frontend", icon: "/images/tech-stack-icons/xamarin.svg", name: "Xamarin" },

    // Backend Technologies
    { tab: "Backend", icon: "/images/database9.png", name: "Node.js" },
    { tab: "Backend", icon: "/images/django-tech.svg", name: "Django" },
    { tab: "Backend", icon: "/images/database8.png", name: "PHP" },
    { tab: "Backend", icon: "/images/database5.png", name: "Express.js" },
    { tab: "Backend", icon: "/images/database6.png", name: "Python" },
    { tab: "Backend", icon: "/images/tech-stack-icons/dart.svg", name: "Dart" },

    // Database Technologies
    { tab: "Database", icon: "/images/db1.png", name: "Firebase" },
    { tab: "Database", icon: "/images/db5.png", name: "MySQL" },
    { tab: "Database", icon: "/images/tech-stack-icons/postgres.svg", name: "PostgreSQL" },
    { tab: "Database", icon: "/images/db3.png", name: "MongoDB" },

    // Frameworks Used
    { tab: "Frameworks", icon: "/images/tech-stack-icons/visualstudio.svg", name: "Visual Studio" },
    { tab: "Frameworks", icon: "/images/tech-stack-icons/Android-Studio.webp", name: "Android Studio" },

    // DevOps Tools
    { tab: "DevOps", icon: "/images/devops1.png", name: "Gradle" },
    { tab: "DevOps", icon: "/images/devops2.png", name: "Kubernetes" },
    { tab: "DevOps", icon: "/images/devops3.png", name: "Docker" },
    { tab: "DevOps", icon: "/images/devops4.png", name: "Jenkins" },

    // Mobile Development Tools
    { tab: "Mobile Tools", icon: "/images/tech-stack-icons/cordova.svg", name: "Cordova" },
    { tab: "Mobile Tools", icon: "/images/tech-stack-icons/Phone.svg", name: "Phone Gap" },
    { tab: "Mobile Tools", icon: "/images/tech-stack-icons/ios.svg", name: "iOS" }
];


export const faqData = [
    {
        id: "collapseOne",
        question: "What are mobile application development services?",
        answer:
            "Mobile application development services cover everything from designing and coding to testing and launching mobile apps. These services include UI/UX design, backend development, security enhancements, and post-launch maintenance. Apps can be built for iOS, Android, or both using cross-platform solutions.",
    },
    {
        id: "collapseTwo",
        question: "What is the cost to develop a mobile application?",
        answer:
            "The cost of developing a mobile app depends on factors like features, platform (iOS, Android, or both), complexity, and development time. It can range from a few thousand to hundreds of thousands of dollars, depending on project requirements.",
    },
    {
        id: "collapseThree",
        question: "How long does it take to develop a mobile app?",
        answer:
            "The development timeline varies based on app complexity, features, and team size. Simple apps may take 3-6 months, while complex solutions with advanced functionalities can take a year or more.",
    },
    {
        id: "fourth",
        question: "How can I choose a mobile application development company?",
        answer:
            "Look for a company with a strong portfolio, experience in your industry, and expertise in mobile technologies. Check client reviews, assess communication, and ensure they offer post-launch support.",
    },
    {
        id: "fifth",
        question: "How can I track the progress of my app?",
        answer:
            "A good development team provides regular updates, project milestones, and demo versions. Using project management tools and scheduled meetings ensures transparency throughout the development process.",
    },
    {
        id: "sixth",
        question: "Do you work with security-sensitive apps?",
        answer:
            "Yes, we develop apps with high-security standards, including encryption, secure authentication, and compliance with industry regulations. We ensure data protection for banking, healthcare, and enterprise applications.",
    },
];

export const TypesOfBuildData = [
    {
        id: "collapseOne",
        question: "Native Apps",
        answer: {
            discription:
                "Native iOS and Android apps are at the core of our mobile app development services. We develop high- performance native apps that provide an intuitive and seamless user experience.",
            listTitle: "Our native mobile apps are best for:",
            listContent: [
                {
                    line: "Complex mobile applications",
                },
                {
                    line: "High performance & smooth Ul",
                },
                {
                    line: "Apps with heavy data processing",
                },
                {
                    line: "Security- sensitive applications",
                },
                {
                    line: "Apps requiring device features",
                },
            ],
        },
    },
    {
        id: "collapseTwo",
        question: "Cross-Platform Apps",
        answer: {
            discription:
                "Rather than developing two native mobile apps, get a cross-platform app that is practical among iOS and Android at the same time. So, for every setback that your client might face, we’ve got you covered already with our custom app development services.",
            listTitle: "Our cross-platform apps are best for:",
            listContent: [
                {
                    line: "Rapid development and time-to-market",
                },
                {
                    line: "Reaching both iOS and Android users with a single codebase",
                },
                {
                    line: "Cost-effective development and maintenance",
                },
                {
                    line: "Apps with basic to moderate functionality",
                },
                {
                    line: "Apps targeting multiple platforms with minimal performance overhead",
                },
                {
                    line: "Projects with limited resources or smaller budgets",
                },
            ],
        },
    },
    {
        id: "collapseThree",
        question: "Hybrid Apps",
        answer: {
            discription:
                "Using web resources, our developers can build native-like applications for mobile users. Our hybrid app development services are a perfect fit for quick-build solutions.",
            listTitle: "Hybrid apps are best for:",
            listContent: [
                {
                    line: "Simple mobile applications with basic functionality",
                },
                {
                    line: "Rapid development and deployment across multiple platforms",
                },
                {
                    line: "Apps with limited or no reliance on device-specific features",
                },
                {
                    line: "Projects with a constrained budget or timeline",
                },
                {
                    line: "Apps that need to target a broad user base on both iOS and Android",
                },
                {
                    line: "Content-driven apps (e.g., news, blogs, media)",
                },
            ],
        },
    },
    {
        id: "fourth",
        question: "Wearables Apps",
        answer: {
            discription:
                "Be it a smartwatch or VR headset, our wearables applications are the only go-to solution. We offer app development services for every smart gadget, utilizing wireframe designs and AR features.",
            listTitle: "Wearables Apps are best for:",
            listContent: [
                {
                    line: "Complex mobile applications",
                },
                {
                    line: "High performance & smooth Ul",
                },
                {
                    line: "Apps with heavy data processing",
                },
                {
                    line: "Security- sensitive applications",
                },
                {
                    line: "Apps requiring device features",
                },
            ],
        },
    },
];

export const WhoWeServeData = [
    {
        icon: "/images/servie-icon/startups.svg",
        title: "Startups",
        description:
            "We are always passionate about working with startups, offering them the kickstart they need and building long-term connections.",
        link: "#",
        borderClass: "border-1",
    },
    {
        icon: "/images/servie-icon/companies.svg",
        title: "Software Product Companies",
        description:
            "For software product companies, we provide end-to-end mobile app development services that enhance the value of your product, improve customer experience, and support seamless integration with existing systems.",
        link: "#",
        borderClass: "border-2",
    },
    {
        icon: "/images/servie-icon/industries.svg",
        title: "Enterprises from 30+ Industries",
        description:
            "We collaborate with enterprises across more than 30 industries, including finance, healthcare, retail, education, logistics, and more. Our expertise spans custom mobile app development tailored to meet industry-specific challenges, drive business transformation, and deliver measurable results at scale.We understand the unique needs of each sector and work with you to create solutions that not only meet your goals but also exceed expectations.",
        link: "#",
        borderClass: "border-3",
    },
];

export const HighvaluesData = [
    {
        image: "custom-app-development.svg",
        text: "Custom App Development",
    },
    {
        image: "user-centric-designs.svg",
        text: "User-Centric Designs",
    },
    {
        image: "robust-architecture.svg",
        text: "Robust Architecture",
    },
    {
        image: "efficient-integration.svg",
        text: "Efficient Integration",
    },
    {
        image: "advanced-analytics.svg",
        text: "Advanced Analytics",
    },
    {
        image: "enhanced-security.svg",
        text: "Enhanced Security",
    },
    {
        image: "agile-methodology.svg",
        text: "Agile Methodology",
    },
    {
        image: "cross-platform-support.svg",
        text: "Cross-Platform Support",
    },
    {
        image: "ongoing-maintenance.svg",
        text: "Ongoing Maintenance",
    },
    {
        image: "innovation-driven-features.svg",
        text: "Innovation-Driven Features",
    },
];

export const WhyChooseBitsclanData = [
    {
        id: "Tab1",
        label: "Quality because it matters",
        content:
            "We prioritize top-tier quality in every stage, ensuring robust, high-performance apps that deliver exceptional user experiences.",
        btnText: "Connect with the clan",
        btnLink: "#",
    },
    {
        id: "Tab2",
        label: "The best-of-the-breed Talent",
        content:
            "Our team consists of skilled professionals who are experts in the latest technologies, creating innovative and reliable mobile solutions.",
        btnText: "Connect with the clan",
        btnLink: "#",
    },
    {
        id: "Tab3",
        label: "Time Zone Alignment",
        content:
            "Our global presence ensures seamless collaboration, with time zone alignment that keeps your project on track, no matter where you are.",
        btnText: "Connect with the clan",
        btnLink: "#",
    },
    {
        id: "Tab4",
        label: "Post-Deployment Support",
        content:
            "We offer comprehensive post-launch support, ensuring your app remains updated, secure, and optimized for long-term success.",
        btnText: "Connect with the clan",
        btnLink: "#",
    },
    {
        id: "Tab5",
        label: "Cost-Effective Solutions",
        content:
            "We deliver high-quality mobile apps at competitive prices, ensuring you get the best value for your investment without compromising on quality.",
        btnText: "Connect with the clan",
        btnLink: "#",
    },
];

export const DevelopmentServicesData = [
    {
        id: "Tab1",
        label: "Custom Mobile App Development",
        content:
            "We design and develop custom mobile applications that align perfectly with your business goals, ensuring a seamless and high-performance user experience. Whether you need an iOS, Android, or cross-platform application, our expert developers utilize the latest technologies to craft scalable and feature-rich mobile solutions. From initial ideation to final deployment, we focus on creating apps that are intuitive, engaging, and future-ready.",
        secondContent: "Our team ensures that every application is built with a strong foundation, incorporating top-tier security, smooth functionality, and user-friendly interfaces. Whether you're a startup looking for an innovative mobile presence or an enterprise aiming to streamline operations, we tailor our solutions to meet your specific needs. With a strong focus on usability and performance, our apps stand out in the competitive market, ensuring long-term success for your business.",
        btnText: "Let's Discuss",
        btnLink: "#",
        imgs: [
            {
                img: "/images/mobile3.png",
            },
            {
                img: "/images/kotlin-icon.png",
            },
            {
                img: "/images/java-icon.png",
            },
            {
                img: "/images/object-c.png",
            },
            {
                img: "/images/fluttre-icon.png",
            },
        ],
    },
    {
        id: "Tab2",
        label: "Enterprise Mobile App Development",
        content:
            "For businesses looking to enhance productivity and optimize internal processes, we offer enterprise mobile app development that transforms operations and streamlines workflows. Our solutions are designed to meet the unique challenges of large organizations, providing secure, scalable, and high-performance applications that integrate seamlessly with existing enterprise systems.",
        secondContent: "We focus on building mobile solutions that improve team collaboration, automate business processes, and enhance data security while ensuring compliance with industry standards. From ERP integrations to custom workflow automation, our enterprise applications empower businesses with real-time data access, cloud synchronization, and enhanced mobility. With a commitment to reliability and performance, our enterprise mobile solutions enable organizations to operate more efficiently in a fast-paced digital world.",
        btnText: "Let's Discuss",
        btnLink: "#",
        imgs: [
            {
                img: "/images/services-icons/mobile-app-development/swiftui.png",
            },
            {
                img: "/images/services-icons/mobile-app-development/jetpack compose icon_RGB.png",
            },
            {
                img: "/images/services-icons/mobile-app-development/Google-flutter-logo.png",
            },
        ],
    },
    {
        id: "Tab3",
        label: "Cross-Platform Mobile App Development",
        content:
            "Reaching a broader audience with a single application is now possible with our cross-platform mobile app development services. By leveraging frameworks like Flutter, React Native, and Xamarin, we create applications that deliver a native-like experience on both iOS and Android while reducing development time and costs.",
        secondContent: "Our cross-platform solutions ensure a consistent user experience, seamless performance, and easy scalability. Businesses can benefit from a single codebase while reaching multiple platforms without compromising quality. Whether you're looking to build an eCommerce platform, a social networking app, or an enterprise mobility solution, our cross-platform approach ensures cost-effectiveness, faster deployment, and enhanced maintainability.",
        btnText: "Let's Discuss",
        btnLink: "#",
        imgs: [
            {
                img: "/images/services-icons/mobile-app-development/swiftui.png",
            },
            {
                img: "/images/services-icons/mobile-app-development/jetpack compose icon_RGB.png",
            },
            {
                img: "/images/services-icons/mobile-app-development/Google-flutter-logo.png",
            },
        ],
    },
    {
        id: "Tab4",
        label: "UI/UX Design for Mobile Apps",
        content:
            "A visually appealing and user-friendly interface is the key to an app’s success. Our UI/UX design services focus on crafting stunning, intuitive, and engaging mobile interfaces that elevate user experience. We employ data-driven design strategies, usability testing, and in-depth user research to create applications that are not only aesthetically pleasing but also highly functional.",
        secondContent: "From smooth navigation to seamless interactions, our design team ensures that every aspect of your app is optimized for engagement and user retention. We prioritize responsive designs, ensuring that your app delivers a flawless experience across different screen sizes and devices. By combining innovation with user-centric design, we create digital experiences that captivate users and enhance brand loyalty.",
        btnText: "Let's Discuss",
        btnLink: "#",
        imgs: [
            {
                img: "/images/services-icons/mobile-app-development/swiftui.png",
            },
            {
                img: "/images/services-icons/mobile-app-development/jetpack compose icon_RGB.png",
            },
            {
                img: "/images/services-icons/mobile-app-development/Google-flutter-logo.png",
            },
        ],
    },
    {
        id: "Tab5",
        label: "Mobile App Consulting",
        content:
            "If you have an app idea but are unsure how to bring it to life, our mobile app consulting services provide the guidance you need. We help businesses refine their concepts, choose the right technology stack, and define an effective development roadmap. Our experts analyze market trends, user behavior, and competitive landscapes to craft a solid strategy that ensures your app’s success.",
        secondContent: "From feature planning and monetization strategies to scalability and performance optimization, we help you avoid common pitfalls and make informed decisions. Whether you need assistance in choosing between native or cross-platform development, optimizing user experience, or ensuring long-term sustainability, our consulting services empower you with expert insights and practical solutions.",
        btnText: "Let's Discuss",
        btnLink: "#",
        imgs: [
            {
                img: "/images/services-icons/mobile-app-development/swiftui.png",
            },
            {
                img: "/images/services-icons/mobile-app-development/jetpack compose icon_RGB.png",
            },
            {
                img: "/images/services-icons/mobile-app-development/Google-flutter-logo.png",
            },
        ],
    },
    {
        id: "Tab6",
        label: "Custom Mobile Apps for Startups",
        content:
            "Startups require agile, scalable, and cost-effective mobile applications to stand out in today’s competitive market. Our startup-focused mobile app development services are designed to transform innovative ideas into high-performance applications that drive growth. We specialize in building Minimum Viable Products (MVPs) that help startups launch quickly, gather user feedback, and refine their offerings.",
        secondContent: "By leveraging advanced technologies and market insights, we ensure that your app is future-proof, adaptable, and ready to scale as your business grows. From ideation and prototyping to full-scale development and deployment, we provide end-to-end solutions that help startups achieve their vision and maximize their market potential. With our expertise in agile development, lean methodologies, and cost-efficient solutions, we ensure that your app delivers real value and long-term success.",
        btnText: "Let's Discuss",
        btnLink: "#",
        imgs: [
            {
                img: "/images/services-icons/mobile-app-development/swiftui.png",
            },
            {
                img: "/images/services-icons/mobile-app-development/jetpack compose icon_RGB.png",
            },
            {
                img: "/images/services-icons/mobile-app-development/Google-flutter-logo.png",
            },
        ],
    },
    {
        id: "Tab7",
        label: "Mobile App Maintenance and Support",
        content:
            "Launching a mobile app is just the beginning. To keep your application running smoothly and securely, ongoing maintenance and support are essential. We provide comprehensive post-launch services to ensure that your app remains up-to-date, bug-free, and optimized for performance.",
        secondContent: "Our maintenance services include regular updates, security patches, performance monitoring, feature enhancements, and user behavior analysis. We proactively identify potential issues and resolve them before they impact user experience. Whether it's adapting to new OS updates, optimizing app performance, or implementing new features, we ensure that your app remains reliable, secure, and competitive in the ever-evolving digital landscape.",
        btnText: "Let's Discuss",
        btnLink: "#",
        imgs: [
            {
                img: "/images/services-icons/mobile-app-development/swiftui.png",
            },
            {
                img: "/images/services-icons/mobile-app-development/jetpack compose icon_RGB.png",
            },
            {
                img: "/images/services-icons/mobile-app-development/Google-flutter-logo.png",
            },
        ],
    },
];

export var mainBannerImg = {
    backgroundImage: `url(/images/what-we-do/mobile-app-development.png)`,
};
import React, { useEffect } from "react";
import SimilerBanner from "../components/similerBanner/SimilerBanner";
import DevelopingSolution from "../components/developingsolution/DevelopingSolution";
import Testimonial from "../components/testimonila/Testimonial";
import CoverTheStack from "../components/coverthestack/CoverTheStack";
import SecondFaq from "../components/secondFaq/SecondFaq";
import Footer from "../components/footer/Footer";
import { Helmet } from "react-helmet";
import MegaHeader from "../components/MegaHeader/MegaHeader";
import TrendsInsights from "../components/trends-n-insights/TrendsInsights";
import RoadMap from "../components/roadMap/RoadMap";
import WhoWeServe from "../components/who-we-serve/WhoWeServe";
import HighValues from "../components/high-values/HighValues";
import TypesOfBuild from "../components/types-of-build/TypesOfBuild";
import WhyChooseBitsclan from "../components/why-choose-bitsclan/WhyChooseBitsclan";
import IndustriesTabs from "../components/industries-tabs/IndustriesTabs";
import {
    IndustryTabsData,
} from "../data/industriesTabsData";
import DevelopmentServices from "../components/development-services/DevelopmentServices";
import ContactSection from "../components/contactSection/ContactSection";
import { WebServicesIndustryTabsItemsData } from "../data/web-app-service-page";
import NewEngagementModels from "../components/new-engagment-modal";
import { DevelopmentServicesData, faqData, HighvaluesData, mainBannerImg, tabItems, tabs, TypesOfBuildData, WhoWeServeData, WhyChooseBitsclanData } from "../data/cyber-security-data";

const MobileDevelopmentServices = () => {


    return (
        <>
            <Helmet>
                <title>Protect Your Business with Cybersecurity Services | Bitsclan</title>
                <meta name="description" content="From network security to cloud computing security, we provide comprehensive protection against cyber threats and breaches. Explore our services." />
            </Helmet>

            <div className="sticky-header">
                <MegaHeader />
            </div>

            <SimilerBanner diffBannerBackgroundImage={mainBannerImg} mainHeading="Cybersecurity" greenHeading="Services" content="Protect your digital assets, manage risks, and build cyber-immunity with our cybersecurity services." subHeading="Trusted by Startups, SMEs, and Enterprises for Cybersecurity" btnText2={"Let's Get Started"} sideImage="" />

            <Testimonial
                heading="Web Development Company of the Year 2021"
                content="We received the Best Web Development Company of the Year award from Payoneer in 2021. We bring the same expertise, dedication, vision, and conviction to every service we provide."
            />

            <DevelopmentServices tabs={DevelopmentServicesData}
                heading='Our Expansive Range of Cybersecurity Services'
                subheading="Protect your business with our end-to-end cybersecurity solutions. We secure everything from cloud environments and enterprise networks to web applications with advanced threat detection, encryption, and compliance strategies. Stay ahead of cyber threats with expert-driven security solutions tailored to your business needs."
            />

            <TypesOfBuild typeOfApps={TypesOfBuildData} heading="The Impactful Benefits of Cyber Security for Your Business" content="Protecting data and digital assets is more crucial than ever. Our cybersecurity solutions provide comprehensive security against cyber threats, ensuring safe transactions, regulatory compliance, and robust infrastructure protection. With expert threat detection and risk management, businesses can operate securely in an evolving digital landscape." sideImage="cyber-security.png" />

            <WhoWeServe
                cardContent={WhoWeServeData}
                heading="Who We Serve"
                subHeading="From startups securing their first digital assets to large enterprises needing cybersecurity solutions, we provide data protection that scales with your needs. Our cybersecurity services ensure network security, threat detection, and compliance, keeping your business safe in an ever-evolving digital world."
            />

            <WhyChooseBitsclan
                tabs={WhyChooseBitsclanData}
                heading="Why Choose Bitsclan as Your Cybersecurity Services Partner?"
                subheading="In a world of ever-evolving cyber threats, protecting your business isn’t just an option—it’s a necessity. Bitsclan offers cutting-edge cybersecurity solutions, proactive threat detection, and expert security consulting to safeguard your systems, data, and users. Our approach ensures your organization remains secure, compliant, and resilient against cyber risks."
            />

            <HighValues
                heading="Essential Cybersecurity Features for Unmatched Digital Protection"
                subHeading="As cyber threats become more advanced, businesses need robust security measures to protect sensitive data, prevent breaches, and ensure compliance. Our cutting-edge cybersecurity solutions are designed to safeguard your digital infrastructure with real-time threat detection, proactive risk management, and industry-standard encryption."
                boxContent={HighvaluesData}
                btnLink="#"
                btnText="Consult Project Scope"
            />

            <NewEngagementModels />

            <RoadMap heading="Our Proven Roadmap to Secure Your Business" description="Cyber threats are evolving, and so should your security strategy. Our structured approach ensures your business stays protected with cutting-edge cybersecurity solutions. From assessing vulnerabilities to ongoing security maintenance, we follow a streamlined process that guarantees robust protection, rapid threat detection, and compliance with industry standards. Here’s how we do it:" roadMapHeading={["Security Assessment & Planning", "Strategy & Implementation", "Compliance & Risk Management", "Ongoing Protection & Support",]} roadMapsteps={["We analyze your current security setup, identify vulnerabilities, and create a solid action plan to safeguard your business against cyber threats.", "Our experts design and implement security measures, integrating advanced threat detection, encryption, and data protection to build a resilient system.", "We ensure your business meets regulatory requirements, reducing risks through continuous monitoring, policy enforcement, and proactive security protocols.", "Cyber threats never stop evolving, and neither do we. Our team provides 24/7 monitoring, rapid incident response, and regular updates to keep you secure."]} />

            < CoverTheStack
                whereStart={"Frontend"}
                mainHeading={"Optimize Your Cyber Security with Industry-Leading Tech Stack"}
                subHeading={
                    "At Bitsclan, we ensure that your DevOps processes are powered by the most advanced technologies available. Our carefully curated tech stack is designed to maximize efficiency, security, and scalability at every stage of the software development lifecycle. "
                }
                tabs={tabs}
                tabItems={tabItems}
            />

            <IndustriesTabs
                mainheading={"Industries we serve in Cyber Security"}
                tabs={IndustryTabsData}
                defaultTab={"Healthcare"}
                tabsItems={WebServicesIndustryTabsItemsData}
            />
            <DevelopingSolution heading="Developing Solutions That Transcend Mere Digital Transformation" subheading="We deliver innovative solutions that surpass digital transformation, driving impactful and lasting success." />

            <TrendsInsights specificClass="mobile-dev-page" category="cyber-security" />

            <SecondFaq
                specificClass="mobile-dev-page"
                faqs={faqData}
                mainHeading={"FAQs"}
                subHeading={
                    "Get quick answers to your questions about our mobile app development process and services."
                }
            />
            <ContactSection />

            <Footer />
        </>
    );
};

export default MobileDevelopmentServices;

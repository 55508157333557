import { Navigate, useLocation } from "react-router-dom";


const ProtectedRoute = ({ region, children }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const forcedRegion = searchParams.get("force")?.toUpperCase(); // Get 'force' param in uppercase


  // Allowed force values
  const allowedRegions = ["USA", "KSA", "AUS", "PK"];


  // Check if the force parameter exists and is valid
  if (forcedRegion && allowedRegions.includes(forcedRegion)) {
    sessionStorage.setItem("forcedRegion", forcedRegion); // Set session storage immediately
  }


  // Get stored forced region
  const savedForcedRegion = sessionStorage.getItem("forcedRegion");


  if (savedForcedRegion && forcedRegion) {
    return <Navigate to={`/${savedForcedRegion}`} replace />;
  }
  // If a forced region exists, bypass all restrictions
  if (savedForcedRegion) {
    return children;
  }

  // Apply original restrictions if no force parameter
  if (region === "PK" && location.pathname !== "/life-at-bitsclan") {
    return <Navigate to="/life-at-bitsclan" replace />;
  }
  if (region === "USA" && location.pathname === "/") {
    return <Navigate to="/USA" replace />;
  }
  if (region === "AUS" && location.pathname === "/") {
    return <Navigate to="/AUS" replace />;
  }
  if (region === "KSA" && location.pathname === "/") {
    return <Navigate to="/KSA" replace />;
  }


  return children;
};


export default ProtectedRoute;

// import { Navigate, useLocation } from "react-router-dom";

// const ProtectedRoute = ({ region, children }) => {
//   const location = useLocation();

//   // If the region is PK, redirect to "/life-at-bitsclan"
//   if (region === "PK" && location.pathname !== "/life-at-bitsclan") {
//     return <Navigate to="/life-at-bitsclan" replace />;
//   }
//   if (region === "USA" && location.pathname === "/") {
//     return <Navigate to="/USA" replace />;
//   }
//   if (region === "AUS" && location.pathname === "/") {
//     return <Navigate to="/AUS" replace />;
//   }
//   if (region === "KSA" && location.pathname === "/") {
//     return <Navigate to="/KSA" replace />;
//   }

//   return children;
// };

// export default ProtectedRoute;

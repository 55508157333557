const tabData = [
  {
    id: 1,
    title: "Healthcare",
    slug: "healthcare",
    content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
    bannerBtnText: "Let's Get Started",
    metaTitle:
      "Healthcare Software Development Services | Secure & Scalable Solutions",
    metaDes:
      "Build HIPAA-compliant healthcare software, including telemedicine apps, EMR systems, and patient portals. Enhance care delivery with Bitsclan",
    description:
      "We deliver tailored solutions, like EHR systems and patient management platforms, enhancing care delivery, compliance, and operational efficiency in the healthcare sector.",
    icon: "/images/heart.png",
    image: "healthcare.png",
    bannerHeading: "Build healthcare software",
    bannerImage: "banner-image-industry.png",
    backgroundImage: `url(/images/industry-detial-banner-bg/health-care.png)`,
    discriptionSection: {
      heading: "Technology innovation",
      discription:
        "revolutionizes healthcare provider-patient interactions, empowering medical professionals to deliver cost-effective medical services. By leveraging healthcare software development services, you can automate processes, enhance efficiency and optimize facets ranging from patient data management to medical insurance.",
      cardHeading: "Healthcare Software Company",
      cardDiscripiton:
        "Healthcare industry is undergoing crucial changes with heavy penetration of emerging technology in both healthcare providers as well as patient segments. The time is now to transform your existing systems to the cloud, mobility, and IoT-driven solutions to automate business processes and manage healthcare data efficiently.Bitsclan is a prominent healthcare software development company that offers technology consultation, customization, integration, modernization and maintenance of healthcare software.With more than 24 years of experience, we have successfully served various organizations helping them improve the quality of care.",
    },
    statsSection: {
      backgroundImage: "healthcare-counter-bg.png",
      heading: "Lead the Future of",
      greenPart: "Digital Health",
      discription:
        "Bitsclan fosters HealthTech companies by developing custom medical applications and leveraging benefits from our proficiency in HIPAA, HITECH, HAPI/FHIR, CoPay, eFax, OWASP, IEC 62443, DICOM, ICD-10, and CPT.",
      subheading: "Your Top Healthcare",
      greenPartSubHeading: "Consulting Firm",
      stats: [
        {
          count: "650+",
          text: "Expert Minds",
        },
        {
          count: "3000+",
          text: "Global Clients",
        },
        {
          count: "4200+",
          text: "Successful Projects",
        },
        {
          count: "93%",
          text: "Client Retention Rate",
        },
      ],
    },
    industryServices: {
      heading: "Healthcare Software Development Services",
      text: "Healthcare software development services involve the design, development, and implementation of customized software solutions to enhance patient care and optimize hospital workflows. Bitsclan offers end-to-end healthcare software services and solutions to healthcare organizations, physicians, clinics, hospitals, digital health startups, and medical device infrastructures.",
      industryServicesSlides: [
        {
          id: 1,
          borderClass: "border-1",
          icon: "/images/icon1.svg",
          heading: "EMR and EHR Software Development",
          text: "Our expert developers create custom EMR and EHR systems, helping healthcare providers manage patient data, improve care coordination, and streamline workflows for a seamless patient experience.",
          btnLink: "emr-ehr-software-development",
          image: "image.png",
        },
        {
          id: 2,
          borderClass: "border-2",
          icon: "/images/icon2.svg",
          heading: "Custom Medical App Development",
          text: "We design tailored medical apps to enhance patient care and streamline hospital operations. Our apps are HIPAA-compliant and support scheduling, messaging, and prescription management.",
          btnLink: "emr-ehr-software-development",
          image: "image.png",
        },
        {
          id: 3,
          borderClass: "border-1",
          icon: "/images/machine-learning.png",
          heading: "Telemedicine Platform Development",
          text: "Our telemedicine platforms connect healthcare professionals and patients remotely. With secure video calls, appointment booking, and medical history tracking, we make healthcare accessible from anywhere.",
          btnLink: "emr-ehr-software-development",
          image: "image.png",
        },

        {
          id: 1,
          borderClass: "border-1",
          icon: "/images/icon1.svg",
          heading: "Patient Management Systems",
          text: "We create systems to manage patient data, appointments, and billing efficiently. Our solutions improve workflow efficiency, reduce administrative tasks, and enhance overall patient care.",
          btnLink: "emr-ehr-software-development",
          image: "image.png",
        },
        {
          id: 2,
          borderClass: "border-2",
          icon: "/images/icon2.svg",
          heading: "Medical Billing Software",
          text: "We develop medical billing software to simplify billing, insurance claims, and payment processing. Our solutions ensure accuracy, streamline financial processes, and support compliance with regulations.",
          btnLink: "emr-ehr-software-development",
          image: "image.png",
        },
      ],
    },
    industryAdvertising: {
      heading:
        "Healthcare Software Security Standards and Certifications We Work With",
      discription:
        "Our clients’ data protection, security, and product compliance are our top priorities; that’s why, as a leading healthcare app development company, we comply with robust security standards and certifications in healthcare. Our healthcare software solutions will also fully comply with national, regional, and industry health standards. Our healthcare IT consultants perform vulnerability scans using the OWASP methodology and penetration testing and examine software security and compliance through audits.",
      image: "health-care-software-image.png",
    },
    softwareSolutions: {
      heading: "Explore Our Healthcare Software Solutions",
      discription:
        "Healthcare software solutions from Bitsclan are designed to meet the specific needs of the healthcare industry to deliver efficient, personalized, and quality care. Enable data-driven decisions and transform the healthcare management process with the right healthcare software solutions.",
      healthcareSolutionsData: [
        {
          id: 1,
          heading: "Healthcare CRM",
          text: "Our end to end CRM software for healthcare can automate your medical operations, enhance the patient’s journey, and help you manage the patient portal effectively.",
        },
        {
          id: 2,
          heading: "Healthcare Analytics Solutions",
          text: "Our healthcare analytics software helps your business with accurate data analysis that improves decision-making speed and gives users the ability to prepare data without writing any code.",
        },
        {
          id: 3,
          heading: "Hospital Management Software",
          text: "Our HMS - Hospital Management System helps you access patient data, manage effectively, and generate multiple records based on your requirements - location, gender, age, etc,",
        },
        {
          id: 4,
          heading: "Patient Engagement Platform",
          text: "Our Patient engagement solutions empower patients to proactively manage their health information, communicate with experts, schedule appointments and participate in treatment plans.",
        },
      ],
    },
    industryExpertise: {
      heading: "Our Expertise: Cutting-Edge Healthcare Software Development",
      discription:
        "Our healthcare software engineers take the best advantage of leveraging innovative technologies to offer smarter healthcare solutions development services.",
      accordionData: [
        {
          id: "collapseOne",
          title: "Blockchain Solutions",
          content:
            "Our blockchain-based healthcare software services ensure the secure transfer of health records and clinical trials between private blockchains for a solid long-term investment. We adhere to industry standards and security protocols.",
          expanded: true,
        },
        {
          id: "collapseTwo",
          title: "AI & Machine Learning",
          content:
            "Harness the power of AI and ML to enhance decision-making, automate tasks, and deliver intelligent healthcare solutions. Our advanced algorithms provide actionable insights tailored to your needs.",
          expanded: false,
        },
        {
          id: "collapseThree",
          title: "Immersive Technologies (VR/AR/MR)",
          content:
            "Leverage virtual, augmented, and mixed reality to transform patient care, training, and diagnostics with immersive, cutting-edge experiences.",
          expanded: false,
        },
        {
          id: "collapseFour",
          title: "Robotic Process Automation (RPA)",
          content:
            "Streamline repetitive tasks and optimize operations with RPA, enabling greater efficiency and precision in healthcare workflows.",
          expanded: false,
        },
      ],
    },
    industryFaqs: {
      heading: "Frequently Asked Questions",
      discription:
        "Find expert answers to FAQs on healthcare web application services, including deployment, benefits, and best practices.",
      faqData: [
        {
          id: "collapseOne",
          question:
            "Why is custom software development critical for healthcare facilities?",
          answer:
            "Custom software helps healthcare facilities meet specific needs, enhance operations, and deliver quality patient care",
        },
        {
          id: "collapseTwo",
          question: "What do healthcare software development companies do?",
          answer:
            "They design, develop, and implement tailored software to streamline healthcare operations and improve efficiency.",
        },
        {
          id: "collapseThree",
          question:
            "What types of custom healthcare software development solutions are available?",
          answer:
            "Solutions include EHR systems, telemedicine platforms, patient portals, and analytics tools.",
        },
        {
          id: "fourth",
          question:
            "How is custom healthcare software beneficial from ready-made solutions?",
          answer:
            "Custom software offers tailored features, scalability, and integration options to meet unique business needs.",
        },
        {
          id: "fifth",
          question:
            "Can the implemented custom healthcare be modified or expanded after its deployment?",
          answer:
            "Yes, custom software can be updated or expanded to adapt to evolving requirements and technologies.",
        },
      ],
    },
  },

  {
    id: 2,
    metaTitle:
      "Fintech Software Development Services | Next-Gen Financial Solution",
    metaDes:
      "Leverage advanced fintech software for digital wallets, payment gateways, and blockchain solutions. Build secure and innovative financial apps with Bitsclan",
    content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
    title: "Fintech",
    slug: "fintech",
    bannerBtnText: "Let's Get Started",
    bannerHeading: "Build Financial Technology Software",
    description:
      "Fintech thrives on innovation, and we’re here to drive it forward. Our web apps simplify complex financial processes, from secure payment systems to financial planning tools. They’re designed to empower businesses, enhance customer experiences, and keep everything secure along the way.",
    icon: "/images/Fintech.png",
    smallIcon: "Fintech-logo.png",
    image: "fintech.png",
    bannerHeading: "Build Fintech software",
    bannerImage: "fintech-detail-banner.png",
    backgroundImage: `url(/images/industry-detial-banner-bg/Fintech.png)`,

    discriptionSection: {
      heading: "Fintech Software Expertise",
      discription:
        "We provide innovative fintech solutions, including secure payment gateways, custom banking platforms, robo-advisors, blockchain for transparent transactions, and AI-powered fraud detection. Enhance your financial operations with secure, efficient, and reliable technology.",

      cardHeading:
        "Transforming Financial Services with Cutting-Edge Technology",
      cardDiscripiton:
        "The fintech revolution is transforming how individuals and businesses engage with financial services, delivering seamless, efficient, and secure solutions. From digital payments to AI-powered fraud detection and advanced investment platforms, our innovations streamline operations and enhance user experiences. Embrace cutting-edge technology to redefine your financial services, build trust, and unlock new growth opportunities.",
    },

    statsSection: {
      backgroundImage: "Fintech-counter.jpeg",
      heading: "Lead the Future of",
      greenPart: "Digital Finance",
      discription:
        "Empower your business with cutting-edge fintech solutions designed to streamline operations, enhance security, and redefine financial experiences. Stay ahead in the evolving digital finance landscape.",
      subheading: "Your Top Fintech",
      greenPartSubHeading: "Consulting Firm",
      stats: [
        {
          count: "650+",
          text: "Projects Delivered in the Financial Sector",
        },
        {
          count: "300+",
          text: "Satisfied Financial Institutions",
        },
        {
          count: "2500+",
          text: "Custom Features Deployed",
        },
        {
          count: "95%",
          text: "Client Satisfaction Rate",
        },
      ],
    },

    industryServices: {
      heading: "Fintech Software Development Services",
      text: "Empower your business with cutting-edge fintech software solutions designed to enhance security, efficiency, and user experiences. Transform financial operations with innovation-driven technology.",
      industryServicesSlides: [
        {
          id: 1,
          borderClass: "border-1",
          icon: "/images/icon1.svg",
          heading: "Digital Banking Solutions",
          text: "Develop user-friendly, secure, and feature-rich online banking platforms tailored to meet the needs of modern financial institutions and their customers.",
          btnLink: "emr-ehr-software-development",
          image: "image.png",
        },
        {
          id: 2,
          borderClass: "border-2",
          icon: "/images/icon2.svg",
          heading: "Payment Processing Systems",
          text: "Implement seamless, efficient, and secure digital payment gateways to support hassle-free transactions and enhance user trust in your services.",
          btnLink: "emr-ehr-software-development",
          image: "image.png",
        },
        {
          id: 3,
          borderClass: "border-1",
          icon: "/images/machine-learning.png",
          heading: "Blockchain and Cryptocurrency Development",
          text: "Leverage blockchain technology to create transparent, decentralized systems that drive innovation and provide secure, scalable cryptocurrency solutions.",
          btnLink: "emr-ehr-software-development",
          image: "image.png",
        },
      ],
    },

    industryAdvertising: {
      heading: "Financial Security Standards and Regulations We Comply With",
      discription:
        "In the financial sector, security and trust are essential. We adhere to global standards like PCI DSS, ensuring the highest level of payment data protection, GDPR for robust data privacy, and ISO 27001 for comprehensive information security management. Our expertise helps you stay ahead of regulatory challenges, delivering secure, compliant fintech solutions that inspire trust and confidence among your users. Partner with us for reliable, regulation-compliant financial platforms that meet the demands of today’s digital economy.",
      image: "Fintech-add.jpg",
    },

    softwareSolutions: {
      heading: "Explore Our Fintech Software Solutions",
      discription:
        "Healthcare software solutions from Bitsclan are designed to meet the specific need of the healthcare industry to deliver efficient, personalized, and quality care. Enable data-driven decisions and transform the healthcare management process with the right healthcare software solutions.",
      healthcareSolutionsData: [
        {
          id: 1,
          heading: "Digital Wallets",
          text: "Develop secure, user-friendly, and feature-rich digital wallet solutions designed to store, manage, and transact digital currencies with ease and reliability.",
        },
        {
          id: 2,
          heading: "Stock Trading Platforms",
          text: "Create custom platforms equipped with advanced tools to enable seamless, efficient, and secure trading and investment experiences for users.",
        },
        {
          id: 3,
          heading: "Loan Management Systems",
          text: "Design comprehensive and streamlined systems to efficiently manage loan processes, from application and disbursement to tracking repayments and settlements.",
        },
        {
          id: 4,
          heading: "Insurance Tech Solutions",
          text: "Develop innovative solutions to automate, optimize, and enhance various insurance processes, improving efficiency and customer satisfaction.",
        },
      ],
    },
    industryExpertise: {
      heading: "Our Expertise: Leading Fintech Software Development",
      discription:
        "We craft smart financial tech solutions, focusing on secure apps, digital wallets, blockchain, and payment gateways to simplify your financial world.",
      accordionData: [
        {
          id: "collapseOne",
          title: "Blockchain Solutions",
          content:
            "Our blockchain-powered fintech solutions ensure secure, transparent transactions, providing a reliable foundation for digital wallets, investment tools, and secure payment gateways, enhancing customer trust.",
          expanded: true,
        },
        {
          id: "collapseTwo",
          title: "Digital Wallet Development",
          content:
            "We create secure, user-friendly digital wallets that enable seamless payments and cryptocurrency management. Our solutions prioritize safety and ease of use for both consumers and businesses.",
          expanded: false,
        },
        {
          id: "collapseThree",
          title: "Payment Gateway Integration",
          content:
            "Our payment gateway services streamline online transactions, ensuring secure, efficient payment processing. We integrate robust security protocols to protect sensitive financial data in real time.",
          expanded: false,
        },
        {
          id: "collapseFour",
          title: "Fintech Security Solutions",
          content:
            "We offer cutting-edge security solutions to protect financial applications. From secure data encryption to fraud prevention, our systems safeguard financial transactions and customer information.",
          expanded: false,
        },
      ],
    },

    industryFaqs: {
      heading: "Frequently Asked Questions",
      discription: "Address common concerns like",
      faqData: [
        {
          id: "collapseOne",
          question: "What technologies are used for fintech development?",
          answer:
            "We leverage cutting-edge technologies such as blockchain, artificial intelligence (AI), machine learning (ML), cloud computing, and big data analytics to build secure and innovative fintech solutions. These technologies enhance efficiency, scalability, and user experience.",
        },
        {
          id: "collapseTwo",
          question: "How do you ensure data security and compliance?",
          answer:
            "We adhere to globally recognized security standards such as PCI DSS, ISO 27001, and GDPR. Additionally, we implement advanced encryption protocols, multi-factor authentication, and regular security audits to protect sensitive financial data.",
        },
        {
          id: "collapseThree",
          question: "Can you integrate with existing banking systems?",
          answer:
            "Yes, we specialize in integrating modern solutions with legacy banking systems using APIs, middleware, and other secure protocols. Our seamless integrations ensure minimal disruption while enhancing system functionality.",
        },
        {
          id: "fourth",
          question: "How long does it take to develop a fintech solution?",
          answer:
            "The development timeline depends on the complexity and scope of your project. On average, a custom fintech solution may take 3-6 months, but smaller features or MVPs (Minimum Viable Products) can be developed more quickly.",
        },
        {
          id: "fifth",
          question: "Do you offer post-development support?",
          answer:
            "Absolutely! We provide comprehensive post-development support, including regular maintenance, feature updates, security patches, and 24/7 technical assistance to ensure the smooth operation of your fintech solution.",
        },
      ],
    },
  },

  {
    id: 3,
    metaTitle:
      "Custom Retail Software Development Services | Drive Retail Success",
    metaDes:
      "Shopping online should be seamless, and our web apps make sure it is. From personalized shopping experiences to advanced inventory management, we help businesses connect with customers, grow their sales, and create lasting impressions.",
    content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
    title: "Retail & eCommerce",
    slug: "retail-ecommerce",
    description:
      "We support retailers with inventory management systems, personalized shopping experiences, and omnichannel platforms that boost sales and customer loyalty.",
    icon: "/images/Retail-eCommerce.png",
    image: "ecommerce.png",
    bannerBtnText: "Let's Get Started",
    bannerHeading: "Build Retail & eCommerce Software",
    bannerImage: "ecommerce-detail-banner.png",
    backgroundImage: `url(/images/industry-detial-banner-bg/Retail-ecommerce.png)`,

    discriptionSection: {
      heading: "Transforming Retail and eCommerce with Innovative Technology ",
      discription:
        "The retail and eCommerce industries are evolving rapidly, driven by customer expectations and digital transformation. From personalized shopping experiences to efficient supply chain management, our solutions empower businesses to adapt, grow, and succeed in a competitive market.",

      cardHeading: "Retail & eCommerce Expertise",
      cardDiscripiton:
        "We deliver end-to-end eCommerce solutions designed to elevate your business. Our omnichannel platforms provide a seamless shopping experience across all devices, while AI-powered product recommendations drive personalized engagement. With efficient inventory and order management, real-time tracking, and seamless payment integration, we ensure smooth and secure transactions. Our advanced data analytics offer valuable insights into customer behavior, enabling smarter decisions and fostering business growth.",
    },

    statsSection: {
      backgroundImage: "ecommerce-counter-bg.jpg",
      heading: "Transform Your ",
      greenPart: "eCommerce Business",
      discription:
        "We provide smart solutions to simplify your online store, improve security, and enhance payment systems, helping you stay ahead in the fast-evolving digital shopping world.",
      subheading: "Your Top Retail & eCommerce",
      greenPartSubHeading: "Consulting Firm",
      stats: [
        {
          count: "800+",
          text: " Projects Delivered in Retail and eCommerce",
        },
        {
          count: "500+",
          text: "Satisfied Retail Brands",
        },
        {
          count: "10,000+",
          text: "Custom Features Deployed",
        },
        {
          count: "97%",
          text: "Client Satisfaction Rate",
        },
      ],
    },

    industryServices: {
      heading: "Retail & eCommerce Software Development Services",
      text: "Our Retail & eCommerce Software Development Services create customized, scalable solutions that enhance customer experiences and streamline operations. We build secure platforms that drive growth and success in the digital marketplace.",
      industryServicesSlides: [
        {
          id: 1,
          borderClass: "border-1",
          icon: "/images/icon1.svg",
          heading: "Custom eCommerce Platforms",
          text: "Develop scalable, feature-rich eCommerce platforms tailored to your unique business needs, providing an optimal shopping experience for your customers.",
          btnLink: "emr-ehr-software-development",
          image: "image.png",
        },
        {
          id: 2,
          borderClass: "border-2",
          icon: "/images/icon2.svg",
          heading: "Mobile Commerce Solutions",
          text: "Create seamless and secure mobile commerce solutions that offer smooth digital payment integration, enhancing user experience and trust across all mobile platforms.",
          btnLink: "emr-ehr-software-development",
          image: "image.png",
        },
        {
          id: 3,
          borderClass: "border-1",
          icon: "/images/machine-learning.png",
          heading: "POS and Inventory Management",
          text: "Optimize your retail operations with robust POS and inventory management systems, designed to streamline stock tracking, order management, and real-time analytics.",
          btnLink: "emr-ehr-software-development",
          image: "image.png",
        },
      ],
    },

    industryAdvertising: {
      heading: "Retail & eCommerce Security Standards We Comply With",
      discription:
        "We adhere to the highest Retail & eCommerce security standards to ensure the protection of customer data and transactions. Compliant with PCI DSS, GDPR, and ISO 27001, our solutions are built to safeguard against threats and maintain trust across all digital platforms.",
      image: "ecommerce-add.jpg",
    },

    softwareSolutions: {
      heading: "Explore Our Retail & eCommerce Solutions",
      discription:
        "Explore our innovative Retail & eCommerce Solutions designed to boost growth, enhance customer experiences, and streamline operations with secure, scalable platforms and seamless integrations.",
      healthcareSolutionsData: [
        {
          id: 1,
          heading: "Omnichannel Platforms",
          text: "Unify online and in-store experiences to deliver a seamless customer journey across all touchpoints, ensuring consistent and personalized engagement.",
        },
        {
          id: 2,
          heading: "Custom Marketplace Development",
          text: "Create tailored platforms that connect sellers and buyers, offering a secure and efficient environment for transactions and business growth.",
        },
        {
          id: 3,
          heading: "Logistics & Supply Chain Solutions",
          text: "Optimize delivery processes with real-time tracking and automation, ensuring faster, more reliable shipments and improved operational efficiency.",
        },
        {
          id: 4,
          heading: "Customer Loyalty Programs",
          text: "Engage customers with personalized rewards and offers that drive repeat business and strengthen brand loyalty through targeted incentives.",
        },
      ],
    },

    industryExpertise: {
      heading: "Our Expertise: Cutting-Edge Retail & eCommerce Solutions",
      discription:
        "We specialize in crafting innovative, secure, and scalable platforms that elevate customer engagement and drive business growth in the retail and eCommerce sectors.",
      accordionData: [
        {
          id: "collapseOne",
          title: "Seamless Shopping Experiences",
          content:
            "Integrate both online and offline channels to deliver a smooth and personalized shopping journey across all customer touchpoints, ensuring satisfaction at every step.",
          expanded: true,
        },
        {
          id: "collapseTwo",
          title: "Custom Marketplace Solutions",
          content:
            "Design tailored platforms that connect buyers and sellers with secure and efficient transaction systems, promoting smooth commerce and business expansion.",
          expanded: false,
        },
        {
          id: "collapseFour",
          title: "Personalized Customer Engagement",
          content:
            "Boost customer loyalty with personalized rewards, offers, and incentives, turning one-time shoppers into repeat customers and fostering long-term relationships.",
          expanded: false,
        },
      ],
    },

    industryFaqs: {
      heading: "Retail & eCommerce: Commonly Asked Questions",
      discription:
        "Here are answers to some of the most frequently asked questions about our retail and eCommerce services:",
      faqData: [
        {
          id: "collapseOne",
          question:
            "What technologies do you use to build eCommerce platforms?",
          answer:
            "We use modern frameworks and tools such as Shopify, Magento, WooCommerce, ReactJS, and NodeJS to create high-performing platforms. Additionally, we incorporate cloud solutions for scalability and reliability.",
        },
        {
          id: "collapseTwo",
          question: "Can you integrate third-party tools like CRMs and ERPs?",
          answer:
            "Yes, we specialize in integrating tools like Salesforce, Zoho CRM, SAP, and Oracle ERP into eCommerce platforms to streamline business operations and improve efficiency.",
        },
        {
          id: "collapseThree",
          question: "How do you ensure fast and secure transactions?",
          answer:
            "We implement SSL/TLS encryption, tokenization, and PCI DSS compliance to safeguard transactions. Additionally, we optimize platforms for fast loading speeds, ensuring a smooth checkout experience.",
        },
        {
          id: "fourth",
          question: "Do you provide mobile app development for eCommerce?",
          answer:
            "Yes, we design and develop mobile apps for iOS and Android, ensuring a responsive and engaging shopping experience across devices.",
        },
        {
          id: "fifth",
          question: "How do you handle inventory and order management?",
          answer:
            "We develop systems that provide real-time inventory tracking, automated reordering, and order fulfillment management, ensuring efficient operations for both small and large-scale businesses.",
        },
        {
          id: "sixth",
          question: "Can you help us create a custom marketplace?",
          answer:
            "Absolutely! We specialize in developing multi-vendor marketplaces tailored to your business needs, with features like seller dashboards, payment splitting, and product catalog management.",
        },
      ],
    },
  },

  {
    id: 4,
    metaTitle:
      "Real Estate Software Development | Transform Property Management",
    metaDes:
      "Finding and managing properties has never been easier. With our web apps, you can offer virtual tours, manage client relationships, and help buyers and sellers connect seamlessly. We build tools that keep real estate professionals one step ahead.",
    content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
    title: "Real Estate",
    slug: "real-estate",
    description:
      "We provide real estate professionals with property management systems, CRM platforms, and secure transaction solutions to streamline operations and improve client interactions.",
    icon: "/images/RealEstate.png",
    image: "realestate.png",
    bannerBtnText: "Let's Get Started",
    bannerHeading: "Build Real Estate Software",
    bannerImage: "realestate-detail-banner.png",
    backgroundImage: `url(/images/industry-detial-banner-bg/RealEstate.png)`,

    discriptionSection: {
      heading: "Transforming Real Estate with Cutting-Edge Technology",
      discription:
        "The real estate industry is embracing digital transformation to streamline property management, boost sales, and improve client interactions. Our tailored real estate solutions help businesses modernize their operations, enhance visibility, and create seamless customer experiences.",

      cardHeading: "Real Estate Technology Expertise",
      cardDiscripiton:
        "We provide innovative real estate solutions, including dynamic property listing portals, immersive virtual tours, and seamless CRM integration. Our automated lead generation and secure online transaction systems streamline operations and enhance customer experiences. Leverage cutting-edge technology to boost efficiency and drive growth in your real estate business.",
    },

    statsSection: {
      backgroundImage: "realestate-counter-bg.jpg",
      heading: "Lead the Future of",
      greenPart: " Real Estate Technology",
      discription:
        "We help your business thrive with easy-to-use property management tools, real estate portals, virtual tours, and CRM systems to boost listings and lead generation.",
      subheading: "Your Top Real Estate",
      greenPartSubHeading: "Consulting Firm",
      stats: [
        {
          count: "600+",
          text: "Projects Delivered in the Real Estate Sector",
        },
        {
          count: "400+",
          text: "Satisfied Agencies and Developers",
        },
        {
          count: "5,000+",
          text: "Custom Features Deployed",
        },
        {
          count: "95%",
          text: "Client Retention Rate",
        },
      ],
    },

    industryServices: {
      heading: "Real Estate Software Development Services",
      text: "We offer custom real estate software solutions that optimize property management, enhance client engagement, and drive efficiency across operations.",
      industryServicesSlides: [
        {
          id: 1,
          borderClass: "border-1",
          icon: "/images/icon1.svg",
          heading: "Property Management Systems",
          text: "Simplify tasks like tenant management, rent collection, and maintenance scheduling with custom property management systems tailored to your business needs.",
          btnLink: "emr-ehr-software-development",
          image: "image.png",
        },
        {
          id: 2,
          borderClass: "border-2",
          icon: "/images/icon2.svg",
          heading: "Virtual Tour Solutions",
          text: "Bring properties to life with 360-degree virtual tours and 3D modeling, offering an immersive experience for potential buyers or renters.",
          btnLink: "emr-ehr-software-development",
          image: "image.png",
        },
        {
          id: 3,
          borderClass: "border-1",
          icon: "/images/machine-learning.png",
          heading: "Real Estate CRMs",
          text: "Empower agents with robust CRMs for lead tracking, pipeline management, and automated follow-ups, optimizing real estate operations.",
          btnLink: "emr-ehr-software-development",
          image: "image.png",
        },
      ],
    },

    industryAdvertising: {
      heading: "Real Estate Data Security and Compliance Standards",
      discription:
        "Ensuring data security in real estate is crucial. We comply with global data protection standards such as GDPR, SOC 2, and ISO 27001. Our advanced security measures safeguard sensitive client information, enabling secure property transactions and tenant management systems.",
      image: "realestate-add.jpg",
    },

    softwareSolutions: {
      heading: "Explore Our Real Estate Software Solutions",
      discription:
        "Discover innovative real estate software solutions designed to streamline operations, enhance client interactions, and drive business growth.",
      healthcareSolutionsData: [
        {
          id: 1,
          heading: "Property Portals",
          text: "Build feature-rich portals for property listings, advanced search capabilities, and seamless booking experiences.",
        },
        {
          id: 2,
          heading: "Agent Management Systems",
          text: "Streamline operations for real estate agents with robust tools for task management, client interactions, and performance tracking.",
        },
        {
          id: 3,
          heading: "Market Analytics Tools",
          text: "Gain valuable insights into property trends, pricing, and investment opportunities with powerful analytics solutions.",
        },
        {
          id: 4,
          heading: "Online Booking Platforms",
          text: "Enable secure, real-time property booking and transaction processing for enhanced user experience and trust.",
        },
      ],
    },

    industryExpertise: {
      heading: "Our Expertise: Transformative Real Estate Software Solutions",
      discription:
        "We deliver innovative and scalable real estate software solutions to simplify property management, enhance customer experiences, and optimize business operations.",
      accordionData: [
        {
          id: "collapseOne",
          title: "Feature-Rich Property Portals",
          content:
            "Develop advanced property listing platforms with powerful search capabilities, seamless booking options, and intuitive user experiences for buyers and renters.",
          expanded: true,
        },
        {
          id: "collapseTwo",
          title: "Agent Management Systems",
          content:
            "Empower real estate agents with tools for task automation, lead tracking, and performance analytics, streamlining their workflows and enhancing efficiency.",
          expanded: false,
        },
        {
          id: "collapseThree",
          title: "Market Analytics Solutions",
          content:
            "Deliver actionable insights with tools for analyzing property trends, pricing, and market dynamics, enabling informed decision-making and better investment strategies.",
          expanded: false,
        },
        {
          id: "collapseFour",
          title: "Secure Online Booking Platforms",
          content:
            "Facilitate real-time property bookings with secure transaction systems, ensuring a trustworthy and hassle-free experience for users.",
          expanded: false,
        },
      ],
    },

    industryFaqs: {
      heading: "Your Questions About Real Estate Solutions, Answered",
      discription:
        "Find answers to common questions about our innovative and secure real estate software development services:",
      faqData: [
        {
          id: "collapseOne",
          question: "Can you develop custom solutions for real estate portals?",
          answer:
            "Yes, we specialize in building custom real estate portals tailored to your business needs. From advanced property search filters to virtual tours, we deliver features that enhance user engagement and simplify operations.",
        },
        {
          id: "collapseTwo",
          question:
            "How do you ensure the security of property and transaction data?",
          answer:
            "We implement top-tier security protocols, including end-to-end encryption, multi-factor authentication, and compliance with GDPR and ISO 27001, ensuring your data remains protected.",
        },
        {
          id: "collapseThree",
          question: "Can your systems integrate with existing tools like CRMs?",
          answer:
            "Yes, our solutions can seamlessly integrate with CRMs, ERPs, and other tools to create a unified platform for real estate operations, improving efficiency and collaboration.",
        },
        {
          id: "fourth",
          question: "Do you offer solutions for virtual tours and 3D modeling?",
          answer:
            "Absolutely! We develop interactive virtual tour platforms and 3D property modeling solutions to enhance the property viewing experience for buyers and tenants.",
        },
        {
          id: "fifth",
          question: "How long does it take to build a real estate portal?",
          answer:
            "The timeline depends on the project’s complexity and scope. A standard real estate portal typically takes 3-6 months, while smaller features or MVPs (Minimum Viable Products) can be developed more quickly.",
        },
        {
          id: "sixth",
          question: "What types of real estate businesses do you work with?",
          answer:
            "We cater to real estate agencies, property developers, brokers, and property management firms, offering tailored solutions to meet diverse business needs.",
        },
      ],
    },
  },

  {
    id: 5,
    title: "Automotive",
    slug: "automotive",
    metaTitle: "Automation Software Development | Smart Industrial Solutions",
    metaDes: "Unlock the power of automation with IoT, robotics software, and workflow tools. Optimize your industry processes with Bitsclan.",
    description:
      "The automotive world is evolving fast, and so should your software! Whether it’s tracking vehicles, managing inventory, or connecting with customers, we create easy-to-use solutions that simplify operations and improve experiences.",
    content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
    icon: "/images/Automotive.png",
    image: "automotive.png",
    bannerHeading: "Build Automotive Software",
    bannerBtnText: "Let's Get Started",
    bannerImage: "automotive.png",
    backgroundImage: `url(/images/industry-detial-banner-bg/Automotive.png)`,

    discriptionSection: {
      heading: "Revolutionizing the Automotive Industry with Technology ",
      discription:
        "The automotive industry is undergoing a massive transformation driven by digital innovation. From connected vehicles to intelligent fleet management, our customized software solutions empower businesses to stay ahead in the rapidly evolving automotive landscape.",

      cardHeading: "Automotive Technology Expertise",
      cardDiscripiton:
        "We deliver innovative automotive software solutions, including connected vehicle systems and advanced telematics integration, to enhance connectivity and performance. Our fleet management and predictive maintenance tools streamline operations and reduce downtime. Additionally, our Dealer Management Systems (DMS) empower dealerships with efficient inventory, sales, and service management.",
    },

    statsSection: {
      backgroundImage: "automotive-counter-bg.jpg",
      heading: "Lead the Future of ",
      greenPart: " Automotive Innovation",
      discription:
        "Driving innovation in the automotive industry with cutting-edge software solutions.",
      subheading: "Your Top Automotive",
      greenPartSubHeading: "Consulting Firm",
      stats: [
        {
          count: "700+",
          text: "Projects Delivered in the Automotive Sector",
        },
        {
          count: "500+",
          text: "Automotive Businesses Empowered",
        },
        {
          count: "10,000+",
          text: "Features Deployed for Vehicle and Fleet Optimization",
        },
        {
          count: "97%",
          text: "Customer Satisfaction Rate",
        },
      ],
    },

    industryServices: {
      heading: "Automotive Software Development Services",
      text: "Revolutionize your automotive business with cutting-edge software solutions designed to enhance efficiency, optimize fleet management, and deliver seamless connected vehicle experiences.",
      industryServicesSlides: [
        {
          id: 1,
          borderClass: "border-1",
          icon: "/images/icon1.svg",
          heading: "Fleet Management Systems",
          text: "Optimize vehicle tracking, route planning, and maintenance scheduling with advanced fleet management solutions tailored for efficiency.",
          btnLink: "fleet-management-systems",
          image: "image.png",
        },
        {
          id: 2,
          borderClass: "border-2",
          icon: "/images/icon2.svg",
          heading: "Dealer Management Systems",
          text: "Streamline sales, service, and inventory processes for automotive dealerships with powerful DMS solutions.",
          btnLink: "dealer-management-systems",
          image: "image.png",
        },
        {
          id: 3,
          borderClass: "border-1",
          icon: "/images/machine-learning.png",
          heading: "Connected Vehicle Platforms",
          text: "Enable real-time vehicle data monitoring and driver-assistance systems with cutting-edge connected vehicle platforms.",
          btnLink: "connected-vehicle-platforms",
          image: "image.png",
        },
      ],
    },

    industryAdvertising: {
      heading: "Automotive Data Security and Compliance Standards",
      discription:
        "Data security is crucial in the automotive industry. We adhere to global standards such as ISO/SAE 21434 for cybersecurity and GDPR for data protection. Our solutions ensure the secure exchange of data between vehicles, devices, and systems.",
      image: "automotive-add.jpg",
    },

    softwareSolutions: {
      heading: "Explore Our Automotive Software Solutions",
      discription:
        "Discover innovative and tailored automotive software solutions designed to enhance vehicle performance, optimize fleet management, and elevate dealership operations.",

      healthcareSolutionsData: [
        {
          id: 1,
          heading: "Connected Vehicle Systems",
          text: "Enable real-time communication between vehicles, infrastructure, and devices for smarter and safer driving experiences.",
        },
        {
          id: 2,
          heading: "Telematics Solutions",
          text: "Monitor vehicle performance, driver behavior, and fuel efficiency through advanced telematics for optimized fleet operations.",
        },
        {
          id: 3,
          heading: "Predictive Maintenance Tools",
          text: "Reduce vehicle downtime with AI-powered diagnostics and maintenance alerts, ensuring timely repairs and greater reliability.",
        },
        {
          id: 4,
          heading: "Car Rental Platforms",
          text: "Build intuitive car rental platforms featuring advanced booking, tracking, and management tools for a seamless user experience.",
        },
        {
          id: 5,
          heading: "Electric Vehicle (EV) Integration",
          text: "Develop EV-compatible systems for charging stations, fleet operations, and maintenance, supporting the shift to sustainable transportation.",
        },
      ],
    },

    industryExpertise: {
      heading: "Our Expertise: Advanced Automotive Software Solutions",
      discription:
        "We specialize in delivering cutting-edge automotive software solutions to optimize fleet management, enhance vehicle performance, and support the growth of automotive businesses.",
      accordionData: [
        {
          id: "collapseOne",
          title: "Connected Vehicle Systems",
          content:
            "Enable real-time communication between vehicles, infrastructure, and devices, improving driving experiences, safety, and operational efficiency.",
          expanded: true,
        },
        {
          id: "collapseTwo",
          title: "Fleet Management Systems",
          content:
            "Optimize fleet operations with robust vehicle tracking, route planning, and maintenance scheduling tools, ensuring smooth and cost-effective management.",
          expanded: false,
        },
        {
          id: "collapseThree",
          title: "Predictive Maintenance Tools",
          content:
            "Minimize downtime and reduce maintenance costs with AI-powered diagnostics, real-time alerts, and predictive analytics for proactive vehicle maintenance.",
          expanded: false,
        },
        {
          id: "collapseFour",
          title: "Telematics Solutions",
          content:
            "Monitor vehicle performance, driver behavior, and fuel efficiency with advanced telematics tools, empowering fleet managers to optimize operations and improve performance.",
          expanded: false,
        },
      ],
    },

    industryFaqs: {
      heading: "Automotive Software Development FAQs",
      discription:
        "Here are answers to some of the most common questions about our automotive software development services:",
      faqData: [
        {
          id: "collapseOne",
          question: "Can you develop software for connected vehicles?",
          answer:
            "Yes, we specialize in creating connected vehicle solutions that include real-time data monitoring, vehicle-to-infrastructure communication, and advanced driver-assistance systems (ADAS).",
        },
        {
          id: "collapseTwo",
          question: "How do you ensure the security of telematics data?",
          answer:
            "We implement robust cybersecurity measures such as end-to-end encryption, secure communication protocols, and compliance with industry standards like ISO/SAE 21434, ensuring secure data exchange.",
        },
        {
          id: "collapseThree",
          question:
            "What is the typical timeline for building automotive software?",
          answer:
            "The timeline depends on the scope of the project. A typical fleet management system or telematics solution can take 3-6 months, while more complex solutions like connected vehicle platforms may require 6-12 months.",
        },
        {
          id: "fourth",
          question: "Can you integrate IoT into automotive solutions?",
          answer:
            "Absolutely! We develop IoT-enabled automotive systems for real-time tracking, predictive maintenance, and vehicle performance analysis.",
        },
        {
          id: "fifth",
          question: "Do you work with electric vehicle (EV) platforms?",
          answer:
            "Yes, we provide solutions tailored for electric vehicles, including charging station integrations, battery monitoring systems, and fleet management for EVs.",
        },
        {
          id: "sixth",
          question: "What types of automotive businesses do you serve?",
          answer:
            "We work with automotive manufacturers, fleet operators, car rental agencies, dealerships, and EV businesses, offering tailored software solutions to meet their needs.",
        },
      ],
    },
  },

  {
    id: 6,
    title: "Education",
    slug: "education",
    content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
    description:
      "Education should be engaging and accessible, and our web apps make it happen. From e-learning platforms to tools for managing classrooms, we design solutions that cater to students, educators, and institutions, bringing everyone closer to success.",
    icon: "/images/Education.png",
    image: "education.png",
    bannerHeading: "Build Education Software",
    bannerBtnText: "Let's Get Started",
    bannerImage: "edtech-detail-banner.png",
    backgroundImage: `url(/images/industry-detial-banner-bg/Education.png)`,

    discriptionSection: {
      heading: "Revolutionizing Education with Digital Solution",
      discription:
        "The education sector is evolving rapidly, with digital tools redefining how knowledge is imparted and absorbed. From e-learning platforms to virtual classrooms, our innovative solutions enable educational institutions and EdTech companies to deliver personalized, engaging, and impactful learning experiences.",

      cardHeading: "Educational Technology Expertise",
      cardDiscripiton:
        "We develop robust Learning Management Systems (LMS) to streamline course management and student progress tracking. Our AI-powered adaptive learning platforms personalize educational content for each student, enhancing their learning experience. Additionally, we provide tools for virtual classrooms, course authoring, and student information systems (SIS), all designed to improve collaboration, content delivery, and data management in educational environments.",
    },

    statsSection: {
      backgroundImage: "edtech-counter-bd.jpg",
      heading: "Lead the Future of",
      greenPart: " Education Technology",
      discription:
        "Driving innovation in education with advanced software solutions for enhanced learning experiences.",
      subheading: "Your Top Education",
      greenPartSubHeading: "Consulting Firm",
      stats: [
        {
          count: "800+",
          text: "Educational Projects Delivered",
        },
        {
          count: "1,000+",
          text: "Schools, Colleges, and Universities Digitally Transformed",
        },
        {
          count: "10M+",
          text: "Students Benefited From Our Platforms",
        },
        {
          count: "96%",
          text: "Customer Satisfaction Rate",
        },
      ],
    },

    industryServices: {
      heading: "Education Software Development Services",
      text: "Revolutionize education with innovative software solutions designed to enhance learning experiences, streamline administration, and empower institutions with cutting-edge technology.",

      industryServicesSlides: [
        {
          id: 1,
          borderClass: "border-1",
          icon: "/images/icon1.svg",
          heading: "Learning Management Systems (LMS)",
          text: "Design user-friendly platforms to manage courses, track progress, and assess performance with advanced LMS solutions tailored for educational institutions.",
          btnLink: "learning-management-systems",
          image: "image.png",
        },
        {
          id: 2,
          borderClass: "border-2",
          icon: "/images/icon2.svg",
          heading: "Virtual Classrooms",
          text: "Create interactive environments for real-time online teaching and collaboration, enhancing the learning experience for students and educators.",
          btnLink: "virtual-classrooms",
          image: "image.png",
        },
        {
          id: 3,
          borderClass: "border-1",
          icon: "/images/machine-learning.png",
          heading: "AI-Powered Learning Tools",
          text: "Enable personalized learning experiences with AI-driven recommendations, progress analysis, and adaptive learning solutions to support diverse student needs.",
          btnLink: "ai-powered-learning-tools",
          image: "image.png",
        },
      ],
    },

    industryAdvertising: {
      heading: "Education Software Standards and Security Compliance",
      discription:
        "We adhere to industry standards and compliance requirements such as FERPA (Family Educational Rights and Privacy Act) and GDPR to ensure data privacy and security for students, educators, and institutions. Our solutions guarantee a secure and reliable learning environment.",
      image: "edtech-add.jpg",
    },

    softwareSolutions: {
      heading: "Explore Our Education Software Solutions",
      discription:
        "Discover innovative and tailored education software solutions designed to streamline course management, enhance learning experiences, and empower educational institutions to drive academic success.",

      healthcareSolutionsData: [
        {
          id: 1,
          heading: "Learning Management Systems (LMS)",
          text: "Simplify course delivery, assessments, and reporting with powerful LMS solutions tailored for educational institutions.",
        },
        {
          id: 2,
          heading: "Student Information Systems (SIS)",
          text: "Manage student data, attendance, and academic records efficiently with a comprehensive SIS solution to streamline school operations.",
        },
        {
          id: 3,
          heading: "E-Learning Platforms",
          text: "Build immersive and scalable platforms for online learning, offering a flexible and engaging educational experience for students globally.",
        },
        {
          id: 4,
          heading: "Exam Management Software",
          text: "Automate exam scheduling, grading, and result tracking, enhancing efficiency and accuracy in academic assessments.",
        },
        {
          id: 5,
          heading: "Corporate Training Solutions",
          text: "Create tailored solutions for professional development, upskilling, and corporate training to boost employee performance and growth.",
        },
      ],
    },

    industryExpertise: {
      heading: "Our Expertise: Innovative Education Software Solutions",
      discription:
        "We deliver advanced software solutions tailored for educational institutions, enhancing student engagement, streamlining administration, and fostering better learning outcomes.",
      accordionData: [
        {
          id: "collapseOne",
          title: "Course Management Systems",
          content:
            "Create intuitive platforms that simplify course delivery, track student progress, and provide detailed assessments to improve teaching effectiveness and learning outcomes.",
          expanded: true,
        },
        {
          id: "collapseTwo",
          title: "Student Data Management Solutions",
          content:
            "Efficiently organize and manage student records, attendance, and academic performance through automated systems that reduce administrative workload and ensure data accuracy.",
          expanded: false,
        },
        {
          id: "collapseThree",
          title: "Online Learning Solutions",
          content:
            "Design interactive and engaging online learning platforms that support remote education, enhance virtual classrooms, and provide rich learning experiences for students.",
          expanded: false,
        },
        {
          id: "collapseFour",
          title: "Assessment and Testing Systems",
          content:
            "Streamline the entire assessment process with automated exam scheduling, grading, and result analysis, ensuring timely and transparent evaluation of student performance.",
          expanded: false,
        },
      ],
    },

    industryFaqs: {
      heading: "Education Software Development FAQs",
      discription:
        "Here are answers to some of the most common questions about our education software development services:",
      faqData: [
        {
          id: "collapseOne",
          question: "Can you build custom LMS platforms?",
          answer:
            "Yes, we specialize in creating tailor-made Learning Management Systems (LMS) with features like course management, analytics, and integrations with third-party tools.",
        },
        {
          id: "collapseTwo",
          question: "Do you provide solutions for virtual classrooms?",
          answer:
            "Absolutely! We develop virtual classroom tools with real-time video, chat, and collaboration features to enhance online teaching experiences.",
        },
        {
          id: "collapseThree",
          question:
            "What is the timeline for developing an e-learning platform?",
          answer:
            "The timeline varies depending on the project scope. Typically, a standard e-learning platform takes 4-6 months, while more advanced features may require 6-9 months.",
        },
        {
          id: "fourth",
          question: "How do you ensure student data privacy?",
          answer:
            "We follow global standards like FERPA and GDPR, incorporating robust encryption, secure authentication, and access controls to protect sensitive data.",
        },
        {
          id: "fifth",
          question: "Do you support mobile learning apps?",
          answer:
            "Yes, we design and develop mobile-friendly e-learning solutions to ensure seamless access to learning resources anytime, anywhere.",
        },
        {
          id: "sixth",
          question:
            "Can you integrate existing educational tools with your solutions?",
          answer:
            "Yes, our solutions support integrations with tools like Google Classroom, Microsoft Teams, and other third-party applications to ensure a cohesive learning experience.",
        },
      ],
    },
  },

  {
    id: 7,
    title: "Insurance",
    slug: "insurance",
    content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
    description:
      "We make insurance processes smooth and hassle-free. Our web apps simplify claims, manage policies, and improve communication with customers. These secure and efficient solutions are built to adapt to the ever-changing needs of the insurance sector.",
    icon: "/images/Insurance.png",
    image: "insurance.png",
    bannerHeading: "Build Insurance Software",
    bannerBtnText: "Let's Get Started",
    bannerImage: "insurance-detail-banner.png",
    backgroundImage: `url(/images/industry-detial-banner-bg/Insurance.png)`,

    discriptionSection: {
      heading: "Transforming Insurance Through Technology",
      discription:
        "The insurance industry is experiencing a digital revolution, where technology is enabling personalized customer experiences, faster claims processing, and efficient policy management. Our cutting-edge solutions empower insurance providers to streamline operations, enhance decision-making, and deliver value to their customers.",

      cardHeading: "Insurance Technology Expertise",
      cardDiscripiton:
        "Streamline your insurance operations with our Claims Management Systems, Underwriting Automation, and AI-powered Predictive Analytics. Enhance customer relationships with tailored CRM solutions and simplify policy management with seamless Policy Administration.",
    },

    statsSection: {
      backgroundImage: "insurance-counter-bg.jpg",
      heading: "Lead the Future of ",
      greenPart: " Digital Insurance",
      discription:
        "Driving innovation in Digital Insurance with advanced software solutions for enhanced learning experiences.",
      subheading: "Your Top Insurance",
      greenPartSubHeading: "Consulting Firm",
      stats: [
        {
          count: "500+",
          text: "Insurance Software Solutions Delivered",
        },
        {
          count: "200+",
          text: "Insurance Companies Digitally Empowered",
        },
        {
          count: "10M+",
          text: "Policies Managed Through Our Platforms",
        },
        {
          count: "95%",
          text: "Customer Retention Rate",
        },
      ],
    },

    industryServices: {
      heading: "Insurance Software Development Services",
      text: "Transform your insurance business with innovative software solutions designed to streamline claims management, automate underwriting, and enhance customer relationships with advanced technology.",

      industryServicesSlides: [
        {
          id: 1,
          borderClass: "border-1",
          icon: "/images/icon1.svg",
          heading: "Claims Management Systems",
          text: "Simplify and automate the claims lifecycle for faster resolution and transparency, improving efficiency and customer satisfaction.",
          btnLink: "claims-management-systems",
          image: "image.png",
        },
        {
          id: 2,
          borderClass: "border-2",
          icon: "/images/icon2.svg",
          heading: "Underwriting Solutions",
          text: "Leverage AI and machine learning for accurate risk assessment and underwriting, enhancing decision-making and operational efficiency.",
          btnLink: "underwriting-solutions",
          image: "image.png",
        },
        {
          id: 3,
          borderClass: "border-1",
          icon: "/images/machine-learning.png",
          heading: "Policy Administration Software",
          text: "Streamline the management of insurance policies from issuance to renewal, ensuring seamless operations and customer satisfaction.",
          btnLink: "policy-administration-software",
          image: "image.png",
        },
      ],
    },

    industryAdvertising: {
      heading: "Insurance Software Standards and Compliance",
      discription:
        "We adhere to regulatory standards like GDPR, HIPAA (for health insurance), and PCI DSS (for payment security) to ensure compliance, security, and data privacy in all our solutions. Our platforms are built to handle sensitive information securely and efficiently.",
      image: "insurance-add.jpg",
    },

    softwareSolutions: {
      heading: "Explore Our Insurance Software Solutions",
      discription:
        "Discover innovative and tailored insurance software solutions designed to streamline claims management, enhance underwriting accuracy, and optimize policy administration for improved business performance.",

      healthcareSolutionsData: [
        {
          id: 1,
          heading: "Claims Management Software",
          text: "Enable quick and hassle-free claims processing, reducing turnaround time and enhancing customer satisfaction.",
        },
        {
          id: 2,
          heading: "Customer Relationship Management (CRM)",
          text: "Optimize customer interactions, enhance retention, and drive loyalty with a comprehensive CRM solution for insurers.",
        },
        {
          id: 3,
          heading: "Policy Administration Platforms",
          text: "Efficiently manage policies, renewals, and endorsements, ensuring streamlined operations and enhanced customer service.",
        },
        {
          id: 4,
          heading: "Risk Assessment Tools",
          text: "Leverage AI-driven insights for accurate risk evaluations and better underwriting decisions in the insurance process.",
        },
        {
          id: 5,
          heading: "InsurTech Platforms",
          text: "Develop next-generation digital solutions for innovative insurance services, transforming the industry with advanced technology.",
        },
      ],
    },

    industryExpertise: {
      heading: "Our Expertise: Smart & Simple Insurance Software Solutions",
      discription:
        "We create easy-to-use insurance software that helps manage policies, speed up claims, improve customer service, and make daily tasks hassle-free for insurers and agents.",
      accordionData: [
        {
          id: "collapseOne",
          title: "Policy Management Systems",
          content:
            "Easily handle all your insurance policies in one place, from creating new ones to updating old ones. No more messy paperwork, just smooth sailing!",
          expanded: true,
        },
        {
          id: "collapseTwo",
          title: "Claims Processing Solutions",
          content:
            "When things go wrong, quick claims matter. This system helps speed up approvals, track progress, and get payouts without the headache.",
          expanded: false,
        },
        {
          id: "collapseThree",
          title: "Customer Relationship Management (CRM) for Insurance",
          content:
            "Keeping customers happy is key! Manage contacts, track conversations, and send reminders so no one feels forgotten.",
          expanded: false,
        },
        {
          id: "collapseFour",
          title: "Risk Assessment & Underwriting Tools",
          content:
            "Know the risks before saying yes! These tools help analyze customer details and decide the best coverage with less guesswork.",
          expanded: false,
        },
      ],
    },

    industryFaqs: {
      heading: "Insurance Software Development FAQs",
      discription:
        "Here are answers to some of the most common questions about our insurance software development services:",
      faqData: [
        {
          id: "collapseOne",
          question: "Can you develop custom insurance platforms?",
          answer:
            "Yes, we specialize in creating tailor-made solutions for claims management, policy administration, and customer relationship management to meet your unique business needs.",
        },
        {
          id: "collapseTwo",
          question: "Do you provide AI solutions for claims processing?",
          answer:
            "Absolutely! We integrate AI into claims management systems to automate fraud detection, expedite approvals, and ensure accuracy in processing.",
        },
        {
          id: "collapseThree",
          question: "How long does it take to build insurance software?",
          answer:
            "The timeline depends on the complexity of the project. Standard solutions take 3-6 months, while more advanced or custom platforms may take 6-12 months.",
        },
        {
          id: "fourth",
          question: "How do you ensure compliance with industry regulations?",
          answer:
            "We follow global standards such as GDPR, HIPAA, and PCI DSS to ensure compliance and data security across all our solutions.",
        },
        {
          id: "fifth",
          question:
            "Can your platforms integrate with third-party insurance tools?",
          answer:
            "Yes, we ensure seamless integration with third-party tools like underwriting engines, payment gateways, and analytics platforms to deliver end-to-end functionality.",
        },
        {
          id: "sixth",
          question: "Do you offer mobile-friendly insurance solutions?",
          answer:
            "Yes, our solutions are fully responsive and mobile-friendly, enabling customers to access services like policy management and claims filing on the go.",
        },
      ],
    },
  },

  {
    id: 8,
    title: "Logistics",
    slug: "logistics",
    content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
    description:
      "Efficiency is the backbone of logistics, and we’re here to deliver it. Our web apps streamline supply chains with real-time tracking, fleet management, and inventory control. Businesses stay organized and customers stay informed, every step of the way.",
    icon: "/images/Logistics.png",
    image: "logistics.png",
    bannerHeading: "Build Logistics Software",
    bannerBtnText: "Let's Get Started",
    bannerImage: "logistics-transportation-detail-banner.png",
    backgroundImage: `url(/images/industry-detial-banner-bg/Logistics.png)`,

    discriptionSection: {
      heading: "Revolutionizing Logistics with Technology",
      discription:
        "The logistics industry is evolving rapidly, driven by the need for efficiency, transparency, and scalability. Our innovative logistics software solutions empower businesses to optimize supply chains, manage fleets effectively, and deliver superior customer experiences. With real-time tracking and advanced analytics, we help you achieve seamless operations and cost efficiency.",

      cardHeading: "Logistics Technology Expertise",
      cardDiscripiton:
        "Optimize fleet management, streamline supply chains, and enhance warehouse efficiency with cutting-edge solutions. Real-time tracking, smart route planning, and seamless visibility boost operations and accelerate deliveries. Maximize productivity and improve order fulfillment with powerful tools designed to keep everything running smoothly.",
    },

    statsSection: {
      backgroundImage: "logistic-counter-bg.png",
      heading: "Lead the Future of",
      greenPart: "Smart Logistics",
      discription:
        "Driving innovation in Smart Logistics with advanced software solutions for enhanced learning experiences.",
      subheading: "Your Top Logistics",
      greenPartSubHeading: "Consulting Firm",
      stats: [
        {
          count: "700+",
          text: "Logistics Solutions Delivered",
        },
        {
          count: "300+",
          text: "Companies Served Globally",
        },
        {
          count: "99%",
          text: "On-Time Delivery Improvement",
        },
        {
          count: "95%",
          text: "Customer Satisfaction Rate",
        },
      ],
    },

    industryServices: {
      heading: "Logistics Software Development Services",
      text: "Revolutionize your logistics operations with cutting-edge software solutions designed to optimize fleet management, streamline supply chains, and enhance real-time tracking for seamless operations.",

      industryServicesSlides: [
        {
          id: 1,
          borderClass: "border-1",
          icon: "/images/icon1.svg",
          heading: "Fleet Management Systems",
          text: "Enable efficient tracking, monitoring, and maintenance of your fleet, ensuring smooth operations and reducing downtime.",
          btnLink: "fleet-management-systems",
          image: "image.png",
        },
        {
          id: 2,
          borderClass: "border-2",
          icon: "/images/icon2.svg",
          heading: "Supply Chain Optimization",
          text: "Automate and streamline supply chain processes to maximize efficiency, reduce costs, and enhance delivery performance.",
          btnLink: "supply-chain-optimization",
          image: "image.png",
        },
        {
          id: 3,
          borderClass: "border-1",
          icon: "/images/machine-learning.png",
          heading: "Warehouse Management Solutions",
          text: "Simplify inventory tracking, picking, and shipping processes, improving accuracy and reducing operational overhead.",
          btnLink: "warehouse-management-solutions",
          image: "image.png",
        },
      ],
    },

    industryAdvertising: {
      heading: "Logistics Software Standards and Compliance",
      discription:
        "Our solutions comply with international standards like ISO 9001 for quality management and GDPR for data privacy. We ensure adherence to industry-specific regulations, allowing logistics companies to operate securely and efficiently in a global marketplace.",
      image: "logistic-add.jpg",
    },

    softwareSolutions: {
      heading: "Explore Our Logistics Software Solutions",
      discription:
        "Discover innovative and tailored logistics software solutions designed to provide real-time tracking, optimize route planning, streamline warehouse management, and improve supply chain efficiency for better business outcomes.",

      healthcareSolutionsData: [
        {
          id: 1,
          heading: "Real-Time Tracking Platforms",
          text: "Provide transparency and updates on shipments in transit, ensuring accurate and timely tracking for better customer satisfaction.",
        },
        {
          id: 2,
          heading: "Route Optimization Tools",
          text: "Reduce fuel costs and improve delivery times with AI-driven route planning, maximizing efficiency in transportation operations.",
        },
        {
          id: 3,
          heading: "Warehouse Management Systems",
          text: "Improve storage, inventory management, and order fulfillment processes, ensuring smooth operations and timely deliveries.",
        },
        {
          id: 4,
          heading: "Freight Management Solutions",
          text: "Simplify freight forwarding, scheduling, and cost estimation, optimizing logistics workflows and reducing operational costs.",
        },
        {
          id: 5,
          heading: "Supply Chain Analytics",
          text: "Leverage data to identify inefficiencies, streamline processes, and improve decision-making for enhanced supply chain performance.",
        },
      ],
    },

    industryExpertise: {
      heading: "Our Expertise: Innovative Logistics Software Solutions",
      discription:
        "We specialize in delivering cutting-edge logistics software solutions that optimize fleet management, enhance real-time tracking, improve route planning, and streamline supply chain processes for better efficiency and performance.",
      accordionData: [
        {
          id: "collapseOne",
          title: "Fleet Management Systems",
          content:
            "Enable efficient tracking, monitoring, and maintenance of your fleet with real-time data, ensuring reduced operational costs and improved fleet performance.",
          expanded: true,
        },
        {
          id: "collapseTwo",
          title: "Supply Chain Optimization",
          content:
            "Automate and streamline supply chain processes to enhance efficiency, reduce bottlenecks, and improve decision-making through advanced analytics and AI-driven insights.",
          expanded: false,
        },
        {
          id: "collapseThree",
          title: "Real-Time Tracking and Visibility",
          content:
            "Provide end-to-end visibility of shipments in transit, offering real-time updates to customers and enhancing transparency throughout the delivery process.",
          expanded: false,
        },
        {
          id: "collapseFour",
          title: "Route Planning and Optimization",
          content:
            "Leverage AI and machine learning algorithms to optimize delivery routes, reduce fuel costs, improve delivery times, and ensure the most efficient transportation strategy.",
          expanded: false,
        },
      ],
    },

    industryFaqs: {
      heading: "Logistics Software Development FAQs",
      discription:
        "Here are answers to some of the most common questions about our logistics software development services:",
      faqData: [
        {
          id: "collapseOne",
          question:
            "Can you integrate real-time tracking into our logistics platform?",
          answer:
            "Yes, we specialize in integrating GPS-based real-time tracking systems into your logistics platform for enhanced shipment visibility and transparency.",
        },
        {
          id: "collapseTwo",
          question: "How do your solutions optimize supply chains?",
          answer:
            "Our solutions leverage advanced analytics, AI, and machine learning to identify inefficiencies, reduce costs, and improve process flow across the supply chain.",
        },
        {
          id: "collapseThree",
          question: "Do you offer custom fleet management systems?",
          answer:
            "Absolutely! We design fleet management systems tailored to your operational needs, including tracking, maintenance scheduling, and performance analytics.",
        },
        {
          id: "fourth",
          question:
            "What industries can benefit from your logistics solutions?",
          answer:
            "Our solutions cater to industries like retail, eCommerce, manufacturing, and distribution, ensuring efficient logistics operations for all sectors.",
        },
        {
          id: "fifth",
          question: "How long does it take to develop logistics software?",
          answer:
            "Depending on complexity, development timelines range from 3-6 months for standard solutions to 6-12 months for custom systems.",
        },
        {
          id: "sixth",
          question:
            "Do your platforms support multi-language and multi-currency features?",
          answer:
            "Yes, our platforms are built to support multi-language interfaces and multi-currency transactions for global operations.",
        },
      ],
    },
  },

  {
    id: 9,
    title: "Entertainment",
    slug: "entertainment",
    content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
    description:
      "Our entertainment solutions include content management systems, audience analytics platforms, and IP protection tools, optimizing content distribution and engagement.",
    icon: "/images/Entertainment.png",
    image: "entertainmentMain.png",
    bannerHeading: "Build Entertainment Software",
    bannerBtnText: "Let's Get Started",
    bannerImage: "travel-entertainment-detail-banner.png",
    backgroundImage: `url(/images/industry-detial-banner-bg/Entertainment.png)`,

    discriptionSection: {
      heading: "Shaping the Future",
      discription:
        "of Entertainment, The entertainment industry thrives on creativity and innovation, requiring robust technology to deliver immersive experiences. Our entertainment software solutions empower media companies, streaming platforms, event organizers, and content creators to captivate audiences, monetize content effectively, and stay ahead in a competitive market.",

      cardHeading: "Entertainment Technology Expertise",
      cardDiscripiton:
        "Elevate your entertainment and event management with our cutting-edge solutions. We offer seamless video streaming platforms, efficient event management systems, and robust content management tools. Our AR/VR integration enhances engagement, while advanced ticketing and audience analytics provide valuable insights to optimize your events.",
    },

    statsSection: {
      backgroundImage: "entertainment-counter-bg.jpg",
      heading: "Lead the Future of",
      greenPart: "Digital Entertainment",
      discription:
        "Driving innovation in Smart Entertainment with advanced software solutions for enhanced learning experiences.",
      subheading: "Your Top Entertainment",
      greenPartSubHeading: "Consulting Firm",
      stats: [
        {
          count: "800+",
          text: "Entertainment Projects Delivered",
        },
        {
          count: "500+",
          text: "Companies Served Globally",
        },
        {
          count: "98%",
          text: "Engagement Rate Improvement",
        },
        {
          count: "96%",
          text: "Customer Satisfaction Rate",
        },
      ],
    },

    industryServices: {
      heading: "Entertainment Software Development Services",
      text: "Transform your entertainment business with innovative software solutions designed to enhance video streaming, streamline event management, and provide immersive AR/VR experiences for engaging audiences.",

      industryServicesSlides: [
        {
          id: 1,
          borderClass: "border-1",
          icon: "/images/icon1.svg",
          heading: "Video Streaming Platforms",
          text: "Build scalable platforms for seamless live or on-demand video streaming, ensuring high-quality user experiences.",
          btnLink: "video-streaming-platforms",
          image: "image.png",
        },
        {
          id: 2,
          borderClass: "border-2",
          icon: "/images/icon2.svg",
          heading: "Event Management Solutions",
          text: "Manage concerts, festivals, and conferences with efficient ticketing, audience tools, and real-time analytics.",
          btnLink: "event-management-solutions",
          image: "image.png",
        },
        {
          id: 3,
          borderClass: "border-1",
          icon: "/images/arvr-icon.png",
          heading: "AR/VR Experiences",
          text: "Integrate augmented and virtual reality for immersive entertainment experiences that engage and captivate audiences.",
          btnLink: "arvr-experiences",
          image: "image.png",
        },
      ],
    },

    industryAdvertising: {
      heading: "Entertainment Software Standards and Compliance",
      discription:
        "Our solutions align with global media standards like DASH for adaptive streaming and adhere to copyright protection regulations like DMCA. We prioritize secure data handling to protect user privacy and content integrity.",
      image: "entertainment-add.jpg",
    },

    softwareSolutions: {
      heading: "Explore Our Entertainment Software Solutions",
      discription:
        "Discover innovative and tailored entertainment software solutions designed to deliver seamless video streaming, optimize event management, and integrate immersive AR/VR experiences for enhanced audience engagement and business success.",

      healthcareSolutionsData: [
        {
          id: 1,
          heading: "Streaming Platforms",
          text: "Create personalized and interactive experiences for your audience, offering seamless live and on-demand content delivery.",
        },
        {
          id: 2,
          heading: "Event Ticketing Systems",
          text: "Simplify online ticket booking, seat allocation, and payments to streamline event management and enhance customer experience.",
        },
        {
          id: 3,
          heading: "Audience Analytics Tools",
          text: "Leverage data to understand audience preferences and trends, optimizing content strategies and increasing engagement.",
        },
        {
          id: 4,
          heading: "Content Distribution Networks",
          text: "Ensure fast and reliable content delivery worldwide, enhancing user experience and ensuring high-quality streaming.",
        },
        {
          id: 5,
          heading: "AR/VR Integration",
          text: "Enhance storytelling and engagement through immersive technologies, creating unforgettable experiences for your audience.",
        },
      ],
    },

    industryExpertise: {
      heading: "Our Expertise: Innovative Entertainment Software Solutions",
      discription:
        "We specialize in delivering cutting-edge entertainment software solutions that power streaming platforms, streamline event management, integrate immersive AR/VR experiences, and enhance audience engagement for better performance and satisfaction.",
      accordionData: [
        {
          id: "collapseOne",
          title: "Streaming Platforms",
          content:
            "Create seamless, scalable platforms for live and on-demand video streaming, offering personalized experiences and high-quality content delivery for global audiences.",
          expanded: true,
        },
        {
          id: "collapseTwo",
          title: "Event Management Solutions",
          content:
            "Efficiently manage events from ticketing and seat allocation to audience engagement, simplifying logistics and enhancing customer experiences for concerts, festivals, and conferences.",
          expanded: false,
        },
        {
          id: "collapseThree",
          title: "Audience Analytics Tools",
          content:
            "Leverage data to understand audience preferences, enhance content strategies, and improve engagement by analyzing viewership trends and behavior.",
          expanded: false,
        },
        {
          id: "collapseFour",
          title: "AR/VR Experiences",
          content:
            "Integrate immersive AR/VR technologies to create unique and interactive entertainment experiences, revolutionizing storytelling and audience engagement.",
          expanded: false,
        },
      ],
    },

    industryFaqs: {
      heading: "Entertainment Software Development FAQs",
      discription:
        "Here are answers to some of the most common questions about our entertainment software development services:",
      faqData: [
        {
          id: "collapseOne",
          question: "Can you help build a scalable video streaming platform?",
          answer:
            "Yes, we specialize in creating robust and scalable platforms for live and on-demand video streaming with advanced features like content recommendation and adaptive bitrate streaming.",
        },
        {
          id: "collapseTwo",
          question: "How do you integrate AR/VR into entertainment software?",
          answer:
            "We design and integrate AR/VR technologies to create immersive experiences for gaming, live events, and virtual tours, enhancing user engagement.",
        },
        {
          id: "collapseThree",
          question: "Do you offer audience analytics tools?",
          answer:
            "Absolutely! Our audience analytics tools help track user behavior, preferences, and engagement, enabling data-driven decisions to boost your entertainment platform's performance.",
        },
        {
          id: "fourth",
          question: "What kind of event management solutions do you provide?",
          answer:
            "We provide end-to-end event management solutions, including ticket booking systems, attendee tracking, and post-event analytics.",
        },
        {
          id: "fifth",
          question: "Do your platforms support multi-device compatibility?",
          answer:
            "Yes, our solutions are optimized for mobile, desktop, and smart TVs, ensuring a seamless experience across all devices.",
        },
        {
          id: "sixth",
          question: "How do you protect digital content from piracy?",
          answer:
            "We implement DRM (Digital Rights Management) and watermarking technologies to safeguard your content and prevent unauthorized access or distribution.",
        },
      ],
    },
  },

  {
    id: 10,
    title: "Gaming",
    slug: "gaming",
    content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
    description:
      "We support the gaming industry with custom game development, in-game economy platforms, and player analytics tools, creating immersive and secure gaming experiences.",
    icon: "/images/Gaming.png",
    image: "game.png",
    bannerHeading: "Build Gaming Software",
    bannerBtnText: "Let's Get Started",
    bannerImage: "gaming-detail-banner.png",
    backgroundImage: `url(/images/industry-detial-banner-bg/Gaming.png)`,

    discriptionSection: {
      heading: "Revolutionize the",
      discription:
        "Gaming Industry, The gaming industry is driven by immersive experiences and cutting-edge technologies. At Bitsclan, we create gaming software solutions that blend creativity with technology, empowering game developers, studios, and publishers to captivate players and redefine gaming landscapes. From mobile games to cloud-based gaming platforms, we deliver innovation that sets your games apart.",

      cardHeading: "Gaming Software Expertise",
      cardDiscripiton:
        "Develop immersive cross-platform games with AR/VR experiences, integrate blockchain for secure gameplay, offer cloud gaming solutions for seamless access, and build real-time multiplayer systems for interactive and engaging player experiences.",
    },

    statsSection: {
      backgroundImage: "game-counter-bg.png",
      heading: "Lead the Future of",
      greenPart: "Digital Gaming",
      discription:
        "Driving innovation in Smart Gaming with advanced software solutions for enhanced learning experiences.",
      subheading: "Your Top Gaming",
      greenPartSubHeading: "Consulting Firm",
      stats: [
        {
          count: "1,200+",
          text: "Games Supported by Our Solutions",
        },
        {
          count: "700+",
          text: "Global Gaming Companies Served",
        },
        {
          count: "98%",
          text: "Increase in Player Engagement",
        },
        {
          count: "95%",
          text: "Client Satisfaction Rate",
        },
      ],
    },

    industryServices: {
      heading: "Gaming Software Development Services",
      text: "Revolutionize your entertainment business with cutting-edge software solutions that enhance video streaming, optimize event management, and deliver immersive AR/VR experiences for deeper audience engagement.",

      industryServicesSlides: [
        {
          id: 1,
          borderClass: "border-1",
          icon: "/images/icon1.svg",
          heading: "Cross-Platform Game Development",
          text: "Build games that provide seamless experiences across mobile, console, and PC platforms.",
          btnLink: "cross-platform-game-development",
          image: "image.png",
        },
        {
          id: 2,
          borderClass: "border-2",
          icon: "/images/icon2.svg",
          heading: "AR/VR Game Development",
          text: "Create immersive gaming worlds using augmented and virtual reality to engage players.",
          btnLink: "arvr-game-development",
          image: "image.png",
        },
        {
          id: 3,
          borderClass: "border-1",
          icon: "/images/arvr-icon.png",
          heading: "Cloud Gaming Solutions",
          text: "Deliver high-performance gaming experiences via cloud technology for seamless play anywhere.",
          btnLink: "cloud-gaming-solutions",
          image: "image.png",
        },
        {
          id: 4,
          borderClass: "border-2",
          icon: "/images/blockchain-icon.png",
          heading: "Blockchain Gaming",
          text: "Leverage blockchain technology to develop secure, decentralized gaming ecosystems with true ownership.",
          btnLink: "blockchain-gaming",
          image: "image.png",
        },
      ],
    },

    industryAdvertising: {
      heading: " Gaming Software Standards and Compliance",
      discription:
        "Our gaming solutions adhere to industry standards such as OpenGL, DirectX, and Vulkan, ensuring high-quality performance. We also ensure compliance with data privacy regulations like GDPR and implement anti-cheat mechanisms to maintain fair gameplay.",
      image: "game-add.jpg",
    },

    softwareSolutions: {
      heading: "Explore Our Gaming Software Solutions",
      discription:
        "Discover innovative and tailored entertainment software solutions designed to deliver seamless video streaming, optimize event management, and integrate immersive AR/VR experiences for enhanced audience engagement and business success.",

      healthcareSolutionsData: [
        {
          id: 1,
          heading: "Game Engines Integration",
          text: "Seamlessly integrate with Unity, Unreal Engine, or custom-built engines to create high-quality gaming experiences.",
        },
        {
          id: 2,
          heading: "AR/VR Gaming",
          text: "Build immersive and interactive gaming worlds with augmented and virtual reality for a next-level player experience.",
        },
        {
          id: 3,
          heading: "Multiplayer Gaming Solutions",
          text: "Design real-time multiplayer functionality with minimal latency, enabling players to connect and compete globally.",
        },
        {
          id: 4,
          heading: "Cloud Gaming Platforms",
          text: "Provide scalable and high-performance gaming experiences through cloud technology, reducing hardware dependency.",
        },
        {
          id: 5,
          heading: "In-Game Monetization Tools",
          text: "Incorporate secure payment systems and in-game purchases to generate revenue and enhance the gaming experience.",
        },
      ],
    },

    industryExpertise: {
      heading: "Our Expertise: Innovative Gaming Software Solutions",
      discription:
        "We specialize in delivering cutting-edge gaming software solutions that power cross-platform game development, enhance multiplayer experiences, integrate immersive AR/VR technologies, and provide cloud-based gaming for global players.",
      accordionData: [
        {
          id: "collapseOne",
          title: "Cross-Platform Game Development",
          content:
            "Build seamless and scalable games that work across mobile, console, and PC platforms, ensuring a unified experience for all players.",
          expanded: true,
        },
        {
          id: "collapseTwo",
          title: "AR/VR Gaming",
          content:
            "Develop immersive gaming worlds using augmented and virtual reality technologies, enhancing player interaction and engagement in dynamic environments.",
          expanded: false,
        },
        {
          id: "collapseThree",
          title: "Real-Time Multiplayer Solutions",
          content:
            "Create real-time multiplayer functionality with minimal latency, enabling seamless online gameplay and competitive features across regions.",
          expanded: false,
        },
        {
          id: "collapseFour",
          title: "Cloud Gaming Platforms",
          content:
            "Deliver high-performance, scalable gaming experiences via cloud technology, reducing hardware dependency and enabling gaming on demand from anywhere.",
          expanded: false,
        },
      ],
    },

    industryFaqs: {
      heading: "Gaming Software Development FAQs",
      discription:
        "Here are answers to some of the most common questions about our gaming software development services:",
      faqData: [
        {
          id: "collapseOne",
          question: "Can you develop cross-platform games?",
          answer:
            "Yes, we specialize in building cross-platform games that deliver seamless experiences on mobile, PC, and consoles.",
        },
        {
          id: "collapseTwo",
          question:
            "How do you ensure gaming solutions are scalable for large user bases?",
          answer:
            "We use cloud technologies and scalable architectures to support millions of concurrent players without performance issues.",
        },
        {
          id: "collapseThree",
          question: "Do you integrate AR/VR technologies into games?",
          answer:
            "Absolutely! Our team designs and develops AR/VR-based gaming solutions to provide players with immersive and interactive experiences.",
        },
        {
          id: "fourth",
          question:
            "What monetization tools do you offer for gaming platforms?",
          answer:
            "We develop in-game monetization systems, including microtransactions, subscription models, and advertising integrations.",
        },
        {
          id: "fifth",
          question: "Can you assist with multiplayer gaming development?",
          answer:
            "Yes, we build real-time multiplayer systems with features like matchmaking, leaderboards, and low-latency connections.",
        },
        {
          id: "sixth",
          question: "Do you provide gaming analytics tools?",
          answer:
            "We offer tools to track player behavior, engagement, and in-game metrics, helping developers make data-driven decisions.",
        },
      ],
    },
  },

  {
    id: 11,
    title: "Social Networking",
    slug: "social-networking",
    content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
    description:
      "We provide social platforms with user engagement analytics, content moderation tools, and secure data management systems, enhancing user interaction and privacy.",
    icon: "/images/SocialNetworking.png",
    image: "Social.png",
    bannerHeading: "Build Innovative Social Networking Platforms",
    bannerBtnText: "Let's Get Started",
    bannerImage: "social-networking-detail-banner.png",
    backgroundImage: `url(/images/industry-detial-banner-bg/SocialNetworking.png)`,

    discriptionSection: {
      heading: "Empower Communities ",
      discription:
        "and Connections,  Social networking is at the heart of how we interact today, driving conversations, content sharing, and collaborations. At Bitsclan, we specialize in building robust social platforms that foster real-time communication, community engagement, and seamless content sharing. Whether it’s creating a new social platform or enhancing an existing one, we deliver innovative technology solutions that enable users to connect and interact in meaningful ways.",
      cardHeading: "Social Networking Software Expertise",
      cardDiscripiton:
        "Our entertainment software solutions empower community building with advanced tools for user engagement and interaction. We offer seamless content sharing, social media insights, and secure messaging features to enhance communication and drive audience interaction. These solutions create a dynamic, connected environment that fosters loyalty and boosts engagement across your platform.",
    },

    statsSection: {
      backgroundImage: "social-network-counter-add.png",
      heading: "Lead the Future of",
      greenPart: "Social Networking",
      discription:
        "Driving innovation in Social Networking with advanced software solutions for enhanced learning experiences.",
      subheading: "Your Top Social Networking",
      greenPartSubHeading: "Consulting Firm",
      stats: [
        {
          count: "1,000+",
          text: "Social Platforms Built",
        },
        {
          count: "500+",
          text: "Global Social Media Solutions Delivered",
        },
        {
          count: "97%",
          text: "Increased User Engagement",
        },
        {
          count: "98%",
          text: "Customer Satisfaction Rate",
        },
      ],
    },

    industryServices: {
      heading: "Social Networking Software Development Services",
      text: "Transform your entertainment business with innovative software solutions that elevate video streaming, streamline event management, and integrate immersive AR/VR experiences, driving deeper audience engagement and satisfaction.",

      industryServicesSlides: [
        {
          id: 1,
          borderClass: "border-1",
          icon: "/images/icon1.svg",
          heading: "Community Building Solutions",
          text: "Create robust features for building and managing online communities, enhancing engagement and interaction.",
          btnLink: "community-building-solutions",
          image: "image.png",
        },
        {
          id: 2,
          borderClass: "border-2",
          icon: "/images/icon2.svg",
          heading: "Real-Time Messaging Solutions",
          text: "Develop secure, interactive communication tools to foster seamless and engaging user interactions.",
          btnLink: "real-time-messaging-solutions",
          image: "image.png",
        },
        {
          id: 3,
          borderClass: "border-1",
          icon: "/images/arvr-icon.png",
          heading: "Content Sharing Platforms",
          text: "Build platforms enabling users to seamlessly share videos, images, and other content, fostering creativity and connection.",
          btnLink: "content-sharing-platforms",
          image: "image.png",
        },
        {
          id: 4,
          borderClass: "border-2",
          icon: "/images/blockchain-icon.png",
          heading: "Social Media Analytics Tools",
          text: "Integrate real-time analytics to track user engagement and interactions, optimizing content strategies.",
          btnLink: "social-media-analytics-tools",
          image: "image.png",
        },
      ],
    },

    industryAdvertising: {
      heading: "Social Networking Software Standards and Compliance",
      discription:
        " Our social networking solutions comply with global data protection regulations such as GDPR and CCPA, ensuring that user privacy is respected. We also prioritize accessibility and follow web standards to ensure that our platforms are user-friendly and inclusive.",
      image: "social-network-add.jpg",
    },

    softwareSolutions: {
      heading: "Explore Our Social Networking Software Solutions",
      discription:
        "Discover innovative and tailored entertainment software solutions designed to deliver seamless video streaming, optimize event management, and integrate immersive AR/VR experiences for enhanced audience engagement and business success.",

      healthcareSolutionsData: [
        {
          id: 1,
          heading: "Community Engagement Tools",
          text: "Build and manage vibrant communities with features like forums, groups, and user interactions to foster engagement.",
        },
        {
          id: 2,
          heading: "Secure Messaging Platforms",
          text: "Develop real-time, encrypted messaging solutions for secure communication, ensuring user privacy and safety.",
        },
        {
          id: 3,
          heading: "Social Media Integration",
          text: "Enable users to connect their accounts across various social platforms, facilitating content sharing and enhancing social interaction.",
        },
        {
          id: 4,
          heading: "User-Generated Content Systems",
          text: "Empower users to share content through simple and effective submission tools, promoting creativity and community participation.",
        },
        {
          id: 5,
          heading: "Advanced Social Analytics",
          text: "Track interactions, likes, shares, and other key metrics to optimize user engagement and content strategies.",
        },
      ],
    },

    industryExpertise: {
      heading: "Our Expertise: Innovative Social Networking Software Solutions",
      discription:
        "We specialize in delivering advanced social networking software solutions that empower community building, real-time messaging, content sharing, and social media integration for seamless user engagement and interaction.",
      accordionData: [
        {
          id: "collapseOne",
          title: "Community Engagement Tools",
          content:
            "Build and manage vibrant online communities with features like forums, groups, and user interactions to foster engagement and create a loyal user base.",
          expanded: true,
        },
        {
          id: "collapseTwo",
          title: "Real-Time Messaging Solutions",
          content:
            "Develop secure, real-time messaging platforms with encrypted communication tools for enhanced user privacy and interaction.",
          expanded: false,
        },
        {
          id: "collapseThree",
          title: "Social Media Integration",
          content:
            "Enable users to connect their accounts across various social platforms, allowing for seamless content sharing and better social interaction.",
          expanded: false,
        },
        {
          id: "collapseFour",
          title: "User-Generated Content Systems",
          content:
            "Empower users to create, share, and contribute content through simple tools, fostering creativity and community-driven platforms.",
          expanded: false,
        },
      ],
    },

    industryFaqs: {
      heading: "Social Networking Software Development FAQs",
      discription:
        "Here are answers to some of the most common questions about our social networking software development services:",
      faqData: [
        {
          id: "collapseOne",
          question: "Can you develop platforms for community building?",
          answer:
            "Yes, we specialize in developing platforms that allow users to create and manage online communities, complete with group chats, forums, and user-generated content features.",
        },
        {
          id: "collapseTwo",
          question:
            "Do you offer messaging features for social networking apps?",
          answer:
            "Absolutely! We design secure, real-time messaging systems that allow users to chat privately, in groups, or with media sharing capabilities.",
        },
        {
          id: "collapseThree",
          question:
            "How do you integrate social media analytics tools into platforms?",
          answer:
            "We provide powerful analytics tools that track user interactions, content engagement, and social metrics, allowing you to gain insights into your platform’s performance.",
        },
        {
          id: "fourth",
          question:
            "What kind of features can you implement for user-generated content?",
          answer:
            "We integrate tools for users to create, upload, and share content like photos, videos, and articles, making it easy to manage and display user submissions.",
        },
        {
          id: "fifth",
          question: "Do you support third-party social media integrations?",
          answer:
            "Yes, we enable social media integrations so users can link their accounts and share content across different platforms, improving engagement and visibility.",
        },
        {
          id: "sixth",
          question: "Can you help with user privacy and data protection?",
          answer:
            "Yes, we comply with the latest data protection laws such as GDPR and provide features like data encryption and consent management to protect your users’ privacy.",
        },
      ],
    },
  },

  {
    id: 12,
    title: "Telecom",
    slug: "telecom",
    content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
    description:
      "Our telecom solutions include billing management systems, network optimization tools, and customer relationship platforms, ensuring efficient operations and customer satisfaction.",
    icon: "/images/Telecom.png",
    image: "telecom.png",
    bannerHeading: "Transform Telecom Experiences with Cutting-Edge Solutions",
    bannerBtnText: "Let's Get Started",
    bannerImage: "telecom-detail-banner.png",
    backgroundImage: `url(/images/industry-detial-banner-bg/Telecom.png)`,

    discriptionSection: {
      heading: "Revolutionizing the ",
      discription:
        "Telecom Industry, The telecom industry is the backbone of global communication, connecting billions of people worldwide. At Bitsclan, we specialize in building advanced telecom software solutions that enhance connectivity, streamline network management, and offer next-gen communication experiences. From cloud-based solutions to mobile carrier systems, we help telecom companies deliver high-quality, scalable, and secure services that drive customer satisfaction and business growth.",

      cardHeading: "Telecom Software Expertise",
      cardDiscripiton:
        "We offer advanced telecom solutions, including network optimization, cloud-based systems, seamless mobile billing, real-time communication tools, and data analytics. Our services help telecom businesses improve performance, scalability, and customer experience through cutting-edge technologies.",
    },

    statsSection: {
      backgroundImage: "telecom-counter-add.png",
      heading: "Drive the Future of ",
      greenPart: "Telecom Innovation",
      discription:
        "Driving innovation in Telecom Innovation with advanced software solutions for enhanced learning experiences.",
      subheading: "Your Top Telecom Innovation",
      greenPartSubHeading: "Consulting Firm",

      stats: [
        {
          count: "1,000+",
          text: "Telecom Solutions Deployed Globally",
        },
        {
          count: "500+",
          text: "Telecom Providers Served",
        },
        {
          count: "98%",
          text: "Client Retention Rate",
        },
        {
          count: "99.99%",
          text: "Uptime for Network Systems",
        },
      ],
    },

    industryServices: {
      heading: "Telecom Software Development Services",
      text: "Revolutionize your telecom business with cutting-edge software solutions that optimize network performance, enhance communication tools, and provide secure, scalable services, ensuring seamless user experiences and operational efficiency.",

      industryServicesSlides: [
        {
          id: 1,
          borderClass: "border-1",
          icon: "/images/icon1.svg",
          heading: "Network Optimization Solutions",
          text: "Enhance network efficiency, speed, and capacity through intelligent optimization tools.",
          btnLink: "network-optimization-solutions",
          image: "image.png",
        },
        {
          id: 2,
          borderClass: "border-2",
          icon: "/images/icon2.svg",
          heading: "Cloud-Based Telecom Systems",
          text: "Build scalable, cloud-native solutions for managing telecom infrastructure and services.",
          btnLink: "cloud-based-telecom-systems",
          image: "image.png",
        },
        {
          id: 3,
          borderClass: "border-1",
          icon: "/images/arvr-icon.png",
          heading: "Mobile Billing and Payment Systems",
          text: "Design secure and efficient billing systems for mobile operators and service providers.",
          btnLink: "mobile-billing-and-payment-systems",
          image: "image.png",
        },
        {
          id: 4,
          borderClass: "border-2",
          icon: "/images/blockchain-icon.png",
          heading: "Real-Time Communication Tools",
          text: "Develop seamless voice, video, and messaging systems for enhanced communication.",
          btnLink: "real-time-communication-tools",
          image: "image.png",
        },
      ],
    },

    industryAdvertising: {
      heading: "Telecom Software Standards and Compliance",
      discription:
        " Our telecom solutions adhere to global standards such as ETSI, 3GPP, and GSMA, ensuring interoperability across networks and platforms. We also comply with data privacy regulations like GDPR and CCPA to protect user data and ensure regulatory compliance.",
      image: "telecom-add.jpg",
    },

    softwareSolutions: {
      heading: "Explore Our Telecom Software Solutions",
      discription:
        "Discover innovative and tailored telecom software solutions designed to optimize network performance, enhance communication tools, and provide secure, scalable services for seamless user experiences and business success.",

      healthcareSolutionsData: [
        {
          id: 1,
          heading: "Telecom Network Management",
          text: "Optimize and manage network performance across multiple locations to ensure reliable and efficient service delivery.",
        },
        {
          id: 2,
          heading: "Mobile Billing Systems",
          text: "Implement secure, efficient, and accurate billing solutions to streamline mobile service provider operations.",
        },
        {
          id: 3,
          heading: "Cloud Communication Platforms",
          text: "Build scalable cloud-based platforms for seamless voice, video, and messaging services, enhancing communication capabilities.",
        },
        {
          id: 4,
          heading: "Customer Engagement Tools",
          text: "Create interactive customer service platforms with chatbots, IVR, and real-time support to improve customer satisfaction.",
        },
        {
          id: 5,
          heading: "Telecom Analytics Solutions",
          text: "Utilize big data analytics to enhance network performance and provide actionable customer insights for better decision-making.",
        },
      ],
    },

    industryExpertise: {
      heading: "Our Expertise: Innovative Telecom Software Solutions",
      discription:
        "We specialize in delivering cutting-edge telecom software solutions that optimize network performance, streamline billing systems, enhance real-time communication, and harness data analytics for better customer insights.",
      accordionData: [
        {
          id: "collapseOne",
          title: "Telecom Network Management",
          content:
            "Manage and optimize network performance across multiple locations, ensuring reliable, high-quality service and enhanced operational efficiency.",
          expanded: true,
        },
        {
          id: "collapseTwo",
          title: "Mobile Billing Systems",
          content:
            "Design secure, efficient, and accurate billing solutions that cater to mobile service providers, ensuring seamless payment processing and customer satisfaction.",
          expanded: false,
        },
        {
          id: "collapseThree",
          title: "Cloud Communication Platforms",
          content:
            "Build scalable cloud-based platforms for voice, video, and messaging services, empowering telecom providers to deliver flexible and on-demand communication solutions.",
          expanded: false,
        },
        {
          id: "collapseFour",
          title: "Telecom Analytics Solutions",
          content:
            "Leverage big data analytics to track and enhance network performance, improve customer experiences, and make data-driven business decisions.",
          expanded: false,
        },
      ],
    },

    industryFaqs: {
      heading: "Telecom Software Development FAQs",
      discription:
        "Here are answers to some of the most common questions about our telecom software development services:",
      faqData: [
        {
          id: "collapseOne",
          question: "Can you help optimize telecom networks?",
          answer:
            "Yes, we provide network optimization solutions that enhance speed, reliability, and capacity, ensuring smooth operation across telecom systems.",
        },
        {
          id: "collapseTwo",
          question: "How do you ensure the security of telecom systems?",
          answer:
            "We integrate advanced security features like encryption, multi-factor authentication, and fraud detection to protect telecom systems from threats and data breaches.",
        },
        {
          id: "collapseThree",
          question: "What is cloud-based telecom infrastructure?",
          answer:
            "Cloud-based telecom infrastructure leverages the cloud to deliver scalable, on-demand telecom services, allowing telecom providers to reduce costs and increase flexibility.",
        },
        {
          id: "fourth",
          question: "Do you offer mobile billing solutions?",
          answer:
            "Yes, we design mobile billing systems that are secure, efficient, and capable of handling high volumes of transactions for telecom providers.",
        },
        {
          id: "fifth",
          question: "Can you integrate analytics into telecom systems?",
          answer:
            "Absolutely! We offer data analytics tools that help telecom companies optimize their network performance, track usage patterns, and gain insights into customer behavior.",
        },
        {
          id: "sixth",
          question: "Do you provide solutions for customer engagement?",
          answer:
            "Yes, we offer solutions like chatbots, IVR systems, and real-time messaging platforms that enhance customer service and engagement.",
        },
      ],
    },
  },

  {
    id: 13,
    title: "Energy",
    slug: "energy",
    content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
    description:
      "We deliver smart grid management systems, resource optimization platforms, and secure trading solutions that enhance efficiency and compliance in the energy sector.",
    icon: "/images/Energy.png",
    image: "energy.png",
    bannerHeading: "Powering the Future with Sustainable Energy Solutions",
    bannerBtnText: "Let's Get Started",
    bannerImage: "energy-detail-banner.png",
    backgroundImage: `url(/images/industry-detial-banner-bg/Energy.png)`,

    discriptionSection: {
      heading: "Leading the Charge ",
      discription:
        "in Energy Innovation, The energy sector is evolving rapidly with the global push towards sustainability, efficiency, and renewable energy solutions. At Bitsclan, we empower energy providers with cutting-edge software solutions that streamline energy production, distribution, and consumption. Whether it's renewable energy management, smart grids, or energy analytics, we help businesses in the energy sector optimize operations, reduce waste, and meet sustainability goals.",

      cardHeading: "Energy Software Expertise",
      cardDiscripiton:
        "We offer innovative energy solutions to optimize efficiency and sustainability. Our Smart Grid Solutions enhance grid reliability and real-time monitoring. Renewable Energy Management integrates and manages renewable sources, while Energy Consumption Analytics provide insights to reduce costs. With Automated Billing and Reporting, we streamline financial processes, and our Energy Efficiency Solutions help businesses cut waste and improve performance.",
    },

    statsSection: {
      backgroundImage: "energy-counter-bg.jpg",
      heading: "Empowering the Energy ",
      greenPart: "Sector with Technology",
      discription:
        "Driving innovation in Energy Innovation with advanced software solutions for enhanced learning experiences.",
      subheading: "Your Top Energy",
      greenPartSubHeading: "Consulting Firm",

      stats: [
        {
          count: "1,500+",
          text: "Energy Solutions Deployed Globally",
        },
        {
          count: "250+",
          text: "Energy Providers Served",
        },
        {
          count: "98%",
          text: "Client Satisfaction Rate",
        },
        {
          count: "75%",
          text: "Reduction in Energy Waste for Clients",
        },
      ],
    },

    industryServices: {
      heading: "Energy Software Development Services",
      text: "Transform your energy business with innovative software solutions that optimize energy management, enhance efficiency, and provide scalable, secure services, driving operational excellence and sustainability.",

      industryServicesSlides: [
        {
          id: 1,
          borderClass: "border-1",
          icon: "/images/icon1.svg",
          heading: "Smart Grid Solutions",
          text: "Create intelligent, automated grids for efficient energy distribution and reduced downtime.",
          btnLink: "smart-grid-solutions",
          image: "image.png",
        },
        {
          id: 2,
          borderClass: "border-2",
          icon: "/images/icon2.svg",
          heading: "Renewable Energy Management",
          text: "Develop systems for managing renewable energy sources like solar, wind, and hydro.",
          btnLink: "renewable-energy-management",
          image: "image.png",
        },
        {
          id: 3,
          borderClass: "border-1",
          icon: "/images/arvr-icon.png",
          heading: "Energy Consumption Analytics",
          text: "Leverage big data to optimize energy consumption across industries and improve efficiency.",
          btnLink: "energy-consumption-analytics",
          image: "image.png",
        },
        {
          id: 4,
          borderClass: "border-2",
          icon: "/images/blockchain-icon.png",
          heading: "Automated Billing and Reporting",
          text: "Create automated billing systems for accurate, timely energy consumption payments.",
          btnLink: "automated-billing-and-reporting",
          image: "image.png",
        },
      ],
    },

    industryAdvertising: {
      heading: "Energy Software Standards and Compliance",
      discription:
        " Our energy software solutions comply with industry regulations and standards, such as ISO 50001, ANSI, and IEEE, ensuring that your operations meet global best practices. Additionally, our systems are built to adhere to environmental regulations, helping you meet sustainability goals.",
      image: "energy-add.jpg",
    },

    softwareSolutions: {
      heading: "Explore Our Energy Solutions",
      discription:
        "Discover cutting-edge energy solutions tailored to optimize resource distribution, enhance energy efficiency, and provide secure, scalable systems for sustainable business growth and operational success.",
      healthcareSolutionsData: [
        {
          id: 1,
          heading: "Smart Grid Management",
          text: "Optimize grid performance through real-time monitoring, automation, and predictive analytics to enhance efficiency and reliability.",
        },
        {
          id: 2,
          heading: "Renewable Energy Integration",
          text: "Seamlessly integrate renewable energy sources such as solar and wind into your existing grid for efficient, sustainable energy use.",
        },
        {
          id: 3,
          heading: "Energy Analytics and Forecasting",
          text: "Gain actionable insights into energy consumption patterns, forecast future demand, and optimize resource allocation for maximum efficiency.",
        },
        {
          id: 4,
          heading: "Demand Response Systems",
          text: "Implement systems that dynamically manage energy consumption based on real-time demand, preventing grid overload and enhancing reliability.",
        },
        {
          id: 5,
          heading: "Energy Efficiency Solutions",
          text: "Identify and capitalize on opportunities to reduce energy waste and improve operational efficiency, driving sustainability and cost savings.",
        },
      ],
    },

    industryExpertise: {
      heading: "Our Expertise: Innovative Energy Software Solutions",
      discription:
        "We specialize in delivering advanced energy software solutions that optimize grid performance, integrate renewable energy, streamline energy analytics, and enhance demand response systems for sustainable and efficient energy use.",
      accordionData: [
        {
          id: "collapseOne",
          title: "Smart Grid Management",
          content:
            "Optimize grid performance through real-time monitoring, automation, and predictive analytics to enhance energy distribution, minimize downtime, and improve operational efficiency.",
          expanded: true,
        },
        {
          id: "collapseTwo",
          title: "Renewable Energy Integration",
          content:
            "Seamlessly integrate renewable energy sources, such as solar and wind, into existing grid systems to maximize energy efficiency and sustainability.",
          expanded: false,
        },
        {
          id: "collapseThree",
          title: "Energy Analytics and Forecasting",
          content:
            "Leverage big data and advanced analytics to gain insights into energy usage patterns, forecast future demands, and optimize resource allocation for cost-effective operations.",
          expanded: false,
        },
        {
          id: "collapseFour",
          title: "Demand Response Systems",
          content:
            "Implement systems to manage real-time energy demand, preventing grid overload and ensuring stable, efficient energy use during peak times.",
          expanded: false,
        },
      ],
    },

    industryFaqs: {
      heading: "Energy Software Development FAQs",
      discription:
        "Here are answers to some of the most common questions about our energy software development services:",
      faqData: [
        {
          id: "collapseOne",
          question:
            "How can you help reduce energy consumption in our operations?",
          answer:
            "We offer energy analytics solutions that identify patterns in energy consumption, allowing you to optimize usage, reduce waste, and improve efficiency across your operations.",
        },
        {
          id: "collapseTwo",
          question: "What are smart grids and how do they work?",
          answer:
            "Smart grids are advanced electrical grids that use real-time data and automation to optimize energy distribution. They can detect issues in the grid, minimize downtime, and enable more efficient energy use by balancing supply and demand.",
        },
        {
          id: "collapseThree",
          question:
            "How can renewable energy be integrated into our existing systems?",
          answer:
            "We develop seamless integration solutions that allow renewable energy sources like solar, wind, and hydro to be efficiently connected to your existing energy infrastructure, helping you meet sustainability goals while maintaining grid stability.",
        },
        {
          id: "fourth",
          question: "What is energy consumption analytics?",
          answer:
            "Energy consumption analytics uses data to analyze energy usage patterns and identify areas for improvement. Our solutions provide actionable insights to reduce consumption and costs, enhancing overall operational efficiency.",
        },
        {
          id: "fifth",
          question:
            "Can your solutions help with energy efficiency in industrial operations?",
          answer:
            "Yes, our energy efficiency solutions are designed to optimize energy use across industrial systems. By leveraging predictive analytics and automation, we help businesses minimize waste and reduce their carbon footprint.",
        },
        {
          id: "sixth",
          question:
            "Do you offer solutions for managing demand-response in energy systems?",
          answer:
            "Yes, we provide demand-response systems that manage energy usage during peak demand times, preventing grid overloads and ensuring that energy is used efficiently when it's most needed.",
        },
      ],
    },
  },

  {
    id: 14,
    title: "Agriculture",
    slug: "agriculture",
    content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
    description:
      "We cultivate innovation in agriculture with precision farming technologies, AI-driven crop monitoring, and blockchain-secured supply chains, ensuring higher yields and sustainable practices.",
    icon: "/images/industries-icons/Agriculture.svg",
    image: "Agriculture.png",
    bannerHeading: "Powering the Future with Sustainable Energy Solutions",
    bannerBtnText: "Let's Get Started",
    bannerImage: "energy-detail-banner.png",
    backgroundImage: `url(/images/industry-detial-banner-bg/Energy.png)`,

    discriptionSection: {
      heading: "Leading the Charge ",
      discription:
        "in Energy Innovation, The energy sector is evolving rapidly with the global push towards sustainability, efficiency, and renewable energy solutions. At Bitsclan, we empower energy providers with cutting-edge software solutions that streamline energy production, distribution, and consumption. Whether it's renewable energy management, smart grids, or energy analytics, we help businesses in the energy sector optimize operations, reduce waste, and meet sustainability goals.",

      cardHeading: "Energy Software Expertise",
      cardDiscripiton:
        "We offer innovative energy solutions to optimize efficiency and sustainability. Our Smart Grid Solutions enhance grid reliability and real-time monitoring. Renewable Energy Management integrates and manages renewable sources, while Energy Consumption Analytics provide insights to reduce costs. With Automated Billing and Reporting, we streamline financial processes, and our Energy Efficiency Solutions help businesses cut waste and improve performance.",
    },

    statsSection: {
      backgroundImage: "energy-counter-bg.jpg",
      heading: "Empowering the Energy ",
      greenPart: "Sector with Technology",
      discription:
        "Driving innovation in Energy Innovation with advanced software solutions for enhanced learning experiences.",
      subheading: "Your Top Energy",
      greenPartSubHeading: "Consulting Firm",

      stats: [
        {
          count: "1,500+",
          text: "Energy Solutions Deployed Globally",
        },
        {
          count: "250+",
          text: "Energy Providers Served",
        },
        {
          count: "98%",
          text: "Client Satisfaction Rate",
        },
        {
          count: "75%",
          text: "Reduction in Energy Waste for Clients",
        },
      ],
    },

    industryServices: {
      heading: "Energy Software Development Services",
      text: "Transform your energy business with innovative software solutions that optimize energy management, enhance efficiency, and provide scalable, secure services, driving operational excellence and sustainability.",

      industryServicesSlides: [
        {
          id: 1,
          borderClass: "border-1",
          icon: "/images/icon1.svg",
          heading: "Smart Grid Solutions",
          text: "Create intelligent, automated grids for efficient energy distribution and reduced downtime.",
          btnLink: "smart-grid-solutions",
          image: "image.png",
        },
        {
          id: 2,
          borderClass: "border-2",
          icon: "/images/icon2.svg",
          heading: "Renewable Energy Management",
          text: "Develop systems for managing renewable energy sources like solar, wind, and hydro.",
          btnLink: "renewable-energy-management",
          image: "image.png",
        },
        {
          id: 3,
          borderClass: "border-1",
          icon: "/images/arvr-icon.png",
          heading: "Energy Consumption Analytics",
          text: "Leverage big data to optimize energy consumption across industries and improve efficiency.",
          btnLink: "energy-consumption-analytics",
          image: "image.png",
        },
        {
          id: 4,
          borderClass: "border-2",
          icon: "/images/blockchain-icon.png",
          heading: "Automated Billing and Reporting",
          text: "Create automated billing systems for accurate, timely energy consumption payments.",
          btnLink: "automated-billing-and-reporting",
          image: "image.png",
        },
      ],
    },

    industryAdvertising: {
      heading: "Energy Software Standards and Compliance",
      discription:
        " Our energy software solutions comply with industry regulations and standards, such as ISO 50001, ANSI, and IEEE, ensuring that your operations meet global best practices. Additionally, our systems are built to adhere to environmental regulations, helping you meet sustainability goals.",
      image: "energy-add.jpg",
    },

    softwareSolutions: {
      heading: "Explore Our Energy Solutions",
      discription:
        "Discover cutting-edge energy solutions tailored to optimize resource distribution, enhance energy efficiency, and provide secure, scalable systems for sustainable business growth and operational success.",
      healthcareSolutionsData: [
        {
          id: 1,
          heading: "Smart Grid Management",
          text: "Optimize grid performance through real-time monitoring, automation, and predictive analytics to enhance efficiency and reliability.",
        },
        {
          id: 2,
          heading: "Renewable Energy Integration",
          text: "Seamlessly integrate renewable energy sources such as solar and wind into your existing grid for efficient, sustainable energy use.",
        },
        {
          id: 3,
          heading: "Energy Analytics and Forecasting",
          text: "Gain actionable insights into energy consumption patterns, forecast future demand, and optimize resource allocation for maximum efficiency.",
        },
        {
          id: 4,
          heading: "Demand Response Systems",
          text: "Implement systems that dynamically manage energy consumption based on real-time demand, preventing grid overload and enhancing reliability.",
        },
        {
          id: 5,
          heading: "Energy Efficiency Solutions",
          text: "Identify and capitalize on opportunities to reduce energy waste and improve operational efficiency, driving sustainability and cost savings.",
        },
      ],
    },

    industryExpertise: {
      heading: "Our Expertise: Innovative Energy Software Solutions",
      discription:
        "We specialize in delivering advanced energy software solutions that optimize grid performance, integrate renewable energy, streamline energy analytics, and enhance demand response systems for sustainable and efficient energy use.",
      accordionData: [
        {
          id: "collapseOne",
          title: "Smart Grid Management",
          content:
            "Optimize grid performance through real-time monitoring, automation, and predictive analytics to enhance energy distribution, minimize downtime, and improve operational efficiency.",
          expanded: true,
        },
        {
          id: "collapseTwo",
          title: "Renewable Energy Integration",
          content:
            "Seamlessly integrate renewable energy sources, such as solar and wind, into existing grid systems to maximize energy efficiency and sustainability.",
          expanded: false,
        },
        {
          id: "collapseThree",
          title: "Energy Analytics and Forecasting",
          content:
            "Leverage big data and advanced analytics to gain insights into energy usage patterns, forecast future demands, and optimize resource allocation for cost-effective operations.",
          expanded: false,
        },
        {
          id: "collapseFour",
          title: "Demand Response Systems",
          content:
            "Implement systems to manage real-time energy demand, preventing grid overload and ensuring stable, efficient energy use during peak times.",
          expanded: false,
        },
      ],
    },

    industryFaqs: {
      heading: "Energy Software Development FAQs",
      discription:
        "Here are answers to some of the most common questions about our energy software development services:",
      faqData: [
        {
          id: "collapseOne",
          question:
            "How can you help reduce energy consumption in our operations?",
          answer:
            "We offer energy analytics solutions that identify patterns in energy consumption, allowing you to optimize usage, reduce waste, and improve efficiency across your operations.",
        },
        {
          id: "collapseTwo",
          question: "What are smart grids and how do they work?",
          answer:
            "Smart grids are advanced electrical grids that use real-time data and automation to optimize energy distribution. They can detect issues in the grid, minimize downtime, and enable more efficient energy use by balancing supply and demand.",
        },
        {
          id: "collapseThree",
          question:
            "How can renewable energy be integrated into our existing systems?",
          answer:
            "We develop seamless integration solutions that allow renewable energy sources like solar, wind, and hydro to be efficiently connected to your existing energy infrastructure, helping you meet sustainability goals while maintaining grid stability.",
        },
        {
          id: "fourth",
          question: "What is energy consumption analytics?",
          answer:
            "Energy consumption analytics uses data to analyze energy usage patterns and identify areas for improvement. Our solutions provide actionable insights to reduce consumption and costs, enhancing overall operational efficiency.",
        },
        {
          id: "fifth",
          question:
            "Can your solutions help with energy efficiency in industrial operations?",
          answer:
            "Yes, our energy efficiency solutions are designed to optimize energy use across industrial systems. By leveraging predictive analytics and automation, we help businesses minimize waste and reduce their carbon footprint.",
        },
        {
          id: "sixth",
          question:
            "Do you offer solutions for managing demand-response in energy systems?",
          answer:
            "Yes, we provide demand-response systems that manage energy usage during peak demand times, preventing grid overloads and ensuring that energy is used efficiently when it's most needed.",
        },
      ],
    },
  },

  {
    id: 15,
    title: "Aviation",
    slug: "agriculture",
    content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
    description:
      "We optimize aviation with AI-driven flight scheduling, predictive maintenance systems, and enhanced passenger experience platforms, ensuring efficiency and safety in every journey.",
    icon: "/images/industries-icons/Aviation.svg",
    image: "Aviation.png",
    bannerHeading: "Powering the Future with Sustainable Energy Solutions",
    bannerBtnText: "Let's Get Started",
    bannerImage: "energy-detail-banner.png",
    backgroundImage: `url(/images/industry-detial-banner-bg/Energy.png)`,

    discriptionSection: {
      heading: "Leading the Charge ",
      discription:
        "in Energy Innovation, The energy sector is evolving rapidly with the global push towards sustainability, efficiency, and renewable energy solutions. At Bitsclan, we empower energy providers with cutting-edge software solutions that streamline energy production, distribution, and consumption. Whether it's renewable energy management, smart grids, or energy analytics, we help businesses in the energy sector optimize operations, reduce waste, and meet sustainability goals.",

      cardHeading: "Energy Software Expertise",
      cardDiscripiton:
        "We offer innovative energy solutions to optimize efficiency and sustainability. Our Smart Grid Solutions enhance grid reliability and real-time monitoring. Renewable Energy Management integrates and manages renewable sources, while Energy Consumption Analytics provide insights to reduce costs. With Automated Billing and Reporting, we streamline financial processes, and our Energy Efficiency Solutions help businesses cut waste and improve performance.",
    },

    statsSection: {
      backgroundImage: "energy-counter-bg.jpg",
      heading: "Empowering the Energy ",
      greenPart: "Sector with Technology",
      discription:
        "Driving innovation in Energy Innovation with advanced software solutions for enhanced learning experiences.",
      subheading: "Your Top Energy",
      greenPartSubHeading: "Consulting Firm",

      stats: [
        {
          count: "1,500+",
          text: "Energy Solutions Deployed Globally",
        },
        {
          count: "250+",
          text: "Energy Providers Served",
        },
        {
          count: "98%",
          text: "Client Satisfaction Rate",
        },
        {
          count: "75%",
          text: "Reduction in Energy Waste for Clients",
        },
      ],
    },

    industryServices: {
      heading: "Energy Software Development Services",
      text: "Transform your energy business with innovative software solutions that optimize energy management, enhance efficiency, and provide scalable, secure services, driving operational excellence and sustainability.",

      industryServicesSlides: [
        {
          id: 1,
          borderClass: "border-1",
          icon: "/images/icon1.svg",
          heading: "Smart Grid Solutions",
          text: "Create intelligent, automated grids for efficient energy distribution and reduced downtime.",
          btnLink: "smart-grid-solutions",
          image: "image.png",
        },
        {
          id: 2,
          borderClass: "border-2",
          icon: "/images/icon2.svg",
          heading: "Renewable Energy Management",
          text: "Develop systems for managing renewable energy sources like solar, wind, and hydro.",
          btnLink: "renewable-energy-management",
          image: "image.png",
        },
        {
          id: 3,
          borderClass: "border-1",
          icon: "/images/arvr-icon.png",
          heading: "Energy Consumption Analytics",
          text: "Leverage big data to optimize energy consumption across industries and improve efficiency.",
          btnLink: "energy-consumption-analytics",
          image: "image.png",
        },
        {
          id: 4,
          borderClass: "border-2",
          icon: "/images/blockchain-icon.png",
          heading: "Automated Billing and Reporting",
          text: "Create automated billing systems for accurate, timely energy consumption payments.",
          btnLink: "automated-billing-and-reporting",
          image: "image.png",
        },
      ],
    },

    industryAdvertising: {
      heading: "Energy Software Standards and Compliance",
      discription:
        " Our energy software solutions comply with industry regulations and standards, such as ISO 50001, ANSI, and IEEE, ensuring that your operations meet global best practices. Additionally, our systems are built to adhere to environmental regulations, helping you meet sustainability goals.",
      image: "energy-add.jpg",
    },

    softwareSolutions: {
      heading: "Explore Our Energy Solutions",
      discription:
        "Discover cutting-edge energy solutions tailored to optimize resource distribution, enhance energy efficiency, and provide secure, scalable systems for sustainable business growth and operational success.",
      healthcareSolutionsData: [
        {
          id: 1,
          heading: "Smart Grid Management",
          text: "Optimize grid performance through real-time monitoring, automation, and predictive analytics to enhance efficiency and reliability.",
        },
        {
          id: 2,
          heading: "Renewable Energy Integration",
          text: "Seamlessly integrate renewable energy sources such as solar and wind into your existing grid for efficient, sustainable energy use.",
        },
        {
          id: 3,
          heading: "Energy Analytics and Forecasting",
          text: "Gain actionable insights into energy consumption patterns, forecast future demand, and optimize resource allocation for maximum efficiency.",
        },
        {
          id: 4,
          heading: "Demand Response Systems",
          text: "Implement systems that dynamically manage energy consumption based on real-time demand, preventing grid overload and enhancing reliability.",
        },
        {
          id: 5,
          heading: "Energy Efficiency Solutions",
          text: "Identify and capitalize on opportunities to reduce energy waste and improve operational efficiency, driving sustainability and cost savings.",
        },
      ],
    },

    industryExpertise: {
      heading: "Our Expertise: Innovative Energy Software Solutions",
      discription:
        "We specialize in delivering advanced energy software solutions that optimize grid performance, integrate renewable energy, streamline energy analytics, and enhance demand response systems for sustainable and efficient energy use.",
      accordionData: [
        {
          id: "collapseOne",
          title: "Smart Grid Management",
          content:
            "Optimize grid performance through real-time monitoring, automation, and predictive analytics to enhance energy distribution, minimize downtime, and improve operational efficiency.",
          expanded: true,
        },
        {
          id: "collapseTwo",
          title: "Renewable Energy Integration",
          content:
            "Seamlessly integrate renewable energy sources, such as solar and wind, into existing grid systems to maximize energy efficiency and sustainability.",
          expanded: false,
        },
        {
          id: "collapseThree",
          title: "Energy Analytics and Forecasting",
          content:
            "Leverage big data and advanced analytics to gain insights into energy usage patterns, forecast future demands, and optimize resource allocation for cost-effective operations.",
          expanded: false,
        },
        {
          id: "collapseFour",
          title: "Demand Response Systems",
          content:
            "Implement systems to manage real-time energy demand, preventing grid overload and ensuring stable, efficient energy use during peak times.",
          expanded: false,
        },
      ],
    },

    industryFaqs: {
      heading: "Energy Software Development FAQs",
      discription:
        "Here are answers to some of the most common questions about our energy software development services:",
      faqData: [
        {
          id: "collapseOne",
          question:
            "How can you help reduce energy consumption in our operations?",
          answer:
            "We offer energy analytics solutions that identify patterns in energy consumption, allowing you to optimize usage, reduce waste, and improve efficiency across your operations.",
        },
        {
          id: "collapseTwo",
          question: "What are smart grids and how do they work?",
          answer:
            "Smart grids are advanced electrical grids that use real-time data and automation to optimize energy distribution. They can detect issues in the grid, minimize downtime, and enable more efficient energy use by balancing supply and demand.",
        },
        {
          id: "collapseThree",
          question:
            "How can renewable energy be integrated into our existing systems?",
          answer:
            "We develop seamless integration solutions that allow renewable energy sources like solar, wind, and hydro to be efficiently connected to your existing energy infrastructure, helping you meet sustainability goals while maintaining grid stability.",
        },
        {
          id: "fourth",
          question: "What is energy consumption analytics?",
          answer:
            "Energy consumption analytics uses data to analyze energy usage patterns and identify areas for improvement. Our solutions provide actionable insights to reduce consumption and costs, enhancing overall operational efficiency.",
        },
        {
          id: "fifth",
          question:
            "Can your solutions help with energy efficiency in industrial operations?",
          answer:
            "Yes, our energy efficiency solutions are designed to optimize energy use across industrial systems. By leveraging predictive analytics and automation, we help businesses minimize waste and reduce their carbon footprint.",
        },
        {
          id: "sixth",
          question:
            "Do you offer solutions for managing demand-response in energy systems?",
          answer:
            "Yes, we provide demand-response systems that manage energy usage during peak demand times, preventing grid overloads and ensuring that energy is used efficiently when it's most needed.",
        },
      ],
    },
  },

  {
    id: 16,
    title: "Construction",
    slug: "construction",
    content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
    description:
      "We drive the future of construction with AI-powered project planning, IoT-enabled site monitoring, and sustainable building technologies that reduce costs and environmental impact.",
    icon: "/images/industries-icons/Construction.svg",
    image: "Construction.png",
    bannerHeading: "Powering the Future with Sustainable Energy Solutions",
    bannerBtnText: "Let's Get Started",
    bannerImage: "energy-detail-banner.png",
    backgroundImage: `url(/images/industry-detial-banner-bg/Energy.png)`,

    discriptionSection: {
      heading: "Leading the Charge ",
      discription:
        "in Energy Innovation, The energy sector is evolving rapidly with the global push towards sustainability, efficiency, and renewable energy solutions. At Bitsclan, we empower energy providers with cutting-edge software solutions that streamline energy production, distribution, and consumption. Whether it's renewable energy management, smart grids, or energy analytics, we help businesses in the energy sector optimize operations, reduce waste, and meet sustainability goals.",

      cardHeading: "Energy Software Expertise",
      cardDiscripiton:
        "We offer innovative energy solutions to optimize efficiency and sustainability. Our Smart Grid Solutions enhance grid reliability and real-time monitoring. Renewable Energy Management integrates and manages renewable sources, while Energy Consumption Analytics provide insights to reduce costs. With Automated Billing and Reporting, we streamline financial processes, and our Energy Efficiency Solutions help businesses cut waste and improve performance.",
    },

    statsSection: {
      backgroundImage: "energy-counter-bg.jpg",
      heading: "Empowering the Energy ",
      greenPart: "Sector with Technology",
      discription:
        "Driving innovation in Energy Innovation with advanced software solutions for enhanced learning experiences.",
      subheading: "Your Top Energy",
      greenPartSubHeading: "Consulting Firm",

      stats: [
        {
          count: "1,500+",
          text: "Energy Solutions Deployed Globally",
        },
        {
          count: "250+",
          text: "Energy Providers Served",
        },
        {
          count: "98%",
          text: "Client Satisfaction Rate",
        },
        {
          count: "75%",
          text: "Reduction in Energy Waste for Clients",
        },
      ],
    },

    industryServices: {
      heading: "Energy Software Development Services",
      text: "Transform your energy business with innovative software solutions that optimize energy management, enhance efficiency, and provide scalable, secure services, driving operational excellence and sustainability.",

      industryServicesSlides: [
        {
          id: 1,
          borderClass: "border-1",
          icon: "/images/icon1.svg",
          heading: "Smart Grid Solutions",
          text: "Create intelligent, automated grids for efficient energy distribution and reduced downtime.",
          btnLink: "smart-grid-solutions",
          image: "image.png",
        },
        {
          id: 2,
          borderClass: "border-2",
          icon: "/images/icon2.svg",
          heading: "Renewable Energy Management",
          text: "Develop systems for managing renewable energy sources like solar, wind, and hydro.",
          btnLink: "renewable-energy-management",
          image: "image.png",
        },
        {
          id: 3,
          borderClass: "border-1",
          icon: "/images/arvr-icon.png",
          heading: "Energy Consumption Analytics",
          text: "Leverage big data to optimize energy consumption across industries and improve efficiency.",
          btnLink: "energy-consumption-analytics",
          image: "image.png",
        },
        {
          id: 4,
          borderClass: "border-2",
          icon: "/images/blockchain-icon.png",
          heading: "Automated Billing and Reporting",
          text: "Create automated billing systems for accurate, timely energy consumption payments.",
          btnLink: "automated-billing-and-reporting",
          image: "image.png",
        },
      ],
    },

    industryAdvertising: {
      heading: "Energy Software Standards and Compliance",
      discription:
        " Our energy software solutions comply with industry regulations and standards, such as ISO 50001, ANSI, and IEEE, ensuring that your operations meet global best practices. Additionally, our systems are built to adhere to environmental regulations, helping you meet sustainability goals.",
      image: "energy-add.jpg",
    },

    softwareSolutions: {
      heading: "Explore Our Energy Solutions",
      discription:
        "Discover cutting-edge energy solutions tailored to optimize resource distribution, enhance energy efficiency, and provide secure, scalable systems for sustainable business growth and operational success.",
      healthcareSolutionsData: [
        {
          id: 1,
          heading: "Smart Grid Management",
          text: "Optimize grid performance through real-time monitoring, automation, and predictive analytics to enhance efficiency and reliability.",
        },
        {
          id: 2,
          heading: "Renewable Energy Integration",
          text: "Seamlessly integrate renewable energy sources such as solar and wind into your existing grid for efficient, sustainable energy use.",
        },
        {
          id: 3,
          heading: "Energy Analytics and Forecasting",
          text: "Gain actionable insights into energy consumption patterns, forecast future demand, and optimize resource allocation for maximum efficiency.",
        },
        {
          id: 4,
          heading: "Demand Response Systems",
          text: "Implement systems that dynamically manage energy consumption based on real-time demand, preventing grid overload and enhancing reliability.",
        },
        {
          id: 5,
          heading: "Energy Efficiency Solutions",
          text: "Identify and capitalize on opportunities to reduce energy waste and improve operational efficiency, driving sustainability and cost savings.",
        },
      ],
    },

    industryExpertise: {
      heading: "Our Expertise: Innovative Energy Software Solutions",
      discription:
        "We specialize in delivering advanced energy software solutions that optimize grid performance, integrate renewable energy, streamline energy analytics, and enhance demand response systems for sustainable and efficient energy use.",
      accordionData: [
        {
          id: "collapseOne",
          title: "Smart Grid Management",
          content:
            "Optimize grid performance through real-time monitoring, automation, and predictive analytics to enhance energy distribution, minimize downtime, and improve operational efficiency.",
          expanded: true,
        },
        {
          id: "collapseTwo",
          title: "Renewable Energy Integration",
          content:
            "Seamlessly integrate renewable energy sources, such as solar and wind, into existing grid systems to maximize energy efficiency and sustainability.",
          expanded: false,
        },
        {
          id: "collapseThree",
          title: "Energy Analytics and Forecasting",
          content:
            "Leverage big data and advanced analytics to gain insights into energy usage patterns, forecast future demands, and optimize resource allocation for cost-effective operations.",
          expanded: false,
        },
        {
          id: "collapseFour",
          title: "Demand Response Systems",
          content:
            "Implement systems to manage real-time energy demand, preventing grid overload and ensuring stable, efficient energy use during peak times.",
          expanded: false,
        },
      ],
    },

    industryFaqs: {
      heading: "Energy Software Development FAQs",
      discription:
        "Here are answers to some of the most common questions about our energy software development services:",
      faqData: [
        {
          id: "collapseOne",
          question:
            "How can you help reduce energy consumption in our operations?",
          answer:
            "We offer energy analytics solutions that identify patterns in energy consumption, allowing you to optimize usage, reduce waste, and improve efficiency across your operations.",
        },
        {
          id: "collapseTwo",
          question: "What are smart grids and how do they work?",
          answer:
            "Smart grids are advanced electrical grids that use real-time data and automation to optimize energy distribution. They can detect issues in the grid, minimize downtime, and enable more efficient energy use by balancing supply and demand.",
        },
        {
          id: "collapseThree",
          question:
            "How can renewable energy be integrated into our existing systems?",
          answer:
            "We develop seamless integration solutions that allow renewable energy sources like solar, wind, and hydro to be efficiently connected to your existing energy infrastructure, helping you meet sustainability goals while maintaining grid stability.",
        },
        {
          id: "fourth",
          question: "What is energy consumption analytics?",
          answer:
            "Energy consumption analytics uses data to analyze energy usage patterns and identify areas for improvement. Our solutions provide actionable insights to reduce consumption and costs, enhancing overall operational efficiency.",
        },
        {
          id: "fifth",
          question:
            "Can your solutions help with energy efficiency in industrial operations?",
          answer:
            "Yes, our energy efficiency solutions are designed to optimize energy use across industrial systems. By leveraging predictive analytics and automation, we help businesses minimize waste and reduce their carbon footprint.",
        },
        {
          id: "sixth",
          question:
            "Do you offer solutions for managing demand-response in energy systems?",
          answer:
            "Yes, we provide demand-response systems that manage energy usage during peak demand times, preventing grid overloads and ensuring that energy is used efficiently when it's most needed.",
        },
      ],
    },
  },
  {
    id: 17,
    title: "Electric Vehicles",
    slug: "electric-vehicles",
    content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
    description:
      "We accelerate the EV revolution with smart charging networks, AI-powered energy management systems, and advanced telematics, driving the adoption of sustainable transportation solutions.",
    icon: "/images/industries-icons/Electric Vehicles (EV).svg",
    image: "Electric Vehicles (EV).png",
    bannerHeading: "Powering the Future with Sustainable Energy Solutions",
    bannerBtnText: "Let's Get Started",
    bannerImage: "energy-detail-banner.png",
    backgroundImage: `url(/images/industry-detial-banner-bg/Energy.png)`,

    discriptionSection: {
      heading: "Leading the Charge ",
      discription:
        "in Energy Innovation, The energy sector is evolving rapidly with the global push towards sustainability, efficiency, and renewable energy solutions. At Bitsclan, we empower energy providers with cutting-edge software solutions that streamline energy production, distribution, and consumption. Whether it's renewable energy management, smart grids, or energy analytics, we help businesses in the energy sector optimize operations, reduce waste, and meet sustainability goals.",

      cardHeading: "Energy Software Expertise",
      cardDiscripiton:
        "We offer innovative energy solutions to optimize efficiency and sustainability. Our Smart Grid Solutions enhance grid reliability and real-time monitoring. Renewable Energy Management integrates and manages renewable sources, while Energy Consumption Analytics provide insights to reduce costs. With Automated Billing and Reporting, we streamline financial processes, and our Energy Efficiency Solutions help businesses cut waste and improve performance.",
    },

    statsSection: {
      backgroundImage: "energy-counter-bg.jpg",
      heading: "Empowering the Energy ",
      greenPart: "Sector with Technology",
      discription:
        "Driving innovation in Energy Innovation with advanced software solutions for enhanced learning experiences.",
      subheading: "Your Top Energy",
      greenPartSubHeading: "Consulting Firm",

      stats: [
        {
          count: "1,500+",
          text: "Energy Solutions Deployed Globally",
        },
        {
          count: "250+",
          text: "Energy Providers Served",
        },
        {
          count: "98%",
          text: "Client Satisfaction Rate",
        },
        {
          count: "75%",
          text: "Reduction in Energy Waste for Clients",
        },
      ],
    },

    industryServices: {
      heading: "Energy Software Development Services",
      text: "Transform your energy business with innovative software solutions that optimize energy management, enhance efficiency, and provide scalable, secure services, driving operational excellence and sustainability.",

      industryServicesSlides: [
        {
          id: 1,
          borderClass: "border-1",
          icon: "/images/icon1.svg",
          heading: "Smart Grid Solutions",
          text: "Create intelligent, automated grids for efficient energy distribution and reduced downtime.",
          btnLink: "smart-grid-solutions",
          image: "image.png",
        },
        {
          id: 2,
          borderClass: "border-2",
          icon: "/images/icon2.svg",
          heading: "Renewable Energy Management",
          text: "Develop systems for managing renewable energy sources like solar, wind, and hydro.",
          btnLink: "renewable-energy-management",
          image: "image.png",
        },
        {
          id: 3,
          borderClass: "border-1",
          icon: "/images/arvr-icon.png",
          heading: "Energy Consumption Analytics",
          text: "Leverage big data to optimize energy consumption across industries and improve efficiency.",
          btnLink: "energy-consumption-analytics",
          image: "image.png",
        },
        {
          id: 4,
          borderClass: "border-2",
          icon: "/images/blockchain-icon.png",
          heading: "Automated Billing and Reporting",
          text: "Create automated billing systems for accurate, timely energy consumption payments.",
          btnLink: "automated-billing-and-reporting",
          image: "image.png",
        },
      ],
    },

    industryAdvertising: {
      heading: "Energy Software Standards and Compliance",
      discription:
        " Our energy software solutions comply with industry regulations and standards, such as ISO 50001, ANSI, and IEEE, ensuring that your operations meet global best practices. Additionally, our systems are built to adhere to environmental regulations, helping you meet sustainability goals.",
      image: "energy-add.jpg",
    },

    softwareSolutions: {
      heading: "Explore Our Energy Solutions",
      discription:
        "Discover cutting-edge energy solutions tailored to optimize resource distribution, enhance energy efficiency, and provide secure, scalable systems for sustainable business growth and operational success.",
      healthcareSolutionsData: [
        {
          id: 1,
          heading: "Smart Grid Management",
          text: "Optimize grid performance through real-time monitoring, automation, and predictive analytics to enhance efficiency and reliability.",
        },
        {
          id: 2,
          heading: "Renewable Energy Integration",
          text: "Seamlessly integrate renewable energy sources such as solar and wind into your existing grid for efficient, sustainable energy use.",
        },
        {
          id: 3,
          heading: "Energy Analytics and Forecasting",
          text: "Gain actionable insights into energy consumption patterns, forecast future demand, and optimize resource allocation for maximum efficiency.",
        },
        {
          id: 4,
          heading: "Demand Response Systems",
          text: "Implement systems that dynamically manage energy consumption based on real-time demand, preventing grid overload and enhancing reliability.",
        },
        {
          id: 5,
          heading: "Energy Efficiency Solutions",
          text: "Identify and capitalize on opportunities to reduce energy waste and improve operational efficiency, driving sustainability and cost savings.",
        },
      ],
    },

    industryExpertise: {
      heading: "Our Expertise: Innovative Energy Software Solutions",
      discription:
        "We specialize in delivering advanced energy software solutions that optimize grid performance, integrate renewable energy, streamline energy analytics, and enhance demand response systems for sustainable and efficient energy use.",
      accordionData: [
        {
          id: "collapseOne",
          title: "Smart Grid Management",
          content:
            "Optimize grid performance through real-time monitoring, automation, and predictive analytics to enhance energy distribution, minimize downtime, and improve operational efficiency.",
          expanded: true,
        },
        {
          id: "collapseTwo",
          title: "Renewable Energy Integration",
          content:
            "Seamlessly integrate renewable energy sources, such as solar and wind, into existing grid systems to maximize energy efficiency and sustainability.",
          expanded: false,
        },
        {
          id: "collapseThree",
          title: "Energy Analytics and Forecasting",
          content:
            "Leverage big data and advanced analytics to gain insights into energy usage patterns, forecast future demands, and optimize resource allocation for cost-effective operations.",
          expanded: false,
        },
        {
          id: "collapseFour",
          title: "Demand Response Systems",
          content:
            "Implement systems to manage real-time energy demand, preventing grid overload and ensuring stable, efficient energy use during peak times.",
          expanded: false,
        },
      ],
    },

    industryFaqs: {
      heading: "Energy Software Development FAQs",
      discription:
        "Here are answers to some of the most common questions about our energy software development services:",
      faqData: [
        {
          id: "collapseOne",
          question:
            "How can you help reduce energy consumption in our operations?",
          answer:
            "We offer energy analytics solutions that identify patterns in energy consumption, allowing you to optimize usage, reduce waste, and improve efficiency across your operations.",
        },
        {
          id: "collapseTwo",
          question: "What are smart grids and how do they work?",
          answer:
            "Smart grids are advanced electrical grids that use real-time data and automation to optimize energy distribution. They can detect issues in the grid, minimize downtime, and enable more efficient energy use by balancing supply and demand.",
        },
        {
          id: "collapseThree",
          question:
            "How can renewable energy be integrated into our existing systems?",
          answer:
            "We develop seamless integration solutions that allow renewable energy sources like solar, wind, and hydro to be efficiently connected to your existing energy infrastructure, helping you meet sustainability goals while maintaining grid stability.",
        },
        {
          id: "fourth",
          question: "What is energy consumption analytics?",
          answer:
            "Energy consumption analytics uses data to analyze energy usage patterns and identify areas for improvement. Our solutions provide actionable insights to reduce consumption and costs, enhancing overall operational efficiency.",
        },
        {
          id: "fifth",
          question:
            "Can your solutions help with energy efficiency in industrial operations?",
          answer:
            "Yes, our energy efficiency solutions are designed to optimize energy use across industrial systems. By leveraging predictive analytics and automation, we help businesses minimize waste and reduce their carbon footprint.",
        },
        {
          id: "sixth",
          question:
            "Do you offer solutions for managing demand-response in energy systems?",
          answer:
            "Yes, we provide demand-response systems that manage energy usage during peak demand times, preventing grid overloads and ensuring that energy is used efficiently when it's most needed.",
        },
      ],
    },
  },
  {
    id: 18,
    title: "Event",
    slug: "event",
    content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
    description:
      "We accelerate the EV revolution with smart charging networks, AI-powered energy management systems, and advanced telematics, driving the adoption of sustainable transportation solutions.",
    icon: "/images/industries-icons/Event.svg",
    image: "Event.png",
    bannerHeading: "Powering the Future with Sustainable Energy Solutions",
    bannerBtnText: "Let's Get Started",
    bannerImage: "energy-detail-banner.png",
    backgroundImage: `url(/images/industry-detial-banner-bg/Energy.png)`,

    discriptionSection: {
      heading: "Leading the Charge ",
      discription:
        "in Energy Innovation, The energy sector is evolving rapidly with the global push towards sustainability, efficiency, and renewable energy solutions. At Bitsclan, we empower energy providers with cutting-edge software solutions that streamline energy production, distribution, and consumption. Whether it's renewable energy management, smart grids, or energy analytics, we help businesses in the energy sector optimize operations, reduce waste, and meet sustainability goals.",

      cardHeading: "Energy Software Expertise",
      cardDiscripiton:
        "We offer innovative energy solutions to optimize efficiency and sustainability. Our Smart Grid Solutions enhance grid reliability and real-time monitoring. Renewable Energy Management integrates and manages renewable sources, while Energy Consumption Analytics provide insights to reduce costs. With Automated Billing and Reporting, we streamline financial processes, and our Energy Efficiency Solutions help businesses cut waste and improve performance.",
    },

    statsSection: {
      backgroundImage: "energy-counter-bg.jpg",
      heading: "Empowering the Energy ",
      greenPart: "Sector with Technology",
      discription:
        "Driving innovation in Energy Innovation with advanced software solutions for enhanced learning experiences.",
      subheading: "Your Top Energy",
      greenPartSubHeading: "Consulting Firm",

      stats: [
        {
          count: "1,500+",
          text: "Energy Solutions Deployed Globally",
        },
        {
          count: "250+",
          text: "Energy Providers Served",
        },
        {
          count: "98%",
          text: "Client Satisfaction Rate",
        },
        {
          count: "75%",
          text: "Reduction in Energy Waste for Clients",
        },
      ],
    },

    industryServices: {
      heading: "Energy Software Development Services",
      text: "Transform your energy business with innovative software solutions that optimize energy management, enhance efficiency, and provide scalable, secure services, driving operational excellence and sustainability.",

      industryServicesSlides: [
        {
          id: 1,
          borderClass: "border-1",
          icon: "/images/icon1.svg",
          heading: "Smart Grid Solutions",
          text: "Create intelligent, automated grids for efficient energy distribution and reduced downtime.",
          btnLink: "smart-grid-solutions",
          image: "image.png",
        },
        {
          id: 2,
          borderClass: "border-2",
          icon: "/images/icon2.svg",
          heading: "Renewable Energy Management",
          text: "Develop systems for managing renewable energy sources like solar, wind, and hydro.",
          btnLink: "renewable-energy-management",
          image: "image.png",
        },
        {
          id: 3,
          borderClass: "border-1",
          icon: "/images/arvr-icon.png",
          heading: "Energy Consumption Analytics",
          text: "Leverage big data to optimize energy consumption across industries and improve efficiency.",
          btnLink: "energy-consumption-analytics",
          image: "image.png",
        },
        {
          id: 4,
          borderClass: "border-2",
          icon: "/images/blockchain-icon.png",
          heading: "Automated Billing and Reporting",
          text: "Create automated billing systems for accurate, timely energy consumption payments.",
          btnLink: "automated-billing-and-reporting",
          image: "image.png",
        },
      ],
    },

    industryAdvertising: {
      heading: "Energy Software Standards and Compliance",
      discription:
        " Our energy software solutions comply with industry regulations and standards, such as ISO 50001, ANSI, and IEEE, ensuring that your operations meet global best practices. Additionally, our systems are built to adhere to environmental regulations, helping you meet sustainability goals.",
      image: "energy-add.jpg",
    },

    softwareSolutions: {
      heading: "Explore Our Energy Solutions",
      discription:
        "Discover cutting-edge energy solutions tailored to optimize resource distribution, enhance energy efficiency, and provide secure, scalable systems for sustainable business growth and operational success.",
      healthcareSolutionsData: [
        {
          id: 1,
          heading: "Smart Grid Management",
          text: "Optimize grid performance through real-time monitoring, automation, and predictive analytics to enhance efficiency and reliability.",
        },
        {
          id: 2,
          heading: "Renewable Energy Integration",
          text: "Seamlessly integrate renewable energy sources such as solar and wind into your existing grid for efficient, sustainable energy use.",
        },
        {
          id: 3,
          heading: "Energy Analytics and Forecasting",
          text: "Gain actionable insights into energy consumption patterns, forecast future demand, and optimize resource allocation for maximum efficiency.",
        },
        {
          id: 4,
          heading: "Demand Response Systems",
          text: "Implement systems that dynamically manage energy consumption based on real-time demand, preventing grid overload and enhancing reliability.",
        },
        {
          id: 5,
          heading: "Energy Efficiency Solutions",
          text: "Identify and capitalize on opportunities to reduce energy waste and improve operational efficiency, driving sustainability and cost savings.",
        },
      ],
    },

    industryExpertise: {
      heading: "Our Expertise: Innovative Energy Software Solutions",
      discription:
        "We specialize in delivering advanced energy software solutions that optimize grid performance, integrate renewable energy, streamline energy analytics, and enhance demand response systems for sustainable and efficient energy use.",
      accordionData: [
        {
          id: "collapseOne",
          title: "Smart Grid Management",
          content:
            "Optimize grid performance through real-time monitoring, automation, and predictive analytics to enhance energy distribution, minimize downtime, and improve operational efficiency.",
          expanded: true,
        },
        {
          id: "collapseTwo",
          title: "Renewable Energy Integration",
          content:
            "Seamlessly integrate renewable energy sources, such as solar and wind, into existing grid systems to maximize energy efficiency and sustainability.",
          expanded: false,
        },
        {
          id: "collapseThree",
          title: "Energy Analytics and Forecasting",
          content:
            "Leverage big data and advanced analytics to gain insights into energy usage patterns, forecast future demands, and optimize resource allocation for cost-effective operations.",
          expanded: false,
        },
        {
          id: "collapseFour",
          title: "Demand Response Systems",
          content:
            "Implement systems to manage real-time energy demand, preventing grid overload and ensuring stable, efficient energy use during peak times.",
          expanded: false,
        },
      ],
    },

    industryFaqs: {
      heading: "Energy Software Development FAQs",
      discription:
        "Here are answers to some of the most common questions about our energy software development services:",
      faqData: [
        {
          id: "collapseOne",
          question:
            "How can you help reduce energy consumption in our operations?",
          answer:
            "We offer energy analytics solutions that identify patterns in energy consumption, allowing you to optimize usage, reduce waste, and improve efficiency across your operations.",
        },
        {
          id: "collapseTwo",
          question: "What are smart grids and how do they work?",
          answer:
            "Smart grids are advanced electrical grids that use real-time data and automation to optimize energy distribution. They can detect issues in the grid, minimize downtime, and enable more efficient energy use by balancing supply and demand.",
        },
        {
          id: "collapseThree",
          question:
            "How can renewable energy be integrated into our existing systems?",
          answer:
            "We develop seamless integration solutions that allow renewable energy sources like solar, wind, and hydro to be efficiently connected to your existing energy infrastructure, helping you meet sustainability goals while maintaining grid stability.",
        },
        {
          id: "fourth",
          question: "What is energy consumption analytics?",
          answer:
            "Energy consumption analytics uses data to analyze energy usage patterns and identify areas for improvement. Our solutions provide actionable insights to reduce consumption and costs, enhancing overall operational efficiency.",
        },
        {
          id: "fifth",
          question:
            "Can your solutions help with energy efficiency in industrial operations?",
          answer:
            "Yes, our energy efficiency solutions are designed to optimize energy use across industrial systems. By leveraging predictive analytics and automation, we help businesses minimize waste and reduce their carbon footprint.",
        },
        {
          id: "sixth",
          question:
            "Do you offer solutions for managing demand-response in energy systems?",
          answer:
            "Yes, we provide demand-response systems that manage energy usage during peak demand times, preventing grid overloads and ensuring that energy is used efficiently when it's most needed.",
        },
      ],
    },
  },
  {
    id: 19,
    title: "Government",
    slug: "government",
    content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
    description:
      "We modernize government operations with secure citizen engagement platforms, AI-driven data analysis, and compliance tools, ensuring transparency, efficiency, and better public service delivery.",
    icon: "/images/industries-icons/Government.svg",
    image: "Government.png",
    bannerHeading: "Powering the Future with Sustainable Energy Solutions",
    bannerBtnText: "Let's Get Started",
    bannerImage: "energy-detail-banner.png",
    backgroundImage: `url(/images/industry-detial-banner-bg/Energy.png)`,

    discriptionSection: {
      heading: "Leading the Charge ",
      discription:
        "in Energy Innovation, The energy sector is evolving rapidly with the global push towards sustainability, efficiency, and renewable energy solutions. At Bitsclan, we empower energy providers with cutting-edge software solutions that streamline energy production, distribution, and consumption. Whether it's renewable energy management, smart grids, or energy analytics, we help businesses in the energy sector optimize operations, reduce waste, and meet sustainability goals.",

      cardHeading: "Energy Software Expertise",
      cardDiscripiton:
        "We offer innovative energy solutions to optimize efficiency and sustainability. Our Smart Grid Solutions enhance grid reliability and real-time monitoring. Renewable Energy Management integrates and manages renewable sources, while Energy Consumption Analytics provide insights to reduce costs. With Automated Billing and Reporting, we streamline financial processes, and our Energy Efficiency Solutions help businesses cut waste and improve performance.",
    },

    statsSection: {
      backgroundImage: "energy-counter-bg.jpg",
      heading: "Empowering the Energy ",
      greenPart: "Sector with Technology",
      discription:
        "Driving innovation in Energy Innovation with advanced software solutions for enhanced learning experiences.",
      subheading: "Your Top Energy",
      greenPartSubHeading: "Consulting Firm",

      stats: [
        {
          count: "1,500+",
          text: "Energy Solutions Deployed Globally",
        },
        {
          count: "250+",
          text: "Energy Providers Served",
        },
        {
          count: "98%",
          text: "Client Satisfaction Rate",
        },
        {
          count: "75%",
          text: "Reduction in Energy Waste for Clients",
        },
      ],
    },

    industryServices: {
      heading: "Energy Software Development Services",
      text: "Transform your energy business with innovative software solutions that optimize energy management, enhance efficiency, and provide scalable, secure services, driving operational excellence and sustainability.",

      industryServicesSlides: [
        {
          id: 1,
          borderClass: "border-1",
          icon: "/images/icon1.svg",
          heading: "Smart Grid Solutions",
          text: "Create intelligent, automated grids for efficient energy distribution and reduced downtime.",
          btnLink: "smart-grid-solutions",
          image: "image.png",
        },
        {
          id: 2,
          borderClass: "border-2",
          icon: "/images/icon2.svg",
          heading: "Renewable Energy Management",
          text: "Develop systems for managing renewable energy sources like solar, wind, and hydro.",
          btnLink: "renewable-energy-management",
          image: "image.png",
        },
        {
          id: 3,
          borderClass: "border-1",
          icon: "/images/arvr-icon.png",
          heading: "Energy Consumption Analytics",
          text: "Leverage big data to optimize energy consumption across industries and improve efficiency.",
          btnLink: "energy-consumption-analytics",
          image: "image.png",
        },
        {
          id: 4,
          borderClass: "border-2",
          icon: "/images/blockchain-icon.png",
          heading: "Automated Billing and Reporting",
          text: "Create automated billing systems for accurate, timely energy consumption payments.",
          btnLink: "automated-billing-and-reporting",
          image: "image.png",
        },
      ],
    },

    industryAdvertising: {
      heading: "Energy Software Standards and Compliance",
      discription:
        " Our energy software solutions comply with industry regulations and standards, such as ISO 50001, ANSI, and IEEE, ensuring that your operations meet global best practices. Additionally, our systems are built to adhere to environmental regulations, helping you meet sustainability goals.",
      image: "energy-add.jpg",
    },

    softwareSolutions: {
      heading: "Explore Our Energy Solutions",
      discription:
        "Discover cutting-edge energy solutions tailored to optimize resource distribution, enhance energy efficiency, and provide secure, scalable systems for sustainable business growth and operational success.",
      healthcareSolutionsData: [
        {
          id: 1,
          heading: "Smart Grid Management",
          text: "Optimize grid performance through real-time monitoring, automation, and predictive analytics to enhance efficiency and reliability.",
        },
        {
          id: 2,
          heading: "Renewable Energy Integration",
          text: "Seamlessly integrate renewable energy sources such as solar and wind into your existing grid for efficient, sustainable energy use.",
        },
        {
          id: 3,
          heading: "Energy Analytics and Forecasting",
          text: "Gain actionable insights into energy consumption patterns, forecast future demand, and optimize resource allocation for maximum efficiency.",
        },
        {
          id: 4,
          heading: "Demand Response Systems",
          text: "Implement systems that dynamically manage energy consumption based on real-time demand, preventing grid overload and enhancing reliability.",
        },
        {
          id: 5,
          heading: "Energy Efficiency Solutions",
          text: "Identify and capitalize on opportunities to reduce energy waste and improve operational efficiency, driving sustainability and cost savings.",
        },
      ],
    },

    industryExpertise: {
      heading: "Our Expertise: Innovative Energy Software Solutions",
      discription:
        "We specialize in delivering advanced energy software solutions that optimize grid performance, integrate renewable energy, streamline energy analytics, and enhance demand response systems for sustainable and efficient energy use.",
      accordionData: [
        {
          id: "collapseOne",
          title: "Smart Grid Management",
          content:
            "Optimize grid performance through real-time monitoring, automation, and predictive analytics to enhance energy distribution, minimize downtime, and improve operational efficiency.",
          expanded: true,
        },
        {
          id: "collapseTwo",
          title: "Renewable Energy Integration",
          content:
            "Seamlessly integrate renewable energy sources, such as solar and wind, into existing grid systems to maximize energy efficiency and sustainability.",
          expanded: false,
        },
        {
          id: "collapseThree",
          title: "Energy Analytics and Forecasting",
          content:
            "Leverage big data and advanced analytics to gain insights into energy usage patterns, forecast future demands, and optimize resource allocation for cost-effective operations.",
          expanded: false,
        },
        {
          id: "collapseFour",
          title: "Demand Response Systems",
          content:
            "Implement systems to manage real-time energy demand, preventing grid overload and ensuring stable, efficient energy use during peak times.",
          expanded: false,
        },
      ],
    },

    industryFaqs: {
      heading: "Energy Software Development FAQs",
      discription:
        "Here are answers to some of the most common questions about our energy software development services:",
      faqData: [
        {
          id: "collapseOne",
          question:
            "How can you help reduce energy consumption in our operations?",
          answer:
            "We offer energy analytics solutions that identify patterns in energy consumption, allowing you to optimize usage, reduce waste, and improve efficiency across your operations.",
        },
        {
          id: "collapseTwo",
          question: "What are smart grids and how do they work?",
          answer:
            "Smart grids are advanced electrical grids that use real-time data and automation to optimize energy distribution. They can detect issues in the grid, minimize downtime, and enable more efficient energy use by balancing supply and demand.",
        },
        {
          id: "collapseThree",
          question:
            "How can renewable energy be integrated into our existing systems?",
          answer:
            "We develop seamless integration solutions that allow renewable energy sources like solar, wind, and hydro to be efficiently connected to your existing energy infrastructure, helping you meet sustainability goals while maintaining grid stability.",
        },
        {
          id: "fourth",
          question: "What is energy consumption analytics?",
          answer:
            "Energy consumption analytics uses data to analyze energy usage patterns and identify areas for improvement. Our solutions provide actionable insights to reduce consumption and costs, enhancing overall operational efficiency.",
        },
        {
          id: "fifth",
          question:
            "Can your solutions help with energy efficiency in industrial operations?",
          answer:
            "Yes, our energy efficiency solutions are designed to optimize energy use across industrial systems. By leveraging predictive analytics and automation, we help businesses minimize waste and reduce their carbon footprint.",
        },
        {
          id: "sixth",
          question:
            "Do you offer solutions for managing demand-response in energy systems?",
          answer:
            "Yes, we provide demand-response systems that manage energy usage during peak demand times, preventing grid overloads and ensuring that energy is used efficiently when it's most needed.",
        },
      ],
    },
  },
  {
    id: 20,
    title: "News",
    slug: "news",
    content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
    description:
      "We empower news organizations with AI-driven content generation, real-time analytics for audience engagement, and blockchain-secured publishing platforms, ensuring accurate and timely news delivery.",
    icon: "/images/industries-icons/News.svg",
    image: "News.png",
    bannerHeading: "Powering the Future with Sustainable Energy Solutions",
    bannerBtnText: "Let's Get Started",
    bannerImage: "energy-detail-banner.png",
    backgroundImage: `url(/images/industry-detial-banner-bg/Energy.png)`,

    discriptionSection: {
      heading: "Leading the Charge ",
      discription:
        "in Energy Innovation, The energy sector is evolving rapidly with the global push towards sustainability, efficiency, and renewable energy solutions. At Bitsclan, we empower energy providers with cutting-edge software solutions that streamline energy production, distribution, and consumption. Whether it's renewable energy management, smart grids, or energy analytics, we help businesses in the energy sector optimize operations, reduce waste, and meet sustainability goals.",

      cardHeading: "Energy Software Expertise",
      cardDiscripiton:
        "We offer innovative energy solutions to optimize efficiency and sustainability. Our Smart Grid Solutions enhance grid reliability and real-time monitoring. Renewable Energy Management integrates and manages renewable sources, while Energy Consumption Analytics provide insights to reduce costs. With Automated Billing and Reporting, we streamline financial processes, and our Energy Efficiency Solutions help businesses cut waste and improve performance.",
    },

    statsSection: {
      backgroundImage: "energy-counter-bg.jpg",
      heading: "Empowering the Energy ",
      greenPart: "Sector with Technology",
      discription:
        "Driving innovation in Energy Innovation with advanced software solutions for enhanced learning experiences.",
      subheading: "Your Top Energy",
      greenPartSubHeading: "Consulting Firm",

      stats: [
        {
          count: "1,500+",
          text: "Energy Solutions Deployed Globally",
        },
        {
          count: "250+",
          text: "Energy Providers Served",
        },
        {
          count: "98%",
          text: "Client Satisfaction Rate",
        },
        {
          count: "75%",
          text: "Reduction in Energy Waste for Clients",
        },
      ],
    },

    industryServices: {
      heading: "Energy Software Development Services",
      text: "Transform your energy business with innovative software solutions that optimize energy management, enhance efficiency, and provide scalable, secure services, driving operational excellence and sustainability.",

      industryServicesSlides: [
        {
          id: 1,
          borderClass: "border-1",
          icon: "/images/icon1.svg",
          heading: "Smart Grid Solutions",
          text: "Create intelligent, automated grids for efficient energy distribution and reduced downtime.",
          btnLink: "smart-grid-solutions",
          image: "image.png",
        },
        {
          id: 2,
          borderClass: "border-2",
          icon: "/images/icon2.svg",
          heading: "Renewable Energy Management",
          text: "Develop systems for managing renewable energy sources like solar, wind, and hydro.",
          btnLink: "renewable-energy-management",
          image: "image.png",
        },
        {
          id: 3,
          borderClass: "border-1",
          icon: "/images/arvr-icon.png",
          heading: "Energy Consumption Analytics",
          text: "Leverage big data to optimize energy consumption across industries and improve efficiency.",
          btnLink: "energy-consumption-analytics",
          image: "image.png",
        },
        {
          id: 4,
          borderClass: "border-2",
          icon: "/images/blockchain-icon.png",
          heading: "Automated Billing and Reporting",
          text: "Create automated billing systems for accurate, timely energy consumption payments.",
          btnLink: "automated-billing-and-reporting",
          image: "image.png",
        },
      ],
    },

    industryAdvertising: {
      heading: "Energy Software Standards and Compliance",
      discription:
        " Our energy software solutions comply with industry regulations and standards, such as ISO 50001, ANSI, and IEEE, ensuring that your operations meet global best practices. Additionally, our systems are built to adhere to environmental regulations, helping you meet sustainability goals.",
      image: "energy-add.jpg",
    },

    softwareSolutions: {
      heading: "Explore Our Energy Solutions",
      discription:
        "Discover cutting-edge energy solutions tailored to optimize resource distribution, enhance energy efficiency, and provide secure, scalable systems for sustainable business growth and operational success.",
      healthcareSolutionsData: [
        {
          id: 1,
          heading: "Smart Grid Management",
          text: "Optimize grid performance through real-time monitoring, automation, and predictive analytics to enhance efficiency and reliability.",
        },
        {
          id: 2,
          heading: "Renewable Energy Integration",
          text: "Seamlessly integrate renewable energy sources such as solar and wind into your existing grid for efficient, sustainable energy use.",
        },
        {
          id: 3,
          heading: "Energy Analytics and Forecasting",
          text: "Gain actionable insights into energy consumption patterns, forecast future demand, and optimize resource allocation for maximum efficiency.",
        },
        {
          id: 4,
          heading: "Demand Response Systems",
          text: "Implement systems that dynamically manage energy consumption based on real-time demand, preventing grid overload and enhancing reliability.",
        },
        {
          id: 5,
          heading: "Energy Efficiency Solutions",
          text: "Identify and capitalize on opportunities to reduce energy waste and improve operational efficiency, driving sustainability and cost savings.",
        },
      ],
    },

    industryExpertise: {
      heading: "Our Expertise: Innovative Energy Software Solutions",
      discription:
        "We specialize in delivering advanced energy software solutions that optimize grid performance, integrate renewable energy, streamline energy analytics, and enhance demand response systems for sustainable and efficient energy use.",
      accordionData: [
        {
          id: "collapseOne",
          title: "Smart Grid Management",
          content:
            "Optimize grid performance through real-time monitoring, automation, and predictive analytics to enhance energy distribution, minimize downtime, and improve operational efficiency.",
          expanded: true,
        },
        {
          id: "collapseTwo",
          title: "Renewable Energy Integration",
          content:
            "Seamlessly integrate renewable energy sources, such as solar and wind, into existing grid systems to maximize energy efficiency and sustainability.",
          expanded: false,
        },
        {
          id: "collapseThree",
          title: "Energy Analytics and Forecasting",
          content:
            "Leverage big data and advanced analytics to gain insights into energy usage patterns, forecast future demands, and optimize resource allocation for cost-effective operations.",
          expanded: false,
        },
        {
          id: "collapseFour",
          title: "Demand Response Systems",
          content:
            "Implement systems to manage real-time energy demand, preventing grid overload and ensuring stable, efficient energy use during peak times.",
          expanded: false,
        },
      ],
    },

    industryFaqs: {
      heading: "Energy Software Development FAQs",
      discription:
        "Here are answers to some of the most common questions about our energy software development services:",
      faqData: [
        {
          id: "collapseOne",
          question:
            "How can you help reduce energy consumption in our operations?",
          answer:
            "We offer energy analytics solutions that identify patterns in energy consumption, allowing you to optimize usage, reduce waste, and improve efficiency across your operations.",
        },
        {
          id: "collapseTwo",
          question: "What are smart grids and how do they work?",
          answer:
            "Smart grids are advanced electrical grids that use real-time data and automation to optimize energy distribution. They can detect issues in the grid, minimize downtime, and enable more efficient energy use by balancing supply and demand.",
        },
        {
          id: "collapseThree",
          question:
            "How can renewable energy be integrated into our existing systems?",
          answer:
            "We develop seamless integration solutions that allow renewable energy sources like solar, wind, and hydro to be efficiently connected to your existing energy infrastructure, helping you meet sustainability goals while maintaining grid stability.",
        },
        {
          id: "fourth",
          question: "What is energy consumption analytics?",
          answer:
            "Energy consumption analytics uses data to analyze energy usage patterns and identify areas for improvement. Our solutions provide actionable insights to reduce consumption and costs, enhancing overall operational efficiency.",
        },
        {
          id: "fifth",
          question:
            "Can your solutions help with energy efficiency in industrial operations?",
          answer:
            "Yes, our energy efficiency solutions are designed to optimize energy use across industrial systems. By leveraging predictive analytics and automation, we help businesses minimize waste and reduce their carbon footprint.",
        },
        {
          id: "sixth",
          question:
            "Do you offer solutions for managing demand-response in energy systems?",
          answer:
            "Yes, we provide demand-response systems that manage energy usage during peak demand times, preventing grid overloads and ensuring that energy is used efficiently when it's most needed.",
        },
      ],
    },
  },
  {
    id: 21,
    title: "On Demand",
    slug: "on-demand",
    content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
    description:
      "We empower the on-demand industry with scalable platforms, real-time tracking systems, and AI-driven personalized experiences, ensuring fast, efficient, and seamless services for customers whenever they need them.",
    icon: "/images/industries-icons/On-Demand.svg",
    image: "On-Demand.png",
    bannerHeading: "Powering the Future with Sustainable Energy Solutions",
    bannerBtnText: "Let's Get Started",
    bannerImage: "energy-detail-banner.png",
    backgroundImage: `url(/images/industry-detial-banner-bg/Energy.png)`,

    discriptionSection: {
      heading: "Leading the Charge ",
      discription:
        "in Energy Innovation, The energy sector is evolving rapidly with the global push towards sustainability, efficiency, and renewable energy solutions. At Bitsclan, we empower energy providers with cutting-edge software solutions that streamline energy production, distribution, and consumption. Whether it's renewable energy management, smart grids, or energy analytics, we help businesses in the energy sector optimize operations, reduce waste, and meet sustainability goals.",

      cardHeading: "Energy Software Expertise",
      cardDiscripiton:
        "We offer innovative energy solutions to optimize efficiency and sustainability. Our Smart Grid Solutions enhance grid reliability and real-time monitoring. Renewable Energy Management integrates and manages renewable sources, while Energy Consumption Analytics provide insights to reduce costs. With Automated Billing and Reporting, we streamline financial processes, and our Energy Efficiency Solutions help businesses cut waste and improve performance.",
    },

    statsSection: {
      backgroundImage: "energy-counter-bg.jpg",
      heading: "Empowering the Energy ",
      greenPart: "Sector with Technology",
      discription:
        "Driving innovation in Energy Innovation with advanced software solutions for enhanced learning experiences.",
      subheading: "Your Top Energy",
      greenPartSubHeading: "Consulting Firm",

      stats: [
        {
          count: "1,500+",
          text: "Energy Solutions Deployed Globally",
        },
        {
          count: "250+",
          text: "Energy Providers Served",
        },
        {
          count: "98%",
          text: "Client Satisfaction Rate",
        },
        {
          count: "75%",
          text: "Reduction in Energy Waste for Clients",
        },
      ],
    },

    industryServices: {
      heading: "Energy Software Development Services",
      text: "Transform your energy business with innovative software solutions that optimize energy management, enhance efficiency, and provide scalable, secure services, driving operational excellence and sustainability.",

      industryServicesSlides: [
        {
          id: 1,
          borderClass: "border-1",
          icon: "/images/icon1.svg",
          heading: "Smart Grid Solutions",
          text: "Create intelligent, automated grids for efficient energy distribution and reduced downtime.",
          btnLink: "smart-grid-solutions",
          image: "image.png",
        },
        {
          id: 2,
          borderClass: "border-2",
          icon: "/images/icon2.svg",
          heading: "Renewable Energy Management",
          text: "Develop systems for managing renewable energy sources like solar, wind, and hydro.",
          btnLink: "renewable-energy-management",
          image: "image.png",
        },
        {
          id: 3,
          borderClass: "border-1",
          icon: "/images/arvr-icon.png",
          heading: "Energy Consumption Analytics",
          text: "Leverage big data to optimize energy consumption across industries and improve efficiency.",
          btnLink: "energy-consumption-analytics",
          image: "image.png",
        },
        {
          id: 4,
          borderClass: "border-2",
          icon: "/images/blockchain-icon.png",
          heading: "Automated Billing and Reporting",
          text: "Create automated billing systems for accurate, timely energy consumption payments.",
          btnLink: "automated-billing-and-reporting",
          image: "image.png",
        },
      ],
    },

    industryAdvertising: {
      heading: "Energy Software Standards and Compliance",
      discription:
        " Our energy software solutions comply with industry regulations and standards, such as ISO 50001, ANSI, and IEEE, ensuring that your operations meet global best practices. Additionally, our systems are built to adhere to environmental regulations, helping you meet sustainability goals.",
      image: "energy-add.jpg",
    },

    softwareSolutions: {
      heading: "Explore Our Energy Solutions",
      discription:
        "Discover cutting-edge energy solutions tailored to optimize resource distribution, enhance energy efficiency, and provide secure, scalable systems for sustainable business growth and operational success.",
      healthcareSolutionsData: [
        {
          id: 1,
          heading: "Smart Grid Management",
          text: "Optimize grid performance through real-time monitoring, automation, and predictive analytics to enhance efficiency and reliability.",
        },
        {
          id: 2,
          heading: "Renewable Energy Integration",
          text: "Seamlessly integrate renewable energy sources such as solar and wind into your existing grid for efficient, sustainable energy use.",
        },
        {
          id: 3,
          heading: "Energy Analytics and Forecasting",
          text: "Gain actionable insights into energy consumption patterns, forecast future demand, and optimize resource allocation for maximum efficiency.",
        },
        {
          id: 4,
          heading: "Demand Response Systems",
          text: "Implement systems that dynamically manage energy consumption based on real-time demand, preventing grid overload and enhancing reliability.",
        },
        {
          id: 5,
          heading: "Energy Efficiency Solutions",
          text: "Identify and capitalize on opportunities to reduce energy waste and improve operational efficiency, driving sustainability and cost savings.",
        },
      ],
    },

    industryExpertise: {
      heading: "Our Expertise: Innovative Energy Software Solutions",
      discription:
        "We specialize in delivering advanced energy software solutions that optimize grid performance, integrate renewable energy, streamline energy analytics, and enhance demand response systems for sustainable and efficient energy use.",
      accordionData: [
        {
          id: "collapseOne",
          title: "Smart Grid Management",
          content:
            "Optimize grid performance through real-time monitoring, automation, and predictive analytics to enhance energy distribution, minimize downtime, and improve operational efficiency.",
          expanded: true,
        },
        {
          id: "collapseTwo",
          title: "Renewable Energy Integration",
          content:
            "Seamlessly integrate renewable energy sources, such as solar and wind, into existing grid systems to maximize energy efficiency and sustainability.",
          expanded: false,
        },
        {
          id: "collapseThree",
          title: "Energy Analytics and Forecasting",
          content:
            "Leverage big data and advanced analytics to gain insights into energy usage patterns, forecast future demands, and optimize resource allocation for cost-effective operations.",
          expanded: false,
        },
        {
          id: "collapseFour",
          title: "Demand Response Systems",
          content:
            "Implement systems to manage real-time energy demand, preventing grid overload and ensuring stable, efficient energy use during peak times.",
          expanded: false,
        },
      ],
    },

    industryFaqs: {
      heading: "Energy Software Development FAQs",
      discription:
        "Here are answers to some of the most common questions about our energy software development services:",
      faqData: [
        {
          id: "collapseOne",
          question:
            "How can you help reduce energy consumption in our operations?",
          answer:
            "We offer energy analytics solutions that identify patterns in energy consumption, allowing you to optimize usage, reduce waste, and improve efficiency across your operations.",
        },
        {
          id: "collapseTwo",
          question: "What are smart grids and how do they work?",
          answer:
            "Smart grids are advanced electrical grids that use real-time data and automation to optimize energy distribution. They can detect issues in the grid, minimize downtime, and enable more efficient energy use by balancing supply and demand.",
        },
        {
          id: "collapseThree",
          question:
            "How can renewable energy be integrated into our existing systems?",
          answer:
            "We develop seamless integration solutions that allow renewable energy sources like solar, wind, and hydro to be efficiently connected to your existing energy infrastructure, helping you meet sustainability goals while maintaining grid stability.",
        },
        {
          id: "fourth",
          question: "What is energy consumption analytics?",
          answer:
            "Energy consumption analytics uses data to analyze energy usage patterns and identify areas for improvement. Our solutions provide actionable insights to reduce consumption and costs, enhancing overall operational efficiency.",
        },
        {
          id: "fifth",
          question:
            "Can your solutions help with energy efficiency in industrial operations?",
          answer:
            "Yes, our energy efficiency solutions are designed to optimize energy use across industrial systems. By leveraging predictive analytics and automation, we help businesses minimize waste and reduce their carbon footprint.",
        },
        {
          id: "sixth",
          question:
            "Do you offer solutions for managing demand-response in energy systems?",
          answer:
            "Yes, we provide demand-response systems that manage energy usage during peak demand times, preventing grid overloads and ensuring that energy is used efficiently when it's most needed.",
        },
      ],
    },
  },
  {
    id: 22,
    title: "OTT (Over-The-Top)",
    slug: "ott",
    content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
    description:
      "We drive the future of OTT with personalized content recommendations, AI-powered viewer analytics, and secure streaming solutions, delivering seamless, on-demand entertainment.",
    icon: "/images/industries-icons/OTT (Over-The-Top).svg",
    image: "OTT (Over-The-Top).png",
    bannerHeading: "Powering the Future with Sustainable Energy Solutions",
    bannerBtnText: "Let's Get Started",
    bannerImage: "energy-detail-banner.png",
    backgroundImage: `url(/images/industry-detial-banner-bg/Energy.png)`,

    discriptionSection: {
      heading: "Leading the Charge ",
      discription:
        "in Energy Innovation, The energy sector is evolving rapidly with the global push towards sustainability, efficiency, and renewable energy solutions. At Bitsclan, we empower energy providers with cutting-edge software solutions that streamline energy production, distribution, and consumption. Whether it's renewable energy management, smart grids, or energy analytics, we help businesses in the energy sector optimize operations, reduce waste, and meet sustainability goals.",

      cardHeading: "Energy Software Expertise",
      cardDiscripiton:
        "We offer innovative energy solutions to optimize efficiency and sustainability. Our Smart Grid Solutions enhance grid reliability and real-time monitoring. Renewable Energy Management integrates and manages renewable sources, while Energy Consumption Analytics provide insights to reduce costs. With Automated Billing and Reporting, we streamline financial processes, and our Energy Efficiency Solutions help businesses cut waste and improve performance.",
    },

    statsSection: {
      backgroundImage: "energy-counter-bg.jpg",
      heading: "Empowering the Energy ",
      greenPart: "Sector with Technology",
      discription:
        "Driving innovation in Energy Innovation with advanced software solutions for enhanced learning experiences.",
      subheading: "Your Top Energy",
      greenPartSubHeading: "Consulting Firm",

      stats: [
        {
          count: "1,500+",
          text: "Energy Solutions Deployed Globally",
        },
        {
          count: "250+",
          text: "Energy Providers Served",
        },
        {
          count: "98%",
          text: "Client Satisfaction Rate",
        },
        {
          count: "75%",
          text: "Reduction in Energy Waste for Clients",
        },
      ],
    },

    industryServices: {
      heading: "Energy Software Development Services",
      text: "Transform your energy business with innovative software solutions that optimize energy management, enhance efficiency, and provide scalable, secure services, driving operational excellence and sustainability.",

      industryServicesSlides: [
        {
          id: 1,
          borderClass: "border-1",
          icon: "/images/icon1.svg",
          heading: "Smart Grid Solutions",
          text: "Create intelligent, automated grids for efficient energy distribution and reduced downtime.",
          btnLink: "smart-grid-solutions",
          image: "image.png",
        },
        {
          id: 2,
          borderClass: "border-2",
          icon: "/images/icon2.svg",
          heading: "Renewable Energy Management",
          text: "Develop systems for managing renewable energy sources like solar, wind, and hydro.",
          btnLink: "renewable-energy-management",
          image: "image.png",
        },
        {
          id: 3,
          borderClass: "border-1",
          icon: "/images/arvr-icon.png",
          heading: "Energy Consumption Analytics",
          text: "Leverage big data to optimize energy consumption across industries and improve efficiency.",
          btnLink: "energy-consumption-analytics",
          image: "image.png",
        },
        {
          id: 4,
          borderClass: "border-2",
          icon: "/images/blockchain-icon.png",
          heading: "Automated Billing and Reporting",
          text: "Create automated billing systems for accurate, timely energy consumption payments.",
          btnLink: "automated-billing-and-reporting",
          image: "image.png",
        },
      ],
    },

    industryAdvertising: {
      heading: "Energy Software Standards and Compliance",
      discription:
        " Our energy software solutions comply with industry regulations and standards, such as ISO 50001, ANSI, and IEEE, ensuring that your operations meet global best practices. Additionally, our systems are built to adhere to environmental regulations, helping you meet sustainability goals.",
      image: "energy-add.jpg",
    },

    softwareSolutions: {
      heading: "Explore Our Energy Solutions",
      discription:
        "Discover cutting-edge energy solutions tailored to optimize resource distribution, enhance energy efficiency, and provide secure, scalable systems for sustainable business growth and operational success.",
      healthcareSolutionsData: [
        {
          id: 1,
          heading: "Smart Grid Management",
          text: "Optimize grid performance through real-time monitoring, automation, and predictive analytics to enhance efficiency and reliability.",
        },
        {
          id: 2,
          heading: "Renewable Energy Integration",
          text: "Seamlessly integrate renewable energy sources such as solar and wind into your existing grid for efficient, sustainable energy use.",
        },
        {
          id: 3,
          heading: "Energy Analytics and Forecasting",
          text: "Gain actionable insights into energy consumption patterns, forecast future demand, and optimize resource allocation for maximum efficiency.",
        },
        {
          id: 4,
          heading: "Demand Response Systems",
          text: "Implement systems that dynamically manage energy consumption based on real-time demand, preventing grid overload and enhancing reliability.",
        },
        {
          id: 5,
          heading: "Energy Efficiency Solutions",
          text: "Identify and capitalize on opportunities to reduce energy waste and improve operational efficiency, driving sustainability and cost savings.",
        },
      ],
    },

    industryExpertise: {
      heading: "Our Expertise: Innovative Energy Software Solutions",
      discription:
        "We specialize in delivering advanced energy software solutions that optimize grid performance, integrate renewable energy, streamline energy analytics, and enhance demand response systems for sustainable and efficient energy use.",
      accordionData: [
        {
          id: "collapseOne",
          title: "Smart Grid Management",
          content:
            "Optimize grid performance through real-time monitoring, automation, and predictive analytics to enhance energy distribution, minimize downtime, and improve operational efficiency.",
          expanded: true,
        },
        {
          id: "collapseTwo",
          title: "Renewable Energy Integration",
          content:
            "Seamlessly integrate renewable energy sources, such as solar and wind, into existing grid systems to maximize energy efficiency and sustainability.",
          expanded: false,
        },
        {
          id: "collapseThree",
          title: "Energy Analytics and Forecasting",
          content:
            "Leverage big data and advanced analytics to gain insights into energy usage patterns, forecast future demands, and optimize resource allocation for cost-effective operations.",
          expanded: false,
        },
        {
          id: "collapseFour",
          title: "Demand Response Systems",
          content:
            "Implement systems to manage real-time energy demand, preventing grid overload and ensuring stable, efficient energy use during peak times.",
          expanded: false,
        },
      ],
    },

    industryFaqs: {
      heading: "Energy Software Development FAQs",
      discription:
        "Here are answers to some of the most common questions about our energy software development services:",
      faqData: [
        {
          id: "collapseOne",
          question:
            "How can you help reduce energy consumption in our operations?",
          answer:
            "We offer energy analytics solutions that identify patterns in energy consumption, allowing you to optimize usage, reduce waste, and improve efficiency across your operations.",
        },
        {
          id: "collapseTwo",
          question: "What are smart grids and how do they work?",
          answer:
            "Smart grids are advanced electrical grids that use real-time data and automation to optimize energy distribution. They can detect issues in the grid, minimize downtime, and enable more efficient energy use by balancing supply and demand.",
        },
        {
          id: "collapseThree",
          question:
            "How can renewable energy be integrated into our existing systems?",
          answer:
            "We develop seamless integration solutions that allow renewable energy sources like solar, wind, and hydro to be efficiently connected to your existing energy infrastructure, helping you meet sustainability goals while maintaining grid stability.",
        },
        {
          id: "fourth",
          question: "What is energy consumption analytics?",
          answer:
            "Energy consumption analytics uses data to analyze energy usage patterns and identify areas for improvement. Our solutions provide actionable insights to reduce consumption and costs, enhancing overall operational efficiency.",
        },
        {
          id: "fifth",
          question:
            "Can your solutions help with energy efficiency in industrial operations?",
          answer:
            "Yes, our energy efficiency solutions are designed to optimize energy use across industrial systems. By leveraging predictive analytics and automation, we help businesses minimize waste and reduce their carbon footprint.",
        },
        {
          id: "sixth",
          question:
            "Do you offer solutions for managing demand-response in energy systems?",
          answer:
            "Yes, we provide demand-response systems that manage energy usage during peak demand times, preventing grid overloads and ensuring that energy is used efficiently when it's most needed.",
        },
      ],
    },
  },
  {
    id: 23,
    title: "Restaurant",
    slug: "restaurant",
    content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
    description:
      "We transform the restaurant industry with AI-powered menu optimization, contactless payment systems, and personalized dining experiences that cater to modern consumer preferences.",
    icon: "/images/industries-icons/Restaurant.svg",
    image: "Restaurant.png",
    bannerHeading: "Powering the Future with Sustainable Energy Solutions",
    bannerBtnText: "Let's Get Started",
    bannerImage: "energy-detail-banner.png",
    backgroundImage: `url(/images/industry-detial-banner-bg/Energy.png)`,

    discriptionSection: {
      heading: "Leading the Charge ",
      discription:
        "in Energy Innovation, The energy sector is evolving rapidly with the global push towards sustainability, efficiency, and renewable energy solutions. At Bitsclan, we empower energy providers with cutting-edge software solutions that streamline energy production, distribution, and consumption. Whether it's renewable energy management, smart grids, or energy analytics, we help businesses in the energy sector optimize operations, reduce waste, and meet sustainability goals.",

      cardHeading: "Energy Software Expertise",
      cardDiscripiton:
        "We offer innovative energy solutions to optimize efficiency and sustainability. Our Smart Grid Solutions enhance grid reliability and real-time monitoring. Renewable Energy Management integrates and manages renewable sources, while Energy Consumption Analytics provide insights to reduce costs. With Automated Billing and Reporting, we streamline financial processes, and our Energy Efficiency Solutions help businesses cut waste and improve performance.",
    },

    statsSection: {
      backgroundImage: "energy-counter-bg.jpg",
      heading: "Empowering the Energy ",
      greenPart: "Sector with Technology",
      discription:
        "Driving innovation in Energy Innovation with advanced software solutions for enhanced learning experiences.",
      subheading: "Your Top Energy",
      greenPartSubHeading: "Consulting Firm",

      stats: [
        {
          count: "1,500+",
          text: "Energy Solutions Deployed Globally",
        },
        {
          count: "250+",
          text: "Energy Providers Served",
        },
        {
          count: "98%",
          text: "Client Satisfaction Rate",
        },
        {
          count: "75%",
          text: "Reduction in Energy Waste for Clients",
        },
      ],
    },

    industryServices: {
      heading: "Energy Software Development Services",
      text: "Transform your energy business with innovative software solutions that optimize energy management, enhance efficiency, and provide scalable, secure services, driving operational excellence and sustainability.",

      industryServicesSlides: [
        {
          id: 1,
          borderClass: "border-1",
          icon: "/images/icon1.svg",
          heading: "Smart Grid Solutions",
          text: "Create intelligent, automated grids for efficient energy distribution and reduced downtime.",
          btnLink: "smart-grid-solutions",
          image: "image.png",
        },
        {
          id: 2,
          borderClass: "border-2",
          icon: "/images/icon2.svg",
          heading: "Renewable Energy Management",
          text: "Develop systems for managing renewable energy sources like solar, wind, and hydro.",
          btnLink: "renewable-energy-management",
          image: "image.png",
        },
        {
          id: 3,
          borderClass: "border-1",
          icon: "/images/arvr-icon.png",
          heading: "Energy Consumption Analytics",
          text: "Leverage big data to optimize energy consumption across industries and improve efficiency.",
          btnLink: "energy-consumption-analytics",
          image: "image.png",
        },
        {
          id: 4,
          borderClass: "border-2",
          icon: "/images/blockchain-icon.png",
          heading: "Automated Billing and Reporting",
          text: "Create automated billing systems for accurate, timely energy consumption payments.",
          btnLink: "automated-billing-and-reporting",
          image: "image.png",
        },
      ],
    },

    industryAdvertising: {
      heading: "Energy Software Standards and Compliance",
      discription:
        " Our energy software solutions comply with industry regulations and standards, such as ISO 50001, ANSI, and IEEE, ensuring that your operations meet global best practices. Additionally, our systems are built to adhere to environmental regulations, helping you meet sustainability goals.",
      image: "energy-add.jpg",
    },

    softwareSolutions: {
      heading: "Explore Our Energy Solutions",
      discription:
        "Discover cutting-edge energy solutions tailored to optimize resource distribution, enhance energy efficiency, and provide secure, scalable systems for sustainable business growth and operational success.",
      healthcareSolutionsData: [
        {
          id: 1,
          heading: "Smart Grid Management",
          text: "Optimize grid performance through real-time monitoring, automation, and predictive analytics to enhance efficiency and reliability.",
        },
        {
          id: 2,
          heading: "Renewable Energy Integration",
          text: "Seamlessly integrate renewable energy sources such as solar and wind into your existing grid for efficient, sustainable energy use.",
        },
        {
          id: 3,
          heading: "Energy Analytics and Forecasting",
          text: "Gain actionable insights into energy consumption patterns, forecast future demand, and optimize resource allocation for maximum efficiency.",
        },
        {
          id: 4,
          heading: "Demand Response Systems",
          text: "Implement systems that dynamically manage energy consumption based on real-time demand, preventing grid overload and enhancing reliability.",
        },
        {
          id: 5,
          heading: "Energy Efficiency Solutions",
          text: "Identify and capitalize on opportunities to reduce energy waste and improve operational efficiency, driving sustainability and cost savings.",
        },
      ],
    },

    industryExpertise: {
      heading: "Our Expertise: Innovative Energy Software Solutions",
      discription:
        "We specialize in delivering advanced energy software solutions that optimize grid performance, integrate renewable energy, streamline energy analytics, and enhance demand response systems for sustainable and efficient energy use.",
      accordionData: [
        {
          id: "collapseOne",
          title: "Smart Grid Management",
          content:
            "Optimize grid performance through real-time monitoring, automation, and predictive analytics to enhance energy distribution, minimize downtime, and improve operational efficiency.",
          expanded: true,
        },
        {
          id: "collapseTwo",
          title: "Renewable Energy Integration",
          content:
            "Seamlessly integrate renewable energy sources, such as solar and wind, into existing grid systems to maximize energy efficiency and sustainability.",
          expanded: false,
        },
        {
          id: "collapseThree",
          title: "Energy Analytics and Forecasting",
          content:
            "Leverage big data and advanced analytics to gain insights into energy usage patterns, forecast future demands, and optimize resource allocation for cost-effective operations.",
          expanded: false,
        },
        {
          id: "collapseFour",
          title: "Demand Response Systems",
          content:
            "Implement systems to manage real-time energy demand, preventing grid overload and ensuring stable, efficient energy use during peak times.",
          expanded: false,
        },
      ],
    },

    industryFaqs: {
      heading: "Energy Software Development FAQs",
      discription:
        "Here are answers to some of the most common questions about our energy software development services:",
      faqData: [
        {
          id: "collapseOne",
          question:
            "How can you help reduce energy consumption in our operations?",
          answer:
            "We offer energy analytics solutions that identify patterns in energy consumption, allowing you to optimize usage, reduce waste, and improve efficiency across your operations.",
        },
        {
          id: "collapseTwo",
          question: "What are smart grids and how do they work?",
          answer:
            "Smart grids are advanced electrical grids that use real-time data and automation to optimize energy distribution. They can detect issues in the grid, minimize downtime, and enable more efficient energy use by balancing supply and demand.",
        },
        {
          id: "collapseThree",
          question:
            "How can renewable energy be integrated into our existing systems?",
          answer:
            "We develop seamless integration solutions that allow renewable energy sources like solar, wind, and hydro to be efficiently connected to your existing energy infrastructure, helping you meet sustainability goals while maintaining grid stability.",
        },
        {
          id: "fourth",
          question: "What is energy consumption analytics?",
          answer:
            "Energy consumption analytics uses data to analyze energy usage patterns and identify areas for improvement. Our solutions provide actionable insights to reduce consumption and costs, enhancing overall operational efficiency.",
        },
        {
          id: "fifth",
          question:
            "Can your solutions help with energy efficiency in industrial operations?",
          answer:
            "Yes, our energy efficiency solutions are designed to optimize energy use across industrial systems. By leveraging predictive analytics and automation, we help businesses minimize waste and reduce their carbon footprint.",
        },
        {
          id: "sixth",
          question:
            "Do you offer solutions for managing demand-response in energy systems?",
          answer:
            "Yes, we provide demand-response systems that manage energy usage during peak demand times, preventing grid overloads and ensuring that energy is used efficiently when it's most needed.",
        },
      ],
    },
  },
  {
    id: 24,
    title: "Saas",
    slug: "saas",
    content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
    description:
      "We empower SaaS companies with scalable cloud infrastructures, AI-powered customer insights, and automated service management tools, enabling them to deliver continuous value and innovation.",
    icon: "/images/industries-icons/Saas.svg",
    image: "Saas.png",
    bannerHeading: "Powering the Future with Sustainable Energy Solutions",
    bannerBtnText: "Let's Get Started",
    bannerImage: "energy-detail-banner.png",
    backgroundImage: `url(/images/industry-detial-banner-bg/Energy.png)`,

    discriptionSection: {
      heading: "Leading the Charge ",
      discription:
        "in Energy Innovation, The energy sector is evolving rapidly with the global push towards sustainability, efficiency, and renewable energy solutions. At Bitsclan, we empower energy providers with cutting-edge software solutions that streamline energy production, distribution, and consumption. Whether it's renewable energy management, smart grids, or energy analytics, we help businesses in the energy sector optimize operations, reduce waste, and meet sustainability goals.",

      cardHeading: "Energy Software Expertise",
      cardDiscripiton:
        "We offer innovative energy solutions to optimize efficiency and sustainability. Our Smart Grid Solutions enhance grid reliability and real-time monitoring. Renewable Energy Management integrates and manages renewable sources, while Energy Consumption Analytics provide insights to reduce costs. With Automated Billing and Reporting, we streamline financial processes, and our Energy Efficiency Solutions help businesses cut waste and improve performance.",
    },

    statsSection: {
      backgroundImage: "energy-counter-bg.jpg",
      heading: "Empowering the Energy ",
      greenPart: "Sector with Technology",
      discription:
        "Driving innovation in Energy Innovation with advanced software solutions for enhanced learning experiences.",
      subheading: "Your Top Energy",
      greenPartSubHeading: "Consulting Firm",

      stats: [
        {
          count: "1,500+",
          text: "Energy Solutions Deployed Globally",
        },
        {
          count: "250+",
          text: "Energy Providers Served",
        },
        {
          count: "98%",
          text: "Client Satisfaction Rate",
        },
        {
          count: "75%",
          text: "Reduction in Energy Waste for Clients",
        },
      ],
    },

    industryServices: {
      heading: "Energy Software Development Services",
      text: "Transform your energy business with innovative software solutions that optimize energy management, enhance efficiency, and provide scalable, secure services, driving operational excellence and sustainability.",

      industryServicesSlides: [
        {
          id: 1,
          borderClass: "border-1",
          icon: "/images/icon1.svg",
          heading: "Smart Grid Solutions",
          text: "Create intelligent, automated grids for efficient energy distribution and reduced downtime.",
          btnLink: "smart-grid-solutions",
          image: "image.png",
        },
        {
          id: 2,
          borderClass: "border-2",
          icon: "/images/icon2.svg",
          heading: "Renewable Energy Management",
          text: "Develop systems for managing renewable energy sources like solar, wind, and hydro.",
          btnLink: "renewable-energy-management",
          image: "image.png",
        },
        {
          id: 3,
          borderClass: "border-1",
          icon: "/images/arvr-icon.png",
          heading: "Energy Consumption Analytics",
          text: "Leverage big data to optimize energy consumption across industries and improve efficiency.",
          btnLink: "energy-consumption-analytics",
          image: "image.png",
        },
        {
          id: 4,
          borderClass: "border-2",
          icon: "/images/blockchain-icon.png",
          heading: "Automated Billing and Reporting",
          text: "Create automated billing systems for accurate, timely energy consumption payments.",
          btnLink: "automated-billing-and-reporting",
          image: "image.png",
        },
      ],
    },

    industryAdvertising: {
      heading: "Energy Software Standards and Compliance",
      discription:
        " Our energy software solutions comply with industry regulations and standards, such as ISO 50001, ANSI, and IEEE, ensuring that your operations meet global best practices. Additionally, our systems are built to adhere to environmental regulations, helping you meet sustainability goals.",
      image: "energy-add.jpg",
    },

    softwareSolutions: {
      heading: "Explore Our Energy Solutions",
      discription:
        "Discover cutting-edge energy solutions tailored to optimize resource distribution, enhance energy efficiency, and provide secure, scalable systems for sustainable business growth and operational success.",
      healthcareSolutionsData: [
        {
          id: 1,
          heading: "Smart Grid Management",
          text: "Optimize grid performance through real-time monitoring, automation, and predictive analytics to enhance efficiency and reliability.",
        },
        {
          id: 2,
          heading: "Renewable Energy Integration",
          text: "Seamlessly integrate renewable energy sources such as solar and wind into your existing grid for efficient, sustainable energy use.",
        },
        {
          id: 3,
          heading: "Energy Analytics and Forecasting",
          text: "Gain actionable insights into energy consumption patterns, forecast future demand, and optimize resource allocation for maximum efficiency.",
        },
        {
          id: 4,
          heading: "Demand Response Systems",
          text: "Implement systems that dynamically manage energy consumption based on real-time demand, preventing grid overload and enhancing reliability.",
        },
        {
          id: 5,
          heading: "Energy Efficiency Solutions",
          text: "Identify and capitalize on opportunities to reduce energy waste and improve operational efficiency, driving sustainability and cost savings.",
        },
      ],
    },

    industryExpertise: {
      heading: "Our Expertise: Innovative Energy Software Solutions",
      discription:
        "We specialize in delivering advanced energy software solutions that optimize grid performance, integrate renewable energy, streamline energy analytics, and enhance demand response systems for sustainable and efficient energy use.",
      accordionData: [
        {
          id: "collapseOne",
          title: "Smart Grid Management",
          content:
            "Optimize grid performance through real-time monitoring, automation, and predictive analytics to enhance energy distribution, minimize downtime, and improve operational efficiency.",
          expanded: true,
        },
        {
          id: "collapseTwo",
          title: "Renewable Energy Integration",
          content:
            "Seamlessly integrate renewable energy sources, such as solar and wind, into existing grid systems to maximize energy efficiency and sustainability.",
          expanded: false,
        },
        {
          id: "collapseThree",
          title: "Energy Analytics and Forecasting",
          content:
            "Leverage big data and advanced analytics to gain insights into energy usage patterns, forecast future demands, and optimize resource allocation for cost-effective operations.",
          expanded: false,
        },
        {
          id: "collapseFour",
          title: "Demand Response Systems",
          content:
            "Implement systems to manage real-time energy demand, preventing grid overload and ensuring stable, efficient energy use during peak times.",
          expanded: false,
        },
      ],
    },

    industryFaqs: {
      heading: "Energy Software Development FAQs",
      discription:
        "Here are answers to some of the most common questions about our energy software development services:",
      faqData: [
        {
          id: "collapseOne",
          question:
            "How can you help reduce energy consumption in our operations?",
          answer:
            "We offer energy analytics solutions that identify patterns in energy consumption, allowing you to optimize usage, reduce waste, and improve efficiency across your operations.",
        },
        {
          id: "collapseTwo",
          question: "What are smart grids and how do they work?",
          answer:
            "Smart grids are advanced electrical grids that use real-time data and automation to optimize energy distribution. They can detect issues in the grid, minimize downtime, and enable more efficient energy use by balancing supply and demand.",
        },
        {
          id: "collapseThree",
          question:
            "How can renewable energy be integrated into our existing systems?",
          answer:
            "We develop seamless integration solutions that allow renewable energy sources like solar, wind, and hydro to be efficiently connected to your existing energy infrastructure, helping you meet sustainability goals while maintaining grid stability.",
        },
        {
          id: "fourth",
          question: "What is energy consumption analytics?",
          answer:
            "Energy consumption analytics uses data to analyze energy usage patterns and identify areas for improvement. Our solutions provide actionable insights to reduce consumption and costs, enhancing overall operational efficiency.",
        },
        {
          id: "fifth",
          question:
            "Can your solutions help with energy efficiency in industrial operations?",
          answer:
            "Yes, our energy efficiency solutions are designed to optimize energy use across industrial systems. By leveraging predictive analytics and automation, we help businesses minimize waste and reduce their carbon footprint.",
        },
        {
          id: "sixth",
          question:
            "Do you offer solutions for managing demand-response in energy systems?",
          answer:
            "Yes, we provide demand-response systems that manage energy usage during peak demand times, preventing grid overloads and ensuring that energy is used efficiently when it's most needed.",
        },
      ],
    },
  },
  {
    id: 25,
    title: "Travel and Tourism",
    slug: "travel-and-tourism",
    content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
    description:
      "We reshape travel and tourism with AI-driven personalized travel planning, blockchain-secured bookings, and immersive virtual reality experiences, creating memorable journeys for travelers.",
    icon: "/images/industries-icons/Travel and Tourism.svg",
    image: "Travel and Tourism.png",
    bannerHeading: "Powering the Future with Sustainable Energy Solutions",
    bannerBtnText: "Let's Get Started",
    bannerImage: "energy-detail-banner.png",
    backgroundImage: `url(/images/industry-detial-banner-bg/Energy.png)`,

    discriptionSection: {
      heading: "Leading the Charge ",
      discription:
        "in Energy Innovation, The energy sector is evolving rapidly with the global push towards sustainability, efficiency, and renewable energy solutions. At Bitsclan, we empower energy providers with cutting-edge software solutions that streamline energy production, distribution, and consumption. Whether it's renewable energy management, smart grids, or energy analytics, we help businesses in the energy sector optimize operations, reduce waste, and meet sustainability goals.",

      cardHeading: "Energy Software Expertise",
      cardDiscripiton:
        "We offer innovative energy solutions to optimize efficiency and sustainability. Our Smart Grid Solutions enhance grid reliability and real-time monitoring. Renewable Energy Management integrates and manages renewable sources, while Energy Consumption Analytics provide insights to reduce costs. With Automated Billing and Reporting, we streamline financial processes, and our Energy Efficiency Solutions help businesses cut waste and improve performance.",
    },

    statsSection: {
      backgroundImage: "energy-counter-bg.jpg",
      heading: "Empowering the Energy ",
      greenPart: "Sector with Technology",
      discription:
        "Driving innovation in Energy Innovation with advanced software solutions for enhanced learning experiences.",
      subheading: "Your Top Energy",
      greenPartSubHeading: "Consulting Firm",

      stats: [
        {
          count: "1,500+",
          text: "Energy Solutions Deployed Globally",
        },
        {
          count: "250+",
          text: "Energy Providers Served",
        },
        {
          count: "98%",
          text: "Client Satisfaction Rate",
        },
        {
          count: "75%",
          text: "Reduction in Energy Waste for Clients",
        },
      ],
    },

    industryServices: {
      heading: "Energy Software Development Services",
      text: "Transform your energy business with innovative software solutions that optimize energy management, enhance efficiency, and provide scalable, secure services, driving operational excellence and sustainability.",

      industryServicesSlides: [
        {
          id: 1,
          borderClass: "border-1",
          icon: "/images/icon1.svg",
          heading: "Smart Grid Solutions",
          text: "Create intelligent, automated grids for efficient energy distribution and reduced downtime.",
          btnLink: "smart-grid-solutions",
          image: "image.png",
        },
        {
          id: 2,
          borderClass: "border-2",
          icon: "/images/icon2.svg",
          heading: "Renewable Energy Management",
          text: "Develop systems for managing renewable energy sources like solar, wind, and hydro.",
          btnLink: "renewable-energy-management",
          image: "image.png",
        },
        {
          id: 3,
          borderClass: "border-1",
          icon: "/images/arvr-icon.png",
          heading: "Energy Consumption Analytics",
          text: "Leverage big data to optimize energy consumption across industries and improve efficiency.",
          btnLink: "energy-consumption-analytics",
          image: "image.png",
        },
        {
          id: 4,
          borderClass: "border-2",
          icon: "/images/blockchain-icon.png",
          heading: "Automated Billing and Reporting",
          text: "Create automated billing systems for accurate, timely energy consumption payments.",
          btnLink: "automated-billing-and-reporting",
          image: "image.png",
        },
      ],
    },

    industryAdvertising: {
      heading: "Energy Software Standards and Compliance",
      discription:
        " Our energy software solutions comply with industry regulations and standards, such as ISO 50001, ANSI, and IEEE, ensuring that your operations meet global best practices. Additionally, our systems are built to adhere to environmental regulations, helping you meet sustainability goals.",
      image: "energy-add.jpg",
    },

    softwareSolutions: {
      heading: "Explore Our Energy Solutions",
      discription:
        "Discover cutting-edge energy solutions tailored to optimize resource distribution, enhance energy efficiency, and provide secure, scalable systems for sustainable business growth and operational success.",
      healthcareSolutionsData: [
        {
          id: 1,
          heading: "Smart Grid Management",
          text: "Optimize grid performance through real-time monitoring, automation, and predictive analytics to enhance efficiency and reliability.",
        },
        {
          id: 2,
          heading: "Renewable Energy Integration",
          text: "Seamlessly integrate renewable energy sources such as solar and wind into your existing grid for efficient, sustainable energy use.",
        },
        {
          id: 3,
          heading: "Energy Analytics and Forecasting",
          text: "Gain actionable insights into energy consumption patterns, forecast future demand, and optimize resource allocation for maximum efficiency.",
        },
        {
          id: 4,
          heading: "Demand Response Systems",
          text: "Implement systems that dynamically manage energy consumption based on real-time demand, preventing grid overload and enhancing reliability.",
        },
        {
          id: 5,
          heading: "Energy Efficiency Solutions",
          text: "Identify and capitalize on opportunities to reduce energy waste and improve operational efficiency, driving sustainability and cost savings.",
        },
      ],
    },

    industryExpertise: {
      heading: "Our Expertise: Innovative Energy Software Solutions",
      discription:
        "We specialize in delivering advanced energy software solutions that optimize grid performance, integrate renewable energy, streamline energy analytics, and enhance demand response systems for sustainable and efficient energy use.",
      accordionData: [
        {
          id: "collapseOne",
          title: "Smart Grid Management",
          content:
            "Optimize grid performance through real-time monitoring, automation, and predictive analytics to enhance energy distribution, minimize downtime, and improve operational efficiency.",
          expanded: true,
        },
        {
          id: "collapseTwo",
          title: "Renewable Energy Integration",
          content:
            "Seamlessly integrate renewable energy sources, such as solar and wind, into existing grid systems to maximize energy efficiency and sustainability.",
          expanded: false,
        },
        {
          id: "collapseThree",
          title: "Energy Analytics and Forecasting",
          content:
            "Leverage big data and advanced analytics to gain insights into energy usage patterns, forecast future demands, and optimize resource allocation for cost-effective operations.",
          expanded: false,
        },
        {
          id: "collapseFour",
          title: "Demand Response Systems",
          content:
            "Implement systems to manage real-time energy demand, preventing grid overload and ensuring stable, efficient energy use during peak times.",
          expanded: false,
        },
      ],
    },

    industryFaqs: {
      heading: "Energy Software Development FAQs",
      discription:
        "Here are answers to some of the most common questions about our energy software development services:",
      faqData: [
        {
          id: "collapseOne",
          question:
            "How can you help reduce energy consumption in our operations?",
          answer:
            "We offer energy analytics solutions that identify patterns in energy consumption, allowing you to optimize usage, reduce waste, and improve efficiency across your operations.",
        },
        {
          id: "collapseTwo",
          question: "What are smart grids and how do they work?",
          answer:
            "Smart grids are advanced electrical grids that use real-time data and automation to optimize energy distribution. They can detect issues in the grid, minimize downtime, and enable more efficient energy use by balancing supply and demand.",
        },
        {
          id: "collapseThree",
          question:
            "How can renewable energy be integrated into our existing systems?",
          answer:
            "We develop seamless integration solutions that allow renewable energy sources like solar, wind, and hydro to be efficiently connected to your existing energy infrastructure, helping you meet sustainability goals while maintaining grid stability.",
        },
        {
          id: "fourth",
          question: "What is energy consumption analytics?",
          answer:
            "Energy consumption analytics uses data to analyze energy usage patterns and identify areas for improvement. Our solutions provide actionable insights to reduce consumption and costs, enhancing overall operational efficiency.",
        },
        {
          id: "fifth",
          question:
            "Can your solutions help with energy efficiency in industrial operations?",
          answer:
            "Yes, our energy efficiency solutions are designed to optimize energy use across industrial systems. By leveraging predictive analytics and automation, we help businesses minimize waste and reduce their carbon footprint.",
        },
        {
          id: "sixth",
          question:
            "Do you offer solutions for managing demand-response in energy systems?",
          answer:
            "Yes, we provide demand-response systems that manage energy usage during peak demand times, preventing grid overloads and ensuring that energy is used efficiently when it's most needed.",
        },
      ],
    },
  },
];


export const slidesData = [
  {
    heading: 'Expertise Across Industries',
    text: 'With extensive experience in diverse sectors, we bring industry-specific knowledge that drives tailored solutions, ensuring we meet your unique challenges with precision.',
  },
  {
    heading: 'Cutting-Edge Technology',
    text: 'We leverage the latest technologies, from AI and blockchain to advanced analytics, ensuring that your business benefits from innovative solutions that are both effective and future-proof.',
  },
  {
    heading: 'Client-Centric Approach',
    text: 'Your success is our priority. We take the time to understand your business, delivering customized strategies that align with your objectives and deliver measurable results.',
  },
  {
    heading: 'Global Reach with Local Understanding',
    text: 'With a global presence and deep local insights, we provide solutions that resonate with your market, ensuring relevance and effectiveness in every region you operate.',
  },
  {
    heading: 'Innovation at the Core',
    text: 'We are driven by a passion for innovation, constantly exploring new technologies and methodologies to ensure that we deliver solutions that are not just current, but ahead of the curve.',
  },
];

export var mainBannerImg = {
  backgroundImage: `url(/images/what-we-do/industy-page-box.png)`,
};

export default tabData;

import React, { useRef, useState, useCallback } from "react";
import WhoWeServeCard from "./WhoWeServeCard";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";

const WhoWeServe = ({ heading, subHeading, cardContent }) => {
  const sliderRef = useRef();

  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const handleSlideChange = (swiper) => {
    setIsBeginning(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
  };

  return (
    <>
      <div className="who-we-serve-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9">
              <h1 className="main-heading">{heading}</h1>
              <h3 className="sub-heading">{subHeading}</h3>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 mb-4">
              {cardContent?.length > 3 ? (
                <div className="arrow-developing-slider">
                  <img
                    onClick={handlePrev}
                    src="/images/arrow-right.png"
                    className={`left-image ${isBeginning ? "disabled" : ""}`}
                    alt="Arrow"
                    style={{ cursor: isBeginning ? "not-allowed" : "pointer" }}
                  />
                  <img
                    onClick={handleNext}
                    src="/images/arrow-right.png"
                    className={`${isEnd ? "disabled" : ""}`}
                    alt="Arrow"
                    style={{ cursor: isEnd ? "not-allowed" : "pointer" }}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="row">
            {/* {cardContent?.map((item, index) => {
              return <div className="col-xl-4 col-lg-4 col-md-6 pt-5" key={index}>
                <WhoWeServeCard cardContent={item} />
              </div>
            })} */}

            <Swiper
              slidesPerView={6}
              spaceBetween={20}
              navigation={false}
              onSlideChange={handleSlideChange}
              autoplay={{
                delay: 3000,
                disableOnInteraction: true,
                pauseOnMouseEnter: true,
              }}
              modules={[Navigation, Autoplay]}
              className="mySwiper"
              ref={sliderRef}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                375: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                425: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                1280: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
              }}
            >
              {cardContent?.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <WhoWeServeCard cardContent={item} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhoWeServe;

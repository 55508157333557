import React from "react";
import "./styles.css";
import { Link } from "react-router-dom";
function AiDevelopmentPowerSection() {
  return (
    <div className="ai-development-power-section-main">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-12 my-5 power-section-left-section">
            <h1>
              Unlock the Power of Artificial Intelligence - The Bitsclan Way
            </h1>

            <p className="power-section-des">
              No technology has revolutionized the business landscape more
              profoundly than Artificial Intelligence in the last 30 years.
              Today, AI is the biggest catalyst for transformation in the
              business world.
            </p>

            <Link className="power-section-nav-btn-wrapper" to="/contact-us" style={{textDecoration: "none"}}>
              <div className="power-section-nav-btn">
                <p>Let's Connect</p>
                <i
                  style={{ color: "#80B927", fontSize: "18px", marginTop: "4px" }}
                  className="fa-solid fa-arrow-right"
                />
              </div>
            </Link>

            <div className="power-section-objectives-wrapper">
              <div className="power-section-single-objective">
                <p>$1.3 Trillion</p>
                <p>Estimated market value of AI by the year 2032</p>
              </div>

              <div
                className="power-section-single-objective"
                style={{ paddingLeft: "60px", paddingRight: "0px" }}
              >
                <p>54%</p>
                <p>
                  Companies have implemented GenAI in some areas of their
                  business.
                </p>
              </div>

              <div className="power-section-single-objective">
                <p>$415.81 Billion</p>
                <p>
                  The projected value of the AI market in the US alone by the
                  end of this decade
                </p>
              </div>

              <div
                className="power-section-single-objective"
                style={{ paddingLeft: "60px", paddingRight: "0px" }}
              >
                <p>7 in 10</p>
                <p>
                  Organizations have specific training programs for workers for
                  Generative AI
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AiDevelopmentPowerSection;

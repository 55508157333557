export const HireAndroidBannerList = [
  { list: "Freelance contractors" },
  { list: "Full-time roles" },
  { list: "Global teams" },
];

export var mainBannerImg = {
  backgroundImage: `url(/images/hire-page-images/hire-android-page.png)`,
  factBackgroundImage: `url(/images/hire-page-images/hire-android-page.svg)`,
};

export const HireAndroidServicesData = [
  {
    title: "Android App Consulting",
    description:
      " Get strategic guidance and expert advice to optimize your Android app development and maximize its potential.",
    icon: "/images/hire-page-icons/hire-android-icon1.svg",
  },
  {
    title: "Android App Development",
    description:
      "Leverage our developers' expertise to build scalable, feature-rich Android applications that drive engagement and performance.",
    icon: "/images/hire-page-icons/hire-android-icon2.svg",
  },
  {
    title: "Android API Development",
    description:
      "Develop robust APIs that enable seamless communication between mobile applications and third-party services.",
    icon: "/images/hire-page-icons/hire-android-icon3.svg",
  },
  {
    title: "Android CMS and Portal Development",
    description:
      "Create advanced content management systems and web portals with our expert Android developers.",
    icon: "/images/hire-page-icons/hire-android-icon4.svg",
  },
  {
    title: "Migration to Android",
    description:
      "Effortlessly migrate your existing mobile applications to Android while ensuring a smooth transition and data integrity.",
    icon: "/images/hire-page-icons/hire-android-icon5.svg",
  },
  {
    title: "Integration with Existing Solutions",
    description:
      " Seamlessly integrate Android applications with various platforms, services, and enterprise systems for enhanced functionality.",
    icon: "/images/hire-page-icons/hire-android-icon6.svg",
  },
  {
    title: "Android eCommerce Solutions",
    description:
      "Leverage our Android developers’ expertise to build high-performing eCommerce solutions that enhance user experience and interactivity, ensuring seamless shopping experiences.",
    icon: "/images/hire-page-icons/hire-android-icon7.svg",
  },
  {
    title: "Android Support & Maintenance",
    description:
      " Keep your Android applications stable, efficient, and up-to-date with our dedicated support and proactive maintenance services.",
    icon: "/images/hire-page-icons/hire-android-icon8.svg",
  },
];

export const HireAndroidSolutionsData = [
  {
    icon: "/images/hire-page-icons/chose-ror-section1.svg",
    title: "Access vetted talent",
    description:
      "Easily hire top-tier Android developers with the right skills and strong communication abilities. Our rigorous vetting process ensures the best match for your project.",
  },
  {
    icon: "/images/hire-page-icons/chose-ror-section2.svg",
    title: "View matches in seconds",
    description:
      "Skip the lengthy resume reviews! With HireAI, you can quickly discover qualified Android developers and choose the right fit for your team.",
  },
  {
    icon: "/images/hire-page-icons/chose-ror-section3.svg",
    title: "Save with global hires",
    description:
      "Access a diverse pool of highly skilled professionals worldwide while optimizing costs and securing top talent for your projects.",
    noBorder: true,
  },
  {
    icon: "/images/hire-page-icons/chose-ror-section4.svg",
    title: "Get real human support",
    description:
      "Our dedicated recruitment team is here to assist you at every step, ensuring a smooth and hassle-free hiring experience.",
  },
];

export const HireAndroidStacksdata = [
  {
    title: "Junior Ruby on Rails Developer",
    description: [
      "2 Years' experience",
      "Fresh perspectives & innovative ideas",
      "Dedicated to explore web development",
    ],
    linkText: "Connect to Hire",
    link: "#",
    borderClass: "border-1",
  },
  {
    title: "Senior Ruby on Rails Developer",
    description: [
      "2+ years' experience",
      "Leadership in complex projects",
      "Proven app deployment success",
    ],
    linkText: "Connect to Hire",
    link: "#",
    borderClass: "border-2",
  },
  {
    title: "Ruby on Rails Tech Lead",
    description: [
      "5+ years' experience",
      "Skilled with Jira, Asana, MS Teams, etc.",
      "Suitable when you Hire Web App Team",
    ],
    linkText: "Connect to Hire",
    link: "#",
    borderClass: "border-3",
  },
];

export const HireAndroidBenefitsData = [
  {
    service: "Android CMS Development",
    description:
      "Get an upper hand on your web content, augment efficiency, and develop a shared environment with our Ruby on Rails CMS solutions.",
  },
  {
    service: "Ruby on Rails Migrations",
    description:
      "Our Ruby on Rails engineers strategically migrate legacy content into modern systems in a secured, erAndroid-free, and robust way.",
  },
  {
    service: "Advanced Android Programming",
    description:
      "Our experts have extensive experience in using Android tech stack for tangible results to reach your business vision.",
  },
  {
    service: "Android Integration and Optimization",
    description:
      "Leverage the full-scale potential of Ruby on Rails integration and optimization functionalities for your app development needs.",
  },
  {
    service: "Customized Android Solutions",
    description:
      "Our Ruby on Rails developers delve into ideation, reinvention, and scale development as per your enterprise's unique IT demands.",
  },
  {
    service: "Android eCommerce App",
    description:
      "With using modern Android tools and technologies, we have a proven track record of developing scalable and secured eCommerce sites.",
  },
];

export const HireAndroidSteps = [
  {
    title: "Choose your Engagement Model",
    description:
      "Select the hiring model that best fits your needs—Full-Time, Part-Time, or Hourly—based on your project’s scope and development requirements.",
    icon: "/images/hire-page-setps-icons/icon-1.svg",
  },
  {
    title: "Screen & Select Android Developers",
    description:
      "Browse pre-vetted Android developers, review their profiles, and shortlist candidates with the right expertise for your project.",
    icon: "/images/hire-page-setps-icons/icon-2.svg",
  },
  {
    title: "Conduct One-on-One Interview",
    description:
      "Interview selected developers to evaluate their technical skills, problem-solving abilities, and Android development expertise before making a decision.",
    icon: "/images/hire-page-setps-icons/icon-3.svg",
  },
  {
    title: "Onboard & Get Started",
    description:
      "Once you’ve made your choice, your Android developer joins your team within 24-48 hours, ready to contribute immediately.",
    icon: "/images/hire-page-setps-icons/icon-4.svg",
  },
];

export const HireAndroidFactsData = [
  {
    text: "20+ Skilled Android Developers specializing in custom Android app development.",
  },
  {
    text: "10+ Years of Combined Experience in building high-quality mobile applications.",
  },
  { text: "Expertise in Android SDK, Kotlin & Java, the core technologies for Android development." },
  {
    text: "Live Apps on Google Play—our developers have successfully launched numerous custom Android applications.",
  },
  { text: "Scalable Mobile Solutions that deliver seamless performance on both phones and tablets." },
  { text: "Enterprise-Grade Expertise in developing robust mobile solutions and integrating third-party APIs into Android apps." },
];

export const HireAndroidWhoWeServeData = [
  {
    icon: "/images/servie-icon/startups.png",
    title: "Budget savings",
    description:
      "Assembling a local Ruby on Rails team is unavoidably expensive. By approaching Innowise. you bypass many hurdles and avoid redundantexpenses such as training fees. taxes. and software licenses.",
    link: "#",
    borderClass: "border-1",
  },
  {
    icon: "/images/servie-icon/companies.png",
    title: "Software product companies",
    description:
      "When you hire Android developers. you avoid the risks of budget overruns and missed deadlines. Innowise provides solid risk management plans and has an array of risk mitigation measures",
    link: "#",
    borderClass: "border-2",
  },
  {
    icon: "/images/servie-icon/industries.png",
    title: "Enterprises from 30+ industries",
    description:
      "Innowise has experts with excellent development backgrounds. so you can rest assured that your project is in good hands. Pyrggyelgprneg",
    link: "#",
    borderClass: "border-3",
  },
];

export const HireAndroidHirignDetails = [
  {
    text: "Hourly Hiring",
    hours: "48",
    icon: "time.svg",
    bgColor: "#FDFDFF",
  },

  {
    text: "Full Time Hiring",
    hours: "72",
    icon: "full-time.svg",
    bgColor: "#FFFEFA",
  },

  {
    text: "Part Time Hiring",
    hours: "48",
    icon: "part-time.svg",
    bgColor: "#FDFFFA",
  },
];
export const HireAndroidPageDeliverData = [
  {
    image: "/images/hire-page-images/card-1.png",
    title: "Complex SaaS systems",
    description:
      "Innowise provides its expertise in the area of building complex platforms for businesses to streamline workflows. The main applications we develop include HRM, CRM, ERP, accounting software, etc.",
  },
  {
    image: "/images/hire-page-images/card-2.png", // Assumed path based on provided example
    title: "Complex SaaS systems",
    description:
      "Innowise provides its expertise in the area of building complex platforms for businesses to streamline workflows. The main applications we develop include HRM, CRM, ERP, accounting software, etc.",
  },
  {
    image: "/images/hire-page-images/card-3.png", // Assumed path based on provided example
    title: "Complex SaaS systems",
    description:
      "Innowise provides its expertise in the area of building complex platforms for businesses to streamline workflows. The main applications we develop include HRM, CRM, ERP, accounting software, etc.",
  },
];

export const HireAndroidSlidesData = [
  {
    title: "Experienced Android Developers",
    content:
      "Our team consists of senior Android developers who have extensive experience in building sophisticated web applications across various industries.",
    backgroundClass: "bg-1",
  },
  {
    title: "Remote and On-Demand",
    content:
      "Need flexibility? Hire remote Android developers from Bitsclan for seamless project execution without geographic limitations.",
    backgroundClass: "bg-2",
  },
  {
    title: "Agile Development Approach",
    content:
      "We follow agile methodologies to ensure rapid delivery, transparency, and adaptability throughout the development process.",
    backgroundClass: "bg-3",
  },
  {
    title: "Automation Solutions",
    content:
      "We combine AI automation with Robotic Process Automation to boost operational efficiency, reduce operational costs, and minimize human error, allowing you to focus on strategic initiatives.",
    backgroundClass: "bg-4",
  },
];

export const HireAndroidFaqData = [
  {
    id: "collapseOne",
    question: "What is Android app development?",
    answer:
      "Android app development is the process of creating applications for devices that run on Android, such as smartphones and tablets. It involves coding, designing, and testing apps to ensure they work smoothly.",
  },
  {
    id: "collapseTwo",
    question: "Why should I hire Android developers from Bitsclan?",
    answer:
      "By hiring Android developers from Bitsclan, you get access to experts who have the right skills and experience in Android app development. We ensure high-quality and scalable apps that meet your business needs.",
  },
  {
    id: "collapseThree",
    question: "Can you help migrate my existing app to Android?",
    answer:
      "Yes! Our Android experts can help you migrate your existing mobile applications to Android. We ensure a smooth transition, preserving all your app’s functionality while upgrading it to work perfectly on Android devices.",
  },
  {
    id: "fourth",
    question: "Do you offer cross-platform app development?",
    answer:
      " Yes, we do! Our developers specialize in creating cross-platform apps using Flutter and other tools, so your app can run on both Android and iOS without needing separate development for each.",
  },
  {
    id: "fifth",
    question: "What kind of Android apps can you build?",
    answer:
      " We can build all kinds of Android apps, including eCommerce, social networking, enterprise solutions, and more. Whether you need a simple app or a complex one with integrations, we’ve got you covered.",
  },
  {
    id: "sixth",
    question: "How long does it take to develop an Android app?",
    answer:
      " The development time depends on the complexity of the app. Simple apps can be developed in a few weeks, while more complex apps might take a few months. We provide realistic timelines based on your project requirements.",
  },
];


export const tabs = [
  "Tools & Utilities",
  "Languages & Frameworks",
  "Libraries, Components & Toolsets",
  "QA tools & Build Tools",
  "Databases & Storage",
];

export const tabItems = [
  { tab: "Tools & Utilities", icon: "/images/tech-stack-icons/android-studio.svg", name: "Android Studio IDE" },
  { tab: "Tools & Utilities", icon: "/images/tech-stack-icons/android-studio.svg", name: "Android Emulator" },
  { tab: "Tools & Utilities", icon: "/images/tech-stack-icons/leak-canary.svg", name: "LeakCanary" },
  { tab: "Tools & Utilities", icon: "/images/tech-stack-icons/vysor.svg", name: "Vysor" },
  { tab: "Tools & Utilities", icon: "/images/tech-stack-icons/zeplin.svg", name: "Zeplin" },
  { tab: "Tools & Utilities", icon: "/images/tech-stack-icons/figma.svg", name: "Figma" },
  { tab: "Tools & Utilities", icon: "/images/tech-stack-icons/jira.svg", name: "Jira" },
  { tab: "Languages & Frameworks", icon: "/images/tech-stack-icons/java.svg", name: "Java" },
  { tab: "Languages & Frameworks", icon: "/images/tech-stack-icons/kotlin.svg", name: "Kotlin" },
  { tab: "Languages & Frameworks", icon: "/images/tech-stack-icons/android-sdk.svg", name: "Android SDK" },
  { tab: "Languages & Frameworks", icon: "/images/tech-stack-icons/jetpack-compose.svg", name: "Jetpack Compose" },
  { tab: "Languages & Frameworks", icon: "/images/tech-stack-icons/android-jetpack.svg", name: "Android Jetpack" },
  { tab: "Libraries, Components & Toolsets", icon: "/images/tech-stack-icons/rx-js.svg", name: "RxJava" },
  { tab: "Libraries, Components & Toolsets", icon: "/images/tech-stack-icons/kotlin-coroutines.svg", name: "Kotlin Coroutines" },
  { tab: "Libraries, Components & Toolsets", icon: "/images/tech-stack-icons/kotlin.svg", name: "Kotlin Flow" },
  { tab: "Libraries, Components & Toolsets", icon: "/images/tech-stack-icons/koin.svg", name: "KOIN" },
  { tab: "Libraries, Components & Toolsets", icon: "/images/tech-stack-icons/retrofit.svg", name: "Retrofit" },
  { tab: "Libraries, Components & Toolsets", icon: "/images/tech-stack-icons/moshi.svg", name: "Moshi" },
  { tab: "Libraries, Components & Toolsets", icon: "/images/tech-stack-icons/glide.svg", name: "Glide" },
  { tab: "Libraries, Components & Toolsets", icon: "/images/tech-stack-icons/work-manager.svg", name: "WorkManager" },
  { tab: "Libraries, Components & Toolsets", icon: "/images/tech-stack-icons/jetpack-room.svg", name: "Jetpack Room" },
  { tab: "Libraries, Components & Toolsets", icon: "/images/tech-stack-icons/jetpack-view-model.svg", name: "Jetpack ViewModel" },
  { tab: "Libraries, Components & Toolsets", icon: "/images/tech-stack-icons/jetpack-navigation.svg", name: "Jetpack Navigation" },
  { tab: "Libraries, Components & Toolsets", icon: "/images/tech-stack-icons/jetpack-lifecycle.svg", name: "Jetpack Lifecycle" },
  { tab: "Libraries, Components & Toolsets", icon: "/images/tech-stack-icons/material-ui.svg", name: "Material Components" },
  { tab: "Libraries, Components & Toolsets", icon: "/images/tech-stack-icons/view-components", name: "View Components" },
  { tab: "Libraries, Components & Toolsets", icon: "/images/tech-stack-icons/android-jetpack.svg", name: "JJetpack ViewModel" },
  { tab: "QA tools & Build Tools", icon: "/images/tech-stack-icons/j-unit.svg", name: "JUnit" },
  { tab: "QA tools & Build Tools", icon: "/images/tech-stack-icons/mockito.svg", name: "Mockito" },
  { tab: "QA tools & Build Tools", icon: "/images/tech-stack-icons/espresso.svg", name: "Espresso" },
  { tab: "QA tools & Build Tools", icon: "/images/tech-stack-icons/gradle.svg", name: "Gradle" },
  { tab: "QA tools & Build Tools", icon: "/images/tech-stack-icons/jenkins.svg", name: "Jenkins" },
  { tab: "QA tools & Build Tools", icon: "/images/tech-stack-icons/gitlab.svg", name: "GitLab CI/CD" },
  { tab: "Databases & Storage", icon: "/images/tech-stack-icons/firebase.png", name: "Firebase" },
  { tab: "Databases & Storage", icon: "/images/tech-stack-icons/room.svg", name: "Room" },
  { tab: "Databases & Storage", icon: "/images/tech-stack-icons/sq-lite.svg", name: "SQ Lite" },
  { tab: "Databases & Storage", icon: "/images/tech-stack-icons/shared-preference.svg", name: "Shared Preference" },
];

export const techCombinations = [
  {
    title: "Kotlin + Firebase",
    description:
      "Kotlin as the preferred language for Android development combined with Firebase ensures real-time database, authentication, and cloud functions for mobile applications.",
    icons: ["kotlin.svg", "firebase.png"],
  },
  {
    title: "Kotlin + Jetpack Compose + Room Database",
    description:
      "Kotlin with Jetpack Compose offers modern UI development, while Room Database ensures efficient local data storage for Android applications.",
    icons: ["kotlin.svg", "jetpack-compose.svg", "room.svg"],
  },
  {
    title: "Java + Retrofit + MySQL",
    description:
      "Java for Android development, Retrofit for seamless API integration, and MySQL as a structured relational database for scalable applications.",
    icons: ["java.svg", "retrofit.svg", "mysql-whale-icon.svg"],
  },
  {
    title: "Kotlin + MVVM + SQLite",
    description:
      "Kotlin with MVVM architecture ensures scalable and maintainable Android apps, while SQLite provides a lightweight local database solution.",
    icons: ["kotlin.svg", "mvv.svg", "sq-lite.svg"],
  },
  {
    title: "Flutter + Firebase",
    description:
      "Flutter for cross-platform mobile development and Firebase for real-time database, authentication, and backend services offer a complete mobile solution.",
    icons: ["Flutter.svg", "firebase.png"],
  },
  {
    title: "React Native + GraphQL + PostgreSQL",
    description:
      "React Native for cross-platform mobile development, GraphQL for optimized data queries, and PostgreSQL for structured database management.",
    icons: ["react.svg", "graphql.svg", "postgres.svg"],
  },
];

export const DevOpsTabsData = [
  {
    id: "Tab1",
    label: "Enterprise Web Apps",
    content:
      "Enhance enterprise operations with DevOps-driven automation, seamless integrations, and optimized workflows for increased agility and efficiency.",
    featureList: [
      { text: "Scalable Infrastructure Management" },
      { text: "Continuous Deployment & Monitoring" },
      { text: "Automated Security & Compliance" },
      { text: "Cloud-Native Enterprise Solutions" },
      { text: "Performance Optimization" },
    ],
  },
  {
    id: "Tab2",
    label: "Supply Chain Management Web Apps",
    content:
      "Improve logistics and supply chain efficiency with DevOps-enabled automation, predictive analytics, and seamless cloud-based solutions.",
    featureList: [
      { text: "Real-Time Inventory Tracking" },
      { text: "Automated Deployment Pipelines" },
      { text: "Secure & Scalable APIs" },
      { text: "Performance Monitoring & Alerts" },
      { text: "Cloud-Based Logistics Optimization" },
    ],
  },
  {
    id: "Tab3",
    label: "Web Portals",
    content:
      "Enable seamless user interactions with DevOps-backed web portals featuring high availability, security, and real-time performance monitoring.",
    featureList: [
      { text: "Automated Scaling & Load Balancing" },
      { text: "CI/CD for Faster Updates" },
      { text: "Secure API Gateway Management" },
      { text: "Cloud-Optimized Performance" },
      { text: "AI-Powered Analytics & Insights" },
    ],
  },
  {
    id: "Tab4",
    label: "Customer-Facing Apps",
    content:
      "Deliver reliable and high-performance apps with DevOps-driven strategies, ensuring smooth deployments, security, and improved user experiences.",
    featureList: [
      { text: "Zero-Downtime Deployments" },
      { text: "Automated Performance Testing" },
      { text: "Secure Access & Identity Management" },
      { text: "Real-Time Error Monitoring" },
      { text: "Cloud & Edge Computing Integration" },
    ],
  },
  {
    id: "Tab5",
    label: "Online Web Services",
    content:
      "Optimize online services with DevOps methodologies, enhancing scalability, automation, and security for seamless digital experiences.",
    featureList: [
      { text: "API-First Development Strategy" },
      { text: "Continuous Monitoring & Incident Response" },
      { text: "DevSecOps for Secure Applications" },
      { text: "Scalable Cloud Hosting & Management" },
      { text: "Auto-Healing & Fault-Tolerant Systems" },
    ],
  },
  {
    id: "Tab6",
    label: "Analytics Web Apps",
    content:
      "Gain actionable insights with DevOps-driven analytics platforms that ensure real-time data processing, predictive modeling, and secure cloud integrations.",
    featureList: [
      { text: "Real-Time Data Processing Pipelines" },
      { text: "Automated Data Ingestion & ETL" },
      { text: "Scalable Cloud-Based Analytics" },
      { text: "AI & ML Model Deployment" },
      { text: "Secure & Compliant Data Governance" },
    ],
  },
];


export const DevOpsFaqData = [
  {
    id: "collapseOne",
    question: "What are DevOps services in simple terms?",
    answer:
      "DevOps services combine development and operations practices to automate and streamline the software development lifecycle, ensuring faster, more reliable software delivery.",
  },
  {
    id: "collapseTwo",
    question: "What are examples of DevOps as a service?",
    answer:
      "Examples include continuous integration and deployment (CI/CD), infrastructure automation, cloud management, and monitoring and logging as services that enhance efficiency and collaboration.",
  },
  {
    id: "collapseThree",
    question: "What are CI and CD in DevOps?",
    answer:
      "CI (Continuous Integration) is the practice of regularly merging code changes, while CD (Continuous Deployment) is the automated release of these changes to production environments.",
  },
  {
    id: "fourth",
    question:
      "What is DevOps Managed Service?",
    answer:
      "DevOps Managed Service involves outsourcing the management of DevOps processes, including infrastructure, automation, and continuous delivery, to a specialized provider like Bitsclan.",
  },
  {
    id: "fifth",
    question: "What are Bitsclan's engagement models in DevOps services?",
    answer:
      "Bitsclan offers flexible engagement models including project-based, dedicated team, and managed services, tailored to meet the specific needs and goals of your organization.",
  },
  {
    id: "sixth",
    question: "How does Bitsclan compare to other DevOps service providers?",
    answer:
      "Bitsclan stands out with its tailored, end-to-end DevOps solutions, expert team, and commitment to quality, ensuring your projects are delivered on time and aligned with your business objectives.",
  },
  {
    id: "seventh",
    question: "What is the difference between Agile and DevOps?",
    answer:
      "Agile focuses on iterative development and collaboration within teams, while DevOps extends this collaboration to include operations, emphasizing automation and continuous delivery.",
  },
];

import React from 'react'
import blogsSlidesData from '../data/blogsData'
import MegaHeader from '../components/MegaHeader/MegaHeader'
import Footer from '../components/footer/Footer'
import { Link, useParams } from 'react-router-dom'

const BlogDetails = () => {

    const param = useParams();
    const blogData = param.id;
    const matchedItem = blogsSlidesData.find((item) => Number(item.id) === Number(blogData));
    console.log(matchedItem, "match items");
    const filteredBlogs = blogsSlidesData.filter((item) => Number(item.id) !== Number(blogData));


    return (
        <>
            <div className="sticky-header">
                <MegaHeader />
            </div>

            <div
                className="blog-detail-image-banner"
                style={{ backgroundImage: `url(${matchedItem?.imageSrc})` }}
            >
                <div className="container h-100">
                    <div className="row h-100">
                        <div className="col-md-12">
                            <div className="banner-text-box">
                                <h1 className='text-center'>{matchedItem?.title}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            <div className="blog-other-details">
                <div className="container">
                    <div className="row reverse-mobile-style">
                        <div className="col-xl-5 col-lg-5 col-md-6">
                            <div className="container" style={{ position: 'sticky', top: '100px' }}>
                                <div className="row">
                                    {filteredBlogs.slice(0, 3).map((items, index) => {
                                        return <div className="col-md-12 p-0" key={index}>
                                            <Link to={`/blog-details/${items.id}`} style={{ textDecoration: 'none' }}>
                                                <div className="blogs-show-cards">
                                                    <div className="blog-image">
                                                        <img src={items.imageSrc} alt="Blog Image" />
                                                    </div>

                                                    <div className="blog-image-text">
                                                        <h1>{items.title}</h1>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    })}
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="social-icons-box">
                                            <a href="https://www.facebook.com/bitsclan">
                                                <i class="fa-brands fa-square-facebook"></i>
                                            </a>

                                            <a href="https://www.linkedin.com/company/bitsclanitsolutions">
                                                <i class="fa-brands fa-linkedin"></i>
                                            </a>

                                            <a href="https://x.com/BitsClan?mx=2">
                                                <i class="fa-brands fa-square-x-twitter"></i>
                                            </a>
                                        </div>

                                        <h2 className='social-icon-heading' style={{ fontWeight: '300', fontSize: '28px' }}>Share This Article</h2>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-7 col-lg-7 col-md-6">
                            <div className="blog-content-box">
                                <h2 className='social-icon-heading'>{matchedItem?.title}</h2>
                                <p>{matchedItem?.description}</p>

                                <div className="mt-5">
                                    <h2 className='social-icon-heading'>{matchedItem?.title1}</h2>
                                    {/* <div className="blog-image-box">
                                        <img src={matchedItem?.imageSrc} alt="Blog Banner" />
                                    </div> */}
                                    <p>{matchedItem?.description1}</p>
                                </div>

                                <div className="mt-5">
                                    <h2 className='social-icon-heading'>{matchedItem?.title2}</h2>
                                    {/* {
                                        matchedItem?.imageSrc2 ? <div className="blog-image-box">
                                            <img src={matchedItem?.imageSrc2} alt="Blog Banner" />
                                        </div> : ""
                                    } */}
                                    <p>{matchedItem?.description2}</p>
                                    <ul className='blog-detial-list'>
                                        {matchedItem?.description2List?.map((list, index) => {
                                            return <li key={index}>{list}</li>
                                        })}
                                    </ul>
                                </div>

                                <div className="mt-5">
                                    <h2 className='social-icon-heading'>{matchedItem?.title3}</h2>
                                    {/* {
                                        matchedItem?.imageSrc3 ? <div className="blog-image-box">
                                            <img src={matchedItem?.imageSrc3} alt="Blog Images" />
                                        </div> : ""
                                    } */}
                                    <p>{matchedItem?.description3}</p>
                                    <ul className='blog-detial-list'>
                                        {matchedItem?.description3List?.map((list, index) => {
                                            return <li key={index}>{list}</li>
                                        })}
                                    </ul>
                                </div>

                                <div className="mt-5">
                                    <h2 className='social-icon-heading'>{matchedItem?.title4}</h2>
                                    <p>{matchedItem?.description4}</p>
                                    <ul className='blog-detial-list'>
                                        {matchedItem?.description4List?.map((list, index) => {
                                            return <li key={index}>{list}</li>
                                        })}
                                    </ul>
                                </div>

                                <div className="mt-5">
                                    <h2 className='social-icon-heading'>{matchedItem?.title5}</h2>
                                    <p>{matchedItem?.description5}</p>
                                    <ul className='blog-detial-list'>
                                        {matchedItem?.description5List?.map((list, index) => {
                                            return <li key={index}>{list}</li>
                                        })}
                                    </ul>
                                </div>

                                <div className="mt-5">
                                    <h2 className='social-icon-heading'>{matchedItem?.title6}</h2>
                                    <p>{matchedItem?.description6}</p>
                                    <ul className='blog-detial-list'>
                                        {matchedItem?.description6List?.map((list, index) => {
                                            return <li key={index}>{list}</li>
                                        })}
                                    </ul>
                                </div>

                                <div className="mt-5">
                                    <h2 className='social-icon-heading'>{matchedItem?.title7}</h2>
                                    <p>{matchedItem?.description7}</p>
                                    <ul className='blog-detial-list'>
                                        {matchedItem?.description7List?.map((list, index) => {
                                            return <li key={index}>{list}</li>
                                        })}
                                    </ul>
                                </div>

                                <div className="mt-5">
                                    <h2 className='social-icon-heading'>{matchedItem?.title8}</h2>
                                    <p>{matchedItem?.description8}</p>
                                    <ul className='blog-detial-list'>
                                        {matchedItem?.description8List?.map((list, index) => {
                                            return <li key={index}>{list}</li>
                                        })}
                                    </ul>
                                </div>

                                <div className="mt-5">
                                    <h2 className='social-icon-heading'>{matchedItem?.title9}</h2>
                                    <p>{matchedItem?.description9}</p>
                                    <ul className='blog-detial-list'>
                                        {matchedItem?.description9List?.map((list, index) => {
                                            return <li key={index}>{list}</li>
                                        })}
                                    </ul>
                                </div>

                                <div className="mt-5">
                                    <h2 className='social-icon-heading'>{matchedItem?.title9}</h2>
                                    <p>{matchedItem?.description9}</p>
                                    <ul className='blog-detial-list'>
                                        {matchedItem?.description9List?.map((list, index) => {
                                            return <li key={index}>{list}</li>
                                        })}
                                    </ul>
                                </div>

                                <div className="mt-5">
                                    <h2 className='social-icon-heading'>{matchedItem?.title10}</h2>
                                    <p>{matchedItem?.description10}</p>
                                    <ul className='blog-detial-list'>
                                        {matchedItem?.description10List?.map((list, index) => {
                                            return <li key={index}>{list}</li>
                                        })}
                                    </ul>
                                </div>

                                <div className="mt-5">
                                    <h2 className='social-icon-heading'>{matchedItem?.title11}</h2>
                                    <p>{matchedItem?.description11}</p>
                                    <ul className='blog-detial-list'>
                                        {matchedItem?.description11List?.map((list, index) => {
                                            return <li key={index}>{list}</li>
                                        })}
                                    </ul>
                                </div>

                                <div className="mt-5">
                                    <h2 className='social-icon-heading'>{matchedItem?.title12}</h2>
                                    <p>{matchedItem?.description12}</p>
                                    <ul className='blog-detial-list'>
                                        {matchedItem?.description12List?.map((list, index) => {
                                            return <li key={index}>{list}</li>
                                        })}
                                    </ul>
                                </div>

                                <div className="mt-5">
                                    <h2 className='social-icon-heading'>{matchedItem?.title13}</h2>
                                    <p>{matchedItem?.description13}</p>
                                    <ul className='blog-detial-list'>
                                        {matchedItem?.description13List?.map((list, index) => {
                                            return <li key={index}>{list}</li>
                                        })}
                                    </ul>
                                </div>

                                <div className="mt-5">
                                    <h2 className='social-icon-heading'>{matchedItem?.title14}</h2>
                                    <p>{matchedItem?.description14}</p>
                                    <ul className='blog-detial-list'>
                                        {matchedItem?.description14List?.map((list, index) => {
                                            return <li key={index}>{list}</li>
                                        })}
                                    </ul>
                                </div>

                                <div className="mt-5">
                                    <h2 className='social-icon-heading'>{matchedItem?.title15}</h2>
                                    <p>{matchedItem?.description15}</p>
                                    <ul className='blog-detial-list'>
                                        {matchedItem?.description15List?.map((list, index) => {
                                            return <li key={index}>{list}</li>
                                        })}
                                    </ul>
                                </div>

                                <div className="mt-5">
                                    <h2 className='social-icon-heading'>{matchedItem?.title16}</h2>
                                    <p>{matchedItem?.description16}</p>
                                    <ul className='blog-detial-list'>
                                        {matchedItem?.description16List?.map((list, index) => {
                                            return <li key={index}>{list}</li>
                                        })}
                                    </ul>
                                </div>

                                <div className="mt-5">
                                    <h2 className='social-icon-heading'>{matchedItem?.title17}</h2>
                                    <p>{matchedItem?.description17}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            <Footer />
        </>
    )
}

export default BlogDetails
export const services = [
    {
        title: "DevOps Consulting",
        description:
            "Our DevOps consulting services provide a clear roadmap and detailed planning to enhance your development lifecycle, ensuring your DevOps transformation is smooth and aligned with your business goals.",
    },
    {
        title: "Cloud Services",
        description:
            "Benefit from the cloud tech with our expertise in cloud services for seamless cloud migration, optimization, and containerization. We help you manage databases, implement scalable solutions, and ensure your cloud infrastructure is both efficient and cost-effective.",
    },
    {
        title: "Continuous Integration and Continuous Development",
        description:
            "Implementing CI/CD with us means high-quality code and faster delivery cycles. We enhance the transparency of work, facilitate quicker bug fixes, and support iterative development, ensuring your software is always up-to-date and ready for deployment.",
    },
    {
        title: "DevOps Automation Implementation",
        description:
            "Automate your processes and streamline your delivery pipeline with our DevOps automation services. We handle everything from infrastructure management to orchestration and deployment, reducing manual tasks and accelerating your software release cycle.",
    },
    {
        title: "Microservices Management, Kubernetes, and Containerization",
        description:
            "Adopt microservices architecture with our expert guidance on containerization technologies like Docker and Kubernetes. We ensure your applications are scalable, flexible, and resilient, enabling you to manage complex deployments easily.",
    },
    {
        title: "Legacy Environment Migration",
        description:
            "Modernize your legacy systems with our engineering modernization services powered by DevOps. We ensure a smooth transition to newer, more efficient environments that align with your current and future business needs.",
    },
    {
        title: "DevSecOps",
        description:
            "Our DevSecOps services provide comprehensive security audits, implement cloud security measures, and ensure that your applications are protected from threats, all while maintaining the agility and speed of DevOps processes.",
    },
];

export const processStepsData = [
    {
        stepNumber: "01",
        title: "Faster Time-to-Market",
        content:
            "Speed up your software development and deployment processes, ensuring you can respond quickly to market demands.",
        icon: "/images/FAST.png",
        bgClass: "icon-change",
    },
    {
        stepNumber: "02",
        title: "Improved Collaboration",
        content:
            "Foster better teamwork and communication between development and operations, reducing bottlenecks and improving efficiency.",
        icon: "/images/FULL-ownership.png",
        bgClass: "",
    },
    {
        stepNumber: "03",
        title: "Increased Efficiency",
        content:
            "Automate repetitive tasks to reduce manual errors and increase productivity across your teams.",
        icon: "/images/FLEXIBILITY.png",
        bgClass: "",
    },
    {
        stepNumber: "04",
        title: "Scalability",
        content:
            "Easily scale your applications and infrastructure to handle increased traffic and business growth without disruptions.",
        icon: "/images/scalability.png",
        bgClass: "icon-change",
    },
    {
        stepNumber: "05",
        title: "Enhanced Security",
        content:
            "Integrate security from the start with continuous monitoring and automated compliance checks throughout the development lifecycle.",
        icon: "/images/SECURE.png",
        bgClass: "",
    },
    {
        stepNumber: "06",
        title: "Adaptability",
        content:
            "Stay agile and responsive to market changes with the flexibility provided by microservices management and containerization technologies like Kubernetes and Docker.",
        icon: "/images/req-icon3.svg",
        bgClass: "",
    },
];

export const HireUsData = [
    {
        id: 1,
        icon: "/images/icon1.png",
        heading: "Integrating DevOps with Existing IT Infrastructure",
        chalange: "Integrating DevOps practices into existing, often outdated, IT infrastructure.",
        solution:
            "We conduct a thorough analysis of your current IT environment and create a tailored modernization strategy. We ensure seamless integration of DevOps practices with your existing systems, minimizing disruptions and maximizing efficiency.",
        borderClass: "border-1",
    },
    {
        id: 2,
        icon: "/images/icon2.png",
        heading: "Managing Security and Compliance",
        chalange:
            "Balancing the speed of DevOps with the need for stringent security measures and compliance with industry regulations can be difficult.",
        solution:
            "We integrate security protocols at every stage of the DevOps pipeline. Our DevSecOps practices ensure that security and compliance are maintained without slowing down your development processes.",
        borderClass: "border-2",
    },
    {
        id: 3,
        icon: "/images/icon3.png",
        heading: "Continuous Delivery and Deployment Complexity",
        chalange:
            "Implementing continuous integration and continuous deployment (CI/CD) can be complex, especially in environments with frequent updates and multiple teams.",
        solution:
            "We simplify CI/CD by automating the integration and deployment processes. We use advanced DevOps tools to ensure that code is tested, integrated, and deployed efficiently, reducing the risk of errors and accelerating release cycles.",
        borderClass: "border-3",
    },
    {
        id: 4,
        icon: "/images/icon4.png",
        heading: "Lack of Collaboration Between Teams",
        chalange:
            " Poor collaboration between development, operations, and security teams can lead to bottlenecks and misalignment of goals.",
        solution:
            "Bitsclan fosters a culture of collaboration through structured communication channels and shared tools. Our DevOps solutions are designed to break down silos, ensuring that all teams work together seamlessly towards common objectives.",
        borderClass: "border-1",
    },
    {
        id: 5,
        icon: "/images/icon5.png",
        heading: "Scaling DevOps Practices",
        chalange:
            "As businesses grow, scaling DevOps practices across multiple teams and applications becomes increasingly challenging.",
        solution:
            " We implement microservices architecture, containerization, and cloud-based platforms to ensure that your DevOps practices can easily scale, supporting your expanding operations without compromising performance.",
        borderClass: "border-2",
    },
];


export const solutionsData = [
    {
        title: "Expert Team",
        description:
            "We bring innovative, tech-savvy solutions to streamline your operations and drive efficiency.",
    },
    {
        title: "Holistic Service",
        description:
            "From initial consultation and planning to implementation and ongoing support, we manage the entire DevOps lifecycle.",
    },
    {
        title: "High-Quality Delivery",
        description:
            "We prioritize quality at every stage, with rigorous testing, monitoring, and validation to ensure reliable and secure outcomes. ",
        noBorder: true,
    },
    {
        title: "Scalability & Flexibility",
        description:
            "We ensure your infrastructure can grow with your business, providing the flexibility needed to stay competitive.",
    },
    {
        title: "Security-First Approach",
        description:
            "Our DevOps services incorporate security practices from the ground up, ensuring that your applications and data are protected.",
    },
    {
        title: "Time Zone Alignment",
        description:
            "We ensure seamless collaboration by aligning with your time zone, allowing for real-time communication and quick decision-making.",
    },
];

export const tabs = [
    "Database",
    "Backend",
    "Mobile",
    "Frontend",
    "Frameworks",
    "Cloud",
    "DevOps",
    "Ecommerce",
    "Platforms",
];

export const tabItems = [
    { tab: "Frontend", icon: "/images/iconone.png", name: "Angular JS" },
    { tab: "Frontend", icon: "/images/icontwo.png", name: "JavaScript" },
    { tab: "Frontend", icon: "/images/iconthree.png", name: "React JS" },
    { tab: "Frontend", icon: "/images/icon4.png", name: "Vue JS" },
    { tab: "Frontend", icon: "/images/icon5.png", name: "Tez JS" },
    { tab: "Frontend", icon: "/images/icon6.png", name: "CSS3" },
    { tab: "Frontend", icon: "/images/icon7.png", name: "HTML5" },
    { tab: "Backend", icon: "/images/database1.png", name: ".Net" },
    { tab: "Backend", icon: "/images/database2.png", name: "Nest Js" },
    { tab: "Backend", icon: "/images/database3.png", name: "Meteor JS" },
    { tab: "Backend", icon: "/images/database4.png", name: "Fastify" },
    { tab: "Backend", icon: "/images/database5.png", name: "Express JS" },
    { tab: "Backend", icon: "/images/database6.png", name: "Python" },
    { tab: "Backend", icon: "/images/database7.png", name: "Java" },
    { tab: "Backend", icon: "/images/database8.png", name: "PHP" },
    { tab: "Backend", icon: "/images/database9.png", name: "Node Js" },
    { tab: "Mobile", icon: "/images/mobile1.png", name: "Android" },
    { tab: "Mobile", icon: "/images/mobile2.png", name: "IOS" },
    { tab: "Mobile", icon: "/images/mobile3.png", name: "Swift" },
    { tab: "Mobile", icon: "/images/mobile4.png", name: "Flutter" },
    { tab: "Mobile", icon: "/images/mobile5.png", name: "Xamarin" },
    { tab: "Mobile", icon: "/images/mobile6.png", name: "React Native" },
    { tab: "Database", icon: "/images/db1.png", name: "Firebase" },
    { tab: "Database", icon: "/images/db2.png", name: "Oracle " },
    { tab: "Database", icon: "/images/db3.png", name: "MongoDB" },
    { tab: "Database", icon: "/images/tech-stack-icons/postgres.svg", name: "PostgreSQL" },
    { tab: "Database", icon: "/images/db5.png", name: "MySQL" },
    { tab: "Database", icon: "/images/db6.png", name: "SQL Server" },
    { tab: "Database", icon: "/images/db7.png", name: "SQLite" },
    { tab: "Database", icon: "/images/db8.png", name: "DynamoDB" },
    { tab: "Frameworks", icon: "/images/framework1.png", name: "MEAN" },
    { tab: "Frameworks", icon: "/images/framework2.png", name: "CodeIgniter" },
    { tab: "Frameworks", icon: "/images/framework3.png", name: "Laravel" },
    { tab: "Frameworks", icon: "/images/framework4.png", name: "Rxweb.io" },
    { tab: "Frameworks", icon: "/images/framework5.png", name: "CakePHP" },
    { tab: "Frameworks", icon: "/images/framework6.png", name: "Django" },
    { tab: "Cloud", icon: "/images/cloud1.png", name: "Google Cloud" },
    { tab: "Cloud", icon: "/images/cloud2.png", name: "Azure" },
    { tab: "Cloud", icon: "/images/cloud3.png", name: "AWS" },
    { tab: "DevOps", icon: "/images/devops1.png", name: "Gradle" },
    { tab: "DevOps", icon: "/images/devops2.png", name: "Kubernetes" },
    { tab: "DevOps", icon: "/images/devops3.png", name: "Docker" },
    { tab: "DevOps", icon: "/images/devops4.png", name: "Jenkins" },
    { tab: "Ecommerce", icon: "/images/ecomres1.png", name: "NopCommerce" },
    { tab: "Ecommerce", icon: "/images/ecomres2.png", name: "Kentico" },
    { tab: "Ecommerce", icon: "/images/ecomres3.png", name: "Shopify" },
    { tab: "Ecommerce", icon: "/images/ecomres4.png", name: "Magento" },
    { tab: "Ecommerce", icon: "/images/ecomres5.png", name: "WooCommerce" },
    { tab: "Platforms", icon: "/images/platform1.png", name: "Servicenow " },
    { tab: "Platforms", icon: "/images/platform2.png", name: "Tableau" },
    {
        tab: "Platforms",
        icon: "/images/platform3.png",
        name: "MS Dynamics CRM",
    },
    { tab: "Platforms", icon: "/images/platform4.png", name: "Zoho" },
    { tab: "Platforms", icon: "/images/platform5.png", name: "Power BI" },
    { tab: "Platforms", icon: "/images/platform6.png", name: "Salesforce " },
];

export var mainBannerImg = {
    backgroundImage: `url(/images/what-we-do/devops-services-and-solutions.png)`,
};
import React from "react";
import LifeBanner from "../components/lifeBanner/LifeBanner";
import MeetOurTeam from "../components/meetourteam/MeetOurTeam";
import PerkAndBenifit from "../components/perkandbenifit/PerkAndBenifit";
import { Helmet } from "react-helmet";
import MegaHeader from "../components/MegaHeader/MegaHeader";
import CeoIntro from "../components/CeoIntro/CeoIntro";
import LifeGallery from "../components/LifeGallery/LifeGallery";
import ContactSection from "../components/contactSection/ContactSection";
import { useSelector } from "react-redux";
import Footer from "../components/footer/Footer";

const LifeAtBitsclan = () => {
  const country = useSelector((state) => state.country.name);

  return (
    <>
      <Helmet>
        <title>Careers at Bitsclan | Explore Opportunities & Culture</title>
        <meta
          name="description"
          content="Join Bitsclan and be part of an innovative team. Discover exciting career opportunities in software development and IT"
        />
      </Helmet>
      <MegaHeader />
      <LifeBanner />
      <MeetOurTeam />
      <CeoIntro />
      <PerkAndBenifit />
      <LifeGallery />
      {country !== "PK" &&  <ContactSection />}
      {country !== "PK" && <Footer />}
    </>
  );
};

export default LifeAtBitsclan;

export const mainBannerImg = {
    backgroundImage: `url(/images/hire-page-images/hire-python.png)`,
    factBackgroundImage: `url(/images/hire-page-images/hire-python.svg)`
};

export const techCombinations = [
    {
        title: "Python + Django + PostgreSQL",
        description:
            "Django, a high-level Python framework, combined with PostgreSQL ensures secure, scalable, and high-performance web applications.",
        icons: ["python.svg", "django.svg", "postgres.svg"],
    },
    {
        title: "Python + Flask + MySQL",
        description:
            "Flask, a lightweight Python web framework, paired with MySQL enables rapid development of scalable web applications.",
        icons: ["python.svg", "Flask.svg", "mysql-whale-icon.svg"],
    },
    {
        title: "Python + FastAPI + MongoDB",
        description:
            "FastAPI provides high-speed API development with Python, while MongoDB serves as a flexible NoSQL database for modern applications.",
        icons: ["python.svg", "fast-api.svg", "mongodb.svg"],
    },
    {
        title: "Python + React + PostgreSQL",
        description:
            "React for dynamic front-end development, Django or Flask for backend logic, and PostgreSQL for a robust relational database solution.",
        icons: ["python.svg", "react.svg", "postgres.svg"],
    },
    {
        title: "Python + TensorFlow + AWS",
        description:
            "Leverage Python with TensorFlow for AI and ML applications, backed by AWS cloud services for scalable computing and storage.",
        icons: ["python.svg", "tensorflow.svg", "aws.svg"],
    },
    {
        title: "Python + FastAPI + GraphQL",
        description:
            "FastAPI as a high-performance backend with GraphQL enables efficient API development and data fetching for modern applications.",
        icons: ["python.svg", "fast-api.svg", "graphql.svg"],
    },
];

export const bannerList = [
    { list: "Freelance contractors" },
    { list: "Full-time roles" },
    { list: "Global teams" },
];

export const solutionsData = [
    {
        icon: "/images/hire-page-icons/chose-ror-section1.svg",
        title: "Access Vetted Talent",
        description:
            "With us, you can meet Python developers for hire who have been fully vetted for their expertise and strong communication skills in English. We ensure that the developers we provide are skilled and reliable.",
    },
    {
        icon: "/images/hire-page-icons/chose-ror-section2.svg",
        title: "View matches in seconds",
        description:
            "Forget about sifting through endless resumes. With HireAI, you can quickly view top Python developers and find the perfect match for your project. Get the talent you need in just a few clicks.",
    },
    {
        icon: "/images/hire-page-icons/chose-ror-section3.svg",
        title: "Save with Global Hires",
        description:
            "With access to a global pool of over 450,000 developers from 190 countries, you can save up to 58% compared to traditional hiring. Get the best Python developers without the high costs of local hiring.",
        noBorder: true,
    },
    {
        icon: "/images/hire-page-icons/chose-ror-section4.svg",
        title: "Get real human support",
        description:
            "Feel confident when you hire Python developers with help from our team of expert recruiters. We’re here to guide you through the process, making sure you find the best talent for your needs, and ensuring everything runs smoothly.",
    },
];

export const servicesData = [
    {
        title: "Python Consulting",
        description:
            "Take advantage of our expert Python developers for hire who can offer valuable insights and strategic advice to help you get the most out of your Python projects. We guide you through the best practices and innovative approaches, ensuring your project’s success.",
        icon: "/images/hire-page-icons/hire-python-icons8.svg",
    },
    {
        title: "Python web Development",
        description:
            "Our developers are skilled in building feature-rich web applications using Python. From simple websites to complex platforms, we provide scalable solutions that meet your business needs while offering great user experiences.",
        icon: "/images/hire-page-icons/hire-python-icons1.svg",
    },
    {
        title: "Python API Development",
        description:
            "We have extensive experience in developing robust APIs with Python, enabling seamless integration and smooth communication between different applications. Our Python development services ensure that your APIs are powerful, secure, and easy to manage.",
        icon: "/images/hire-page-icons/hire-python-icons2.svg",
    },
    {
        title: "CMS and Portal Development",
        description:
            "Whether you need a content management system (CMS) or a custom web portal, our developers are experts at using Python to create sophisticated, easy-to-use platforms. We build solutions that are both secure and scalable, ensuring your content is well-managed and accessible.",
        icon: "/images/hire-page-icons/hire-python-icons4.svg",
    },
    {
        title: "Migration to Python",
        description:
            "If you're looking to migrate your existing systems to Python, our experts can handle it smoothly. We provide custom Python development solutions that ensure minimal disruption during the migration process, so your business continues to run efficiently.",
        icon: "/images/hire-page-icons/hire-python-icons5.svg",
    },
    {
        title: "Integration with Existing Solutions",
        description:
            "Our Python developers are skilled at integrating Python applications with various platforms and services. Whether you need to connect with third-party systems or expand existing solutions, we offer seamless integration services that enhance functionality and performance.",
        icon: "/images/hire-page-icons/hire-python-icons6.svg",
    },
    {
        title: "Python eCommerce Solutions",
        description:
            "We specialize in building powerful Python eCommerce solutions that improve user experience and make online shopping more interactive. With our Python developers’ expertise, we ensure your eCommerce platform is robust, secure, and easy to manage.",
        icon: "/images/hire-page-icons/hire-python-icons7.svg",
    },
    {
        title: "Python Support & Maintenance",
        description:
            "We don't just build your Python application; we ensure it runs smoothly long after the launch. Our dedicated Python support and maintenance services keep your applications stable, secure, and updated, so you can focus on growing your business.",
        icon: "/images/hire-page-icons/hire-python-icons8.svg",
    },
];

export const hirePageStacks = [
    {
        title: "Junior Python Developer",
        description: [
            "2 Years' experience",
            "Fresh perspectives & innovative ideas",
            "Dedicated to explore web development",
        ],
        linkText: "Connect to Hire",
        link: "#",
        borderClass: "border-1",
    },
    {
        title: "Senior Python Developer",
        description: [
            "2+ years' experience",
            "Leadership in complex projects",
            "Proven app deployment success",
        ],
        linkText: "Connect to Hire",
        link: "#",
        borderClass: "border-2",
    },
    {
        title: "Python Tech Lead",
        description: [
            "5+ years' experience",
            "Skilled with Jira, Asana, MS Teams, etc.",
            "Suitable when you Hire Web App Team",
        ],
        linkText: "Connect to Hire",
        link: "#",
        borderClass: "border-3",
    },
];

export const benefits = [
    {
        service: "Python CMS Development",
        description: "Get an upper hand on your web content, augment efficiency, and develop a shared environment with our Python CMS solutions."
    },
    {
        service: "Python Migrations",
        description: "Our Python engineers strategically migrate legacy content into modern systems in a secured, error-free, and robust way."
    },
    {
        service: "Advanced Python Programming",
        description: "Our experts have extensive experience in using Python tech stack for tangible results to reach your business vision."
    },
    {
        service: "Python Integration and Optimization",
        description: "Leverage the full-scale potential of Python integration and optimization functionalities for your app development needs."
    },
    {
        service: "Customized Python Solutions",
        description: "Our Python developers delve into ideation, reinvention, and scale development as per your enterprise's unique IT demands."
    },
    {
        service: "Python eCommerce App",
        description: "With using modern Python tools and technologies, we have a proven track record of developing scalable and secured eCommerce sites."
    }
];

export const slidesData = [
    {
        title: "Rapid Development",
        content:
            "Python’s built-in tools and features allow for faster development, reducing time-to-market.",
        backgroundClass: "bg-1",
    },
    {
        title: "Scalability",
        content:
            "Python is designed to handle high-traffic and complex applications, making it perfect for growing businesses",
        backgroundClass: "bg-2",
    },
    {
        title: "Security",
        content:
            "With built-in protection against common security threats like SQL injection and cross-site scripting, Python ensures secure applications",
        backgroundClass: "bg-3",
    },
    {
        title: "Versatility",
        content:
            "From simple websites to complex web apps, Python is flexible enough to handle a variety of development needs",
        backgroundClass: "bg-4",
    },
    {
        title: "Maintainability",
        content:
            'Its clean architecture and "Don’t Repeat Yourself" (DRY) principle make Python applications easy to manage and scale over time.',
        backgroundClass: "bg-1",
    },
];

export const steps = [
    {
        title: "Choose Your Engagement Model",
        description: "Pick the engagement model that best suits your project needs. Whether it's full-time, part-time, or hourly, we tailor the solution to your specific requirements.",
        icon: "/images/hire-page-setps-icons/icon-1.svg"
    },
    {
        title: "Screen & Select Python Developers",
        description: "Browse through our Python developers' profiles and select the best candidates for your project. We ensure you have access to the most qualified developers to meet your project needs.",
        icon: "/images/hire-page-setps-icons/icon-2.svg"
    },
    {
        title: "Conduct One-on-One Interview",
        description: "After you’ve selected your candidates, interview them directly. You can assess their skills through both theoretical and practical questions to make sure they’re the right fit for your project.",
        icon: "/images/hire-page-setps-icons/icon-3.svg"
    },
    {
        title: "Python Developers",
        description: "Once you’ve made your choice, the selected Python developer will join your team within 24-48 hours after final confirmation. Get started with ease and confidence, knowing your project is in good hands.",
        icon: "/images/hire-page-setps-icons/icon-4.svg"
    }
];

export const facts = [
    {
        text: "<strong>40+ Python developers:</strong> Our team consists of over 40 skilled developers who specialize in Python application development.",
    },
    {
        text: "<strong>25.98 years of combined experience:</strong> With an average of nearly 26 years of experience, our team has a deep understanding of Python development.",
    },
    { text: "<strong>Expertise across diverse areas:</strong> We have experience in custom Python development solutions, including web development, API development, and system integrations." },
    {
        text: "<strong>Advanced cloud and serverless skills:</strong> Our developers are well-versed in building secure web development with Python, following industry best practices.",
    },
    { text: "<strong>Experience in data-driven solutions:</strong> We work on big data, machine learning, and more to create powerful Python-based applications." },
];

export const WhoWeServeData = [
    {
        icon: "/images/servie-icon/startups.svg",
        title: "Budget savings",
        description: "Assembling a local Python team is unavoidably expensive. By approaching Innowise. you bypass many hurdles and avoid redundantexpenses such as training fees. taxes. and software licenses.",
        link: "#",
        borderClass: "border-1",
    },
    {
        icon: "/images/servie-icon/companies.svg",
        title: "Software product companies",
        description: "When you hire ROR developers. you avoid the risks of budget overruns and missed deadlines. Innowise provides solid risk management plans and has an array of risk mitigation measures",
        link: "#",
        borderClass: "border-2",
    },
    {
        icon: "/images/servie-icon/industries.svg",
        title: "Enterprises from 30+ industries",
        description:
            "Innowise has experts with excellent development backgrounds. so you can rest assured that your project is in good hands. Pyrggyelgprneg",
        link: "#",
        borderClass: "border-3",
    },
];

export const hirignDetails = [
    {
        text: "Hourly Hiring",
        hours: "48",
        icon: "time.svg",
        bgColor: "#FDFDFF"
    },

    {
        text: "Full Time Hiring",
        hours: "72",
        icon: "full-time.svg",
        bgColor: "#FFFEFA"
    },

    {
        text: "Part Time Hiring",
        hours: "48",
        icon: "part-time.svg",
        bgColor: "#FDFFFA"
    },
]

export const hirePageDeliver = [
    {
        image: "/images/hire-page-images/card-1.png",
        title: "Complex SaaS systems",
        description: "Innowise provides its expertise in the area of building complex platforms for businesses to streamline workflows. The main applications we develop include HRM, CRM, ERP, accounting software, etc."
    },
    {
        image: "/images/hire-page-images/card-2.png", // Assumed path based on provided example
        title: "Complex SaaS systems",
        description: "Innowise provides its expertise in the area of building complex platforms for businesses to streamline workflows. The main applications we develop include HRM, CRM, ERP, accounting software, etc."
    },
    {
        image: "/images/hire-page-images/card-3.png", // Assumed path based on provided example
        title: "Complex SaaS systems",
        description: "Innowise provides its expertise in the area of building complex platforms for businesses to streamline workflows. The main applications we develop include HRM, CRM, ERP, accounting software, etc."
    },
];

export const faqData = [
    {
        id: "collapseOne",
        question: " What is Python development?",
        answer:
            "Python development refers to building applications, websites, and software using Python, a versatile and easy-to-learn programming language. It’s known for its simplicity, making it a popular choice for developers to create web applications, automate tasks, and handle data-driven projects."
    },
    {
        id: "collapseTwo",
        question: " Why should I hire Python developers?",
        answer:
            "Hiring Python developers ensures that you get a team of experts who can efficiently create scalable, secure, and high-performance applications. Python is widely used for web development, data analysis, and automation, making it perfect for building custom solutions tailored to your needs.",
    },
    {
        id: "collapseThree",
        question: "What services do Python developers offer?",
        answer:
            "Python developers offer a range of Python development services including web development, API creation, CMS and portal development, data analytics, and system integration. They can also help migrate your existing systems to Python or build new applications from scratch.",
    },
    {
        id: "fourth",
        question: "How do you ensure the quality of Python development?",
        answer:
            "Our Python developers follow industry best practices to deliver secure web development with Python. They use modern frameworks, ensure thorough testing, and work in an agile environment to make sure your application is high quality, scalable, and user-friendly.",
    },
    {
        id: "fifth",
        question: " Can I hire Python developers for short-term projects?",
        answer:
            "Yes, you can hire Python developers for hire on a short-term or long-term basis. Whether you need a developer for a few months or a few weeks, we offer flexible engagement models like full-time, part-time, or hourly contracts to fit your project needs.",
    },
    {
        id: "sixth",
        question: "How long does it take to develop a Python application?",
        answer:
            "The time it takes to develop a Python application depends on the complexity of the project. For example, building a basic website may take a few weeks, while a more complex system or custom Python solution could take several months. We ensure timely delivery with expert planning and development practices.",
    },
];


export const tabs = [
    "Database",
    "Libraries",
    "Framework",
    "DevOps",
    "Testing",
];

export const tabItems = [
    { tab: "Framework", icon: "/images/tech-stack-icons/pytorch.svg", name: "PyTorch" },
    { tab: "Framework", icon: "/images/tech-stack-icons/caffe.svg", name: "Caffe" },
    { tab: "Framework", icon: "/images/tech-stack-icons/theano.svg", name: "Theano" },
    { tab: "Framework", icon: "/images/tech-stack-icons/Flask.svg", name: "Flask" },
    { tab: "Framework", icon: "/images/tech-stack-icons/bottle.svg", name: "Bottle" },
    { tab: "Framework", icon: "/images/tech-stack-icons/cherrypy.svg", name: "CherryPy" },
    { tab: "Framework", icon: "/images/tech-stack-icons/django.svg", name: "Django" },
    { tab: "Framework", icon: "/images/tech-stack-icons/ember.svg", name: "FastAPI" },
    { tab: "Framework", icon: "/images/tech-stack-icons/fast-api.svg", name: "FastAPI" },
    { tab: "Framework", icon: "/images/tech-stack-icons/Pyramid.svg", name: "Pyramid" },
    { tab: "Framework", icon: "/images/tech-stack-icons/tornado.svg", name: "Tornado" },
    { tab: "Framework", icon: "/images/tech-stack-icons/py-qt.svg", name: "PyQt" },
    { tab: "Framework", icon: "/images/tech-stack-icons/dash-framework.svg", name: "Dash Framework" },
    { tab: "Database", icon: "/images/tech-stack-icons/postgres.svg", name: "PostgreSQL" },
    { tab: "Database", icon: "/images/db3.png", name: "MongoDB" },
    { tab: "Database", icon: "/images/db5.png", name: "MySQL" },
    { tab: "Database", icon: "/images/tech-stack-icons/oracals.svg", name: "Oracle" },
    { tab: "Database", icon: "/images/db7.png", name: "SQLite" },
    { tab: "Database", icon: "/images/tech-stack-icons/redis.svg", name: "Redis" },
    { tab: "Database", icon: "/images/tech-stack-icons/couchdb.svg", name: "Couch DB" },
    { tab: "Database", icon: "/images/db8.png", name: "DynamoDB" },
    { tab: "Database", icon: "/images/tech-stack-icons/neo4j.svg", name: "Neo4j" },
    { tab: "Database", icon: "/images/tech-stack-icons/snowflake.svg", name: "SnowFlake" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/request.svg", name: "Requests" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/scrapy.svg", name: "Scrapy" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/pillow.svg", name: "Pillow" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/fabric.svg", name: "Fabric" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/scikit-learn.svg", name: "Scikit-learn" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/fast-api.svg", name: "FastAPI" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/numpy.svg", name: "NumPy" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/pandas.svg", name: "Pandas" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/matplotlib.svg", name: "Matplotlib" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/scipy.svg", name: "SciPy" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/tensorflow.svg", name: "TensorFlow" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/pytorch.svg", name: "PyTorch" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/keras.svg", name: "Keras" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/django.svg", name: "Django" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/Streamlit.svg", name: "Stream Lit" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/boto.svg", name: "Boto3" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/Flask.svg", name: "Flask" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/beautiful.svg", name: "Beautiful Soup" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/open-cv.svg", name: "OpenCV" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/seaborn.svg", name: "Seaborn" },
    { tab: "DevOps", icon: "/images/tech-stack-icons/docker.svg", name: "Docker" },
    { tab: "DevOps", icon: "/images/tech-stack-icons/azure.svg", name: "Azure" },
    { tab: "DevOps", icon: "/images/tech-stack-icons/kubernetes.svg", name: "Kubernetes" },
    { tab: "DevOps", icon: "/images/tech-stack-icons/google-cloud.svg", name: "Google Cloud" },
    { tab: "DevOps", icon: "/images/tech-stack-icons/serverless-functions.svg", name: "Serverless Functions" },
    { tab: "DevOps", icon: "/images/tech-stack-icons/aws.svg", name: "AWS" },
    { tab: "DevOps", icon: "/images/tech-stack-icons/digital-ocean.svg", name: "Digital Ocean" },
    { tab: "Testing", icon: "/images/tech-stack-icons/postman.svg", name: "Postman" },
    { tab: "Testing", icon: "/images/tech-stack-icons/seaborn.svg", name: "Jmeter" },
    { tab: "Testing", icon: "/images/tech-stack-icons/selenium.svg", name: "Selenium" },
    { tab: "Testing", icon: "/images/tech-stack-icons/cypress.svg", name: "Cypress" },
    { tab: "Testing", icon: "/images/tech-stack-icons/browserstack.svg", name: "BrowserStack" },
    { tab: "Testing", icon: "/images/tech-stack-icons/bugzilla.svg", name: "Bugzilla" },
    { tab: "Testing", icon: "/images/tech-stack-icons/soap-icon.svg", name: "SoapUI" },
    { tab: "Testing", icon: "/images/tech-stack-icons/jasmine.svg", name: "Jasmine" },
    { tab: "Testing", icon: "/images/tech-stack-icons/karma.svg", name: "Karma" },
    { tab: "Testing", icon: "/images/tech-stack-icons/jenkins.svg", name: "Jenkins" },
];
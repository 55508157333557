import React, { useEffect } from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import SimilerBanner from "../components/similerBanner/SimilerBanner";
import CustomSoftware from "../components/custom-software/CustomSoftware";
import DevelopmentProcess from "../components/developmentProcess/DevelopmentProcess";
import PioneeringChange from "../components/pioneeringChange/PioneeringChange";
import AnalyticsSolutions from "../components/analyticsSolution/AnalyticsSolutions";
import TrustedPartner from "../components/trustedPartners/TrustedPartner";
import BigRoadMap from "../components/bigRoadMap/BigRoadMap";
import Testimonial from "../components/testimonila/Testimonial";
import WhyUsBox from "../components/whyUsBox/WhyUsBox";
import CoverTheStack from "../components/coverthestack/CoverTheStack";
import SecondFaq from "../components/secondFaq/SecondFaq";
import AIContactSection from "../components/ai-contact-section/AIContactSection";
import OurLocations from "../components/ourlocations/OurLocations";
import { Helmet } from "react-helmet";
import MegaHeader from "../components/MegaHeader/MegaHeader";
import AIServices from "../components/ai-development-services/AIServices";
import AiServiceForm from "../components/ai-services-form/AiServiceForm";
import HomeLogo from "../components/home-logos/HomeLogo";
import DevelopmentServices from "../components/development-services/DevelopmentServices";
import {
  AnalyticsHighvaluesData,
  AnalyticsWeDoEngagementData,
  AnalyticsWhoWeServeData,
  AnalyticsWhyChooseBitsclanData,
  DataAnalyticsTypesOfBuildData,
  DataAndAnalyticsServicesData,
  faqData,
  mainBannerImg,
  tabItems,
  tabs,
} from "../data/dataAnalyticsPageData";
import TypesOfBuild from "../components/types-of-build/TypesOfBuild";
import WhoWeServe from "../components/who-we-serve/WhoWeServe";
import WhyChooseBitsclan from "../components/why-choose-bitsclan/WhyChooseBitsclan";
import HighValues from "../components/high-values/HighValues";
import WeDoEngagement from "../components/we-do-engagement-models/WeDoEngagement";
import RoadMap from "../components/roadMap/RoadMap";
import {
  IndustryTabsData,
  IndustryTabsItemsData,
} from "../data/industriesTabsData";
import IndustriesTabs from "../components/industries-tabs/IndustriesTabs";
import DevelopingSolution from "../components/developingsolution/DevelopingSolution";
import TrendsInsights from "../components/trends-n-insights/TrendsInsights";
import PageScroller from "../components/page-scroller/PageScroller";
import ContactSection from "../components/contactSection/ContactSection";
import { WebServicesIndustryTabsItemsData } from "../data/web-app-service-page";
import NewEngagementModels from "../components/new-engagment-modal";

const DataAndAnalytics = () => {



  return (
    <>
      <Helmet>
        <title>
          Data Analytics Services | Data Science | Bitsclan IT Solutions
        </title>
        <meta
          name="description"
          content="Unlock valuable insights with our data analytics services, turning complex data into actionable business strategies"
        />
      </Helmet>

      {/* <Header /> */}

      <div className="sticky-header">
        <MegaHeader />
      </div>

      <div className="data-and-analytics">
        <SimilerBanner
          diffBannerBackgroundImage={mainBannerImg}
          mainHeading="Data Analytics"
          greenHeading="Services"
          subHeading="Trusted by Startups, SMBs, and Enterprises alike"
          content="Turn your raw data into actionable insights, make informed decisions, and stay ahead with our data analytics services."
          btnText2={"Let's Get Started"}
          sideImage=""
        />
      </div>

      {/* <PageScroller content={scrollerData} /> */}
      {/* <HomeLogo /> */}
      <Testimonial
        heading="Web Development Company of the Year 2021"
        content="Unlock the full potential of your data with our advanced analytics solutions, empowering your business to make strategic, data-driven decisions with clarity and confidence."
      />

      <DevelopmentServices
        tabs={DataAndAnalyticsServicesData}
        heading="Comprehensive Data & Analytics Services for Your Business"
        subheading="As one of the top data analytics companies, we combine advanced analytics, AI-driven insights, and business intelligence to help you make informed decisions, optimize operations, and drive growth. Whether you need predictive analytics, data visualization, or data analytics consulting, our expert team is here to provide tailored solutions that transform your raw data into actionable insights."
      />

      <TypesOfBuild
        typeOfApps={DataAnalyticsTypesOfBuildData}
        heading="Solving Your Data Challenges with Expert Analytics"
        sideImage="data-and-anylytics.png"
      />

      <AnalyticsSolutions />

      <WhoWeServe
        cardContent={AnalyticsWhoWeServeData}
        heading="Who We Serve"
        subHeading="Data is the backbone of modern businesses, and our data analytics services help companies turn raw information into actionable insights. From startups seeking data-driven strategies to enterprises needing business intelligence solutions, we offer scalable and tailored solutions for all."
      />

      <WhyChooseBitsclan
        tabs={AnalyticsWhyChooseBitsclanData}
        heading="Why Choose Bitsclan as Your Data Analytics Partner?"
        subheading="Choosing the right data analytics services provider is key to unlocking data-driven growth. We simplify big data analytics, streamline business intelligence solutions, and offer data consulting tailored to your needs. Our expert-driven approach ensures data-driven strategies that maximize efficiency, uncover hidden insights, and drive informed decision-making."
      />

      <HighValues
        heading="High-Value Features for Data Analytics Solutions"
        subHeading="Our data analytics services provide top-tier features that convert complex data into meaningful insights. From advanced analytics and data integration to scalable solutions and real-time reporting, our features are designed to ensure data accuracy, security, and actionable insights for improved decision-making and business growth."
        boxContent={AnalyticsHighvaluesData}
        btnLink="#"
        btnText="Consult Project Scope"
      />

      {/* <WeDoEngagement
        heading="Our Engagement Models"
        cardContent={AnalyticsWeDoEngagementData}
      /> */}

      <NewEngagementModels />

      <RoadMap roadMapsteps={[]} roadMapHeading={[]} />

      <CoverTheStack
        whereStart={"Generative AI Models"}
        mainHeading="Our Advanced Data Analytics Technology Stack"
        subHeading="Our data analytics services use a robust tech stack to tackle complex data challenges, ensuring accurate processing, insightful analytics, and secure management."
        tabs={tabs}
        tabItems={tabItems}
      />

      <IndustriesTabs
        mainheading={"Industries we serve data analytics"}
        tabs={IndustryTabsData}
        defaultTab={"Healthcare"}
        tabsItems={WebServicesIndustryTabsItemsData}
      />

      <DevelopingSolution heading="We Make Success Stories" />
      <TrendsInsights specificClass="mobile-dev-page" category="data-analytics" />

      <SecondFaq
        faqs={faqData}
        mainHeading={"Frequently Asked Questions"}
        subHeading={
          "Explore key questions about our data analytics services, from costs to getting started and maximizing business value."
        }
      />

      <ContactSection />
      <Footer />
    </>
  );
};

export default DataAndAnalytics;

import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";

const cookies = new Cookies();

function ClutchIcon({ isAccepted, setIsAccepted }) {
  const [region, setRegion] = useState(null);
  const [showClutch, setShowClutch] = useState(false);
  const [showButtons, setShowButtons] = useState(false);

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const res = await fetch("https://ipinfo.io/json");
        const data = await res.json();
        const countryCode = data.country;

        if (countryCode === "PK") setRegion("PK");
        else if (countryCode === "SA") setRegion("KSA");
        else if (countryCode === "US") setRegion("USA");
        else if (countryCode === "AU") setRegion("AUS");
        else setRegion("");
      } catch (error) {
        setRegion("");
      }
    };

    fetchLocation();
  }, []);

  useEffect(() => {
    const savedForcedRegion = sessionStorage.getItem("forcedRegion");
    if (savedForcedRegion) {
      setShowButtons(true)
    }
  })

  useEffect(() => {
    setIsAccepted(cookies.get("cookiesAccepted"));
  }, []);

  useEffect(() => {
    if (region !== "PK") {
      setShowClutch(true);
    } else {
      setShowClutch(false);
    }
  }, [region]);

  useEffect(() => {
    if (showClutch || showButtons) {
      const script = document.createElement("script");
      script.src = "https://widget.clutch.co/static/js/widget.js";
      script.async = true;
      script.onload = () => {
        if (window.CLUTCHCO) {
          window.CLUTCHCO.Init();
        }
      };
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [showClutch]);


  if (!showClutch && !showButtons) {
    return null;
  }

  return (
    <div
      className="position-fixed bottom-0 left-0 bg-light"
      style={{ zIndex: 9999 }}
    >
      <div
        className="clutch-widget"
        style={{
          width: "210px",
          padding: "10px",
          border: "2px solid #80b927",
        }}
        data-url="https://widget.clutch.co"
        data-widget-type="2"
        data-height="45"
        data-nofollow="true"
        data-expandifr="true"
        data-scale="100"
        data-clutchcompany-id="1007672"
      ></div>
    </div>
  );
}

export default ClutchIcon;
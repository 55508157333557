import React, { useEffect } from "react";
import Footer from "../components/footer/Footer";
import SimilerBanner from "../components/similerBanner/SimilerBanner";
import DevelopmentProcess from "../components/developmentProcess/DevelopmentProcess";
import Testimonial from "../components/testimonila/Testimonial";
import CoverTheStack from "../components/coverthestack/CoverTheStack";
import { Helmet } from "react-helmet";
import MegaHeader from "../components/MegaHeader/MegaHeader";
import {
  BlockchainDevelopmentServicesData,
  blockchaintabItems,
  blockchaintabs,

  BlockchainWhoWeServeData,
  BlockchainWhyChooseBitsclanData,
  mainBannerImg,
  processStepsData,
} from "../data/blockchainPageData";
import DevelopmentServices from "../components/development-services/DevelopmentServices";
import WhoWeServe from "../components/who-we-serve/WhoWeServe";
import WhyChooseBitsclan from "../components/why-choose-bitsclan/WhyChooseBitsclan";
import HighValues from "../components/high-values/HighValues";
import {
  AnalyticsHighvaluesData,
} from "../data/dataAnalyticsPageData";
import RoadMap from "../components/roadMap/RoadMap";
import {
  IndustryTabsData,
} from "../data/industriesTabsData";
import IndustriesTabs from "../components/industries-tabs/IndustriesTabs";
import DevelopingSolution from "../components/developingsolution/DevelopingSolution";
import TrendsInsights from "../components/trends-n-insights/TrendsInsights";
import { DevOpsFaqData } from "../data/devOpsPageData";
import FAQ from "../components/Fqas/FAQ";
import ContactSection from "../components/contactSection/ContactSection";
import { WebServicesIndustryTabsItemsData } from "../data/web-app-service-page";
import NewEngagementModels from "../components/new-engagment-modal";

const BlockChainAnalytics = () => {



  return (
    <>
      <Helmet>
        <title>
          Top Blockchain Development Services | Bitsclan IT Solutions
        </title>
        <meta
          name="description"
          content="Leverage blockchain technology with our expert development services for secure, scalable, and innovative solutions"
        />
      </Helmet>

      <div className="sticky-header">
        <MegaHeader />
      </div>

      <SimilerBanner
        diffBannerBackgroundImage={mainBannerImg}
        mainHeading="Blockchain Development"
        greenHeading="Services"
        subHeading="Drive your business forward with secure, tailored blockchain solutions."
        content="Tap into the decentralized ecosystem with our blockchain development services and position your business for the future."
        sideImage={""}
        btnText2={"Let's Get Started"}
      />

      <Testimonial
        heading="Innovating Security and Transparency Through Cutting-Edge Blockchain Solutions"
        content="Our blockchain development services deliver secure, transparent, and scalable solutions, driving trust and efficiency in every transaction."
      />

      <DevelopmentServices
        tabs={BlockchainDevelopmentServicesData}
        heading="Our Expansive Range of Blockchain Development Services"
        subheading="Unlock the potential of blockchain with secure, scalable, and innovative solutions tailored to your business needs. From smart contracts to decentralized applications, we empower businesses with enhanced transparency, efficiency, and security. Whether you require consulting, custom blockchain development, or enterprise-grade solutions, our expertise ensures seamless integration and optimal performance."
      />

      <DevelopmentProcess
        heading="The Impactful Benefits of Blockchain "
        greenHeading="Technology for Your Business"
        description="Blockchain technology transforms your business with decentralized, secure, and transparent solutions. We help you harness it to drive growth and stay competitive."
        processSteps={processStepsData}
      />

      <WhoWeServe
        cardContent={BlockchainWhoWeServeData}
        heading="Who We Serve"
        subHeading="Blockchain is reshaping industries, offering security, transparency, and efficiency like never before. Our blockchain development services cater to businesses seeking custom blockchain solutions, secure transactions, and decentralized apps (DApps) to drive innovation and growth."
      />

      <WhyChooseBitsclan
        tabs={BlockchainWhyChooseBitsclanData}
        heading="Why Choose Bitsclan as Your Blockchain Development Partner?"
        subheading="Blockchain isn’t just a trend—it’s a transformative technology. At Bitsclan, we deliver secure, scalable, and innovative blockchain solutions designed to elevate your business. Whether you need smart contracts, decentralized applications (DApps), or enterprise blockchain integration, our expertise ensures enhanced security, transparency, and operational efficiency."
      />

      <HighValues
        heading="High-Value Features for Advanced Blockchain Solutions"
        subHeading="Unlock the power of blockchain with secure, scalable, and efficient solutions. From smart contracts to decentralized applications (DApps), our blockchain development services ensure transparency, automation, and enhanced security for your business. We focus on seamless integration, high performance, and innovative blockchain solutions to help you stay ahead in the decentralized landscape. Whether you're securing transactions or building enterprise-grade applications, our expertise ensures reliability and trust."
        boxContent={AnalyticsHighvaluesData}
        btnLink="#"
        btnText="Consult Project Scope"
      />

      <NewEngagementModels />

      <RoadMap heading="Our Proven Roadmap for Successful Blockchain Development" description="We follow a structured approach to ensure your blockchain solutions are secure, scalable, and efficient. From initial planning to deployment, our custom blockchain development process ensures seamless execution, smart contract integration, and long-term reliability. Here's how we do it:" roadMapHeading={[]} roadMapsteps={["We analyze your goals, define the best blockchain strategy, and map out a clear roadmap to ensure smooth execution and future scalability.", "We create a proof of concept to test feasibility, security, and decentralized system architecture, ensuring alignment with your business needs.", "From smart contracts to decentralized apps, we develop and rigorously test your blockchain solutions for performance, security, and efficiency.", "We deploy your custom blockchain development solution and provide ongoing support, ensuring updates, security patches, and seamless operation."]} />

      <CoverTheStack
        whereStart={"Blockchain Platforms"}
        mainHeading={"Tech Stack Powering Our Mobile App Development"}
        subHeading={
          "At Bitsclan, we don’t just build mobile apps; we craft digital experiences powered by the most advanced technologies in the industry. With our expertise spanning the leading tech stack, we leverage the latest tools and platforms to ensure your mobile application is not only functional but also future-proof and optimized for the best user experience."
        }
        tabs={blockchaintabs}
        tabItems={blockchaintabItems}
      />

      <IndustriesTabs
        mainheading={"Industries we serve Blockchain Analytics"}
        tabs={IndustryTabsData}
        defaultTab={"Healthcare"}
        tabsItems={WebServicesIndustryTabsItemsData}
      />

      <DevelopingSolution heading="Developing Solutions That Transcend Mere Digital Transformation" subheading="We deliver innovative solutions that surpass digital transformation, driving impactful and lasting success." />

      <TrendsInsights specificClass="mobile-dev-page" category="blockchain-development" />

      <FAQ
        faqs={DevOpsFaqData}
        mainHeading={"Frequently Asked Questions"}
        subHeading={
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        }
      />

      <ContactSection />

      <Footer />
    </>
  );
};

export default BlockChainAnalytics;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "", // Initial state is an empty string
};

const countrySlice = createSlice({
  name: "country",
  initialState,
  reducers: {
    setCountry: (state, action) => {
      state.name = action.payload; // Update country name
    },
    clearCountry: (state) => {
      state.name = ""; // Reset country name
    },
  },
});

export const { setCountry, clearCountry } = countrySlice.actions;
export default countrySlice.reducer;

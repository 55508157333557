
export const tabs = [
  "Frontend",
  "Backend",
  "Database",
];

export const tabItems = [
  { tab: "Frontend", name: "ReactJS", icon: "/images/iconthree.png", },
  { tab: "Frontend", name: "Vue.js", icon: "/images/icon4.png" },
  { tab: "Backend", name: "Express.js", icon: "/images/database5.png", },
  { tab: "Backend", name: "Laravel", icon: "/images/tech-stack-icons/laravel.svg", },
  { tab: "Backend", name: "Node.js", icon: "/images/database9.png", },
  { tab: "Database", name: "MongoDB", icon: "/images/db3.png", },
];

export const techCombinations = [
  {
    title: "MERN (MongoDB + Express.js + React + Node.js)",
    description:
      "MERN is a powerful full-stack JavaScript framework where MongoDB serves as a NoSQL database, Express.js handles backend logic, React builds dynamic UIs, and Node.js runs the server, making it ideal for modern web applications.",
    icons: ["mongodb.svg", "express.svg", "react.svg", "nodejs.svg"],
  },
  {
    title: "MERN + GraphQL",
    description:
      "GraphQL enables efficient data queries, React provides a dynamic front-end, and Express.js with Node.js ensures scalable backend operations while MongoDB stores flexible NoSQL data.",
    icons: ["mongodb.svg", "express.svg", "react.svg", "nodejs.svg", "graphql.svg"],
  },
  {
    title: "MERN + Redux Toolkit",
    description:
      "Redux Toolkit enhances state management, combined with the MERN stack, providing seamless data flow and improved scalability for large-scale applications.",
    icons: ["mongodb.svg", "express.svg", "react.svg", "nodejs.svg", "redux.svg"],
  },
  {
    title: "MERN + Next.js",
    description:
      "Next.js for server-side rendering (SSR) improves SEO and performance, paired with the MERN stack to build fast, scalable, and SEO-friendly applications.",
    icons: ["mongodb.svg", "express.svg", "react.svg", "nodejs.svg", "next-js.svg"],
  },
];

export const HireMernstackBannerList = [
  { list: "Freelance Contractors" },
  { list: "Full-time Roles" },
  { list: "Global Teams" },
];

export var mainBannerImg = {
  backgroundImage: `url(/images/hire-page-images/hire-mern.png)`,
  factBackgroundImage: `url(/images/hire-page-images/hire-mern.svg)`,
};

export const HireMernstackServicesData = [
  {
    title: "MERN Stack Consulting",
    description:
      "Get expert guidance on your MERN Stack project, from architecture planning to performance optimization for the best development results.",
    icon: "/images/hire-page-icons/mern-stack-icon-1.svg",
  },
  {
    title: "MERN Stack Web Development",
    description:
      " We build dynamic, feature-rich web applications that leverage the full power of the MERN stack, ensuring optimal speed and scalability.",
    icon: "/images/hire-page-icons/mern-stack-icon-2.svg",
  },
  {
    title: "MERN Stack API Development",
    description:
      "Our team develops secure, high-performing APIs that facilitate seamless communication between different applications and services.",
    icon: "/images/hire-page-icons/mern-stack-icon-3.svg",
  },
  {
    title: "MERN Stack CMS and Portal Development",
    description:
      "Develop a custom CMS or web portal using the MERN stack for flexible content management and smooth user interactions.",
    icon: "/images/hire-page-icons/mern-stack-icon-4.svg",
  },
  {
    title: "Migration to MERN Stack",
    description:
      "Seamlessly transition your existing applications to the MERN stack with zero data loss and optimized performance.",
    icon: "/images/hire-page-icons/mern-stack-icon-5.svg",
  },
  {
    title: "Integration with Existing Solutions",
    description:
      "We ensure smooth integration of your MERN Stack application with third-party services, tools, and platforms for enhanced functionality.",
    icon: "/images/hire-page-icons/mern-stack-icon-6.svg",
  },
  {
    title: "MERN Stack eCommerce Solutions",
    description:
      "Create fast, scalable, and engaging eCommerce platforms that enhance user experience and drive conversions.",
    icon: "/images/hire-page-icons/mern-stack-icon-7.svg",
  },
  {
    title: "MERN Stack Support & Maintenance",
    description:
      " Ensure long-term stability and performance with our ongoing support, troubleshooting, and system upgrades.",
    icon: "/images/hire-page-icons/mern-stack-icon-8.svg",
  },
];

export const HireMernstackSolutionsData = [
  {
    icon: "/images/hire-page-icons/chose-ror-section1.svg",
    title: "Access vetted talent",
    description:
      " We connect you with highly skilled MERN stack developers who are experienced, fluent in English, and proficient in full-stack JavaScript development, ensuring seamless communication and execution.",
  },
  {
    icon: "/images/hire-page-icons/chose-ror-section2.svg",
    title: "Find the Right Match Instantly",
    description:
      "Skip the hassle of sorting through endless resumes. With HireAI, you can instantly access top MERN developers, making your hiring process faster and more efficient.",
  },
  {
    icon: "/images/hire-page-icons/chose-ror-section3.svg",
    title: "Save with Global Hiring",
    description:
      "Expand your reach beyond local talent and hire top MERN developers worldwide—reducing hiring costs by up to 58% compared to traditional methods.",
    noBorder: true,
  },
  {
    icon: "/images/hire-page-icons/chose-ror-section4.svg",
    title: "Get Real Human Support",
    description:
      " Our expert recruitment team is available every step of the way, ensuring you hire the best MERN stack developers with confidence.",
  },
];

export const HireMernstackStacksdata = [
  {
    title: "Junior Ruby on Rails Developer",
    description: [
      "2 Years' experience",
      "Fresh perspectives & innovative ideas",
      "Dedicated to explore web development",
    ],
    linkText: "Connect to Hire",
    link: "#",
    borderClass: "border-1",
  },
  {
    title: "Senior Ruby on Rails Developer",
    description: [
      "2+ years' experience",
      "Leadership in complex projects",
      "Proven app deployment success",
    ],
    linkText: "Connect to Hire",
    link: "#",
    borderClass: "border-2",
  },
  {
    title: "Ruby on Rails Tech Lead",
    description: [
      "5+ years' experience",
      "Skilled with Jira, Asana, MS Teams, etc.",
      "Suitable when you Hire Web App Team",
    ],
    linkText: "Connect to Hire",
    link: "#",
    borderClass: "border-3",
  },
];

export const HireMernstackBenefitsData = [
  {
    service: "Android CMS Development",
    description:
      "Get an upper hand on your web content, augment efficiency, and develop a shared environment with our Ruby on Rails CMS solutions.",
  },
  {
    service: "Ruby on Rails Migrations",
    description:
      "Our Ruby on Rails engineers strategically migrate legacy content into modern systems in a secured, erAndroid-free, and robust way.",
  },
  {
    service: "Advanced Android Programming",
    description:
      "Our experts have extensive experience in using Android tech stack for tangible results to reach your business vision.",
  },
  {
    service: "Android Integration and Optimization",
    description:
      "Leverage the full-scale potential of Ruby on Rails integration and optimization functionalities for your app development needs.",
  },
  {
    service: "Customized Android Solutions",
    description:
      "Our Ruby on Rails developers delve into ideation, reinvention, and scale development as per your enterprise's unique IT demands.",
  },
  {
    service: "Android eCommerce App",
    description:
      "With using modern Android tools and technologies, we have a proven track record of developing scalable and secured eCommerce sites.",
  },
];

export const HireMernstackSteps = [
  {
    title: "Choose your Engagement Model",
    description:
      " Select a model that suits your project—Full-Time, Part-Time, or Hourly—for maximum flexibility and efficiency.",
    icon: "/images/hire-page-setps-icons/icon-1.svg",
  },
  {
    title: "Screen & Select Mern Stack Developers",
    description:
      " Explore top MERN developer profiles, shortlist candidates, and find the right match for your team.",
    icon: "/images/hire-page-setps-icons/icon-2.svg",
  },
  {
    title: "Conduct One-on-One Interview",
    description:
      "Assess technical expertise through practical and theoretical questions to ensure they meet your project’s needs.",
    icon: "/images/hire-page-setps-icons/icon-3.svg",
  },
  {
    title: "Onboard Your MERN Stack Developer",
    description:
      "Once selected, your developer joins your team within 24-48 hours, ready to start working.",
    icon: "/images/hire-page-setps-icons/icon-4.svg",
  },
];

export const HireMernstackFactsData = [
  {
    text: "<strong>10+ Skilled MERN Developers: </strong>Our expert team of MERN developers builds powerful, scalable web apps tailored to your needs.",
  },
  {
    text: "<strong>25+ Years of Combined Experience:</strong> With over 25 years of combined expertise, our team brings deep knowledge in full-stack MERN development.",
  },
  { text: "<strong>Full-Stack Development Mastery:</strong> We specialize in React.js for frontend, Node.js & Express.js for backend, and MongoDB for robust database solutions." },
  {
    text: "<strong>Cloud-Native & Serverless Solutions:</strong> Our expertise in cloud-native and serverless architectures ensures scalable, future-proof applications.",
  },
  { text: "<strong> Strong DevOps & CI/CD Practices:</strong> With a mature DevOps culture, we streamline workflows for faster, more reliable deployments." },
  { text: "<strong>Experts in High-Performance Web Apps:</strong> We build scalable, data-driven web apps optimized for speed, efficiency, and seamless user experiences." },
];

export const HireMernstackWhoWeServeData = [
  {
    icon: "/images/servie-icon/startups.png",
    title: "Budget savings",
    description:
      "Assembling a local Ruby on Rails team is unavoidably expensive. By approaching Innowise. you bypass many hurdles and avoid redundantexpenses such as training fees. taxes. and software licenses.",
    link: "#",
    borderClass: "border-1",
  },
  {
    icon: "/images/servie-icon/companies.png",
    title: "Software product companies",
    description:
      "When you hire Android developers. you avoid the risks of budget overruns and missed deadlines. Innowise provides solid risk management plans and has an array of risk mitigation measures",
    link: "#",
    borderClass: "border-2",
  },
  {
    icon: "/images/servie-icon/industries.png",
    title: "Enterprises from 30+ industries",
    description:
      "Innowise has experts with excellent development backgrounds. so you can rest assured that your project is in good hands. Pyrggyelgprneg",
    link: "#",
    borderClass: "border-3",
  },
];

export const HireMernstackHirignDetails = [
  {
    text: "Hourly Hiring",
    hours: "48",
    icon: "time.svg",
    bgColor: "#FDFDFF",
  },

  {
    text: "Full Time Hiring",
    hours: "72",
    icon: "full-time.svg",
    bgColor: "#FFFEFA",
  },

  {
    text: "Part Time Hiring",
    hours: "48",
    icon: "part-time.svg",
    bgColor: "#FDFFFA",
  },
];
export const HireMernstackPageDeliverData = [
  {
    image: "/images/hire-page-images/card-1.png",
    title: "Complex SaaS systems",
    description:
      "Innowise provides its expertise in the area of building complex platforms for businesses to streamline workflows. The main applications we develop include HRM, CRM, ERP, accounting software, etc.",
  },
  {
    image: "/images/hire-page-images/card-2.png", // Assumed path based on provided example
    title: "Complex SaaS systems",
    description:
      "Innowise provides its expertise in the area of building complex platforms for businesses to streamline workflows. The main applications we develop include HRM, CRM, ERP, accounting software, etc.",
  },
  {
    image: "/images/hire-page-images/card-3.png", // Assumed path based on provided example
    title: "Complex SaaS systems",
    description:
      "Innowise provides its expertise in the area of building complex platforms for businesses to streamline workflows. The main applications we develop include HRM, CRM, ERP, accounting software, etc.",
  },
];

export const HireMernstackSlidesData = [
  {
    title: "Experienced Android Developers",
    content:
      "Our team consists of senior Android developers who have extensive experience in building sophisticated web applications across various industries.",
    backgroundClass: "bg-1",
  },
  {
    title: "Remote and On-Demand",
    content:
      "Need flexibility? Hire remote Android developers from Bitsclan for seamless project execution without geographic limitations.",
    backgroundClass: "bg-2",
  },
  {
    title: "Agile Development Approach",
    content:
      "We follow agile methodologies to ensure rapid delivery, transparency, and adaptability throughout the development process.",
    backgroundClass: "bg-3",
  },
  {
    title: "Automation Solutions",
    content:
      "We combine AI automation with Robotic Process Automation to boost operational efficiency, reduce operational costs, and minimize human error, allowing you to focus on strategic initiatives.",
    backgroundClass: "bg-4",
  },
];

export const HireMernstackFaqData = [
  {
    id: "collapseOne",
    question: "What is the MERN stack?",
    answer:
      "The MERN stack is a combination of technologies used to build web applications. It includes MongoDB (a NoSQL database), Express.js (a web framework), React.js (a front-end library), and Node.js (a server-side runtime). These tools work together to create fast, scalable, and dynamic web apps. When you hire a MERN stack developer, you're getting someone who can handle the entire development process from front-end to back-end.",
  },
  {
    id: "collapseTwo",
    question: "Why should I hire a MERN stack developer for my project?",
    answer:
      "Hiring a MERN stack developer is a great choice if you're looking for someone who can build full-fledged web applications. MERN developers can work across all layers of development, meaning they can design both the front-end and back-end of your application, integrate APIs, and manage databases—all within a unified tech stack. This makes development faster and more efficient because everything works seamlessly together.",
  },
  {
    id: "collapseThree",
    question: "What kind of projects are best suited for MERN stack development?",
    answer:
      "MERN stack is perfect for building modern web apps like e-commerce platforms, social media sites, real-time chat applications, and content management systems. It’s especially useful for single-page applications (SPAs) where performance and user experience are key. If you're aiming to create a dynamic, interactive web app with a responsive UI, MERN is a solid choice.",
  },
  {
    id: "fourth",
    question: " How long does it take to develop a MERN stack application?",
    answer:
      "The time it takes to develop a MERN stack application depends on the complexity of the project. A simple app could take a few weeks, while a more complex platform might take a few months. During the initial consultation, a good MERN stack developer will be able to give you an estimated timeline based on your requirements and features.",
  },
  {
    id: "fifth",
    question: "Do I need to know coding to work with a MERN stack developer?",
    answer:
      "Not at all! You don’t need to know how to code to collaborate effectively with a MERN stack developer. What’s important is that you have a clear understanding of your project goals and functionality. A good developer will guide you through the process, simply explain technical terms, and keep you updated on the progress.",
  },
  {
    id: "sixth",
    question: "How much does it cost to hire a MERN stack developer?",
    answer:
      "The cost of hiring a MERN stack developer varies depending on their experience, location, and the complexity of your project. On average, hourly rates can range from $30 to $150 per hour. For larger projects, some developers may offer a fixed-price quote. It’s always a good idea to discuss your budget and project requirements upfront to ensure there are no surprises later.",
  },
];

import React, { useEffect } from "react";
import SimilerBanner from "../components/similerBanner/SimilerBanner";
import DevelopingSolution from "../components/developingsolution/DevelopingSolution";
import Testimonial from "../components/testimonila/Testimonial";
import CoverTheStack from "../components/coverthestack/CoverTheStack";
import SecondFaq from "../components/secondFaq/SecondFaq";
import Footer from "../components/footer/Footer";
import { Helmet } from "react-helmet";
import MegaHeader from "../components/MegaHeader/MegaHeader";
import TrendsInsights from "../components/trends-n-insights/TrendsInsights";
import RoadMap from "../components/roadMap/RoadMap";
import WhoWeServe from "../components/who-we-serve/WhoWeServe";
import HighValues from "../components/high-values/HighValues";
import TypesOfBuild from "../components/types-of-build/TypesOfBuild";
import WhyChooseBitsclan from "../components/why-choose-bitsclan/WhyChooseBitsclan";
import IndustriesTabs from "../components/industries-tabs/IndustriesTabs";
import {
  IndustryTabsData,
} from "../data/industriesTabsData";
import DevelopmentServices from "../components/development-services/DevelopmentServices";
import ContactSection from "../components/contactSection/ContactSection";
import { WebServicesIndustryTabsItemsData } from "../data/web-app-service-page";
import NewEngagementModels from "../components/new-engagment-modal";
import { DevelopmentServicesData, faqData, HighvaluesData, mainBannerImg, schemaData, tabItems, tabs, TypesOfBuildData, WhoWeServeData, WhyChooseBitsclanData } from "../data/custom-development-data";

const MobileDevelopmentServices = () => {

  return (
    <>
      <Helmet>
        <title>
          Custom Software Development Company | Bitsclan IT Solutions
        </title>
        <meta
          name="description"
          content="Get tailored software development services to meet your unique business needs with scalable, efficient, and innovative solutions"
        />
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
      </Helmet>

      <div className="sticky-header">
        <MegaHeader />
      </div>

      <SimilerBanner
        diffBannerBackgroundImage={mainBannerImg}
        mainHeading={"Custom Software"}
        greenHeading="Development Services"
        subHeading={
          "Trusted by Startups, SMBs, and Enterprises Around the Globe"
        }
        content={
          "Let us take your eureka moment from a raw concept to feasibility assessment, and from design to development. Our custom software development services offer robust, secure, scalable solutions to ensure sustainable growth for your business."
        }
        btnText2={"Let's Get Started"}
        sideImage={""}
      />

      <Testimonial
        heading="Web Development Company of the Year 2021"
        content="We received the Best Web Development Company of the Year award from Payoneer in 2021. We bring the same expertise, dedication, vision, and conviction to every service we provide."
      />

      <DevelopmentServices
        tabs={DevelopmentServicesData}
        heading="Our Expansive Range of End-to-end Custom Software Development Services."
        subheading="We create software solutions that truly work for you—whether it’s building custom cloud platforms, interactive portals, or crafting seamless front-end and reliable back-end systems. Our tailored software services are built to grow with your business. From expert consulting to scalable e-commerce development, we deliver secure and flexible solutions designed to meet your unique needs."
      />

      <TypesOfBuild
        typeOfApps={TypesOfBuildData}
        heading="Step-by-Step Approach to a Successful Project"
        content="Discover tailored software solutions, from design to deployment, crafted to meet your unique business needs."
        sideImage="web-app-development2.png"
      />

      <WhoWeServe
        cardContent={WhoWeServeData}
        heading="Who We Serve"
        subHeading="We create tailored software services for startups, product companies, and enterprises across industries."
      />

      <WhyChooseBitsclan
        tabs={WhyChooseBitsclanData}
        heading="Why Choose Bitsclan as Your Custom Software Development Company?"
        subheading="At Bitsclan, we deliver tailored software services built to meet your unique needs. From expert talent and time zone alignment to quality and scalability, our custom software development ensures seamless solutions for businesses of all sizes."
      />

      <HighValues
        heading="High-Value Features for High-Performance Custom Software Solutions"
        subHeading="Our custom software development delivers high-performance solutions tailored to your needs. From expert teams to scalable designs, we ensure quality, innovation, and seamless integration to elevate your business success."
        boxContent={HighvaluesData}
        btnLink="#"
        btnText="Consult Project Scope"
      />

      <NewEngagementModels />

      <RoadMap
        roadMapHeading={[]}
        description="Our roadmap ensures success at every stage of your custom software development journey. From planning to testing, we deliver tailored software services designed to meet your unique goals with precision and care."
        roadMapsteps={[
          "We align with your vision, creating a roadmap that ensures efficient development, scalability, and business-focused custom software solutions.",
          "Turn ideas into reality! We create designs and proofs of concept, ensuring feasibility and aligning the solution with your needs and expectations.",
          "Our robust development and rigorous testing deliver secure and reliable custom app solutions while ensuring optimal performance and scalability.",
          "We focus on smooth implementation and provide continuous support, ensuring your scalable software meets long-term operational goals seamlessly.",
        ]}
      />

      <CoverTheStack
        whereStart={"Frontend"}
        mainHeading={
          "Optimize Your Software Solutions with Industry-Leading Tech Stack"
        }
        subHeading={
          "Leverage the latest technologies to power your custom software development. Our cutting-edge tech stack ensures scalable, secure, and efficient software solutions tailored to your business needs."
        }
        tabs={tabs}
        tabItems={tabItems}
      />

      <IndustriesTabs
        mainheading={"Industries we serve in Custom Software Development"}
        tabs={IndustryTabsData}
        defaultTab={"Healthcare"}
        tabsItems={WebServicesIndustryTabsItemsData}
      />
      <DevelopingSolution
        heading="Developing Solutions That Transcend Mere Digital Transformation"
        subheading="We deliver innovative solutions that surpass digital transformation, driving impactful and lasting success."
      />

      <TrendsInsights specificClass="mobile-dev-page" category="custom-developmet" />

      <SecondFaq
        specificClass="mobile-dev-page"
        faqs={faqData}
        mainHeading={"FAQs"}
        subHeading={
          "Get quick answers to your questions about our mobile app development process and services."
        }
      />

      <ContactSection />
      <Footer />
    </>
  );
};

export default MobileDevelopmentServices;

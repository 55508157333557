import React, { useState } from "react";
import "./styles.css";
import { Link } from "react-router-dom";

function IndustriesTabs({ mainheading, tabs, defaultTab, tabsItems }) {
  const [activeTab, setActiveTab] = useState(defaultTab);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const activeTabContent = tabsItems.find((item) => item.tabName === activeTab);

  return (
    <div className="industries-main-container" id="industries-tabs">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="industries-main-heading">{mainheading}</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="links-boxes">
              <ul className="links-boxes-lists serve-industries-page">
                {tabs.map((tab) => (
                  <li
                    key={tab}
                    className={activeTab === tab ? "active" : ""}
                    onClick={() => handleTabClick(tab)}
                  >
                    {tab}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {activeTabContent && (
          <div className="row pt-5">
            {/* Text Section */}
            <div className="col-md-6">
              <h4 className="sub-heading-industries">{activeTabContent.tabName}</h4>
              <p className="common-text">{activeTabContent.tabDes}</p>

              <div className="d-flex flex-column gap-3 mt-4 mb-4">
                {activeTabContent.objectives.map((objective, index) => (
                  <div className="d-flex align-items-center gap-3" key={index}>
                    <img
                      src="/images/blog-images/auth-logo.png"
                      width={20}
                      height={20}
                      alt={`Objective Icon ${index + 1}`}
                    />
                    <p className="mb-0 common-text">{objective}</p>
                  </div>
                ))}
              </div>

              {/* <div className="header-link-green">
                <Link to={activeTabContent.href} className="ms-0 px-2" style={{ width: "fit-content" }}>
                  Learn more about {activeTabContent.tabName}
                </Link>
              </div> */}
            </div>

            {/* Image Section */}
            <div className="col-md-6">
              <img
                style={{ width: "100%", height: "320px", objectFit: 'cover' }}
                className="top-image-space"
                src={`./images/industries-tabs-images/${activeTabContent?.tabImage}`}
                alt="Side Visual"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default IndustriesTabs;

import React, { useState } from 'react';
import './style.css'
import { Link } from 'react-router-dom';

const AnalyticsSolutions = () => {

    const [activeTab, setActiveTab] = useState(0);

    const analyticsData = [
        {
            title: "CRM Analytics",
            description: "Our CRM analytics solutions provide deep insights into customer interactions, helping you optimize customer relationships, improve retention rates, and enhance overall satisfaction.",
            icon: 'CRM.png'
        },
        {
            title: "Customer Analytics",
            description: "We analyze customer behavior to help you understand preferences, predict trends, and personalize experiences, leading to increased loyalty and higher conversion rates.",
            icon: 'Customer-ANALYTICS.png'
        },
        {
            title: "Financial Risk Assessment",
            description: "Our HR analytics solutions offer valuable insights into workforce management, enabling you to improve recruitment, optimize employee performance, and reduce turnover.",
            icon: 'RISK-ASSESMENTS.png'
        },
        {
            title: "HR Analytics",
            description: "Leverage our sales analytics to identify trends, forecast sales, and optimize your sales strategies, ensuring you hit your targets and drive revenue growth.",
            icon: 'HR-ANALYTICS.png'
        },
        {
            title: "Sales Analytics",
            description: "We provide comprehensive marketing analytics to track campaign performance, understand customer journeys, and optimize your marketing efforts for maximum ROI.",
            icon: 'SALES-ANALYTICS.png'
        },
        {
            title: "Predictive Analytics",
            description: "Our supply chain analytics solutions enhance operational efficiency by providing insights into inventory management, demand forecasting, and logistics optimization.",
            icon: 'PREDICTIVE-ANALYSIS.png'
        },
        {
            title: "Marketing Analytics",
            description: "We help you assess and manage financial risks with our advanced analytics, enabling better decision-making and ensuring financial stability.",
            icon: 'MARKETING-ANALYTICS.png'
        },
        {
            title: "Supply Chain Analytics",
            description: "Our predictive analytics solutions allow you to anticipate market changes, optimize strategies, and make proactive decisions that keep you ahead of the competition.",
            icon: 'SUPPLY-CHAIN-ANALYTICS.png'
        }
    ]


    return (
        <>
            <div className="analytics-solutions-box">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-12 space-bottom-box">
                            <div className="analytics-textbox-border">
                                <h1>Specialized <span>Analytics Solutions</span> for Your Industry Needs</h1>

                                <p className="common-text">We understand that each industry has unique challenges and opportunities. That’s why we offer industry-specific analytics solutions designed to address your most pressing needs.</p>
                            </div>

                            <div className="container-fluid">
                                <div className="row">
                                    {analyticsData.map((item, index) => (
                                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6" key={index}>
                                            <div
                                                className={`analytics-image-box ${index === activeTab ? 'active-tab' : ''}`}
                                                onClick={() => setActiveTab(index)}
                                            >
                                                <img src={`/images/anylatics-icons/${item.icon}`} alt={`${item.icon}`} />
                                                <h3>{item.title}</h3>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-12">
                            <div className="crm-analytics-box">
                                <div className="crm-icon-box">
                                    <img src={`/images/anylatics-icons/${analyticsData[activeTab].icon}`} alt={`${analyticsData[activeTab].icon}`} />
                                </div>

                                <h2>{analyticsData[activeTab].title}</h2>

                                <p>{analyticsData[activeTab].description}</p>

                                <div className="explor-industries-btn">
                                    <Link to="/Industries">Explore all Industries <i className="fa-solid fa-arrow-right"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="comingsoon-model" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
                <div class="modal-dialog shedule-call-model-box modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class="fa-solid fa-xmark"></i></button>
                        </div>
                        <div class="modal-body">
                            <div className="d-flex justify-content-center">
                                <img src="/images/logo.png" width={300} alt="Logo" />
                            </div>
                            <h1 style={{ fontSize: '42px', textAlign: 'center', color: '#000000' }} className='mt-2'>Coming Soon,</h1>
                            <h1 style={{ fontSize: '42px', textAlign: 'center', color: '#000000' }}>We Are Working On It.</h1>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AnalyticsSolutions
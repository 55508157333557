import React, { useState } from "react";
import "./styles.css";
import { Link } from "react-router-dom";

const engagementModels = [
  {
    title: "Staff Augmentation",
    description:
      "Add skilled professionals to your team to fill skill gaps and scale resources as needed.",
      icon: "/images/engagement-model-gifs/animation-three.mp4",
    },
    {
      title: "Dedicated Team",
      description:
      "A full-time staff committed only to your project, guaranteeing alignment with your goals.",
      icon: "/images/engagement-model-gifs/animation-one.mp4",
  },
  {
    title: "Turnkey Project Solutions",
    description:
      "Perfect for defined projects with set timelines. Let us handle everything from beginning to end.",
    icon: "/images/engagement-model-gifs/animation-two.mp4",
  },
  {
    title: "Support and Maintenance",
    description:
      "Continuous support to maintain the functionality, security, and updates of your program.",
    icon: "/images/engagement-model-gifs/animation-two.mp4",
  },
];

// const engagementModels = [
//   {
//     title: "Staff Augmentation",
//     description:
//       "Add skilled professionals to your team to fill skill gaps and scale resources as needed.",
//     icon: "/images/new-engangment-modal-icons/staff-augmentation-icon.svg",
//   },
//   {
//     title: "Dedicated Team",
//     description:
//       "A full-time staff committed only to your project, guaranteeing alignment with your goals.",
//     icon: "/images/new-engangment-modal-icons/dedicated-team-icon.svg",
//   },
//   {
//     title: "Turnkey Project Solutions",
//     description:
//       "Perfect for defined projects with set timelines. Let us handle everything from beginning to end.",
//     icon: "/images/new-engangment-modal-icons/project-solutions-icon.svg",
//   },
//   {
//     title: "Support and Maintenance",
//     description:
//       "Continuous support to maintain the functionality, security, and updates of your program.",
//     icon: "/images/new-engangment-modal-icons/support-icon.svg",
//   },
// ];

function NewEngagementModels() {

  const [activeIndex, setActiveIndex] = useState(0);

  const handleGtmClick = (modalName) => {
    // Push data to Google Tag Manager (GTM)
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "engagement-modal-know-more-btn-event",
      engagment_modal_title: modalName,
      engagement_modal_link: "/contact-us"
    });
  };
  return (
    <div className="new-engagement-modal-wrapper" id="engagment-models">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="text-white text-center">
              Our Flexible Engagement Models
            </h1>
            <p className="text-center text-white mt-0">
              Choose the engagement model that fits your project needs! Whether
              you need full-time, part-time, or hourly developers, we offer
              flexible options to ensure seamless collaboration and maximum
              efficiency throughout your project.
            </p>
          </div>
        </div>

        <div className="col-md-12 mt-4">
          <div className="engagment-model-finel-desing">
            <ul className="new-engagement-models-top-bar">
              {engagementModels.map((model, index) => (
                <li
                  key={index}
                  className={index === activeIndex ? "active-list" : ""}
                  onClick={() => setActiveIndex(index)}
                >
                  {model.title}
                </li>
              ))}
            </ul>

            <div className="new-engagement-model-text-card">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-xl-6 col-lg-6 col-md-12">
                    <div className="card-text-box">
                      <h1>{engagementModels[activeIndex].title}</h1>
                      <p className="common-text">{engagementModels[activeIndex].description}</p>
                      <Link to="/contact-us" className="new-engagement-modal-btn know-more-card-button mt-3" id="engagement-modal-know-more-btn">
                        <p className="my-0">Know More</p>
                        <i className="fa-solid fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-12">
                    <video
                      key={activeIndex}
                      autoPlay
                      loop
                      muted
                      playsInline
                      className="w-full vedio-widht-control"
                    >
                      <source src={engagementModels[activeIndex].icon} type="video/mp4" />
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="col-md-12">
            <div className="engagment-model-finel-desing">
              <ul className="new-engagement-models-top-bar">
                <li className="active-list">Staff Augmentation</li>
                <li>Staff Augmentation</li>
                <li>Staff Augmentation</li>
                <li>Staff Augmentation</li>
              </ul>

              <div className="new-engagement-model-text-card">
                <div className="container">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="card-text-box">
                        <h1>Product Development</h1>
                        <p className="common-text">Add skilled professionals to your team to fill skill gaps and scale resources as needed.</p>
                        <ul>
                          <li>Early Growth Stage or Stealth Startup.</li>
                          <li>Early Growth Stage or Stealth Startup.</li>
                          <li>Early Growth Stage or Stealth Startup.</li>
                          <li>Early Growth Stage or Stealth Startup.</li>
                          <li>Early Growth Stage or Stealth Startup.</li>
                        </ul>

                        <Link to="/contact-us" className="new-engagement-modal-btn know-more-card-button mt-3" id="engagement-modal-know-more-btn">
                          <p className="my-0">Know More</p>
                          <i className="fa-solid fa-arrow-right"></i>
                        </Link>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <video autoPlay loop muted playsInline className="w-full">
                        <source src="/images/engagement-model-gifs/animation-one.mp4" type="video/mp4" />
                      </video>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

        {/* {engagementModels.map((model, index) => (
            <div key={index} className="col-xl-3 col-lg-3 col-md-6">
              <div className="new-engagement-modal-card">
                <img src={model.icon} alt={model.title} width={50} />
                <h3 className="text-white text-center mt-2">{model.title}</h3>
                <p className="text-white text-center common-text">{model.description}</p>
                <Link to="/contact-us" className="new-engagement-modal-btn mt-3" id="engagement-modal-know-more-btn" onClick={() => handleGtmClick(model.title)}>
                  <p className="my-0">Know More</p>
                  <i className="fa-solid fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          ))} */}
      </div>
    </div>
  );
}

export default NewEngagementModels;

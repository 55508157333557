import React, { useState, useEffect } from "react";
import "./style.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";

const EngagmentModelScroller = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
    });
  }, []);

  const navigate = useNavigate();
  const EngagmentScrollerData = [
    {
      title: "Fixed Bid Model",
      description:
        "With our Fixed Bid Model, you get a predefined cost for your entire project. This means no budget surprises and a structured approach to project execution. It’s ideal for startups or businesses that need clarity and precise planning.",
      benefits: [
        "Clear contractual agreements with no hidden costs.",
        "Predictable cost management and budgeting.",
        "Well-defined project scope and objectives.",
        "Minimized financial risks and uncertainties.",
        "Best fit for small-scale and startup projects.",
      ],
      modelTitle: "Choose Fixed Price Model if",
      modelPoints: [
        "You have a clear set of requirements.",
        "You prefer budget certainty with a fixed cost.",
        "Your project scope is stable and well-defined.",
        "Risk management and cost control are your priorities.",
      ],
      userImage: "engagment-scroll-image2.png",
    },
    {
      title: "Milestone-Based Billing",
      description:
        "This model gives you full control over payments, ensuring you only pay for work completed. It’s great for businesses that want flexibility while keeping an eye on costs.",
      benefits: [
        "Payments are tied to achieved milestones.",
        "Transparent cost tracking to stay within budget.",
        "Increased flexibility as projects evolve.",
        "Minimized risks by breaking projects into phases.",
        "Perfect for mid to long-term projects.",
      ],
      modelTitle: "Choose Milestone Billing if:",
      modelPoints: [
        "You prefer incremental payments.",
        "You want flexibility in project execution.",
        "Your project scope may evolve over time.",
        "You need measurable progress at each step.",
      ],
      userImage: "engagment-scroll-image1.png",
    },
    {
      title: "Staff Augmentation",
      description:
        "Scale your team with expert remote developers who work as an extension of your in-house team. No hiring hassle, no commitment—just talent on demand!",
      benefits: [
        "Access top-tier tech talent with zero recruitment delays.",
        "Quickly scale your team based on project demands.",
        "No long-term hiring obligations—pay for what you need.",
        "Seamless collaboration with your existing team.",
        "Ideal for companies needing skilled professionals on short notice.",
      ],
      modelTitle: "Choose Staff Augmentation if:",
      modelPoints: [
        "You need immediate access to specialized talent.",
        "Your project requires temporary expertise.",
        "You want to avoid long-term hiring costs.",
        "You need developers who integrate seamlessly with your team.",
      ],
      userImage: "engagment-scroll-image3.png",
    },
    {
      title: "White Labeling Partnership",
      description:
        "We build and manage tech solutions while you take the credit. Perfect for agencies and businesses that want to offer services without hiring an in-house team.",
      benefits: [
        "Seamless integration under your brand identity.",
        "Cost-effective—no need for in-house development teams.",
        "Expand your service offerings effortlessly.",
        "Confidential and secure project execution.",
        "Ideal for agencies and consulting firms.",
      ],
      modelTitle: "Choose White Labeling if:",
      modelPoints: [
        "You want to offer new services without hiring",
        "You need a trusted development partner.",
        "You prefer a behind-the-scenes expert team.",
        "You need a cost-effective way to scale.",
      ],
      userImage: "engagment-scroll-image4.png",
    },
    {
      title: "Build-Operate-Transfer (BOT)",
      description:
        "We handle everything from building and operating your tech solution to transferring it to you when you're ready. It’s the best long-term growth model for businesses.",
      benefits: [
        "End-to-end development, management, and transfer.",
        "Minimizes risks and optimizes project execution.",
        "Allows businesses to focus on scaling while we handle tech.",
        "Ideal for offshore development centers and large-scale projects.",
        "Smooth transition with zero operational disruptions.",
      ],
      modelTitle: "Choose BOT Model if:",
      modelPoints: [
        "You need an experienced team to set up and manage.",
        "You want to avoid operational risks early on.",
        "You prefer a structured transfer of ownership.",
        "Your business is expanding and needs a tech partner.",
      ],
      userImage: "engagment-scroll-image5.png",
    },
    {
      title: "Dedicated Team",
      description:
        "Get a full-time, dedicated team working exclusively on your project. No distractions, no shared resources—just a fully committed team.",
      benefits: [
        "A handpicked team of experts aligned with your vision.",
        "Long-term collaboration with full transparency.",
        "Scalable resources as your business grows.",
        "Seamless communication and integration.",
        "Best for large-scale projects requiring ongoing development.",
      ],
      modelTitle: "Choose a Dedicated Team if:",
      modelPoints: [
        "You need a long-term, full-time team.",
        "You want complete control over your development team.",
        "Your project requires ongoing support and scaling.",
        "You prefer consistent collaboration with a trusted team.",
      ],
      userImage: "engagment-scroll-image6.png",
    },
  ];

  const [gradientPosition, setGradientPosition] = useState(53);

  useEffect(() => {
    const handleScroll = () => {
      // Calculate the new gradient position based on scroll position
      const scrollPosition = window.scrollY;
      const maxScroll =
        document.documentElement.scrollHeight - window.innerHeight;
      const newGradientPosition = Math.min(
        100,
        (scrollPosition / maxScroll) * 100
      ); // Map scroll position to a range of 0 to 100
      setGradientPosition(newGradientPosition);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <div className="engagment-model-scroller-box">
        <div className="container">
          <div className="row side-line-bar">
            <div className="col-lg-6 col-md-12 col-xl-6">
              <div className="engagment-model-scroller-leftbar sticy-bar-left">
                <h2
                  className="moving-gradient-heading"
                  style={{
                    "--gradient-position": `${gradientPosition}%`,
                  }}
                >
                  Flexible Engagement Models for Smooth Project Execution
                </h2>

                <p className="common-text">
                  We know that every project has its own unique needs, and
                  that’s why we offer engagement models that fit just right.
                  Whether you need fully dedicated teams or a more agile hiring
                  approach, we make sure your development process stays
                  efficient, predictable, and result-driven. <br />
                  Pick the best fit, and let’s make your project a seamless
                  success.
                </p>
                <h4>Choosing the Right Model for Collaboration and Growth</h4>
                <p className="common-text">
                  Finding the right engagement model isn’t just about
                  hiring—it’s about ensuring flexibility, efficiency, and
                  long-term success. Our models give you the freedom to scale,
                  manage, and optimize your resources the way that works best
                  for your goals
                </p>

                {/* <div class="engagment-model-btn">
                                    <a class="ms-0" href="/hire-contact">Let's Talk <i class="fa-arrow-right-long fa-solid"></i></a>
                                </div> */}

                <button
                  className="engagement-model-page-lets-talk engagment-model-btn mt-3"
                  onClick={() => navigate("/hire-contact")}
                >
                  Let's Talk
                  <i className="fa-arrow-right fa-solid"></i>
                </button>
              </div>
            </div>

            <div
              className="col-lg-6 col-md-12 col-xl-6 pl-lg-4 ps-xl-4"
              style={{ scrollBehavior: "smooth" }}
            >
              {EngagmentScrollerData?.map((item, index) => {
                return (
                  <div
                    className="engagment-model-scroller-side"
                    key={index}
                    data-aos="fade-up"
                  >
                    <h1>{item?.title}</h1>
                    <p className="common-text">{item?.description}</p>
                    <div className="engagment-model-scroller-leftbar">
                      <h4>Benefits</h4>
                      <ul>
                        {item?.benefits?.map((list, index) => {
                          return <li key={index}>{list}</li>;
                        })}
                      </ul>
                    </div>

                    <div className="engagement-model-bg-image-box">
                      <div className="engagment-mode-scroller-images">
                        <h3>{item?.modelTitle}</h3>
                        <ul>
                          {item?.modelPoints?.map((modeList, index) => {
                            return <li key={index}>{modeList}</li>;
                          })}
                        </ul>
                      </div>

                      <div className="side-image-engagement-model-scroller">
                        <img
                          src={`/images/engagment-model-images/${item?.userImage}`}
                          alt={`${item?.userImage}`}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EngagmentModelScroller;

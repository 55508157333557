import React, { useEffect, useRef, useState } from "react";
import "./style.css";

const EngagmentModels = () => {
  const sectionRef = useRef(null); // Reference to the section
  const [isInView, setIsInView] = useState(false); // State to track if section is in view
  const [addClass, setAddClass] = useState(false); // State to manage the class toggle

  useEffect(() => {
    const section = sectionRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true); // Section is in view
        } else {
          setIsInView(false); // Section is out of view
        }
      },
      {
        threshold: 0.5, // Adjust based on when you want to trigger (0.5 means 50% visible)
      }
    );

    if (section) {
      observer.observe(section); // Start observing
    }

    return () => {
      if (section) {
        observer.unobserve(section); // Cleanup the observer
      }
    };
  }, []);

  useEffect(() => {
    if (isInView) {
      setAddClass(true); // Add class immediately when section comes into view

      // Remove the class after 2 seconds
      const timer = setTimeout(() => {
        setAddClass(false);
      }, 2000);

      // Cleanup the timeout if component unmounts or if visibility changes
      return () => clearTimeout(timer);
    }
  }, [isInView]);

  return (
    <>
      <div
        ref={sectionRef}
        className={`engagement-models-box ${isInView ? "in-view-class" : ""}`}
        id="engagment-modesl"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="engagment-model-text">
                Available <span>Engagement Models</span>
              </h1>
              <h3 className="sub-heading-box">
                Flexible partnership options tailored to meet your project
                requirements, business goals, and budget constraints.
              </h3>
            </div>

            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="staff-card-box">
                <div className="staff-card-image">
                  <img src="/images/engagment-icon-1.png" alt="Engagement Model Icon" />
                </div>

                <h3>Staff Augmentation</h3>
                <p>
                  Add skilled professionals to your team to fill skill gaps and scale resources as needed.
                </p>
              </div>
            </div>

            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="staff-card-box">
                <div className="staff-card-image">
                  <img src="/images/engagment-icon-2.png" alt="Engagement Model Icon" />
                </div>

                <h3>Dedicated Team</h3>
                <p>
                  A full-time staff committed only to your project, guaranteeing alignment with your goals.
                </p>

                {/* <div className="engagment-card-btn">
                                    <a data-bs-toggle="modal" href="#comingsoon" role="button">Explore more <i class="fas fa-chevron-right"></i></a>
                                </div> */}
              </div>
            </div>

            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="staff-card-box">
                <div className="staff-card-image">
                  <img src="/images/engagment-icon-3.png" alt="Engagement Model Icon" />
                </div>

                <h3>Turnkey Project Solutions</h3>
                <p>
                  Perfect for defined projects with set timelines. Let us handle everything from beginning to end.
                </p>
              </div>
            </div>

            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="staff-card-box">
                <div className="staff-card-image">
                  <img src="/images/engagment-icon-4.png" alt="Engagement Model Icon" />
                </div>

                <h3>Support and Maintenance</h3>
                <p>
                  Continuous support to maintain the functionality, security, and updates of your program.
                </p>
              </div>
            </div>

            <div className="engagment-card-button mt-5">
              <a
                class="common-white-btn"
                href="https://calendly.com/bitsclan-it/30min?month=2025-01"
                target="_blank"
              >
                Make an Appointment
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EngagmentModels;

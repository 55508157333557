import React, { useRef, useState, useCallback } from 'react'
import './style.css'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

import { Navigation } from 'swiper/modules';

const AIServices = ({ mainHeading, content, aiData, services }) => {

    const sliderRef = useRef();

    const [isBeginning, setIsBeginning] = useState(true);
    const [isEnd, setIsEnd] = useState(false);

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);

    const handleSlideChange = (swiper) => {
        setIsBeginning(swiper.isBeginning);
        setIsEnd(swiper.isEnd);
    };

    const chunkArray = (array, chunkSize) => {
        const result = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            result.push(array.slice(i, i + chunkSize));
        }
        return result;
    };

    const chunkedServices = chunkArray(services, 4);

    return (
        <>
            <div className="ai-services-box ai-development-box-main">
                <div className="container">

                    <div className="row">
                        <div className="col-md-6">
                            <h1 className="main-heading-text">{mainHeading}</h1>
                        </div>

                        <div className="col-md-6">
                            <p className='common-text mb-3'>{content}</p>
                        </div>
                    </div>

                    <div className="row mt-2">
                        {services.map((service, idx) => (
                            <div className="col-xl-4 col-lg-4 col-md-6 generated-ai-card-two-main" key={idx}>
                                <div className={`generated-ai-card-two generated-ai-card ${idx % 2 === 0 ? 'green-card' : ''}`}>
                                    <h1>{service.title}</h1>
                                    <p className='common-text'>{service.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div >
            </div >
        </>
    )
}

export default AIServices
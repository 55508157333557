import React, { useRef, useEffect, useMemo } from "react";
import "./style.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";
import { Link, useNavigate } from "react-router-dom";

const SlidOnScrollBanner = ({ sectionsData }) => {
  const sliderRef = useRef(null);
  const navigate = useNavigate();

  // Define handleSlideChange function

  const handleSlideChange = (swiper) => {
    const progressBar = document.querySelector(".scroll-bar-progress");
    const scrollBarTexts = document.querySelectorAll(".scroll-bar-text span");

    // Update progress bar width
    const progress = ((swiper.activeIndex + 1) / swiper.slides.length) * 100;
    if (progressBar) {
      progressBar.style.width = `${progress}%`;
    }

    // Update active class on scrollbar text
    scrollBarTexts.forEach((text, index) => {
      if (index === swiper.activeIndex) {
        text.classList.add("active");
      } else {
        text.classList.remove("active");
      }
    });
  };

  useEffect(() => {
    const scrollBarTexts = document.querySelectorAll(".scroll-bar-text span");
    if (scrollBarTexts.length > 0) {
      scrollBarTexts[0].classList.add("active");
    }
  }, []);

  const sectionsButton = useMemo(() => {
    return [
      { id: 1, heading: "01 Generative AI" },
      { id: 2, heading: "02 Blockchain Services" },
      { id: 3, heading: "03 Web App Development" },
      { id: 4, heading: "04 Mobile App Development" },
    ];
  });

  const goToSlide = (index) => {
    if (sliderRef.current && sliderRef.current.swiper) {
      sliderRef.current.swiper.slideTo(index);
    }
  };

  const handleGtmClick = (bannerName) => {
    // Push data to Google Tag Manager (GTM)
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "banner_click",
      banner_name: bannerName,
    });
  };

  return (
    <>
      <div className="mobile-slier container-fluid">
        <div className="row">
          <div className="col-md-12 p-0">
            <Swiper
              slidesPerView={1}
              spaceBetween={0}
              onSlideChange={handleSlideChange}
              navigation={false}
              modules={[Navigation, Autoplay]} // Include Autoplay module
              className="mySwiper"
              ref={sliderRef}
              autoplay={{
                delay: 3500, // Delay between slides in milliseconds (3000ms = 3 seconds)
                disableOnInteraction: true, // Keep autoplay even after interaction
                pauseOnMouseEnter: true,
              }}
              breakpoints={{
                425: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                },
                375: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                },
                320: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                },
                768: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                },
                1280: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                },
              }}
            >
              {sectionsData.map((section) => (
                <SwiperSlide>
                  <section
                    className="mobile-horizontal-section"
                    style={{
                      backgroundImage: `url(${section.backgroundImage})`,
                    }}
                    key={section.id}
                  >
                    <div className="main-banner-box-new banner-bg-image-new h-100">
                      <div className="container h-100">
                        <div className="row flex-convert-box text-center align-items-center h-100">
                          <div className="col-lg-12 col-md-12">
                            <h3 className="common-text slide-in-top-1 banner-pioner-image">
                              {section.title}{" "}
                              <img
                                src={section.awardLogo}
                                alt={section.awardName}
                              />
                              <span>Payoneer</span>
                            </h3>
                            <h1 className="banner-heading main-banner-heading slide-in-top-2">
                              {section.heading.map((part, index) =>
                                part.isSpan ? (
                                  <span key={index}>{part.text}</span>
                                ) : (
                                  <React.Fragment key={index}>
                                    {part.text}
                                  </React.Fragment>
                                )
                              )}
                            </h1>
                            <div class="row justify-content-center">
                              <div class="col-lg-9">
                                <h2 className="banner-sub-heading slide-in-top-3 ">
                                  {section.subHeading}
                                </h2>
                              </div>
                            </div>
                            <div className="w-full d-flex justify-content-center mt-5 slide-in-top-4">
                              <button
                                id="home-banner-btn"
                                className="banner-button-box-2"
                                onClick={() => navigate(section.buttonLink)}
                              >
                                {section.buttonText}
                                <i className="fa-solid fa-arrow-right"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>

        <div className="both-bars container">
          <div className="scroll-bar-container">
            <div className="scroll-bar-progress"></div>
          </div>
          <div className="scroll-bar-text">
            {sectionsButton.map((section, index) => (
              <span
                className="home-banner-slider-banner-btn"
                key={section.id}
                onClick={() => {
                  goToSlide(index);
                  handleGtmClick(section.heading);
                }}
              >
                {`${section.heading || ""}`}
              </span>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SlidOnScrollBanner;

{
  /* <main id="container" className="scroll-banner">
        <div>
          <div className="scroll-bar-container">
            <div className="scroll-bar-progress"></div>
          </div>
          <div className="scroll-bar-text">
            <span>01 Generative AI</span>
            <span>02 Blockchain Services</span>
            <span>03 Data Science & Analytics</span>
            <span>04 Machine Learning Services</span>
          </div>
        </div>

        {sectionsData.map((section) => (
          <section
            className="horizontal-section"
            style={{ backgroundImage: `url(${section.backgroundImage})` }}
            key={section.id}
          >
            <div className="main-banner-box-new banner-bg-image-new">
              <div className="container">
                <div className="row flex-convert-box">
                  <div className="col-lg-7 col-md-12">
                    <h3 className="common-text slide-in-top-1">
                      {section.title}{" "}
                      <img src={section.awardLogo} alt={section.awardName} />
                      Payoneer
                    </h3>
                    <h1 className="banner-heading main-banner-heading slide-in-top-2">
                      {section.heading.map((part, index) =>
                        part.isSpan ? (
                          <span key={index}>{part.text}</span>
                        ) : (
                          <React.Fragment key={index}>{part.text}</React.Fragment>
                        )
                      )}
                    </h1>
                    <h2 className="banner-sub-heading slide-in-top-3">{section.subHeading}</h2>
                    <div className="mt-5 slide-in-top-4">
                      <a
                        href={section.buttonLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="banner-button-box-2"
                      >
                        {section.buttonText}
                        <i className="fa-solid fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-12"></div>
                </div>
              </div>
            </div>
          </section>
        ))}
      </main> */
}

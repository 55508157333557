import React, { useState, useMemo } from 'react'
import './style.css'

const OurLocations = () => {

    const address = useMemo(() => [
        {
            title: "Head Office",
            location: "New York",
            address: "2 Park Ave 20th floor, New York, NY 10016",
            icon: "new-york.svg",
        },
        {
            title: "Europe Office",
            location: "Sweden",
            address: "Bergsgårdsgärdet 71, LGH 1101, 424 32 Angered",
            icon: "Sweden.svg",
        },
        {
            title: "Western Asia Office",
            location: "Qatar",
            address: "E1, Zone 55, Al Waab City P.O. Box 17654, Doha - Qatar",
            icon: "Qatar.svg",
        },
        {
            title: "Australia Office",
            location: "Australia",
            address: "PO Box 1244, Fitzroy North, VIC, 3068",
            icon: "Australia.svg",
        },

    ], []);

    return (
        <>
            <div className="our-locations-box">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 pb-5">
                            <h1 className="main-heading">Our Locations</h1>
                            {/* <h3 className="sub-heading">
                                Discover expert insights on the latest industry developments, trends, and emerging technologies shaping the future.
                            </h3> */}
                        </div>
                    </div>

                    <div className="row">
                        {address?.map((item, index) => {
                            return <div className="col-xl-3 col-lg-3 col-md-6 mb-md-3 space-bottom" key={index}>
                                <div className="location-card-box">
                                    <div className="location-icon">
                                        <img src={`/images/address-icons/${item?.icon}`} />
                                    </div>
                                    <h3>{item?.title}</h3>
                                    <h4>{item?.location}</h4>
                                    <p>{item?.address}</p>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default OurLocations
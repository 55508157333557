import React from 'react'
import './style.css'

const FutureSolution = ({ heading, subHeading, solutions, greenHeading }) => {


    return (
        <>
            <div className="future-solutions-box">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className='engagment-model-text'>{heading} <span>{greenHeading}</span></h1>
                            <h3 className='sub-heading'>{subHeading}</h3>
                        </div>
                    </div>

                    <div className="row border-box-industry">
                        {solutions.map((solution, index) => (
                            <div className={`col-xl-4 col-lg-4 col-md-4 ${solution.noBorder ? 'none-border' : ''}`} key={index}>
                                <div className="industry-text-box">
                                    <h2>{solution.title}</h2>
                                    <p className='common-text'>{solution.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    
                </div>
            </div>
        </>
    )
}

export default FutureSolution
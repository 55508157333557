export const tabs = [
    "Test Automation Tools",
    "Test or Defect Management Tools",
    "Documentation tools",
    "Testing Tools",
];

export const tabItems = [
    { tab: "Test Automation Tools", icon: "/images/tech-stack-icons/selenium.svg", name: "Selenium" },
    { tab: "Test Automation Tools", icon: "/images/tech-stack-icons/cypress.svg", name: "Cypress" },
    { tab: "Test Automation Tools", icon: "/images/tech-stack-icons/playwright.svg", name: "Playwright" },
    { tab: "Test Automation Tools", icon: "/images/tech-stack-icons/test-io.svg", name: "Test.io" },
    { tab: "Test Automation Tools", icon: "/images/tech-stack-icons/cucumber.svg", name: "Cucumber" },
    { tab: "Test Automation Tools", icon: "/images/tech-stack-icons/postman.svg", name: "Postman" },
    { tab: "Test Automation Tools", icon: "/images/tech-stack-icons/browserstack.svg", name: "Browserstack" },
    { tab: "Test Automation Tools", icon: "/images/tech-stack-icons/cicd.svg", name: "Ci/Cd" },

    { tab: "Test or Defect Management Tools", icon: "/images/tech-stack-icons/jira.svg", name: "Jira" },
    { tab: "Test or Defect Management Tools", icon: "/images/tech-stack-icons/trello.svg", name: "Trello" },
    { tab: "Test or Defect Management Tools", icon: "/images/tech-stack-icons/asana.svg", name: "Asana" },
    { tab: "Test or Defect Management Tools", icon: "/images/tech-stack-icons/bugzilla.svg", name: "Bugzilla" },
    { tab: "Test or Defect Management Tools", icon: "/images/tech-stack-icons/open-project.svg", name: "OpenProject" },
    { tab: "Test or Defect Management Tools", icon: "/images/tech-stack-icons/testrail.svg", name: "Testrail" },
    { tab: "Test or Defect Management Tools", icon: "/images/tech-stack-icons/click-up.svg", name: "Clickup" },

    { tab: "Documentation tools", icon: "/images/tech-stack-icons/notion.svg", name: "Notion" },
    { tab: "Documentation tools", icon: "/images/tech-stack-icons/jira-confluence.svg", name: "Jira-Confluence " },

    { tab: "Testing Tools", icon: "/images/tech-stack-icons/excel.svg", name: "Excel" },
    { tab: "Testing Tools", icon: "/images/tech-stack-icons/word.svg", name: "Word" },
    { tab: "Testing Tools", icon: "/images/tech-stack-icons/apache.svg", name: "Apache Jmeter" },
    { tab: "Testing Tools", icon: "/images/tech-stack-icons/postman.svg", name: "Postman" },
    { tab: "Testing Tools", icon: "/images/tech-stack-icons/swagger.svg", name: "Swagger" },
];

export const faqData = [
    {
        id: "collapseOne",
        question: "What is software quality assurance (QA) and why is it important?",
        answer:
            "Software quality assurance (QA) ensures that your application functions properly, meets user expectations, and complies with industry standards. By using both automated and manual testing, QA services identify potential issues early in the development process, saving time and cost while ensuring reliable, high-quality products.",
    },
    {
        id: "collapseTwo",
        question: "How do automated testing and manual testing differ?",
        answer:
            "Automated testing uses scripts and tools to perform tests quickly and repeatedly, making it perfect for regression tests and large-scale systems. On the other hand, manual testing involves human testers who explore applications to find unexpected behavior and usability issues that automation may miss. Both are crucial to delivering top-quality software.",
    },
    {
        id: "collapseThree",
        question: "What types of QA services do you provide?",
        answer:
            "We offer a full range of software quality assurance services, including automated testing, manual testing, performance testing, security testing, and QA consulting. Whether you're launching a mobile app or a complex web platform, we ensure everything runs smoothly and securely for your users.",
    },
    {
        id: "fourth",
        question: "How can QA consulting help my business?",
        answer:
            "Our QA consulting services guide your team through the best practices in software testing. We analyze your current processes, recommend improvements, and help implement testing strategies that fit your project's specific needs. Our goal is to ensure that your software meets all functional, security, and user experience standards.",
    },
    {
        id: "fifth",
        question: "Is your QA service compatible with all types of software?",
        answer:
            "Yes, we provide QA services for all types of software—whether it's mobile apps, web applications, or enterprise systems. Our team has experience across various industries, ensuring that your software solution is tested thoroughly, from functionality to user experience and security.",
    },
    {
        id: "sixth",
        question: "Why should I invest in QA services for my software project?",
        answer:
            "Investing in QA services helps you catch potential issues before they reach your customers, ensuring smooth user experiences and reducing the risk of costly errors. With the help of automated and manual testing, we help you create a product that’s high-performing, secure, and user-friendly.",
    },
    {
        id: "seventh",
        question: "How can I get started with your QA services?",
        answer:
            "Getting started is easy! Just reach out to us, and our experts will assess your project and recommend the best QA strategy. We will walk you through the testing phases and help ensure that your software meets all necessary standards for a successful launch. Let’s work together to deliver quality solutions!",
    },
];

export const TypesOfBuildData = [
    {
        id: "collapseOne",
        question: "Automated Testing",
        answer: {
            discription:
                "Automated testing speeds up the QA process, running thousands of test cases instantly to catch bugs before launch. By leveraging automation, we ensure consistent quality, reducing manual effort while enhancing software performance, security, and reliability for seamless user experiences.",
            listTitle: "Our automated testing services are best for:",
            listContent: [
                {
                    line: "Large-scale enterprise applications",
                },
                {
                    line: "Frequent software updates & releases",
                },
                {
                    line: "Performance optimization & stress testing",
                },
                {
                    line: "Identifying security vulnerabilities",
                },
                {
                    line: "Enhancing accuracy with minimal manual effort",
                },
            ],
        },
    },
    {
        id: "collapseTwo",
        question: "Manual Testing",
        answer: {
            discription:
                "Sometimes, only a human eye can spot subtle issues! Our manual testing experts simulate real-world scenarios, ensuring software feels intuitive, responsive, and user-friendly. We dig deep into your system, making sure no bug slips through, delivering a polished, flawless user experience.",
            listTitle: "Our manual testing services are best for:",
            listContent: [
                {
                    line: "Usability & exploratory testing",
                },
                {
                    line: "Complex workflows & user journeys",
                },
                {
                    line: "Apps requiring human judgment",
                },
                {
                    line: "Ensuring intuitive UI/UX design",
                },
                {
                    line: "Identifying edge-case bugs",
                },
            ],
        },
    },
    {
        id: "collapseThree",
        question: "Performance Testing",
        answer: {
            discription:
                "A slow app drives users away! We test your software under real-world pressure—simulating high traffic, stress loads, and extreme conditions to ensure smooth, lag-free performance. Our testing guarantees your software solutions run efficiently, even during peak demand.",
            listTitle: "Our performance testing services are best for:",
            listContent: [
                {
                    line: "High-traffic web & mobile applications",
                },
                {
                    line: "Load balancing & scalability testing",
                },
                {
                    line: "Ensuring quick response times",
                },
                {
                    line: "Apps handling real-time data processing",
                },
                {
                    line: "Identifying performance bottlenecks",
                },
            ],
        },
    },
    {
        id: "fourth",
        question: "Security Testing",
        answer: {
            discription:
                "Cyber threats are real—and we ensure your software is fortified against them. Our security testing detects and fixes vulnerabilities before hackers do, safeguarding sensitive data, user privacy, and business integrity. From penetration testing to compliance checks, we've got your security covered.",
            listTitle: "Our security testing services are best for:",
            listContent: [
                {
                    line: "Protecting sensitive customer data",
                },
                {
                    line: "E-commerce & financial transactions",
                },
                {
                    line: "Compliance with security standards (ISO, GDPR)",
                },
                {
                    line: "Vulnerability assessments & ethical hacking",
                },
                {
                    line: "Preventing unauthorized access & threats",
                },
            ],
        },
    },
    {
        id: "fifth",
        question: "Compatibility Testing",
        answer: {
            discription:
                "Your software should work flawlessly everywhere! Whether it’s iOS, Android, Windows, Mac, or wearables, our compatibility testing ensures a seamless experience across all platforms, browsers, and devices. No matter where your users are, your software stays smooth and accessible.",
            listTitle: "Our compatibility testing services are best for:",
            listContent: [
                {
                    line: "Multi-platform mobile & desktop applications",
                },
                {
                    line: "Ensuring browser & OS compatibility",
                },
                {
                    line: "Responsive web applications",
                },
                {
                    line: "Wearables & IoT devices",
                },
                {
                    line: "Cross-device consistency",
                },
            ],
        },
    },
    {
        id: "sixth",
        question: "Regression Testing",
        answer: {
            discription:
                "Every update should improve, not break your app! Our regression testing ensures that when new features roll out, everything still works perfectly. We retest core functions, ensuring previous updates and integrations stay intact and reliable, preventing unexpected glitches.",
            listTitle: "Our regression testing services are best for:",
            listContent: [
                {
                    line: "Continuous software updates & enhancements",
                },
                {
                    line: "Testing new features without breaking old ones",
                },
                {
                    line: "Complex systems with multiple integrations",
                },
                {
                    line: "Fast-paced agile development cycles",
                },
                {
                    line: "Preventing post-release issues",
                },
            ],
        },
    },
];

export const WhoWeServeData = [
    {
        icon: "/images/servie-icon/startups.svg",
        title: "Startups",
        description:
            "Testing from the ground up to ensure your MVP or first release is smooth, functional, and user-friendly. Our QA services help startups identify potential issues before launch, ensuring a seamless experience for early adopters.",
        link: "#",
        borderClass: "border-1",
    },
    {
        icon: "/images/servie-icon/companies.svg",
        title: "Software product companies",
        description:
            "Software companies require ongoing testing as their platforms grow. We offer continuous QA services to identify bugs, compatibility issues, and security vulnerabilities, ensuring that your software solutions perform optimally across all versions and updates.",
        link: "#",
        borderClass: "border-2",
    },
    {
        icon: "/images/servie-icon/industries.svg",
        title: "Enterprises from 30+ industries",
        description:
            "Large-scale businesses require robust software quality assurance to maintain compliance, security, and performance across complex systems. We ensure enterprise software remains secure, scalable, and efficient while minimizing downtime and risks.",
        link: "#",
        borderClass: "border-3",
    },
];

export const HighvaluesData = [
    {
        image: "qa-icon-1.svg",
        text: "Automated Testing",
    },
    {
        image: "qa-icon-2.svg",
        text: "Manual Testing",
    },
    {
        image: "qa-icon-3.svg",
        text: "Performance Testing",
    },
    {
        image: "qa-icon-4.svg",
        text: "Security Testing",
    },
    {
        image: "qa-icon-5.svg",
        text: "Functional Testing",
    },
    {
        image: "qa-icon-6.svg",
        text: "Regression Testing",
    },
    {
        image: "qa-icon-7.svg",
        text: "Usability Testing",
    },
    {
        image: "qa-icon-8.svg",
        text: "Compatibility Testing",
    },
    {
        image: "qa-icon-9.svg",
        text: "Load & Stress Testing",
    },
    {
        image: "qa-icon-10.svg",
        text: "Bug Tracking & Reporting",
    },
];

export const WhyChooseBitsclanData = [
    {
        id: "Tab1",
        label: "Quality Because It Matters",
        content:
            "We prioritize software quality assurance to ensure flawless performance, security, and reliability. Our rigorous testing methods help deliver bug-free software.",
        btnText: "Connect with the clan",
        btnLink: "#",
    },
    {
        id: "Tab2",
        label: "The best-of-the-breed talent",
        content:
            "Our QA specialists bring years of experience in software testing services, ensuring high-quality, scalable, and reliable applications.",
        btnText: "Connect with the clan",
        btnLink: "#",
    },
    {
        id: "Tab3",
        label: "Time zone Alignment",
        content:
            "We work around your time zone, providing real-time communication and quick turnarounds so your QA testing stays on track and hassle-free.",
        btnText: "Connect with the clan",
        btnLink: "#",
    },
    {
        id: "Tab4",
        label: "Automation for Faster Testing",
        content:
            "With automated testing, we cut down manual efforts, speeding up the QA process while ensuring accuracy, consistency, and efficiency.",
        btnText: "Connect with the clan",
        btnLink: "#",
    },
    {
        id: "Tab5",
        label: "Security Testing for Data Protection",
        content:
            "Our QA services include security testing, identifying vulnerabilities and ensuring your software is safe from breaches and cyber threats.",
        btnText: "Connect with the clan",
        btnLink: "#",
    },
];

export const DevelopmentServicesData = [
    {
        id: "Tab1",
        label: "Web Portal QA & Testing",
        content:
            "A flawless web portal is crucial for a seamless user experience. Our comprehensive QA services focus on performance, security, and functionality to ensure your portal runs efficiently without issues.",
        btnText: "Let's Discuss",
        btnLink: "#",
        imgs: [
            {
                img: "/images/services-icons/software-quality-assurance/Apache_JMeter.png",
            },
            {
                img: "/images/services-icons/software-quality-assurance/Postman_(software).png",
            },
            {
                img: "/images/services-icons/software-quality-assurance/Selenium_logo.png",
            },
        ],
    },
    {
        id: "Tab2",
        label: "Backend QA & Security Testing",
        content:
            "We conduct rigorous backend testing to ensure your web portal’s infrastructure is secure, scalable, and free from vulnerabilities. Our security testing includes penetration testing, database integrity checks, API security validation, and server-side performance analysis to prevent cyber threats and ensure smooth functionality.",
        btnText: "Let's Discuss",
        btnLink: "#",
        imgs: [
            {
                img: "/images/services-icons/software-quality-assurance/Apache_JMeter.png",
            },
            {
                img: "/images/services-icons/software-quality-assurance/Postman_(software).png",
            },
            {
                img: "/images/services-icons/software-quality-assurance/Selenium_logo.png",
            },
        ],
    },
    {
        id: "Tab3",
        label: "Frontend Testing & UI/UX Validation",
        content:
            "A well-optimized frontend is key to user engagement and retention. Our team performs UI/UX validation, responsiveness testing, and cross-browser compatibility checks to ensure your portal delivers an intuitive, accessible, and visually appealing experience across all devices and platforms.",
        btnText: "Let's Discuss",
        btnLink: "#",
        imgs: [
            {
                img: "/images/services-icons/software-quality-assurance/Apache_JMeter.png",
            },
            {
                img: "/images/services-icons/software-quality-assurance/Postman_(software).png",
            },
            {
                img: "/images/services-icons/software-quality-assurance/Selenium_logo.png",
            },
        ],
    },
    {
        id: "Tab4",
        label: "Web Application QA Consulting",
        content:
            "We offer QA consulting to help businesses implement effective testing strategies, automation frameworks, and quality control processes. Our expertise ensures your web applications adhere to industry standards and best practices, reducing bugs, downtime, and performance bottlenecks.",
        btnText: "Let's Discuss",
        btnLink: "#",
        imgs: [
            {
                img: "/images/services-icons/software-quality-assurance/Apache_JMeter.png",
            },
            {
                img: "/images/services-icons/software-quality-assurance/Postman_(software).png",
            },
            {
                img: "/images/services-icons/software-quality-assurance/Selenium_logo.png",
            },
        ],
    },
    {
        id: "Tab5",
        label: "Custom Software Testing for Enterprises",
        content:
            "Enterprise applications require specialized testing to handle high traffic, complex workflows, and multiple integrations. Our tailored QA solutions include performance testing, scalability assessments, compliance verification, and real-time monitoring to ensure business continuity.",
        btnText: "Let's Discuss",
        btnLink: "#",
        imgs: [
            {
                img: "/images/services-icons/software-quality-assurance/Apache_JMeter.png",
            },
            {
                img: "/images/services-icons/software-quality-assurance/Postman_(software).png",
            },
            {
                img: "/images/services-icons/software-quality-assurance/Selenium_logo.png",
            },
        ],
    },
    {
        id: "Tab6",
        label: "Headless CMS & WordPress QA",
        content:
            "For content-driven businesses, we provide end-to-end testing for Headless CMS and WordPress platforms. Our process includes plugin compatibility checks, SEO validation, speed optimization, and security audits to enhance content delivery, search rankings, and overall performance.",
        btnText: "Let's Discuss",
        btnLink: "#",
        imgs: [
            {
                img: "/images/services-icons/software-quality-assurance/Apache_JMeter.png",
            },
            {
                img: "/images/services-icons/software-quality-assurance/Postman_(software).png",
            },
            {
                img: "/images/services-icons/software-quality-assurance/Selenium_logo.png",
            },
        ],
    },
];

export var mainBannerImg = {
    backgroundImage: `url(/images/what-we-do/software-quality-assurance-service.png)`
}

export const schemaData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
        "@type": "Question",
        "name": "What is software quality assurance (QA) and why is it important?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Software quality assurance (QA) ensures that your application functions properly, meets user expectations, and complies with industry standards. By using both automated and manual testing, QA services identify potential issues early in the development process, saving time and cost while ensuring reliable, high-quality products."
        }
    }, {
        "@type": "Question",
        "name": "How do automated testing and manual testing differ?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Automated testing uses scripts and tools to perform tests quickly and repeatedly, making it perfect for regression tests and large-scale systems. On the other hand, manual testing involves human testers who explore applications to find unexpected behavior and usability issues that automation may miss. Both are crucial to delivering top-quality software."
        }
    }, {
        "@type": "Question",
        "name": "How can QA consulting help my business?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Our QA consulting services guide your team through the best practices in software testing. We analyze your current processes, recommend improvements, and help implement testing strategies that fit your project's specific needs. Our goal is to ensure that your software meets all functional, security, and user experience standards."
        }
    }, {
        "@type": "Question",
        "name": "What types of QA services do you provide?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "We offer a full range of software quality assurance services, including automated testing, manual testing, performance testing, security testing, and QA consulting. Whether you're launching a mobile app or a complex web platform, we ensure everything runs smoothly and securely for your users."
        }
    }, {
        "@type": "Question",
        "name": "Is your QA service compatible with all types of software?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Yes, we provide QA services for all types of software—whether it's mobile apps, web applications, or enterprise systems. Our team has experience across various industries, ensuring that your software solution is tested thoroughly, from functionality to user experience and security."
        }
    }, {
        "@type": "Question",
        "name": "Why should I invest in QA services for my software project?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "nvesting in QA services helps you catch potential issues before they reach your customers, ensuring smooth user experiences and reducing the risk of costly errors. With the help of automated and manual testing, we help you create a product that’s high-performing, secure, and user-friendly."
        }
    }, {
        "@type": "Question",
        "name": "How can I get started with your QA services?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Getting started is easy! Just reach out to us, and our experts will assess your project and recommend the best QA strategy. We will walk you through the testing phases and help ensure that your software meets all necessary standards for a successful launch. Let’s work together to deliver quality solutions!"
        }
    }]
}
import React, { useState } from "react";
import './style.css'
import { Link } from "react-router-dom";

const EnterpriseItServices = ({IndexActive, SlideBox}) => {
  const [activeIndex, setActiveIndex] = useState(IndexActive); // Default active column is index 1 (second column)

  const handleMouseEnter = (index) => {
    setActiveIndex(index); // Set active column on hover
  };

  const handleMouseLeave = () => {
    setActiveIndex(null); // Clear active column when not hovered
  };

  const handleGtmClick = (serviceName) => {
    // Push data to Google Tag Manager (GTM)
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "enterprise_service_insights",
      enterprise_service_insights: serviceName,
    });
  };

  return (
    <>
      <div className="container p-0">
        
        <div className="custom-container">
            {SlideBox?.map((data, index) => (
                <div
                key={index}
                className={`custom-column ${activeIndex === index ? "active" : ""} ${
                    activeIndex === null && index === IndexActive ? "default-active" : ""
                }`}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                >
                <div className="column-content">
                  <div className="inter-box" style={{backgroundImage: `url(${data?.bgImg})`}}>
                    <div className="inter-box-overlay">
                      <div>
                        <h1 className="main-heading">{data?.title}</h1>
                        <h3 className="sub-heading">{data?.content}</h3>
                      </div>
                      <div  >
                        <Link to={`/${data?.linkURL}`} id="enterprise_services_insights_btn" onClick={() => handleGtmClick(data?.title)}>
                          {data?.linkText} <i className="fa-solid fa-arrow-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>

                </div>
                </div>
            ))}
        </div>
      </div>
      </>
  );
};

export default EnterpriseItServices;

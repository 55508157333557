import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const contactFromHomeAPI = createAsyncThunk(
    "contactFromHomeAPI",
    async (formData, { rejectWithValue }) => {
        try {
            const response = await fetch("https://remote-pad.bitsclan.com/api/contact-us-two", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const result = await response.json();
            return result;
        } catch (error) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({ message: "Something went wrong" });
        }
    }
);

export const contactFromContactUsAPI = createAsyncThunk(
    "contactFromContactUsAPI",
    async (formData, { rejectWithValue }) => {
        try {
            const response = await fetch("https://remote-pad.bitsclan.com/api/contact-us", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const result = await response.json();
            return result;
        } catch (error) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({ message: "Something went wrong" });
        }
    }
);

const initialState = {
    data: null,
    loading: false,
    error: null,
};

const contactFormApiSlice = createSlice({
    initialState,
    name: "contactFormApiSlice",
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(contactFromHomeAPI.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(contactFromHomeAPI.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(contactFromHomeAPI.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || "Something went wrong";
            });
    },

    extraReducers: (builder) => {
        builder
            .addCase(contactFromContactUsAPI.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(contactFromContactUsAPI.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(contactFromContactUsAPI.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || "Something went wrong";
            });
    },
})

export const { } = contactFormApiSlice.actions;
export default contactFormApiSlice.reducer;
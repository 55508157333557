import React, { useState } from "react";
import "./style.css";
import { Link } from "react-router-dom";

const DevelopmentServices = ({
  heading,
  subheading,
  secondSubHeading,
  tabs,
}) => {
  const [activeTab, setActiveTab] = useState("Tab1");

  return (
    <>
      <div className="development-services-main">
        <div className="container">
          <div className="row">
            <div className="col-md-10 development-top-content">
              <h2>{heading}</h2>
              <p>{subheading}</p>
              <p>{secondSubHeading}</p>
            </div>
          </div>
          <div className="row development-tab-block">
            <div className="development-tab-menu col-xl-3 col-lg-3 col-md-5">
              {tabs.map((tab) => (
                <div
                  key={tab.id}
                  className={`development-tab-item ${activeTab === tab.id ? "active" : ""
                    }`}
                  onClick={() => setActiveTab(tab.id)}
                >
                  {tab.label}
                </div>
              ))}
            </div>
            <div className="development-tab-content col-xl-9 col-lg-9 col-md-7">
              <div className="development-tab-content-box">
                <h3 className="mb-0">
                  {tabs.find((tab) => tab.id === activeTab)?.label}
                </h3>
                <p className="mb-0">
                  {tabs.find((tab) => tab.id === activeTab)?.content}
                </p>
                <p className="mt-3">
                  {tabs.find((tab) => tab.id === activeTab)?.secondContent}
                </p>
                <div className="d-flex justify-content-start">
                  {tabs.find((tab) => tab.id === activeTab)?.btnLink ? (
                    <Link
                      to={"/contact-us"}
                      className="developement-services-cta mt-3"
                    >
                      <p className="my-0">{tabs.find((tab) => tab.id === activeTab)?.btnText}</p>{" "}
                      <i class="fa-solid fa-arrow-right"></i>
                    </Link>
                  ) : (
                    ""
                  )}
                </div>

                {/* <div className="development-detail-icon">
                  {tabs.find((tab) => tab.id === activeTab)?.imgs &&
                    tabs
                      .find((tab) => tab.id === activeTab)
                      ?.imgs.map((data) => <img src={`${data.img}`} />)}
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DevelopmentServices;

import React, { useState } from 'react'
import './style.css'

const FAQ = ({ faqs, mainHeading, subHeading }) => {

    const [expandedIndex, setExpandedIndex] = useState(0);

    const handleToggle = (index) => {
        setExpandedIndex((prevIndex) => (prevIndex === index ? -1 : index));
    };

    return (
        <>
            <div className="ai-industry-services-box">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="main-heading">{mainHeading}</h1>
                            <h3 className="sub-heading">{subHeading}</h3>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="accordion accordian-box-faq" id="accordionExample">
                                {faqs?.map((faq, index) => (
                                    <div className="accordion-item" key={faq.id}>
                                        <h2 className="accordion-header">
                                            <button
                                                className={`accordion-button ${expandedIndex === index ? 'expanded-arrow' : 'collapsed'}`}
                                                type="button"
                                                onClick={() => handleToggle(index)}
                                                data-bs-toggle="collapse"
                                                data-bs-target={`#${faq.id}`}
                                                aria-expanded={expandedIndex === index ? 'true' : 'false'}
                                                aria-controls={faq.id}
                                            >
                                                {faq.question}
                                            </button>
                                        </h2>
                                        <div
                                            id={faq.id}
                                            className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                <p className="common-text">{faq.answer}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FAQ
import React, { useState } from "react";
import './style.css';
import { Link } from "react-router-dom";

const WhyChooseBitsclan = ({ heading, subheading, tabs, bgClassHere, btnText2 }) => {

    const [activeTab, setActiveTab] = useState("Tab1");



    return (
        <>
            <div className="why-choose-bitsclan-main">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 why-top-content text-center">
                            <h2>{heading}</h2>
                            <p>{subheading}</p>
                        </div>
                    </div>
                    <div className="row why-tab-content-box">
                        <div className="tab-menu border-right-box col-md-4">
                            {tabs.map((tab) => (
                                <div
                                    key={tab.id}
                                    className={`tab-item ${activeTab === tab.id ? "active" : ""} d-flex flex-row gap-2 align-items-center`}
                                    onClick={() => setActiveTab(tab.id)}
                                >
                                    <p className="m-0">{tab.label}</p>
                                </div>
                            ))}
                        </div>
                        <div className="tab-content col-md-8 who-we-are-page-content">
                            <div className={` ${bgClassHere || null}`}>
                                <p>{tabs.find((tab) => tab.id === activeTab)?.content}</p>

                                <ul>
                                    {tabs.find((tab) => tab.id === activeTab)?.featureList?.map((list, index) => (
                                        <li key={index}>{list?.text}</li>
                                    ))}
                                </ul>

                                <div className="hire-us-cta-btn">
                                    {tabs.find((tab) => tab.id === activeTab)?.btnText && <Link className="btn-left-space" to={"/contact-us"}>{tabs.find((tab) => tab.id === activeTab)?.btnText}</Link>}
                                </div>

                                <div className="hire-us-cta-btn d-flex ms-4 mt-3">
                                    {btnText2 && <a href="https://calendly.com/bitsclan-it/30min?month=2025-01" target="_blank">{btnText2}</a>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WhyChooseBitsclan

import React, { useEffect } from "react";
import Footer from "../components/footer/Footer";
import SimilerBanner from "../components/similerBanner/SimilerBanner";
import AIBannerBox from "../components/ai-banner-box/AIBannerBox";
import FutureSolution from "../components/futuresolution/FutureSolution";
import BitsclanAI from "../components/bitsclanAi/BitsclanAI";
import Testimonial from "../components/testimonila/Testimonial";
import FAQ from "../components/Fqas/FAQ";
import { Helmet } from "react-helmet";
import CoverTheStack from "../components/coverthestack/CoverTheStack";
import MegaHeader from "../components/MegaHeader/MegaHeader";
import AiDevelopmentPowerSection from "../components/ai-development-power-section/AiDevelopmentPowerSection";
import DevelopmentServices from "../components/development-services/DevelopmentServices";
import { AiDevelopmentServicesData, AiDevPageFaqData, AIDevPageSolutionsData } from "../data/aiDevelopmentPageData";
import tabData from "../data/industryData";
import PioneeringChange from "../components/pioneeringChange/PioneeringChange";
import TrendsInsights from "../components/trends-n-insights/TrendsInsights";
import DarkRoadMap from "../components/dark-roadmap/darkRoadMap";
import ContactSection from "../components/contactSection/ContactSection";
import { mainBannerImg, schemaData, tabItems, tabs } from "../data/ai-development-data";

const AiDevelopment = () => {
  return (
    <>
      <Helmet>
        <title>
          AI Development Services | Custom AI/ML Solutions | Bitsclan
        </title>
        <meta
          name="description"
          content="Explore cutting-edge AI development services to transform your business with custom machine learning and automation solutions"
        />
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
      </Helmet>

      {/* <Header /> */}
      <div className="sticky-header">
        <MegaHeader />
      </div>

      <SimilerBanner
        diffBannerBackgroundImage={mainBannerImg}
        mainHeading={"AI Development"}
        greenHeading={"Services"}
        subHeading={"Leverage AI the way it’s meant to be."}
        content={
          "Improve your business with automation, chatbots, generative AI, custom applications, advanced reporting, and more. The possibilities are endless with our AI services."
        }
        btnText2={"Let's Get Started"}
        sideImage={""}
      />
      <Testimonial
        heading="Our Customer Reviews Say It All About Our AI Development Services"
        content="Read genuine customer feedback and discover how our AI development services have consistently exceeded expectations and delivered outstanding results"
      />
      <AiDevelopmentPowerSection />
      <DevelopmentServices
        tabs={AiDevelopmentServicesData}
        heading={"Tap into the future with our AI development Service"}
        subheading={
          "AI is not just a technology; it is the future. Every business today can use AI to boost operational efficiency, automate processes, and scale revenue. The question is how. That's where Bitsclan IT Solutions comes in. Explore our artificial intelligence services below."
        }
      />
      <AIBannerBox />
      <PioneeringChange
        tabData={tabData}
        greenHeading={"Across Industries"}
        mainHeading={"Pioneering Change"}
        content={
          "Leading innovation and transforming sectors with cutting-edge solutions and insights."
        }
      />
      <FutureSolution
        heading="Build Future-ready Solutions With Our"
        greenHeading={"AI Development Services"}
        subHeading="As a dedicated AI development company, we are the experts in the field of Artificial Intelligence. Our AI experts are among the best in the domain with years of experience in automation services."
        solutions={AIDevPageSolutionsData}
      />
      <BitsclanAI />
      <DarkRoadMap />
      <CoverTheStack
        whereStart="Libraries"
        tabs={tabs}
        tabItems={tabItems}
        subHeading={
          "At Bitsclan, we ensure that your DevOps processes are powered by the most advanced technologies available. Our carefully curated tech stack is designed to maximize efficiency, security, and scalability at every stage of the software development lifecycle. "
        }
        mainHeading={
          "Optimize Your AI Development with Industry-Leading Tech Stack"
        }
      />
      <TrendsInsights category="ai-developmet"/>
      <FAQ
        faqs={AiDevPageFaqData}
        mainHeading={"Frequently Asked Questions"}
        subHeading={
          "Get quick answers to your questions about our AI development process and services."
        }
      />
      <ContactSection />
      <Footer />
    </>
  );
};

export default AiDevelopment;

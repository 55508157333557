import React from "react";
import EnterpriseItServices from "./slide-box/EnterpriseItServices";

const topbox = [
  {
    id: 1,
    title: "Mobile App Development",
    content:
      "With our AI-integrated mobile app development services, we offer future-ready solutions for iOS, Android and Windows.",
    linkText: "Learn More",
    linkURL: "mobile-app-development",
    bgImg: "/images/enterprise-services/mobile-app-development.png",
  },
  {
    id: 2,
    title: "DevOps Services",
    content:
      "Streamlining development and operations for faster delivery and enhanced collaboration.",
    linkText: "Learn More",
    linkURL: "dev-ops-services",
    bgImg: "/images/enterprise-services/devops-services.png",
  },
  {
    id: 3,
    title: "AI Services",
    content:
      "At Bitsclan, our AI-powered solutions are designed to seamlessly meet your digital needs while upholding the highest standards of ethics and efficiency.",
    linkText: "Learn More",
    linkURL: "ai-development",
    bgImg: "/images/enterprise-services/ai-services.png",
  },
  {
    id: 4,
    title: "Cyber Security",
    content:
      "Protecting your digital assets with comprehensive security solutions against evolving threats.",
    linkText: "Learn More",
    linkURL: "cyber-security",
    bgImg: "/images/enterprise-services/cyber-security.png",
  },
];

const bottombox = [
  {
    id: 1,
    title: "Quality Assurance Services",
    content:
      "Ensuring top-notch quality through rigorous testing and validation for flawless performance.",
    linkText: "Learn More",
    linkURL: "quality-assurance-services",
    bgImg: "/images/enterprise-services/quality-assurance.png",
  },
  {
    id: 2,
    title: "Web Application Development",
    content:
      "Crafting robust, scalable web applications to elevate your business online.",
    linkText: "Learn More",
    linkURL: "web-app-services",
    bgImg: "/images/enterprise-services/uiux.png",
  },
  {
    id: 3,
    title: "Data Analytics",
    content:
      "Transforming data into actionable insights to drive informed decision-making and growth.",
    linkText: "Learn More",
    linkURL: "data-and-analytics",
    bgImg: "/images/enterprise-services/data-and-analytics.png",
  },
  {
    id: 4,
    title: "Blockchain Services",
    content:
      "We offer blockchain services with cloud-based solutions for better integration, seamless functionality and a secure environment",
    linkText: "Learn More",
    linkURL: "blockchain-development",
    bgImg: "/images/enterprise-services/block-chain-services.png",
  },
  {
    id: 5,
    title: "Custom Software Development",
    content:
      "We offer custom software development services for your business offering scalability and specificity.",
    linkText: "Learn More",
    linkURL: "custom-development",
    bgImg: "/images/enterprise-services/software-development.png",
  },
];

const MainEnterpriseItServices = () => {
  return (
    <>
      <div className="main-enterprise-services">
        <div className="container" id="it-services" >
          <div className="row">
            <div className="col-md-12 px-4 pb-3">
              <h1 className="main-heading">Enterprise Service Insights</h1>
              {/* <h3 className="sub-heading">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
              <br />
              Lorem Ipsum has been the industry's standard dummy text ever since
              the 1500s,
            </h3> */}
            </div>
          </div>
          <EnterpriseItServices IndexActive={2} SlideBox={topbox} />
          <EnterpriseItServices IndexActive={0} SlideBox={bottombox} />
        </div>
      </div>
    </>
  );
};

export default MainEnterpriseItServices;

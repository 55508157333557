import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const MegaHeader = () => {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("USA"); // Default country
  const dropdownRef = useRef(null);
  const allowedCountries = ["USA", "KSA", "AUS"];
  const location = useLocation();
  const currentCountry = allowedCountries.find((country) =>
    location.pathname.includes(`/${country}`)
  );

  const country = useSelector((state) => state.country.name);
  const dispatch = useDispatch();

  const toggleDropdown = (dropdownName) => {
    setActiveDropdown((prevActiveDropdown) =>
      prevActiveDropdown === dropdownName ? null : dropdownName
    );
    document.body.classList.toggle(
      "mega-menu-open",
      activeDropdown !== dropdownName
    );
  };

  const closeDropdown = () => {
    setActiveDropdown(null);
  };

  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    closeDropdown(); // Close the dropdown after selection
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setActiveDropdown(null);
      document.body.classList.remove("mega-menu-open");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // shedule a call model

  const [activeBox, setActiveBox] = useState(null);

  const handleClick = (box) => {
    setActiveBox(box === activeBox ? null : box);
  };

  const savedForcedRegion = sessionStorage.getItem("forcedRegion");


  return (
    <>
      <nav className="navbar navbar-box-main navbar-expand-xl">
        {country === "PK" && (location.pathname === "/life-at-bitsclan" && !savedForcedRegion) ? (
          <div className="container h-100 justify-content-center">
            <Link
              className="navbar-brand"
              to={`/${currentCountry ? currentCountry : ""}`}
            >
              <img width={180} height={50} src="/images/logo.png" alt="Logo" />
            </Link>
          </div>
        ) : (
          <div className="container h-100">
            <Link
              className="navbar-brand"
              to={`/${currentCountry ? currentCountry : ""}`}
            >
              <img width={180} height={50} src="/images/logo.png" alt="Logo" />
            </Link>

            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>

            <div
              className="collapse navbar-collapse h-100"
              id="navbarSupportedContent"
            >
              <ul
                className="navbar-nav mb-2 mb-lg-0 mega-header-main-list ms-auto"
                ref={dropdownRef}
              >
                <li className="nav-item">
                  <Link to="/" className="dropdown-toggle nav-link">
                    Home
                  </Link>
                </li>

                {/* Company Mega Menu */}
                <li
                  className="nav-item"
                  onMouseEnter={() => toggleDropdown("dropdown1")}
                  onMouseLeave={closeDropdown}
                >
                  <a
                    className="dropdown-toggle nav-link"
                    href="javascript:void(0)"
                    role="button"
                    aria-expanded={
                      activeDropdown === "dropdown1" ? "true" : "false"
                    }
                  >
                    Company{" "}
                    <i
                      className={`fa-solid fa-angle-down ${activeDropdown === "dropdown1" ? "up-arrow" : ""
                        }`}
                    ></i>
                  </a>
                  <div
                    className={`dropdown-menu mega-menu ${activeDropdown === "dropdown1" ? "show" : ""
                      }`}
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-12 col-lg-3 col-md-12 col-xl-3">
                          <ul className="mega-menu-list-left">
                            <h3 className="mega-header-heading">
                              Company insights & Services
                            </h3>

                            <li>
                              <Link to="/Industries">
                                <span>Industry</span>{" "}
                                <span className="mega-menu-sub-text">
                                  Discover tailored tech solutions that address
                                  unique industry challenges and drive
                                  innovation.
                                </span>
                              </Link>
                            </li>

                            <li>
                              <Link to="/engagment-model">
                                <span>Engagment Model</span>
                                <span className="mega-menu-sub-text">
                                  An engagement model streamlines collaboration
                                  by defining scope, roles, and deliverables.
                                </span>
                              </Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-12 col-lg-3 col-md-12 col-xl-3">
                          <div className="space-top-box-main">
                            <ul className="mega-menu-list-left">
                              <li>
                                <Link to="/life-at-bitsclan">
                                  <span>Life At Bitsclan</span>{" "}
                                  <span className="mega-menu-sub-text">
                                    Explore our vibrant culture, where
                                    innovation and teamwork fuel every success.
                                  </span>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                {/* What we do Mega Menu */}
                <li
                  className="nav-item"
                  onMouseEnter={() => toggleDropdown("dropdown2")}
                  onMouseLeave={closeDropdown}
                >
                  <a
                    className="dropdown-toggle nav-link"
                    href="javascript:void(0)"
                    role="button"
                    aria-expanded={
                      activeDropdown === "dropdown2" ? "true" : "false"
                    }
                  >
                    What We Do{" "}
                    <i
                      className={`fa-solid fa-angle-down ${activeDropdown === "dropdown2" ? "up-arrow" : ""
                        }`}
                    ></i>
                  </a>
                  <div
                    className={`dropdown-menu mega-menu ${activeDropdown === "dropdown2" ? "show" : ""
                      }`}
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-12 col-lg-3 col-md-12 col-xl-3">
                          <ul className="mega-menu-list-left">
                            <h3 className="mega-header-heading">
                              Innovative tech solutions
                            </h3>

                            <li>
                              <Link to="/web-app-development-services">
                                <span>Web Application Development</span> <br />{" "}
                                <span className="mega-menu-sub-text">
                                  Crafting robust, scalable web applications to
                                  elevate your business online.
                                </span>
                              </Link>
                            </li>

                            <li>
                              <Link to="/artificial-intelligence-development-services">
                                <span>AI Development</span> <br />{" "}
                                <span className="mega-menu-sub-text">
                                  Unlock intelligent solutions that automate,
                                  optimize, and elevate business processes.
                                </span>
                              </Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-12 col-lg-3 col-md-12 col-xl-3">
                          <div className="space-top-box-main">
                            <ul className="mega-menu-list-left">
                              <li>
                                <Link to="/custom-software-development-services">
                                  <span>Custom Development</span> <br />{" "}
                                  <span className="mega-menu-sub-text">
                                    Tailored software solutions designed to meet
                                    your unique business needs.
                                  </span>
                                </Link>
                              </li>

                              <li>
                                <Link to="/mobile-app-development-services">
                                  <span>Mobile App Development</span> <br />{" "}
                                  <span className="mega-menu-sub-text">
                                    Creating intuitive mobile experiences that
                                    engage users and drive business growth.
                                  </span>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="col-12 col-lg-3 col-md-12 col-xl-3">
                          <div className="space-top-box-main">
                            <ul className="mega-menu-list-left">
                              <li>
                                <Link to="/dev-ops-services">
                                  <span>DevOps Services</span> <br />{" "}
                                  <span className="mega-menu-sub-text">
                                    Streamlining development and operations for
                                    faster delivery and enhanced collaboration.
                                  </span>
                                </Link>
                              </li>

                              <li>
                                <Link to="/quality-assurance-services">
                                  <span>Quality Assurance Services</span> <br />{" "}
                                  <span className="mega-menu-sub-text">
                                    Ensuring top-notch quality through rigorous
                                    testing and validation for flawless
                                    performance.
                                  </span>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="col-12 col-lg-3 col-md-12 col-xl-3">
                          <div className="space-top-box-main">
                            <ul className="mega-menu-list-left">
                              <li>
                                <Link to="/data-analytics-services">
                                  <span>Data Analytics</span> <br />{" "}
                                  <span className="mega-menu-sub-text">
                                    Transforming data into actionable insights
                                    to drive informed decision-making and
                                    growth.
                                  </span>
                                </Link>
                              </li>

                              <li>
                                <Link to="/blockchain-development-services">
                                  <span>BlockChain Development</span> <br />{" "}
                                  <span className="mega-menu-sub-text">
                                    Building secure and transparent
                                    decentralized solutions to revolutionize
                                    industries.
                                  </span>
                                </Link>
                              </li>

                              <li>
                                <Link to="/cyber-security-services">
                                  <span>Cyber Security</span> <br />{" "}
                                  <span className="mega-menu-sub-text">
                                    Protecting your digital assets with
                                    comprehensive security solutions against
                                    evolving threats.
                                  </span>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                {/* How we work Mega Menu */}


                <li className="nav-item">
                  <Link to="/portfolio" className="dropdown-toggle nav-link">
                    Portfolio
                  </Link>
                </li>

                <li
                  className="nav-item"
                  onMouseEnter={() => toggleDropdown("dropdown4")}
                  onMouseLeave={closeDropdown}
                >
                  <a
                    className="dropdown-toggle nav-link"
                    href="javascript:void(0)"
                    role="button"
                    aria-expanded={
                      activeDropdown === "dropdown4" ? "true" : "false"
                    }
                  >
                    <i className="fa-globe fa-solid"></i>{" "}
                    {currentCountry && currentCountry}{" "}
                    <i
                      className={`fa-solid fa-arrow-down ${activeDropdown === "dropdown4" ? "up-arrow" : ""
                        }`}
                    ></i>
                  </a>
                  <ul
                    className={`dropdown-menu ${activeDropdown === "dropdown4" ? "show" : ""
                      } country-dropdown`}
                  >
                    {allowedCountries.map((country) => (
                      <li key={country}>
                        <Link className="country-url" to={`/${country}`}>
                          <p className="my-0"> {country}</p>{" "}
                          <i class="fa-arrow-right fa-solid"></i>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>

                <li
                  className="nav-item"
                  onMouseEnter={() => toggleDropdown("dropdown3")}
                  onMouseLeave={closeDropdown}
                >
                  <a
                    className="dropdown-toggle nav-link hire-expert-button"
                    href="javascript:void(0)"
                    role="button"
                    aria-expanded={
                      activeDropdown === "dropdown3" ? "true" : "false"
                    }
                  >
                    Hire Experts{" "}
                    <i
                      className={`fa-solid fa-angle-down ${activeDropdown === "dropdown3" ? "up-arrow" : ""
                        }`}
                    ></i>
                  </a>

                  <div
                    className={`dropdown-menu mega-menu ${activeDropdown === "dropdown3" ? "show" : ""
                      }`}
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-12 col-lg-3 col-md-12 col-xl-3">
                          <ul className="mega-menu-list-left">
                            <h3 className="mega-header-heading">
                              Hire Our Experts
                            </h3>

                            <li>
                              <Link to="/hire-ruby-on-rails-developer">
                                <span>Hire Ruby Rails</span> <br />{" "}
                                <span className="mega-menu-sub-text">
                                  Expert Ruby on Rails developers ready to build
                                  scalable and efficient web applications for
                                  your business.
                                </span>
                              </Link>
                            </li>

                            <li>
                              <Link to="/hire-django-developer">
                                <span>Hire Django</span> <br />{" "}
                                <span className="mega-menu-sub-text">
                                  Skilled Django developers to create powerful,
                                  high-performance web applications tailored to
                                  your needs.
                                </span>
                              </Link>
                            </li>

                            <li>
                              <Link to="/hire-python-developer">
                                <span>Hire Python</span> <br />{" "}
                                <span className="mega-menu-sub-text">
                                  Talented Python developers available to
                                  deliver versatile and efficient solutions for
                                  your projects.
                                </span>
                              </Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-12 col-lg-3 col-md-12 col-xl-3">
                          <div className="space-top-box-main">
                            <ul className="mega-menu-list-left">
                              <li>
                                <Link to="/hire-golang-developer">
                                  <span>Hire Go Lang</span> <br />{" "}
                                  <span className="mega-menu-sub-text">
                                    Proficient Go developers ready to build
                                    high-performance applications with
                                    efficiency and speed.
                                  </span>
                                </Link>
                              </li>

                              <li>
                                <Link to="/hire-javascript-developer">
                                  <span>Hire JavaScript</span> <br />{" "}
                                  <span className="mega-menu-sub-text">
                                    Experienced JavaScript developers to create
                                    dynamic and interactive web applications
                                    tailored to your vision.
                                  </span>
                                </Link>
                              </li>

                              <li>
                                <Link to="/hire-react-developer">
                                  <span>Hire React</span> <br />{" "}
                                  <span className="mega-menu-sub-text">
                                    Skilled React developers available to craft
                                    responsive and high-performing user
                                    interfaces for your applications.
                                  </span>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="col-12 col-lg-3 col-md-12 col-xl-3">
                          <div className="space-top-box-main">
                            <ul className="mega-menu-list-left">
                              <li>
                                <Link to="/hire-flutter-developer">
                                  <span>Hire Flutter</span> <br />{" "}
                                  <span className="mega-menu-sub-text">
                                    Expert Flutter developers ready to build
                                    beautiful, natively compiled applications
                                    for mobile, web, and desktop from a single
                                    codebase.
                                  </span>
                                </Link>
                              </li>

                              <li>
                                <Link to="/hire-android-developer">
                                  <span>Hire Android</span> <br />{" "}
                                  <span className="mega-menu-sub-text">
                                    Experienced Android developers to create
                                    intuitive and robust mobile applications
                                    tailored for Android users.
                                  </span>
                                </Link>
                              </li>

                              <li>
                                <Link to="/hire-angular-developer">
                                  <span>Hire Angular</span> <br />{" "}
                                  <span className="mega-menu-sub-text">
                                    Proficient Angular developers dedicated to
                                    building dynamic, high-performance web
                                    applications with a seamless user
                                    experience.
                                  </span>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="col-12 col-lg-3 col-md-12 col-xl-3">
                          <div className="space-top-box-main">
                            <ul className="mega-menu-list-left">
                              <li>
                                <Link to="/hire-mern-stack-developer">
                                  <span>Hire Mern Stack</span> <br />{" "}
                                  <span className="mega-menu-sub-text">
                                    Skilled MERN Stack developers ready to build
                                    full-stack applications with MongoDB,
                                    Express.js, React, and Node.js for optimal
                                    performance.
                                  </span>
                                </Link>
                              </li>

                              <li>
                                <Link to="/hire-node-developer">
                                  <span>Hire Node</span> <br />{" "}
                                  <span className="mega-menu-sub-text">
                                    {" "}
                                    Experienced Node.js developers to create
                                    scalable and efficient server-side
                                    applications that power your business.
                                  </span>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="header-link-green">
                  <a
                    href="https://calendly.com/bitsclan-it/30min?month=2025-01"
                    target="_blank"
                    className="ms-0"
                    id="header-schedule-a-call-btn"
                  >
                    Schedule a Call
                  </a>
                </li>
              </ul>
            </div>
          </div>
        )}
      </nav>
    </>
  );
};

export default MegaHeader;

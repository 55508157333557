import React from 'react'
import './style.css'
import { Link } from 'react-router-dom'

const HirePageSteps = ({ heading, subHeading, steps }) => {
    return (
        <>
            <div className="hire-steps-box">
                <div className="container">
                    <div className="row">
                        <div class="col-md-12">
                            <h1 class="main-heading">{heading}</h1>
                            <h3 class="sub-heading">{subHeading}</h3>
                        </div>
                    </div>

                    <div className="row hire-page-setps-box">
                        {steps?.map((step, index) => {
                            return <div className="col-xl-3 col-lg-3 col-md-6 steps-here" key={index}>
                                <div className="hire-step-card">
                                    <div className="setps-icon">
                                        <img src={step.icon} alt="Icon" />
                                    </div>
                                    <h2>{step?.title}</h2>
                                    <p className='common-text'>{step?.description}</p>
                                </div>
                            </div>
                        })}
                    </div>

                    <div className="row mt-5">
                        <div className="col-md-12">
                            <div class="hire-us-cta-btn d-flex justify-content-center">
                                <Link id='hire-programmers-in-4-easy-step-click' to="/hire-contact" class="ms-0">Let’s Find Your Perfect Developer</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HirePageSteps
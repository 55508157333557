import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "./styles.css";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const SubmitEmailModal = ({ isOpen, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [modalWidth, setModalWidth] = useState("50%");

  useEffect(() => {
    const updateWidth = () => {
      if (window.innerWidth <= 768) {
        setModalWidth("90%");
      } else if (window.innerWidth <= 1024) {
        setModalWidth("50%");
      } else {
        setModalWidth("50%");
      }
    };

    updateWidth(); // Set initial value
    window.addEventListener("resize", updateWidth);

    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  const handleOnChange = (e) => {
    setEmail(e.target.value);
  };

  const sheetName = "EmailSubmissions";

  const state = {
    Email: email,
    SheetName: sheetName,
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    if (email === "") {
      toast.error("Please enter the email");
    } else {
      setIsLoading(true);
      const formData = new FormData();
      for (const key in state) {
        if (state.hasOwnProperty(key)) {
          const value = state[key];

          if (Array.isArray(value)) {
            for (let i = 0; i < value.length; i++) {
              formData.append(`${key}[${i}]`, value[i]);
            }
          } else {
            formData.append(key, value);
          }
        }
      }

      fetch(
        "https://script.google.com/macros/s/AKfycbxguWPWthfSy76Z2MK28LGAamDVa3RJ_omrgEFUfEaPULK9JCylYT1StWulNMXmtW71/exec",
        {
          method: "POST",
          body: formData,
          mode: "no-cors",
        }
      )
        .then((data) => {
          cookies.set("emailAccepted", "Yes", { path: "/" }); // Ensure cookie applies site-wide
          console.log(data);
          setIsLoading(false);

          onClose(); // Close the modal after submission
          setEmail("");
          toast.success("Success");
        })
        .catch((error) => console.log(error));
    }
  };

  const handleCloseByBtn = () => {
    cookies.set("emailAccepted", "Yes", { path: "/" }); // Ensure cookie applies site-wide
    onClose()
  }

  return (
    <Popup
      open={isOpen}
      position={"center center"}
      closeOnDocumentClick={false}
      lockScroll={true}
      repositionOnResize
      contentStyle={{
        width: modalWidth,
      }}
    >
      <div className="email-modal-body-wrapper">
        <div onClick={handleCloseByBtn} className="email-custom-btn-close">
          <i className="fa-solid fa-xmark"></i>
        </div>
        <h1 className="text-black text-center">
          We are offering a free consultation call
        </h1>
        <p className="text-black text-center">
          Get a free consultation call for your project or any technical
          challenge you're facing. Submit your email, and our top engineers will
          assist you!
        </p>

        <form
          className="row custom-email-modal-box justify-content-center"
          onSubmit={handleSubmit}
        >
          <div className="col-md-6">
            <div className="form-floating">
              <input
                type="email"
                placeholder="Email"
                className="form-control border-dark"
                id="fullName"
                onChange={handleOnChange}
                value={email}
                autocomplete="off"
              />
              <label htmlFor="fullName">
                Email <span style={{ color: "red" }}>*</span>
              </label>
            </div>
          </div>
          <div className="custom-email-modal-footer">
            <div className="contact-section-button">
              <button type="submit" className="btn btn-primary">
                {isLoading ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </Popup>
  );
};

export default SubmitEmailModal;

import React, { useEffect, useState, useRef } from 'react';
import './style.css';
import { Link } from 'react-router-dom';

const PageScroller = ({ content }) => {
    const [isSticky, setIsSticky] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const scrollerRef = useRef(null);
    const originalPosition = useRef(0);

    useEffect(() => {
        const calculateOriginalPosition = () => {
            if (scrollerRef.current) {
                originalPosition.current = scrollerRef.current.offsetTop;
            }
        };

        const handleScroll = () => {
            if (window.scrollY > originalPosition.current) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        // Calculate the original position on mount and resize
        calculateOriginalPosition();
        window.addEventListener('resize', calculateOriginalPosition);
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('resize', calculateOriginalPosition);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    const toggleDropdown = () => {
        setIsDropdownOpen((prevState) => !prevState);
    };

    const closeDropdown = () => {
        setIsDropdownOpen(false);
    };
    
    // const scrollToSection = (id) => {
    //     const section = document.getElementById(id);
    //     if (section) {
    //         section.scrollIntoView({ behavior: "smooth" });
    //     }
    // };

    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
            const scrollerHeight = scrollerRef.current?.offsetHeight || 0;
            const offsetTop = section.offsetTop - scrollerHeight;
            window.scrollTo({ top: offsetTop, behavior: "smooth" });
        }
    };

    return (
        <>
            <div
                ref={scrollerRef}
                className={`page-scroller-section ${isSticky ? 'sticky' : ''}`}
            >
                <div className="container">
                    <div className="row">
                        <div className='desktop-scroller'>
                        {content.map((data, i) => (
                            // <div className="col" key={i}>
                                <div className="scroller-link">
                                    <p to={data.link} onClick={() => scrollToSection(data.link)}>
                                        {data.text} <i className="fa-solid fa-arrow-down"></i>
                                    </p>
                                </div>
                            // </div>
                        ))}
                        </div>
                    </div>
                    <div className="mobile-scroller">
                        <span
                            className={`dropdown-toggle ${isDropdownOpen ? 'open' : ''}`}
                            onClick={toggleDropdown}
                        >
                            Go to Section{' '}
                            <i
                                className={`fa-solid fa-arrow-down ${isDropdownOpen ? 'rotate' : ''
                                    }`}
                            ></i>
                        </span>
                        <div
                            className={`scroller-dropdown ${isDropdownOpen ? 'show' : 'hide'
                                }`}
                        >
                            {content.map((data, i) => (
                                <div className="scroller-link" key={i}>
                                    <p to={data.link} onClick={() => {
                                        scrollToSection(data.link);
                                        closeDropdown()
                                    }}>
                                        {data.text}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PageScroller;

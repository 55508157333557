import tabData from "../data/industryData";
import React from "react";
import { Helmet } from "react-helmet";
import MegaHeader from "../components/MegaHeader/MegaHeader";
import SlidOnScrollBanner from "../components/slide-on-scroll-banner/SlidOnScrollBanner";
import Footer from "../components/footer/Footer";
import WhyUs from "../components/whyus/WhyUs";
import CoverTheStack from "../components/coverthestack/CoverTheStack";
import PioneeringChange from "../components/pioneeringChange/PioneeringChange";
import DevelopingSolution from "../components/developingsolution/DevelopingSolution";
import ContactSection from "../components/contactSection/ContactSection";
import HireForUs from "../components/hire-us-for/HireForUs";
import MainEnterpriseItServices from "../components/enterprise-it-services/MainEnterpriseItServices";
import TrendsInsights from "../components/trends-n-insights/TrendsInsights";
import PageScroller from "../components/page-scroller/PageScroller";
import Testimonial from "../components/testimonila/Testimonial";
import NewEngagementModels from "../components/new-engagment-modal";
import { HomeSectionsData } from "../data/homeBannerData";
import { HireUsData, scrollerData, slidesData, tabItems, tabs } from "../data/home-data";
import OurLocations from "../components/ourlocations/OurLocations";
import BrandImages from "../components/brandImages/BrandImages";

function NewHome() {

  return (
    <>
      <Helmet>
        <title>
          AI, Blockchain & Technology Services | Bitsclan IT Solutions
        </title>
        <meta
          name="description"
          content="Unlock innovation with Bitsclan. Expert software development, AI services, mobile app solutions, and blockchain technology tailored to your needs. Contact us now"
        />
      </Helmet>

      {/* <Header /> */}

      <MegaHeader />

      <SlidOnScrollBanner sectionsData={HomeSectionsData} />

      <PageScroller content={scrollerData} />

      {/* <HomeLogo /> */}

      <Testimonial heading="Best Development Company of the Year 2021"
        content="We received the Best Development Company of the Year award from Payoneer in 2021. We bring the same expertise, dedication, vision, and conviction to every service we provide."
      />

      <HireForUs
        HireUsData={HireUsData}
        heading="Hire us for"
        content={[
          "From advanced software development and IT consulting to blockchain, QA automation, and cybersecurity—we provide solutions that drive innovation.",
          "With Agile methodologies and Zero-Trust security, we ensure performance, scalability, and reliability.",
          "No matter the service you choose, we deliver exceptional quality and measurable impact.",
        ]}
      />

      <WhyUs
        slidesData={slidesData}
        heading="for Software Development & Consulting Services?"
        greenHeading="Why Us"
        content="Bitsclan is not your run-of-the-mill technology company. We are your full-scale tech partners. We take ownership of the project and deliver it to the finish line."
      />

      <CoverTheStack
        whereStart={"Frontend"}
        tabs={tabs}
        tabItems={tabItems}
        mainHeading={"Don't worry! We've got the tech stack you're looking for!"}
        secondSubHeading={
          "We have experts in almost all major and common tech stacks. Our team is rapidly expanding, covering more programming languages and expertise. You can be confident you'll get the experts you need when you work with us."
        }
      />

      <MainEnterpriseItServices />

      <NewEngagementModels />

      <PioneeringChange
        tabData={tabData}
        greenHeading={"Across Industries"}
        mainHeading={"Pioneering Change"}
        content={
          "Leading innovation and transforming sectors with cutting-edge solutions and insights."
        }
      />

      {/* <BrandImages /> */}

      <DevelopingSolution
        heading="Developing Solutions That Transcend Mere Digital Transformation"
        subheading="We deliver innovative solutions that surpass digital transformation, driving impactful and lasting success."
      />

      <TrendsInsights category="all" />

      <OurLocations />

      <ContactSection />

      <Footer />
    </>
  );
}

export default NewHome;

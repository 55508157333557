export const mainBannerImg = {
    backgroundImage: `url(/images/hire-page-images/hire-django.png)`,
    factBackgroundImage: `url(/images/hire-page-images/hire-django.svg)`
};

export const tabs = [
    "Framework",
    "Libraries",
    "Tools & Utilities",
    "Database",
    "Tools For PM"
];

export const tabItems = [
    { tab: "Framework", icon: "/images/django-tech.svg", name: "Django" },
    { tab: "Framework", icon: "/images/tech-stack-icons/Pyramid.svg", name: "Pyramid" },
    { tab: "Framework", icon: "/images/tech-stack-icons/Flask.svg", name: "Flask" },
    { tab: "Framework", icon: "/images/tech-stack-icons/bottle.svg", name: "Bottle" },

    { tab: "Libraries", icon: "/images/tech-stack-icons/fabric.svg", name: "Fabric" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/request.svg", name: "Requests" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/pillow.svg", name: "Pillow/PIL" },
    { tab: "Libraries", icon: "/images/database6.png", name: "wxPython" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/scrapy.svg", name: "Scrapy" },

    { tab: "Tools & Utilities", icon: "/images/tech-stack-icons/numpy.svg", name: "NumPy" },

    { tab: "Tools & Utilities", icon: "/images/database1.png", name: ".Net" },
    { tab: "Tools & Utilities", icon: "/images/database6.png", name: "Python" },
    { tab: "Tools & Utilities", icon: "/images/tech-stack-icons/ruby_on_rails.svg", name: "Ruby On Rails" },
    { tab: "Tools & Utilities", icon: "/images/tech-stack-icons/java.svg", name: "Java" },
    { tab: "Tools & Utilities", icon: "/images/database8.png", name: "PHP" },
    { tab: "Tools & Utilities", icon: "/images/django-tech.svg", name: "Django" },
    { tab: "Tools & Utilities", icon: "/images/tech-stack-icons/golang.svg", name: "Go" },
    { tab: "Tools & Utilities", icon: "/images/tech-stack-icons/c-sharp.svg", name: "C#" },
    { tab: "Tools & Utilities", icon: "/images/tech-stack-icons/kotlin.svg", name: "Kotlin" },
    { tab: "Tools & Utilities", icon: "/images/tech-stack-icons/scala.svg", name: "Scala" },
    { tab: "Tools & Utilities", icon: "/images/tech-stack-icons/rust.svg", name: "Rust" },

    { tab: "Database", icon: "/images/db5.png", name: "MySQL" },
    { tab: "Database", icon: "/images/tech-stack-icons/oracals.svg", name: "Oracle" },
    { tab: "Database", icon: "/images/db7.png", name: "SQLite" },
    { tab: "Database", icon: "/images/db6.png", name: "MS SQL" },
    { tab: "Database", icon: "/images/db3.png", name: "MongoDB" },
    { tab: "Database", icon: "/images/tech-stack-icons/redis.svg", name: "Redis" },
    { tab: "Database", icon: "/images/tech-stack-icons/postgres.svg", name: "Postgres" },

    { tab: "Tools For PM", icon: "/images/tech-stack-icons/jira.svg", name: "Jira" },
    { tab: "Tools For PM", icon: "/images/tech-stack-icons/trello.svg", name: "Trello" },
    { tab: "Tools For PM", icon: "/images/tech-stack-icons/slack-icon.png", name: "Slack" },
    { tab: "Tools For PM", icon: "/images/tech-stack-icons/zoom-icon.svg", name: "Zoom" },
    { tab: "Tools For PM", icon: "/images/tech-stack-icons/google-meet.webp", name: "Google Meet" },
];

export const techCombinations = [
    {
        title: "Django + PostgreSQL + React",
        description:
            "Django as a powerful backend framework, PostgreSQL for structured database management, and React for building dynamic front-end applications ensure a scalable and high-performance web solution.",
        icons: ["django.svg", "postgres.svg", "react.svg"],
    },
    {
        title: "Django + GraphQL",
        description:
            "GraphQL enables efficient data queries, Django provides a secure and scalable backend, and PostgreSQL ensures reliable data storage, making it an excellent choice for modern applications.",
        icons: ["django.svg", "graphql.svg", "postgres.svg"],
    },
    {
        title: "Django + Redux Toolkit",
        description:
            "Combining Django with Redux Toolkit enhances state management in front-end applications while maintaining a robust backend with Django and PostgreSQL.",
        icons: ["django.svg", "redux.svg", "postgres.svg"],
    },
    {
        title: "Django + Next.js",
        description:
            "Next.js enables server-side rendering (SSR) for better SEO and performance, paired with Django as the backend to create fast, scalable, and secure applications.",
        icons: ["django.svg", "next-js.svg", "postgres.svg"],
    },
];

export const bannerList = [
    { list: "Flexible Hiring Options" },
    { list: "Full-time roles" },
    { list: "Global teams" },
];

export const servicesData = [
    {
        title: "Django Consulting",
        description:
            " Need expert guidance for your Django project? Our skilled Django developers provide strategic planning and technical advice to help you optimize your project and achieve maximum efficiency.",
        icon: "/images/hire-page-icons/hire-django-icon1.svg",
    },
    {
        title: "Django web Development",
        description:
            " We specialize in building robust, feature-rich, and scalable web applications with Django. Whether you're launching a new project or upgrading an existing one, our developers ensure smooth performance and exceptional user experience.",
        icon: "/images/hire-page-icons/hire-django-icon2.svg",
    },
    {
        title: "Django API Development",
        description:
            " Our Django experts build secure and efficient APIs that enable seamless communication between applications and services. From internal APIs to third-party integrations, we've got you covered.",
        icon: "/images/hire-page-icons/hire-django-icon3.svg",
    },
    {
        title: "Django CMS and Portal Development",
        description:
            "Looking for a powerful CMS or web portal? Our Django developers build flexible, easy-to-manage solutions that are scalable and perfectly aligned with your business requirements.",
        icon: "/images/hire-page-icons/hire-django-icon4.svg",
    },
    {
        title: "Migration to Django",
        description:
            "Planning to migrate your existing system to Django? Our experts handle the transition smoothly, ensuring minimal downtime and maximum performance.",
        icon: "/images/hire-page-icons/hire-django-icon5.svg",
    },
    {
        title: "Integration with Existing Solutions",
        description:
            " Our Django developers specialize in integrating Django applications with other platforms, APIs, and third-party services, ensuring smooth functionality and enhanced performance.",
        icon: "/images/hire-page-icons/hire-django-icon6.svg",
    },
    {
        title: "Django eCommerce Solutions",
        description:
            " We build high-performance eCommerce platforms with Django, offering secure payment gateways, seamless user experience, and scalable architecture to drive customer engagement.",
        icon: "/images/hire-page-icons/hire-django-icon7.svg",
    },
    {
        title: "Django Support & Maintenance",
        description:
            " Ensure your Django application runs smoothly with our ongoing support and maintenance services. We handle regular updates, performance optimization, and bug fixes to keep your platform stable and secure.",
        icon: "/images/hire-page-icons/hire-django-icon8.svg",
    },
];

export const solutionsData = [
    {
        icon: "/images/hire-page-icons/chose-ror-section1.svg",
        title: "Access vetted talent",
        description:
            "Meet skilled Django developers who are carefully vetted for their technical expertise and English fluency. You can be sure that you’re working with top-notch professionals.",
    },
    {
        icon: "/images/hire-page-icons/chose-ror-section2.svg",
        title: "View matches in seconds",
        description:
            "No more sifting through endless resumes. Find the perfect Django developers for hire quickly and easily through our platform with just a few clicks.",
    },
    {
        icon: "/images/hire-page-icons/chose-ror-section3.svg",
        title: "Save with global hires",
        description:
            "Tap into a talent pool of 450,000 professionals across 190 countries. With our global hiring options, you can save up to 58% compared to traditional hiring methods, without compromising on quality.",
        noBorder: true,
    },
    {
        icon: "/images/hire-page-icons/chose-ror-section4.svg",
        title: "Get real human support",
        description:
            "Feel confident when you hire Django developers with our expert recruitment team's support. We're here to guide you every step of the way, ensuring you find the right talent for your project.",
    },
];

export const hirePageStacks = [
    {
        title: "Junior Django Developer",
        description: [
            "2 Years' experience",
            "Fresh perspectives & innovative ideas",
            "Dedicated to explore web development",
        ],
        linkText: "Connect to Hire",
        link: "#",
        borderClass: "border-1",
    },
    {
        title: "Senior Django Developer",
        description: [
            "2+ years' experience",
            "Leadership in complex projects",
            "Proven app deployment success",
        ],
        linkText: "Connect to Hire",
        link: "#",
        borderClass: "border-2",
    },
    {
        title: "Django Tech Lead",
        description: [
            "5+ years' experience",
            "Skilled with Jira, Asana, MS Teams, etc.",
            "Suitable when you Hire Web App Team",
        ],
        linkText: "Connect to Hire",
        link: "#",
        borderClass: "border-3",
    },
];

export const benefits = [
    {
        service: "Django CMS Development",
        description: "Get an upper hand on your web content, augment efficiency, and develop a shared environment with our Django CMS solutions."
    },
    {
        service: "Django Migrations",
        description: "Our Django engineers strategically migrate legacy content into modern systems in a secured, error-free, and robust way."
    },
    {
        service: "Advanced Django Programming",
        description: "Our experts have extensive experience in using Django tech stack for tangible results to reach your business vision."
    },
    {
        service: "Django Integration and Optimization",
        description: "Leverage the full-scale potential of Django integration and optimization functionalities for your app development needs."
    },
    {
        service: "Customized Django Solutions",
        description: "Our Django developers delve into ideation, reinvention, and scale development as per your enterprise's unique IT demands."
    },
    {
        service: "Django eCommerce App",
        description: "With using modern Django tools and technologies, we have a proven track record of developing scalable and secured eCommerce sites."
    }
];

export const slidesData = [
    {
        title: "Rapid Development",
        content:
            "Django’s built-in tools and features allow for faster development, reducing time-to-market.",
        backgroundClass: "bg-1",
    },
    {
        title: "Scalability",
        content:
            "Django is designed to handle high-traffic and complex applications, making it perfect for growing businesses",
        backgroundClass: "bg-2",
    },
    {
        title: "Security",
        content:
            "With built-in protection against common security threats like SQL injection and cross-site scripting, Django ensures secure applications",
        backgroundClass: "bg-3",
    },
    {
        title: "Versatility",
        content:
            "From simple websites to complex web apps, Django is flexible enough to handle a variety of development needs",
        backgroundClass: "bg-4",
    },
    {
        title: "Maintainability",
        content:
            'Its clean architecture and "Don’t Repeat Yourself" (DRY) principle make Django applications easy to manage and scale over time.',
        backgroundClass: "bg-1",
    },
];

export const steps = [
    {
        title: "Choose your Engagement Model",
        description: "Select the engagement model that works best for your project—whether it’s Full-Time, Part-Time, or Hourly. We offer flexible options to suit your needs.",
        icon: "/images/hire-page-setps-icons/icon-1.svg"
    },
    {
        title: "Screen & Select Django Developers",
        description: "Browse through top Django developers for hire and choose the best candidates for your project. You can review their profiles and select the right ones to move forward.",
        icon: "/images/hire-page-setps-icons/icon-2.svg"
    },
    {
        title: "Conduct One-on-One Interview",
        description: "Interview the shortlisted developers to assess their skills and expertise. Ask both theoretical and practical questions to ensure they’re the right fit for your team.",
        icon: "/images/hire-page-setps-icons/icon-3.svg"
    },
    {
        title: "Onboard Django Developers",
        description: "Once you’ve made your selection, the final Django developer will join your team within 24-48 hours after confirmation, ready to start working on your project.",
        icon: "/images/hire-page-setps-icons/icon-4.svg"
    }
];

export const facts = [
    {
        text: "<strong>40+</strong> expert Django developers are ready to bring your project to life.",
    },
    {
        text: "Our developers have an average of <strong>6+ years</strong> of experience in Django development services.",
    },
    { text: "Proficiency in secure web development with Django, ensuring the safety of your application" },
    {
        text: "Extensive experience in both front-end and back-end development.",
    },
    { text: "Skilled in building scalable, feature-rich web applications using Django." },
    { text: "Well-versed in integrating Django with various platforms and services." },
];

export const WhoWeServeData = [
    {
        icon: "/images/servie-icon/startups.svg",
        title: "Budget savings",
        description: "Assembling a local Django team is unavoidably expensive. By approaching Innowise. you bypass many hurdles and avoid redundantexpenses such as training fees. taxes. and software licenses.",
        link: "#",
        borderClass: "border-1",
    },
    {
        icon: "/images/servie-icon/companies.svg",
        title: "Software product companies",
        description: "When you hire ROR developers. you avoid the risks of budget overruns and missed deadlines. Innowise provides solid risk management plans and has an array of risk mitigation measures",
        link: "#",
        borderClass: "border-2",
    },
    {
        icon: "/images/servie-icon/industries.svg",
        title: "Enterprises from 30+ industries",
        description:
            "Innowise has experts with excellent development backgrounds. so you can rest assured that your project is in good hands. Pyrggyelgprneg",
        link: "#",
        borderClass: "border-3",
    },
];

export const hirignDetails = [
    {
        text: "Hourly Hiring",
        hours: "24 - 48",
        icon: "time.svg",
        bgColor: "#FDFDFF"
    },

    {
        text: "Full Time Hiring",
        hours: "48",
        icon: "full-time.svg",
        bgColor: "#FFFEFA"
    },

    {
        text: "Part Time Hiring",
        hours: "24 - 48",
        icon: "part-time.svg",
        bgColor: "#FDFFFA"
    },
]

export const hirePageDeliver = [
    {
        image: "/images/hire-page-images/card-1.png",
        title: "Complex SaaS systems",
        description: "Innowise provides its expertise in the area of building complex platforms for businesses to streamline workflows. The main applications we develop include HRM, CRM, ERP, accounting software, etc."
    },
    {
        image: "/images/hire-page-images/card-2.png", // Assumed path based on provided example
        title: "Complex SaaS systems",
        description: "Innowise provides its expertise in the area of building complex platforms for businesses to streamline workflows. The main applications we develop include HRM, CRM, ERP, accounting software, etc."
    },
    {
        image: "/images/hire-page-images/card-3.png", // Assumed path based on provided example
        title: "Complex SaaS systems",
        description: "Innowise provides its expertise in the area of building complex platforms for businesses to streamline workflows. The main applications we develop include HRM, CRM, ERP, accounting software, etc."
    },
];

export const faqData = [
    {
        id: "collapseOne",
        question: "Why should I hire Django developers from your team?",
        answer:
            "Our Django developers have a deep understanding of building secure web development with Django, and we specialize in creating scalable and high-performing web applications that are tailored to your business needs. With years of experience, our developers are skilled in both front-end and back-end development, ensuring your project is in safe hands.",
    },
    {
        id: "collapseTwo",
        question: "How do I get started if I want to hire Django developers?",
        answer:
            "Simply reach out to us with your project details, and we'll provide you with a clear plan. From there, you can pick the right Django developer for hire based on your needs. We help guide you through the entire hiring process, ensuring a smooth experience.",
    },
    {
        id: "collapseThree",
        question: "What types of Django development services do you offer?",
        answer:
            "We offer a range of Django development services, including full-stack web development, API development, content management systems (CMS), e-commerce solutions, and migration to Django. Whatever your project requires, we can build it using Django's powerful framework.",
    },
    {
        id: "fourth",
        question: "How do you ensure quality in your Django developers?",
        answer:
            "All of our Django developers for hire undergo a strict vetting process to ensure they are skilled, experienced, and ready to deliver top-quality results. We also focus on continuous training to keep our developers updated with the latest trends and best practices in Django development.",
    },
    {
        id: "fifth",
        question: "How quickly can I expect to see results after hiring a Django developer?",
        answer:
            "Depending on the project scope, we ensure that you start seeing results within a few days of onboarding your Django developer. Our process is efficient, and our team works fast to deliver results while maintaining high standards of quality.",
    },
    {
        id: "sixth",
        question: "What are your engagement models for hiring Django developers?",
        answer:
            "We offer three flexible engagement models: full-time, part-time, and hourly. Whether you're looking for long-term assistance or short-term support, we can tailor the engagement model to suit your needs, ensuring efficient and cost-effective development.",
    },
];

import React, { useState, useEffect } from "react";
import "./style.css";
import projectData from "../../data/PorjectData";
import ContentLoader from "react-content-loader";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay, Pagination } from "swiper/modules";

const PortFolioData = () => {
  // ALL STATES
  const [showAll, setShowAll] = useState(false);
  const [activeCategory, setActiveCategory] = useState("All");
  const [selectedProject, setSelectedProject] = useState(null);
  const [showDetailCard, setshowDetailCard] = useState(false);
  const [loading, setLoading] = useState(true);
  // ALL STATES

  // Extract unique categories
  const categories = [
    "All",
    ...new Set(projectData.map((item) => item.category)),
  ];
  const filteredProjects =
    activeCategory === "All"
      ? projectData
      : projectData.filter((item) => item.category === activeCategory);
  const visibleProjects = showAll
    ? filteredProjects
    : filteredProjects.slice(0, 6);

  // SCALTON LOADING ON DETAILS
  useEffect(() => {
    if (showDetailCard) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  }, [showDetailCard, selectedProject]);

  // SHOW DETAILS FUNCTIONS
  const showDetails = (id) => {
    setshowDetailCard(true);
    const project = visibleProjects.find((item) => item.id === id);
    setSelectedProject(project);
  };

  const handleGtmClick = (obj) => {
    // Push data to Google Tag Manager (GTM)
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "portfolio_project_card_click",
      project_portfolio_card_id: obj.id,
      project_portfolio_card_title: obj.title,
      project_portfolio_card_description: obj.description,
      project_portfolio_card_image: obj.image,
    });
  };

  return (
    <>
      <div className="portfolio-main-box">
        <div className="container">
          <div className="row" style={{ transition: "all 0.3s ease-in-out" }}>
            <div className="col-md-12">
              <h1 class="main-heading">
                Excellence in Transforming Your Ideas into Reality
              </h1>
              <h3 class="sub-heading">
                Whether it is software development and consulting services, QA
                testing services, cybersecurity, Blockchain, or digital
                branding, we have got you covered! No matter the service you
                choose, we deliver exceptional quality and measurable impact.
              </h3>
            </div>

            <div className="col-md-12">
              <div className="links-boxes mb-5">
                <ul className="links-boxes-lists">
                  {categories.map((category, index) => (
                    <li
                      id="portfolio-category-tab-title"
                      key={index}
                      className={`category-tab ${
                        activeCategory === category ? "active" : ""
                      }`}
                      onClick={() => setActiveCategory(category)}
                    >
                      {category}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* <div className={`${showDetailCard ? 'col-md-4' : 'row'}`} style={{transition: "all 0.3s ease-in-out"}}> */}

            {showDetailCard ? (
              <div
                className="col-lg-4 col-md-5 col-xl-4"
                style={{ transition: "all 0.3s ease-in-out" }}
              >
                {visibleProjects.map((card, index) => (
                  // <div className={`${showDetailCard ? 'col-md-12 mb-4' : "col-xl-4 col-lg-4 col-md-6 mb-4"}`} key={index}>
                  <div
                    className="col-lg-12 col-md-12 col-xl-12 mb-4"
                    key={index}
                  >
                    <div
                      className={`project-card-main ${
                        selectedProject.id === card.id
                          ? "active-detail-card"
                          : ""
                      }`}
                    >
                      <div className="project-image">
                        <img src={card.image} alt={card.title} />
                      </div>
                      <h2>{card.title}</h2>
                      <p className="common-text">
                        {card?.description?.split(" ").slice(0, 20).join(" ")}
                        {""}
                        ...
                      </p>
                      <div className="project-button">
                        <a
                          className="common-green-btn details-btn portfolio-project-card-title"
                          onClick={() => {
                            showDetails(card.id);
                            handleGtmClick(card);
                          }}
                        >
                          See Details{" "}
                          <i className="fa-arrow-right fa-solid"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                ))}

                {filteredProjects.length > 6 && (
                  <div className="mt-4 view-all-button">
                    <span onClick={() => setShowAll((prev) => !prev)}>
                      {showAll ? "Load Less" : "Load More"}
                    </span>
                  </div>
                )}
              </div>
            ) : (
              <>
                {visibleProjects.map((card, index) => (
                  <div
                    className="col-lg-4 col-md-6 col-xl-4 mb-4"
                    key={index}
                    style={{ transition: "all 0.3s ease-in-out" }}
                  >
                    <div className="project-card-main">
                      <div className="project-image">
                        <img src={card.image} alt={card.title} />
                      </div>
                      <h2>{card.title}</h2>
                      <p className="common-text">
                        {card?.description?.split(" ").slice(0, 20).join(" ")}
                        {""}
                        ...
                      </p>
                      <div className="project-button">
                        <a
                          className="common-green-btn details-btn portfolio-project-card-title"
                          onClick={() => {
                            showDetails(card.id);
                            handleGtmClick(card);
                          }}
                        >
                          See Details{" "}
                          <i className="fa-arrow-right fa-solid"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                ))}

                {filteredProjects.length > 6 && (
                  <div className="mt-4 view-all-button">
                    <span onClick={() => setShowAll((prev) => !prev)}>
                      {showAll ? "Load Less" : "Load More"}
                    </span>
                  </div>
                )}
              </>
            )}

            {showDetailCard && (
              <div
                className="col-lg-8 col-md-7 col-xl-8"
                style={{ transition: "all 0.3s ease-in-out" }}
              >
                <div className="project-details-box">
                  {loading ? (
                    // Skeleton loader
                    <div>
                      <ContentLoader
                        speed={1}
                        width={`100%`}
                        height={`100%`}
                        viewBox="0 0 800 400"
                        backgroundColor="#6969692a"
                        foregroundColor="#ffffff29"
                      >
                        <rect
                          x="0"
                          y="0"
                          rx="5"
                          ry="5"
                          width="800"
                          height="200"
                        />
                        <rect
                          x="0"
                          y="220"
                          rx="4"
                          ry="4"
                          width="400"
                          height="30"
                        />
                        <rect
                          x="0"
                          y="270"
                          rx="4"
                          ry="4"
                          width="700"
                          height="20"
                        />
                        <rect
                          x="0"
                          y="300"
                          rx="4"
                          ry="4"
                          width="650"
                          height="20"
                        />
                      </ContentLoader>
                    </div>
                  ) : (
                    // Actual content
                    <>
                      <div className="project-image-main">
                        <Swiper
                          slidesPerView={"1"}
                          spaceBetween={30}
                          navigation={false}
                          autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                          }}
                          pagination={{
                            clickable: true, // Makes dots clickable
                          }}
                          modules={[Navigation, Autoplay, Pagination]}
                          className="mySwiper project-detials-card"
                        >
                          <SwiperSlide>
                            <img
                              src={selectedProject?.image}
                              alt={`${selectedProject?.image}`}
                            />
                          </SwiperSlide>

                          <SwiperSlide>
                            <img
                              src={selectedProject?.image2}
                              alt={`${selectedProject?.image2}`}
                            />
                          </SwiperSlide>

                          <SwiperSlide>
                            <img
                              src={selectedProject?.image3}
                              alt={`${selectedProject?.image3}`}
                            />
                          </SwiperSlide>
                        </Swiper>

                        <span
                          onClick={() => setshowDetailCard(false)}
                          className="cross-detail-button"
                        >
                          <i className="fa-solid fa-xmark"></i>
                        </span>
                      </div>

                      <h2>{selectedProject?.title}</h2>
                      <p className="common-text">
                        {selectedProject?.description}
                      </p>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PortFolioData;

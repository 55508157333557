import React, { useRef, useState, useCallback } from 'react'
import blogsSlidesData from '../../data/blogsData';
import './style.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';

const TypeIndustries = () => {

    const sliderRef = useRef();

    const [isBeginning, setIsBeginning] = useState(true);
    const [isEnd, setIsEnd] = useState(false);

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);

    const handleSlideChange = (swiper) => {
        setIsBeginning(swiper.isBeginning);
        setIsEnd(swiper.isEnd);
    };


    // BLOG DATA ARRAY

    return (
        <>
            <div className="typetesing-box">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="main-heading">Latest Trends and Expert Insights</h1>
                            <h3 className="sub-heading">Discover expert insights on the latest industry developments, trends, and emerging technologies shaping the future.</h3>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-md-12 mb-3 mt-5">
                            <div className="arrow-developing-slider">
                                <img
                                    onClick={handlePrev}
                                    src="/images/arrow-right.png"
                                    className={`left-image ${isBeginning ? 'disabled' : ''}`}
                                    alt="Arrow"
                                    style={{ cursor: isBeginning ? 'not-allowed' : 'pointer' }}
                                />
                                <img
                                    onClick={handleNext}
                                    src="/images/arrow-right.png"
                                    className={`${isEnd ? 'disabled' : ''}`}
                                    alt="Arrow"
                                    style={{ cursor: isEnd ? 'not-allowed' : 'pointer' }}
                                />
                            </div>
                        </div>

                        <div className="col-md-12">
                            <Swiper
                                slidesPerView={3}
                                spaceBetween={30}
                                onSlideChange={handleSlideChange}
                                navigation={false}
                                modules={[Navigation]}
                                className="mySwiper"
                                ref={sliderRef}
                                breakpoints={{
                                    425: {
                                        slidesPerView: 1,
                                        spaceBetween: 10,
                                    },
                                    375: {
                                        slidesPerView: 1,
                                        spaceBetween: 10,
                                    },
                                    320: {
                                        slidesPerView: 1,
                                        spaceBetween: 10,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 10,
                                    },
                                    1280: {
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                    },
                                }}
                            >

                                {blogsSlidesData.map(slide => (
                                    <SwiperSlide key={slide.id}>
                                        <Link to={`/blog-details/${slide.id}`} style={{ textDecoration: 'none' }}>
                                            <div className="type-setting-card side-typing-card-box">
                                                <div className="type-card-image">
                                                    <img src={slide.imageSrc} alt="Industries" />
                                                </div>
                                                <div className="type-card-text">
                                                    <a>{slide.title}</a>
                                                    <div className='insights-box'>
                                                        <div className="insight-size-box">
                                                            <img src="/images/favicon.png" alt="Favicon" />
                                                        </div>
                                                        <span>{slide.author}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TypeIndustries
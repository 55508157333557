export const AiDevelopmentServicesData = [
  {
    id: "Tab1",
    label: "AI Product Development",
    content:
      "Artificial intelligence is revolutionizing industries, and our AI product development services help businesses harness its power to create innovative, intelligent solutions. We specialize in developing AI-powered products that enhance efficiency, automate complex processes, and deliver superior user experiences. Whether it's intelligent automation tools, predictive analytics solutions, or AI-driven applications, we ensure seamless integration of AI capabilities tailored to your unique needs. Our team works closely with clients from ideation to deployment, ensuring that each AI product is designed for scalability, reliability, and performance.",
    secondContent: "With expertise in machine learning, natural language processing, and deep learning, we build AI solutions that drive business growth. Whether you're looking to streamline customer interactions, optimize decision-making, or enhance operational efficiency, our AI-driven products empower businesses with cutting-edge technology. From AI-powered chatbots and recommendation engines to smart data processing systems, we transform your vision into high-performance AI solutions that deliver tangible results.",
    btnText: "Let's Discuss",
    btnLink: "#",
    imgs: [
      {
        img: "/images/services-icons/ai-development/langchain-color.png",
      },
      {
        img: "/images/services-icons/ai-development/open-cv.png",
      },
      {
        img: "/images/services-icons/ai-development/SpaCy_logo.png",
      },
      {
        img: "/images/services-icons/ai-development/TensorFlow_logo.png",
      },
    ],
  },
  {
    id: "Tab4",
    label: "Automation Solutions",
    content:
      "Automation is the key to unlocking greater efficiency and reducing operational costs in today's fast-paced digital world. Our AI-driven automation solutions help businesses streamline repetitive tasks, eliminate human errors, and optimize workflows. By leveraging robotic process automation (RPA), intelligent workflow management, and AI-powered decision-making, we enable companies to accelerate productivity and free up valuable resources. Our automation solutions are designed to integrate seamlessly with existing systems, ensuring minimal disruption while maximizing efficiency and accuracy.",
    secondContent: "From customer service chatbots to AI-powered document processing and smart workflow automation, our solutions drive business transformation. Whether you need automated data entry, fraud detection, or intelligent process automation, we develop AI-driven tools that enhance operational speed and precision. By implementing our automation solutions, businesses can improve service delivery, boost employee productivity, and stay ahead of the competition in an increasingly automated landscape.",
    btnText: "Let's Discuss",
    btnLink: "#",
    imgs: [
      {
        img: "/images/services-icons/ai-development/langchain-color.png",
      },
      {
        img: "/images/services-icons/ai-development/open-cv.png",
      },
      {
        img: "/images/services-icons/ai-development/SpaCy_logo.png",
      },
      {
        img: "/images/services-icons/ai-development/TensorFlow_logo.png",
      },
    ],
  },
  {
    id: "Tab5",
    label: "AI as a Service",
    content:
      "Not every business has the resources or expertise to develop AI models from scratch, which is why our AI as a Service (AIaaS) solutions make artificial intelligence accessible to all. We provide ready-to-use AI models and cloud-based AI services that businesses can integrate into their existing systems without the complexity of in-house development. Our AIaaS offerings include machine learning-based analytics, intelligent automation tools, and AI-powered customer engagement solutions, all designed to scale with your business needs.",
    secondContent: "With our AIaaS solutions, you can leverage the power of AI for predictive analytics, fraud detection, personalized recommendations, and more—without heavy upfront investment. Our expert team ensures smooth integration, allowing businesses to enhance decision-making, automate processes, and drive innovation effortlessly. Whether you're a startup looking to integrate AI into your product or an enterprise seeking advanced AI capabilities, our AIaaS solutions provide a cost-effective way to stay ahead in the AI revolution.",
    btnText: "Let's Discuss",
    btnLink: "#",
    imgs: [
      {
        img: "/images/services-icons/ai-development/langchain-color.png",
      },
      {
        img: "/images/services-icons/ai-development/open-cv.png",
      },
      {
        img: "/images/services-icons/ai-development/SpaCy_logo.png",
      },
      {
        img: "/images/services-icons/ai-development/TensorFlow_logo.png",
      },
    ],
  },
  {
    id: "Tab6",
    label: "AI Integration",
    content:
      "Seamlessly integrating AI into your existing systems can dramatically improve efficiency and decision-making. Our AI integration services help businesses leverage artificial intelligence to enhance their applications, workflows, and customer experiences. Whether you need AI-driven automation, predictive analytics, or intelligent recommendation engines, we ensure smooth AI adoption without disrupting your operations. Our team specializes in integrating AI capabilities into CRM systems, ERP platforms, and custom business applications to optimize performance and user engagement.",
    secondContent: "By embedding AI into your infrastructure, businesses can gain deeper insights, automate repetitive tasks, and create personalized user experiences. From chatbots that enhance customer support to AI-powered data processing that improves accuracy, our AI integration solutions drive innovation and efficiency. We ensure that AI aligns with your business objectives, enabling smarter decision-making and improved operational outcomes while maintaining security and scalability.",
    btnText: "Let's Discuss",
    btnLink: "#",
    imgs: [
      {
        img: "/images/services-icons/ai-development/langchain-color.png",
      },
      {
        img: "/images/services-icons/ai-development/open-cv.png",
      },
      {
        img: "/images/services-icons/ai-development/SpaCy_logo.png",
      },
      {
        img: "/images/services-icons/ai-development/TensorFlow_logo.png",
      },
    ],
  },
  {
    id: "Tab7",
    label: "Prompt Engineering",
    content:
      "Effective AI interactions depend on well-optimized prompts that ensure accurate and relevant responses. Our prompt engineering services refine and optimize AI model inputs to enhance the quality of responses in chatbots, automation tools, and generative AI applications. By fine-tuning prompts for large language models (LLMs) and AI assistants, we ensure that AI-powered systems understand context, reduce ambiguity, and provide meaningful, accurate answers.",
    secondContent: "Our expertise in prompt design helps businesses build AI-driven tools that improve customer interactions, automate content generation, and streamline workflow automation. Whether you're developing AI chatbots for customer support or using AI for intelligent content creation, our optimized prompts enhance response quality, ensuring AI systems communicate effectively. We ensure that AI interactions align with business goals, delivering intelligent and user-friendly AI experiences.",
    btnText: "Let's Discuss",
    btnLink: "#",
    imgs: [
      {
        img: "/images/services-icons/ai-development/langchain-color.png",
      },
      {
        img: "/images/services-icons/ai-development/open-cv.png",
      },
      {
        img: "/images/services-icons/ai-development/SpaCy_logo.png",
      },
      {
        img: "/images/services-icons/ai-development/TensorFlow_logo.png",
      },
    ],
  },
  {
    id: "Tab8",
    label: "Predictive Analytics Models",
    content:
      "In today’s data-driven world, businesses need accurate insights to stay competitive. Our predictive analytics models use AI and machine learning to analyze historical data, identify patterns, and generate forecasts that drive informed decision-making. Whether you're in finance, healthcare, retail, or any other industry, our AI-powered predictive analytics help optimize operations, improve risk assessment, and uncover growth opportunities. Our models are designed to process vast amounts of data in real-time, delivering accurate and actionable insights.",
    secondContent: "From demand forecasting and customer behavior analysis to fraud detection and market trend prediction, our predictive analytics solutions empower businesses to make proactive, data-backed decisions. By leveraging AI-driven forecasting, businesses can minimize risks, improve resource allocation, and gain a competitive edge. Our predictive models continuously learn and adapt, ensuring that your business stays ahead of market trends and customer demands.",
    btnText: "Let's Discuss",
    btnLink: "#",
    imgs: [
      {
        img: "/images/services-icons/ai-development/langchain-color.png",
      },
      {
        img: "/images/services-icons/ai-development/open-cv.png",
      },
      {
        img: "/images/services-icons/ai-development/SpaCy_logo.png",
      },
      {
        img: "/images/services-icons/ai-development/TensorFlow_logo.png",
      },
    ],
  },
  {
    id: "Tab10",
    label: "Natural language processing",
    content:
      "Natural language processing (NLP) enables machines to understand and interpret human language, revolutionizing how businesses interact with customers and process information. Our NLP solutions help businesses develop AI-powered chatbots, sentiment analysis tools, speech recognition systems, and automated content processing solutions. By leveraging advanced NLP techniques, we enable businesses to extract insights from text and voice data, automate customer interactions, and improve overall communication efficiency.",
    secondContent: "Our NLP models power intelligent search engines, virtual assistants, and automated document analysis tools that enhance business operations. Whether you need an AI-powered chatbot for customer support or sentiment analysis for brand monitoring, our NLP solutions ensure accurate, context-aware processing of human language. By integrating NLP into your business, you can improve customer engagement, optimize workflows, and enhance decision-making with AI-powered language understanding.",
    btnText: "Let's Discuss",
    btnLink: "#",
    imgs: [
      {
        img: "/images/services-icons/ai-development/langchain-color.png",
      },
      {
        img: "/images/services-icons/ai-development/open-cv.png",
      },
      {
        img: "/images/services-icons/ai-development/SpaCy_logo.png",
      },
      {
        img: "/images/services-icons/ai-development/TensorFlow_logo.png",
      },
    ],
  },
  {
    id: "Tab11",
    label: "Computer vision",
    content:
      "Computer vision technology enables machines to interpret and analyze visual data, opening new possibilities in automation, security, and business intelligence. Our AI-powered computer vision solutions include image recognition, object detection, facial recognition, and automated visual inspection systems. Businesses across industries use computer vision to enhance security, improve quality control, and automate data extraction from images and videos.",
    secondContent: "From smart surveillance systems to AI-powered quality assurance in manufacturing, our computer vision solutions enhance operational efficiency and accuracy. We develop AI-driven image processing systems for healthcare, retail, and industrial applications, enabling businesses to analyze and act on visual information in real time. Whether you need automated image classification, medical imaging analysis, or smart inventory tracking, our cutting-edge computer vision technology helps businesses optimize their processes.",
    btnText: "Let's Discuss",
    btnLink: "#",
    imgs: [
      {
        img: "/images/services-icons/ai-development/langchain-color.png",
      },
      {
        img: "/images/services-icons/ai-development/open-cv.png",
      },
      {
        img: "/images/services-icons/ai-development/SpaCy_logo.png",
      },
      {
        img: "/images/services-icons/ai-development/TensorFlow_logo.png",
      },
    ],
  },
  {
    id: "Tab12",
    label: "Deep learning",
    content:
      "Deep learning is at the forefront of AI innovation, driving advanced automation, predictive modeling, and intelligent decision-making. Our deep learning solutions leverage neural networks to process large datasets, recognize patterns, and make accurate predictions. Businesses use deep learning for applications such as speech recognition, fraud detection, self-learning AI systems, and recommendation engines. Our expertise in deep learning ensures businesses harness its power to optimize operations and enhance customer experiences.",
    secondContent: "With deep learning models, businesses can achieve superior accuracy in AI applications, from personalized content recommendations to autonomous systems. Whether you're looking to implement AI-powered diagnostics in healthcare, fraud prevention in finance, or intelligent automation in retail, our deep learning solutions provide unparalleled performance. By integrating deep learning into your business, you gain access to powerful AI capabilities that drive innovation and efficiency.",
    btnText: "Let's Discuss",
    btnLink: "#",
    imgs: [
      {
        img: "/images/services-icons/ai-development/langchain-color.png",
      },
      {
        img: "/images/services-icons/ai-development/open-cv.png",
      },
      {
        img: "/images/services-icons/ai-development/SpaCy_logo.png",
      },
      {
        img: "/images/services-icons/ai-development/TensorFlow_logo.png",
      },
    ],
  },
  {
    id: "Tab13",
    label: "Machine learning",
    content:
      "Machine learning transforms raw data into intelligent insights, helping businesses automate processes and make data-driven decisions. Our custom machine learning models analyze complex datasets to identify trends, detect anomalies, and optimize business operations. Whether you're building AI-driven recommendation engines, automated fraud detection systems, or predictive maintenance tools, our ML solutions provide powerful, scalable intelligence tailored to your needs.",
    secondContent: "By implementing machine learning, businesses can improve efficiency, enhance personalization, and drive automation. Our expertise spans supervised, unsupervised, and reinforcement learning techniques, ensuring businesses leverage AI for maximum impact. Whether you need customer segmentation, demand forecasting, or intelligent data processing, our machine learning solutions turn data into valuable business intelligence.",
    btnText: "Let's Discuss",
    btnLink: "#",
    imgs: [
      {
        img: "/images/services-icons/ai-development/langchain-color.png",
      },
      {
        img: "/images/services-icons/ai-development/open-cv.png",
      },
      {
        img: "/images/services-icons/ai-development/SpaCy_logo.png",
      },
      {
        img: "/images/services-icons/ai-development/TensorFlow_logo.png",
      },
    ],
  },
];

export const AIDevPageSolutionsData = [
  {
    title: "Machine Learning",
    description:
      "Build intelligent systems with machine learning solutions that learn from data. Applications include recommendation systems, predictive maintenance, and fraud detection.",
  },
  {
    title: "Deep Learning",
    description:
      "Leverage deep learning to analyze complex data. From image and speech recognition to autonomous systems, our AI solutions transform how data drives decisions.",
  },
  {
    title: "Computer Vision",
    description:
      "Empower machines to interpret visuals with AI development services. Key uses include facial recognition, object detection, medical imaging, and inspections.",
    noBorder: true,
  },
  {
    title: "Natural Language Processing (NLP)",
    description:
      "Enhance interactions with NLP tools for chatbots, sentiment analysis, voice recognition, and language translation, creating seamless human-AI communication.",
  },
  {
    title: "Robotic Process Automation (RPA)",
    description:
      "Automate repetitive tasks with RPA solutions. Boost efficiency, reduce costs, and eliminate human errors to focus on strategic goals with smarter workflows.",
  },
  {
    title: "Convolutional Neural Networks (CNNs)",
    description:
      "Use CNNs for visual data processing. Ideal for image and video recognition, medical imaging, and creating powerful AI solutions for advanced visual analysis.",
    noBorder: true,
  },
];

export const AiDevPageFaqData = [
  {
    id: "collapseOne",
    question: "What are AI development services?",
    answer:
      "AI development services refer to any services that utilize artificial intelligence to develop new solutions or modify existing ones with AI integration for enhanced operations, reduced errors, better productivity, increased agility, and scaled revenue.",
  },
  {
    id: "collapseTwo",
    question: "How much does an AI service cost?",
    answer:
      "The cost of the AI services you require depends upon factors like the scope of the project, the technology involved, etc. Typically, an AI service can range anywhere between $5000 to $300,000. As a top-rated AI consulting and development company, Bitsclan IT Solutions offers free of cost AI consultation.",
  },
  {
    id: "collapseThree",
    question: "How long does it take to build an AI product?",
    answer:
      "There is no fixed time for AI product development. The complexity of the product will decide the time required to complete it. A highly complex product with advanced features may take up to 10 to 20 months. On the other hand, a simple product with minimal AI features can take up to 3 to 6 months.",
  },
  {
    id: "fourth",
    question:
      "How can integrating artificial intelligence benefit my business?",
    answer:
      "By integrating AI, decision-making procedures can be improved, repetitive jobs can be automated, and data-driven insights can be obtained. AI can also be used to forecast trends, optimize resource allocation, and personalize client experiences.",
  },
  {
    id: "fifth",
    question: "What is the process of outsourcing AI development services?",
    answer:
      "Outsourcing AI development involves partnering with a specialized AI company to handle your AI projects. The process typically includes initial consultation, requirement gathering, project planning, development, testing, implementation, and ongoing support.",
  },
  {
    id: "sixth",
    question: "Which industries can benefit from AI development services?",
    answer:
      "Numerous industries, including healthcare, finance, retail, manufacturing, logistics, real estate, legal, education, media, agriculture, gaming, and energy, can profit from AI development services.",
  },
  {
    id: "seventh",
    question:
      "Can AI solutions be integrated with my existing systems and workflows?",
    answer:
      "Yes, AI solutions can be seamlessly integrated with your existing systems and workflows. Our experts ensure that the AI technology complements your current infrastructure, enhancing its capabilities without disrupting operations.",
  },
  {
    id: "eighteeth",
    question: "How do you ensure data privacy and security in AI projects?",
    answer:
      "We prioritize data privacy and security in all our AI projects. We implement robust security measures, comply with industry standards, and use encryption and anonymization techniques to protect your data throughout the project lifecycle.",
  },
];

export const tabs = [
    "Framework",
    "Package",
    "Database",
    "Cloud Services",
    "Tools",
    "Communication",
];

export const tabItems = [
    { tab: "Framework", icon: "/images/tech-stack-icons/gin-cae.svg", name: "Gin" },
    { tab: "Framework", icon: "/images/tech-stack-icons/echo-icon.svg", name: "Echo" },
    { tab: "Framework", icon: "/images/tech-stack-icons/beego-icon.svg", name: "Beego" },
    { tab: "Framework", icon: "/images/tech-stack-icons/revel-icon.png", name: "Revel" },
    { tab: "Framework", icon: "/images/tech-stack-icons/buffalo-icon.svg", name: "Buffalo" },
    { tab: "Framework", icon: "/images/tech-stack-icons/goa-icon.svg", name: "Goa" },
    { tab: "Framework", icon: "/images/tech-stack-icons/fiber.svg", name: "Fiber" },
    { tab: "Framework", icon: "/images/tech-stack-icons/chi-icon.svg", name: "Chi" },
    { tab: "Framework", icon: "/images/tech-stack-icons/image-icon.svg", name: "Google Protobuf" },

    { tab: "Package", icon: "/images/tech-stack-icons/chi-icon.svg", name: "Chi" },
    { tab: "Package", icon: "/images/tech-stack-icons/stringer-icon.svg", name: "Stringer" },
    { tab: "Package", icon: "/images/tech-stack-icons/gorilla-icon.svg", name: "Gorilla Mux" },
    { tab: "Package", icon: "/images/tech-stack-icons/gorm-icon.svg", name: "GORM" },

    { tab: "Database", icon: "/images/db5.png", name: "MySQL" },
    { tab: "Database", icon: "/images/db3.png", name: "MongoDB" },
    { tab: "Database", icon: "/images/tech-stack-icons/redis.svg", name: "Redis" },
    { tab: "Database", icon: "/images/tech-stack-icons/postgres.svg", name: "Postgres" },
    { tab: "Database", icon: "/images/tech-stack-icons/elasticsearch.svg", name: "ElasticSearch" },

    { tab: "Cloud Services", icon: "/images/devops3.png", name: "Docker" },
    { tab: "Cloud Services", icon: "/images/cloud3.png", name: "AWS" },
    { tab: "Cloud Services", icon: "/images/cloud2.png", name: "Azure" },
    { tab: "Cloud Services", icon: "/images/cloud1.png", name: "Google Cloud" },
    { tab: "Cloud Services", icon: "/images/tech-stack-icons/digital-ocean.svg", name: "Digital Ocean" },

    { tab: "Tools", icon: "/images/tech-stack-icons/postman.svg", name: "Postman" },
    { tab: "Tools", icon: "/images/tech-stack-icons/postgres.svg", name: "PG Admin" },
    { tab: "Tools", icon: "/images/tech-stack-icons/git-hub.svg", name: "Git" },
    { tab: "Tools", icon: "/images/tech-stack-icons/gitlab.svg", name: "gitlab" },

    { tab: "Communication", icon: "/images/tech-stack-icons/rest-apis.svg", name: "Rest APIs" },
    { tab: "Communication", icon: "/images/tech-stack-icons/grpc.svg", name: "gRPC" },
    { tab: "Communication", icon: "/images/tech-stack-icons/sockets.svg", name: "Web Sockets" },
    { tab: "Communication", icon: "/images/tech-stack-icons/graph.svg", name: "GraphQl" },
    { tab: "Communication", icon: "/images/tech-stack-icons/apache.svg", name: "Apache" },
    { tab: "Communication", icon: "/images/tech-stack-icons/kafka.svg", name: "Kafka" },
    { tab: "Communication", icon: "/images/tech-stack-icons/rabbit.svg", name: "RabbitMQ" },
];

export const techCombinations = [
    {
        title: "Go + Gin + PostgreSQL",
        description:
            "Gin, a lightweight Go web framework, combined with PostgreSQL, provides a high-performance and scalable backend solution.",
        icons: ["golang.svg", "gin-cae.svg", "postgres.svg"],
    },
    {
        title: "Go + Echo + MySQL",
        description:
            "Echo, a fast and minimalistic Go web framework, paired with MySQL, enables efficient development of scalable applications.",
        icons: ["golang.svg", "echo-icon.svg", "mysql-whale-icon.svg"],
    },
    {
        title: "Go + Fiber + MongoDB",
        description:
            "Fiber, an Express-inspired Go framework, combined with MongoDB, ensures high-speed API development with flexible NoSQL storage.",
        icons: ["golang.svg", "fiber.svg", "mongodb.svg"],
    },
    {
        title: "Go + React + PostgreSQL",
        description:
            "React for dynamic front-end development, Go for efficient backend logic, and PostgreSQL for a reliable relational database.",
        icons: ["golang.svg", "react.svg", "postgres.svg"],
    },
    {
        title: "Go + gRPC + Kubernetes",
        description:
            "Go with gRPC enables efficient microservices communication, while Kubernetes provides scalable container orchestration.",
        icons: ["golang.svg", "grpc.svg", "kubernetes.svg"],
    },
    {
        title: "Go + GraphQL + AWS",
        description:
            "Go for backend logic, GraphQL for flexible API queries, and AWS for scalable cloud computing and storage solutions.",
        icons: ["golang.svg", "graphql.svg", "aws.svg"],
    },
];

export const mainBannerImg = {
    backgroundImage: `url(/images/hire-page-images/Golang.png)`,
    factBackgroundImage: `url(/images/hire-page-images/hire-golang.svg)`
};

export const bannerList = [
    { list: "Freelance contractors" },
    { list: "Full-time roles" },
    { list: "Global teams" },
];

export const servicesData = [
    {
        title: "Golang Consulting",
        description:
            "Get expert advice and strategic guidance to make the most of your Golang projects. Our developers will help you plan and implement your ideas to ensure success.",
        icon: "/images/hire-page-icons/go-lang-1.svg",
    },
    {
        title: "Golang web Development",
        description:
            "Our developers are skilled at building scalable and feature-rich web applications using Golang. Whether you're building a new platform or enhancing an existing one, we provide robust and efficient solutions.",
        icon: "/images/hire-page-icons/go-lang-2.svg",
    },
    {
        title: "Golang API Development",
        description:
            "Our developers excel at Golang API development, creating seamless communication between applications. This allows you to connect your systems effortlessly and ensure smooth data transfer.",
        icon: "/images/hire-page-icons/go-lang-3.svg",
    },
    {
        title: "CMS and Portal Development",
        description:
            "Trust our experts to build content management systems and web portals that are powerful, secure, and easy to use, all powered by Golang. We create user-friendly, high-performance platforms to meet your business needs.",
        icon: "/images/hire-page-icons/go-lang-4.svg",
    },
    {
        title: "Migration to Golang",
        description:
            "Our Golang developers make the transition to Golang application development easy and smooth. We can help you migrate your existing systems to Golang without disrupting your operations.",
        icon: "/images/hire-page-icons/go-lang-5.svg",
    },
    {
        title: "Integration with Existing Solutions",
        description:
            "Our team has extensive experience in integrating Golang applications with your existing platforms and services. Whether it's for databases, third-party tools, or other systems, we ensure seamless integration.",
        icon: "/images/hire-page-icons/go-lang-6.svg",
    },
    {
        title: "Golang eCommerce Solutions",
        description:
            "Developing eCommerce solutions with Golang ensures a fast, secure, and highly interactive user experience. Our experts build reliable and scalable platforms that deliver optimal performance for online stores.",
        icon: "/images/hire-page-icons/go-lang-7.svg",
    },
    {
        title: "Golang Support & Maintenance",
        description:
            "We provide ongoing support and maintenance services for your Golang applications, ensuring they stay efficient, secure, and up-to-date. Our team is dedicated to keeping your systems running smoothly.",
        icon: "/images/hire-page-icons/go-lang-8.svg",
    },
];

export const solutionsData = [
    {
        icon: "/images/hire-page-icons/chose-ror-section1.svg",
        title: "Access vetted talent",
        description:
            "When you hire Golang developers from us, you're getting experts who are thoroughly vetted for both their technical skills and English proficiency.",
    },
    {
        icon: "/images/hire-page-icons/chose-ror-section2.svg",
        title: "View matches in seconds",
        description:
            "No need to sift through piles of resumes. With HireAI, you can instantly view and select the best Golang developers for hire who match your project requirements.",
    },
    {
        icon: "/images/hire-page-icons/chose-ror-section3.svg",
        title: "Save with global hires",
        description:
            "Access a global pool of over 450,000 developers from 190 countries. By hiring from a global talent pool, you can save up to 58% compared to traditional hiring methods, while still getting top-tier expertise in Golang application development.",
        noBorder: true,
    },
    {
        icon: "/images/hire-page-icons/chose-ror-section4.svg",
        title: "Get real human support",
        description:
            "At Bitsclan, you're not just hiring developers; you're getting the support of our team of expert recruiters who are dedicated to helping you find the right talent. Feel confident knowing you'll have real, hands-on assistance throughout the process.",
    },
];

export const hirePageStacks = [
    {
        title: "Junior Golang Developer",
        description: [
            "2 Years' experience",
            "Fresh perspectives & innovative ideas",
            "Dedicated to explore web development",
        ],
        linkText: "Connect to Hire",
        link: "#",
        borderClass: "border-1",
    },
    {
        title: "Senior Golang Developer",
        description: [
            "2+ years' experience",
            "Leadership in complex projects",
            "Proven app deployment success",
        ],
        linkText: "Connect to Hire",
        link: "#",
        borderClass: "border-2",
    },
    {
        title: "Golang Tech Lead",
        description: [
            "5+ years' experience",
            "Skilled with Jira, Asana, MS Teams, etc.",
            "Suitable when you Hire Web App Team",
        ],
        linkText: "Connect to Hire",
        link: "#",
        borderClass: "border-3",
    },
];

export const benefits = [
    {
        service: "Golang CMS Development",
        description: "Get an upper hand on your web content, augment efficiency, and develop a shared environment with our Golang CMS solutions."
    },
    {
        service: "Golang Migrations",
        description: "Our Golang engineers strategically migrate legacy content into modern systems in a secured, error-free, and robust way."
    },
    {
        service: "Advanced Golang Programming",
        description: "Our experts have extensive experience in using Golang tech stack for tangible results to reach your business vision."
    },
    {
        service: "Golang Integration and Optimization",
        description: "Leverage the full-scale potential of Golang integration and optimization functionalities for your app development needs."
    },
    {
        service: "Customized Golang Solutions",
        description: "Our Golang developers delve into ideation, reinvention, and scale development as per your enterprise's unique IT demands."
    },
    {
        service: "Golang eCommerce App",
        description: "With using modern Golang tools and technologies, we have a proven track record of developing scalable and secured eCommerce sites."
    }
];

export const slidesData = [
    {
        title: "Rapid Development",
        content:
            "Golang’s built-in tools and features allow for faster development, reducing time-to-market.",
        backgroundClass: "bg-1",
    },
    {
        title: "Scalability",
        content:
            "Golang is designed to handle high-traffic and complex applications, making it perfect for growing businesses",
        backgroundClass: "bg-2",
    },
    {
        title: "Security",
        content:
            "With built-in protection against common security threats like SQL injection and cross-site scripting, Golang ensures secure applications",
        backgroundClass: "bg-3",
    },
    {
        title: "Versatility",
        content:
            "From simple websites to complex web apps, Golang is flexible enough to handle a variety of development needs",
        backgroundClass: "bg-4",
    },
    {
        title: "Maintainability",
        content:
            'Its clean architecture and "Don’t Repeat Yourself" (DRY) principle make Golang applications easy to manage and scale over time.',
        backgroundClass: "bg-1",
    },
];

export const steps = [
    {
        title: "Choose your Engagement Model",
        description: "Select the engagement model that suits your needs best — whether it’s Full-Time, Part-Time, or Hourly. This flexibility ensures you get the support you need based on your project requirements.",
        icon: "/images/hire-page-setps-icons/icon-1.svg"
    },
    {
        title: "Screen & Select Golang Developers",
        description: "Browse through profiles of Go developers for hire and pick the candidates who best match your project needs. Choose based on skills, experience, and expertise.",
        icon: "/images/hire-page-setps-icons/icon-2.svg"
    },
    {
        title: "Conduct One-on-One Interview",
        description: "Interview the shortlisted candidates to assess their skills. You can test them with theoretical and practical questions to ensure they are the right fit.",
        icon: "/images/hire-page-setps-icons/icon-3.svg"
    },
    {
        title: "Onboard Golang Developers",
        description: "Once you've made your final choice, the selected Golang developer will join your team within 24-48 hours. This ensures a smooth transition and quick project progress.",
        icon: "/images/hire-page-setps-icons/icon-4.svg"
    }
];

export const facts = [
    {
        text: "We have a team of over 40 experienced Golang developers.",
    },
    {
        text: "The average experience of our developers is 25.98 years.",
    },
    { text: "Our developers have extensive experience in Golang application development." },
    {
        text: "We rely on a mature DevOps culture for smooth workflows.",
    },
    { text: "We specialize in scalable solutions with Golang and cloud-native development." },
    { text: "Our team has expertise in big data, IoT, machine learning, and blockchain technology." },
];

export const WhoWeServeData = [
    {
        icon: "/images/servie-icon/startups.svg",
        title: "Budget savings",
        description: "Assembling a local Golang team is unavoidably expensive. By approaching Innowise. you bypass many hurdles and avoid redundantexpenses such as training fees. taxes. and software licenses.",
        link: "#",
        borderClass: "border-1",
    },
    {
        icon: "/images/servie-icon/companies.svg",
        title: "Software product companies",
        description: "When you hire ROR developers. you avoid the risks of budget overruns and missed deadlines. Innowise provides solid risk management plans and has an array of risk mitigation measures",
        link: "#",
        borderClass: "border-2",
    },
    {
        icon: "/images/servie-icon/industries.svg",
        title: "Enterprises from 30+ industries",
        description:
            "Innowise has experts with excellent development backgrounds. so you can rest assured that your project is in good hands. Pyrggyelgprneg",
        link: "#",
        borderClass: "border-3",
    },
];

export const hirignDetails = [
    {
        text: "Hourly Hiring",
        hours: "48",
        icon: "time.svg",
        bgColor: "#FDFDFF"
    },

    {
        text: "Full Time Hiring",
        hours: "72",
        icon: "full-time.svg",
        bgColor: "#FFFEFA"
    },

    {
        text: "Part Time Hiring",
        hours: "48",
        icon: "part-time.svg",
        bgColor: "#FDFFFA"
    },
]

export const hirePageDeliver = [
    {
        image: "/images/hire-page-images/card-1.png",
        title: "Complex SaaS systems",
        description: "Innowise provides its expertise in the area of building complex platforms for businesses to streamline workflows. The main applications we develop include HRM, CRM, ERP, accounting software, etc."
    },
    {
        image: "/images/hire-page-images/card-2.png", // Assumed path based on provided example
        title: "Complex SaaS systems",
        description: "Innowise provides its expertise in the area of building complex platforms for businesses to streamline workflows. The main applications we develop include HRM, CRM, ERP, accounting software, etc."
    },
    {
        image: "/images/hire-page-images/card-3.png", // Assumed path based on provided example
        title: "Complex SaaS systems",
        description: "Innowise provides its expertise in the area of building complex platforms for businesses to streamline workflows. The main applications we develop include HRM, CRM, ERP, accounting software, etc."
    },
];

export const faqData = [
    {
        id: "collapseOne",
        question: "Why should I hire Golang developers from Bitsclan?",
        answer:
            "At Bitsclan, we specialize in providing high-quality Golang development services. Our team is skilled in building scalable, secure, and high-performance applications that meet your business needs.",
    },
    {
        id: "collapseTwo",
        question: "How long does it take to onboard a Golang developer?",
        answer:
            "Once you've selected the developer, they will join your team within 24-48 hours. We ensure a smooth and fast onboarding process to get your project moving quickly.",
    },
    {
        id: "collapseThree",
        question: "What is the experience level of your Golang developers?",
        answer:
            "Our Golang developers have an average experience of over 25 years, ensuring top-tier expertise in Golang application development.",
    },
    {
        id: "fourth",
        question: "Can you help migrate my existing systems to Golang?",
        answer:
            "Yes, our experts are well-versed in seamlessly migrating existing systems to Golang, ensuring minimal downtime and a smooth transition.",
    },
    {
        id: "fifth",
        question: "What types of projects can you handle with Golang?",
        answer:
            "We offer Golang development services for a wide range of projects, including web applications, APIs, CMS systems, eCommerce platforms, and more.",
    },
    {
        id: "sixth",
        question: "How do I select the best Golang developer for my project?",
        answer:
            "You can review profiles of Go developers for hire, conduct interviews, and assess their skills based on your project needs. Our process ensures that you find the best match for your requirements.",
    },
];

import React from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import './style.css'
import blogsSlidesData from '../../data/blogsData'
import { Link } from 'react-router-dom'

const BlogSection = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const selectedCategory = queryParams.get("category");

    const handleCategoryClick = (category) => {
        queryParams.set("category", category);
        navigate(`?${queryParams.toString()}`);
    };

    // const filteredBlogs = selectedCategory
    //     ? blogsSlidesData.filter(blog => blog.category === selectedCategory)
    //     : blogsSlidesData;

    const filteredBlogs = !selectedCategory || selectedCategory === "all"
        ? blogsSlidesData
        : blogsSlidesData.filter(blog => blog.category === selectedCategory);



    return (
        <>
            <div className="trends-insights blog-section-main">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="main-heading">Latest Trends and Expert Insights</h1>
                            <h3 className="sub-heading">
                                Discover expert insights on the latest industry developments, trends, and emerging technologies shaping the future.
                            </h3>
                        </div>
                    </div>

                    <div className="row mt-5">
                        {/* Sidebar */}
                        <div className="col-md-3">
                            <ul className="blog-sidebar-box">
                                <li
                                    className={!selectedCategory || selectedCategory === "all" ? "active-category" : ""}
                                    onClick={() => handleCategoryClick("all")}
                                    style={{ cursor: "pointer" }}
                                >
                                    All
                                </li>
                                {[
                                    { category: "web-developmet", label: "Web Application Development" },
                                    { category: "ai-developmet", label: "AI Development" },
                                    { category: "custom-developmet", label: "Custom Development" },
                                    { category: "mobile-app-developmet", label: "Mobile App Development" },
                                    { category: "devops-services", label: "DevOps Services" },
                                    { category: "quality-assurance-services", label: "Quality Assurance Services" },
                                    { category: "data-analytics", label: "Data Analytics" },
                                    { category: "blockchain-development", label: "Blockchain Development" },
                                    { category: "cyber-security", label: "Cyber Security" },
                                ].map((list, index) => (
                                    <li
                                        key={index}
                                        className={list?.category === selectedCategory ? "active-category" : ""}
                                        onClick={() => handleCategoryClick(list.category)} // Update category on click
                                        style={{ cursor: "pointer" }}
                                    >
                                        {list?.label}
                                    </li>
                                ))}
                            </ul>
                        </div>

                        {/* Blogs Listing */}
                        <div className="col-md-9">
                            <div className="container">
                                <div className="row">
                                    {filteredBlogs?.map((card, index) => (
                                        <div className="col-md-6 mb-4" key={index}>
                                            <div className="project-card-main">
                                                <div className="project-image">
                                                    <img src={card?.imageSrc} alt={card?.title} />
                                                </div>
                                                <h2>{card?.title}</h2>
                                                <p className="common-text blog-main-card-text mt-2">
                                                    {card?.description?.split(" ").slice(0, 15).join(" ")}...
                                                </p>
                                                <div className="project-button">
                                                    <Link
                                                        to={`/blog-details/${card.id}`}
                                                        className="common-green-btn details-btn"
                                                    >
                                                        See Details <i className="fa-solid fa-arrow-right"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlogSection
import React, { useRef, useState, useCallback } from 'react'
import './style.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

import slidesData from '../../data/case-study-data'

import { Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';

const DevelopingSolution = ({ heading, subheading }) => {

    const sliderRef = useRef();

    const [isBeginning, setIsBeginning] = useState(true);
    const [isEnd, setIsEnd] = useState(false);

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current || isEnd) return;
        sliderRef.current.swiper.slideNext();
    }, [isEnd]);

    const handleSlideChange = (swiper) => {
        setIsBeginning(swiper.isBeginning);
        setIsEnd(swiper.isEnd);
    };

    return (
        <>
            <div className="developing-solution" id='case-study'>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-10 col-lg-10 col-md-12">
                            <div className="engagment-text-box">
                                <h2>{heading}</h2>
                                <p>{subheading}</p>
                            </div>
                        </div>

                        <div className=" col-xl-2 col-lg-2 col-md-12 pt-3">
                            <div className="arrow-developing-slider">
                                <img
                                    onClick={handlePrev}
                                    src="/images/arrow-right.png"
                                    className={`left-image ${isBeginning ? 'disabled' : ''}`}
                                    alt="Arrow Image"
                                    style={{ cursor: isBeginning ? 'not-allowed' : 'pointer' }}
                                />
                                <img
                                    onClick={handleNext}
                                    src="/images/arrow-right.png"
                                    className={`${isEnd ? 'disabled' : ''}`}
                                    alt="Arrow Image"
                                    style={{ cursor: isEnd ? 'not-allowed' : 'pointer' }}
                                />
                            </div>
                        </div>

                        <div className="col-md-12">

                            <Swiper
                                slidesPerView={'1.5'}
                                spaceBetween={20}
                                navigation={false}
                                modules={[Navigation]}
                                onSlideChange={handleSlideChange}
                                className="mySwiper"
                                ref={sliderRef}
                                breakpoints={{
                                    425: {
                                        slidesPerView: 1,
                                        spaceBetween: 10,
                                    },
                                    375: {
                                        slidesPerView: 1,
                                        spaceBetween: 10,
                                    },
                                    320: {
                                        slidesPerView: 1,
                                        spaceBetween: 10,
                                    },
                                    768: {
                                        slidesPerView: 1.3,
                                        spaceBetween: 10,
                                    },
                                    1024: {
                                        slidesPerView: 1.5,
                                        spaceBetween: 10,
                                    },
                                    1280: {
                                        slidesPerView: 1.5,
                                        spaceBetween: 10,
                                    },
                                }}
                            >
                                {slidesData.map((slide) => (
                                    <SwiperSlide key={slide.id}>
                                        <Link to={`/case-study-details/${slide.slug}`} >
                                            <div className="developing-solution-box">
                                                <div className="developing-card-image">
                                                    <img src={slide.image} alt={slide.title || "Slide Image"} />
                                                </div>
                                                <div className="developing-card-text-box">
                                                    <h5>{slide.title}</h5>
                                                    <div className="text-title-box">
                                                        <h2>{slide.heading}</h2>
                                                        {/* <p>{slide.date}</p> */}
                                                    </div>
                                                    <div className="developing-text-under-box">
                                                        <img
                                                            style={{ width: '150px' }}
                                                            src={slide.logo}
                                                            alt={slide.heading || "Logo"}
                                                        />
                                                        <a
                                                            href={slide.clutchLink}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <img
                                                                className="cluth-logo"
                                                                src={slide.clutchLogo}
                                                                alt="Clutch Rating"
                                                            />
                                                        </a>
                                                    </div>
                                                    <p className="common-text">{slide.description}</p>
                                                    <div className="profile-text-main-box">
                                                        <div className="profile-box-main">
                                                            <div className="porfile-image">
                                                                <img src={slide.profileImage} alt={slide.profileName || "Profile"} />
                                                            </div>
                                                            <div className="profile-text-side">
                                                                <h3>{slide.profileName}</h3>
                                                                <h5>{slide.profileDesignation}, {slide.heading}</h5>
                                                                <img src="/images/five-star-rating.png" alt="Rating Stars" />
                                                            </div>
                                                        </div>

                                                        <i
                                                            className="fa-solid fa-arrow-right"
                                                        ></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="comingsoon" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
                <div class="modal-dialog shedule-call-model-box modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class="fa-solid fa-xmark"></i></button>
                        </div>
                        <div class="modal-body">
                            <div className="d-flex justify-content-center">
                                <img src="/images/logo.png" width={300} alt="Logo" />
                            </div>
                            <h1 style={{ fontSize: '42px', textAlign: 'center', color: '#000000' }} className='mt-2'>Coming Soon,</h1>
                            <h1 style={{ fontSize: '42px', textAlign: 'center', color: '#000000' }}>We Are Working On It.</h1>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DevelopingSolution
import React, { useState } from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import "./style.css";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import { contactFromContactUsAPI } from "../../redux/slices/ContactFormApiSlice";

const ContactBanner = () => {

  const dispatch = useDispatch();

  const notify = () => toast("Your query submitted successfully!");

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const loading = useSelector(state => state?.contactFormApiSlice?.loading)


  const [contactUsPageFormData, setContactUsPageFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    company_name: "",
    about_project: "",
    nda: null,
    services: [],
  });

  const isFormComplete = () => {
    return (
      contactUsPageFormData.first_name &&
      contactUsPageFormData.last_name &&
      contactUsPageFormData.email &&
      contactUsPageFormData.company_name &&
      contactUsPageFormData.about_project &&
      contactUsPageFormData.nda &&
      contactUsPageFormData.services.length > 0
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactUsPageFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setContactUsPageFormData((prevData) => ({
      ...prevData,
      [name]: checked ? "Yes" : "No",
    }));
  };

  const handleServicesChange = (selected) => {
    const selectedValues = selected.map((option) => option.value);
    setContactUsPageFormData((prevData) => ({
      ...prevData,
      services: selectedValues,
    }));
  };

  const servicesArray = [
    {
      value: "Web Application Development",
      label: "Web Application Development",
    },
    {
      value: "AI Development",
      label: "AI Development",
    },
    {
      value: "Custom Development",
      label: "Custom Development",
    },
    {
      value: "Mobile App Development",
      label: "Mobile App Development",
    },
    {
      value: "DevOps Services",
      label: "DevOps Services",
    },
    {
      value: "Quality Assurance Services",
      label: "Quality Assurance Services",
    },
    {
      value: "Data Analytics",
      label: "Data Analytics",
    },
    {
      value: "BlockChain Development",
      label: "BlockChain Development",
    },
    {
      value: "Cyber Security",
      label: "Cyber Security",
    },
  ];

  // GOOGLE SHEET INTIGRATION

  const sheetName = "ContactUsPageFormSheet";

  const state = {
    FirstName: contactUsPageFormData.first_name,
    LastName: contactUsPageFormData.last_name,
    Email: contactUsPageFormData.email,
    CompanyName: contactUsPageFormData.company_name,
    AboutProject: contactUsPageFormData.about_project,
    NDA: contactUsPageFormData.nda,
    Services: JSON.stringify(contactUsPageFormData.services),
    SheetName: sheetName,
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitted Form Data:", contactUsPageFormData);

    const res = await dispatch(contactFromContactUsAPI(contactUsPageFormData));

    if (res.type === 'contactFromContactUsAPI/fulfilled') {
      toast.success(res?.payload?.message)
    } else if (res.type === 'contactFromContactUsAPI/rejected') {
      toast.success(res.payload.errors);
    } else {
      toast.error('Sorry, something went wrong!');
    }

    return

    // const formData = new FormData();
    // for (const key in state) {
    //   if (state.hasOwnProperty(key)) {
    //     const value = state[key];
    //     if (Array.isArray(value)) {
    //       for (let i = 0; i < value.length; i++) {
    //         formData.append(`${key}[${i}]`, value[i]);
    //       }
    //     } else {
    //       formData.append(key, value);
    //     }
    //   }
    // }
    // fetch(
    //   "https://script.google.com/macros/s/AKfycbxAT_8Kug6jF8L2QmYcbTLnGJ453fEwcX7kNMtKJEwOFwVv2zE0HRD8SUoxfMimdtlk/exec",
    //   {
    //     method: "POST",
    //     body: formData,
    //     mode: 'no-cors',
    //   }
    // )
    //   .then((data) => {
    //     console.log(data);
    //     setIsPopupOpen(true);
    //     setContactUsPageFormData({
    //       first_name: '',
    //       last_name: '',
    //       email: '',
    //       about_project: '',
    //       nda: '',
    //       company_name: "",
    //       services: [],
    //     })
    //   })
    //   .catch((error) => console.log(error));
  };
  // GOOGLE SHEET INTIGRATION




  return (
    <>
      <ToastContainer hideProgressBar={true} />
      <div className="contact-banner-box">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="contact-text-side center-box-contract-section ">
                <div className="contact-text-box">
                  <h1>
                    The Bottom Line is <br /> <span>WE GET IT DONE!</span>
                  </h1>
                </div>

                <div className="container-fluid p-0">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="skilled-contact-section">
                        <h2>Skilled Talent Network</h2>
                        <p className="common-text">
                          Our team consists of highly experienced professionals.
                        </p>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="skilled-contact-section">
                        <h2>Swift Turnaround</h2>
                        <p className="common-text">
                          We provide fast and efficient solutions despite time
                          constraints.
                        </p>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="skilled-contact-section">
                        <h2>Cost-effective Solutions</h2>
                        <p className="common-text">
                          We deliver high-quality services that maximize your
                          budget’s potential.
                        </p>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="skilled-contact-section">
                        <h2>Innovation-Driven Development</h2>
                        <p className="common-text">
                          We focus on delivering cutting-edge, innovative, and
                          tailored solutions.
                        </p>
                      </div>
                    </div>

                    <div className="col-md-6 mt-4">
                      <a href="mailto:info@bitsclan.com" className='contact-mail-to-text'><img src="/images/SVG/email-icon.png" alt="Email Icon" /> info@bitsclan.com</a>
                    </div>

                    <div className="col-md-6 mt-4">
                      <a href="mailto:hello@bitsclan.com" className='contact-mail-to-text'><img src="/images/SVG/email-icon.png" alt="Email Icon" /> hello@bitsclan.com</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-lg-6 col-md-12">
              <div className="contact-side-form contact-banner-form contact-banner-side">
                <div className="container p-0">


                  <form className="row contact-side-form-box" onSubmit={handleSubmit}>
                    <div className="col-md-6">
                      <div className="contact-us-form-feild-wrapper">
                        <label htmlFor="first_name">First Name</label>
                        <input
                          type="text"
                          className="contact-form-input"
                          placeholder="Enter Your first name here"
                          id="first_name"
                          name="first_name"
                          value={contactUsPageFormData.first_name}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="contact-us-form-feild-wrapper">
                        <label htmlFor="last_name">Last Name</label>
                        <input
                          type="text"
                          className="contact-form-input"
                          placeholder="Enter Your last name here"
                          id="last_name"
                          name="last_name"
                          value={contactUsPageFormData.last_name}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="contact-us-form-feild-wrapper mt-3">
                        <label htmlFor="email">Email</label>
                        <input
                          type="email"
                          className="contact-form-input"
                          placeholder="Enter Your email here"
                          id="email"
                          name="email"
                          value={contactUsPageFormData.email}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="contact-us-form-feild-wrapper mt-3">
                        <label htmlFor="company_name">Company Name</label>
                        <input
                          type="text"
                          className="contact-form-input"
                          placeholder="Enter Your company name here"
                          id="company_name"
                          name="company_name"
                          value={contactUsPageFormData.company_name}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="contact-us-form-feild-wrapper mt-3">
                        <label htmlFor="about_project">About your project</label>
                        <textarea
                          placeholder="Tell us more about your project*"
                          id="about_project"
                          className="contact-form-input"
                          name="about_project"
                          value={contactUsPageFormData.about_project}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="contact-us-form-feild-wrapper mt-3">
                        <label htmlFor="nda">Do you need an NDA first?</label>
                        <div className="d-flex gap-2">
                          <div className="form-check contact-us-radio-wrapper">
                            <input
                              className="form-check-input contact-us-radio"
                              type="radio"
                              name="nda"
                              value="Yes"
                              checked={contactUsPageFormData.nda === "Yes"}
                              onChange={handleInputChange}
                            />
                            <label>Yes</label>
                          </div>
                          <div className="form-check contact-us-radio-wrapper">
                            <input
                              className="form-check-input contact-us-radio"
                              type="radio"
                              name="nda"
                              value="No"
                              checked={contactUsPageFormData.nda === "No"}
                              onChange={handleInputChange}
                            />
                            <label>No</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="contact-us-form-feild-wrapper  mt-3">
                        <Select
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              backgroundColor: "transparent",
                              border: "1.12px solid #E9E9E9 !important",
                              boxShadow: "none !important",
                              color: "#FFFFFF",
                              borderRadius: "8px",
                              fontSize: "16px",
                            }),
                            placeholder: (baseStyles, state) => ({
                              ...baseStyles,
                              fontSize: "16px",
                            }),
                            menu: (baseStyles, state) => ({
                              ...baseStyles,
                              color: "black",
                            }),
                          }}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary25: "#80b927",
                            },
                          })}
                          isMulti
                          value={servicesArray.filter((service) =>
                            contactUsPageFormData.services.includes(service.value)
                          )}
                          onChange={handleServicesChange}
                          options={servicesArray}
                          placeholder="Services"
                        />
                      </div>
                    </div>

                    <div className="col-md-5 contact-section-button">
                      <button type="submit" disabled={loading || !isFormComplete()} className="btn btn-primary">
                        {loading ? 'Loading...' : 'Request Free Estimate'}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Popup open={isPopupOpen} position="center center" closeOnDocumentClick={false}>
        <div className="position-relative" style={{ backgroundColor: "rgb(242 242 242)" }}>

          <button
            onClick={() => setIsPopupOpen(false)}
            className="cross-btn-close"
          ><i class="fa-solid fa-xmark"></i></button>

          <video width="100%" height="100%" autoPlay muted>
            <source src="/videos/thankyou-vedio.mp4" type="video/mp4" />
          </video>

          <div className="header-link-green d-flex justify-content-center pb-5">
            <a href="https://calendly.com/bitsclan-it/30min?month=2025-01" target='_blank'>Let's Talk</a>
          </div>
        </div>
      </Popup>
    </>
  );
};

export default ContactBanner;

export const tabs = [
    "Backend",
    "Frontend",
    "Database",
    "Frameworks",
    "DevOps",
    "Quality Assurance",
];

export const tabItems = [
    { tab: "Frontend", icon: "/images/iconone.png", name: "Angular JS" },
    { tab: "Frontend", icon: "/images/icontwo.png", name: "JavaScript" },
    { tab: "Frontend", icon: "/images/iconthree.png", name: "React JS" },
    { tab: "Frontend", icon: "/images/icon4.png", name: "Vue JS" },
    { tab: "Frontend", icon: "/images/mobile3.png", name: "Swift" },
    { tab: "Frontend", icon: "/images/icon6.png", name: "CSS3" },
    { tab: "Frontend", icon: "/images/icon7.png", name: "HTML5" },
    { tab: "Backend", icon: "/images/database1.png", name: ".Net" },
    { tab: "Backend", icon: "/images/database2.png", name: "Nest Js" },
    { tab: "Backend", icon: "/images/database3.png", name: "Meteor JS" },
    { tab: "Backend", icon: "/images/database5.png", name: "Express JS" },
    { tab: "Backend", icon: "/images/database6.png", name: "Python" },
    { tab: "Backend", icon: "/images/django-tech.svg", name: "Django" },
    { tab: "Backend", icon: "/images/database8.png", name: "PHP" },
    { tab: "Backend", icon: "/images/database9.png", name: "Node Js" },
    { tab: "Database", icon: "/images/db1.png", name: "Firebase" },
    { tab: "Database", icon: "/images/db2.png", name: "Oracle " },
    { tab: "Database", icon: "/images/db3.png", name: "MongoDB" },
    { tab: "Database", icon: "/images/tech-stack-icons/postgres.svg", name: "PostgreSQL" },
    { tab: "Database", icon: "/images/db5.png", name: "MySQL" },
    { tab: "Database", icon: "/images/db6.png", name: "SQL Server" },
    { tab: "Database", icon: "/images/db7.png", name: "SQLite" },
    { tab: "Database", icon: "/images/db8.png", name: "DynamoDB" },
    { tab: "Frameworks", icon: "/images/framework1.png", name: "MEAN" },
    { tab: "Frameworks", icon: "/images/framework2.png", name: "CodeIgniter" },
    { tab: "Frameworks", icon: "/images/framework3.png", name: "Laravel" },
    { tab: "Frameworks", icon: "/images/framework4.png", name: "Rxweb.io" },
    { tab: "Frameworks", icon: "/images/framework5.png", name: "CakePHP" },
    { tab: "Frameworks", icon: "/images/framework6.png", name: "Django" },
    { tab: "DevOps", icon: "/images/devops1.png", name: "Gradle" },
    { tab: "DevOps", icon: "/images/devops2.png", name: "Kubernetes" },
    { tab: "DevOps", icon: "/images/devops3.png", name: "Docker" },
    { tab: "DevOps", icon: "/images/devops4.png", name: "Jenkins" },
    { tab: "Quality Assurance", icon: "/images/qa1.svg", name: "TestRail" },
    { tab: "Quality Assurance", icon: "/images/qa2.svg", name: "LoadRunner" },
    { tab: "Quality Assurance", icon: "/images/qa3.svg", name: "Cucumber" },
    { tab: "Quality Assurance", icon: "/images/qa4.svg", name: "TestNG" },
    { tab: "Quality Assurance", icon: "/images/qa5.svg", name: "Appium" },
    { tab: "Quality Assurance", icon: "/images/qa6.svg", name: "Selenium" },
];

export const faqData = [
    {
        id: "collapseOne",
        question: "How much does it cost to develop custom software?",
        answer:
            "The cost of developing custom software varies based on a number of factors, including duration, team allocation, and needs. Commonly, it lies somewhere between $15,000 to $300,000. For an accurate estimate.",
    },
    {
        id: "collapseTwo",
        question: "Why hire a custom software development company?",
        answer:
            "If you are looking to develop customized software tailored to your needs, it is best to hire a company that provides custom software development services.",
    },
    {
        id: "collapseThree",
        question: "How long will it take to complete my custom software?",
        answer:
            "The duration it takes to complete a custom software depends upon various factors including the complexity of the software, the structure, the tech stack, and more. To give you an idea, it can lie anywhere between 4 to 20 months. For an accurate estimate of your project.",
    },
    {
        id: "fourth",
        question: "What if I change the project requirements during the sprint?",
        answer:
            "If you decide to change the project priorities and requirements during an ongoing sprint, we will quickly assess the new changes, create a report based on them, strategize a new plan, and start implementing it after confirmation with key stakeholders.",
    },
    {
        id: "fifth",
        question: "How do you ensure collaboration?",
        answer:
            "Our team is aligned to work in your nearshore time zone and we use premium collaboration tools to prevent any miscommunications and ensure a smooth collaboration. Our PMs have regular meetings with your team to ensure timely and accurate delivery.",
    },
    {
        id: "sixth",
        question:
            "How do you work with delays and difficulties in communication with our business stakeholders?",
        answer:
            "We understand that business stakeholders have tight schedules so we prioritize meetings with proper agendas. Our team takes everything that needs to be discussed into account and with clear priorities, make the meetings count. Another fact is that most of these meetings are needed at the requirement-gathering stage and as the project develops, there is a lesser need for them.",
    },
];

export const TypesOfBuildData = [
    {
        id: "collapsenine",
        question: "Development Services",
        answer: {
            discription:
                "We craft software solutions that align with your goals, delivering tailored services for everything from front-end design to back-end development and beyond.",
            listTitle: "",
            listContent: [],
        },
    },
    {
        id: "collapseOne",
        question: "Requirement Gathering",
        answer: {
            discription:
                "Understanding your needs is where it all begins. We work closely with you to gather every detail, ensuring our custom software development meets your exact requirements.",
            listTitle: "",
            listContent: [],
        },
    },
    {
        id: "collapseTwo",
        question: "Analysis and Planning",
        answer: {
            discription:
                "Planning makes everything better! We analyze your requirements and map out a clear strategy, ensuring your software is scalable, efficient, and perfectly aligned.",
        },
    },
    {
        id: "collapseThree",
        question: "UI / UX Design",
        answer: {
            discription:
                "Design isn’t just about looks—it’s about creating experiences. We focus on intuitive, user-friendly designs that make your software solutions engaging and easy to use.",
        },
    },
    {
        id: "fourth",
        question: "Development",
        answer: {
            discription:
                "Here’s where ideas come to life! Our development process is focused on building reliable and scalable software that works seamlessly and grows with your business.",
        },
    },
    {
        id: "fifth",
        question: "QA and Testing",
        answer: {
            discription:
                "We test everything rigorously to ensure your software is bug-free and runs perfectly. Quality assurance is how we deliver reliable software solutions you can trust.",
        },
    },
    {
        id: "Sixth",
        question: "Deployment",
        answer: {
            discription:
                "When it’s ready, we make sure everything is set for a smooth launch. From final checks to real-time deployment, we ensure your software is up and running flawlessly.",
        },
    },
    {
        id: "seventh",
        question: "Maintenance and Upgrades",
        answer: {
            discription:
                "Our work doesn’t end at delivery. We provide ongoing support, regular updates, and upgrades to ensure your software stays current, efficient, and ready for the future.",
        },
    },
];

export const WhoWeServeData = [
    {
        icon: "/images/servie-icon/startups.svg",
        title: "Startups",
        description:
            "We help startups bring their ideas to life with scalable software and custom app solutions designed to grow with their business needs.",
        link: "#",
        borderClass: "border-1",
    },
    {
        icon: "/images/servie-icon/companies.svg",
        title: "Software product companies",
        description:
            "Empowering product companies with tailored software services, from design to deployment, ensuring seamless user experiences and scalability.",
        link: "#",
        borderClass: "border-2",
    },
    {
        icon: "/images/servie-icon/industries.svg",
        title: "Enterprises from 30+ industries",
        description:
            "Transforming enterprises with custom software development and scalable software solutions across diverse industries and challenges.",
        link: "#",
        borderClass: "border-3",
    },
];

export const HighvaluesData = [
    {
        image: "custom-app-development.svg",
        text: "Custom App Development",
    },
    {
        image: "user-centric-designs.svg",
        text: "User-Centric Designs",
    },
    {
        image: "robust-architecture.svg",
        text: "Robust Architecture",
    },
    {
        image: "efficient-integration.svg",
        text: "Efficient Integration",
    },
    {
        image: "advanced-analytics.svg",
        text: "Advanced Analytics",
    },
    {
        image: "enhanced-security.svg",
        text: "Enhanced Security",
    },
    {
        image: "agile-methodology.svg",
        text: "Agile Methodology",
    },
    {
        image: "cross-platform-support.svg",
        text: "Cross-Platform Support",
    },
    {
        image: "ongoing-maintenance.svg",
        text: "Ongoing Maintenance",
    },
    {
        image: "innovation-driven-features.svg",
        text: "Innovation-Driven Features",
    },
];

export const WhyChooseBitsclanData = [
    {
        id: "Tab1",
        label: "Quality Because It Matters",
        content:
            "At Bitsclan, quality isn’t just a goal—it’s a commitment. We focus on delivering high-performance, scalable, and reliable software solutions tailored to your unique business needs. From the initial concept to final deployment, our meticulous attention to detail ensures that every project is optimized for efficiency, security, and long-term success.",
        btnText: "Connect with the clan",
        btnLink: "#",
    },
    {
        id: "Tab2",
        label: "The Best-of-the-Breed Talent",
        content:
            "Our team comprises highly skilled developers, designers, and engineers who bring creativity and technical expertise to every project. With experience across multiple industries and the latest tech stacks, we craft innovative software solutions that solve real-world problems. Whether it’s web development, mobile applications, AI-powered solutions, or enterprise software, our experts ensure that every product is designed for usability, scalability, and seamless performance.",
        btnText: "Connect with the clan",
        btnLink: "#",
    },
    {
        id: "Tab3",
        label: "Time Zone Alignment for Seamless Collaboration",
        content:
            "We understand the importance of smooth communication and real-time collaboration, no matter where you are. Our globally distributed team works in sync with your time zone, ensuring timely updates, efficient project management, and hassle-free coordination. This approach minimizes delays, keeps workflows efficient, and ensures that your project progresses without disruptions.",
        btnText: "Connect with the clan",
        btnLink: "#",
    },
    {
        id: "Tab4",
        label: "Up-to-Date Technology for Future-Proof Solutions",
        content:
            "Technology is constantly evolving, and we stay ahead of the curve. Our developers utilize the latest frameworks, programming languages, and best practices to build software that is not only powerful but also adaptable to future advancements. Whether it’s cloud computing, AI-driven automation, or blockchain solutions, we ensure your software is built with the latest innovations to meet your business’s growing demands.",
        btnText: "Connect with the clan",
        btnLink: "#",
    },
    {
        id: "Tab5",
        label: "Commitment to Excellence",
        content:
            "We take complete ownership of every project, treating your success as our top priority. From in-depth requirement analysis to post-deployment support, we stay committed to delivering solutions that align perfectly with your business goals. Every line of code, every feature, and every optimization is crafted with precision and excellence to ensure the highest standards are met.",
        btnText: "Connect with the clan",
        btnLink: "#",
    },
];

export const DevelopmentServicesData = [
    {
        id: "Tab1",
        label: "Web Portals That Work for You",
        content:
            "Web portals serve as a centralized digital hub for businesses, providing employees, customers, and stakeholders with seamless access to essential information and services. Whether it's an internal portal for team collaboration, a customer self-service portal, or an e-commerce management system, web portals improve efficiency and streamline operations.",
        secondContent: "Custom web portal development ensures tailored features like role-based access, real-time data sharing, interactive dashboards, and secure authentication. With a user-centric design and smooth integration with third-party tools, a well-built web portal enhances engagement, improves workflow automation, and optimizes business processes for better productivity.",
        btnText: "Let's Discuss",
        btnLink: "#",
        imgs: [
            {
                img: "/images/services-icons/custom-development/fast-api.png",
            },
            {
                img: "/images/services-icons/custom-development/GraphQL-logo.png",
            },
            {
                img: "/images/services-icons/custom-development/angular-icon.png",
            },
            {
                img: "/images/services-icons/custom-development/mysql-logo.png",
            },
        ],
    },
    {
        id: "Tab2",
        label: "Strong & Secure Backend Systems",
        content:
            "A strong and secure backend is the backbone of any digital application, ensuring seamless functionality, data integrity, and system reliability. Backend systems handle data processing, authentication, business logic, and server-side operations, allowing applications to run smoothly without disruptions.",
        secondContent: "By leveraging technologies like FastAPI, GraphQL, Angular, and MySQL, businesses can build scalable and high-performance backend solutions. Security measures such as encryption, API authentication, and database optimization ensure data protection and compliance with industry standards. A well-structured backend architecture supports future growth, enabling businesses to scale their applications effortlessly.",
        btnText: "Let's Discuss",
        btnLink: "#",
        imgs: [
            {
                img: "/images/services-icons/custom-development/fast-api.png",
            },
            {
                img: "/images/services-icons/custom-development/GraphQL-logo.png",
            },
            {
                img: "/images/services-icons/custom-development/angular-icon.png",
            },
            {
                img: "/images/services-icons/custom-development/mysql-logo.png",
            },
        ],
    },
    {
        id: "Tab3",
        label: "Easy-to-Use Frontend Experiences",
        content:
            "A visually appealing and intuitive frontend is essential for engaging users and delivering a seamless digital experience. Frontend development focuses on creating user-friendly interfaces that enhance accessibility, responsiveness, and ease of navigation across devices.",
        secondContent: "By adopting modern UI/UX principles, businesses can develop aesthetically pleasing designs that maximize usability. Features like interactive elements, real-time updates, and optimized performance contribute to customer satisfaction. Leveraging frameworks like React, Angular, or Vue.js ensures that frontend applications are dynamic, fast-loading, and highly interactive, keeping users engaged and improving conversion rates.",
        btnText: "Let's Discuss",
        btnLink: "#",
        imgs: [
            {
                img: "/images/services-icons/custom-development/fast-api.png",
            },
            {
                img: "/images/services-icons/custom-development/GraphQL-logo.png",
            },
            {
                img: "/images/services-icons/custom-development/angular-icon.png",
            },
            {
                img: "/images/services-icons/custom-development/mysql-logo.png",
            },
        ],
    },
    {
        id: "Tab4",
        label: "Smart Software Consulting",
        content:
            "Software consulting plays a crucial role in guiding businesses through technological advancements, ensuring they choose the right solutions for their unique needs. Expert consultants analyze existing workflows, identify inefficiencies, and recommend tailored software solutions that improve operations.",
        secondContent: "A well-planned software strategy helps businesses reduce costs, mitigate risks, and enhance efficiency. Whether it's migrating to cloud-based solutions, optimizing system architecture, or integrating automation tools, software consulting provides the expertise needed to implement effective digital transformation. Through a structured approach, businesses can stay ahead of the competition and leverage technology to drive growth.",
        btnText: "Let's Discuss",
        btnLink: "#",
        imgs: [
            {
                img: "/images/services-icons/custom-development/fast-api.png",
            },
            {
                img: "/images/services-icons/custom-development/GraphQL-logo.png",
            },
            {
                img: "/images/services-icons/custom-development/angular-icon.png",
            },
            {
                img: "/images/services-icons/custom-development/mysql-logo.png",
            },
        ],
    },
    {
        id: "Tab5",
        label: "Custom Software for Enterprises",
        content:
            "Enterprise software solutions are designed to address specific business needs, providing automation, data management, and streamlined processes. Unlike off-the-shelf solutions, custom software offers flexibility, scalability, and security to meet the demands of large-scale operations.",
        secondContent: "Tailored enterprise applications enable businesses to optimize workflows, integrate multiple systems, and ensure seamless communication across departments. From customer relationship management (CRM) systems to enterprise resource planning (ERP) solutions, custom software enhances productivity, reduces operational inefficiencies, and improves decision-making through real-time analytics and reporting.",
        btnText: "Let's Discuss",
        btnLink: "#",
        imgs: [
            {
                img: "/images/services-icons/custom-development/fast-api.png",
            },
            {
                img: "/images/services-icons/custom-development/GraphQL-logo.png",
            },
            {
                img: "/images/services-icons/custom-development/angular-icon.png",
            },
            {
                img: "/images/services-icons/custom-development/mysql-logo.png",
            },
        ],
    },
    {
        id: "Tab6",
        label: "Flexible Headless CMS Solutions",
        content:
            "A headless CMS separates content management from the front-end display, giving businesses complete control over how their content is delivered across multiple platforms. This flexibility is crucial for companies managing content across websites, mobile apps, IoT devices, and other digital channels.",
        secondContent: "With a headless CMS, businesses can streamline content distribution, ensuring consistency across different platforms while enabling faster updates and scalability. It allows developers to use APIs to fetch content dynamically, providing improved performance and a seamless user experience. Headless CMS solutions empower content creators with easy-to-use tools while offering developers the freedom to design custom front-end experiences.",
        btnText: "Let's Discuss",
        btnLink: "#",
        imgs: [
            {
                img: "/images/services-icons/custom-development/fast-api.png",
            },
            {
                img: "/images/services-icons/custom-development/GraphQL-logo.png",
            },
            {
                img: "/images/services-icons/custom-development/angular-icon.png",
            },
            {
                img: "/images/services-icons/custom-development/mysql-logo.png",
            },
        ],
    },
];

export var mainBannerImg = {
    backgroundImage: `url(/images/what-we-do/custom-software-development.png)`,
};

export const schemaData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
        {
            "@type": "Question",
            name: "Why hire a custom software development company?",
            acceptedAnswer: {
                "@type": "Answer",
                text: "If you are looking to develop customized software tailored to your needs, it is best to hire a company that provides custom software development services.",
            },
        },
        {
            "@type": "Question",
            name: "How much does it cost to develop custom software?",
            acceptedAnswer: {
                "@type": "Answer",
                text: "The cost of developing custom software varies based on a number of factors, including duration, team allocation, and needs. Commonly, it lies somewhere between $15,000 to $300,000. For an accurate estimate, we recommend you talk to our experts.",
            },
        },
        {
            "@type": "Question",
            name: "How long will it take to complete my custom software?",
            acceptedAnswer: {
                "@type": "Answer",
                text: "The duration it takes to complete a custom software depends upon various factors including the complexity of the software, the structure, the tech stack, and more. To give you an idea, it can lie anywhere between 4 to 20 months. For an accurate estimate of your project, talk to the experts.",
            },
        },
        {
            "@type": "Question",
            name: "What if I change the project requirements during the sprint?",
            acceptedAnswer: {
                "@type": "Answer",
                text: "If you decide to change the project priorities and requirements during an ongoing sprint, we will quickly assess the new changes, create a report based on them, strategize a new plan, and start implementing it after confirmation with key stakeholders.",
            },
        },
        {
            "@type": "Question",
            name: "How do you ensure collaboration?",
            acceptedAnswer: {
                "@type": "Answer",
                text: "Our team is aligned to work in your nearshore time zone and we use premium collaboration tools to prevent any miscommunications and ensure a smooth collaboration. Our PMs have regular meetings with your team to ensure timely and accurate delivery.",
            },
        },
        {
            "@type": "Question",
            name: "How do you work with delays and difficulties in communication with our business stakeholders?",
            acceptedAnswer: {
                "@type": "Answer",
                text: "We understand that business stakeholders have tight schedules so we prioritize meetings with proper agendas. Our team takes everything that needs to be discussed into account and with clear priorities, make the meetings count. Another fact is that most of these meetings are needed at the requirement-gathering stage and as the project develops, there is a lesser need for them.",
            },
        },
    ],
};
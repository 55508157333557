import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "./styles.css";

const cookies = new Cookies();

const CookiesPolicyModal = ({ isOpen, onClose }) => {
  const [modalWidth, setModalWidth] = useState("50%");

  useEffect(() => {
    const updateWidth = () => {
      if (window.innerWidth <= 768) {
        setModalWidth("90%");
      } else if (window.innerWidth <= 1024) {
        setModalWidth("50%");
      } else {
        setModalWidth("50%");
      }
    };

    updateWidth(); // Set initial value
    window.addEventListener("resize", updateWidth);

    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  // const handleSubmit = () => {
  //   cookies.set("cookiesAccepted", "Yes", { path: "/" });
  //   onClose();
  // };

  const handleSubmit = () => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 10);

    cookies.set("cookiesAccepted", "Yes", { path: "/", expires: expirationDate });
    onClose();
  };


  return (
    <Popup
      open={isOpen}
      closeOnDocumentClick={false}
      contentStyle={{
        position: "fixed",
        bottom: "75px",
        left: "10px",
        width: modalWidth,
      }}
      overlayStyle={{
        background: "transparent",
      }}
    >
      <div className="modal-body-wrapper">
        <h3 className="">Your Privacy</h3>
        <p className="mt-3 text-justify">
          Bitsclan uses essential cookies and similar technologies to ensure the
          optional operation of our website and to offer you a tailored user
          experience. For more information, please review our{" "}
          <Link to={"/"}>Policy.</Link>
        </p>

        <p className="mt-3"></p>

        <div className="custom-modal-footer">
          <button
            onClick={handleSubmit}
            type="button"
            className="customize-cookies-btn"
          >
            Reject
          </button>
          <button
            onClick={handleSubmit}
            type="button"
            className="cookies-accept-btn"
          >
            Accept
          </button>
        </div>
      </div>
    </Popup>
  );
};

export default CookiesPolicyModal;

import React from "react";
import HirePageBanner from "../components/HirePageBanner/HirePageBanner";
import DevelopmentPerformance from "../components/DevelopmentPerformance/DevelopmentPerformance";
import SecondFaq from "../components/secondFaq/SecondFaq";
import Footer from "../components/footer/Footer";
import MegaHeader from "../components/MegaHeader/MegaHeader";
import { Helmet } from "react-helmet";
import {
  HireAngularBannerList,
  HireAngularFactsData,
  HireAngularFaqData,
  HireAngularHirignDetails,
  HireAngularServicesData,
  HireAngularSolutionsData,
  HireAngularSteps,
  mainBannerImg,
  tabItems,
  tabs,
  techCombinations,
} from "../data/hireAngularPagedata";
import HirePageExperties from "../components/hirepageExperties/HirePageExperties";
import HirePageSteps from "../components/hire-page-steps/HirePageSteps";
import HireExpertFacts from "../components/hire-expert-facts/HireExpertFacts";
import HirePageEngagmentModels from "../components/hire-page-engagment-models/HirePageEngagmentModels";
import HirePageAppIdea from "../components/hire-page-app-idea/HirePageAppIdea";
import ContactSection from "../components/contactSection/ContactSection";
import TrendsInsights from "../components/trends-n-insights/TrendsInsights";
import HireCombinationSection from "../components/hire-combination-sectiono/HireCombinationSection";
import CoverTheStack from "../components/coverthestack/CoverTheStack";

const HireAngular = () => {
  return (
    <>
      <Helmet>
        <title>Hire Angular Developers | Angular Development Services</title>
        <meta
          name="description"
          content="Hire top Angular developers for cutting-edge web apps. Custom Angular development services for fast, interactive user experiences."
        />
      </Helmet>

      <div className="sticky-header">
        <MegaHeader />
      </div>

      <HirePageBanner
        diffBannerBackgroundImage={mainBannerImg?.backgroundImage}
        image={""}
        mainHeading="Hire Angular Developers"
        content="Your trusted partner for expert Angular development, ideal for startups and enterprises looking for scalable, high-performance web applications."
        btnText="Consult Our Angular Developers Today"
        bannerList={HireAngularBannerList}
      />

      <HirePageExperties
        heading="Our Angular Developer Expertise"
        discription="Our Angular developers excel at building fast, interactive, and scalable web applications, ensuring smooth functionality and outstanding user experiences."
        servicesData={HireAngularServicesData}
      />

      <DevelopmentPerformance
        heading="Why Choose Bitsclan to Hire "
        greenHeading="Angular Developers?"
        subHeading="We leverage Angular and modern technologies to develop scalable, high-performance applications tailored to your business needs. Whether you're building a web platform or a mobile solution, we ensure smooth performance, efficiency, and a seamless user experience."
        solutions={HireAngularSolutionsData}
      />

      <HireCombinationSection combinations={techCombinations} heading="Top Angular Technology Combinations for High-Performance Development" description="Harness the power of Angular with the right technology stack to build dynamic, scalable, and efficient web applications. Hire expert Angular developers to ensure exceptional development and seamless user experiences." />

      <HirePageSteps
        heading={"Hire Angular Developers in 4 Simple Steps"}
        subHeading={
          "Finding the right Angular developer is quick and seamless with our efficient 4-step process."
        }
        steps={HireAngularSteps}
      />

      <CoverTheStack
        whereStart={"Languages"}
        mainHeading={"Enhance Your Angular Development with a Cutting-Edge Tech Stack"}
        subHeading={"At Bitsclan, we accelerate your Angular development with a meticulously selected tech stack that ensures high performance and scalability. Our Angular experts leverage the latest technologies to build dynamic, responsive, and custom web applications tailored to your business needs."}
        tabs={tabs}
        tabItems={tabItems} />

      <HireExpertFacts
        heading="Quick Facts About "
        greenHeading="Our Angular Team"
        facts={HireAngularFactsData}
        diffBannerBackgroundImage={mainBannerImg?.factBackgroundImage}
      />

      <HirePageAppIdea heading="Not Sure About Your App Idea? Get a Prototype in Just Weeks!" content="Have an app idea but unsure how to proceed? Our expert Ruby on Rails developers can help you bring your vision to life with a fully functional prototype in just 6-8 weeks. Validate your concept, test user engagement, and build a solid foundation for long-term success—without the guesswork." />

      <HirePageEngagmentModels description="Choose the engagement model that fits your project needs! Whether you need full-time, part-time, or hourly developers, we offer flexible options to ensure seamless collaboration and maximum efficiency throughout your project." hirignDetails={HireAngularHirignDetails} />

      <TrendsInsights specificClass="mobile-dev-page" category="all" />

      <SecondFaq
        specificClass="mobile-dev-page"
        faqs={HireAngularFaqData}
        mainHeading={"Frequently Asked Questions"}
        subHeading={"Discover answers to common questions about Angular."}
      />

      <ContactSection />

      <Footer />
    </>
  );
};

export default HireAngular;

import React from "react";
import "./styles.css";

const DevOpsRoadMap = () => {
  return (
    <>
      <div className="devops-road-map-box">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="devops-engagment-model-text">
                Structured DevOps Pipeline to <span style={{ color: '#80b927' }}>Drive Your Success</span>
              </h1>
              <h3 className="devops-sub-heading">
                At Bitsclan, we take a systematic approach to streamlining your IT operations while delivering continuous value and reliable performance, ensuring that your business thrives in an ever-evolving technological landscape.
              </h3>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="devops-prover-box-map-left ">
                <div className="devops-proven-roadmap-text devops-road-map-left-space second">
                  <h2>Analysis and Planning</h2>
                  <p className="devops-common-text">
                    We begin by conducting a thorough analysis of your existing IT infrastructure. This allows us to identify areas for improvement and create a strategic modernization plan.
                  </p>
                </div>

                <div className="devops-proven-roadmap-text forth">
                  <h2>Development of Your Solution</h2>
                  <p className="devops-common-text">
                    In the development phase, our expert teams build and customize your solution using the latest DevOps technologies to ensure high-quality code and faster delivery cycles.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 none-tab-box position-relative">
              <div className="devops-road-image-box devops-ai-development-road-map">
                <img
                  className="devops-none-tab"
                  src="/images/devops-roadmap.svg"
                  alt="Road Map"
                />
                <img
                  className="devops-none-web-box"
                  src="/images/roadmap-tablet/roadmap4-tab.png"
                  alt="Road Map"
                />
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="right-box-text ">
                <div className="devops-proven-roadmap-text devops-road-map-left-space first">
                  <h2> Applying Security Protocols</h2>
                  <p className="devops-common-text">
                    We implement industry-leading security protocols to safeguard your applications, infrastructure, and data, ensuring compliance with regulatory standards and protecting against threats.
                  </p>
                </div>

                <div className="devops-proven-roadmap-text third">
                  <h2>Continuous Integration and Continuous Deployment</h2>
                  <p className="devopscommon-text">
                    Our CI/CD practices ensure that updates and new features are integrated and deployed efficiently, enabling faster releases and minimizing downtime.
                  </p>
                </div>

                <div className="devops-proven-roadmap-text third">
                  <h2>Support and Maintenance</h2>
                  <p className="devopscommon-text">
                    We provide ongoing support and maintenance to keep your systems optimized, secure, and running smoothly, ensuring continuous performance and availability.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DevOpsRoadMap;

import React, { useEffect } from "react";
import SimilerBanner from "../components/similerBanner/SimilerBanner";
import DevelopingSolution from "../components/developingsolution/DevelopingSolution";
import Testimonial from "../components/testimonila/Testimonial";
import CoverTheStack from "../components/coverthestack/CoverTheStack";
import SecondFaq from "../components/secondFaq/SecondFaq";
import Footer from "../components/footer/Footer";
import { Helmet } from "react-helmet-async";
import MegaHeader from "../components/MegaHeader/MegaHeader";
import TrendsInsights from "../components/trends-n-insights/TrendsInsights";
import RoadMap from "../components/roadMap/RoadMap";
import WhoWeServe from "../components/who-we-serve/WhoWeServe";
import HighValues from "../components/high-values/HighValues";
import TypesOfBuild from "../components/types-of-build/TypesOfBuild";
import WhyChooseBitsclan from "../components/why-choose-bitsclan/WhyChooseBitsclan";
import IndustriesTabs from "../components/industries-tabs/IndustriesTabs";
import {
  IndustryTabsData,
} from "../data/industriesTabsData";
import DevelopmentServices from "../components/development-services/DevelopmentServices";
import ContactSection from "../components/contactSection/ContactSection";
import { WebServicesIndustryTabsItemsData } from "../data/web-app-service-page";
import NewEngagementModels from "../components/new-engagment-modal";
import { DevelopmentServicesData, faqData, HighvaluesData, mainBannerImg, schemaData, tabItems, tabs, TypesOfBuildData, WhoWeServeData, WhyChooseBitsclanData } from "../data/mobile-app-development-data";

const MobileDevelopmentServices = () => {

  return (
    <>
      <Helmet>
        <title>
          Mobile App Development Services | Custom iOS & Android Solutions
        </title>
        <meta
          name="description"
          content="Create high-quality mobile apps tailored to your business needs with our top-notch mobile app development services"
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
        />
      </Helmet>

      <div className="sticky-header">
        <MegaHeader />
      </div>

      <SimilerBanner
        diffBannerBackgroundImage={mainBannerImg}
        mainHeading={"Mobile App"}
        greenHeading="Development Services"
        subHeading={"Trusted by Startups, SMBs, and Enterprises alike."}
        content={
          "Bitsclan IT Solutions is a leading mobile application development company. We build user-centric, high-performance, customized, and secure mobile applications to succeed in a competitive market."
        }
        btnText2={"Let's Get Started"}
      />

      <Testimonial
        heading="Web Development Company of the Year 2021"
        content="We received the Best Web Development Company of the Year award from Payoneer in 2021. We bring the same expertise, dedication, vision, and conviction to every service we provide."
      />

      <div className="small-images-box">
        <DevelopmentServices
          tabs={DevelopmentServicesData}
          heading="Our Expansive Range of End-to-end Mobile App Development Services."
          subheading="Our custom mobile app services cover everything from AI-integrated, future-ready applications to safe and secure tailored solutions. Businesses require a mobile presence and that’s where our developers tap in."
          secondSubHeading="With round-the-clock flawless services, our experts are available to deliver a seamless, and innovative experience for your clients. From ideation to delivery, our developers ensure your product is exactly the prototype that you dreamt of."
        />
      </div>

      <TypesOfBuild
        typeOfApps={TypesOfBuildData}
        heading="Types of Mobile apps we build."
        sideImage="mobile-app-development-type.png"
      />

      <WhoWeServe
        cardContent={WhoWeServeData}
        heading="Who We Serve"
        subHeading="Our Mobile App Development Services are not confined to any level of success, but we take on from where you are and serve the highest level of innovative victory."
      />

      <WhyChooseBitsclan
        tabs={WhyChooseBitsclanData}
        heading="Why Choose Bitsclan as Your Mobile App Development Company?"
        subheading="From iOS app development to Android App Development services, Bitsclan offers a seamless experience of services that are integrable into existing solutions, flawless beyond deployment and equally secure while functional."
      />

      <HighValues
        heading="High-Value Features for High-Performance Apps"
        subHeading="At Bitsclan, we believe in high-tech solutions at zero compromise and that’s what has helped us climb the success ladder in this competitive era. We help you unlock the true potential of your projects with qualitative features offering custom solutions."
        boxContent={HighvaluesData}
        btnLink="#"
        btnText="Consult Project Scope"
      />

      <NewEngagementModels />

      <RoadMap
        heading={"Our Proven Roadmap to Ensure Your Success"}
        description={
          "As a leading AI app development services provider, we have developed a structured roadmap to ensure the success of your projects. From ideation to ongoing support, we follow a systematic approach that guarantees alignment with your business goals, delivers high-quality solutions, and provides continuous improvement. Here’s how we do it:"
        }
        roadMapHeading={[]}
        roadMapsteps={[]}
      />

      <CoverTheStack
        whereStart={"Frontend"}
        mainHeading={
          "Optimize Your Mobile App Development with Industry-Leading Tech Stack"
        }
        subHeading={
          "At Bitsclan, we ensure that your mobile app development processes are powered by the most advanced technologies available. Our carefully curated tech stack is designed to maximize efficiency, security, and scalability at every stage of the software development lifecycle."
        }
        tabs={tabs}
        tabItems={tabItems}
      />

      <IndustriesTabs
        mainheading={"Industries we serve Mobile Development"}
        tabs={IndustryTabsData}
        defaultTab={"Healthcare"}
        tabsItems={WebServicesIndustryTabsItemsData}
      />
      <DevelopingSolution
        heading="Developing Solutions That Transcend Mere Digital Transformation"
        subheading="We deliver innovative solutions that surpass digital transformation, driving impactful and lasting success."
      />

      <TrendsInsights specificClass="mobile-dev-page" category="mobile-app-developmet"/>

      <SecondFaq
        specificClass="mobile-dev-page"
        faqs={faqData}
        mainHeading={"FAQs"}
        subHeading={
          "Get quick answers to your questions about our mobile app development process and services."
        }
      />

      <ContactSection />
      <Footer />
    </>
  );
};

export default MobileDevelopmentServices;

import React from "react";
import "./style.css";

const BitsclanAI = () => {
  return (
    <>
      <div className="bitsclan-ai-box">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="main-heading">
                Why Bitsclan as your Artificial Intelligence Development
                Company?
              </h1>
              <h3 className="sub-heading">
                As a customer-centric AI development and consulting company, We
                offer tailored AI development services to meet the unique needs
                of various industries. Our expertise helps you leverage advanced
                technologies to address your industry's specific challenges and
                opportunities.
              </h3>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="quality-matter-box">
                <div className="construction-icon">
                  <img src="/images/premium.png" alt="Premium" />
                </div>

                <div className="construction-card-text quality-text-box">
                  <h2>
                    Quality Because <span>It Matters</span>
                  </h2>

                  <p className="common-text">
                    We deliver AI solutions tailored to your needs. With
                    rigorous testing and validation, we ensure reliable,
                    high-quality results that drive meaningful outcomes.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="quality-matter-box">
                <div className="construction-icon">
                  <img src="/images/best-bred.png" alt="Best Brands" />
                </div>

                <div className="construction-card-text quality-text-box">
                  <h2>
                    The Best-of-the-Breed<span> Talent</span>
                  </h2>

                  <p className="common-text">
                    Our team of top-tier experts combines innovation and
                    technical knowledge. We create custom AI development
                    solutions using the latest technologies to solve your
                    challenges.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="quality-matter-box">
                <div className="construction-icon">
                  <img src="/images/time-zone.png" alt="Time Zone" />
                </div>

                <div className="construction-card-text quality-text-box">
                  <h2>
                    Time Zone <span>Alignment</span>
                  </h2>

                  <p className="common-text">
                    We align with your time zone for seamless communication and
                    quick turnarounds. Real-time collaboration ensures your AI
                    projects meet deadlines with precision.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="quality-matter-box">
                <div className="construction-icon">
                  <img src="/images/tech-upd.png" alt="Tech Updated" />
                </div>

                <div className="construction-card-text quality-text-box">
                  <h2>
                    Up-to-Date <span>Technology</span>
                  </h2>

                  <p className="common-text">
                    Using advanced AI technologies, we craft cutting-edge
                    solutions. Staying updated helps us deliver innovation and
                    industry-leading AI development services for you.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="quality-matter-box">
                <div className="construction-icon">
                  <img src="/images/conviction.png" alt="conviction" />
                </div>

                <div className="construction-card-text quality-text-box">
                  <h2>
                    Our <span>Conviction</span>
                  </h2>

                  <p className="common-text">
                    We take ownership of your projects with dedication and
                    excellence. Our AI solutions are tailored to your goals,
                    ensuring they meet the highest standards.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="quality-matter-box quality-box-main-blue">
                <div className="construction-card-text quality-text-box quality-button-box">
                  <a
                    className="bitsclan-ai-calendly-click"
                    href="https://calendly.com/bitsclan-it/30min?month=2025-01"
                    target="_blank"
                  >
                    Schedule a Call <i class="fa-solid fa-arrow-right-long"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BitsclanAI;

import React from "react";
import Footer from "../components/footer/Footer";
import HirePageBanner from "../components/HirePageBanner/HirePageBanner";
import DevelopmentPerformance from "../components/DevelopmentPerformance/DevelopmentPerformance";
import SecondFaq from "../components/secondFaq/SecondFaq";
import MegaHeader from "../components/MegaHeader/MegaHeader";
import { Helmet } from "react-helmet";
import {
  bannerList,
  facts,
  faqData,
  hirignDetails,
  mainBannerImg,
  servicesData,
  solutionsData,
  steps,
  tabItems,
  tabs,
  techCombinations,
} from "../data/hire-django-data";
import HirePageExperties from "../components/hirepageExperties/HirePageExperties";
import HirePageSteps from "../components/hire-page-steps/HirePageSteps";
import HireExpertFacts from "../components/hire-expert-facts/HireExpertFacts";
import HirePageEngagmentModels from "../components/hire-page-engagment-models/HirePageEngagmentModels";
import HirePageAppIdea from "../components/hire-page-app-idea/HirePageAppIdea";
import ContactSection from "../components/contactSection/ContactSection";
import TrendsInsights from "../components/trends-n-insights/TrendsInsights";
import HireCombinationSection from "../components/hire-combination-sectiono/HireCombinationSection";
import CoverTheStack from "../components/coverthestack/CoverTheStack";

const HireDjango = () => {
  return (
    <>
      <Helmet>
        <title>
          Hire Django Developers | Django Development Services
        </title>
        <meta
          name="description"
          content="Hire skilled Django developers for secure, high-performing web solutions. Expert Django development services for all business sizes."
        />
      </Helmet>

      <div className="sticky-header">
        <MegaHeader />
      </div>

      <HirePageBanner
        diffBannerBackgroundImage={mainBannerImg?.backgroundImage}
        image={""}
        mainHeading="Hire Django Developers"
        content="Looking to hire top-notch Django developers? At Bitsclan, we provide pre-vetted experts to build secure, scalable, and high-performing web applications tailored for startups and enterprises."
        btnText="Consult our Django developers"
        bannerList={bannerList}
      />

      <HirePageExperties
        heading="Our Django Developer Expertise"
        discription=" At Bitsclan, our Django developers excel in building secure, scalable, and feature-rich web applications. Leveraging the power of Python and Django, we deliver seamless development that perfectly aligns with your business goals."
        servicesData={servicesData}
      />

      <DevelopmentPerformance
        heading="Why Hire Django Developers "
        greenHeading="from Bitsclan?"
        subHeading=" At Bitsclan, we combine Django with the latest front-end and backend technologies to build secure, scalable, and high-performing applications tailored to your business needs."
        solutions={solutionsData}
      />

      <HireCombinationSection combinations={techCombinations} heading="Top Django Technology Combinations for Robust Development" description="Leverage Django’s powerful framework with the best technology combinations to build secure, scalable, and high-performing applications. Hire expert Django developers to ensure seamless development and exceptional results." />

      <HirePageSteps
        heading={"Hire Django Developers in 4 Easy Steps"}
        subHeading={
          "Hiring top Django developers is now quick and hassle-free with our simple 4-step process. We ensure a smooth experience and the right talent for your project."
        }
        steps={steps}
      />

      <CoverTheStack
        whereStart={"Framework"}
        mainHeading={"Hire Expert Django Developers for Scalable & Secure Web Applications"}
        subHeading={"At Bitsclan, we empower your web development journey with a robust Django tech stack that ensures efficiency, security, and scalability. Our experienced Django developers leverage modern technologies to build high-performance, custom web applications tailored to your business needs."}
        tabs={tabs}
        tabItems={tabItems} />

      <HireExpertFacts
        heading="Quick Facts About "
        greenHeading="Our Django Team"
        facts={facts}
        diffBannerBackgroundImage={mainBannerImg?.factBackgroundImage}
      />

      <HirePageAppIdea content=" Have an idea but unsure how to proceed? Our Django experts can help you build a working prototype in just 6-8 weeks! Validate your vision, test user engagement, and lay a solid foundation for success." />

      <HirePageEngagmentModels description="Choose the engagement model that fits your project needs! Whether you need full-time, part-time, or hourly developers, we offer flexible options to ensure seamless collaboration and maximum efficiency throughout your project." hirignDetails={hirignDetails} />

      <TrendsInsights specificClass="mobile-dev-page" category="all" />

      <SecondFaq
        specificClass="mobile-dev-page"
        faqs={faqData}
        mainHeading={"Frequently Asked Questions"}
        subHeading={
          "Explore our Django development services for tailored web solutions, API creation, and deployment management!"
        }
      />

      <ContactSection />

      <Footer />
    </>
  );
};

export default HireDjango;

export const tabs = [
    "Generative AI Models",
    "Modules/Toolkits",
    "Deep Learning (DL) Frameworks",
    "Neural Networks",
    "Libraries",
    "Image Classification Models",
];

export const tabItems = [
    {
        tab: "Generative AI Models",
        icon: "/images/genai1.svg",
        name: "CycleGAN",
    },
    {
        tab: "Generative AI Models",
        icon: "/images/genai2.svg",
        name: "DeepArt",
    },
    {
        tab: "Generative AI Models",
        icon: "/images/genai3.svg",
        name: "DeepDream",
    },
    { tab: "Generative AI Models", icon: "/images/genai4.svg", name: "VQ-VAE" },
    { tab: "Generative AI Models", icon: "/images/genai5.svg", name: "GPT-4" },
    { tab: "Generative AI Models", icon: "/images/genai6.svg", name: "GPT-3" },
    { tab: "Generative AI Models", icon: "/images/genai6.svg", name: "DALL-E" },
    { tab: "Modules/Toolkits", icon: "/images/genai6.svg", name: "OpenAI GPT" },
    {
        tab: "Modules/Toolkits",
        icon: "/images/genai6.svg",
        name: "OpenAI DALL-E",
    },
    {
        tab: "Modules/Toolkits",
        icon: "/images/module1.svg",
        name: "DeepMind Sonnet",
    },
    {
        tab: "Modules/Toolkits",
        icon: "/images/module2.svg",
        name: "Facebook AI Research ",
    },
    {
        tab: "Modules/Toolkits",
        icon: "/images/module3.svg",
        name: "Hugging Face",
    },
    {
        tab: "Modules/Toolkits",
        icon: "/images/module4.svg",
        name: "NVIDIA StyleGAN",
    },
    {
        tab: "Deep Learning (DL) Frameworks",
        icon: "/images/tech-icon.svg",
        name: "PyTorch",
    },
    {
        tab: "Deep Learning (DL) Frameworks",
        icon: "/images/tech-icon2.svg",
        name: "Keras",
    },
    {
        tab: "Deep Learning (DL) Frameworks",
        icon: "/images/tech-icon3.svg",
        name: "MXNet",
    },
    {
        tab: "Deep Learning (DL) Frameworks",
        icon: "/images/tech-icon4.svg",
        name: "TensorFlow",
    },
    {
        tab: "Deep Learning (DL) Frameworks",
        icon: "/images/tech-icon5.svg",
        name: "Caffe",
    },
    { tab: "Neural Networks", icon: "/images/natural1.svg", name: "RBM" },
    { tab: "Neural Networks", icon: "/images/natural2.svg", name: "DBN" },
    {
        tab: "Neural Networks",
        icon: "/images/natural7.svg",
        name: "Transformers",
    },
    {
        tab: "Neural Networks",
        icon: "/images/natural4.svg",
        name: "Autoencoders",
    },
    { tab: "Neural Networks", icon: "/images/natural5.svg", name: "DRLN" },
    { tab: "Neural Networks", icon: "/images/natural6.svg", name: "GAN" },
    { tab: "Libraries", icon: "/images/tech-icon.svg", name: "PyTorch" },
    { tab: "Libraries", icon: "/images/tech-icon2.svg", name: "Keras" },
    { tab: "Libraries", icon: "/images/tech-icon3.svg", name: "MXNet" },
    { tab: "Libraries", icon: "/images/libraries1.svg", name: "FastAI" },
    { tab: "Libraries", icon: "/images/libraries2.svg", name: "TensorFlow" },
    { tab: "Libraries", icon: "/images/libraries3.svg", name: "Theano" },
    { tab: "Libraries", icon: "/images/libraries4.svg", name: "Scikit-learn" },
    {
        tab: "Image Classification Models",
        icon: "/images/calsification.svg",
        name: "AlexNet",
    },
    {
        tab: "Image Classification Models",
        icon: "/images/calsification2.svg",
        name: "EfficientNet",
    },
    {
        tab: "Image Classification Models",
        icon: "/images/calsification3.svg",
        name: "Xception",
    },
    {
        tab: "Image Classification Models",
        icon: "/images/calsification4.svg",
        name: "DenseNet",
    },
    {
        tab: "Image Classification Models",
        icon: "/images/calsification5.svg",
        name: "Inception",
    },
    {
        tab: "Image Classification Models",
        icon: "/images/calsification6.svg",
        name: "ResNet",
    },
    {
        tab: "Image Classification Models",
        icon: "/images/calsification7.svg",
        name: "VGGNet",
    },
];

export var mainBannerImg = {
    backgroundImage: `url(/images/what-we-do/ai-development-services.png)`,
};

export const schemaData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
        "@type": "Question",
        "name": "How much does an AI service cost?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "The cost of the AI services you require depends upon factors like the scope of the project, the technology involved, etc. Typically, an AI service can range anywhere between $5000 to $300,000. As a top-rated AI consulting and development company, Bitsclan IT Solutions offers free of cost AI consultation."
        }
    }, {
        "@type": "Question",
        "name": "What are AI development services?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "AI development services refer to any services that utilize artificial intelligence to develop new solutions or modify existing ones with AI integration for enhanced operations, reduced errors, better productivity, increased agility, and scaled revenue."
        }
    }, {
        "@type": "Question",
        "name": "How long does it take to build an AI product?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "There is no fixed time for AI product development. The complexity of the product will decide the time required to complete it. A highly complex product with advanced features may take up to 10 to 20 months. On the other hand, a simple product with minimal AI features can take up to 3 to 6 months."
        }
    }, {
        "@type": "Question",
        "name": "How can integrating artificial intelligence benefit my business?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "By integrating AI, decision-making procedures can be improved, repetitive jobs can be automated, and data-driven insights can be obtained. AI can also be used to forecast trends, optimize resource allocation, and personalize client experiences."
        }
    }, {
        "@type": "Question",
        "name": "What is the process of outsourcing AI development services?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Outsourcing AI development involves partnering with a specialized AI company to handle your AI projects. The process typically includes initial consultation, requirement gathering, project planning, development, testing, implementation, and ongoing support."
        }
    }, {
        "@type": "Question",
        "name": "Which industries can benefit from AI development services?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Numerous industries, including healthcare, finance, retail, manufacturing, logistics, real estate, legal, education, media, agriculture, gaming, and energy, can profit from AI development services."
        }
    }, {
        "@type": "Question",
        "name": "Can AI solutions be integrated with my existing systems and workflows?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Yes, AI solutions can be seamlessly integrated with your existing systems and workflows. Our experts ensure that the AI technology complements your current infrastructure, enhancing its capabilities without disrupting operations."
        }
    }, {
        "@type": "Question",
        "name": "How do you ensure data privacy and security in AI projects?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "We prioritize data privacy and security in all our AI projects. We implement robust security measures, comply with industry standards, and use encryption and anonymization techniques to protect your data throughout the project lifecycle."
        }
    }]
}
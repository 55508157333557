import React from 'react'
import { Helmet } from 'react-helmet'
import MegaHeader from '../components/MegaHeader/MegaHeader'
import Testimonial from '../components/testimonila/Testimonial'
import Footer from '../components/footer/Footer'
import ContactBanner from '../components/contactbanner/ContactBanner'
import PioneeringChange from '../components/pioneeringChange/PioneeringChange'

const HireContactPage = () => {

    const tabData = [
        {
            title: 'Healthcare',
            description: 'Our DevOps practices are tailored to prioritize continuous integration and automation to enhance the delivery of secure, patient-centered healthcare solutions while adhering to stringent privacy regulations like HIPAA.',
            icon: '/images/heart.png',
            image: 'healthcare.jpg'
        },
        {
            title: 'eCommerce',
            description: 'We enhance the scalability and performance of eCommerce platforms, ensuring that retailers can deliver exceptional customer experiences both online and offline by integrating continuous delivery practices.',
            icon: '/images/Retail-eCommerce.png',
            image: 'ecommerce.jpg'
        },
        {
            title: 'Government',
            description: 'By focusing on security, compliance, and efficiency, we help government entities enhance their service delivery, protect sensitive data, and ensure adherence to regulations while implementing innovative digital solutions.',
            icon: '/images/government.png',
            image: 'government.jpg'
        },
        {
            title: 'Education',
            description: 'Our DevOps practices are designed to optimize learning management systems and secure online educational platforms with reliable, high-performance tools that enhance the learning experience.',
            icon: '/images/Education.png',
            image: 'education.jpg'
        },
        {
            title: 'Logistics',
            description: 'As an expert DevOps service company, we deploy solutions that streamline fleet management, optimize logistics systems, and improve real-time tracking capabilities through automation and continuous improvement.',
            icon: '/images/Logistics.png',
            image: 'logistics.jpg'
        },
        {
            title: 'FinTech',
            description: 'Our DevOps consultants specialize in building robust, scalable financial solutions that protect financial data, ensure adherence to industry regulations, and meet the demanding needs of the FinTech industry.',
            icon: '/images/Fintech.png',
            image: 'fintech.webp'
        },
        {
            title: 'Automotive',
            description: 'By integrating advanced DevOps practices, we support the development of innovative automotive software solutions that optimize production processes and drive efficiency and innovation across the industry.',
            icon: '/images/Automotive.png',
            image: 'automotive.jpg'
        },
        {
            title: 'Agriculture',
            description: 'With a deep understanding of the agriculture industry, we develop DevOps solutions to enhance precision farming techniques, streamline crop management systems, and help agricultural businesses improve operational efficiency and resource management.',
            icon: '/images/Agriculture.png',
            image: 'agriculture.jpg'
        },
        {
            title: 'Real Estate',
            description: 'We implement continuous integration and delivery to ensure that real estate businesses can adapt quickly to market changes and deliver superior client experiences by enhancing property management systems and optimizing sales processes.',
            icon: '/images/RealEstate.png',
            image: 'realestate.webp'
        },
        {
            title: 'Insurance',
            description: 'For the insurance industry, our DevOps practices focus on enhancing policy management systems, streamlining claims processing, and ensuring secure data handling, providing a seamless experience for both insurers and policyholders.',
            icon: '/images/Insurance.png',
            image: 'insurance.webp'
        }
    ];


    return (
        <>
            <Helmet>
                <title>Get in Touch for Expert Services | Bitsclan IT Solutions</title>
                <meta name="description" content="Have questions or need assistance? Contact us at Bitsclan IT Solutions for expert AI services, software development & more. Book free consultation now." />
            </Helmet>
            <div className="sticky-header">
                <MegaHeader />
            </div>
            <ContactBanner />

            <PioneeringChange tabData={tabData} greenHeading={" Tailored Solutions"} mainHeading={"Industries We Serve with Expert Talent and"} content={"Our expertise in mobile app development extends across a wide range of industries, delivering customized solutions that meet the unique demands of each sector."} />

            {/* <div className="space-top-future-solution"> */}
            {/* <Testimonial heading={"Empowering Your Vision with Expert Talent and Seamless Collaboration"} /> */}
            {/* </div> */}
            <Footer />
        </>
    )
}

export default HireContactPage
export const tabs = [
    "Languages & Frameworks",
    "Libraries",
    "Frameworks",
];

export const tabItems = [
    { tab: "Languages & Frameworks", icon: "/images/tech-stack-icons/java.svg", name: "Java" },
    { tab: "Languages & Frameworks", icon: "/images/tech-stack-icons/kotlin.svg", name: "Kotlin" },
    { tab: "Languages & Frameworks", icon: "/images/tech-stack-icons/android-sdk.svg", name: "Android SDK" },
    { tab: "Languages & Frameworks", icon: "/images/tech-stack-icons/jetpack-compose.svg", name: "Jetpack Compose" },
    { tab: "Languages & Frameworks", icon: "/images/tech-stack-icons/android-jetpack.svg", name: "Android Jetpack" },
    { tab: "Libraries", icon: "/images/tech-icon.svg", name: "PyTorch" },
    { tab: "Libraries", icon: "/images/tech-icon2.svg", name: "Keras" },
    { tab: "Libraries", icon: "/images/tech-icon3.svg", name: "MXNet" },
    { tab: "Libraries", icon: "/images/libraries1.svg", name: "FastAI" },
    { tab: "Libraries", icon: "/images/libraries2.svg", name: "TensorFlow" },
    { tab: "Libraries", icon: "/images/libraries3.svg", name: "Theano" },
    { tab: "Libraries", icon: "/images/libraries4.svg", name: "Scikit-learn" },
    { tab: "Frameworks", icon: "/images/iconone.png", name: "Angular JS" },
    { tab: "Frameworks", icon: "/images/node.svg", name: "Node JS" },
    { tab: "Frameworks", icon: "/images/tech-stack-icons/vue-js.svg", name: "Vue.JS" },
    { tab: "Frameworks", icon: "/images/database5.png", name: "Express JS" },
    { tab: "Frameworks", icon: "/images/database3.png", name: "Meteor JS" },
];

export const techCombinations = [
    {
        title: "JavaScript + React + Firebase",
        description:
            "JavaScript as the core language, React for a dynamic front-end, and Firebase as a real-time database help build interactive and serverless web applications.",
        icons: ["javascript.svg", "react.svg", "firebase.png"],
    },
    {
        title: "JavaScript + Angular + Node.js",
        description:
            "Angular on the front end, Node.js as the backend, and JavaScript across the stack enable efficient full-stack development for scalable applications.",
        icons: ["javascript.svg", "angular.svg", "nodejs.svg"],
    },
    {
        title: "JavaScript + Vue.js + PostgreSQL",
        description:
            "Vue.js for a lightweight front-end, JavaScript-powered logic, and PostgreSQL as a relational database ensure a balanced and efficient web stack.",
        icons: ["javascript.svg", "vue-js.svg", "postgres.svg"],
    },
    {
        title: "JavaScript + Express.js + MongoDB",
        description:
            "Express.js as a lightweight backend framework, JavaScript for handling logic, and MongoDB as a NoSQL database create a fast and flexible web solution.",
        icons: ["javascript.svg", "express.svg", "mongodb.svg"],
    },
];


export const mainBannerImg = {
    backgroundImage: `url(/images/hire-page-images/hire-javascript.png)`,
    factBackgroundImage: `url(/images/hire-page-images/hire-javascript.svg)`,
};

export const bannerList = [
    { list: "Flexible Hiring Options" },
    { list: "Full-time roles" },
    { list: "Global teams" },
];

export const servicesData = [
    {
        title: "Javascript Consulting",
        description:
            " Need expert guidance for your JavaScript project? Our experienced developers provide strategic advice to enhance performance, scalability, and efficiency, ensuring you achieve your business goals.",
        icon: "/images/hire-page-icons/hire-javascrip-icon1.svg",
    },
    {
        title: "Javascript web Development",
        description:
            " We specialize in building powerful, feature-rich, and scalable web applications with JavaScript. Our experts ensure smooth performance, enhanced functionality, and an outstanding user experience.",
        icon: "/images/hire-page-icons/hire-javascrip-icon2.svg",
    },
    {
        title: "Javascript API Development",
        description:
            " Our JavaScript developers build robust and secure APIs, enabling seamless integration and communication between applications, third-party services, and platforms.",
        icon: "/images/hire-page-icons/hire-javascrip-icon3.svg",
    },
    {
        title: "JavaScript CMS and Portal Development",
        description:
            "Looking for a powerful CMS or web portal? Our JavaScript developers build scalable and user-friendly content management systems that streamline content management and user interactions.",
        icon: "/images/hire-page-icons/hire-javascrip-icon4.svg",
    },
    {
        title: "Migration to Javascript",
        description:
            "Planning to migrate your existing system to JavaScript? Our experts ensure a seamless transition with minimal downtime, enhancing performance and scalability.",
        icon: "/images/hire-page-icons/hire-javascrip-icon5.svg",
    },
    {
        title: "Integration with Existing Solutions",
        description:
            "Our JavaScript experts seamlessly integrate your applications with third-party services, APIs, and platforms, ensuring smooth workflows and improved functionality.",
        icon: "/images/hire-page-icons/hire-javascrip-icon6.svg",
    },
    {
        title: "Javascript eCommerce Solutions",
        description:
            "We build high-performance eCommerce solutions with JavaScript, offering secure payment gateways, intuitive navigation, and scalable architecture to boost conversions.",
        icon: "/images/hire-page-icons/hire-javascrip-icon7.svg",
    },
    {
        title: "Javascript Support & Maintenance",
        description:
            "Keep your JavaScript application secure and optimized with our continuous support and maintenance services. We handle updates, bug fixes, and performance enhancements.",
        icon: "/images/hire-page-icons/hire-javascrip-icon8.svg",
    },
];


export const hirePageStacks = [
    {
        title: "Junior Javascript Developer",
        description: [
            "2 Years' experience",
            "Fresh perspectives & innovative ideas",
            "Dedicated to explore web development",
        ],
        linkText: "Connect to Hire",
        link: "#",
        borderClass: "border-1",
    },
    {
        title: "Senior Javascript Developer",
        description: [
            "2+ years' experience",
            "Leadership in complex projects",
            "Proven app deployment success",
        ],
        linkText: "Connect to Hire",
        link: "#",
        borderClass: "border-2",
    },
    {
        title: "Javascript Tech Lead",
        description: [
            "5+ years' experience",
            "Skilled with Jira, Asana, MS Teams, etc.",
            "Suitable when you Hire Web App Team",
        ],
        linkText: "Connect to Hire",
        link: "#",
        borderClass: "border-3",
    },
];

export const benefits = [
    {
        service: "Javascript CMS Development",
        description: "Get an upper hand on your web content, augment efficiency, and develop a shared environment with our Javascript CMS solutions."
    },
    {
        service: "Javascript Migrations",
        description: "Our Javascript engineers strategically migrate legacy content into modern systems in a secured, error-free, and robust way."
    },
    {
        service: "Advanced Javascript Programming",
        description: "Our experts have extensive experience in using Javascript tech stack for tangible results to reach your business vision."
    },
    {
        service: "Javascript Integration and Optimization",
        description: "Leverage the full-scale potential of Javascript integration and optimization functionalities for your app development needs."
    },
    {
        service: "Customized Javascript Solutions",
        description: "Our Javascript developers delve into ideation, reinvention, and scale development as per your enterprise's unique IT demands."
    },
    {
        service: "Javascript eCommerce App",
        description: "With using modern Javascript tools and technologies, we have a proven track record of developing scalable and secured eCommerce sites."
    }
];

export const slidesData = [
    {
        title: "Rapid Development",
        content:
            "Javascript’s built-in tools and features allow for faster development, reducing time-to-market.",
        backgroundClass: "bg-1",
    },
    {
        title: "Scalability",
        content:
            "Javascript is designed to handle high-traffic and complex applications, making it perfect for growing businesses",
        backgroundClass: "bg-2",
    },
    {
        title: "Security",
        content:
            "With built-in protection against common security threats like SQL injection and cross-site scripting, Javascript ensures secure applications",
        backgroundClass: "bg-3",
    },
    {
        title: "Versatility",
        content:
            "From simple websites to complex web apps, Javascript is flexible enough to handle a variety of development needs",
        backgroundClass: "bg-4",
    },
    {
        title: "Maintainability",
        content:
            'Its clean architecture and "Don’t Repeat Yourself" (DRY) principle make Javascript applications easy to manage and scale over time.',
        backgroundClass: "bg-1",
    },
];

export const steps = [
    {
        title: "Choose your Engagement Model",
        description: "Select the engagement model that works best for you — whether Full-Time, Part-Time, or Hourly. We give you the flexibility to choose what suits your project.",
        icon: "/images/hire-page-setps-icons/icon-1.svg"
    },
    {
        title: "Screen & Select Javascript Developers",
        description: "Browse through profiles of top JavaScript developers for hire. Choose the best candidates to continue with the process.",
        icon: "/images/hire-page-setps-icons/icon-2.svg"
    },
    {
        title: "Conduct One-on-One Interview",
        description: "Interview the shortlisted candidates and test their skills with both theoretical and practical questions. This step helps ensure you get the right fit for your project.",
        icon: "/images/hire-page-setps-icons/icon-3.svg"
    },
    {
        title: "Javascript Developers",
        description: "Once you finalize your choice, the selected JavaScript developer will join your team within 24-48 hours, ready to start working on your project.",
        icon: "/images/hire-page-setps-icons/icon-4.svg"
    }
];

export const facts = [
    {
        text: "40+ expert JavaScript developers ready to build and scale your applications.",
    },
    {
        text: "Developers with 6+ years of experience in front-end and back-end JavaScript technologies.",
    },
    { text: "Skilled in modern frameworks like React.js, Vue.js, and Node.js for dynamic web development." },
    {
        text: "Expertise in serverless computing, cloud-native development, and API integrations.",
    },
    { text: "Proficient in emerging technologies like AI, IoT, and big data solutions." },
];
export const WhoWeServeData = [
    {
        icon: "/images/servie-icon/startups.svg",
        title: "Budget savings",
        description: "Assembling a local Javascript team is unavoidably expensive. By approaching Innowise. you bypass many hurdles and avoid redundantexpenses such as training fees. taxes. and software licenses.",
        link: "#",
        borderClass: "border-1",
    },
    {
        icon: "/images/servie-icon/companies.svg",
        title: "Software product companies",
        description: "When you hire ROR developers. you avoid the risks of budget overruns and missed deadlines. Innowise provides solid risk management plans and has an array of risk mitigation measures",
        link: "#",
        borderClass: "border-2",
    },
    {
        icon: "/images/servie-icon/industries.svg",
        title: "Enterprises from 30+ industries",
        description:
            "Innowise has experts with excellent development backgrounds. so you can rest assured that your project is in good hands. Pyrggyelgprneg",
        link: "#",
        borderClass: "border-3",
    },
];

export const hirignDetails = [
    {
        text: "Hourly Hiring",
        hours: "48",
        icon: "time.svg",
        bgColor: "#FDFDFF"
    },

    {
        text: "Full Time Hiring",
        hours: "72",
        icon: "full-time.svg",
        bgColor: "#FFFEFA"
    },

    {
        text: "Part Time Hiring",
        hours: "48",
        icon: "part-time.svg",
        bgColor: "#FDFFFA"
    },
]

export const hirePageDeliver = [
    {
        image: "/images/hire-page-images/card-1.png",
        title: "Complex SaaS systems",
        description: "Innowise provides its expertise in the area of building complex platforms for businesses to streamline workflows. The main applications we develop include HRM, CRM, ERP, accounting software, etc."
    },
    {
        image: "/images/hire-page-images/card-2.png", // Assumed path based on provided example
        title: "Complex SaaS systems",
        description: "Innowise provides its expertise in the area of building complex platforms for businesses to streamline workflows. The main applications we develop include HRM, CRM, ERP, accounting software, etc."
    },
    {
        image: "/images/hire-page-images/card-3.png", // Assumed path based on provided example
        title: "Complex SaaS systems",
        description: "Innowise provides its expertise in the area of building complex platforms for businesses to streamline workflows. The main applications we develop include HRM, CRM, ERP, accounting software, etc."
    },
];

export const faqData = [
    {
        id: "collapseOne",
        question: "What is JavaScript and why should I use it?",
        answer:
            "JavaScript is a popular programming language used to create interactive and dynamic web pages. It helps make websites more engaging by allowing features like animations, form validations, and interactive maps. If you want a website that is more than just static pages, JavaScript is a great choice.",
    },
    {
        id: "collapseTwo",
        question: "Why should I hire JavaScript developers?",
        answer:
            "Hiring JavaScript developers ensures that you get experts who can build high-quality, interactive websites or web applications. They know how to make your website work smoothly on different devices, providing a better user experience.",
    },
    {
        id: "collapseThree",
        question: "How do I know if I need JavaScript for my project?",
        answer:
            "If you want a dynamic website that interacts with users, like forms, animations, or live updates, JavaScript is essential. It’s also useful for building apps and websites that require real-time data, such as e-commerce sites or social media platforms.",
    },
    {
        id: "fourth",
        question: "How can I hire the best JavaScript developers?",
        answer:
            "You can hire JavaScript developers by looking for professionals with experience in building interactive and dynamic websites. It’s important to review their past projects, check their skills, and make sure they understand your project’s goals.",
    },
    {
        id: "fifth",
        question: "How long does it take to build a JavaScript web application?",
        answer:
            "The time it takes to build a JavaScript web application depends on the complexity of the project. Simple websites might take a few weeks, while more complex web applications may take several months. It’s important to discuss timelines with your development team.",
    },
    {
        id: "sixth",
        question: "What are the benefits of hiring JavaScript developers from Bitsclan?",
        answer:
            "At Bitsclan, we have experienced JavaScript developers for hire who are skilled in building dynamic, high-performance web applications. We ensure quick onboarding, clear communication, and deliver solutions that meet your business needs.",
    },
];

export const solutionsData = [
    {
        icon: "/images/hire-page-icons/chose-ror-section1.svg",
        title: "Access vetted talent",
        description:
            "When you hire JavaScript developers with us, you get access to professionals who are fully vetted for their skills, experience, and English fluency, ensuring they can contribute effectively to your project.",
    },
    {
        icon: "/images/hire-page-icons/chose-ror-section2.svg",
        title: "View matches in seconds",
        description:
            "Forget going through hundreds of resumes! With our HireAI system, you can instantly find JavaScript developers for hire and match with the right talent for your project in just a few seconds.",
    },
    {
        icon: "/images/hire-page-icons/chose-ror-section3.svg",
        title: "Save with global hires",
        description:
            "By hiring globally, you can access over 450,000 talented professionals from 190 countries. This can save you up to 58% compared to traditional hiring methods, while still getting the best developers for your JavaScript development services.",
        noBorder: true,
    },
    {
        icon: "/images/hire-page-icons/chose-ror-section4.svg",
        title: "Get real human support",
        description:
            "At Bitsclan, we don't leave you on your own. You’ll receive personalized assistance from our team of expert recruiters to ensure you hire the right JavaScript developer for your needs.",
    },
];
import React, { useState } from 'react'
import './style.css'
import { useLocation } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AIContactSection = ({ heading, subHeading, contactSections }) => {

    const notify = () => toast("Your query submitted successfully!");

    const location = useLocation();
    const myPath = location.pathname;

    const [loading, setLoading] = useState(false)

    const [AIFormData, setAIFormData] = useState({
        name: '',
        email: '',
        phone: '',
        company: '',
        projectDetails: '',
        isCareerInquiry: false,
    });

    const isFormComplete = () => {
        return (
            AIFormData.name &&
            AIFormData.email &&
            AIFormData.phone &&
            AIFormData.company &&
            AIFormData.projectDetails
        );
    };

    const [error, setError] = useState(null);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setAIFormData({
            ...AIFormData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    // GOOGLE SHEET INTIGRATION

    const sheetName = "HirePageSheet"

    const state = {
        Name: AIFormData.name,
        Email: AIFormData.email,
        Phone: AIFormData.phone,
        Company: AIFormData.company,
        ProjectDetails: AIFormData.projectDetails,
        Path: myPath.replace(/[-/]/g, ' ')
            .split(' ')
            .map(word =>
                word.charAt(0).toUpperCase() + word.slice(1)
            )
            .join(' '),
        SheetName: sheetName,
    }

    const handleSubmit = async (e) => {

        e.preventDefault();

        console.log(state, "state data");

        setLoading(true)

        const formData = new FormData();
        for (const key in state) {
            if (state.hasOwnProperty(key)) {
                const value = state[key];

                if (Array.isArray(value)) {
                    for (let i = 0; i < value.length; i++) {
                        formData.append(`${key}[${i}]`, value[i]);
                    }
                } else {
                    formData.append(key, value);
                }
            }
        }

        fetch(
            "https://script.google.com/macros/s/AKfycbxM1oWweWu_LvIrcz27z31De6OYDf6Rn5JqBy48NwCmAwEHnpOqR9cNSq4tfqydt9cO/exec",
            {
                method: "POST",
                body: formData,
                mode: 'no-cors',
            }
        )
            .then((data) => {
                console.log(data);
                setLoading(false);
                notify();
                setAIFormData({
                    name: '',
                    email: '',
                    phone: '',
                    company: '',
                    projectDetails: '',
                })
            })
            .catch((error) => console.log(error));
    }

    return (
        <>
            <ToastContainer hideProgressBar={true} />
            <div className="contact-section-box position-relative" id='contact-from'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">

                            <div className="contact-left-image">
                                <img src="/images/ai-top-bg.svg" alt="AI Background" />
                            </div>

                            <div className="contact-text-side">
                                <div className="contact-text-box">
                                    <h1>{heading} <span>{subHeading}</span></h1>
                                </div>

                                <div className="container-fluid p-0">
                                    <div className="row">
                                        {contactSections.map((section, index) => (
                                            <div className="col-md-6" key={index}>
                                                <div className="skilled-contact-section">
                                                    <h2>{section.title}</h2>
                                                    <p className="common-text">{section.description}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="contact-side-form">
                                <div className="container p-0">

                                    <form className="row contact-side-form-box" onSubmit={handleSubmit}>
                                        {error && <div className="alert alert-danger">{error}</div>}

                                        <div className="col-md-6">
                                            <div className="form-floating">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    placeholder="Your Name"
                                                    className="form-control"
                                                    id="fName"
                                                    value={AIFormData.name}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <label htmlFor="fName">Your Name <span style={{ color: 'red' }}>*</span></label>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-floating">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    placeholder="Enter Email"
                                                    className="form-control"
                                                    id="email"
                                                    value={AIFormData.email}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <label htmlFor="email">Enter Your Email <span style={{ color: 'red' }}>*</span></label>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form-floating">
                                                <input
                                                    type="number"
                                                    name="phone"
                                                    placeholder="Enter Phone Number"
                                                    className="form-control"
                                                    id="phone"
                                                    value={AIFormData.phone}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <label htmlFor="phone">Enter Phone Number <span style={{ color: 'red' }}>*</span></label>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form-floating">
                                                <input
                                                    type="text"
                                                    name="company"
                                                    placeholder="Enter Company Name"
                                                    className="form-control"
                                                    id="company"
                                                    value={AIFormData.company}
                                                    onChange={handleChange}
                                                />
                                                <label htmlFor="company">Enter Company Name</label>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form-floating">
                                                <input
                                                    type="text"
                                                    name="projectDetails"
                                                    placeholder="Project Details"
                                                    className="form-control"
                                                    id="details"
                                                    value={AIFormData.projectDetails}
                                                    onChange={handleChange}
                                                />
                                                <label htmlFor="details">Project Details <span style={{ color: 'red' }}>*</span></label>
                                            </div>
                                        </div>

                                        <div className="col-md-5 contact-section-button">
                                            <button type="submit" disabled={!isFormComplete()} className="btn btn-primary">{loading ? 'Loading...' : 'Submit'}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AIContactSection
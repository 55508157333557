import React from 'react'
import './style.css'

const BrandImages = () => {

    const brandImages = [
        // {
        //     src: 'pasha-logo-2.svg',
        //     alt: 'Brand Icon 1',
        //     width: 'Brand-Icon-1'
        // },
        // {
        //     src: 'pasha-logo-3.svg',
        //     alt: 'Brand Icon 2',
        //     width: 'Brand-Icon-2'
        // },
        // {
        //     src: 'pseb.svg',
        //     alt: 'Brand Icon 3',
        //     width: 'Brand-Icon-3'
        // },
        // {
        //     src: 'payoneer-2.svg',
        //     alt: 'Brand Icon 4',
        //     width: 'Brand-Icon-4'
        // },
        {
            src: 'clutch-footer.svg',
            alt: 'Brand Icon 5',
            width: 'Brand-Icon-5'
        },
        // {
        //     src: 'pasha.svg',
        //     alt: 'Brand Icon 5',
        //     width: 'Brand-Icon-6'
        // }
    ];

    return (
        <>
            <div className="brand-images-box-main" id='globally-recognized'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 p-0">
                            <div className="black-box-main">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12 ">
                                            <div className="flex-box-main-side">
                                                <div className="brand-images-text-box mb-5">
                                                    <h1 className="main-heading">World-Class Services, Globally Recognized</h1>
                                                    <p className="common-text">Over 7 awards, accolades, and achievements showcase our quality and commitment to client success.</p>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="col-md-12">
                                            <div className="brand-images-box">
                                                <div className="container">
                                                    <div className="row justify-content-center">
                                                        {
                                                            brandImages.map((item) => {
                                                                return <div className="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4 py-3">
                                                                    <div className={`brand-image ${item.width}`}>
                                                                        <img src={`/images/${item.src}`} alt={`${item.alt}`} />
                                                                    </div>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}

                                        <div className="col-md-9">
                                            <div className="circle-boxes-main-box">
                                                <div className="circle-boxes-here relative">
                                                    <div className="circle-box-round circle-text relative">
                                                        <h2>300 +</h2>
                                                        <p>projects<br />delivered</p>
                                                    </div>

                                                    <svg viewBox="0 0 100 100" className="animate-spin-slow site-paragraph-s md:site-paragraph-m absolute inset-0 box-border aspect-square overflow-visible p-[0.625em] tracking-[6px] md:font-medium">
                                                        <path id="circle-path" fill="none" d="M0,50a50,50 0 1,1 100,0a50,50 0 1,1 -100,0"></path>
                                                        <text className="origin-center">
                                                            <textPath className="fill-white text-[0.625em]" textLength="292" href="#circle-path">
                                                                projects executed successfully
                                                            </textPath>
                                                        </text>
                                                    </svg>
                                                </div>

                                                <div className="circle-boxes-here relative">
                                                    <div className="circle-box-round circle-text relative">
                                                        <h2>10 +</h2>
                                                        <p>industry<br />sectors</p>
                                                    </div>

                                                    <svg viewBox="0 0 100 100" className="animate-spin-slow site-paragraph-s md:site-paragraph-m absolute inset-0 box-border aspect-square overflow-visible p-[0.625em] tracking-[6px] md:font-medium">
                                                        <path id="circle-path" fill="none" d="M0,50a50,50 0 1,1 100,0a50,50 0 1,1 -100,0"></path>
                                                        <text className="origin-center">
                                                            <textPath className="fill-white text-[0.625em]" textLength="292" href="#circle-path">
                                                                projects executed successfully
                                                            </textPath>
                                                        </text>
                                                    </svg>
                                                </div>

                                                <div className="circle-boxes-here relative">
                                                    <div className="circle-box-round circle-text relative">
                                                        <h2>4.9/5</h2>
                                                        <p>client<br />rating</p>
                                                    </div>

                                                    <svg viewBox="0 0 100 100" className="animate-spin-slow site-paragraph-s md:site-paragraph-m absolute inset-0 box-border aspect-square overflow-visible p-[0.625em] tracking-[6px] md:font-medium">
                                                        <path id="circle-path" fill="none" d="M0,50a50,50 0 1,1 100,0a50,50 0 1,1 -100,0"></path>
                                                        <text className="origin-center">
                                                            <textPath className="fill-white text-[0.625em]" textLength="292" href="#circle-path">
                                                                projects executed successfully
                                                            </textPath>
                                                        </text>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="brand-images-box">
                                                <div className="container">
                                                    <div className="row justify-content-center">
                                                        {
                                                            brandImages.map((item) => {
                                                                return <div className="col-xl-12 col-lg-12 col-md-12 py-3">
                                                                    <div className={`brand-image mt-4 clutch-image-box ${item.width}`}>
                                                                        <a href="https://clutch.co/profile/bitsclan-it-solutions?utm_source=widget&utm_medium=2&utm_campaign=widget&utm_content=logo&utm_term=bitsclan.com#highlights" target='_blank'>
                                                                            <img src={`/images/${item.src}`} alt={`${item.alt}`} />
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BrandImages
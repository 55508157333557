import React from 'react'
import MegaHeader from '../components/MegaHeader/MegaHeader'
import Footer from '../components/footer/Footer'
import BlogSection from '../components/blogs-section/BlogSection'

const Blogs = () => {
    return (
        <>
            <MegaHeader />
            <BlogSection/>
            <Footer/>
        </>
    )
}

export default Blogs
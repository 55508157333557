import React, { useRef, useCallback } from 'react'
import './style.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Autoplay } from 'swiper/modules';
import { Link } from 'react-router-dom';

const HirePageBanner = ({ mainHeading, btnText, bannerList, bgGradientClass, content, secBtnText, greenHeading, image, diffBannerBackgroundImage, contactUsLink }) => {
    console.log(diffBannerBackgroundImage, "bg image heredhhhhhhhh");

    return (
        <>
            <div className={`hire-banner-box ${bgGradientClass ? bgGradientClass : ""}`} style={{ backgroundImage: `${diffBannerBackgroundImage}` }}>
                <div className="container">
                    <div className="row reverse-box-main align-items-center">
                        <div className="col-xl-6 col-lg-6 col-md-12">
                            <h1 className="hire-us-banner-heading mb-1">{mainHeading}</h1>
                            <h2 className='banner-sub-heading'>{content}</h2>
                            <div className="banner-text-list">
                                {bannerList?.map((item, index) => {
                                    return <p key={index}>{item.list}</p>;
                                })}
                            </div>

                            <div className="hire-page-banner-button mt-3">
                                {secBtnText && <div className="space-top-banner-hire-page me-3">
                                    <a href="https://calendly.com/bitsclan-it/30min?month=2025-01" target='_blank' className='banner-button-box'>{secBtnText}</a>
                                </div>}

                                <div className="space-top-banner-hire-page">
                                    <Link id='hire-experts-banner-btn' to="https://calendly.com/bitsclan-it/30min?month=2025-01" target='_blank' className='banner-button-box'>{btnText}</Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="banner-vedio-side-image">
                                {/* <img src={`/images/hire-page-images/${image}`} /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HirePageBanner
import React, { useState } from "react";
import "./style.css";

const Contact = () => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    company: "",
    description: "",
  });

  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formUrl =
      "https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=00DfI000009BJ82";
    const oid = "00DfI000009BJ82";

    const payload = new FormData();
    payload.append("oid", oid);
    payload.append("first_name", formData.first_name);
    payload.append("last_name", formData.last_name);
    payload.append("email", formData.email);
    payload.append("company", formData.company);
    payload.append("description", formData.description);
    payload.append("retURL", "https://bitsclan.com/"); // Empty prevents redirection

    try {
      const response = await fetch(formUrl, {
        method: "POST",
        body: payload,
        mode: "no-cors", // Avoid CORS issues
      });

      console.log(response);

      setSubmitted(true);
    } catch (error) {
      console.error("Error submitting form", error);
    }
  };

  return (
    <div>
      {submitted ? (
        <p>Thank you! Your lead has been submitted.</p>
      ) : (
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <div className="card card-body">
                <h1>Lead Generation Form</h1>
                <form onSubmit={handleSubmit}>
                  <div className="contact-us-form-feild-wrapper">
                    <input
                      type="text"
                      name="first_name"
                      className="contact-form-input"
                      placeholder="First Name"
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="contact-us-form-feild-wrapper">
                    <input
                      type="text"
                      name="last_name"
                      placeholder="Last Name"
                      className="contact-form-input"
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="contact-us-form-feild-wrapper">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      className="contact-form-input"
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="contact-us-form-feild-wrapper">
                    <input
                      type="text"
                      name="company"
                      className="contact-form-input"
                      placeholder="Company"
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="contact-us-form-feild-wrapper">
                    <textarea
                      name="description"
                      placeholder="Description"
                      className="contact-form-input"
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  <button className="btn btn-success" type="submit">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Contact;

import Home from "./pages/Home";
import AiDevelopment from "./pages/AiDevelopment";
import CustomDevelopment from "./pages/CustomDevelopment";
import WebAppServices from "./pages/WebAppServices";
import MobileDevelopmentServices from "./pages/MobileDevelopmentServices";
import DevOpsServices from "./pages/DevOpsServices";
import SoftwareQualityAssurance from "./pages/SoftwareQualityAssurance";
import DataAndAnalytics from "./pages/DataAndAnalytics";
import BlockChainAnalytics from "./pages/BlockChainAnalytics";
import CyberSecurity from "./pages/CyberSecurity";
import ContactUs from "./pages/ContactUs";
import Industry from "./pages/Industry";
import LifeAtBitsclan from "./pages/LifeAtBitsclan";
import HireRubyAndRails from "./pages/HireRubyAndRails";
import HireDjango from "./pages/HireDjango";
import HirePython from "./pages/HirePython";
import HireGoLang from "./pages/HireGoLang";
import HireJavaScript from "./pages/HireJavaScript";
import HireReact from "./pages/HireReact";
import HireAngular from "./pages/HireAngular";
import HireFluter from "./pages/HireFluter";
import HireAndroid from "./pages/HireAndroid";
import HireMernStack from "./pages/HireMernStack";
import HireNode from "./pages/HireNode";
import HireContactPage from "./pages/HireContactPage";
import BlogDetails from "./pages/BlogDetails";
import CaseStudyDetial from "./pages/CaseStudyDetial";
import Potfoliio from "./pages/Potfoliio";
import IndustryDetail from "./pages/IndustryDetail";
import NewHome from "./pages/NewHome";
import NewBlogDetails from "./pages/NewBlogDetails";
import EngagmentModel from "./pages/EngagmentModel";

import Contact from "./components/contactForm/Contact";
import UsaHome from "./pages/UsaHome";
import KsaHome from "./pages/KsaHome";
import AustraliaHome from "./pages/AustraliaHome";
import Blogs from "./pages/Blogs";
export const routes = [
    {path: "/", element: <NewHome/>},
//   { path: "/home", element: <Home /> },
  { path: "/life-at-bitsclan", element: <LifeAtBitsclan /> },
  { path: "/USA", element: <UsaHome /> },
  { path: "/KSA", element: <KsaHome /> },
  { path: "/AUS", element: <AustraliaHome /> },
  {
    path: "/artificial-intelligence-development-services",
    element: <AiDevelopment />,
  },
  {
    path: "/custom-software-development-services",
    element: <CustomDevelopment />,
  },
  { path: "/web-app-development-services", element: <WebAppServices /> },
  {
    path: "/mobile-app-development-services",
    element: <MobileDevelopmentServices />,
  },
  { path: "/dev-ops-services", element: <DevOpsServices /> },
  {
    path: "/quality-assurance-services",
    element: <SoftwareQualityAssurance />,
  },
  { path: "/data-analytics-services", element: <DataAndAnalytics /> },
  {
    path: "/blockchain-development-services",
    element: <BlockChainAnalytics />,
  },
  { path: "/cyber-security-services", element: <CyberSecurity /> },
  { path: "/contact-us", element: <ContactUs /> },
  { path: "/Industries", element: <Industry /> },
  { path: "/life-at-bitsclan", element: <LifeAtBitsclan /> },
  { path: "/hire-ruby-on-rails-developer", element: <HireRubyAndRails /> },
  { path: "/hire-django-developer", element: <HireDjango /> },
  { path: "/hire-python-developer", element: <HirePython /> },
  { path: "/hire-golang-developer", element: <HireGoLang /> },
  { path: "/hire-javascript-developer", element: <HireJavaScript /> },
  { path: "/hire-react-developer", element: <HireReact /> },
  { path: "/hire-angular-developer", element: <HireAngular /> },
  { path: "/hire-flutter-developer", element: <HireFluter /> },
  { path: "/hire-android-developer", element: <HireAndroid /> },
  { path: "/hire-mern-stack-developer", element: <HireMernStack /> },
  { path: "/hire-node-developer", element: <HireNode /> },
  { path: "/hire-contact", element: <HireContactPage /> },
  { path: "/blog-details/:id", element: <BlogDetails /> },
  { path: "/case-study-details/:slug", element: <CaseStudyDetial /> },
  { path: "/portfolio", element: <Potfoliio /> },
  { path: "/industry-detail/:slug", element: <IndustryDetail /> },
  { path: "/newhome", element: <Home /> },
  { path: "/engagment-model", element: <EngagmentModel /> },
  { path: "/test", element: <Contact /> },
  { path: "/blogs", element: <Blogs /> },
];

import React from "react";
import HirePageBanner from "../components/HirePageBanner/HirePageBanner";
import DevelopmentPerformance from "../components/DevelopmentPerformance/DevelopmentPerformance";
import SecondFaq from "../components/secondFaq/SecondFaq";
import Footer from "../components/footer/Footer";
import MegaHeader from "../components/MegaHeader/MegaHeader";
import { Helmet } from "react-helmet";
import {
  HireAndroidBannerList,
  HireAndroidFactsData,
  HireAndroidFaqData,
  HireAndroidHirignDetails,
  HireAndroidServicesData,
  HireAndroidSolutionsData,
  HireAndroidSteps,
  mainBannerImg,
  tabItems,
  tabs,
  techCombinations,
} from "../data/hireAndroidPageData";
import HirePageExperties from "../components/hirepageExperties/HirePageExperties";
import HirePageSteps from "../components/hire-page-steps/HirePageSteps";
import HireExpertFacts from "../components/hire-expert-facts/HireExpertFacts";
import HirePageEngagmentModels from "../components/hire-page-engagment-models/HirePageEngagmentModels";
import HirePageAppIdea from "../components/hire-page-app-idea/HirePageAppIdea";
import ContactSection from "../components/contactSection/ContactSection";
import TrendsInsights from "../components/trends-n-insights/TrendsInsights";
import HireCombinationSection from "../components/hire-combination-sectiono/HireCombinationSection";
import CoverTheStack from "../components/coverthestack/CoverTheStack";

const HireAndroid = () => {


  return (
    <>
      <Helmet>
        <title>Hire Android Developers | Custom Mobile App Development</title>
        <meta
          name="description"
          content="Looking to hire Android developers? Work with experienced professionals to build innovative, user-friendly Android apps. Contact us today to start your project! "
        />
      </Helmet>

      <div className="sticky-header">
        <MegaHeader />
      </div>

      <HirePageBanner
        diffBannerBackgroundImage={mainBannerImg?.backgroundImage}
        image={""}
        mainHeading="Hire Android App Developers"
        content="Your trusted partner for expert Android app development services, tailored for startups and enterprises. Build high-performance, scalable, and innovative mobile applications with our skilled developers."
        btnText="Consult Our Android Developers Today"
        bannerList={HireAndroidBannerList}
      />

      <HirePageExperties
        heading="Our Android Developer Expertise"
        discription="Our Android developers specialize in building high-performance, intuitive, and feature-rich mobile applications, ensuring seamless functionality and an outstanding user experience on all Android devices."
        servicesData={HireAndroidServicesData}
      />

      <DevelopmentPerformance
        heading="Why Choose Bitsclan to Hire "
        greenHeading="Android Developers?"
        subHeading="We leverage the latest Android technologies to develop powerful, scalable, and customized applications that align with your business goals. Whether for mobile or web platforms, we ensure seamless performance, faster development, and an exceptional user experience for your Android apps."
        solutions={HireAndroidSolutionsData}
      />

      <HireCombinationSection combinations={techCombinations} heading="Top Android Technology Combinations for Superior Development" description="Leverage the power of cutting-edge Android technologies by hiring expert developers. Unlock seamless performance, scalability, and innovation with the best technology combinations for your Android solutions." />

      <HirePageSteps
        heading={"Hire Android Developers in 4 Easy Steps"}
        subHeading={
          "Hiring Android developers is quick and hassle-free with our streamlined 4-step process. Find the perfect match for your project in no time."
        }
        steps={HireAndroidSteps}
      />

      <CoverTheStack
        whereStart={"Tools & Utilities"}
        mainHeading={"Hire Expert Android Developers for Scalable & High-Performance Apps"}
        subHeading={"At Bitsclan, we empower your Android app development journey with a meticulously curated tech stack that ensures efficiency and scalability. Our experienced Android developers leverage cutting-edge technologies to build custom Android applications tailored to your business needs, delivering seamless performance and user engagement."}
        tabs={tabs}
        tabItems={tabItems} />

      <HireExpertFacts
        heading="Quick Facts About "
        greenHeading="Our Android Team"
        facts={HireAndroidFactsData}
        diffBannerBackgroundImage={mainBannerImg?.factBackgroundImage}
      />

      {/* <WhoWeServe
        cardContent={HireAndroidWhoWeServeData}
        heading="Benefits of outsourced Android development"
        subHeading="Our business partners always expect minimal risks and professionalism in Android development. We are dedicated to providing our clients with reliable and highly skilled developers who are capable of either building a product from scratch or enhancing one that already exists. Here are the key benefits of outsourcing Ruby on Rails to Innowise."
      /> */}

      <HirePageAppIdea content="Have an idea but unsure how to proceed? Our team helps you turn your vision into a working prototype within 6-8 weeks. This allows you to validate your concept, test user engagement, and build a strong foundation for your project’s success—all with the expertise of our Ruby on Rails development services." />
      <HirePageEngagmentModels description="Choose the engagement model that fits your project needs! Whether you need full-time, part-time, or hourly developers, we offer flexible options to ensure seamless collaboration and maximum efficiency throughout your project." hirignDetails={HireAndroidHirignDetails} />


      {/* <HirePageWeDeliver hirePageDeliver={HireAndroidPageDeliverData} />

      <HirePageTabs /> */}

      <TrendsInsights specificClass="mobile-dev-page" category="all" />

      <SecondFaq
        specificClass="mobile-dev-page"
        faqs={HireAndroidFaqData}
        mainHeading={"Frequently Asked Questions"}
        subHeading={"Discover answers to common questions about Android Development."}
      />

      {/* <HirePageServicesForm /> */}

      <ContactSection />

      <Footer />
    </>
  );
};

export default HireAndroid;

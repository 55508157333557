import { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import CookiesPolicyModal from "../modals/cookies-policy-modal";
import SubmitEmailModal from "../modals/submit-email-modal";

const cookies = new Cookies();
function ModalWrapper({setIsAccepted}) {
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showCookieModal, setShowCookieModal] = useState(false);

  useEffect(() => {
    // Show the email modal when the component mounts
    const isAccepted = cookies.get("emailAccepted");
    if (!isAccepted) {
      setTimeout(() => {
        setShowEmailModal(true);
      }, 20000);
    }
    handleOpenCookieModal();
  }, []);

  const handleEmailModalClose = () => {
    setShowEmailModal(false);
  };

  const handleOpenCookieModal = () => {
    // Show the cookie modal after the email modal is closed
    const isAccepted = cookies.get("cookiesAccepted");

    if (!isAccepted) {
      fetch("https://ipinfo.io/json")
        .then((response) => response.json())
        .then((data) => {
          console.log(data.country);
          if (data.country !== "PK") {
            setShowCookieModal(true);
          }
        })
        .catch((error) => console.error("Error:", error));
    }
  };
  const handleCookieModalClose = () => {
    setShowCookieModal(false);
    setIsAccepted(cookies.get("cookiesAccepted"))
  };
  return (
    <>
      <SubmitEmailModal
        isOpen={showEmailModal}
        onClose={handleEmailModalClose}
      />
      <CookiesPolicyModal
        isOpen={showCookieModal}
        onClose={handleCookieModalClose}
      />
    </>
  );
}

export default ModalWrapper;

import React from 'react'

const HirePageExperties = ({ servicesData, heading, discription }) => {
    return (
        <>
            <div className="bitsclan-ai-box" style={{ padding: "60px 0px 90px 0px" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="main-heading">{heading}</h1>
                            <h3 className="sub-heading">{discription}</h3>
                        </div>
                    </div>

                    <div className="row">
                        {servicesData?.map((item, index) => {
                            return <div className="col-xl-4 col-lg-4 col-md-6">
                                <div className="quality-matter-box">
                                    <div className="construction-icon">
                                        <img src={item?.icon} alt="Icon" />
                                    </div>

                                    <div className="construction-card-text quality-text-box">
                                        <h2><span>{item?.title}</span></h2>
                                        <p className="common-text">{item?.description}</p>
                                    </div>
                                </div>
                            </div>
                        })}

                        <div className="col-xl-4 col-lg-4 col-md-6">
                            <div className="quality-matter-box quality-box-main-blue">
                                <div className="construction-card-text quality-text-box quality-button-box">
                                    <a className='hire-expertise-schedule-a-call' href="https://calendly.com/bitsclan-it/30min?month=2025-01" target='_blank'>Schedule a Call Today<i class="fa-solid fa-arrow-right-long"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HirePageExperties
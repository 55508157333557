import React from 'react'
import MegaHeader from '../components/MegaHeader/MegaHeader'
import Footer from '../components/footer/Footer'
import HirePageBanner from '../components/HirePageBanner/HirePageBanner'
import UnderStandEngagmentModel from '../components/UnderstandEngagmentModel/UnderStandEngagmentModel'
import EngagmentModelScroller from '../components/engagment-model-scroller/EngagmentModelScroller'
import ContactSection from '../components/contactSection/ContactSection'
import { bannerList, mainBannerImg } from '../data/engagement-model-data'

const EngagmentModel = () => {
    return (
        <>
            <MegaHeader />
            <HirePageBanner diffBannerBackgroundImage={mainBannerImg?.backgroundImage}
                mainHeading="Engagement Models in Software Development"
                greenHeading="Our Approach to the Engagement Process."
                content="Bitsclan offers a set of different engagement models to provide you with the most convenient way of working on your project. You are welcome to choose a model from the mentioned below or suggest your own."
                btnText="Get Started"
                bannerList={bannerList} />
            <UnderStandEngagmentModel />
            <EngagmentModelScroller />
            <ContactSection />
            <Footer />
        </>
    )
}

export default EngagmentModel
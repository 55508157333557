import React from 'react'
import './style.css'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <>
            <div className="footer-box">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-4">
                            <h1 className="footer-heading">Services</h1>

                            <ul className='footer-list'>
                                <li><Link to="/artificial-intelligence-development-services">AI Development</Link></li>
                                <li><Link to="/custom-software-development-services">Custom Development</Link></li>
                                <li><Link to="/data-analytics-services">Data Analytics</Link></li>
                                <li><Link to="/blockchain-development-services">BlockChain Development</Link></li>
                                <li><Link to="/cyber-security-services">Cyber Security</Link></li>
                                <li><Link to="/web-app-development-services">Web Application Development</Link></li>
                                <li><Link to="/mobile-app-development-services">Mobile App Development</Link></li>
                                <li><Link to="/dev-ops-services">DevOps Services</Link></li>
                                <li><Link to="/quality-assurance-services">Quality Assurance Services</Link></li>
                            </ul>
                        </div>

                        <div className="col-xl-3 col-lg-3 col-md-4">
                            <h1 className="footer-heading">Company</h1>

                            <ul className='footer-list'>
                                <li><Link to="/life-at-bitsclan">Life at Bitsclan</Link></li>
                                <li><Link to="/contact-us">Contact Us</Link></li>
                                <li><Link to="/engagment-model">Enagagement Models</Link></li>
                                <li><Link to='/portfolio'>Portfolio</Link></li>
                                <li><Link to='/blogs'>Blogs</Link></li>
                                <ul className='footer-list footer-list-box-text'>
                                    <li>
                                        <Link to="/contact-us">
                                            <span className="active-box">
                                                Contact Us{" "}
                                                <i class="fa-solid fa-arrow-right-long"></i>
                                            </span>
                                        </Link>
                                    </li>
                                </ul>
                            </ul>
                        </div>

                        <div className="col-xl-2 col-lg-2 col-md-4">
                            <h1 className="footer-heading">Industries</h1>

                            <ul className='footer-list footer-list-industries-scroller'>
                                <li><Link to='/industry-detail/healthcare'>Healthcare</Link></li>
                                <li><Link to='/industry-detail/retail-ecommerce'>Ecommerce</Link></li>
                                <li><Link to='/industry-detail/fintech'>FinTech</Link></li>
                                <li><Link to='/industry-detail/education'>EdTech</Link></li>
                                <li><Link to='/industry-detail/real-estate'>Real Estate</Link></li>
                                <li><Link to='/industry-detail/insurance'>Insurance</Link></li>
                                <li><Link to='/industry-detail/logistics'>Logistics & Transportation</Link></li>
                                <li><Link to='/industry-detail/automotive'>Automotive </Link></li>
                                <li><Link to='/industry-detail/gaming'>Gaming </Link></li>
                                <li><Link to='/industry-detail/entertainment'>Entertainment </Link></li>
                                <li><Link to='/industry-detail/social-networking'>Socail Networking </Link></li>
                                <li><Link to='/industry-detail/telecom'>Telecom </Link></li>
                                <li><Link to='/industry-detail/energy'>Energy </Link></li>
                            </ul>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-12">
                            <div className="trust-box-text">
                                <h1>Trusted By</h1>

                                <div className="trust-logo-box">
                                    <a href="https://clutch.co/profile/bitsclan-it-solutions?utm_source=widget&utm_medium=2&utm_campaign=widget&utm_content=logo&utm_term=bitsclan.com#highlights">
                                        <img src="/images/clutch-footer.svg" alt="Clutch Footer Logo" />
                                    </a>

                                    <a href="https://www.youtube.com/watch?v=LDmv2CrkM1c&ab_channel=BitsclanITSolutions">
                                        <img src="/images/payoneer-footer.svg" alt="Payoneer Footer Logo" />
                                    </a>
                                </div>
                            </div>

                            <div className="get-social-text">
                                <h1>Get Social</h1>

                                <ul className="social-icon">
                                    <li><a href="https://www.facebook.com/bitsclan" target='_blank'><i class="fa-brands fa-square-facebook"></i></a></li>
                                    <li><a href="https://www.linkedin.com/company/bitsclanitsolutions" target='_blank'><i class="fa-brands fa-linkedin"></i></a></li>
                                    <li><a href="https://www.instagram.com/bitsclansolutions/" target='_blank'><i class="fa-brands fa-square-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row border-top-footer">
                        <div className="col-md-12">
                            <div className="copyright-box-main">
                                <p>© 2025 All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="comingsoon-model" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
                <div class="modal-dialog shedule-call-model-box modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class="fa-solid fa-xmark"></i></button>
                        </div>
                        <div class="modal-body">
                            <div className="d-flex justify-content-center">
                                <img src="/images/logo.png" width={300} alt="Logo" />
                            </div>
                            <h1 style={{ fontSize: '42px', textAlign: 'center', color: '#000000' }} className='mt-2'>Coming Soon,</h1>
                            <h1 style={{ fontSize: '42px', textAlign: 'center', color: '#000000' }}>We Are Working On It.</h1>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer
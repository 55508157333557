export const tabs = [
  "Generative AI Models",
  "Modules/Toolkits",
  "Deep Learning (DL) Frameworks",
  "Neural Networks",
  "Libraries",
  "Image Classification Models",
];

export const tabItems = [
  {
    tab: "Generative AI Models",
    icon: "/images/genai1.svg",
    name: "CycleGAN",
  },
  {
    tab: "Generative AI Models",
    icon: "/images/genai2.svg",
    name: "DeepArt",
  },
  {
    tab: "Generative AI Models",
    icon: "/images/genai3.svg",
    name: "DeepDream",
  },
  { tab: "Generative AI Models", icon: "/images/genai4.svg", name: "VQ-VAE" },
  { tab: "Generative AI Models", icon: "/images/genai5.svg", name: "GPT-4" },
  { tab: "Generative AI Models", icon: "/images/genai6.svg", name: "GPT-3" },
  { tab: "Generative AI Models", icon: "/images/genai6.svg", name: "DALL-E" },
  { tab: "Modules/Toolkits", icon: "/images/genai6.svg", name: "OpenAI GPT" },
  {
    tab: "Modules/Toolkits",
    icon: "/images/genai6.svg",
    name: "OpenAI DALL-E",
  },
  {
    tab: "Modules/Toolkits",
    icon: "/images/module1.svg",
    name: "DeepMind Sonnet",
  },
  {
    tab: "Modules/Toolkits",
    icon: "/images/module2.svg",
    name: "Facebook AI Research ",
  },
  {
    tab: "Modules/Toolkits",
    icon: "/images/module3.svg",
    name: "Hugging Face",
  },
  {
    tab: "Modules/Toolkits",
    icon: "/images/module4.svg",
    name: "NVIDIA StyleGAN",
  },
  {
    tab: "Deep Learning (DL) Frameworks",
    icon: "/images/tech-icon.svg",
    name: "PyTorch",
  },
  {
    tab: "Deep Learning (DL) Frameworks",
    icon: "/images/tech-icon2.svg",
    name: "Keras",
  },
  {
    tab: "Deep Learning (DL) Frameworks",
    icon: "/images/tech-icon3.svg",
    name: "MXNet",
  },
  {
    tab: "Deep Learning (DL) Frameworks",
    icon: "/images/tech-icon4.svg",
    name: "TensorFlow",
  },
  {
    tab: "Deep Learning (DL) Frameworks",
    icon: "/images/tech-icon5.svg",
    name: "Caffe",
  },
  { tab: "Neural Networks", icon: "/images/natural1.svg", name: "RBM" },
  { tab: "Neural Networks", icon: "/images/natural2.svg", name: "DBN" },
  {
    tab: "Neural Networks",
    icon: "/images/natural7.svg",
    name: "Transformers",
  },
  {
    tab: "Neural Networks",
    icon: "/images/natural4.svg",
    name: "Autoencoders",
  },
  { tab: "Neural Networks", icon: "/images/natural5.svg", name: "DRLN" },
  { tab: "Neural Networks", icon: "/images/natural6.svg", name: "GAN" },
  { tab: "Libraries", icon: "/images/tech-icon.svg", name: "PyTorch" },
  { tab: "Libraries", icon: "/images/tech-icon2.svg", name: "Keras" },
  { tab: "Libraries", icon: "/images/tech-icon3.svg", name: "MXNet" },
  { tab: "Libraries", icon: "/images/libraries1.svg", name: "FastAI" },
  { tab: "Libraries", icon: "/images/libraries2.svg", name: "TensorFlow" },
  { tab: "Libraries", icon: "/images/libraries3.svg", name: "Theano" },
  { tab: "Libraries", icon: "/images/libraries4.svg", name: "Scikit-learn" },
  {
    tab: "Image Classification Models",
    icon: "/images/calsification.svg",
    name: "AlexNet",
  },
  {
    tab: "Image Classification Models",
    icon: "/images/calsification2.svg",
    name: "EfficientNet",
  },
  {
    tab: "Image Classification Models",
    icon: "/images/calsification3.svg",
    name: "Xception",
  },
  {
    tab: "Image Classification Models",
    icon: "/images/calsification4.svg",
    name: "DenseNet",
  },
  {
    tab: "Image Classification Models",
    icon: "/images/calsification5.svg",
    name: "Inception",
  },
  {
    tab: "Image Classification Models",
    icon: "/images/calsification6.svg",
    name: "ResNet",
  },
  {
    tab: "Image Classification Models",
    icon: "/images/calsification7.svg",
    name: "VGGNet",
  },
];

export const faqData = [
  {
    id: "collapseOne",
    question:
      "What is Data Analytics, and why is it important?",
    answer:
      "Data analytics involves inspecting, cleaning, and modeling data to discover useful information, support decision-making, and improve business operations. It helps businesses optimize performance, predict trends, and make data-driven decisions",
  },
  {
    id: "collapseTwo",
    question:
      "How can data analytics improve my business?",
    answer:
      "With data analytics, you can unlock valuable insights from your data to understand your customers, streamline operations, and identify growth opportunities. This leads to better decision-making and improved performance.",
  },
  {
    id: "collapseThree",
    question:
      "What is the difference between big data analytics and regular analytics?",
    answer:
      "Big data analytics deals with large volumes of structured and unstructured data, often in real time. Regular analytics typically focuses on smaller data sets, offering insights from past trends rather than future predictions.",
  },
  {
    id: "fourth",
    question: "How can I ensure my data is secure when using analytics services?",
    answer:
      "We prioritize data security by using encryption, secure access protocols, and compliance with data protection regulations. Our services ensure that your sensitive information is well-protected during analysis.",
  },
  {
    id: "fifth",
    question: "Do you provide real-time analytics?",
    answer:
      "Yes, we offer real-time data analytics that helps you monitor key business metrics instantly. This allows for faster decision-making and better response to changing market conditions.",
  },
  {
    id: "sixth",
    question: "What industries can benefit from your data analytics services?",
    answer:
      "Our services cater to a wide range of industries, including healthcare, finance, retail, manufacturing, and more. We tailor solutions to meet the unique needs of each sector, helping businesses optimize operations and stay competitive.",
  },
  {
    id: "seventh",
    question: "Do I need specialized software to use data analytics services?",
    answer:
      "No, we offer custom solutions that can integrate seamlessly with your existing software systems. Our team ensures smooth implementation, so you can get the most out of your data without needing specialized tools.",
  },
];

export var mainBannerImg = {
  backgroundImage: `url(/images/what-we-do/data-and-analytics-services.png)`,
};


export const DataAndAnalyticsServicesData = [
  {
    id: "Tab1",
    label: "Data Analytics and Visualization",
    content:
      "Unlock the power of your data with our advanced analytics and visualization services. We transform raw data into actionable insights using cutting-edge tools and techniques. Our solutions help you make data-driven decisions and present complex information in an easy-to-understand visual format.",
    btnText: "Let's Discuss",
    btnLink: "#",
    imgs: [
      {
        img: "/images/services-icons/data-analytics/sql.png",
      },
      {
        img: "/images/services-icons/data-analytics/AirflowLogo.png",
      },
      {
        img: "/images/services-icons/data-analytics/Apache_Spark_logo.png",
      },
      {
        img: "/images/services-icons/data-analytics/Superset.png",
      },
    ],
  },
  {
    id: "Tab2",
    label: "Big Data Solutions",
    content:
      "Harness the potential of big data with our scalable and efficient solutions. We design and implement big data architectures that process, store, and analyze massive datasets. Our services enable you to extract valuable insights and stay ahead in the data-driven world.",
    btnText: "Let's Discuss",
    btnLink: "#",
    imgs: [
      {
        img: "/images/services-icons/data-analytics/sql.png",
      },
      {
        img: "/images/services-icons/data-analytics/AirflowLogo.png",
      },
      {
        img: "/images/services-icons/data-analytics/Apache_Spark_logo.png",
      },
      {
        img: "/images/services-icons/data-analytics/Superset.png",
      },
    ],
  },
  {
    id: "Tab3",
    label: "Business Intelligence (BI) Services",
    content:
      "Empower your business with our comprehensive Business Intelligence services. We create customized BI solutions that integrate data from multiple sources, providing real-time insights and interactive dashboards. Our BI tools help you monitor performance, identify trends, and drive growth.",
    btnText: "Let's Discuss",
    btnLink: "#",
    imgs: [
      {
        img: "/images/services-icons/data-analytics/sql.png",
      },
      {
        img: "/images/services-icons/data-analytics/AirflowLogo.png",
      },
      {
        img: "/images/services-icons/data-analytics/Apache_Spark_logo.png",
      },
      {
        img: "/images/services-icons/data-analytics/Superset.png",
      },
    ],
  },
  {
    id: "Tab4",
    label: "Predictive Analytics",
    content:
      "Stay ahead of the curve with our predictive analytics services. Using advanced machine learning and statistical models, we analyze historical data to forecast future trends and behaviors. Our predictive analytics solutions help you make proactive decisions and mitigate risks.",
    btnText: "Let's Discuss",
    btnLink: "#",
    imgs: [
      {
        img: "/images/services-icons/data-analytics/sql.png",
      },
      {
        img: "/images/services-icons/data-analytics/AirflowLogo.png",
      },
      {
        img: "/images/services-icons/data-analytics/Apache_Spark_logo.png",
      },
      {
        img: "/images/services-icons/data-analytics/Superset.png",
      },
    ],
  },
  {
    id: "Tab5",
    label: "Data Warehousing",
    content:
      "Build a robust foundation for your data with our data warehousing services. We design and implement centralized data repositories that store and organize your data for easy access and analysis. Our data warehousing solutions ensure data integrity, scalability, and performance.",
    btnText: "Let's Discuss",
    btnLink: "#",
    imgs: [
      {
        img: "/images/services-icons/data-analytics/sql.png",
      },
      {
        img: "/images/services-icons/data-analytics/AirflowLogo.png",
      },
      {
        img: "/images/services-icons/data-analytics/Apache_Spark_logo.png",
      },
      {
        img: "/images/services-icons/data-analytics/Superset.png",
      },
    ],
  },
  {
    id: "Tab6",
    label: "Data Integration and ETL",
    content:
      "Streamline your data workflows with our data integration and ETL (Extract, Transform, Load) services. We connect disparate data sources, transform raw data into meaningful formats, and load it into your systems. Our solutions ensure seamless data flow and consistency across your organization.",
    btnText: "Let's Discuss",
    btnLink: "#",
    imgs: [
      {
        img: "/images/services-icons/data-analytics/sql.png",
      },
      {
        img: "/images/services-icons/data-analytics/AirflowLogo.png",
      },
      {
        img: "/images/services-icons/data-analytics/Apache_Spark_logo.png",
      },
      {
        img: "/images/services-icons/data-analytics/Superset.png",
      },
    ],
  },
  {
    id: "Tab7",
    label: "Data Governance and Compliance",
    content:
      "Ensure your data is secure, accurate, and compliant with our data governance services. We help you establish policies, procedures, and frameworks to manage data effectively. Our solutions ensure compliance with industry regulations and build trust in your data assets.",
    btnText: "Let's Discuss",
    btnLink: "#",
    imgs: [
      {
        img: "/images/services-icons/data-analytics/sql.png",
      },
      {
        img: "/images/services-icons/data-analytics/AirflowLogo.png",
      },
      {
        img: "/images/services-icons/data-analytics/Apache_Spark_logo.png",
      },
      {
        img: "/images/services-icons/data-analytics/Superset.png",
      },
    ],
  },
];

export const DataAnalyticsTypesOfBuildData = [
  {
    id: "collapseOne",
    question: "Understanding and Simplifying Complex Data",
    answer: {
      discription:
        "Making sense of complex data can be a challenge, but we turn overwhelming datasets into clear, actionable insights. Our analytics services break down intricate information into easy-to-understand visuals and reports, ensuring your data tells a coherent story that supports smart business decisions.",
      // listTitle: "Our native mobile apps are best for:",
      listContent: [
        {
          line: "Transform complex data into clear, actionable insights",
        },
        {
          line: "Simplify datasets with user-friendly reports and visuals",
        },
        {
          line: "Help teams understand and leverage business intelligence",
        },
        {
          line: "Provide structured data interpretation for better decision-making",
        },
        {
          line: "Ensure insights are aligned with business goals and strategies",
        },
      ],
    },
  },
  {
    id: "collapseTwo",
    question: "Managing Data Overload",
    answer: {
      discription:
        "Too much data can be overwhelming, making it hard to focus on what matters. We help you filter, prioritize, and organize data, ensuring you only work with relevant, high-impact information. Our solutions turn excessive data into structured insights that improve efficiency and decision-making.",
      // listTitle: "Our native mobile apps are best for:",
      listContent: [
        {
          line: "Filter and prioritize data to focus on what matters most",
        },
        {
          line: "Organize unstructured information into meaningful insights",
        },
        {
          line: "Streamline data analysis for improved productivity",
        },
        {
          line: "Eliminate noise and irrelevant data for better clarity",
        },
        {
          line: "Enhance efficiency by structuring and segmenting data",
        },
      ],
    },
  },
  {
    id: "collapseThree",
    question: "Addressing Data Security Concerns",
    answer: {
      discription:
        "Data security is more important than ever, and we make sure your information stays protected. From encryption to secure storage, our analytics solutions prioritize data safety while ensuring compliance with industry regulations. We safeguard your insights so you can focus on growth.",
      // listTitle: "Our native mobile apps are best for:",
      listContent: [
        {
          line: "Implement best practices for data encryption and security",
        },
        {
          line: "Protect sensitive business and customer data from breaches",
        },
        {
          line: "Ensure compliance with data privacy and regulatory standards",
        },
        {
          line: "Prevent unauthorized access with strict security protocols",
        },
        {
          line: "Monitor and manage risks with proactive security measures",
        },
      ],
    },
  },
  {
    id: "fourth",
    question: "Integrating Disconnected Data Sources",
    answer: {
      discription:
        "Data from multiple sources often creates silos that disrupt decision-making. We bring all your scattered data together, integrating different platforms and databases into one seamless system. This ensures consistency, accuracy, and a unified view of your business operations.",
      // listTitle: "Our native mobile apps are best for:",
      listContent: [
        {
          line: "Unify disconnected data sources into one integrated system",
        },
        {
          line: "Improve accuracy by eliminating redundant and conflicting data",
        },
        {
          line: "Enhance collaboration with seamless data connectivity",
        },
        {
          line: "Ensure easy access to real-time, consolidated insights",
        },
        {
          line: "Streamline data workflows for better operational efficiency",
        },
      ],
    },
  },
  {
    id: "fifth",
    question: "Improving Decision-Making with Data Insights",
    answer: {
      discription:
        "Great decisions require strong data. Our analytics services don’t just provide reports—they deliver data-driven strategies that predict trends, optimize operations, and drive business success. We equip you with the insights needed to make confident, informed choices.",
      // listTitle: "Our native mobile apps are best for:",
      listContent: [
        {
          line: "Deliver real-time insights for smarter decision-making",
        },
        {
          line: "Identify patterns and trends with advanced data analytics",
        },
        {
          line: "Support future planning with predictive analytics models",
        },
        {
          line: "Optimize business strategies with accurate, data-backed guidance",
        },
        {
          line: "Ensure better outcomes with informed, data-driven decisions",
        },
      ],
    },
  },
];

export const AnalyticsWhoWeServeData = [
  {
    icon: "/images/servie-icon/startups.svg",
    title: "Startups",
    description:
      "Harness the power of data analytics services to make informed decisions, optimize operations, and understand customer behavior to drive growth.",
    serveList: [
      "Identify market trends through predictive analytics.",
      "Use real-time insights to refine products and services.",
      "Make data-backed decisions to scale efficiently.",
    ],
    link: "#",
    borderClass: "border-1",
  },
  {
    icon: "/images/servie-icon/companies.svg",
    title: "Tech Companies & SaaS Businesses",
    description:
      "Transform your big data analytics into a competitive edge with cutting-edge tools that enhance automation, reporting, and AI-driven insights.",
    serveList: [
      "Enhance platform intelligence with machine learning models.",
      "Optimize cloud-based analytics for performance and efficiency.",
      "Integrate real-time analytics into SaaS applications.",
    ],
    link: "#",
    borderClass: "border-2",
  },
  {
    icon: "/images/servie-icon/industries.svg",
    title: "Enterprises & Large Corporations",
    description:
      "Leverage business intelligence solutions to streamline operations, improve forecasting, and enhance overall efficiency with customized data strategies.",
    serveList: [
      "Improve financial forecasting and operational planning.",
      "Automate reporting with AI-driven business insights.",
      "Utilize scalable data models for enterprise-wide analysis."
    ],
    link: "#",
    borderClass: "border-3",
  },
];

export const AnalyticsWhyChooseBitsclanData = [
  {
    id: "Tab1",
    label: "Quality Because It Matters",
    content:
      "We deliver data analytics services with precision, ensuring high-quality insights through automated data processing, validation, and optimization.",
    btnText: "Connect with the clan",
    btnLink: "#",
  },
  {
    id: "Tab2",
    label: "The Best-of-the-Breed Talent",
    content:
      "Our team consists of data scientists, analysts, and strategists who transform raw data into actionable business intelligence solutions.",
    btnText: "Connect with the clan",
    btnLink: "#",
  },
  {
    id: "Tab3",
    label: "Time Zone Alignment",
    content:
      "We ensure seamless collaboration by aligning with your time zone, enabling real-time reporting, smooth operations, and faster decision-making.",
    btnText: "Connect with the clan",
    btnLink: "#",
  },
  {
    id: "Tab4",
    label: "Scalable & Future-Ready Solutions",
    content:
      "Our big data analytics solutions scale with your business, integrating AI-driven automation for predictive insights and long-term growth.",
    btnText: "Connect with the clan",
    btnLink: "#",
  },
  {
    id: "Tab5",
    label: "Security & Compliance First",
    content:
      "We prioritize data security, ensuring compliance with industry standards so your sensitive data remains protected and regulatory-ready.",
    btnText: "Connect with the clan",
    btnLink: "#",
  },
];

export const AnalyticsHighvaluesData = [
  {
    image: "data-anylytics-icon1.svg",
    text: "Custom Data Analytics Solutions",
  },
  {
    image: "data-anylytics-icon2.svg",
    text: "Real-Time Data Insights",
  },
  {
    image: "data-anylytics-icon3.svg",
    text: "Advanced Analytics Integration",
  },
  {
    image: "data-anylytics-icon4.svg",
    text: "Scalable Data Solutions",
  },
  {
    image: "data-anylytics-icon5.svg",
    text: "User-Centric Dashboards",
  },
  {
    image: "data-anylytics-icon6.svg",
    text: "Secure Data Processing",
  },
  {
    image: "data-anylytics-icon7.svg",
    text: "Big Data Analytics",
  },
  {
    image: "data-anylytics-icon8.svg",
    text: "Data-Driven Decision Making",
  },
  {
    image: "data-anylytics-icon9.svg",
    text: "Automated Data Analytics",
  },
  {
    image: "data-anylytics-icon10.svg",
    text: "Ongoing Data Maintenance",
  },
];


export const AnalyticsWeDoEngagementData = [
  {
    title: "Fixed Bid Model",
    text: "Know your costs upfront! We deliver custom data analytics development at a fixed price, ensuring budget control and clarity while guaranteeing a secure and efficient solution.",
    linkText: "Know More",
    linkURL: "#",
  },
  {
    title: "Milestone-Based Billing",
    text: "Stay in control with payments tied to project progress. This model ensures transparency, letting you review, approve, and pay as each phase of your data analytics solution unfolds.",
    linkText: "Know More",
    linkURL: "#",
  },
  {
    title: "Staff Augmentation",
    text: "Expand your team without the hiring hassle! Get data analytics experts on-demand, seamlessly integrating with your in-house team for decentralized app development and more.",
    linkText: "Know More",
    linkURL: "#",
  },
  {
    title: "White Labeling Partnership",
    text: "Want to launch data analytics solutions under your brand? Our white-label services let you offer high-quality smart contracts and decentralized apps with your branding.",
    linkText: "Know More",
    linkURL: "#",
  },
  {
    title: "Build-Operate-Transfer (BOT)",
    text: "We build your data analytics infrastructure, operate it efficiently, and later transfer complete ownership to you—ideal for companies looking for long-term self-sufficiency.",
    linkText: "Know More",
    linkURL: "#",
  },
  {
    title: "Dedicated Team",
    text: "Get a dedicated data analytics development team working exclusively on your project. From smart contracts to blockchain consulting, we provide continuous support and innovation.",
    linkText: "Know More",
    linkURL: "#",
  },
];
import React from 'react'
import './style.css'

const LifeBanner = () => {
    return (
        <>
            <div className="life-at-bitsclan-box">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="life-banner-box">
                                <h1>Where Dreamers Find Their Drive and Innovators <span>Become Changemakers</span></h1>
                                <p className='common-text'>At Bitsclan, we believe in fostering an environment where innovation thrives and careers flourish. Our team is a diverse group of talented professionals, united by a passion for technology and a commitment to excellence. Here, every project is a chance to push boundaries, every challenge is a pathway to growth, and every team member is empowered to lead.</p>

                                <p className='common-text'>We offer opportunities to develop your skills, and make a real impact. Discover what life at Bitsclan is all about and take the next step in your career with us.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LifeBanner
import React, { useRef, useCallback } from "react";
import "./style.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";
import { Link } from "react-router-dom";

const SimilerBanner = ({
  mainHeading,
  sideImage,
  btnText,
  btnText2,
  content,
  subHeading,
  greenHeading,
  diffBannerBackgroundImage,
}) => {
  return (
    <>
      <div className="main-banner-box" style={diffBannerBackgroundImage}>
        <div className="container">
          <div className="row">
            <div className="col-md-6 flex-center-banner-box">
              <h1 className="banner-heading">
                {mainHeading} <span>{greenHeading}</span>
              </h1>

              <h3 className="subHeading-banner">{subHeading}</h3>

              <h2 className="banner-sub-heading">{content}</h2>

              <div className="mt-2 mt-md-4 d-flex flex-wrap gap-3">
                {!btnText ? (
                  ""
                ) : (
                  <a
                    href="https://calendly.com/bitsclansolutions/15min"
                    target="_blank"
                    className="what-we-do-banner-button"
                  >
                    {btnText}
                  </a>
                )}

                {!btnText2 ? (
                  ""
                ) : (
                  <Link
                    to="/contact-us"
                    className="what-we-do-banner-button"
                    type="btn"
                  >
                    {btnText2}
                  </Link>
                )}
              </div>
            </div>

            {sideImage && (
              <div className="col-md-6 mt-5 mt-md-0">
                <div className="banner-vedio-side-image">
                  <img src={`/images/${sideImage}`} alt={`${sideImage}`} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SimilerBanner;

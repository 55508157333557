const slidesData = [
    {
        id: 1,
        slug: 'myfetetickets',
        image: "/images/general-image.png",
        title: "Transforming Event Planning",
        heading: "My Fete Tickets",
        location: "California",
        industry: "Entertainment",
        UseCase: "Event Ticketing and Sales Management",
        date: "Oct 1,2024",
        logo: "/images/feteticket.png",
        clutchLink: "https://clutch.co/profile/bitsclan-it-solutions?utm_source=widget&utm_medium=2&utm_campaign=widget&utm_content=logo&utm_term=bitsclan.com#highlights",
        clutchLogo: "/images/clutch-rating.png",
        description: "My Fete Tickets is a top-tiered ticketing platform that caters to and assists Event Planners and Promoters with the ease of ticket sales. It is our mission at My Fete Tickets to bring people together of one mind and one sound to enjoy good company, great food, and moving music. Our intent is to spread positive vibes across the nation, connecting people, one Fete Ticket at a time.",
        profileImage: "/images/SVG/FeteTICKETS.svg",
        profileName: "Owusu",
        profileDesignation: 'Project Manager',
        challenge: "Bitsclan Solutions was tasked with creating a high-performance ticketing platform for My Fete Tickets that could support heavy user traffic, especially during peak event sales. The challenge included ensuring top-notch security and a seamless experience for both event planners and ticket buyers.",
        solution: "To meet these demands, we designed a highly scalable platform that can seamlessly handle large user traffic, ensuring a smooth experience during peak sales periods. We integrated secure payment systems and advanced encryption protocols to protect user data, ensuring safety for every transaction. The platform was built with user experience in mind, offering easy navigation and fast load times. Additionally, we implemented a mobile-optimized design to provide a flawless experience across all devices. Our backend architecture was optimized for scalability, enabling My Fete Tickets to handle future growth effortlessly.",
        conclusion: "The solution surpassed expectations, enabling My Fete Tickets to scale and handle high user volumes while providing an intuitive user experience.",
        clientReview: "Bitsclan Solutions truly understood our needs and brought them to life. The platform they delivered has made ticket sales seamless and secure, and the positive feedback from our users has been overwhelming. Highly recommended!"
    },
    {
        id: 2,
        slug: 'songheart',
        image: "/images/SjamesMockup.png",
        title: "Custom Songs for Occasions",
        heading: "Songheart",
        location: "Nashville | Toronto",
        industry: "Entertainment",
        UseCase: "Personalized Song Creation",
        date: "Dec 2,2021",
        logo: "/images/songheart.png",
        clutchLink: "https://clutch.co/profile/bitsclan-it-solutions?utm_source=widget&utm_medium=2&utm_campaign=widget&utm_content=logo&utm_term=bitsclan.com#highlights",
        clutchLogo: "/images/clutch-rating.png",
        description: "Making custom songs more personal with video & dedication, written by distinguished & award-winning artists. SONGHEART is your gift-giving destination for personalized songs to celebrate any occasion. Whether it's weddings, anniversaries, birthdays, first dance, newborn, or off to college, tell someone how much you love them and why you love them through a song.",
        profileImage: "/images/SVG/SONGHEART.svg",
        profileName: "Johnny I",
        profileDesignation: 'Project Manager',
        challenge: "Bitsclan Solutions needed to build a unique, user-friendly platform for Songheart that allowed customers to easily request and personalize custom songs. The challenge was to ensure a smooth user experience while managing complex video, audio, and dedication requirements.",
        solution: "We developed a platform that simplifies the song customization process, enabling users to personalize every aspect of their song and video dedication. The solution included a straightforward UI, allowing users to choose their song genre, provide personal stories, and even submit photos or videos to be integrated into the final product. The platform supports integration with professional audio recording and editing tools to ensure top-quality sound. We also focused on scalability, ensuring the platform could handle an increasing number of requests and maintain performance. Additionally, we implemented secure payment systems and real-time order tracking to enhance customer satisfaction.",
        conclusion: "The project was a success, with the platform offering seamless customization and an unforgettable user experience for each customer.",
        clientReview: "Bitsclan Solutions transformed our vision into a beautiful, seamless platform! Now our customers can create personalized songs with ease, and the feedback has been fantastic. Highly recommended!"
    },
    {
        id: 3,
        slug: 'airdropja',
        image: "/images/Airdropja.png",
        title: "How Airdropja Simplifies Cross-Border Shipping",
        heading: "Airdropja",
        date: "June 18,2023",
        location: "Jamaica",
        industry: " E-commerce Sloutions",
        UseCase: "Cross-Border Shipping Simplification",
        logo: "/images/aridropja.png",
        clutchLink: "https://clutch.co/profile/bitsclan-it-solutions?utm_source=widget&utm_medium=2&utm_campaign=widget&utm_content=logo&utm_term=bitsclan.com#highlights",
        clutchLogo: "/images/clutch-rating.png",
        description: "Airdropja is a groundbreaking online platform revolutionizing cross-border shipping by facilitating direct communication between customers. Its unique approach allows individuals to coordinate shipments from the same location, enabling the consolidation of multiple packages into a single shipment.",
        profileImage: "/images/SVG/Airdropja.svg",
        profileName: "Kemar Campbell",
        profileDesignation: 'Project Manager',
        challenge: "Bitsclan Solutions was tasked with creating an innovative platform for Airdropja that would simplify cross-border shipping by connecting customers directly. The challenge was to design a system that enabled easy coordination and secure consolidation of packages for efficient, cost-effective shipping.",
        solution: "We designed a platform that empowers users to consolidate multiple packages into one shipment, reducing shipping costs and improving efficiency. Our team created an intuitive interface that allows users to easily connect with others for coordinated shipments, offering real-time tracking and secure user verification. We integrated an advanced algorithm that matches users based on location and package type, making the coordination process seamless. Additionally, we ensured that the platform is highly secure, with robust data protection and encryption to ensure the safety of all transactions and shipments.",
        conclusion: "The platform has streamlined cross-border shipping, offering an innovative solution that saves users both time and money.",
        clientReview: "Bitsclan Solutions understood our needs and brought our concept to life. The platform is intuitive, secure, and our customers love the convenience it provides. Highly recommended!"
    },
    {
        id: 4,
        slug: 'wellnessapp',
        image: "/images/Wellensmobileapp.png",
        title: "Revolutionizing Healthcare with the Wellness App",
        heading: "Wellness App",
        date: "Aug 15,2024",
        location: "USA",
        industry: "Health",
        UseCase: "Healthcare Management",
        logo: "/images/wellness.png",
        clutchLink: "https://clutch.co/profile/bitsclan-it-solutions?utm_source=widget&utm_medium=2&utm_campaign=widget&utm_content=logo&utm_term=bitsclan.com#highlights",
        clutchLogo: "/images/clutch-rating.png",
        description: "Wellness is an application designed to facilitate seamless communication and healthcare management between patients and doctors. Users have the option to register as either a doctor or a patient, unlocking a range of features tailored to their specific needs.",
        profileImage: "/images/SVG/journly.svg",
        profileName: "Billy Duc",
        profileDesignation: 'Project Manager',
        challenge: "Bitsclan Solutions was tasked with developing a comprehensive healthcare platform for Journiy Wellness to facilitate seamless communication and management between patients and doctors. The challenge lay in creating a secure, intuitive interface that could serve both doctors and patients with distinct, role-specific features.",
        solution: "We created a HIPAA-compliant platform that allows patients and doctors to register as different roles and access tailored features. Patients can schedule appointments, view medical records, and communicate with doctors via secure messaging. Doctors can manage appointments, access patient histories, and provide consultations remotely. We also integrated video conferencing capabilities, enabling virtual doctor-patient interactions. The system was designed to be highly secure, ensuring that sensitive health data is always protected, and it is optimized for performance to ensure quick load times and smooth interactions.",
        conclusion: "The platform has revolutionized the patient-doctor interaction, offering a secure and efficient healthcare management system.",
        clientReview: "Bitsclan Solutions exceeded our expectations. The platform is secure, easy to use, and has transformed the way our users experience healthcare. Fantastic work!"
    },
    {
        id: 5,
        slug: 'jmtest',
        image: "/images/JMTest.png",
        title: "Redefining Testing and Calibration Excellence",
        heading: "JM Test",
        date: "Aug 9,2024",
        location: "Louisiana-USA",
        industry: "Calibration, and Repair Services",
        UseCase: "Manufacturing Equipment Calibration",
        logo: "/images/JM_Test_Systems.jpg",
        clutchLink: "https://clutch.co/profile/bitsclan-it-solutions?utm_source=widget&utm_medium=2&utm_campaign=widget&utm_content=logo&utm_term=bitsclan.com#highlights",
        clutchLogo: "/images/clutch-rating.png",
        description: "JM Test Systems is a trusted leader in providing comprehensive testing, calibration, and repair services, offering unparalleled expertise across a wide range of industries. With over four decades of experience, JM Test is committed to ensuring accuracy and compliance in every project, utilizing cutting-edge technology to deliver high-quality, reliable results.",
        profileImage: "/images/SVG/AJMTest .svg",
        profileName: "Amanda",
        profileDesignation: 'Project Manager',
        challenge: "Bitsclan Solutions was tasked with developing a comprehensive testing and calibration management platform for JM Test Systems to streamline their operations and enhance service delivery. The challenge lay in creating a secure, intuitive interface tailored to meet the unique requirements of testing and calibration processes, ensuring compliance and precision across diverse industries.",
        solution: "We built a platform that integrates all the necessary tools for testing, calibration, and repair services, including secure client communication, order tracking, and efficient scheduling. The solution was designed to meet the specific needs of JM Test's clients, enabling them to easily schedule services, access reports, and track progress. We integrated real-time status updates, notifications, and a secure client portal to manage interactions and orders seamlessly. Our system ensured high data accuracy, scalability, and robust security to handle sensitive client information.",
        conclusion: "The platform offers a streamlined process for testing, calibration, and repair services, enhancing efficiency and accuracy for JM Test Systems and their clients.",
        clientReview: "Working with Bitsclan Solutions was a pleasure. They delivered a robust and scalable platform that perfectly meets our needs. Our clients appreciate the convenience and reliability it provides."
    }
];

export default slidesData;
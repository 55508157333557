export const tabs = [
  "Backend",
  "Database",
  "Cloud Services",
];

export const tabItems = [
  { tab: "Backend", icon: "/images/tech-stack-icons/dart.svg", name: "Dart" },
  { tab: "Backend", name: "Firebase", icon: "/images/tech-stack-icons/firebase.png", },
  { tab: "Backend", name: "Node.js", icon: "/images/database9.png", },
  { tab: "Database", icon: "/images/db1.png", name: "Firebase" },
  { tab: "Database", icon: "/images/db7.png", name: "SQLite" },
  { tab: "Cloud Services", icon: "/images/cloud3.png", name: "AWS" },
  { tab: "Cloud Services", name: "Google Cloud", icon: "/images/cloud1.png", },
];

export const techCombinations = [
  {
    title: "Flutter + Firebase",
    description:
      "Flutter for cross-platform development and Firebase as a backend solution enable real-time data sync, authentication, and cloud storage for mobile and web apps.",
    icons: ["Flutter.svg", "firebase.png"],
  },
  {
    title: "Flutter + Node.js + MongoDB",
    description:
      "Flutter for the front end, Node.js for backend logic, and MongoDB for flexible NoSQL storage help create scalable and dynamic applications.",
    icons: ["Flutter.svg", "nodejs.svg", "mongodb.svg"],
  },
  {
    title: "Flutter + Django + PostgreSQL",
    description:
      "Flutter for UI, Django as the backend framework, and PostgreSQL for structured database management ensure secure and high-performance app development.",
    icons: ["Flutter.svg", "django.svg", "postgres.svg"],
  },
  {
    title: "Flutter + GraphQL + Supabase",
    description:
      "GraphQL for efficient data fetching, Flutter for UI, and Supabase as an open-source backend solution provide a modern tech stack for mobile and web applications.",
    icons: ["Flutter.svg", "graphql.svg", "supabase.svg"],
  },
];

export const HireFlutterBannerList = [
  { list: "Freelance contractors" },
  { list: "Full-time roles" },
  { list: "Global teams" },
];

export var mainBannerImg = {
  backgroundImage: `url(/images/hire-page-images/hire-flutter.png)`,
  factBackgroundImage: `url(/images/hire-page-images/hire-flutter.svg)`,
};

export const HireFlutterServicesData = [
  {
    title: "Flutter App Consulting",
    description:
      "With our consulting services, you’ll get expert advice and guidance to maximize your Flutter app development. Whether you're just starting or optimizing an existing app, our developers will help you make the most out of Flutter’s features and capabilities.",
    icon: "/images/hire-page-icons/flutter-icon-1.svg",
  },
  {
    title: "Flutter App Development",
    description:
      "Our developers use the Flutter framework to build mobile apps that are both scalable and feature-rich. With Flutter, we can create apps that offer a consistent look and feel across both iOS and Android, cutting down on development time and costs.",
    icon: "/images/hire-page-icons/flutter-icon-2.svg",
  },
  {
    title: "Flutter API Development",
    description:
      "We have expertise in building reliable and efficient APIs using Flutter. These APIs help ensure smooth communication between your mobile app and other services, making the entire app ecosystem work seamlessly.",
    icon: "/images/hire-page-icons/flutter-icon-3.svg",
  },
  {
    title: "Flutter CMS and Portal Development",
    description:
      "Our developers are skilled in creating complex content management systems (CMS) and web portals using Flutter. These systems are designed to be easy to use, efficient, and scalable, allowing you to manage content effortlessly.",
    icon: "/images/hire-page-icons/flutter-icon-4.svg",
  },
  {
    title: "Migration to Flutter",
    description:
      "If you have an existing mobile app, we can help migrate it to Flutter. Our Flutter experts will ensure a smooth transition, helping you take advantage of the benefits of Flutter, like faster development and cross-platform support.",
    icon: "/images/hire-page-icons/flutter-icon-5.svg",
  },
  {
    title: "Integration with Existing Solutions",
    description:
      "Our team has extensive experience integrating Flutter applications with your existing systems and services. Whether it's connecting with third-party APIs or syncing with databases, we ensure everything works together seamlessly.",
    icon: "/images/hire-page-icons/flutter-icon-6.svg",
  },
  {
    title: "Flutter eCommerce Solutions",
    description:
      "For eCommerce businesses, we offer powerful Flutter solutions that enhance the shopping experience. From improving user interfaces to optimizing performance, our Flutter developers help create a responsive, interactive app that boosts customer engagement.",
    icon: "/images/hire-page-icons/flutter-icon-7.svg",
  },
  {
    title: "Flutter Support & Maintenance",
    description:
      "We provide ongoing support and maintenance for your Flutter apps, ensuring they stay efficient and up to date. Our team helps fix issues, add new features, and ensure that your app continues to perform well over time.",
    icon: "/images/hire-page-icons/flutter-icon-8.svg",
  },
];


export const HireFlutterSolutionsData = [
  {
    icon: "/images/hire-page-icons/chose-ror-section1.svg",
    title: "Access vetted talent",
    description:
      "Meet experienced Flutter developers who are well-versed in the Flutter framework and are ready to contribute to your project.",
  },
  {
    icon: "/images/hire-page-icons/chose-ror-section2.svg",
    title: "View matches in seconds",
    description:
      "Forget the hassle of sorting through hundreds of resumes. With HireAI, you can instantly view the profiles of top Flutter developers.",
  },
  {
    icon: "/images/hire-page-icons/chose-ror-section3.svg",
    title: "Save with global hires",
    description:
      "Hire talented Flutter experts from anywhere in the world. You’ll access a vast pool of developers and save up to 58% compared to traditional hiring methods.",
    noBorder: true,
  },
  {
    icon: "/images/hire-page-icons/chose-ror-section4.svg",
    title: "Get real human support",
    description:
      "With our team of expert recruiters, you get personalized help to hire the best Flutter developers for your project.",
  },
];

export const HireFlutterStacksdata = [
  {
    title: "Junior Ruby on Rails Developer",
    description: [
      "2 Years' experience",
      "Fresh perspectives & innovative ideas",
      "Dedicated to explore web development",
    ],
    linkText: "Connect to Hire",
    link: "#",
    borderClass: "border-1",
  },
  {
    title: "Senior Ruby on Rails Developer",
    description: [
      "2+ years' experience",
      "Leadership in complex projects",
      "Proven app deployment success",
    ],
    linkText: "Connect to Hire",
    link: "#",
    borderClass: "border-2",
  },
  {
    title: "Ruby on Rails Tech Lead",
    description: [
      "5+ years' experience",
      "Skilled with Jira, Asana, MS Teams, etc.",
      "Suitable when you Hire Web App Team",
    ],
    linkText: "Connect to Hire",
    link: "#",
    borderClass: "border-3",
  },
];

export const HireFluterBenefitsData = [
  {
    service: "Flutter CMS Development",
    description:
      "Get an upper hand on your web content, augment efficiency, and develop a shared environment with our Ruby on Rails CMS solutions.",
  },
  {
    service: "Ruby on Rails Migrations",
    description:
      "Our Ruby on Rails engineers strategically migrate legacy content into modern systems in a secured, erFlutter-free, and robust way.",
  },
  {
    service: "Advanced Flutter Programming",
    description:
      "Our experts have extensive experience in using Flutter tech stack for tangible results to reach your business vision.",
  },
  {
    service: "Flutter Integration and Optimization",
    description:
      "Leverage the full-scale potential of Ruby on Rails integration and optimization functionalities for your app development needs.",
  },
  {
    service: "Customized Flutter Solutions",
    description:
      "Our Ruby on Rails developers delve into ideation, reinvention, and scale development as per your enterprise's unique IT demands.",
  },
  {
    service: "Flutter eCommerce App",
    description:
      "With using modern Flutter tools and technologies, we have a proven track record of developing scalable and secured eCommerce sites.",
  },
];

export const HireFluterSteps = [
  {
    title: "Choose your Engagement Model",
    description:
      "Pick the engagement model that fits your needs—Full-Time, Part-Time, or Hourly.",
    icon: "/images/hire-page-setps-icons/icon-1.svg",
  },
  {
    title: "Screen & Select Flutter Developers",
    description:
      "Browse through the profiles of top Flutter developers and select the best ones for your project.",
    icon: "/images/hire-page-setps-icons/icon-2.svg",
  },
  {
    title: "Conduct One-on-One Interview",
    description:
      "Meet with the developers you’ve selected and assess their skills through practical and theoretical questions.",
    icon: "/images/hire-page-setps-icons/icon-3.svg",
  },
  {
    title: "Onboard Your Flutter Developer",
    description:
      " Once you've made your selection, your chosen Flutter developer will join your team within 24-48 hours, ensuring a fast and smooth onboarding process.",
    icon: "/images/hire-page-setps-icons/icon-4.svg",
  },
];

export const HireFluterFactsData = [
  {
    text: "<strong>Experienced Flutter Developers:</strong> Our team includes highly skilled developers with expertise in Flutter app development.",
  },
  {
    text: "<strong>Diverse Project Experience:</strong> We’ve successfully worked on a wide range of mobile app development projects, building cross-platform solutions with Flutter.",
  },
  { text: "<strong>Fast & Efficient Development:</strong> Our developers use the Flutter framework to create apps quickly while ensuring they are high-quality and scalable." },
  {
    text: "<strong>Proven Record in Mobile App Development:</strong> Our team has a deep understanding of Dart programming and the Flutter framework to deliver top-notch mobile apps.",
  },
  { text: "<strong>Cross-Platform Expertise:</strong> Whether it's iOS or Android, we specialize in creating apps that perform well on both platforms." },
  { text: "<strong>Continuous Support:</strong> Our Flutter developers provide full support throughout the development process and beyond, helping with maintenance and updates." },
];

export const HireFluterWhoWeServeData = [
  {
    icon: "/images/servie-icon/startups.png",
    title: "Budget savings",
    description:
      "Assembling a local Ruby on Rails team is unavoidably expensive. By approaching Innowise. you bypass many hurdles and avoid redundantexpenses such as training fees. taxes. and software licenses.",
    link: "#",
    borderClass: "border-1",
  },
  {
    icon: "/images/servie-icon/companies.png",
    title: "Software product companies",
    description:
      "When you hire Flutter developers. you avoid the risks of budget overruns and missed deadlines. Innowise provides solid risk management plans and has an array of risk mitigation measures",
    link: "#",
    borderClass: "border-2",
  },
  {
    icon: "/images/servie-icon/industries.png",
    title: "Enterprises from 30+ industries",
    description:
      "Innowise has experts with excellent development backgrounds. so you can rest assured that your project is in good hands. Pyrggyelgprneg",
    link: "#",
    borderClass: "border-3",
  },
];

export const HireFluterHirignDetails = [
  {
    text: "Hourly Hiring",
    hours: "48",
    icon: "time.svg",
    bgColor: "#FDFDFF",
  },

  {
    text: "Full Time Hiring",
    hours: "72",
    icon: "full-time.svg",
    bgColor: "#FFFEFA",
  },

  {
    text: "Part Time Hiring",
    hours: "48",
    icon: "part-time.svg",
    bgColor: "#FDFFFA",
  },
];
export const HireFlutterPageDeliverData = [
  {
    image: "/images/hire-page-images/card-1.png",
    title: "Complex SaaS systems",
    description:
      "Innowise provides its expertise in the area of building complex platforms for businesses to streamline workflows. The main applications we develop include HRM, CRM, ERP, accounting software, etc.",
  },
  {
    image: "/images/hire-page-images/card-2.png", // Assumed path based on provided example
    title: "Complex SaaS systems",
    description:
      "Innowise provides its expertise in the area of building complex platforms for businesses to streamline workflows. The main applications we develop include HRM, CRM, ERP, accounting software, etc.",
  },
  {
    image: "/images/hire-page-images/card-3.png", // Assumed path based on provided example
    title: "Complex SaaS systems",
    description:
      "Innowise provides its expertise in the area of building complex platforms for businesses to streamline workflows. The main applications we develop include HRM, CRM, ERP, accounting software, etc.",
  },
];

export const HireFluterSlidesData = [
  {
    title: "Experienced Flutter Developers",
    content:
      "Our team consists of senior Flutter developers who have extensive experience in building sophisticated web applications across various industries.",
    backgroundClass: "bg-1",
  },
  {
    title: "Remote and On-Demand",
    content:
      "Need flexibility? Hire remote Flutter developers from Bitsclan for seamless project execution without geographic limitations.",
    backgroundClass: "bg-2",
  },
  {
    title: "Agile Development Approach",
    content:
      "We follow agile methodologies to ensure rapid delivery, transparency, and adaptability throughout the development process.",
    backgroundClass: "bg-3",
  },
  {
    title: "Automation Solutions",
    content:
      "We combine AI automation with Robotic Process Automation to boost operational efficiency, reduce operational costs, and minimize human error, allowing you to focus on strategic initiatives.",
    backgroundClass: "bg-4",
  },
];

export const HireFluterFaqData = [
  {
    id: "collapseOne",
    question: "What is Flutter and why should I use it for mobile apps?",
    answer:
      "Flutter is a framework that helps developers build mobile apps for both iOS and Android using a single codebase. It's fast, efficient, and allows for beautiful, high-performance apps. You should use it because it saves time and money by eliminating the need to develop separate apps for each platform.",
  },
  {
    id: "collapseTwo",
    question: "How long does it take to develop a mobile app using Flutter?",
    answer:
      "The time it takes to develop an app with Flutter depends on the app's complexity and features. However, since Flutter allows developers to use one codebase for both iOS and Android, it usually speeds up the development process compared to traditional methods.",
  },
  {
    id: "collapseThree",
    question: "Can I migrate my existing mobile app to Flutter?",
    answer:
      "Yes, you can migrate your existing app to Flutter. Our Flutter experts can help you move your current app to Flutter without disrupting its functionality, giving you all the benefits of faster development and better performance.",
  },
  {
    id: "fourth",
    question: "How much does it cost to hire Flutter developers?",
    answer:
      "The cost of hiring Flutter developers depends on the complexity of your project and the engagement model (full-time, part-time, or hourly). By hiring Flutter developers, you save time and reduce costs compared to building separate apps for iOS and Android.",
  },
  {
    id: "fifth",
    question: "How can I ensure that Flutter is the right choice for my app?",
    answer:
      "If you need a cross-platform app with fast development and consistent performance on both iOS and Android, Flutter is a great choice. Our team can help you decide if Flutter is the best option based on your app's features and your business needs.",
  },
  {
    id: "sixth",
    question: "What kind of support do you provide after the app is developed?",
    answer:
      "We provide full support and maintenance for your Flutter app after it's developed. This includes fixing bugs, adding new features, and making sure the app continues to work smoothly on both platforms.",
  },
];

import React, { useState, useRef } from "react";
import "./style.css";
import ReCAPTCHA from "react-google-recaptcha";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { contactFromHomeAPI } from "../../redux/slices/ContactFormApiSlice";

const ContactSection = ({ heading }) => {

  const dispatch = useDispatch();

  const notify = () => toast("Your query submitted successfully!");

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const loading = useSelector(state => state?.contactFormApiSlice?.loading);

  const [data, setData] = useState({
    full_name: "",
    email: "",
    phone_number: "",
    details: "",
    about_project: "",
    services: [],
  });

  const isFormComplete = () => {
    return (
      data.full_name &&
      data.email &&
      data.phone_number &&
      data.details &&
      data.about_project &&
      data.services.length > 0
    );
  };

  const handleServicesChange = (selected) => {
    setData((prevData) => ({
      ...prevData,
      services: selected,
    }));
  };

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
    if (type === "checkbox") {
      setData((prevData) => ({
        ...prevData,
        services: checked
          ? [...prevData.services, id]
          : prevData.services.filter((service) => service !== id),
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }
  };

  const recaptchaRef = useRef(null);
  const [captchaValue, setCaptchaValue] = useState(null);

  const getRecaptchaValue = (value) => {
    setCaptchaValue(value);
  };

  const handleRecaptchaExpired = () => {
    setCaptchaValue(null);
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const recaptchaValue = recaptchaRef.current
      ? recaptchaRef.current.getValue()
      : null;
    console.log(recaptchaValue, "recaptch here by form");
    console.log(captchaValue, "recaptch here by use state");
    console.log("Selected Services:", data);

    const res = await dispatch(contactFromHomeAPI(data));

    if (res.type === 'contactFromHomeAPI/fulfilled') {
      toast.success(res?.payload?.message)
    } else if (res.type === 'contactFromHomeAPI/rejected') {
      toast.success(res.payload.errors);
    } else {
      toast.error('Sorry, something went wrong!');
    }
    console.log(res, "res here");
  };

  const servicesArray = [
    {
      value: "Software Development",
      label: "Software Development",
    },
    {
      value: "UI/UX",
      label: "UI/UX",
    },
    {
      value: "QA",
      label: "QA",
    },
    {
      value: "Integration",
      label: "Integration",
    },
    {
      value: "Maintenance",
      label: "Maintenance",
    },
    {
      value: "Consultancy",
      label: "Consultancy",
    },
  ];

  return (
    <>
      <ToastContainer hideProgressBar={true} />
      <div className="contact-section-box height-100-vh" id="contactForm">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="contact-left-image">
                <img src="/images/contact-left-bg.png" alt="Contact Background" />
              </div>

              <div className="contact-text-side center-box-contract-section flex-start-box">
                <div className="contact-text-box">
                  <h1
                    dangerouslySetInnerHTML={{
                      __html:
                        heading ||
                        "The Bottom Line is <br /> <span>WE GET IT DONE!</span>",
                    }}
                  />
                </div>

                <div className="container-fluid p-0">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="skilled-contact-section">
                        <h2>Skilled Talent Network</h2>
                        <p className="common-text">
                          Our team consists of highly experienced professionals.
                        </p>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="skilled-contact-section">
                        <h2>Swift Turnaround</h2>
                        <p className="common-text">
                          We provide fast and efficient solutions despite time
                          constraints.
                        </p>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="skilled-contact-section">
                        <h2>Cost-effective Solutions</h2>
                        <p className="common-text">
                          We deliver high-quality services that maximize your
                          budget’s potential.
                        </p>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="skilled-contact-section">
                        <h2>Innovation-Driven Development</h2>
                        <p className="common-text">
                          We focus on delivering cutting-edge, innovative, and
                          tailored solutions.
                        </p>
                      </div>
                    </div>

                    <div className="col-md-6 mt-4">
                      <a href="mailto:hello@bitsclan.com" className='contact-mail-to-text'><img src="/images/SVG/email-icon.png" alt="" /> hello@bitsclan.com</a>

                      {/* <a href="mailto:sales@bitsclan.com" className='contact-mail-to-text'><img src="/images/SVG/email-icon.png" alt="" /> sales@bitsclan.com</a> */}
                    </div>

                    <div className="col-md-6 mt-4">
                      <a href="#" className='contact-mail-to-text'><img src="/images/SVG/Phone.svg" alt="" /> +1 315 666 8052</a>

                      {/* <a href="mailto:support@bitsclan.com" className='contact-mail-to-text'><img src="/images/SVG/email-icon.png" alt="" /> support@bitsclan.com</a> */}
                    </div>

                    <div className="col-md-12 mt-2">
                      {/* <p className="contact-mail-to-text"><img src="/images/SVG/location-icon.png" alt="" /> 2 Park Ave 20th floor New York, NY 10016</p> */}
                    </div>
                  </div>
                </div>

                {/* <a href="#" className='common-green-btn mt-5'>Book a free consultation <i className="fa-solid fa-arrow-right"></i></a> */}
              </div>

              <div className="contact-left-image bottom-image-contact bottom-contact-image">
                <img src="/images/contact-bg-right.png" alt="Contact Background" />
              </div>
            </div>

            <div className="col-md-6 d-flex align-items-center">
              <div className="contact-side-form">
                <div className="container p-0">
                  <form
                    className="row contact-side-form-box"
                    onSubmit={handleSubmit}
                    id="footer-contact-us-form-click">

                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          requried
                          type="text"
                          placeholder="Full Name"
                          className="form-control"
                          id="full_name"
                          onChange={handleChange}
                          value={data.full_name}
                        />
                        <label htmlFor="full_name">
                          Full Name <span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          requried
                          type="email"
                          placeholder="Email"
                          className="form-control"
                          id="email"
                          onChange={handleChange}
                          value={data.email}
                        />
                        <label htmlFor="email">
                          Email <span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-floating">
                        <input
                          requried
                          type="number"
                          placeholder="Phone Number"
                          className="form-control"
                          id="phone_number"
                          onChange={handleChange}
                          value={data.phone_number}
                        />
                        <label htmlFor="phone_number">
                          Phone Number <span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-floating">
                        {/* <select
                                                    className="form-control form-select"
                                                    multiple
                                                    onChange={handleChange}
                                                >
                                                    <option disabled>Select any services</option>
                                                    {servicesArray.map((service, index) => (
                                                        <option key={service.id} value={service.title}>
                                                            {service.title}
                                                        </option>
                                                    ))}
                                                </select> */}

                        <Select
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderRadius: "0 !important",
                              backgroundColor: "transparent",
                              border: "none !important",
                              borderBottom: "1.12px solid #FFFFFF !important",
                              boxShadow: "none !important",
                              color: "#FFFFFF",
                              fontSize: "16px",
                              padding: "16px 0px 0px 0px",
                            }),
                            placeholder: (baseStyles, state) => ({
                              ...baseStyles,
                              color: "#fff", // Change this to your desired placeholder color
                              fontSize: "16px",
                            }),
                            menu: (baseStyles, state) => ({
                              ...baseStyles,
                              backgroundColor: "#333333", // Change this to your desired dropdown background color
                            }),
                          }}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary25: "#80b927",
                            },
                          })}
                          isMulti
                          value={data.services}
                          onChange={handleServicesChange}
                          options={servicesArray}
                          placeholder="Services"
                        />
                        {/* <label htmlFor="budget">
                                                    Services <span style={{ color: 'red' }}>*</span>
                                                </label> */}
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-floating">
                        <input
                          requried
                          type="text"
                          placeholder="Phone Number"
                          className="form-control"
                          id="about_project"
                          onChange={handleChange}
                          value={data.about_project}
                        />
                        <label htmlFor="about_project">
                          Tell us about your project{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-floating">
                        <div
                          style={{
                            height: "60px",
                            borderBottom: "1px solid #fff",
                            display: "flex",
                            alignItems: "center",
                            padding: "0px",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            document.getElementById("fileInput").click()
                          }
                        >
                          <span style={{ flex: 1 }}>{data.details}</span>
                          <span style={{ color: "#BABABA" }}>
                            <i class="fa-solid fa-paperclip"></i> Attach file
                          </span>
                        </div>
                        <input
                          requried
                          type="file"
                          id="fileInput"
                          onChange={(e) => {
                            const file = e.target.files[0];
                            handleChange({
                              target: {
                                id: "details",
                                value: file ? file.name : "",
                              },
                            });
                          }}
                          style={{
                            display: "none",
                            position: "absolute",
                            pointerEvents: "none",
                          }}
                        />
                      </div>
                    </div>

                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6LduktkqAAAAAAG-TJxQPKptt1p5F4l_FYqYqNgv"
                      onChange={getRecaptchaValue}
                      onExpired={handleRecaptchaExpired}
                    />

                    <div className="col-md-5 contact-section-button">
                      <button type="submit" disabled={loading || !isFormComplete()} className="btn btn-primary">
                        {loading ? "Loading..." : "Submit"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Popup
        open={isPopupOpen}
        position="center center"
        closeOnDocumentClick={false}
      >
        <div
          className="position-relative"
          style={{ backgroundColor: "rgb(242 242 242)", borderRadius: "20px" }}
        >
          <button
            onClick={() => setIsPopupOpen(false)}
            className="cross-btn-close"
          >
            <i class="fa-solid fa-xmark"></i>
          </button>

          <video width="100%" height="100%" autoPlay muted>
            <source src="/videos/thankyou-vedio.mp4" type="video/mp4" />
          </video>

          <div className="header-link-green d-flex justify-content-center pb-5">
            <a
              href="https://calendly.com/bitsclan-it/30min?month=2025-01"
              target="_blank"
            >
              Let's Talk
            </a>
          </div>
        </div>
      </Popup>
    </>
  );
};

export default ContactSection;

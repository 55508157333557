import React, { useState } from 'react';
import './style.css';

const TypesOfBuild = ({ sideImage, typeOfApps, heading, content }) => {
    const [expandedIndex, setExpandedIndex] = useState(0); // Default: No section expanded

    const handleToggle = (index) => {
        setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <div className="types-of-build-main">
            <div className="container">
                <div className="row position-relative">
                    {/* Left side image */}
                    <div className="col-md-6 types-of-build-sideimg">
                        <img src={`./images/what-we-do/${sideImage}`} alt="Side Visual" />
                    </div>

                    {/* Right side accordion */}
                    <div className="col-md-6 types-of-build-content">
                        <h2>{heading}</h2>
                        {content && <p className="common-text">{content}</p>}
                        {typeOfApps?.map((data, index) => (
                            <div className="accordion-item" key={index}>
                                {/* Accordion Header */}
                                <h3 className="accordion-header">
                                    <div
                                        className={`what-we-do-accordion-button accordion-button ${expandedIndex === index ? '' : 'collapsed'}`}
                                        type="button"
                                        onClick={() => handleToggle(index)}
                                        aria-expanded={expandedIndex === index ? 'true' : 'false'}
                                    >
                                        <div className="name-arrow">
                                            <div><h4>{data.question}</h4></div>
                                            <div><i className="fa-solid fa-arrow-down"></i></div>
                                        </div>
                                    </div>
                                </h3>

                                {/* Accordion Body */}
                                <div
                                    className={`accordion-collapse ${expandedIndex === index ? 'show' : ''}`}
                                    style={{
                                        maxHeight: expandedIndex === index ? '1000px' : '0',
                                        transition: 'max-height 0.5s ease',
                                    }}
                                >
                                    <div className="accordion-body type-of-app">
                                        <p>{data.answer.discription}</p>
                                        <h5>{data.answer.listTitle}</h5>
                                        <ul>
                                            {data.answer.listContent?.map((item, i) => (
                                                <li key={i} className="list-item">
                                                    <span><i className="fa-solid fa-check"></i></span> {item.line}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TypesOfBuild;

import tabData from "../data/industryData";
import React from "react";
import { Helmet } from "react-helmet";
import MegaHeader from "../components/MegaHeader/MegaHeader";
import SlidOnScrollBanner from "../components/slide-on-scroll-banner/SlidOnScrollBanner";
import Footer from "../components/footer/Footer";
import HomeLogo from "../components/home-logos/HomeLogo";
import WhyUs from "../components/whyus/WhyUs";
import CoverTheStack from "../components/coverthestack/CoverTheStack";
import PioneeringChange from "../components/pioneeringChange/PioneeringChange";
import EngagmentModels from "../components/engagmentModels/EngagmentModels";
import DevelopingSolution from "../components/developingsolution/DevelopingSolution";
import TypeIndustries from "../components/typeIndustry/TypeIndustries";
import ContactSection from "../components/contactSection/ContactSection";
import BrandImages from "../components/brandImages/BrandImages";
import HireForUs from "../components/hire-us-for/HireForUs";
import MainEnterpriseItServices from "../components/enterprise-it-services/MainEnterpriseItServices";
import TrendsInsights from "../components/trends-n-insights/TrendsInsights";
import PageScroller from "../components/page-scroller/PageScroller";
import OurLocations from "../components/ourlocations/OurLocations";
import Testimonial from "../components/testimonila/Testimonial";
import BootstrapModal from "../components/modals/submit-email-modal";
import MyModal from "../components/modals/submit-email-modal";
import NewEngagementModels from "../components/new-engagment-modal";
import { UsaScrollerData, UsaSectionsData } from "../data/UsaBannerData";
import FloatingButtons from "../components/floating-buttons";
import ClutchIcon from "../components/clutch-icon";

function UsaHome() {
  const tabs = [
    "Database",
    "Backend",
    "Frontend",
    "Mobile",
    "Frameworks",
    "Cloud",
    "DevOps",
    "Ecommerce",
    "Platforms",
  ];

  const tabItems = [
    { tab: "Frontend", icon: "/images/iconone.png", name: "Angular JS" },
    { tab: "Frontend", icon: "/images/icontwo.png", name: "JavaScript" },
    { tab: "Frontend", icon: "/images/iconthree.png", name: "React JS" },
    { tab: "Frontend", icon: "/images/icon4.png", name: "Vue JS" },
    { tab: "Frontend", icon: "/images/icon5.png", name: "Tez JS" },
    { tab: "Frontend", icon: "/images/icon6.png", name: "CSS3" },
    { tab: "Frontend", icon: "/images/icon7.png", name: "HTML5" },
    { tab: "Backend", icon: "/images/database1.png", name: ".Net" },
    { tab: "Backend", icon: "/images/database2.png", name: "Nest Js" },
    { tab: "Backend", icon: "/images/database3.png", name: "Meteor JS" },
    { tab: "Backend", icon: "/images/database4.png", name: "Fastify" },
    { tab: "Backend", icon: "/images/database5.png", name: "Express JS" },
    { tab: "Backend", icon: "/images/database6.png", name: "Python" },
    { tab: "Backend", icon: "/images/database7.png", name: "Java" },
    { tab: "Backend", icon: "/images/database8.png", name: "PHP" },
    { tab: "Backend", icon: "/images/database9.png", name: "Node Js" },
    { tab: "Mobile", icon: "/images/mobile1.png", name: "Android" },
    { tab: "Mobile", icon: "/images/mobile2.png", name: "IOS" },
    { tab: "Mobile", icon: "/images/mobile3.png", name: "Swift" },
    { tab: "Mobile", icon: "/images/mobile4.png", name: "Flutter" },
    { tab: "Mobile", icon: "/images/mobile5.png", name: "Xamarin" },
    { tab: "Mobile", icon: "/images/mobile6.png", name: "React Native" },
    { tab: "Database", icon: "/images/db1.png", name: "Firebase" },
    { tab: "Database", icon: "/images/db2.png", name: "Oracle " },
    { tab: "Database", icon: "/images/db3.png", name: "MongoDB" },
    { tab: "Database", icon: "/images/tech-stack-icons/postgres.svg", name: "PostgreSQL" },
    { tab: "Database", icon: "/images/db5.png", name: "MySQL" },
    { tab: "Database", icon: "/images/db6.png", name: "SQL Server" },
    { tab: "Database", icon: "/images/db7.png", name: "SQLite" },
    { tab: "Database", icon: "/images/db8.png", name: "DynamoDB" },
    { tab: "Frameworks", icon: "/images/framework6.png", name: "Django" },
    { tab: "Frameworks", icon: "/images/framework2.png", name: "CodeIgniter" },
    { tab: "Frameworks", icon: "/images/framework3.png", name: "Laravel" },
    { tab: "Frameworks", icon: "/images/framework1.png", name: "MEAN" },
    { tab: "Frameworks", icon: "/images/framework4.png", name: "Rxweb.io" },
    { tab: "Frameworks", icon: "/images/framework5.png", name: "CakePHP" },
    { tab: "Cloud", icon: "/images/cloud1.png", name: "Google Cloud" },
    { tab: "Cloud", icon: "/images/cloud2.png", name: "Azure" },
    { tab: "Cloud", icon: "/images/cloud3.png", name: "AWS" },
    { tab: "Cloud", icon: "/images/DigitalOcean.png", name: "Digital Ocean" },
    { tab: "DevOps", icon: "/images/devops1.png", name: "Gradle" },
    { tab: "DevOps", icon: "/images/devops2.png", name: "Kubernetes" },
    { tab: "DevOps", icon: "/images/devops3.png", name: "Docker" },
    { tab: "DevOps", icon: "/images/devops4.png", name: "Jenkins" },
    {
      tab: "DevOps",
      icon: "/images/openshift-svgrepo-com.svg",
      name: "Open Shift",
    },
    { tab: "DevOps", icon: "/images/packer-svgrepo-com.svg", name: "Packer" },
    { tab: "DevOps", icon: "/images/ansible-svgrepo-com.svg", name: "Ansible" },
    {
      tab: "DevOps",
      icon: "/images/saltstack-svgrepo-com.svg",
      name: "Salt Stack",
    },
    { tab: "Ecommerce", icon: "/images/ecomres1.png", name: "NopCommerce" },
    { tab: "Ecommerce", icon: "/images/ecomres2.png", name: "Kentico" },
    { tab: "Ecommerce", icon: "/images/ecomres3.png", name: "Shopify" },
    { tab: "Ecommerce", icon: "/images/ecomres4.png", name: "Magento" },
    { tab: "Ecommerce", icon: "/images/ecomres5.png", name: "WooCommerce" },
    { tab: "Platforms", icon: "/images/platform1.png", name: "Servicenow " },
    { tab: "Platforms", icon: "/images/platform2.png", name: "Tableau" },
    {
      tab: "Platforms",
      icon: "/images/platform3.png",
      name: "MS Dynamics CRM",
    },
    { tab: "Platforms", icon: "/images/platform4.png", name: "Zoho" },
    { tab: "Platforms", icon: "/images/platform5.png", name: "Power BI" },
    { tab: "Platforms", icon: "/images/platform6.png", name: "Salesforce " },
  ];
  const slidesData = [
    {
      heading: "We are the experts",
      text: "Our team members are highly skilled in a wide range of technologies and industries. We stay ahead of the curve and guarantee that we offer the most creative and practical solutions for your needs thanks to our ongoing education and certifications.",
    },
    {
      heading: "Nearshore timezones",
      text: "Our team works in your nearshore time zones, offering seamless collaboration with minimal time zone differences. This ensure that your project proceeds easily and effectively by facilitating real-time communication and quick response times.",
    },
    {
      heading: "Nothing beats our quality",
      text: "We prioritize delivering top-notch quality in every project. Our comprehensive quality assurance procedures, which include comprehensive testing and code reviews, guarantee that your software is dependable, secure, and operates at its best.",
    },
    {
      heading: "Flexibility and adaptiveness",
      text: "We understand that project requirements can change. Our flexible and adaptive approach allows us to respond swiftly to evolving needs, ensuring your project remains on track and aligned with your goals.",
    },
    {
      heading: "We take security seriously",
      text: "To give you peace of mind, our team implements rigorous security measures at all stages of development to protect your data and ensure compliance with industry standards.",
    },
    {
      heading: "We deliver value",
      text: "Our value-based mindset drives us to deliver more than just a service. We are committed to understanding your business objectives and providing solutions that offer real, measurable value, helping you achieve long-term success.",
    },
  ];

  const HireUsData = [
    {
      id: 1,
      icon: "/images/icon1.svg",
      heading: "AI development",
      text: "We help you leverage advanced AI technologies like LLMs, chatbots, and NLP to automate processes and gain insightful, data-driven decisions tailored to your business needs.",
      btnLink: "contact-us",
    },
    {
      id: 5,
      icon: "/images/dashboard.png",
      heading: "Blockchain development",
      text: "Leverage secure and transparent blockchain solutions to enhance your business operations, boost security, and foster trust.",
      btnLink: "contact-us",
    },
    {
      id: 3,
      icon: "/images/machine-learning.png",
      heading: "Custom software development",
      text: "Need tailored solutions? Our custom software development transforms your vision into reality, optimizing efficiency and innovation.",
      btnLink: "contact-us",
    },
    {
      id: 4,
      icon: "/images/icon3.svg",
      heading: "Data analytics",
      text: "Unlock the power of data with AI-driven analytics, BI insights, and predictive models to fuel data-driven decision-making.",
      btnLink: "contact-us",
    },
    {
      id: 7,
      icon: "/images/cloud.png",
      heading: "Cloud Solutions",
      text: "Supercharge your cloud infrastructure with expert DevOps services on AWS, GCP, and Azure—ensuring scalability, security, and peak efficiency.",
      btnLink: "contact-us",
    },
    {
      id: 8,
      icon: "/images/web-programming.png",
      heading: "Web app development",
      text: "Empower your digital presence with scalable, high-performance web apps designed for seamless user engagement and business growth",
      btnLink: "contact-us",
    },
    {
      id: 9,
      icon: "/images/mobile-development.png",
      heading: "Mobile app development",
      text: "Transform ideas into powerful, high-speed mobile apps with cutting-edge native, hybrid, and cross-platform solutions for iOS and Android.",
      btnLink: "contact-us",
    },
    {
      id: 6,
      icon: "/images/dekstop-icon.svg",
      heading: "Desktop app development",
      text: "Enhance productivity with custom-built, secure, and high-performance desktop apps tailored to your unique business needs.",
      btnLink: "contact-us",
    },
    {
      id: 10,
      icon: "/images/software-testing.png",
      heading: "QA & Software Testing",
      text: "Ensure flawless software with comprehensive QA services, manual testing, and automated solutions for seamless performance.",
      btnLink: "contact-us",
    },
    {
      id: 11,
      icon: "/images/video-marketing.png",
      heading: "Digital Branding & Growth",
      text: "Amplify your brand’s online presence with data-driven digital branding strategies to boost engagement and build lasting customer connections.",
      btnLink: "contact-us",
    },
  ];

  const scrollerData = [
    {
      text: "Hire Us",
      link: "hire-us",
    },
    {
      text: "Why Us",
      link: "why-us",
    },
    {
      text: "Our Stacks ",
      link: "our-stacks",
    },
    {
      text: "IT Services",
      link: "it-services",
    },
    {
      text: "Models",
      link: "engagment-modesl",
    },
    {
      text: "Industries ",
      link: "pioner-change-box",
    },
    {
      text: "Recognition",
      link: "globally-recognized",
    },
    // {
    //   text: "Case Study",
    //   link: "case-study",
    // },
    {
      text: "Insights",
      link: "trends",
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          AI, Blockchain & Technology Services | Bitsclan IT Solutions
        </title>
        <meta
          name="description"
          content="Unlock innovation with Bitsclan. Expert software development, AI services, mobile app solutions, and blockchain technology tailored to your needs. Contact us now"
        />
      </Helmet>

      {/* <Header /> */}

      <MegaHeader />

      <SlidOnScrollBanner sectionsData={UsaSectionsData} />

      <PageScroller content={UsaScrollerData} />

      {/* <HomeLogo /> */}

      <Testimonial
        heading="Best Development Company of the Year 2021"
        content="We received the Best Development Company of the Year award from Payoneer in 2021. We bring the same expertise, dedication, vision, and conviction to every service we provide."
      />

      <HireForUs
        HireUsData={HireUsData}
        heading="Hire us for"
        content={[
          "From advanced software development and IT consulting to blockchain, QA automation, and cybersecurity—we provide solutions that drive innovation.",
          "With Agile methodologies and Zero-Trust security, we ensure performance, scalability, and reliability.",
          "No matter the service you choose, we deliver exceptional quality and measurable impact.",
        ]}
      // content="From advanced software development and IT consulting to blockchain, QA automation, and cybersecurity—we provide solutions that drive innovation."
      />

      <WhyUs
        slidesData={slidesData}
        heading="for Software Development & Consulting Services?"
        greenHeading="Why Us"
        content="Bitsclan is not your run-of-the-mill technology company. We are your full-scale tech partners. We take ownership of the project and deliver it to the finish line."
      // secondContent="We take ownership of the project and deliver it to the finish line."
      />

      <CoverTheStack
        whereStart={"Frontend"}
        tabs={tabs}
        tabItems={tabItems}
        mainHeading={"Don't worry! We've got the tech stack you're looking for!"}
        // subHeading={
        //   "We have experts for every tech stack. "
        // }
        secondSubHeading={
          "We have experts in almost all major and common tech stacks. Our team is rapidly expanding, covering more programming languages and expertise. You can be confident you'll get the experts you need when you work with us."
        }
      />

      <MainEnterpriseItServices />

      {/* <EngagmentModels /> */}
      <NewEngagementModels />

      <PioneeringChange
        tabData={tabData}
        greenHeading={"Across Industries"}
        mainHeading={"Pioneering Change"}
        content={
          "Leading innovation and transforming sectors with cutting-edge solutions and insights."
        }
      />

      {/* <BrandImages /> */}

      <DevelopingSolution
        heading="Developing Solutions That Transcend Mere Digital Transformation"
        subheading="We deliver innovative solutions that surpass digital transformation, driving impactful and lasting success."
      />

      <TrendsInsights category="all"/>

      <OurLocations />

      <ContactSection />

      <Footer />

    </>
  );
}

export default UsaHome;

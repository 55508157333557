export const WebServicesIndustryTabsData = [
  "Healthcare",
  "Fintech",
  "Retail & eCommerce",
  "Real Estate",
  "Automotive",
  "Education",
  "Insurance",
  "Logistics",
  "Entertainment",
  "Gaming",
  "Social Networking",
  "Telecom",
  "Energy",
];

export const WebServicesIndustryTabsItemsData = [
  {
    tabName: "Healthcare",
    tabDes:
      "We’re helping transform the healthcare sector with web applications that make patient care simpler and more efficient. Whether it’s managing patient records securely, enabling telehealth consultations, or improving operations, our solutions are tailored to meet the needs of healthcare providers and their patients.",
    objectives: [
      "Manage patient data safely and easily.",
      "Create accessible telehealth platforms.",
      "Enhance healthcare services with innovative tools.",
    ],
    href: "/industry-detail/healthcare",
    tabImage: "health-care-image.png",
  },
  {
    tabName: "Fintech",
    tabDes:
      "Fintech thrives on innovation, and we’re here to drive it forward. Our web apps simplify complex financial processes, from secure payment systems to financial planning tools. They’re designed to empower businesses, enhance customer experiences, and keep everything secure along the way.",
    objectives: [
      "Simplify secure financial transactions.",
      "Build scalable platforms for growing businesses.",
      "Improve customer satisfaction with intuitive designs.",
    ],
    href: "/industry-detail/fintech",
    tabImage: "Fintech.png"
  },
  {
    tabName: "Retail & eCommerce",
    tabDes:
      "Shopping online should be seamless, and our web apps make sure it is. From personalized shopping experiences to advanced inventory management, we help businesses connect with customers, grow their sales, and create lasting impressions.",
    objectives: [
      "Create dynamic shopping platforms.",
      "Stay on top of inventory and orders in real-time.",
      "Engage customers with personalized features",
    ],
    href: "/industry-detail/retail-ecommerce",
    tabImage: "Retail-Ecommerce.png"
  },
  {
    tabName: "Real Estate",
    tabDes:
      "Finding and managing properties has never been easier. With our web apps, you can offer virtual tours, manage client relationships, and help buyers and sellers connect seamlessly. We build tools that keep real estate professionals one step ahead.",
    objectives: [
      "Showcase properties with virtual tours.",
      "Simplify property searches with user-friendly tools.",
      "Strengthen client relationships with easy-to-use platforms.",
    ],
    href: "/industry-detail/real-estate",
    tabImage: "Realestate.png"
  },
  {
    tabName: "Automotive",
    tabDes:
      "The automotive industry is moving fast, and our web apps keep pace. Whether it’s tracking vehicles, managing inventory, or engaging with customers, we create solutions that streamline operations and improve the overall experience.",
    objectives: [
      "Keep track of vehicles with advanced systems.",
      "Manage inventory and services efficiently.",
      "Enhance customer experiences with smart tools.",
    ],
    href: "/industry-detail/automotive",
    tabImage: "Automotive.png"
  },
  {
    tabName: "Education",
    tabDes:
      "Education should be engaging and accessible, and our web apps make it happen. From e-learning platforms to tools for managing classrooms, we design solutions that cater to students, educators, and institutions, bringing everyone closer to success.",
    objectives: [
      "Build interactive and engaging e-learning tools.",
      "Simplify administrative tasks for institutions.",
      "Personalize the learning experience for students.",
    ],
    href: "/industry-detail/education",
    tabImage: "Education.png"
  },
  {
    tabName: "Insurance",
    tabDes:
      "We make insurance processes smooth and hassle-free. Our web apps simplify claims, manage policies, and improve communication with customers. These secure and efficient solutions are built to adapt to the ever-changing needs of the insurance sector.",
    objectives: [
      "Simplify claims management and tracking.",
      "Handle sensitive policy data securely.",
      "Improve customer engagement with better tools.",
    ],
    href: "/industry-detail/insurance",
    tabImage: "Insurance.png"
  },
  {
    tabName: "Logistics",
    tabDes:
      "Efficiency is the backbone of logistics, and we’re here to deliver it. Our web apps streamline supply chains with real-time tracking, fleet management, and inventory control. Businesses stay organized and customers stay informed, every step of the way.",
    objectives: [
      "Track deliveries in real time.",
      "Manage fleets and inventories effortlessly.",
      "Keep operations running smoothly with smart solutions.",
    ],
    href: "/industry-detail/logistics",
    tabImage: "Logistics.png"
  },
  {
    tabName: "Entertainment",
    tabDes:
      "Entertainment is about creating memorable experiences, and our web apps make that possible. Whether it’s streaming, gaming, or interactive content, we design solutions that captivate audiences and deliver seamless performance.",
    objectives: [
      "Build scalable platforms for streaming content.",
      "Engage users with interactive features.",
      "Deliver personalized entertainment experiences.",
    ],
    href: "/industry-detail/entertainment",
    tabImage: "Entertainment.png"
  },
  {
    tabName: "Gaming",
    tabDes:
      "Gaming is about excitement, and our web apps take it to the next level. We build platforms that deliver immersive gameplay, real-time interactions, and scalable solutions for growing gaming communities.",
    objectives: [
      "Create multiplayer platforms with real-time features.",
      "Build tools for immersive and engaging experiences.",
      "Support growing user bases with scalable designs.",
    ],
    href: "/industry-detail/gaming",
    tabImage: "Gaming.png"
  },
  {
    tabName: "Social Networking",
    tabDes:
      "Connecting people is more important than ever, and our web apps make it easier. We create platforms for building communities, sharing content, and fostering meaningful interactions. Everything is designed to keep users engaged and connected.",
    objectives: [
      "Foster community building with interactive tools.",
      "Simplify multimedia sharing for users.",
      "Create platforms that grow with user needs.",
    ],
    href: "/industry-detail/social-networking",
    tabImage: "social-networking.png"
  },
  {
    tabName: "Telecom",
    tabDes:
      "The telecom industry is all about staying connected, and our web apps help make it happen. From streamlining communication to managing customer services, we design solutions that keep telecom providers ahead in a fast-moving industry.",
    objectives: [
      "Manage networks and customer services efficiently.",
      "Provide secure systems for billing and data.",
      "Enhance communication tools for better user experiences.",
    ],
    href: "/industry-detail/telecom",
    tabImage: "Telecom.png"
  },
  {
    tabName: "Energy",
    tabDes:
      "Energy management needs smarter solutions, and we deliver. Our web apps optimize resources, monitor usage, and support sustainability efforts. Whether it’s grid management or energy analytics, we help you run smoother and greener operations.",
    objectives: [
      "Monitor energy usage in real-time.",
      "Build scalable tools for grid management.",
      "Support sustainability with smart analytics.",
    ],
    href: "/industry-detail/energy",
    tabImage: "Energy.png"
  },
];

export const WebServicesFaqData = [
  {
    id: "collapseOne",
    question: "What does it cost to build a web app?",
    answer:
      "The price of developing a web application depends upon its complexity, project timeline, and other factors like these. To help you understand, a simple application can be made for $1,500. On the other hand, an advanced web application with AI features can take up to $50,000 to develop.",
  },
  {
    id: "collapseTwo",
    question: "What kinds of web applications can you create?",
    answer:
      "We specialize in developing a wide range of web applications, including enterprise solutions, e-commerce platforms, customer portals, supply chain management systems, CRM/ERP systems, and more. Our expertise allows us to tailor applications to meet the specific needs of various industries.",
  },
  {
    id: "collapseThree",
    question: "How does a Progressive Web App function?",
    answer:
      "Progressive Web Apps (PWAs) combine the best of web and mobile apps. They work by utilizing modern web capabilities to deliver an app-like experience in the browser, including offline access, push notifications, and faster load times, all without needing to download from an app store.",
  },
  {
    id: "fourth",
    question: "What is the typical timeline for building a web application?",
    answer:
      "The timeline for developing a web application varies based on its complexity, features, and scope. On average, a basic web app may take 3-6 months, while more complex applications can take 6-12 months or longer, from initial discovery to final deployment.",
  },
  {
    id: "fifth",
    question: "How do you protect the security of web applications?",
    answer:
      "We prioritize security throughout the development process by implementing best practices such as encryption, secure authentication, and regular security audits. We also adhere to OWASP guidelines and ensure compliance with relevant industry standards to safeguard your web application against potential threats.",
  },
  {
    id: "sixth",
    question:
      "What should I consider when selecting a web application development company?",
    answer:
      "When choosing a web application development partner, look for a company with a strong portfolio, proven expertise in your industry, transparent communication, and a clear understanding of your business goals. It's also essential to ensure they follow best practices for security, scalability, and performance.",
  },
  {
    id: "seventh",
    question:
      "How does web application development differ from website development?",
    answer:
      "Web application development focuses on creating interactive, dynamic systems that perform complex tasks, such as user management, data processing, and real-time interaction. In contrast, website development typically involves creating static or content-driven sites that display information and may have limited interactive features.",
  },
];

export const WebServicesHighvaluesData = [
  {
    image: "custom-app-development.svg",
    text: "Custom App Development",
  },
  {
    image: "user-centric-designs.svg",
    text: "User-Centric Designs",
  },
  {
    image: "robust-architecture.svg",
    text: "Robust Architecture",
  },
  {
    image: "efficient-integration.svg",
    text: "Efficient Integration",
  },
  {
    image: "advanced-analytics.svg",
    text: "Advanced Analytics",
  },
  {
    image: "enhanced-security.svg",
    text: "Enhanced Security",
  },
  {
    image: "agile-methodology.svg",
    text: "Agile Methodology",
  },
  {
    image: "cross-platform-support.svg",
    text: "Cross-Platform Support",
  },
  {
    image: "ongoing-maintenance.svg",
    text: "Ongoing Maintenance",
  },
  {
    image: "innovation-driven-features.svg",
    text: "Innovation-Driven Features",
  },
];

import React, { useState } from "react";
import "./style.css";
import { Link } from "react-router-dom";

const PioneeringChange = ({ mainHeading, greenHeading, content, tabData }) => {
  const [activeTab, setActiveTab] = useState(0);
  const handleGtmClick = (industryName) => {
    // Push data to Google Tag Manager (GTM)
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "industry-btn-click",
      industry_title: industryName,
    });
  };
  return (
    <>
      <div className="poineering-change-box" id="pioner-change-box">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="poinering-change-box">
                <h1>
                  {mainHeading} <span>{greenHeading}</span>
                </h1>
                <p className="common-text">{content}</p>

                <ul className="poineering-list">
                  {tabData.map((tab, index) => (
                    <li
                      id="poineering-industry-title"
                      key={index}
                      className={
                        activeTab === index ? "active-poineer-list" : ""
                      }
                      onClick={() => {
                        setActiveTab(index);
                        handleGtmClick(tab.title);
                      }}
                    >
                      <img src={`${tab.icon}`} alt="Icon" />
                      <span>{tab.title}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="col-md-8">
              <div className="pioner-new-image pioneer-chnage-box">
                <div
                  className="pioneering-main-img"
                  style={{
                    background: `url('/images/pioner-imges/${tabData[activeTab].image}')`,
                  }}
                >
                  <div className="pioner-text-box">
                    <h2>{tabData[activeTab].title}</h2>

                    <p className="common-text ">
                      {tabData[activeTab].description}
                    </p>
                    <Link
                      id="industry_link_click"
                      to={`/industry-detail/${tabData[activeTab]?.slug}`}
                      onClick={() =>
                        window.dataLayer.push({
                          event: "industry_link_click",
                          industry_title: tabData[activeTab].title,
                          industry_slug: `/industry-detail/${tabData[activeTab]?.slug}`,
                        })
                      }
                    >
                      Learn more about our {tabData[activeTab].title} Industry{" "}
                      <i className="fa-solid fa-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="comingsoon-model"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabindex="-1"
      >
        <div class="modal-dialog shedule-call-model-box modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div class="modal-body">
              <div className="d-flex justify-content-center">
                <img src="/images/logo.png" width={300} alt="Logo" />
              </div>
              <h1
                style={{
                  fontSize: "42px",
                  textAlign: "center",
                  color: "#000000",
                }}
                className="mt-2"
              >
                Coming Soon,
              </h1>
              <h1
                style={{
                  fontSize: "42px",
                  textAlign: "center",
                  color: "#000000",
                }}
              >
                We Are Working On It.
              </h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PioneeringChange;

import React from "react";
import Footer from "../components/footer/Footer";
import ContactBanner from "../components/contactbanner/ContactBanner";
import OurLocations from "../components/ourlocations/OurLocations";
import { Helmet } from "react-helmet";
import MegaHeader from "../components/MegaHeader/MegaHeader";

const ContactUs = () => {

  return (
    <>
      <Helmet>
        <title>Get in Touch for Expert Services | Bitsclan IT Solutions</title>
        <meta
          name="description"
          content="Have questions or need assistance? Contact us at Bitsclan IT Solutions for expert AI services, software development & more. Book free consultation now."
        />
      </Helmet>
      <div className="sticky-header">
        <MegaHeader />
      </div>
      <ContactBanner />
      <OurLocations />
      <Footer />
    </>
  );
};

export default ContactUs;

import React, { useRef, useState } from "react";
import './style.css'

const HirePageAppIdea = ({ content, heading }) => {

    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [showControls, setShowControls] = useState(false);

    const togglePlayPause = () => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    return (
        <>
            <div className="hire-page-app-idea-box">
                <div className="container">
                    <div className="row">
                        <div class="col-md-12">
                            <h1 class="main-heading">{heading || "Not sure about your app idea? Get your prototype up in 6-8 weeks!"}</h1>
                            <h3 class="sub-heading">{content}</h3>
                        </div>
                    </div>

                    <div className="row align-items-center justify-content-center mt-5">
                        <div className="col-xl-9 col-lg-9 col-md-12">
                            <div className="app-idea-image">
                                <img src="/images/hire-page-images/app-idea-image.svg" alt="App Idea" />
                            </div>
                        </div>

                        {/* <div className="col-md-4">
                            <div className="app-idea-vedio-section">
                                <div
                                    className="video-container position-relative"
                                    onMouseEnter={() => setShowControls(true)}
                                    onMouseLeave={() => setShowControls(false)}
                                >
                                    <video
                                        ref={videoRef}
                                        className="w-100 rounded vedio-box-app-idea"
                                        src="/videos/home-banner.mp4"
                                        muted
                                    ></video>

                                    {!isPlaying && (
                                        <div
                                            className="play-button position-absolute top-50 start-50 translate-middle d-flex align-items-center justify-content-center"
                                            onClick={togglePlayPause}
                                        >
                                            <i className="fa-solid fa-play"></i>
                                        </div>
                                    )}

                                    {isPlaying && showControls && (
                                        <div
                                            className="pause-button position-absolute top-50 start-50 translate-middle d-flex align-items-center justify-content-center"
                                            onClick={togglePlayPause}
                                        >
                                            <i className="fa-solid fa-pause"></i>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div class="hire-us-cta-btn d-flex justify-content-center mt-4">
                                <a role="button" href="https://calendly.com/bitsclan-it/30min?month=2025-01" target="_blank" class="ms-0">Get a Free Consultation</a>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default HirePageAppIdea
export const tabs = [
    "Backend",
    "Frontend",
    "Database",
    "Frameworks",
    "DevOps",
    "Quality Assurance",
];

export const tabItems = [
    { tab: "Frontend", icon: "/images/iconone.png", name: "Angular JS" },
    { tab: "Frontend", icon: "/images/icontwo.png", name: "JavaScript" },
    { tab: "Frontend", icon: "/images/iconthree.png", name: "React JS" },
    { tab: "Frontend", icon: "/images/icon4.png", name: "Vue JS" },
    { tab: "Frontend", icon: "/images/mobile3.png", name: "Swift" },
    { tab: "Frontend", icon: "/images/icon6.png", name: "CSS3" },
    { tab: "Frontend", icon: "/images/icon7.png", name: "HTML5" },
    { tab: "Backend", icon: "/images/database1.png", name: ".Net" },
    { tab: "Backend", icon: "/images/database2.png", name: "Nest Js" },
    { tab: "Backend", icon: "/images/database3.png", name: "Meteor JS" },
    { tab: "Backend", icon: "/images/database5.png", name: "Express JS" },
    { tab: "Backend", icon: "/images/database6.png", name: "Python" },
    { tab: "Backend", icon: "/images/django-tech.svg", name: "Django" },
    { tab: "Backend", icon: "/images/database8.png", name: "PHP" },
    { tab: "Backend", icon: "/images/database9.png", name: "Node Js" },
    { tab: "Database", icon: "/images/db1.png", name: "Firebase" },
    { tab: "Database", icon: "/images/db2.png", name: "Oracle " },
    { tab: "Database", icon: "/images/db3.png", name: "MongoDB" },
    { tab: "Database", icon: "/images/tech-stack-icons/postgres.svg", name: "PostgreSQL" },
    { tab: "Database", icon: "/images/db5.png", name: "MySQL" },
    { tab: "Database", icon: "/images/db6.png", name: "SQL Server" },
    { tab: "Database", icon: "/images/db7.png", name: "SQLite" },
    { tab: "Database", icon: "/images/db8.png", name: "DynamoDB" },
    { tab: "Frameworks", icon: "/images/framework1.png", name: "MEAN" },
    { tab: "Frameworks", icon: "/images/framework2.png", name: "CodeIgniter" },
    { tab: "Frameworks", icon: "/images/framework3.png", name: "Laravel" },
    { tab: "Frameworks", icon: "/images/framework4.png", name: "Rxweb.io" },
    { tab: "Frameworks", icon: "/images/framework5.png", name: "CakePHP" },
    { tab: "Frameworks", icon: "/images/framework6.png", name: "Django" },
    { tab: "DevOps", icon: "/images/devops1.png", name: "Gradle" },
    { tab: "DevOps", icon: "/images/devops2.png", name: "Kubernetes" },
    { tab: "DevOps", icon: "/images/devops3.png", name: "Docker" },
    { tab: "DevOps", icon: "/images/devops4.png", name: "Jenkins" },
    { tab: "Quality Assurance", icon: "/images/qa1.svg", name: "TestRail" },
    { tab: "Quality Assurance", icon: "/images/qa2.svg", name: "LoadRunner" },
    { tab: "Quality Assurance", icon: "/images/qa3.svg", name: "Cucumber" },
    { tab: "Quality Assurance", icon: "/images/qa4.svg", name: "TestNG" },
    { tab: "Quality Assurance", icon: "/images/qa5.svg", name: "Appium" },
    { tab: "Quality Assurance", icon: "/images/qa6.svg", name: "Selenium" },
];

export const faqData = [
    {
        id: "collapseOne",
        question: "Why do I need cybersecurity services for my business?",
        answer:
            "Cyber threats are everywhere, and a single attack can put your business at risk. Our cybersecurity services keep your data safe, prevent breaches, and ensure smooth operations.",
    },
    {
        id: "collapseTwo",
        question: "How do you protect my business from cyberattacks?",
        answer:
            "We use a mix of advanced tools like threat detection, encryption, and real-time monitoring to catch and stop cyber threats before they cause any harm.",
    },
    {
        id: "collapseThree",
        question: "Is cybersecurity only for big companies?",
        answer:
            "Not at all! Whether you're a startup or a large enterprise, cybercriminals don’t discriminate. Our cybersecurity solutions are tailored for businesses of all sizes.",
    },
    {
        id: "fourth",
        question: "What happens if my business gets hacked?",
        answer:
            "If a breach happens, our team acts fast to contain it, minimize damage, and recover lost data. Plus, we help you strengthen security to prevent future attacks.",
    },
    {
        id: "fifth",
        question: "Do I need cybersecurity even if I have an antivirus?",
        answer:
            "Yes! Antivirus is just one layer of protection. Our cybersecurity services cover everything from network security to data encryption and proactive threat defense.",
    },
    {
        id: "sixth",
        question: "How can I get started with your cybersecurity services?",
        answer:
            "It’s super simple! Just reach out to us, and we’ll assess your security needs, recommend solutions, and get you fully protected in no time.",
    },
];

export const TypesOfBuildData = [
    {
        id: "collapseOne",
        question: "Protecting Sensitive Data",
        answer: {
            discription:
                "Cyber threats are always evolving, and safeguarding your data is more important than ever. We implement advanced encryption, secure access controls, and compliance-driven security protocols to protect your sensitive business information from unauthorized access or breaches.",
            listTitle: "",
            listContent: [
                {
                    line: "End-to-end encryption for secure data transmission",
                },
                {
                    line: "Multi-layer authentication for stronger access control",
                },
                {
                    line: "Compliance with industry standards for robust security",
                },
            ],
        },
    },
    {
        id: "collapseTwo",
        question: "Preventing Cyber Attacks",
        answer: {
            discription:
                "Cyber threats can be devastating for businesses, causing financial and reputational damage. Our proactive security measures, penetration testing, and AI-driven threat detection help prevent cyber attacks before they even happen.",
            // listTitle: "Our native mobile apps are best for:",
            listContent: [
                {
                    line: "Continuous security monitoring to detect vulnerabilities",
                },
                {
                    line: "AI-driven threat detection for real-time risk mitigation",
                },
                {
                    line: "Regular penetration testing to uncover system weaknesses",
                },
            ],
        },
    },
    {
        id: "collapseThree",
        question: "Ensuring Regulatory Compliance",
        answer: {
            discription:
                "Cybersecurity compliance isn’t just about protection—it’s about trust. Our compliance-driven security frameworks ensure that your business meets industry regulations like GDPR, HIPAA, and ISO 27001, keeping you legally compliant and ahead of risks.",
            // listTitle: "Our native mobile apps are best for:",
            listContent: [
                {
                    line: "Compliance audits to meet industry regulations",
                },
                {
                    line: "Risk assessments to identify security gaps",
                },
                {
                    line: "Secure data storage for better regulatory adherence",
                },
            ],
        },
    },
    {
        id: "fourth",
        question: "Maintaining Customer Trust",
        answer: {
            discription:
                "Your customers expect security and privacy when they interact with your digital platforms. Our comprehensive cybersecurity strategies protect user data, ensuring a safe and trustworthy experience across all digital touchpoints.",
            // listTitle: "Our native mobile apps are best for:",
            listContent: [
                {
                    line: "Secure payment gateways to protect transactions",
                },
                {
                    line: "Privacy-first solutions for user data protection",
                },
                {
                    line: " Regular security updates to mitigate emerging threats",
                },
            ],
        },
    },
    {
        id: "fifth",
        question: "Supporting Business Continuity",
        answer: {
            discription:
                "A single cyber attack can bring operations to a halt. Our business continuity solutions, including disaster recovery, cloud backups, and incident response planning, ensure that your business stays up and running no matter what.",
            // listTitle: "Our native mobile apps are best for:",
            listContent: [
                {
                    line: "Automated cloud backups for secure data recovery",
                },
                {
                    line: "Incident response plans for fast mitigation of cyber threats",
                },
                {
                    line: "Resilient security architecture to prevent downtime",
                },
            ],
        },
    },
];

export const WhoWeServeData = [
    {
        icon: "/images/servie-icon/startups.svg",
        title: "Emerging Startups",
        description:
            "Startups often lack in-house cybersecurity experts. We offer affordable and scalable cybersecurity solutions to safeguard sensitive data from cyber threats.",
        link: "#",
        borderClass: "border-1",
    },
    {
        icon: "/images/servie-icon/companies.svg",
        title: "Cybersecurity for SaaS & Tech Companies",
        description:
            "Tech companies require robust network security and penetration testing. We implement strong data protection measures to prevent vulnerabilities.",
        link: "#",
        borderClass: "border-2",
    },
    {
        icon: "/images/servie-icon/industries.svg",
        title: "Enterprise-Level Security for Businesses",
        description:
            "Enterprises handle massive amounts of data. Our cybersecurity services provide advanced threat detection and compliance support to avoid breaches.",
        link: "#",
        borderClass: "border-3",
    },
];

export const HighvaluesData = [
    {
        image: "custom-app-development.svg",
        text: "Advanced Threat Detection",
    },
    {
        image: "user-centric-designs.svg",
        text: "End-to-End Data Encryption",
    },
    {
        image: "robust-architecture.svg",
        text: "Secure Network Infrastructure",
    },
    {
        image: "efficient-integration.svg",
        text: "Identity & Access Management",
    },
    {
        image: "advanced-analytics.svg",
        text: "Real-Time Security Monitoring",
    },
    {
        image: "enhanced-security.svg",
        text: "AI-Powered Risk Analysis",
    },
    {
        image: "agile-methodology.svg",
        text: "Cloud Security Solutions",
    },
    {
        image: "cross-platform-support.svg",
        text: "Compliance & Regulatory Security",
    },
    {
        image: "ongoing-maintenance.svg",
        text: "Incident Response & Recovery",
    },
    {
        image: "innovation-driven-features.svg",
        text: "Cybersecurity Training & Awareness",
    },
];

export const WhyChooseBitsclanData = [
    {
        id: "Tab1",
        label: "Uncompromised Quality",
        content:
            "Security is at the core of everything we do. Our cybersecurity solutions are built with precision, ensuring multi-layered protection that safeguards your business from data breaches, ransomware, and unauthorized access. We adhere to the highest industry standards to deliver reliable and scalable security solutions.",
        btnText: "Connect with the clan",
        btnLink: "#",
    },
    {
        id: "Tab2",
        label: "Top-Tier Talent",
        content:
            "Our team consists of experienced cybersecurity professionals, ethical hackers, and compliance specialists who bring deep industry expertise. From penetration testing to incident response, we provide expert guidance to help you build a resilient cybersecurity framework.",
        btnText: "Connect with the clan",
        btnLink: "#",
    },
    {
        id: "Tab3",
        label: "Time zone Alignment",
        content:
            "Cyber threats don’t wait, and neither do we. With a globally distributed team, we provide 24/7 security support, incident response, and real-time monitoring, ensuring your business remains protected regardless of your time zone or location.",
        btnText: "Connect with the clan",
        btnLink: "#",
    },
    {
        id: "Tab4",
        label: "Proactive Threat Management",
        content:
            "Instead of reacting to security incidents after they occur, we take a proactive approach. Using AI-driven analytics, behavioral threat detection, and vulnerability assessments, we identify risks early and implement mitigation strategies to prevent breaches before they happen.",
        btnText: "Connect with the clan",
        btnLink: "#",
    },
    {
        id: "Tab5",
        label: "Compliance-Ready Security",
        content:
            "Regulatory compliance is a critical aspect of modern cybersecurity. We help businesses meet and maintain compliance with standards such as GDPR, HIPAA, ISO 27001, SOC 2, and PCI-DSS. Our tailored security strategies ensure that your organization remains compliant while minimizing risks and legal liabilities.",
        btnText: "Connect with the clan",
        btnLink: "#",
    },
];

export const DevelopmentServicesData = [
    {
        id: "Tab1",
        label: "Secure Web Portals",
        content:
            "Web portals are vital access points for businesses but are also prime targets for cyber threats. Our secure web portal solutions use end-to-end encryption, multi-factor authentication (MFA), and strict access controls to protect sensitive data. With continuous monitoring and real-time threat detection, we prevent unauthorized access and mitigate cyber risks. Whether for customers, employees, or business applications, our security measures ensure safe and seamless portal access while safeguarding your digital assets from breaches.",
        btnText: "Let's Discuss",
        btnLink: "#",
        imgs: [
            {
                img: "/images/services-icons/cybersecurity/burp-suite.png",
            },
            {
                img: "/images/services-icons/cybersecurity/Kali_Linux_Logo.png",
            },
            {
                img: "/images/services-icons/cybersecurity/Wireshark_Logo.png",
            },
        ],
    },
    {
        id: "Tab2",
        label: "Cybersecurity-Driven Backend Development",
        content:
            "A secure backend is crucial for data protection and preventing cyber threats. We build robust backend architectures with encryption, intrusion detection, and strict access controls. Our solutions secure APIs, prevent data leaks, and provide scalable security for cloud, hybrid, and on-premise systems. Through proactive threat detection and vulnerability assessments, we ensure your backend remains resilient against attacks, safeguarding critical business data and operations from unauthorized access, breaches, and evolving cybersecurity risks.",
        btnText: "Let's Discuss",
        btnLink: "#",
        imgs: [
            {
                img: "/images/services-icons/cybersecurity/burp-suite.png",
            },
            {
                img: "/images/services-icons/cybersecurity/Kali_Linux_Logo.png",
            },
            {
                img: "/images/services-icons/cybersecurity/Wireshark_Logo.png",
            },
        ],
    },
    {
        id: "Tab3",
        label: "Secure Frontend Development",
        content:
            "Frontend security is essential for protecting user interactions and sensitive data. Our secure development practices prevent threats like XSS and SQL injections through secure authentication, encrypted data handling, and strict session management. By integrating proactive vulnerability testing and secure input validation, we safeguard applications from cyber threats. Our approach ensures fast, reliable, and user-friendly applications that maintain data integrity while protecting users and businesses from malicious attacks and unauthorized access attempts.",
        btnText: "Let's Discuss",
        btnLink: "#",
        imgs: [
            {
                img: "/images/services-icons/cybersecurity/burp-suite.png",
            },
            {
                img: "/images/services-icons/cybersecurity/Kali_Linux_Logo.png",
            },
            {
                img: "/images/services-icons/cybersecurity/Wireshark_Logo.png",
            },
        ],
    },
    {
        id: "Tab4",
        label: "Cybersecurity Consulting Services",
        content:
            "Cybersecurity is complex, but we simplify it with expert consulting. We assess risks, enhance security posture, and ensure compliance with GDPR, HIPAA, and ISO standards. Our security audits, risk assessments, and tailored strategies help businesses mitigate cyber risks, prevent data breaches, and strengthen overall resilience. Whether you're a startup or an enterprise, our cybersecurity experts provide actionable insights and industry-best solutions to safeguard your business from evolving digital threats and regulatory challenges.",
        btnText: "Let's Discuss",
        btnLink: "#",
        imgs: [
            {
                img: "/images/services-icons/cybersecurity/burp-suite.png",
            },
            {
                img: "/images/services-icons/cybersecurity/Kali_Linux_Logo.png",
            },
            {
                img: "/images/services-icons/cybersecurity/Wireshark_Logo.png",
            },
        ],
    },
    {
        id: "Tab5",
        label: "Enterprise Cybersecurity Solutions",
        content:
            "Enterprises face sophisticated cyber threats that require robust defenses. Our enterprise cybersecurity solutions secure networks, cloud environments, and applications with advanced intrusion prevention, endpoint security, and data loss prevention (DLP). With real-time threat intelligence, Security Information and Event Management (SIEM), and proactive monitoring, we protect enterprises from breaches and unauthorized access. Our comprehensive security measures ensure business continuity, regulatory compliance, and long-term protection against emerging cyber threats.",
        btnText: "Let's Discuss",
        btnLink: "#",
        imgs: [
            {
                img: "/images/services-icons/cybersecurity/burp-suite.png",
            },
            {
                img: "/images/services-icons/cybersecurity/Kali_Linux_Logo.png",
            },
            {
                img: "/images/services-icons/cybersecurity/Wireshark_Logo.png",
            },
        ],
    },
    {
        id: "Tab6",
        label: "Penetration Testing & Threat Detection",
        content:
            "Cyber threats evolve constantly, making proactive security vital. Our penetration testing and threat detection services simulate real-world attacks, identify vulnerabilities, and provide actionable fixes. We conduct ethical hacking, vulnerability assessments, and red team exercises to strengthen your defenses. With advanced threat detection and rapid incident response, we help businesses stay ahead of cyber risks, ensuring security gaps are closed before attackers exploit them. Strengthen your security with our expert-driven approach.",
        btnText: "Let's Discuss",
        btnLink: "#",
        imgs: [
            {
                img: "/images/services-icons/cybersecurity/burp-suite.png",
            },
            {
                img: "/images/services-icons/cybersecurity/Kali_Linux_Logo.png",
            },
            {
                img: "/images/services-icons/cybersecurity/Wireshark_Logo.png",
            },
        ],
    },
    {
        id: "Tab7",
        label: "Data Encryption & Compliance Services",
        content:
            "Protecting sensitive business and customer data is crucial for security and compliance. We offer advanced encryption solutions to secure data in transit and at rest, ensuring it remains protected from unauthorized access. Our compliance services help businesses meet regulatory requirements like GDPR, HIPAA, and ISO 27001. By implementing strong encryption, governance frameworks, and risk assessments, we safeguard confidential information, minimize legal risks, and maintain industry-leading security standards across all digital assets.",
        btnText: "Let's Discuss",
        btnLink: "#",
        imgs: [
            {
                img: "/images/services-icons/cybersecurity/burp-suite.png",
            },
            {
                img: "/images/services-icons/cybersecurity/Kali_Linux_Logo.png",
            },
            {
                img: "/images/services-icons/cybersecurity/Wireshark_Logo.png",
            },
        ],
    },
];

export var mainBannerImg = {
    backgroundImage: `url(/images/what-we-do/cybersecurity-services.png)`
}
import React from "react";
import "./style.css";
import { Link } from "react-router-dom";

const DevelopmentPerformance = ({
  heading,
  subHeading,
  solutions,
  greenHeading,
  BorderClass,
}) => {
  return (
    <>
      <div className="future-solutions-box">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="engagment-model-text">
                {heading} <span>{greenHeading}</span>
              </h1>
              <h3 className="sub-heading">{subHeading}</h3>
            </div>
          </div>

          <div className={`row border-box-industry ${BorderClass}`}>
            {solutions.map((solution, index) => (
              <div
                className={`col-md-6 ${solution.noBorder ? "none-border" : ""}`}
                key={index}
              >
                {solution?.icon && (
                  <div className="icon-image-here">
                    <img src={solution?.icon} alt={`${solution?.icon}`} />
                  </div>
                )}

                <div className="development-services-text-box">
                  <h1>{solution.title}</h1>
                  <p className="common-text">{solution.description}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="w-100 d-flex justify-content-center mt-5">
            <Link id="hire-experts-why-chose-us-click" to="/hire-contact" className="banner-button-box">
              Hire a developer today
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default DevelopmentPerformance;

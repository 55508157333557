import React from "react";
import "./styles.css";

const DarkRoadMap = () => {
  return (
    <>
      <div className="dark-road-map-box">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="dark-engagment-model-text">
                Our <span>Proven Roadmap</span> to Ensure Your Success
              </h1>
              <h3 className="dark-sub-heading">
                As a leading AI development services provider, we have developed
                a structured roadmap to ensure the success of your projects.
                From initial discovery to ongoing support, we follow a
                systematic approach that guarantees alignment with your business
                goals, delivers high-quality solutions, and provides continuous
                improvement. Here’s how we do it:
              </h3>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="dark-prover-box-map-left ">
                <div className="dark-proven-roadmap-text dark-road-map-left-space second">
                  <h2>Design & Proof of Concept</h2>
                  <p className="dark-common-text">
                    Turn your ideas into reality with a clear proof of concept.
                    We design architectures and validate feasibility, ensuring
                    custom AI development tailored to your needs.
                  </p>
                </div>

                <div className="dark-proven-roadmap-text forth">
                  <h2>Development & Testing</h2>
                  <p className="dark-common-text">
                    Our team builds, tests, and refines your AI systems,
                    ensuring smooth functionality. With rigorous validation, we
                    deliver reliable and future-ready AI development services.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 none-tab-box position-relative">
              <div className="dark-road-image-box dark-ai-development-road-map">
                <img
                  className="none-tab"
                  src="/images/roadmap-fff.svg"
                  alt="Road Map"
                />
                <img
                  className="none-web-box"
                  src="/images/roadmap-tablet/roadmap4-tab.png"
                  alt="Road Map"
                />
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="right-box-text ">
                <div className="dark-proven-roadmap-text dark-road-map-left-space first">
                  <h2>Discovery & Planning</h2>
                  <p className="dark-common-text">
                    We collaborate to understand your goals, create a roadmap,
                    and align our AI solutions with your business vision,
                    ensuring seamless planning and high-quality results.
                  </p>
                </div>

                <div className="dark-proven-roadmap-text third">
                  <h2>Implementation & Support</h2>
                  <p className="darkcommon-text">
                    We bring your project to life with expert implementation.
                    From setting clear goals to offering ongoing support, we
                    ensure your AI solutions are always optimized.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DarkRoadMap;

import React from "react";
import { Helmet } from "react-helmet";
import HirePageBanner from "../components/HirePageBanner/HirePageBanner";
import DevelopmentPerformance from "../components/DevelopmentPerformance/DevelopmentPerformance";
import SecondFaq from "../components/secondFaq/SecondFaq";
import Footer from "../components/footer/Footer";
import MegaHeader from "../components/MegaHeader/MegaHeader";
import {
  HireFluterFactsData,
  HireFluterFaqData,
  HireFluterHirignDetails,
  HireFluterSteps,
  HireFlutterBannerList,
  HireFlutterServicesData,
  HireFlutterSolutionsData,
  mainBannerImg,
  tabItems,
  tabs,
  techCombinations,
} from "../data/hireFlutterPageData";
import HirePageExperties from "../components/hirepageExperties/HirePageExperties";
import HirePageSteps from "../components/hire-page-steps/HirePageSteps";
import HireExpertFacts from "../components/hire-expert-facts/HireExpertFacts";
import HirePageEngagmentModels from "../components/hire-page-engagment-models/HirePageEngagmentModels";
import HirePageAppIdea from "../components/hire-page-app-idea/HirePageAppIdea";
import ContactSection from "../components/contactSection/ContactSection";
import TrendsInsights from "../components/trends-n-insights/TrendsInsights";
import HireCombinationSection from "../components/hire-combination-sectiono/HireCombinationSection";
import CoverTheStack from "../components/coverthestack/CoverTheStack";

const HireFluter = () => {
  return (
    <>
      <Helmet>
        <title>Hire Expert Flutter Developers</title>
        <meta
          name="description"
          content="Looking to hire skilled Flutter developers? Bitsclan offers pre-vetted, experienced professionals to build high-quality, feature-rich apps. Schedule a call today to get started!"
        />
      </Helmet>

      <div className="sticky-header">
        <MegaHeader />
      </div>

      <HirePageBanner
        diffBannerBackgroundImage={mainBannerImg?.backgroundImage}
        image={""}
        mainHeading="Hire Flutter App Developers"
        content="Looking to hire expert Flutter developers? We provide pre-vetted professionals to build high-performance, cross-platform mobile apps tailored for startups and enterprises."
        btnText="Consult our Flutter developers"
        bannerList={HireFlutterBannerList}
      />

      <HirePageExperties
        heading="Our Flutter Developer Expertise"
        discription="Our Flutter developers specialize in building fast, user-friendly, and feature-rich cross-platform applications. Whether it's a simple mobile app or a complex enterprise solution, we ensure seamless performance across iOS and Android."
        servicesData={HireFlutterServicesData}
      />

      <DevelopmentPerformance
        heading="Why Hire Flutter Developers "
        greenHeading="from Bitsclan?"
        subHeading=" At Bitsclan, we leverage Flutter’s capabilities with the latest mobile and web technologies to deliver robust, scalable, and high-performance applications customized to your business needs."
        solutions={HireFlutterSolutionsData}
      />

      <HireCombinationSection combinations={techCombinations} heading="Top Flutter Technology Combinations for Cutting-Edge Development" description="Unlock the full potential of Flutter with the best technology combinations to build high-performance, cross-platform applications. Hire expert Flutter developers to ensure seamless, scalable, and visually stunning solutions." />

      <HirePageSteps
        heading={"Hire Flutter Developers in 4 Easy Steps"}
        subHeading={
          "Hiring expert Flutter developers is simple with our 4-step process. We focus on making sure you get the right developer for your project—fast, reliable, and cost-effective."
        }
        steps={HireFluterSteps}
      />

      <CoverTheStack
        whereStart={"Backend"}
        mainHeading={"Build High-Performance Cross-Platform Apps with Flutter"}
        subHeading={"At Bitsclan, we empower your app development journey with a robust Flutter tech stack that ensures seamless performance across multiple platforms. Our expert Flutter developers leverage cutting-edge technologies to build scalable, high-quality mobile and web applications tailored to your business needs."}
        tabs={tabs}
        tabItems={tabItems} />

      <HireExpertFacts
        heading="Quick Facts About "
        greenHeading="Our Flutter Team"
        facts={HireFluterFactsData}
        diffBannerBackgroundImage={mainBannerImg?.factBackgroundImage}
      />

      {/* <WhoWeServe
        cardContent={HireFluterWhoWeServeData}
        heading="Benefits of outsourced Flutter development"
        subHeading="Our business partners always expect minimal risks and professionalism in Flutter development. We are dedicated to providing our clients with reliable and highly skilled developers who are capable of either building a product from scratch or enhancing one that already exists. Here are the key benefits of outsourcing Ruby on Rails to Innowise."
      /> */}

      <HirePageAppIdea content="Have an idea but unsure how to proceed? Our Flutter experts can help you build a functional prototype in just 6-8 weeks! Validate your vision, test user engagement, and establish a strong foundation for success with our expert mobile app development services." />
      <HirePageEngagmentModels description="Choose the engagement model that fits your project needs! Whether you need full-time, part-time, or hourly developers, we offer flexible options to ensure seamless collaboration and maximum efficiency throughout your project." hirignDetails={HireFluterHirignDetails} />

      {/* <HirePageWeDeliver hirePageDeliver={HireFlutterPageDeliverData} />

      <HirePageTabs /> */}

      <TrendsInsights specificClass="mobile-dev-page" category="all" />

      <SecondFaq
        specificClass="mobile-dev-page"
        faqs={HireFluterFaqData}
        mainHeading={"Frequently Asked Questions"}
        subHeading={"Discover answers to common questions about Flutter."}
      />

      {/* <HirePageServicesForm /> */}

      <ContactSection />

      <Footer />
    </>
  );
};

export default HireFluter;

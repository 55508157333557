import React from "react";
import "./style.css";
import { Link } from "react-router-dom";
import blogsSlidesData from "../../data/blogsData";

const blogContent = [
  {
    id: 1,
    title: "What SEO, SEM, and SMM do to boost your sales?",
    content:
      "We've collated the top 20 UI inspiration sites, all with links in one handy spot! Find your inspiration for your next project.",
    linkURL: "/blog-details/test",
    bgImg: "/images/blog-images/big-blog.png",
    author: "By Bitsclan IT Solutions",
    icon: "/images/blog-images/auth-logo.png",
  },
  {
    id: 2,
    title: "Protecting Your Digital Assets from Cyber Threats",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.",
    linkURL: "/blog-details/test",
    bgImg: "/images/blog-images/small-blog-1.png",
    author: "By Bitsclan IT Solutions",
    icon: "/images/blog-images/auth-logo.png",
  },
  {
    id: 3,
    title: "Are AI and Automation the Future of Tech Industry?",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.",
    linkURL: "/blog-details/test",
    bgImg: "/images/blog-images/small-blog-2.png",
    author: "By Bitsclan IT Solutions",
    icon: "/images/blog-images/auth-logo.png",
  },
  {
    id: 4,
    title: "Cloud Computing: The Future of IT Infrastructure",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.",
    linkURL: "/blog-details/test",
    bgImg: "/images/blog-images/small-blog-3.png",
    author: "By Bitsclan IT Solutions",
    icon: "/images/blog-images/auth-logo.png",
  },
  {
    id: 5,
    title: "The Role of Augmented Reality in the Future of IT",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.",
    linkURL: "/blog-details/test",
    bgImg: "/images/blog-images/small-blog-4.png",
    author: "By Bitsclan IT Solutions",
    icon: "/images/blog-images/auth-logo.png",
  },
];

const TrendsInsights = ({ specificClass, category }) => {

  // const filteredBlogs = category === "all"
  //   ? blogsSlidesData
  //   : blogsSlidesData.filter((blog) => blog.category === category);

  const filteredBlogs = category.toLowerCase() === "all"
    ? blogsSlidesData
    : blogsSlidesData.filter((blog) => blog.category === category);


  console.log(category, "filterd Blogs");


  return (
    <div className={`trends-insights space-top-padding ${specificClass}`} id="trends">
      <div className="container">
        <div className="row">
          <div className="col-md-12 pb-5">
            <h1 className="main-heading">Latest Trends and Expert Insights</h1>
            <h3 className="sub-heading">
              Discover expert insights on the latest industry developments, trends, and emerging technologies shaping the future.
            </h3>
          </div>
        </div>

        <div className="row">
          {filteredBlogs.length > 0 ? (
            <>
              <div className=" col-xl-6 col-lg-12 big-blog-spacing">
                <Link to={`/blog-details/${filteredBlogs[0]?.id}`}>
                  <div
                    className="big-blog"
                    style={{ backgroundImage: `url(${filteredBlogs[0]?.imageSrc})` }}
                  >
                    <div className="overlay">
                      <div className="content">
                        <h3>{filteredBlogs[0]?.title}</h3>
                        <p>{filteredBlogs[0]?.content}</p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-xl-6 col-lg-12 p-0">
                <div className="container">
                  <div className="row">
                    {filteredBlogs.slice(1, 5).map((data) => (
                      <div className="col-md-6 small-blog-spacing" key={data.id}>
                        <Link to={`/blog-details/${data.id}`}>
                          <div
                            className="small-blog"
                            style={{ backgroundImage: `url(${data?.imageSrc})` }}
                          >
                            <div className="overlay">
                              <h3 className="pb-2">{data?.title}</h3>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <p>No blogs available for this category.</p>
          )}
        </div>

        {/* View All Button with Category */}
        <div className="row">
          <div className="col-md-12">
            <div className="hire-us-cta-btn d-flex mt-5 justify-content-center">
              <Link className="consult-project-scope" to={`/blogs?category=${encodeURIComponent(category)}`}>
                View All
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrendsInsights;

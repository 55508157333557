import React, { useEffect } from "react";
import Footer from "../components/footer/Footer";
import SimilerBanner from "../components/similerBanner/SimilerBanner";
import DevelopmentProcess from "../components/developmentProcess/DevelopmentProcess";
import FutureSolution from "../components/futuresolution/FutureSolution";
import PioneeringChange from "../components/pioneeringChange/PioneeringChange";
import Testimonial from "../components/testimonila/Testimonial";
import CoverTheStack from "../components/coverthestack/CoverTheStack";
import { Helmet } from "react-helmet";
import MegaHeader from "../components/MegaHeader/MegaHeader";
import AIServices from "../components/ai-development-services/AIServices";
import { DevOpsFaqData, DevOpsTabsData } from "../data/devOpsPageData";
import WhyChooseBitsclan from "../components/why-choose-bitsclan/WhyChooseBitsclan";
import TrendsInsights from "../components/trends-n-insights/TrendsInsights";
import FAQ from "../components/Fqas/FAQ";
import DevOpsRoadMap from "../components/devops-roadmap/DevopsRoadmap";
import ContactSection from "../components/contactSection/ContactSection";
import DevOpsChallange from "../components/devops-challange/DevOpsChallange";
import { HireUsData, mainBannerImg, processStepsData, services, solutionsData, tabItems, tabs } from "../data/dev-ops-data";
import tabData from "../data/industryData";

const DevOpsServices = () => {

  return (
    <>
      <Helmet>
        <title>DevOps Services and Solutions | Bitsclan IT Solutions</title>
        <meta
          name="description"
          content="Optimize your software delivery pipeline with our expert DevOps services and solutions for faster, reliable deployments."
        />
      </Helmet>

      {/* <Header /> */}

      <div className="sticky-header">
        <MegaHeader />
      </div>

      <div className="dev-ops-services">
        <SimilerBanner
          diffBannerBackgroundImage={mainBannerImg}
          mainHeading="DevOps Services and"
          greenHeading="Solutions"
          subHeading="Trusted by Startups, SMBs, and Enterprises alike"
          content="Automate workflows, accelerate software delivery, and reduce deployment risks with our DevOps services."
          btnText2={"Consult our DevOps experts"}
          sideImage=""
        />
      </div>

      <Testimonial
        heading="Our clients love us and you will too"
        content={
          "Read genuine customer feedback and discover how our AI development services have consistently exceeded expectations and delivered outstanding results"
        }
      />

      {/* <PageScroller content={scrollerData} /> */}

      <AIServices
        services={services}
        mainHeading="Bitsclan’s end-to-end DevOps Services"
        content="At Bitsclan, we provide comprehensive DevOps services designed to optimize your software development lifecycle from start to finish. Our approach integrates development and operations, ensuring faster delivery, greater efficiency, and continuous improvement."
      />

      <DevelopmentProcess
        heading="Our DevOps Services Drive Strategic "
        greenHeading="Business Growth"
        description="Implementing DevOps can significantly change the way your business operates, driving efficiency, agility, and innovation. By adopting DevOps best practices and leveraging cloud DevOps solutions, you streamline your development and operations processes. Partnering with a DevOps services company like Bitsclan IT Solutions helps you stay ahead of the competition and adapt to the rapidly changing digital landscape."
        processSteps={processStepsData}
      />

      {/* <HireUs
       
      /> */}

      <DevOpsChallange HireUsData={HireUsData}
        heading="We Take on Challenges and Solve Problems"
        content="Our DevOps services optimize your software development lifecycle with cutting-edge technologies. We deliver tailored strategies for continuous integration, deployment, and automation to enhance collaboration and efficiency." />

      <FutureSolution
        heading="Why Bitsclan for Your DevOps Solutions"
        subHeading="Choosing the right partner for your DevOps needs is crucial to ensuring the success of your development and operational processes. At Bitsclan, we bring unparalleled expertise and a commitment to excellence in every project we undertake."
        solutions={solutionsData}
      />

      <PioneeringChange
        tabData={tabData}
        greenHeading={"Across Industries"}
        mainHeading={"Pioneering Change"}
        content={
          "Our expertise in mobile app development extends across a wide range of industries, delivering customized solutions that meet the unique demands of each sector."
        }
      />

      <DevOpsRoadMap />

      <CoverTheStack
        whereStart={"DevOps"}
        mainHeading={"Optimize Your DevOps Pipeline with Industry-Leading Tech Stack"}
        subHeading={
          "At Bitsclan, we ensure that your DevOps processes are powered by the most advanced technologies available. Our carefully curated tech stack is designed to maximize efficiency, security, and scalability at every stage of the software development lifecycle. By leveraging these cutting-edge tools, we help you achieve faster deployments, better performance, and higher quality outcomes, all while maintaining rigorous standards for security and compliance."
        }
        tabs={tabs}
        tabItems={tabItems}
      />

      <div className="left-align-content-box">
        <WhyChooseBitsclan
          tabs={DevOpsTabsData}
          heading="Scalable & Secure DevOps Solutions for Web Applications"
          subheading="As a leading web application development agency, we specialize in delivering a diverse range of web apps tailored to meet the unique needs of our clients. Our expertise spans multiple industries and functional requirments, ensuring that we can develop innovative solutions that drive efficiency, engagement, and growth. Here are some of the key types of web applications we deliver."
          bgClassHere="bg-light-grey"
          btnText2="Book a slot now"
        />
      </div>

      <TrendsInsights category="devops-services" />

      <FAQ
        faqs={DevOpsFaqData}
        mainHeading={"Frequently Asked Questions"}
      // subHeading={
      //   "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
      // }
      />


      {/* <AiServiceForm
        heading={contactData.heading}
        subHeading={contactData.subHeading}
        contactSections={contactData.contactSections}
        services={contactData.services}
      /> */}

      <ContactSection />
      <Footer />
    </>
  );
};

export default DevOpsServices;

import React, { useRef, useState, useCallback } from 'react'
import './style.css'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Autoplay } from 'swiper/modules';

const HomeLogo = () => {

    const sliderRef = useRef();

    const [isBeginning, setIsBeginning] = useState(true);
    const [isEnd, setIsEnd] = useState(false);

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);

    const handleSlideChange = (swiper) => {
        setIsBeginning(swiper.isBeginning);
        setIsEnd(swiper.isEnd);
    };


    const dataArray = [
        {
            title: "Schedule a Free Consultation",
            description: "Set up a no-obligation meeting with our experts to discuss your project needs and explore potential solutions.",
            borderClass: "bg-one"
        },
        {
            title: "Get a Proposal",
            description: "Receive a detailed proposal outlining our approach, timeline, and costs, tailored specifically to your project requirements.",
            borderClass: "bg-two"
        },
        {
            title: "Start Building Your Success Story",
            description: "Once approved, our team gets to work, transforming your vision into reality and driving your project toward success.",
            borderClass: "bg-three"
        }
    ];


    const brandImages = [
        {
            src: 'brand-icon1.svg',
            alt: 'Brand Icon 1',
        },
        {
            src: 'brand-icon2.svg',
            alt: 'Brand Icon 2',
        },
        {
            src: 'brand-icon3.svg',
            alt: 'Brand Icon 3',
        },
        {
            src: 'brand-icon4.svg',
            alt: 'Brand Icon 4',
        },
        {
            src: 'brand-icon5.svg',
            alt: 'Brand Icon 5',
        },
        {
            src: 'brand-icon6.webp',
            alt: 'Brand Icon 5',
        },
        {
            src: 'brand-icon7.webp',
            alt: 'Brand Icon 5',
        },
        {
            src: 'brand-icon8.webp',
            alt: 'Brand Icon 5',
        },
        {
            src: 'brand-icon9.webp',
            alt: 'Brand Icon 5',
        },
        {
            src: 'brand-icon10.webp',
            alt: 'Brand Icon 5',
        },
        {
            src: 'brand-icon11.webp',
            alt: 'Brand Icon 5',
        },
        {
            src: 'brand-icon12.webp',
            alt: 'Brand Icon 5',
        },
        {
            src: 'brand-icon13.webp',
            alt: 'Brand Icon 5',
        },
        {
            src: 'brand-icon14.webp',
            alt: 'Brand Icon 5',
        },
        {
            src: 'brand-icon15.webp',
            alt: 'Brand Icon 5',
        },
        {
            src: 'brand-icon16.webp',
            alt: 'Brand Icon 5',
        },
        {
            src: 'brand-icon17.webp',
            alt: 'Brand Icon 5',
        },
        {
            src: 'brand-icon18.webp',
            alt: 'Brand Icon 5',
        },
        {
            src: 'brand-icon19.webp',
            alt: 'Brand Icon 5',
        },
        {
            src: 'brand-icon20.webp',
            alt: 'Brand Icon 5',
        },
        {
            src: 'brand-icon21.webp',
            alt: 'Brand Icon 5',
        },
        {
            src: 'brand-icon22.webp',
            alt: 'Brand Icon 5',
        },
        {
            src: 'brand-icon23.webp',
            alt: 'Brand Icon 5',
        },
        {
            src: 'brand-icon24.webp',
            alt: 'Brand Icon 5',
        },
        {
            src: 'brand-icon25.webp',
            alt: 'Brand Icon 5',
        },
        {
            src: 'brand-icon26.webp',
            alt: 'Brand Icon 5',
        },
        {
            src: 'brand-icon27.webp',
            alt: 'Brand Icon 5',
        },
        {
            src: 'brand-icon28.webp',
            alt: 'Brand Icon 5',
        },
        {
            src: 'brand-icon29.webp',
            alt: 'Brand Icon 5',
        },
        {
            src: 'brand-icon30.webp',
            alt: 'Brand Icon 5',
        },
        {
            src: 'brand-icon31.webp',
            alt: 'Brand Icon 5',
        },
        {
            src: 'brand-icon32.webp',
            alt: 'Brand Icon 5',
        },
        {
            src: 'brand-icon33.webp',
            alt: 'Brand Icon 5',
        },

        {
            src: 'brand-icon34.webp',
            alt: 'Brand Icon 5',
        },

        {
            src: 'brand-icon35.webp',
            alt: 'Brand Icon 5',
        },

        {
            src: 'brand-icon36.webp',
            alt: 'Brand Icon 5',
        },

        {
            src: 'brand-icon37.webp',
            alt: 'Brand Icon 5',
        },
    ];

    return (
        <>
            <div className="how-it-works-box home-logo">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="main-heading mb-2">Behind Every Success Story, is Bits-Clan! </h1>
                        </div>

                        <div className="container">
                            <div className="row brand-images-box">
                                <Swiper
                                    slidesPerView={6}
                                    spaceBetween={20}
                                    navigation={false}
                                    onSlideChange={handleSlideChange}
                                    autoplay={{
                                        delay: 3000,
                                        disableOnInteraction: true,
                                        pauseOnMouseEnter: true,
                                    }}
                                    modules={[Navigation, Autoplay]}
                                    className="mySwiper"
                                    ref={sliderRef}
                                    breakpoints={{
                                        320: {
                                            slidesPerView: 2,
                                            spaceBetween: 10,
                                        },
                                        375: {
                                            slidesPerView: 2,
                                            spaceBetween: 10,
                                        },
                                        425: {
                                            slidesPerView: 2,
                                            spaceBetween: 10,
                                        },
                                        768: {
                                            slidesPerView: 2,
                                            spaceBetween: 10,
                                        },
                                        1024: {
                                            slidesPerView: 3,
                                            spaceBetween: 10,
                                        },
                                        1280: {
                                            slidesPerView: 5,
                                            spaceBetween: 10,
                                        },
                                    }}
                                >
                                    {brandImages.map((item) => (
                                        <SwiperSlide key={item.id}>
                                            <div className="col-md-12">
                                                {/* home-page-contact-logo */}
                                                <div className="contact-brand-image ">
                                                    <img src={`/images/project-logos/${item.src}`} alt={item.alt} />
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeLogo
export const tabs = [
    "Frontend",
    "Backend",
    "Libraries",
    "Database",
    // "DevOps",
    // "Quality Assurance",
];

export const tabItems = [
    { tab: "Frontend", icon: "/images/iconthree.png", name: "React JS" },
    { tab: "Frontend", icon: "/images/iconone.png", name: "Angular JS" },
    { tab: "Frontend", icon: "/images/icon7.png", name: "HTML5" },
    { tab: "Frontend", icon: "/images/icon6.png", name: "CSS3" },
    { tab: "Frontend", icon: "/images/tech-stack-icons/gatsby.svg", name: "Gatsby" },
    { tab: "Frontend", icon: "/images/icon4.png", name: "Vue JS" },
    { tab: "Frontend", icon: "/images/tech-stack-icons/ember.svg", name: "Ember.js" },
    { tab: "Frontend", icon: "/images/tech-stack-icons/svelte.svg", name: "Svelte" },
    { tab: "Frontend", icon: "/images/tech-stack-icons/backbone-js.svg", name: "Backbone.js" },
    { tab: "Frontend", icon: "/images/tech-stack-icons/polymer.svg", name: "Polymer" },
    { tab: "Frontend", icon: "/images/tech-stack-icons/aurelia.svg", name: "Aurelia" },
    { tab: "Frontend", icon: "/images/tech-stack-icons/meteor.svg", name: "Meteor.js" },
    { tab: "Frontend", icon: "/images/tech-stack-icons/next-js.svg", name: "Next.js" },
    { tab: "Frontend", icon: "/images/tech-stack-icons/semantic.svg", name: "Semantic-UI" },
    { tab: "Frontend", icon: "/images/tech-stack-icons/nuxt-js.svg", name: "Nuxt" },
    { tab: "Backend", icon: "/images/database9.png", name: "Node Js" },
    { tab: "Backend", icon: "/images/tech-stack-icons/elixir.svg", name: "Elixir" },
    { tab: "Backend", icon: "/images/database1.png", name: ".Net" },
    { tab: "Backend", icon: "/images/database6.png", name: "Python" },
    { tab: "Backend", icon: "/images/tech-stack-icons/ruby_on_rails.svg", name: "Ruby On Rails" },
    { tab: "Backend", icon: "/images/tech-stack-icons/java.svg", name: "Java" },
    { tab: "Backend", icon: "/images/database8.png", name: "PHP" },
    { tab: "Backend", icon: "/images/django-tech.svg", name: "Django" },
    { tab: "Backend", icon: "/images/tech-stack-icons/golang.svg", name: "Go" },
    { tab: "Backend", icon: "/images/tech-stack-icons/c-sharp.svg", name: "C#" },
    { tab: "Backend", icon: "/images/tech-stack-icons/kotlin.svg", name: "Kotlin" },
    { tab: "Backend", icon: "/images/tech-stack-icons/scala.svg", name: "Scala" },
    { tab: "Backend", icon: "/images/tech-stack-icons/rust.svg", name: "Rust" },
    { tab: "Database", icon: "/images/db5.png", name: "MySQL" },
    { tab: "Database", icon: "/images/tech-stack-icons/couchdb.svg", name: "Couch DB" },
    { tab: "Database", icon: "/images/tech-stack-icons/indexed.svg", name: "Indexed DB" },
    { tab: "Database", icon: "/images/db3.png", name: "MongoDB" },
    { tab: "Database", icon: "/images/db7.png", name: "SQLite" },
    { tab: "Database", icon: "/images/db6.png", name: "MS SQL Server" },
    { tab: "Database", icon: "/images/db1.png", name: "Firebase" },
    { tab: "Database", icon: "/images/tech-stack-icons/swift.svg", name: "Swift" },
    { tab: "Database", icon: "/images/tech-stack-icons/redis.svg", name: "Redis" },
    { tab: "Database", icon: "/images/db8.png", name: "DynamoDB" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/jquery.svg", name: "jQuery" },
    { tab: "Libraries", icon: "/images/iconthree.png", name: "React" },
    { tab: "Libraries", icon: "/images/iconone.png", name: "Angular" },
    { tab: "Libraries", icon: "/images/icon4.png", name: "Vue.js" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/vue-x.svg", name: "Vue.x" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/rx-js.svg", name: "Rxjs" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/bootstrap.svg", name: "Bootstrap" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/backbone-js.svg", name: "Backbone.js" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/ember.svg", name: "Ember.js" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/d3-js.svg", name: "D3.js" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/redux.svg", name: "Redux" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/lodash.svg", name: "Lodash" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/styled-component.svg", name: "Styled Component" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/emotion.svg", name: "Emotion" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/graphql.svg", name: "Graph QL" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/ant-design.svg", name: "Ant Design" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/inertia.svg", name: "Inertia.js" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/mobx.svg", name: "Mobx" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/web3.svg", name: "Web3" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/Web.svg", name: "WebGL" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/core-ui.svg", name: "Core UI" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/tailwind.svg", name: "Tailwind" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/material-ui.svg", name: "Material UI" },
    { tab: "Libraries", icon: "/images/tech-stack-icons/flow-js.svg", name: "Flow.js" },
];

export const TypesOfBuildData = [
    {
        id: "collapseOne",
        question: "Development Services",
        answer: {
            discription:
                "As a dedicated web app development partner, we take a client-first approach to deliver tailored solutions. By combining modern frameworks, agile methodologies, and innovative thinking, we create web applications that meet your unique business goals with precision, scalability, and unmatched quality.",
            listTitle: "",
            listContent: [],
        },
    },
    {
        id: "collapseTwo",
        question: "Secure",
        answer: {
            discription:
                "Our secure web applications are built to protect sensitive data and prevent vulnerabilities, ensuring user trust. With encrypted communication and strong authentication protocols, we deliver solutions that safeguard your business and customer information.",
            listTitle: "Our secure web apps are best for:",
            listContent: [
                {
                    line: "Handling sensitive user or business data",
                },
                {
                    line: "Financial and e-commerce transactions",
                },
                {
                    line: "Preventing security breaches",
                },
                {
                    line: "Ensuring compliance with regulations",
                },
                {
                    line: "Building trust with end-users",
                },
            ],
        },
    },
    {
        id: "collapseThree",
        question: "Fast",
        answer: {
            discription:
                "Speed drives user satisfaction, and our web apps are engineered to load quickly and perform seamlessly. By using optimized code and advanced frameworks, we create fast and responsive solutions that keep your audience engaged.",
            listTitle: "Our fast web apps are best for:",
            listContent: [
                {
                    line: "High-traffic platforms",
                },
                {
                    line: "Real-time processing requirements",
                },
                {
                    line: "Enhancing user experience",
                },
                {
                    line: "Reducing bounce rates",
                },
                {
                    line: "Delivering quick data updates",
                },
            ],
        },
    },
    {
        id: "fourth",
        question: "Scalable",
        answer: {
            discription:
                "Our scalable web apps are designed to grow with your business. With flexible architectures and cloud-based solutions, they handle increasing users, features, and data effortlessly, supporting your success.",
            listTitle: "Our scalable web apps are best for:",
            listContent: [
                {
                    line: "Startups planning to grow",
                },
                {
                    line: "Businesses with fluctuating user loads",
                },
                {
                    line: "Multi-functional platforms",
                },
                {
                    line: "Handling large-scale operations",
                },
                {
                    line: "Future-ready applications",
                },
            ],
        },
    },
    {
        id: "fifth",
        question: "Robust",
        answer: {
            discription:
                "We design web apps that are reliable, resilient, and capable of handling heavy workloads. These robust solutions ensure flawless functionality, even under demanding conditions.",
            listTitle: "Our robust web apps are best for:",
            listContent: [
                {
                    line: "Complex business operations",
                },
                {
                    line: "Apps requiring high performance",
                },
                {
                    line: "Data-intensive processes",
                },
                {
                    line: "Enterprise-grade solutions",
                },
                {
                    line: "Long-term durability",
                },
            ],
        },
    },
    {
        id: "Sixth",
        question: "Responsive",
        answer: {
            discription:
                "We create web apps that adapt beautifully to any device, offering a consistent and enjoyable experience for users on desktops, tablets, and mobiles.",
            listTitle: "Our responsive web apps are best for:",
            listContent: [
                {
                    line: "Multi-device accessibility",
                },
                {
                    line: "Cross-platform consistency",
                },
                {
                    line: "Enhancing user engagement",
                },
                {
                    line: "Mobile-first businesses",
                },
                {
                    line: "Reaching wider audiences",
                },
            ],
        },
    },
    {
        id: "seventh",
        question: "User-Friendly",
        answer: {
            discription:
                "Intuitive and simple, our user-friendly web apps make navigation a breeze. Designed with the end-user in mind, they enhance engagement and ensure satisfaction at every step.",
            listTitle: "Our user-friendly web apps are best for:",
            listContent: [
                {
                    line: "Simplifying complex tasks",
                },
                {
                    line: " Improving user retention",
                },
                {
                    line: "Creating delightful experiences",
                },
                {
                    line: "Catering to non-technical users",
                },
                {
                    line: "Enhancing brand loyalty",
                },
            ],
        },
    },
];

export const WhoWeServeData = [
    {
        icon: "/images/servie-icon/startups.svg",
        title: "Startups",
        description:
            "For startups, every step counts. We help early-stage ventures by developing cost-effective and scalable apps that bring their ideas to life. Whether you need an MVP to attract investors or a feature-rich product to launch in the market, we ensure your solution stands out and grows alongside your business.",
        link: "#",
        borderClass: "border-1",
    },
    {
        icon: "/images/servie-icon/companies.svg",
        title: "Software product companies",
        description:
            "Our expertise supports software product companies in creating innovative and customer-centric solutions. From upgrading existing products to building new applications from scratch, we ensure your software is efficient, secure, and built to exceed market expectations. Let us handle the technical complexities while you focus on growth.",
        link: "#",
        borderClass: "border-2",
    },
    {
        icon: "/images/servie-icon/industries.svg",
        title: "Enterprises from 30+ industries",
        description:
            "We partner with enterprises across diverse industries, including healthcare, finance, education, and logistics, to deliver tailored digital solutions. By understanding each industry’s unique challenges, we build apps that drive efficiency, enhance customer engagement, and deliver measurable business impact.",
        link: "#",
        borderClass: "border-3",
    },
];

export const WhyChooseBitsclanData = [
    {
        id: "Tab1",
        label: "Quality Because It Matters",
        content:
            "We prioritize quality in every line of code we write. With a focus on user experience, functionality, and performance, our web app developers deliver solutions that are secure, robust, and future-ready. Every application is rigorously tested to meet the highest industry standards.",
        btnText: "Connect with the clan",
        btnLink: "#",
    },
    {
        id: "Tab2",
        label: "The Best-of-the-Breed Talent",
        content:
            "Our team of web app developers is made up of innovators who excel in the latest frameworks and technologies. With extensive experience in building custom web apps, we bring creativity and technical expertise together to create web application solutions that stand out.",
        btnText: "Connect with the clan",
        btnLink: "#",
    },
    {
        id: "Tab3",
        label: "Time zone Alignment",
        content:
            "Working in different time zones is a thing of the past. Our team is aligned with nearshore time zones to ensure smooth communication, seamless collaboration, and timely project delivery. This guarantees no gaps in understanding or execution.",
        btnText: "Connect with the clan",
        btnLink: "#",
    },
];

export const DevelopmentServicesData = [
    {
        id: "Tab1",
        label: "Web Portal Development",
        content:
            "Enhance your business with our custom web portal development services. We build scalable, secure, and interactive portals that optimize workflows, improve collaboration, and streamline operations. Our solutions ensure seamless access to data, automation, and user engagement across industries.",
        secondContent: "From enterprise portals to self-service platforms, our team delivers feature-rich, high-performance web portals tailored to your needs. Leverage cutting-edge technologies to boost efficiency and create a centralized digital experience for your users.",
        btnText: "Let's Discuss",
        btnLink: "#",
        imgs: [
            {
                img: "/images/services-icons/web-app-development/sql.png",
            },
            {
                img: "/images/services-icons/web-app-development/node.png",
            },
            {
                img: "/images/services-icons/web-app-development/react.png",
            },
            {
                img: "/images/services-icons/web-app-development/keycloak.png",
            },
        ],
    },
    {
        id: "Tab3",
        label: "SaaS App Development Services",
        content:
            "With our cutting-edge SaaS app development services, we design and build scalable, secure, and user-friendly SaaS applications that cater to your specific business needs. From multi-tenant architecture to seamless updates, our SaaS app development ensures your solution stands out in the competitive market.",
        btnText: "Let's Discuss",
        btnLink: "#",
        imgs: [
            {
                img: "/images/services-icons/web-app-development/sql.png",
            },
            {
                img: "/images/services-icons/web-app-development/node.png",
            },
            {
                img: "/images/services-icons/web-app-development/react.png",
            },
            {
                img: "/images/services-icons/web-app-development/keycloak.png",
            },
        ],
    },
    {
        id: "Tab4",
        label: "Frontend Web App Development",
        content:
            "Deliver stunning, high-performing web applications with our expert frontend development services. We create intuitive, user-friendly interfaces using modern frameworks like React, Angular, and Vue.js, ensuring seamless cross-device experiences.",
        secondContent: "Our team specializes in fast-loading, interactive, and scalable frontend solutions that enhance user engagement and performance. Whether building a progressive web app (PWA) or a complex web platform, we craft solutions that prioritize speed, security, and responsiveness.",
        btnText: "Let's Discuss",
        btnLink: "#",
        imgs: [
            {
                img: "/images/services-icons/web-app-development/sql.png",
            },
            {
                img: "/images/services-icons/web-app-development/node.png",
            },
            {
                img: "/images/services-icons/web-app-development/react.png",
            },
            {
                img: "/images/services-icons/web-app-development/keycloak.png",
            },
        ],
    },
    {
        id: "Tab9",
        label: "Backend Web App Development",
        content:
            "Power your web applications with a robust, scalable, and high-performance backend. Our developers specialize in Node.js, Python, PostgreSQL, and cloud technologies, ensuring efficient data handling, real-time processing, and seamless integrations.",
        secondContent: "With a focus on security, reliability, and API-driven architecture, we create optimized backends that support dynamic web applications. Whether handling high-traffic loads or complex business logic, we build future-ready solutions to enhance your platform’s performance.",
        btnText: "Let's Discuss",
        btnLink: "#",
        imgs: [
            {
                img: "/images/services-icons/web-app-development/sql.png",
            },
            {
                img: "/images/services-icons/web-app-development/node.png",
            },
            {
                img: "/images/services-icons/web-app-development/react.png",
            },
            {
                img: "/images/services-icons/web-app-development/keycloak.png",
            },
        ],
    },
    {
        id: "Tab5",
        label: "Web Application Consulting",
        content:
            "Navigate the complexities of web development with our expert consulting services. We help businesses select the right technology stack, architecture, and security protocols to ensure long-term success and scalability.",
        secondContent: "Our consultants specialize in performance optimization, cloud migration, and digital transformation strategies. Whether you're launching a new web app or refining an existing one, we provide data-driven insights and technical expertise to accelerate growth.",
        btnText: "Let's Discuss",
        btnLink: "#",
        imgs: [
            {
                img: "/images/services-icons/web-app-development/sql.png",
            },
            {
                img: "/images/services-icons/web-app-development/node.png",
            },
            {
                img: "/images/services-icons/web-app-development/react.png",
            },
            {
                img: "/images/services-icons/web-app-development/keycloak.png",
            },
        ],
    },
    {
        id: "Tab6",
        label: "Custom Web Apps Development for Enterprises",
        content:
            "Transform your enterprise with custom web applications designed for efficiency, security, and scalability. We build tailor-made digital solutions that integrate seamlessly with existing workflows, cloud environments, and business operations.",
        secondContent: "Our expert team focuses on automation, real-time data processing, and AI-powered insights to enhance productivity. From custom CRMs to advanced ERP systems, we create enterprise-grade applications that drive innovation and competitive advantage.",
        btnText: "Let's Discuss",
        btnLink: "#",
        imgs: [
            {
                img: "/images/services-icons/web-app-development/sql.png",
            },
            {
                img: "/images/services-icons/web-app-development/node.png",
            },
            {
                img: "/images/services-icons/web-app-development/react.png",
            },
            {
                img: "/images/services-icons/web-app-development/keycloak.png",
            },
        ],
    },
    {
        id: "Tab7",
        label: "Headless WordPress Web App Development",
        content:
            "Unlock unparalleled performance and flexibility with headless WordPress web app development. We integrate WordPress’s powerful CMS with modern frontend frameworks like React and Next.js, ensuring a lightning-fast, API-driven experience",
        secondContent: "By separating the backend from the frontend, we deliver highly customized, scalable, and secure web applications. Whether you need a content-rich platform, an eCommerce store, or a marketing website, our solutions ensure enhanced performance and seamless third-party integrations.",
        btnText: "Let's Discuss",
        btnLink: "#",
        imgs: [
            {
                img: "/images/services-icons/web-app-development/sql.png",
            },
            {
                img: "/images/services-icons/web-app-development/node.png",
            },
            {
                img: "/images/services-icons/web-app-development/react.png",
            },
            {
                img: "/images/services-icons/web-app-development/keycloak.png",
            },
        ],
    },
];

export const WebAppservicesRoadMap = [
    "Every great web app starts with a solid foundation. In this phase, we work closely with you to understand your goals, define the scope, and create a strategic plan. Our web app developers ensure that your custom web apps are designed to align seamlessly with your vision and market needs.",
    "We bring your ideas to life with intuitive designs and a robust proof of concept. This step validates feasibility and ensures the scalability of your web applications, focusing on user-centric experiences and future-ready solutions.",
    "Using modern frameworks, our expert team develops high-performance web applications optimized for speed, security, and functionality. Rigorous testing ensures your custom web apps are free from errors and ready to perform under real-world conditions.",
    "We don’t just deliver—we ensure your app succeeds. From smooth deployment to continuous support, we help you optimize your scalable web applications over time, adapting to evolving business needs and keeping your solutions ahead of the curve.",
];

export var mainBannerImg = {
    backgroundImage: `url(/images/what-we-do/web-app-development-services.png)`,
};

export const schemaData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
        {
            "@type": "Question",
            name: "What does it cost to build a web app?",
            acceptedAnswer: {
                "@type": "Answer",
                text: "The price of developing a web application depends upon its complexity, project timeline, and other factors like these. To help you understand, a simple application can be made for $1,500. On the other hand, an advanced web application with AI features can take up to $50,000 to develop.",
            },
        },
        {
            "@type": "Question",
            name: "What kinds of web applications can you create?",
            acceptedAnswer: {
                "@type": "Answer",
                text: "We specialize in developing a wide range of web applications, including enterprise solutions, e-commerce platforms, customer portals, supply chain management systems, CRM/ERP systems, and more. Our expertise allows us to tailor applications to meet the specific needs of various industries.",
            },
        },
        {
            "@type": "Question",
            name: "How does a Progressive Web App function?",
            acceptedAnswer: {
                "@type": "Answer",
                text: "Progressive Web Apps (PWAs) combine the best of web and mobile apps. They work by utilizing modern web capabilities to deliver an app-like experience in the browser, including offline access, push notifications, and faster load times, all without needing to download from an app store.",
            },
        },
        {
            "@type": "Question",
            name: "What is the typical timeline for building a web application?",
            acceptedAnswer: {
                "@type": "Answer",
                text: "The timeline for developing a web application varies based on its complexity, features, and scope. On average, a basic web app may take 3-6 months, while more complex applications can take 6-12 months or longer, from initial discovery to final deployment.",
            },
        },
        {
            "@type": "Question",
            name: "How do you protect the security of web applications?",
            acceptedAnswer: {
                "@type": "Answer",
                text: "We prioritize security throughout the development process by implementing best practices such as encryption, secure authentication, and regular security audits. We also adhere to OWASP guidelines and ensure compliance with relevant industry standards to safeguard your web application against potential threats.",
            },
        },
        {
            "@type": "Question",
            name: "What should I consider when selecting a web application development company?",
            acceptedAnswer: {
                "@type": "Answer",
                text: "When choosing a web application development partner, look for a company with a strong portfolio, proven expertise in your industry, transparent communication, and a clear understanding of your business goals. It's also essential to ensure they follow best practices for security, scalability, and performance.",
            },
        },
        {
            "@type": "Question",
            name: "How does web application development differ from website development?",
            acceptedAnswer: {
                "@type": "Answer",
                text: "Web application development focuses on creating interactive, dynamic systems that perform complex tasks, such as user management, data processing, and real-time interaction. In contrast, website development typically involves creating static or content-driven sites that display information and may have limited interactive features.",
            },
        },
    ],
};
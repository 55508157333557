export const processStepsData = [
  {
    stepNumber: "01",
    title: "Enhanced Security",
    content:
      "Blockchain’s decentralized structure and cryptography ensure data and transaction security.",
    icon: "/images/SECURE.png",
    bgClass: "icon-change",
  },
  {
    stepNumber: "02",
    title: "Increased Transparency",
    content:
      "All transactions are logged on a visible, distributed ledger accessible to authorized users.",
    icon: "/images/transparency.png",
    bgClass: "",
  },
  {
    stepNumber: "03",
    title: "Improved Efficiency",
    content:
      "Automated processes reduce intermediaries, streamlining operations.",
    icon: "/images/disruptive-SOLUTIONS.png",
    bgClass: "",
  },
  {
    stepNumber: "04",
    title: "Decentralization",
    content:
      "Control is distributed across the network, enhancing data integrity and resilience.",
    icon: "/images/SupplyChain.png",
    bgClass: "icon-change",
  },
  {
    stepNumber: "05",
    title: "Cost Savings",
    content:
      "Lower reliance on third parties reduces expenses, allowing for reinvestment in growth.",
    icon: "/images/FULL-ownership.png",
    bgClass: "",
  },
  {
    stepNumber: "06",
    title: "Real-Time Data Access",
    content:
      "Real-time data access enables faster, more responsive decision-making.",
    icon: "/images/req-icon3.svg",
    bgClass: "",
  },
  {
    stepNumber: "07",
    title: "Trustless Transactions",
    content:
      "Parties transact without needing trust; blockchain ensures terms are met.",
    icon: "/images/FLEXIBILITY.png",
    bgClass: "icon-change",
  },
  {
    stepNumber: "08",
    title: "Immutable Records",
    content:
      "Records are permanent and tamper-proof, ideal for auditing and compliance.",
    icon: "/images/transpaent-insights.png",
    bgClass: "",
  },
  {
    stepNumber: "09",
    title: "Global Reach",
    content:
      "Blockchain facilitates cross-border transactions, expanding business reach.",
    icon: "/images/time-zone.png",
    bgClass: "",
  },
];



export const BlockchainDevelopmentServicesData = [
  {
    id: "Tab1",
    label: "Blockchain-Powered SaaS Development",
    content:
      "Leverage blockchain technology to build secure, scalable, and decentralized SaaS applications. Our solutions ensure seamless integration of smart contracts, transparent transactions, and enhanced data integrity. By decentralizing SaaS platforms, businesses can achieve greater security, automation, and efficiency. Our blockchain-driven approach eliminates third-party risks, reduces operational costs, and ensures compliance with industry standards. Whether you need a new SaaS solution or want to enhance an existing one, we deliver customized blockchain-powered SaaS applications that drive innovation and growth.",
    btnText: "Let's Discuss",
    btnLink: "#",
    imgs: [
      {
        img: "/images/services-icons/blockchain-development/atherium.png",
      },
      {
        img: "/images/services-icons/blockchain-development/solidity.png",
      },
      {
        img: "/images/services-icons/blockchain-development/logo-foundry-formerly-black.png",
      },
      {
        img: "/images/services-icons/blockchain-development/node.png",
      },
    ],
  },
  {
    id: "Tab2",
    label: "Decentralized Web Portals",
    content:
      "Develop highly secure and transparent decentralized web portals powered by blockchain. Our solutions provide businesses with immutable records, enhanced security, and automated workflows, eliminating reliance on centralized authorities. By leveraging smart contracts and distributed ledgers, we ensure data integrity, improved access control, and tamper-proof digital interactions. Ideal for industries requiring secure transactions, decentralized web portals enhance user privacy and operational efficiency while ensuring trust and transparency in business operations.",
    btnText: "Let's Discuss",
    btnLink: "#",
    imgs: [
      {
        img: "/images/services-icons/blockchain-development/atherium.png",
      },
      {
        img: "/images/services-icons/blockchain-development/solidity.png",
      },
      {
        img: "/images/services-icons/blockchain-development/logo-foundry-formerly-black.png",
      },
      {
        img: "/images/services-icons/blockchain-development/node.png",
      },
    ],
  },
  {
    id: "Tab3",
    label: "Blockchain-Powered Backend Development",
    content:
      "Build a robust and decentralized backend system that strengthens data security and transparency. Our blockchain-powered backend solutions eliminate single points of failure, ensuring seamless operations, high availability, and protection against cyber threats. Through distributed ledger technology, we enable secure transactions, automated processes via smart contracts, and real-time data synchronization. Whether for financial services, supply chain management, or enterprise applications, our backend solutions provide a solid foundation for blockchain-driven digital transformation.",
    btnText: "Let's Discuss",
    btnLink: "#",
    imgs: [
      {
        img: "/images/services-icons/blockchain-development/atherium.png",
      },
      {
        img: "/images/services-icons/blockchain-development/solidity.png",
      },
      {
        img: "/images/services-icons/blockchain-development/logo-foundry-formerly-black.png",
      },
      {
        img: "/images/services-icons/blockchain-development/node.png",
      },
    ],
  },
  {
    id: "Tab4",
    label: "Blockchain-Enabled Frontend Development",
    content:
      "Create intuitive, secure, and decentralized frontends that seamlessly interact with blockchain-based backends. Our blockchain-enabled frontend development ensures transparent user interactions, cryptographic security, and tamper-proof data transactions. We integrate Web3 technologies, decentralized identity authentication, and seamless smart contract execution, ensuring high-performance user experiences. By combining UX/UI expertise with blockchain security, we deliver scalable, responsive, and future-proof frontends for decentralized applications, enterprise platforms, and digital solutions.",
    btnText: "Let's Discuss",
    btnLink: "#",
    imgs: [
      {
        img: "/images/services-icons/blockchain-development/atherium.png",
      },
      {
        img: "/images/services-icons/blockchain-development/solidity.png",
      },
      {
        img: "/images/services-icons/blockchain-development/logo-foundry-formerly-black.png",
      },
      {
        img: "/images/services-icons/blockchain-development/node.png",
      },
    ],
  },
  {
    id: "Tab5",
    label: "Blockchain Consulting Services",
    content:
      "Get expert guidance on blockchain implementation, strategy, and best practices tailored to your business needs. Our consulting services help enterprises assess blockchain feasibility, select the right frameworks, and develop secure, scalable solutions. We analyze use cases, identify security risks, and provide in-depth insights into integrating blockchain with existing infrastructures. Whether you need help with smart contracts, tokenization, or decentralized finance (DeFi) solutions, our consultants ensure a seamless and successful blockchain adoption.",
    btnText: "Let's Discuss",
    btnLink: "#",
    imgs: [
      {
        img: "/images/services-icons/blockchain-development/atherium.png",
      },
      {
        img: "/images/services-icons/blockchain-development/solidity.png",
      },
      {
        img: "/images/services-icons/blockchain-development/logo-foundry-formerly-black.png",
      },
      {
        img: "/images/services-icons/blockchain-development/node.png",
      },
    ],
  },
  {
    id: "Tab6",
    label: "Enterprise Blockchain Solutions",
    content:
      "Empower your organization with scalable, secure, and enterprise-grade blockchain solutions tailored to industry-specific needs. Our enterprise blockchain services enable secure data sharing, process automation, and fraud prevention while ensuring compliance with regulatory standards. By implementing permissioned or hybrid blockchain networks, we help businesses enhance transparency, streamline operations, and optimize security. From finance and healthcare to supply chain and governance, our customized enterprise blockchain solutions drive digital transformation and innovation.",
    btnText: "Let's Discuss",
    btnLink: "#",
    imgs: [
      {
        img: "/images/services-icons/blockchain-development/atherium.png",
      },
      {
        img: "/images/services-icons/blockchain-development/solidity.png",
      },
      {
        img: "/images/services-icons/blockchain-development/logo-foundry-formerly-black.png",
      },
      {
        img: "/images/services-icons/blockchain-development/node.png",
      },
    ],
  },
  {
    id: "Tab7",
    label: "Headless Blockchain CMS Development",
    content:
      "Implement a decentralized content management system (CMS) powered by blockchain for secure and efficient content delivery. Our headless blockchain CMS solutions ensure data immutability, content authenticity, and enhanced security. By decoupling the backend from the frontend, we enable seamless content distribution across multiple platforms while leveraging blockchain’s trustless and tamper-proof nature. Whether for media, publishing, or e-commerce, our blockchain-driven CMS guarantees transparency, eliminates unauthorized modifications, and enhances digital asset management.",
    btnText: "Let's Discuss",
    btnLink: "#",
    imgs: [
      {
        img: "/images/services-icons/blockchain-development/atherium.png",
      },
      {
        img: "/images/services-icons/blockchain-development/solidity.png",
      },
      {
        img: "/images/services-icons/blockchain-development/logo-foundry-formerly-black.png",
      },
      {
        img: "/images/services-icons/blockchain-development/node.png",
      },
    ],
  },
];

export var mainBannerImg = {
  backgroundImage: `url(/images/what-we-do/blockchain-development-services.png)`,
};

export const BlockchainWhoWeServeData = [
  {
    icon: "/images/servie-icon/startups.svg",
    title: "Startups Embracing Blockchain",
    description:
      "Startups can leverage blockchain to create innovative solutions, from secure payment gateways to transparent supply chains. We help them build scalable, future-ready blockchain applications.",
    link: "#",
    borderClass: "border-1",
  },
  {
    icon: "/images/servie-icon/companies.svg",
    title: "Enterprises Adopting Blockchain Solutions",
    description:
      "Large enterprises are integrating blockchain to enhance security, efficiency, and traceability. Our blockchain solutions support enterprise-grade applications and seamless smart contract execution.",
    link: "#",
    borderClass: "border-2",
  },
  {
    icon: "/images/servie-icon/industries.svg",
    title: "Fintech & Banking Revolution",
    description:
      "The financial industry benefits from blockchain with secure transactions, fraud prevention, and decentralized finance (DeFi) solutions. Our expertise ensures compliance and high-performance financial applications.",
    link: "#",
    borderClass: "border-3",
  },
];

export const BlockchainWhyChooseBitsclanData = [
  {
    id: "Tab1",
    label: "Quality because it matters",
    content:
      "At Bitsclan, quality isn’t just a priority—it’s the foundation of everything we do. Our blockchain solutions are built with precision, ensuring high performance, security, and scalability.",
    btnText: "Connect with the clan",
    btnLink: "#",
  },
  {
    id: "Tab2",
    label: "The Best-of-the-Breed Talent",
    content:
      "Our team consists of top-tier blockchain developers, security experts, and strategists, bringing deep expertise and innovative thinking to every project.",
    btnText: "Connect with the clan",
    btnLink: "#",
  },
  {
    id: "Tab3",
    label: "Time zone Alignment",
    content:
      "We work across global time zones, ensuring smooth collaboration and real-time communication for uninterrupted project progress.",
    btnText: "Connect with the clan",
    btnLink: "#",
  },
  {
    id: "Tab6",
    label: "Cutting-Edge Security Measures",
    content:
      "Security is at the core of our solutions. We implement advanced encryption, rigorous audits, and best-in-class security protocols to safeguard your blockchain ecosystem.",
    btnText: "Connect with the clan",
    btnLink: "#",
  },
  {
    id: "Tab7",
    label: "Seamless Blockchain Integration",
    content:
      "We ensure effortless blockchain adoption by integrating decentralized solutions with your existing business infrastructure for maximum efficiency.",
    btnText: "Connect with the clan",
    btnLink: "#",
  },
];

export const blockchaintabs = [
  // "Backend",
  // "Frontend",
  // "Database",
  // "Frameworks",
  // "DevOps",
  // "Quality Assurance",
  "Blockchain Platforms",
  "Enterprise Solutions",
  "Consensus Mechanisms",
  "Storage Solutions",
];

export const blockchaintabItems = [
  // Blockchain Platforms
  { tab: "Blockchain Platforms", icon: "/images/tech-stack-icons/ethereum.png", name: "Ethereum" },
  { tab: "Blockchain Platforms", icon: "/images/tech-stack-icons/stellar.png", name: "Stellar" },
  { tab: "Blockchain Platforms", icon: "/images/tech-stack-icons/Ripple.png", name: "Ripple" },

  // Enterprise Solutions
  { tab: "Enterprise Solutions", icon: "/images/tech-stack-icons/Hyperledger.png", name: "HyperLedger" },
  { tab: "Enterprise Solutions", icon: "/images/tech-stack-icons/microsoft-icon.png", name: "Azure Blockchain" },
  { tab: "Enterprise Solutions", icon: "/images/tech-stack-icons/download-3.png", name: "Azure Service Fabric" },

  // Consensus Mechanisms
  { tab: "Consensus Mechanisms", icon: "/images/tech-stack-icons/tendermint-logo-black.png", name: "Tendermint" },

  // Storage Solutions
  { tab: "Storage Solutions", icon: "/images/tech-stack-icons/IPFS_logo.png", name: "IPFS" },
  { tab: "Storage Solutions", icon: "/images/tech-stack-icons/Bigchaindb.png", name: "BigChainDB" }
];

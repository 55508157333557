export const solutionsData = [
    {
        title: "Access Vetted Talent",
        description:
            "When you hire Ruby on Rails programmers from us, you get developers who are fully vetted for both their technical expertise and communication skills. They’re ready to build top-quality apps and work smoothly with your team.",
        icon: "/images/hire-page-icons/chose-ror-section1.svg",
    },
    {
        title: "View Matches in Seconds",
        description:
            "No more wasting time sifting through endless resumes! Quickly find the right fit for your project with just a few clicks. Whether you need a RoR developer for hire for a short-term task or a long-term role, we make the process fast and easy.",
        icon: "/images/hire-page-icons/chose-ror-section4.svg",
    },
    {
        title: "Save with Global Hires",
        description:
            "Our network of Ruby on Rails developers spans 190 countries, meaning you have access to a pool of 450,000 talented professionals. By hiring globally, you can save up to 58% compared to traditional hiring methods—without compromising on quality.",
        noBorder: true,

        icon: "/images/hire-page-icons/chose-ror-section3.svg",
    },
    {
        icon: "/images/hire-page-icons/chose-ror-section2.svg",
        title: "Get Expert Support",
        description:
            "Hiring Ruby on Rails developers is easy with our expert recruiters by your side. From finding the right fit to getting the best talent for your project, we ensure a smooth hiring experience every step of the way.",
    },
];

export const faqData = [
    {
        id: "collapseOne",
        question: "What is Ruby on Rails (RoR)?",
        answer: "Ruby on Rails is a full-stack framework that allows developers to build scalable, fast, and secure web applications. It uses Ruby as the programming language and follows the Model-View-Controller (MVC) architecture.",
    },
    {
        id: "collapseTwo",
        question: "Why should I hire Ruby on Rails developers?",
        answer: "Hiring Ruby on Rails programmers ensures you get a robust, reliable, and scalable web application. RoR is perfect for rapid development, easy maintenance, and a streamlined development process.",
    },
    {
        id: "collapseThree",
        question: "How can I hire Ruby on Rails programmers?",
        answer: "You can hire Ruby on Rails developers from us by selecting your engagement model (Full-Time, Part-Time, or Hourly), screening candidates, and interviewing them to find the best fit for your project."
    },
    {
        id: "fourth",
        question: "What types of projects can you build with Ruby on Rails?",
        answer: "Ruby on Rails development services are ideal for building scalable web apps, APIs, eCommerce platforms, content management systems, and more. It's a flexible and powerful choice for any web project."
    },
    {
        id: "fifth",
        question: "How long does it take to build a project with Ruby on Rails?",
        answer: "The timeline depends on the complexity of your project. However, with RoR developers for hire, we can develop your project quickly and efficiently, often reducing development time compared to other frameworks.",
    },
    {
        id: "sixth",
        question: "Can I migrate my current system to Ruby on Rails?",
        answer: "Yes! Our Ruby on Rails development company can help you seamlessly migrate your existing applications or systems to Ruby on Rails, ensuring a smooth transition with minimal downtime."
    },
];

export var mainBannerImg = {
    backgroundImage: `url(/images/hire-page-images/hire-ror-banner.png)`,
    factBackgroundImage: `url(/images/hire-page-images/hire-ror-quick-section.png)`,
};

export const bannerList = [
    { list: "Pre-Vetted Developers" },
    { list: "Top Talent" },
    { list: "Timezone Alignment" },
    { list: "Faster & Cost-Effective Hiring" },
];

export const servicesData = [
    {
        title: "Ruby on Rails Consulting",
        description:
            "Leverage expert advice and strategic insights from our experienced RoR developers. Whether you're launching a new project or optimizing an existing one, we guide you every step of the way.",
        icon: "/images/hire-page-icons/hire-ror-icons1.svg",
    },
    {
        title: "Ruby on Rails web Development",
        description:
            "Build feature-rich, scalable web applications with our expert RoR developers. We ensure seamless performance, flexibility, and hassle-free maintenance to keep your web solutions running smoothly.",
        icon: "/images/hire-page-icons/hire-ror-icons2.svg",
    },
    {
        title: "Ruby on Rails API Development",
        description:
            "Our RoR specialists develop robust APIs that ensure seamless communication between applications. Whether integrating third-party services or managing internal data, we've got you covered.",
        icon: "/images/hire-page-icons/hire-ror-icons3.svg",
    },
    {
        title: "CMS and Portal Development",
        description:
            "Hire our RoR developers to build scalable, customizable CMS and web portals. Our solutions ensure ease of management and adaptability to your unique business requirements.",
        icon: "/images/hire-page-icons/hire-ror-icons4.svg",
    },
    {
        title: "Migration to Ruby on Rails",
        description:
            " Planning to migrate to Ruby on Rails? Our experts ensure a seamless transition while preserving data integrity and functionality. We manage every detail for a smooth and hassle-free migration.",
        icon: "/images/hire-page-icons/hire-ror-icons5.svg",
    },
    {
        title: "Integration with Existing Solutions",
        description:
            " Enhance your systems with seamless RoR integration. Our developers ensure your Ruby on Rails applications work flawlessly with existing tools, optimizing performance and efficiency.",
        icon: "/images/hire-page-icons/hire-ror-icons6.svg",
    },
    {
        title: "ROR eCommerce Solutions",
        description:
            " Develop a high-performing eCommerce platform with our RoR experts. We craft engaging, feature-rich online stores with seamless user experiences and easy management capabilities.",
        icon: "/images/hire-page-icons/hire-ror-icons7.svg",
    },
    {
        title: "ROR Support & Maintenance",
        description:
            " Keep your Ruby on Rails applications running optimally with our expert support and maintenance services. We handle bug fixes, performance enhancements, and updates to ensure uninterrupted functionality.",
        icon: "/images/hire-page-icons/hire-ror-icons8.svg",
    },
];

export const steps = [
    {
        title: "Choose your Engagement Model",
        description:
            "Select the engagement model that fits your needs—Full-Time, Part-Time, or Hourly. Whether you need a long-term partner or someone for a short-term task, we have flexible options.",
        icon: "/images/hire-page-setps-icons/icon-1.svg",
    },
    {
        title: "Screen & Select Ruby On Rails Developers",
        description:
            "Browse through our pool of skilled RoR developers for hire. Pick the best candidates based on their profiles and get ready to move forward with the right fit for your project.",
        icon: "/images/hire-page-setps-icons/icon-2.svg",
    },
    {
        title: "Conduct One-on-One Interview",
        description:
            "After you’ve shortlisted your candidates, interview them to evaluate their skills. You can ask both theoretical and practical questions to ensure they’re the perfect match for your Ruby on Rails development services.",
        icon: "/images/hire-page-setps-icons/icon-3.svg",
    },
    {
        title: "Onboard Ruby on Rails Developers",
        description:
            "Once you’ve made your decision, the final candidate will join your team within 24-48 hours after confirmation. It’s that simple to bring Ruby on Rails developers into your project!",
        icon: "/images/hire-page-setps-icons/icon-4.svg",
    },
];

export const facts = [
    {
        text: "<strong>40+ Experienced RoR Developers:</strong> Our team consists of more than 40 skilled Ruby on Rails programmers, ready to build powerful, scalable web applications.",
    },
    {
        text: "<strong>Average Experience of 25.98 Years:</strong> With nearly 26 years of combined experience, our RoR developers bring a wealth of expertise to every project.",
    },
    { text: "<strong>Certified Ruby on Rails Experts: </strong>Our team includes certified Ruby on Rails developers with in-depth knowledge of the latest tools and frameworks." },
    {
        text: "<strong>Cloud-Native & Serverless Expertise:</strong> We specialize in building scalable web development solutions using cloud-native and serverless architectures to ensure reliability and performance.",
    },
    { text: "<strong>Advanced DevOps Practices:</strong> Our developers work with a mature DevOps culture, ensuring fast, secure, and efficient delivery through continuous integration and delivery (CI/CD)." },
    { text: "Reliance on a <strong> mature DevOps culture.</strong>" },
    {
        text: "<strong>Versatile Experience:</strong> Whether it’s big data, IoT, machine learning, or blockchain integration, our team has experience in handling complex and innovative projects with Ruby on Rails.",
    },
];

export const hirignDetails = [
    {
        text: "Hourly Hiring",
        hours: "24 - 48",
        icon: "time.svg",
        bgColor: "#FDFDFF",
    },

    {
        text: "Full Time Hiring",
        hours: "48",
        icon: "full-time.svg",
        bgColor: "#FFFEFA",
    },

    {
        text: "Part Time Hiring",
        hours: "24 - 48",
        icon: "part-time.svg",
        bgColor: "#FDFFFA",
    },
];
import React from "react";
import "./style.css";

const AIBannerBox = () => {
  return (
    <>
      <div className="ai-banner-box">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-8 col-md-12">
              <div className="ai-development-box">
                <h1>
                  Not only an <span>AI Development Company</span> <br />
                  We Are Your Partners in Business Transformation.
                </h1>

                <p className="common-text">
                  As a top-rated AI consulting and development company, we don’t
                  believe in a one-size-fits-all approach. We understand that
                  every business has unique challenges, needs, and requirements.
                </p>

                <p className="common-text">
                  Hence, our AI solutions are tailored to ensure the best
                  outcomes for our clients. Whether it is modernizing your
                  legacy systems, integrating AI capabilities into your existing
                  products, or custom AI development solutions, we have you
                  covered.
                </p>

                <div class="engagment-card-button-not-only">
                  <a
                    href="https://calendly.com/bitsclansolutions/15min"
                    target="_blank"
                    class="bitsclan-ai-calendly-click_wrapper common-green-btn"
                  >
                    Schedule a Call <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AIBannerBox;
